import { ClusterState } from "@api/databricks-handler";
import { _selectoptionType } from "@components/form/select-field";
import { useAppSelector } from "@store/hooks";
import { useMemo } from "react";

const useGetRunPreviewClusters = () => {
    const { clusters, defaultClusterInfo } = useAppSelector((store) => store.ClusterReducer);


    const clustersToShow = useMemo(() => {
        const _clusters: _selectoptionType[] = [];
        if(defaultClusterInfo &&  ((defaultClusterInfo.npipWorkSpace && defaultClusterInfo.workspaceType === 'databricks')||(!defaultClusterInfo.npipWorkSpace && defaultClusterInfo.workspaceType === 'cloudera'))&& defaultClusterInfo.state === ClusterState.RUNNING) {
            _clusters.push({ label: defaultClusterInfo.clusterName + ' (Default Cluster)', value: defaultClusterInfo.clusterId })
        }
        clusters.forEach(cluster => {
            if(cluster.state === ClusterState.RUNNING &&  ((cluster.npipWorkSpace && cluster.workspaceType === 'databricks')||(!cluster.npipWorkSpace && cluster.workspaceType === 'cloudera')) && !cluster.defaultCluster)
            _clusters.push({ label: cluster.clusterName, value: cluster.clusterId })
        })

        return _clusters
    }, [clusters, defaultClusterInfo])

 
    return clustersToShow
}

const useDatabricksGetRunPreviewClusters = () => {
    const { clusters, defaultClusterInfo } = useAppSelector((store) => store.ClusterReducer);


    const clustersToShow = useMemo(() => {
        const _clusters: _selectoptionType[] = [];
        if(defaultClusterInfo &&  ((defaultClusterInfo.npipWorkSpace && defaultClusterInfo.workspaceType === 'databricks')||(!defaultClusterInfo.npipWorkSpace && defaultClusterInfo.workspaceType === 'cloudera'))&& defaultClusterInfo.state === ClusterState.RUNNING) {
            _clusters.push({ label: defaultClusterInfo.clusterName + ' (Default Cluster)', value: defaultClusterInfo.databricksClusterId })
        }
        clusters.forEach(cluster => {
            if(cluster.state === ClusterState.RUNNING &&  ((cluster.npipWorkSpace && cluster.workspaceType === 'databricks')||(!cluster.npipWorkSpace && cluster.workspaceType === 'cloudera')) && !cluster.defaultCluster)
            _clusters.push({ label: cluster.clusterName, value: cluster.databricksClusterId })
        })

        return _clusters
    }, [clusters, defaultClusterInfo])

 
    return clustersToShow
}

export { useGetRunPreviewClusters, useDatabricksGetRunPreviewClusters };