import { InputField, SelectField } from "@components/form";
import { _selectoptionType } from "@components/form/select-field";
import React, { useEffect, useRef, useState } from "react";
import { NewExperimentAddIcon } from "../icons";
import classNames from 'classnames';
import { capitalize, isEmpty, set, upperCase } from 'lodash';
import { BriefExperimentInfo, CreateExperimentInfo, MlflowHandler } from "@api/mlflow-handler";
import { useFormikContext } from "formik";
import { useDidMount } from "rooks";
import { successAlert } from "@components/toastify/notify-toast";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/types";
import { convertToSelectFieldOptions } from '@utils/index';
import { setExperimentOption } from "@store/workflow";

type ExperimentsList = (_selectoptionType & { artifactType: string })[]

const NewExperiments: React.FC<{}> = () => {
    const { initialValues, values, setFieldValue } = useFormikContext<any>();
    const [artifactsList, setArtifactsList] = useState<_selectoptionType[]>([]);
    const [selectedArtifact, setSelectedArtifact] = useState("dbfs");
    const [experimentsList, setExperimentsList] = useState<ExperimentsList>([]);
    const experimentsFieldRef = useRef<SelectField>(null);
    const [showNewExperimentFields, toggleNewExperimentFields] = useState(false);
    const userName = useSelector((state: RootState) => state.AccountReducer.activeUserInfo.username);
    const experiment = useSelector((state: RootState) => state.WorkflowReducer.selectedExp);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const dispatch=useDispatch();
    
    const getArtifactsList = () => {
        MlflowHandler.GetArtifactLocations(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, (response) => {
            const artifactsList = convertToSelectFieldOptions(response);
            // Convert hdfs -> HDFS
            if(artifactsList.length > 0) {
                artifactsList[0].label = artifactsList[0].label.toUpperCase();
            }
            // Convert azure -> Azure
            if(artifactsList.length > 1) {
                artifactsList[1].label = capitalize(artifactsList[1].label);
            }

            setArtifactsList(artifactsList);
        });
    };  

    useEffect(() => {
        if(showNewExperimentFields) getArtifactsList();
    }, [showNewExperimentFields]);

    const getExperiments = (experimentInfo: { name?: string; id?: string } = {}) => {
        MlflowHandler.GetExperimentsList(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, (response: BriefExperimentInfo[]) => {
            const experimentsList: ExperimentsList = response.map(exp => ({ 
                label: exp.name.split('/').pop()?.replace(/'/g, '') || '', // Extracting the last part of the path and removing single quotes
                value: {name: exp.name, id: exp.experiment_id }, 
                artifactType: exp.artifact_location_key === 'hdfs' ? upperCase(exp.artifact_location_key): capitalize(exp.artifact_location_key || '')
            }));
            const sortedExperiments = sortExperimentList(experimentsList, experiment);
            setExperimentsList([...sortedExperiments]);
            if(!isEmpty(experimentInfo) && experimentInfo.name) {
                // const selectedOption = {name: experimentInfo.name, id: experimentInfo.id };
                experimentsFieldRef.current?.setOption({ label: experimentInfo.name, value: updateExperimentInfo(experimentInfo, experimentsList)});
                const updatedContent=updateExperimentInfo(experimentInfo, experimentsList)
                set(initialValues, 'experiment_info', updatedContent);
            }else {
                const selectedOption = {name: experimentsList[0].value.name, id: experimentsList[0].value.id };
                experimentsFieldRef.current?.setOption({ label: experimentsList[0].label, value: selectedOption});
                set(initialValues, 'experiment_info', selectedOption);
            }
        });
    };

    const updateExperimentInfo=(experimentInfo: { name?: string; id?: string } = {}, experimentList: ExperimentsList):{ name?: string; id?: string }=> {
        // Find the matching object in experimentList
        const matchedExperiment = experimentList.find(exp => exp.label === experimentInfo.name);
        
        // If a match is found, update the name; otherwise, keep it the same
        if (matchedExperiment) {
            experimentInfo.name = matchedExperiment.value.name;
        }
        
        return experimentInfo;
    }

    useDidMount(() => {
        getExperiments();
    });

    const handleCreateSuccess = (experimentName: string, response: {experiment_id: string}) => {
        successAlert('Experiment created successfully.');
        toggleNewExperimentFields(false);
        getExperiments({ id: response.experiment_id, name: experimentName });
        newExperimentFieldValuesToDefault();
    };

    const newExperimentFieldValuesToDefault = () => {
        setFieldValue('exp_name', '');
        // setFieldValue('exp_name', '');
    };


    function handleCreateExperimentSubmit(this: any) {
        const data: CreateExperimentInfo = { 
            name: values.exp_name, 
            artifactLocation: selectedArtifact, 
            userName 
        };
        MlflowHandler.CreateExperiment(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, data, handleCreateSuccess.bind(this, values.exp_name));
    }
    
    const handleChange = (e: any) => {
        setSelectedArtifact(e.value);
    };

    const handleOptionClick=(option:any)=>{
        dispatch(setExperimentOption(option))
    }

    const sortExperimentList = (list: typeof experimentsList, exp: typeof experiment) => {
        const matchedExperimentIndex = list.findIndex(
            (experiment) => experiment.value.id === exp.value.id
        );
        if (matchedExperimentIndex !== -1) {
            const [matchedExperiment] = list.splice(matchedExperimentIndex, 1);
            list.unshift(matchedExperiment);
        }
    
        return list;
    };

    return(
        <div
            className="transform__fields"
        > 
            <div
                className={classNames('ml-pipeline', {hide: !showNewExperimentFields})}
            >
                <div className="newExperimentFields__box">
                    <InputField
                        name="exp_name"
                        label="Experiment name"
                        autoComplete="off"
                    />
                    <SelectField
                        name="artifact_storage"
                        label="Artifact Storage"
                        options={artifactsList}
                        // autoSelectFirstOption
                        onOptionClick={handleChange}
                    />
                    <div className="modalBtns__box">
                        <button 
                            className="btn-sm btn-grey"
                            type="button"
                            onClick={handleCreateExperimentSubmit}
                        >
                            Create
                        </button>
                        <button 
                            className="btn-sm btn-cancel"
                            type="button"
                            onClick={() => {
                                toggleNewExperimentFields(false);
                                newExperimentFieldValuesToDefault();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <SelectField 
                name="experiment_info"
                options={experimentsList}
                label="Experiment name"
                renderItemAtOptionsBottom={() => (
                    <button
                        id="btn_new_experiment"
                        onClick={() => {
                            toggleNewExperimentFields(true);
                            experimentsFieldRef.current?.closeDropdown();
                        }}
                    >
                        <NewExperimentAddIcon /> New Experiment
                    </button>
                )}
                renderOptionLabel={(option) => (
                    <div
                        className="titleInfoText__selectFieldOptionLabel"
                    >
                        <span>
                            {option.label}
                        </span>
                        <span>
                            {(option as ExperimentsList[0]).artifactType }
                        </span>
                    </div>
                )}
                className={classNames('highlightLabel', {hide: showNewExperimentFields})}
                ref={experimentsFieldRef}
                infoText="Indicates the name of pipeline. Different versions of the pipeline will be created with new runs .These runs can be indentified with a version number while transforming data."
                required
                onOptionClick={handleOptionClick}
            />

        </div>
    )
}

export default NewExperiments;