import {
    NewComponentResponse, CreateNewComponent, CreateNewComponentResponse,
    DeleteCustomComponentResponse, RenameCustomComponentResponse
} from './types';
import { HttpClient } from '../services';
import { API } from '../../constants/settings';
import { ExecutionEnvModes } from '../../constants/enums';
import { stringifyQueryParams } from '../../helpers/utils';
import { ApiResponse } from '@api/data-source-handler';
import { errorHandlerFor400 } from '@api/services/errorhandler';

class CustomComponentClass {

    getComponentNameValidation(link: string, type: string, env: ExecutionEnvModes, callback: (arg0: NewComponentResponse) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Get('Get Workflows by type', link + '/v2/customcomponentvalidation/' + type + stringifyQueryParams({ env }), callback, errorHandlerCallback);
    }

    CreateComponent(link: string, data: CreateNewComponent, callback: (arg0: CreateNewComponentResponse) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Create Component', link + '/v2/customcomponent/', data, callback,errorHandlerCallback);
        // HttpClient.Post('Create Component', API.url + '/v1/customcomponent/', data, callback, errorHandlerCallback);
    }

    getComponentList(link: string, env: ExecutionEnvModes) {
        return HttpClient.GetPromise(link + '/v2/customcomponent'+ stringifyQueryParams({ env }));
    }

    getRITree(link: string) {
        return HttpClient.GetPromise(link + '/v1/jsondata');
    }

    deleteCustomComponent(link: string, componentId: number, callback: (arg0: DeleteCustomComponentResponse) => any, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Delete('Delete Custom Component', `${link}/v2/customcomponent/${componentId}/`, callback, errorHandlerCallback);
    }

    renameCustomComponent(link: string, componentId: any, payload: any, env: ExecutionEnvModes, callback: (arg0: RenameCustomComponentResponse) => any, errorHandlerCallback?: (arg0: any) => void) {
        payload.env = env;
        HttpClient.Post('Rename Component', `${link}/v2/customcomponent/${componentId}/rename`, payload, callback, errorHandlerCallback);
    }

    updateCustomCompoent(link: string, componentId: any, payload: any, env: ExecutionEnvModes, callback: (arg0: RenameCustomComponentResponse) => any, errorHandlerCallback?: (arg0: any) => void) {
        // HttpClient.Put('Update Custom Component', `${API.url}/v2/customcomponent/${componentId}`, payload, callback,errorHandlerCallback);
        payload.env = env;
        HttpClient.Put('Update Custom Component', `${link}/v2/customcomponent/${componentId}`, payload, callback, errorHandlerCallback);
    }

    cloneCustomComponent(link: string, componentId: number, userId: string, env: ExecutionEnvModes, callback: ApiResponse<CreateNewComponentResponse>, ) {
        HttpClient.Get('Clone custom component', `${link}/v2/customcomponent/clone${stringifyQueryParams({ env, componentId, userId })}`, callback, (r) => errorHandlerFor400((r as any).response));
    }

}

const newComponentHandler = new CustomComponentClass();

export { newComponentHandler };