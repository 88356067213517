import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import './styles.scss';
import { Row, Col, Card, Form, Input, Divider, Button, notification } from 'antd';

import { UserHandler } from '../../api/user-handler';
import { ProfileProps } from './types';
import { ShowWhenTrue } from '../../helpers';
import { setActiveTab } from '../../store/workflow';
import { closeAllCanvasTabs } from '../../store/canvas';
import { CanvasTabs } from '../workflow-page/enums';
class ProfileForm extends React.Component<ProfileProps,{}> {
    //  history = useHistory();
    //  dispatch = useDispatch();
    state = {
        showPassword: false,
        is_submitting: false,
        formData: {
            id: 0,
            name: '',
            username: '',
            email: '',
            oldPassword: '',
            password: '',
            confirmPassword: ''

        }
    }

    componentDidMount() {
        _.get(this.props.activeUserInfo, ['id']) !== '' && this.handleGetUserInfoSuccess();
    }

    componentDidUpdate(prevProps: ProfileProps, prevState: any) {
        if (_.get(this.props.activeUserInfo, ['id']) !== '' && _.get(this.props.activeUserInfo, ['id']) !== prevState.formData.id) {
            this.handleGetUserInfoSuccess();
        }
    }

    handleGetUserInfoSuccess = () => {
        this.setState({
            formData: this.props.activeUserInfo
        }, () => {
            this.props.form.setFieldsValue({
                ...this.state.formData,
            });
        });
    }
    handleShowPasswordToggle = () => {
        this.setState(()=>{
            return {'showPassword':!this.state.showPassword};
        });
    }
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err: any) => {
            if (!err) {
                if(this.state.showPassword) {
                    const data = {
                        id: this.state.formData.id,
                        name: this.state.formData.name,
                        newPassword: this.state.formData.password,
                        oldPassword: this.state.formData.oldPassword
                    };
                    this.setState({
                        is_submitting: true
                    }, () => {
                        UserHandler.UpdatePassword(`${this.props.env?.REACT_APP_PLATFORM_URL}/platform/api`, data, this.handleSubmitSuccess, this.handleSubmitError);
                    });
                }else {
                    const data = {
                        id: this.state.formData.id,
                        name: this.state.formData.name,
                    };
                    this.setState({
                        is_submitting: true
                    }, () => {
                        UserHandler.UpdateProfile(`${this.props.env?.REACT_APP_PLATFORM_URL}/platform/api`, data, this.handleSubmitProfileSuccess, this.handleSubmitError);
                    });
                }
            } else {
                notification.error({ message: 'Error', description: 'All Fields are required' });
            }
        });
    }

    handleSubmitProfileSuccess = () =>{
        this.setState({
            is_submitting: false,
        }, () => {
            notification.success({ message: 'Success', description: 'Successfully Updated Profile' });
            this.props.onGetProfile();
        });
    }

        



     handleLogout = () => {
         localStorage.clear();
         this.props.history.push('/');
         this.props.onResetStoreToInitialState();
     }
    handleSubmitSuccess = () => {
        const data = {
            password: '',
            oldPassword: ''
        };
        const formData = { ...this.state.formData, ...data };
        this.setState({
            is_submitting: false,
        }, () => {
            if (this.state.showPassword) {
                this.props.form.setFieldsValue({ 'formData': formData });
            }
            notification.success({ message: 'Success', description: 'Successfully Updated Password,Please Login.' });
            this.handleLogout();
        });
    }


    handleSubmitError = (error: any) => {
        this.setState({
            is_submitting: false
        }, () => {
            notification.error({ message: error.data.message, description: error.message });
        });

    }

    handleInputFieldChange = (e: any) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, () => {
            this.props.form.setFieldsValue({
                ...this.state.formData,
            });
        });
    }

    confirmPassword = (rule: any, value: any, callback: any) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }



    handleCancel = () => {
        this.props.history.push('/jobdashboard');
    }

    passwordValidation = (rule: any, value: any, callback: any) => {
        // const { form } = this.props;
        const lowercaseRegex = new RegExp(/[a-z]+/);
        const uppercaseRegex = new RegExp(/[A-Z]+/);
        const numberRegex = new RegExp(/[0-9]+/);
        const symbolRegex = new RegExp(/(?=.*?[#?!@$%^&*-])/);
        if (value.length < 8) {
            callback('Password must have eight characters');
        } else if(!lowercaseRegex.test(value)) {
            callback('Password must contain lowercase letter');
        }else if(!uppercaseRegex.test(value)) {
            callback('Password must contain uppercase letter');
        }else if(!numberRegex.test(value)) {
            callback('Password must contain number letter');
        }else if(!symbolRegex.test(value)) {
            callback('Password must contain symbol letter');
        } else {
            callback();
        }
    };

    render() {
        const {
            is_submitting
        } = this.state;

        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row>
                    <Col xl={24}>
                        <Form.Item label="Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please input Name!' }],
                            })(
                                <Input placeholder="Enter Name" name="name" onChange={this.handleInputFieldChange} />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xl={24}>
                        <Form.Item label="Username">
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: 'Please Username!' }],
                            })(
                                <Input placeholder="Enter Username" name="username" onChange={this.handleInputFieldChange} disabled />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xl={24}>
                        <Form.Item label="Email">
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please Email!' }],
                            })(
                                <Input placeholder="Enter Email" name="email" onChange={this.handleInputFieldChange} disabled />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xl={24}>
                        <Button className="btn-sm btn-yellow-transparent btn__new__workflow btn_new_component btn__new__action"
                            onClick={this.handleShowPasswordToggle}
                        >
                            Change Password
                        </Button>
                    </Col>
                </Row>
                <ShowWhenTrue show={this.state.showPassword}>
                    <Row>
                        <Col xl={24}>
                            <Form.Item label="Old Password">
                                {getFieldDecorator('oldPassword', {
                                    rules: [{ required: this.state.showPassword, message: 'Please input Old Password!' }],
                                })(
                                    <Input.Password placeholder="Enter Old Password" name="oldPassword" onChange={this.handleInputFieldChange} />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col xl={12}>
                            <Form.Item label="New Password">
                                {getFieldDecorator('password', {
                                    rules: [{ required: this.state.showPassword, message: 'Please input Password!' }],
                                })(
                                    <Input.Password placeholder="New Password" name="password" onChange={this.handleInputFieldChange} />,
                                )}
                            </Form.Item>
                        </Col>

                        <Col xl={12}>
                            <Form.Item label="Confirm Password">
                                {getFieldDecorator('confirmPassword', {
                                    rules: [{ required: this.state.showPassword, message: 'Please confirm your Password!' }, {
                                        validator: this.confirmPassword,
                                    }],
                                })(
                                    <Input.Password placeholder="Confirm Password" name="confirmPassword" onChange={this.handleInputFieldChange} />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </ShowWhenTrue>

                <Divider />

                <Row>
                    <Col xl={24} >
                        <div className="buttons">
                            <Button ghost type="danger" icon='close' onClick={this.handleCancel}>Cancel</Button>
                            <Button ghost type="primary" icon={is_submitting ? 'loading' : 'right-circle'} htmlType="submit">Submit</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        activeUserInfo: state.AccountReducer.activeUserInfo,
        env: state.AccountReducer.envVariables
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onGetProfile: function () {
        // dispatch(getUserInfo());
    },
    onResetStoreToInitialState : function() {
        setTimeout(() => {
            dispatch(setActiveTab(CanvasTabs.COMPONENTS));
            dispatch(closeAllCanvasTabs());
        }, 1000);
    }
  
});

const ProfileFormView = Form.create({})(connect(mapStateToProps, mapDispatchToProps,)(ProfileForm));

class Profile extends React.Component {
    render() {
        return (
            <section className="profile">
                <Row type="flex" justify="center" align="middle" className="profile-row">
                    <Col xl={15}>
                        <Card title="User Details">
                            <ProfileFormView {...this.props} />
                        </Card>
                    </Col>

                </Row>
            </section>
        );
    }
}

export default Profile;
