import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Tree, TreeDataNode, TreeProps } from "antd-latest";
import { Checkbox, Icon } from "antd";
import { FaFolderTree } from "react-icons/fa6";
import styles from "../../styles.module.scss";
import { Flex } from "@components/ui/Flex";
import { errorAlert } from "@components/toastify/notify-toast";
import { DataSourceHandler } from "@api/data-source-handler";
import { RootState } from "@store/types";
import { errorHandlerFor400 } from "@api/services/errorhandler";
import { Text } from "@components/ui/Text";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { FaFolder } from "react-icons/fa";
import { _canvasdagFormInitialValues } from "@pages/workflow-page/utils";
import { _selectoptionType } from "@components/form/select-field";
import PiRequestHierarchyHeader from "./header";
import { RootType, databases, servers } from "./constant";
import HierarchyView from "./view";

type Props = {
    nodeName: string;
    handleGoToReqPage: () => void;
}

const Hierarchy: React.FC<Props> = ({ nodeName,handleGoToReqPage }) => {
    const { envVariables: Env } = useSelector((store: RootState) => store.AccountReducer);
    const [serverName, setServerName] = useState("pi-edge-and-dom");
    const [selectedElements, setSelectedElements] = useState<RootType>({} as RootType);
    const [dbName, setDbName] = useState("AnalysisDB")
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [isLoadingElemets, setIsLoadingElements] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [filteredData, setFilteredData] = useState<RootType[]>([]);
    const [roots, setRoots] = useState<RootType[]>([]);
    const [activeStep, setActiveStep] = useState(0);

    const handleRowSelection = (record: any) => {
        const selectedIndex = selectedRowKeys.indexOf(record.attr_name);
        const newSelectedKeys = [...selectedRowKeys];

        if (selectedIndex > -1) {
            newSelectedKeys.splice(selectedIndex, 1);
        } else {
            newSelectedKeys.push(record.attr_name);
        }

        setSelectedRowKeys(newSelectedKeys);
    };

    const handleSearch = (confirm: () => void) => {
        confirm();
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };


    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <input
                    className="search_input"
                    placeholder={`Search ${dataIndex}`}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    value={selectedKeys[0]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Flex gap="10px">
                    <button className="btn btn-yellow btn-sm" style={{ width: 90 }} onClick={() => handleSearch(confirm)}>Search</button>
                    <button className="btn btn-grey btn-sm" style={{ width: 90 }} onClick={() => handleReset(clearFilters)}>Reset</button>
                </Flex>
            </div>
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        render: (text: any) => text,
    });

    const mapToTreeData = (items: RootType[]): TreeDataNode[] => {
        return items.map((item) => ({
            title: item.element_name,
            key: item.element_id,
            children: item.children ? mapToTreeData(item.children) : [],
        }));
    };

    const getRoots = () => {
        if (isEmpty(serverName)) {
            errorAlert("Please enter server name")
            return;
        }
        if (isEmpty(dbName)) {
            errorAlert("Please enter database name")
            return;
        }
        setIsLoading(true)
        DataSourceHandler.GetRoots(
            `${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`,nodeName,
            (response) => {
                setRoots(response)
                setIsLoading(false)
            },
            (error: any) => {
                errorHandlerFor400(error)
                setIsLoading(false)
            },
        );
    };

    const treeData = useMemo(() => mapToTreeData(roots), [roots]);

    const handleSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
        setIsLoadingElements(true);
        setSelectedElements({} as RootType);
        setFilteredData([]);
        if (info.selected) {
            DataSourceHandler.GetRootElementById(
                `${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`,
                info.node.key?.toString() || "",nodeName,
                (response) => {
                    setSelectedElements(response);
                    setFilteredData(response.attributes || []);
                    setIsLoadingElements(false);
                    const updateTree = (nodes: RootType[]): RootType[] => {
                        return nodes.map((node) => {
                            if (node.element_id === info.node.key?.toString()) {
                                return { ...node, children: response?.children || [] };
                            }
                            if (node.children) {
                                return { ...node, children: updateTree(node.children) };
                            }
                            return node;
                        });
                    };

                    setRoots((prevRoots) => {
                        const newRoots = updateTree(prevRoots);
                        return [...newRoots];
                    });
                },
                (error: any) => {
                    errorHandlerFor400(error);
                    setIsLoadingElements(false);
                }
            );
        } else {
            setSelectedElements({} as RootType);
            setFilteredData([]);
            setIsLoadingElements(false);
        }
    };

    const handleExpand = (expandedKeys: React.Key[]) => {
        const temp = "";
    };

    const tableDataSources: any = useMemo(() => {
        return selectedElements?.attributes?.map((element) => ({
            key: element.attr_id,
            attr_name: element.attr_name,
        }));
    }, [selectedElements]);

    const handleSelectAll = () => {
        if (selectedRowKeys.length === filteredData.length) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys(filteredData.map((item: any) => item.attr_name));
        }
    };
    const columns: any = [
        {
            title: (
                <Checkbox
                    checked={selectedRowKeys?.length === tableDataSources?.length && tableDataSources?.length > 0}
                    indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length < tableDataSources?.length}
                    onChange={handleSelectAll}
                />

            ),
            dataIndex: 'selection',
            key: 'selection',
            width: 100,
            render: (_: any, record: any) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.attr_name)}
                    onChange={() => handleRowSelection(record)}
                />
            )
        },
        {
            title: 'Attr Name',
            dataIndex: 'attr_name',
            key: 'attr_name',
            sorter: (a: any, b: any) => a.attr_name?.length - b.attr_name?.length,
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('attr_name'),
        },
    ];

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: { action: string; currentDataSource: any; }) => {
        setFilteredData(extra.currentDataSource);

    };

    return (
        <div>
            <PiRequestHierarchyHeader servers={servers} databases={databases} serverName={serverName} dbName={dbName} isLoading={isLoading} setServerName={setServerName} setDbName={setDbName} getRoots={getRoots} />
            <div className={styles.treeContainer}>
                <Flex gap="30px">
                    <ShowWhenTrue show={isEmpty(treeData)}>
                        <div className="noworkflows__msg">
                            No Data Available
                        </div>
                    </ShowWhenTrue>
                    <ShowWhenTrue show={!isEmpty(treeData)}>
                        <Flex className={styles.boxHierarchyTreeView} flexDirection={"column"} gap="0px">
                            <Flex gap="10px" className={styles.labelHierarchyView} justifyContent="space-between" alignItems="center">
                                <div className={styles.labelHierarchyView}>
                                    <FaFolderTree />
                                    <Text fontSize={14} fontWeight={500}>Elements</Text>
                                </div>
                                <div >
                                    <button
                                        className="btn btn-md btn-grey-transparent"
                                        id="refreshPiRequests"
                                        style={{backgroundColor: "#212233"}}
                                    >
                                        <>
                                            <img src="/icons/data-browser/refresh.svg" />
                                            Refresh
                                        </>
                                    </button>
                                </div>
                            </Flex>
                            <Tree
                                className="custom-tree"
                                selectable
                                showLine
                                icon={<FaFolder />}
                                showIcon
                                onSelect={handleSelect}
                                onExpand={handleExpand}
                                treeData={treeData}
                            />
                        </Flex>
                        <HierarchyView
                            selectedElements={selectedElements}
                            tableDataSources={tableDataSources}
                            columns={columns}
                            isLoadingElements={isLoadingElemets}
                            showForm={showForm}
                            activeStep={activeStep}
                            selectedRowKeys={selectedRowKeys}
                            setActiveStep={setActiveStep}
                            setShowForm={setShowForm}
                            setSelectedRowKeys={setSelectedRowKeys}
                            handleTableChange={handleTableChange}
                            handleGoToReqPage={handleGoToReqPage}
                        />
                    </ShowWhenTrue>
                </Flex>
            </div>

        </div>
    );
};

export default Hierarchy;
