import React, { useCallback, useEffect, useState } from 'react';
import { MdOutlineArrowLeft, MdOutlineArrowRight } from 'react-icons/md';
import classNames from 'classnames';
import MiddleContainer from './middleContainer';
import { DataSourceHandler } from '@api/data-source-handler';
import { LeftContainer } from './leftContainer';
import { StateData } from '../types';
import { errorAlert } from '@components/toastify/notify-toast';
import { useDataExplorerContext } from '../useDataExplorerContext';
import { _selectoptionType } from '@components/form/select-field';
import styles from './../styles.module.scss';
import '../style.scss'
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

const DataExplorerLayout: React.FC<{}> = () => {
    const [isLoading, setLoading] = useState(true);
    const [isShowSidebar, setIsShowSidebar] = useState<boolean>(false);
    const [mainData, setMainData] = useState<StateData>({
        data: {},
        rdbms_keys: [],
        database_list: [],
        options: []
    });
    const { database, theme } = useDataExplorerContext();
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    useEffect(() => {
        setLoading(true);
        if(Env?.REACT_DEEP_SQL_URL){
        DataSourceHandler.GetAllDeepSqlConnectionsInfo(Env?.REACT_DEEP_SQL_URL, async (data) => {
            const options: _selectoptionType[] = data.map((db: any) => {
                return {
                    label: db.db_id,
                    value: db.db_id,
                    _extra: db,
                    db_type: ""
                }
            });

            setMainData({
                data: {},
                rdbms_keys: [],
                database_list: [],
                options: options
            })
            setLoading(false)

        }, (e) => {
            setLoading(false)
            errorAlert(e.data)
        })
    }
    }, [Env?.REACT_DEEP_SQL_URL]);

    const toggleSidebar = useCallback(() => {
        setIsShowSidebar((prev) => !prev);
    }, []);

    return (
        <div id="dataexplorerNew" className={styles["dataexplorer__container"]}>
            <div className="container">
                <section className={`sidebar ${isShowSidebar ? "open" : ""}`}>
                    <LeftContainer
                        isLoading={isLoading}
                        mainData={mainData}
                    />
                </section>
                <section className="main" style={{ background: theme === "dark" ? '#030613' : '#F4F4F5' }}>
                    {
                        database.id !== -1 ? (
                            <div className='flex'>
                                <MiddleContainer />
                            </div>
                        ) : (
                            <div className={classNames(styles["middle_container"], 'align-middle', 'text-info')}>
                                <span>Please select a knowledge graph to proceed ...</span>
                            </div>
                        )
                    }
                    {isShowSidebar ? (
                        <MdOutlineArrowRight
                            className="burger"
                            size={28.8}
                            onClick={toggleSidebar}
                        />
                    ) : (
                        <MdOutlineArrowLeft
                            className="burger"
                            size={28.8}
                            onClick={toggleSidebar}
                        />
                    )}
                </section>
            </div>

        </div>
    );
};

export default DataExplorerLayout;