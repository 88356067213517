import React from 'react';
import classnames from 'classnames';
import styles from "../../styles.module.scss";
import { MdDelete, MdEdit } from 'react-icons/md';
import { TooltipTop } from '@components/tooltips';
import { HierarchyData } from '../types';
import { EyeIcon } from '@pages/workflow-analytics-page/assets/icons';

type HierachyListProps = {
  data: HierarchyData[] | null;
  handleDelete: (item: HierarchyData) => void;
  handleEdit: (item: HierarchyData) => void;
  handleViewCsvFileMetadata: (item: HierarchyData) => void;
};

const HierachyList: React.FC<HierachyListProps> = ({ data, handleDelete, handleEdit, handleViewCsvFileMetadata }) => {

  return (
    <div className={styles["assetList"]}>
      <ul className={classnames(styles['nifi_titles'])}>
        {data?.map((item, index) => (
          <li key={index}>
            <div>
              <div className={styles['key']}>Hierarchy Name</div>
              <TooltipTop title={item.hierarchyName}>
              <div className={styles['hierarchyName']}>{item.hierarchyName}</div>
              </TooltipTop>
            </div>
            <div className={styles['divider']}></div>
            <div>
              <div className={styles['key']}>Edge Name</div>
              <div>{item.edgeName}</div>
            </div>
            <div className={styles['divider']}></div>


            <div className={styles.actions}>
              <TooltipTop title="Edit Hierarchy">
                <button
                  className={styles.actionButton}
                  onClick={() => handleEdit(item)}
                >
                  <div className={styles.center}>

                    <MdEdit size={"24px"} />
                  </div>
                </button>
              </TooltipTop>
              <TooltipTop title="View Hierarchy">
                <button
                  className={styles.actionButton}
                  onClick={() => handleViewCsvFileMetadata(item)}
                >
                  <div className={styles['center']}>
                    <EyeIcon />
                  </div>
                </button>
              </TooltipTop>
              <TooltipTop title="Delete Hierarchy">
                <button
                  className={styles.deleteButton}
                  onClick={() => handleDelete(item)}
                >
                  <div className={styles.actionButton}>
                    <MdDelete size={"24px"} />
                  </div>
                </button>
              </TooltipTop>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HierachyList;
