import React, { useEffect, useState } from "react";
import { Modal, Table, Button, Radio } from "antd";

interface PatchModalProps {
  isVisible: boolean;
  onClose: () => void;
  patchData: {
    data : any[], type: string
  };
  onUpdate: (selectedIds: number[], type: string) => void;
}

const PatchModal: React.FC<PatchModalProps> = ({ isVisible, onClose, patchData, onUpdate }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleUpdateClick = () => {
    if (selectedIndex !== null) {
      const ids =
        patchData.type === "revert"
          ? patchData.data.slice(selectedIndex).map((patch) => patch.id)
          : patchData.data.slice(0, selectedIndex + 1).map((patch) => patch.id);

      onUpdate(ids, patchData.type);
    }
  };


  const handleRadioChange = (index: number) => {
    setSelectedIndex(index);
  };

  const handleDeselectAll = () => {
    setSelectedIndex(null);
  };

  useEffect(() => {
    if (isVisible) {
      setSelectedIndex(null); 
    }
  }, [isVisible]);

  const columns = [
    {
      title: "Patch Version",
      dataIndex: "patchVersion",
      key: "patchVersion",
    },
    {
      title: "Release Notes",
      dataIndex: "releaseNotes",
      key: "releaseNotes",
      render: (text: string) => (
        <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{text}</pre>
      ),
    },
    {
      title: "Select",
      key: "select",
      render: (_: any, __: any, index: number) => (
        <Radio
          checked={
            selectedIndex !== null &&
            (patchData.type === "update"
              ? index <= selectedIndex
              : index >= selectedIndex
            )
          }
          onChange={() => handleRadioChange(index)}
        />
      ),
    },
  ];

  return (
    <Modal
      title={patchData.type === "update" ? "Patch Updates" : "Revert Patch"}
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="deselect" onClick={handleDeselectAll} disabled={selectedIndex === null}>
          Deselect All
        </Button>,
        <Button
          className="btn-yellow-transparent"
          key="update"
          type="primary"
          onClick={handleUpdateClick}
          disabled={selectedIndex === null}
        >
          {patchData.type === "update" ? "Update" : "Revert"}
        </Button>,
      ]}
    >
      <div style={{ maxHeight: 500, overflowY: 'auto' }}>
        {patchData.type === "revert" && patchData.data.length === 0 ? (
          <div>
            <p className="emptyCheckText">Since there are no updates made, there is nothing to revert.</p>
          </div>
        ) : patchData.type === "update" && patchData.data.length === 0 ? (
          <div>
            <p className="emptyCheckText">No updates are available to apply at this moment.</p>
          </div>
        ) : (
          <Table
            dataSource={patchData?.data.map((item, index) => ({ ...item, key: index }))}
            columns={columns}
            pagination={false}
          />
        )}
      </div>
    </Modal>

  );
};

export default PatchModal;
