import { CanvasIcon, AnalyticsIcon, jobDashboardIcon, DataSourcesIcon } from '../header-sidebar/icons';
import { jobsTabs, dataTabs, studioTabs } from '../header-sidebar/enums';
import { setActiveTab, toggleModal } from '../../store/workflow';
import { CanvasTabs } from '../../pages/workflow-page/enums';
import { toggleLandingPage } from '../../store/common';

export const getAllCards = (enableDataExplorer: boolean) => [
    {
        name: 'Build a Workflow',
        icon: CanvasIcon,
        type: 'button',
        tabs: [
            {
                title: 'Create New',
                action: ({ dispatch, history }: any) => {
                    history.push(studioTabs.tabs.canvas.url);
                    dispatch(toggleModal('newWorkflow', true));
                    dispatch(toggleLandingPage(false));
                }
            },
            {
                title: 'Open Existing',
                action: ({ dispatch, history }: any) => {
                    history.push(studioTabs.tabs.canvas.url);
                    dispatch(setActiveTab(CanvasTabs.WORKFLOWS));
                    dispatch(toggleLandingPage(false));
                } 
            },
        ]
    },
    {
        name: 'Analytics and Metrics',
        icon: AnalyticsIcon,
        type: 'url',
        tabs: [
            {
                title: 'View Analytics',
                url: studioTabs.tabs.visualizations.url
            },
            {
                title: 'Run Analytics',
                url: studioTabs.tabs.visualizations.url 
            }
        ]
    },
    {
        name: 'Manage/Schedule Jobs',
        icon: jobDashboardIcon,
        type: 'url',
        tabs: [
            {
                title: 'Job Dashboard',
                url: jobsTabs.tabs.jobDashboard.url
            },
            {
                title: 'Job History',
                url: jobsTabs.tabs.jobDashboard.url
            }
        ]
    },
    {
        name: 'Manage Data',
        icon: DataSourcesIcon,
        type: 'url',
        tabs: [
            {
                title: 'Open Browser',
                url: dataTabs(enableDataExplorer).tabs.browser.url
            },
            {
                title: 'Manage Sources',
                url: dataTabs(enableDataExplorer).tabs.browser.url
            }
        ]
    }
];

