  
import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import { DsPortModel } from '../port/ds_portmodel';
import { DS, PortTypes } from '../enums';
import { BasePositionModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';
import _, { isEmpty } from 'lodash';
import { DsLinkModel } from '../link/ds_linkmodel';
import { RiTreeComponentDetails } from 'types/component';



export interface DsNodeModelOptions extends BasePositionModelOptions {
    dfName: string;
    actualTitle: string;
    title: string;
    subtitle: string;
    iconKey: string;
    isPropertiesTouched: boolean;
    isPropertiesSavedAtleastOnce: boolean;
    isPropertiesValid: boolean;
    nodeType: 'component' | 'variable';
    isMounted: boolean;
    hash: string | undefined;
    notes: string;
    customComponentId: number | undefined;
}

export interface DsNodeModelGenerics extends NodeModelGenerics {
	OPTIONS: DsNodeModelOptions;
}



export interface DsNodeExtras extends RiTreeComponentDetails {
    fnType: string | number | boolean;
    // for older workflows
    id?: string;
    fnName: string;
    args?: string[];
    input_port?: number;
    output_port?: number;
    customComponentPayload?: string;
    extraData?: any;
    isReadOnly: boolean;
    isUpgradedToFuzzyAggregationV3 ?: boolean;
} 

export type DeletedLinksInfo = {
    linkId: string;
    nodeId: string;
    portId: string;
};
export class DsNodeModel extends NodeModel<DsNodeModelGenerics> {
    public portsIn: DsPortModel[];
    public portsOut: DsPortModel[];
    public extras!: DsNodeExtras;
    title!: string;
    hasVariableInputPort: boolean;
    
    constructor(options?: DsNodeModelOptions);
    constructor(options: any = {}) {
        super({
            type: DS.name,
            ...options
        });
        this.portsOut = [];
        this.portsIn = [];
        this.hasVariableInputPort = false;
    }

    

    addPort<T extends DsPortModel>(port: T): T {
        super.addPort(port);
        if (port.getOptions().name.includes(PortTypes.IN)) {
            if (this.portsIn.indexOf(port) === -1) {
                this.portsIn.push(port);
            }
        } else {
            if (this.portsOut.indexOf(port) === -1) {
                this.portsOut.push(port);
            }
        }
        return port;
    }

    addInPort(portNumber: number, isVariablePort = false): DsPortModel {
        // if(isVariablePort) {
        //     p = new DsVariablePortModel(isVariablePort, PortTypes.IN, portNumber);
        // } else {
        const p = new DsPortModel(isVariablePort, PortTypes.IN, portNumber);
        // }
        if(isVariablePort) {
            this.hasVariableInputPort = true;
        }
        return this.addPort(p);
    }

    addOutPort(portNumber: number, isVariablePort = false): DsPortModel {
        const p = new DsPortModel(isVariablePort, PortTypes.OUT, portNumber);
        return this.addPort(p);
    }

    checkForVariableInputPort() {
        this.hasVariableInputPort = 
            !!(this.portsIn.find(port => port?.getOptions().isVariablePort));
    }

    clearPortLinks(port: DsPortModel): DeletedLinksInfo[] {
        const linksInfo: DeletedLinksInfo[] = [];
            Object.values(port?.getLinks())?.map(_link => {
                _link.remove();
                const linkInfo: DeletedLinksInfo = {
                    linkId: _link.getID(),
                    nodeId: '',
                    portId: ''
                };
                if(port === _link.getSourcePort() && !!_link.getTargetPort()) {
                    // add link only if its connected
                    linkInfo.nodeId = _link.getTargetPort().getNode().getID(); 
                    linkInfo.portId = _link.getTargetPort().getID(); 
                    linksInfo.push(linkInfo);
                } else if(port === _link.getTargetPort()){
                    linkInfo.nodeId = _link.getSourcePort().getNode().getID(); 
                    linkInfo.portId = _link.getSourcePort().getID(); 
                    linksInfo.push(linkInfo);
                }
            });   
        return linksInfo;
    }


    doClone(lookupTable: {}, clone: any): void {
        clone.portsIn = [];
        clone.portsOut = [];
        super.doClone(lookupTable, clone);
    }

    removePort(port: DsPortModel): DeletedLinksInfo[]{
        if (isEmpty(port)){
            return [] ;
        }
        const deletedLinks = this.clearPortLinks(port);
        super.removePort(port);
        if(port.getOptions().alignment === PortModelAlignment.LEFT){
            this.portsIn = this.portsIn.filter(_port => _port?.getID() !== port?.getID());
        } else {            // this.portsOut = this.getOutPorts().filter(_port => _port.getID() !== port.getID());
            this.portsOut = this.portsOut.filter(_port => _port?.getID() !== port?.getID());
        }
        this.checkForVariableInputPort();
        return deletedLinks;
    }


    deserialize(event: DeserializeEvent<this>) {    
        super.deserialize(event);
        this.options.title = event.data.title;
        this.options.actualTitle = event.data.actualTitle;
        this.options.subtitle = event.data.subtitle;
        this.options.iconKey = event.data.iconKey;
        this.options.isPropertiesValid = event.data.isPropertiesValid;
        this.options.notes = event.data.notes;
        this.options.isPropertiesTouched = event.data.isPropertiesTouched;
        this.options.isPropertiesSavedAtleastOnce = event.data.isPropertiesSavedAtleastOnce;
        this.options.nodeType = event.data.nodeType;
        this.options.isMounted = event.data.isMounted;
        this.options.dfName = event.data.dfName;
        this.options.hash = event.data.hash;
        this.options.customComponentId = event.data.customComponentId;
        // retrieve formdata and set it to extras
        this.extras = event.data.extras;
        // set isselected to false 
        this.setSelected(false);
        this.portsIn = _.map(event.data.portsInOrder, id => {
            return this.getPortFromID(id);
        }) as DsPortModel[];
        this.portsOut = _.map(event.data.portsOutOrder, id => {
            return this.getPortFromID(id);
        }) as DsPortModel[];
        this.checkForVariableInputPort();
    }

    serialize(): any {
        return {
            ...super.serialize(),
            title: this.options.title,
            subtitle: this.options.subtitle,
            actualTitle: this.options.actualTitle,
            iconKey: this.options.iconKey,
            isPropertiesValid: this.options.isPropertiesValid,
            notes: this.options.notes,
            isPropertiesTouched: this.options.isPropertiesTouched,
            isPropertiesSavedAtleastOnce: this.options.isPropertiesSavedAtleastOnce,
            nodeType: this.options.nodeType,
            isMounted: this.options.isMounted,
            dfName: this.options.dfName,
            hash: this.options.hash,
            customComponentId: this.options.customComponentId,
            // save formdata from extras 
            extras: this.extras,
            portsInOrder: _.map(this.portsIn, port => {
                return port?.getID();
            }),
            portsOutOrder: _.map(this.portsOut, port => {
                return port?.getID();
            })
        };
    }

    getVariableInputPort() {
        return this.portsIn.find(port => port.getOptions().isVariablePort);
    }


    getConnectedVariables() {
        const connectedVariables: {
            node: DsNodeModel;
            link: DsLinkModel;
        }[] = [];
        if(this.options.nodeType === 'component') {
            const variableInPort = this.getVariableInputPort();
            if(variableInPort) {
                variableInPort.getLinksInArray().forEach(link => {
                    const variableNode = link.getConnectedNode(this.getID());
                    !!variableNode && connectedVariables.push({
                        node: variableNode,
                        link 
                    });
                });
            }
        } return connectedVariables;
    }
    
    getInPorts(): DsPortModel[] {
        return this.portsIn;
    }

    getOutPorts(): DsPortModel[] {
        return this.portsOut;
    }
    
    setIsPropertiesValid(action: boolean) {
        this.options.isPropertiesValid = action;
    }

    setNotes(notes: string) {
        this.options.notes = notes;
    }

    setIsPropertiesTouched(action: boolean) {
        this.options.isPropertiesTouched = action;
    }

    setIsPropertiesSavedAtleastOnce(action: boolean) {
        this.options.isPropertiesSavedAtleastOnce = action;
    }

    isVariableInPortConnected() {
        this.portsIn = this.portsIn.filter(port => port !== null);
        return this.portsIn.some(port => {
            const result = port.isVariablePortAndConnected();
            return result !== null && result; 
        });
    }
    
    

    // checkIfPortsCanBeConnected() {

    // }

}