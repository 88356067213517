import { WorkflowInfo, ComponentStatus, ActiveComponentInfo, WorkflowReducerState } from '../workflow';
import { Layout } from 'react-grid-layout';
import {
    AllGraphsInfo,
} from '../../pages/workflow-analytics-page/Canvas';
import { DfInfo } from '../../api/analytics-handler';
import { JobInfo } from '../../api/job-handler';
import { LoadInitialState, ResetReducerState } from '../types';
import { ExecutionTimeoutFrequency } from '../../pages/workflow-page/SchedulePipelinesForm';
import { ScheduleTiming } from '../../pages/workflow-page/modals/schedule-workflow';
import { Moment } from 'moment';
import { SessionInfoTypes } from '@pages/workflow-analytics-page/utils';

export const OPEN_A_NEW_CANVAS_TAB = 'OPEN_A_NEW_CANVAS_TAB';
export const SWITCH_TO_EXISTING_CANVAS_TAB = 'SWITCH_TO_EXISTING_CANVAS_TAB';
export const UPDATE_ALL_OPEN_TABS = 'UPDATE_ALL_OPEN_TABS';
export const CLOSE_CANVAS_TAB = 'CLOSE_CANVAS_TAB';
export const UPDATE_A_CANVAS_TAB_INFO = 'UPDATE_A_CANVAS_TAB_INFO';
export const CLOSE_ALL_CANVAS_TABS = 'CLOSE_ALL_CANVAS_TABS';
export const SET_CODE_VIEW = 'SET_CODE_VIEW';
export const RESET_ACTIVE_TAB = 'RESET_ACTIVE_TAB';
export const UPDATE_ZEPPLIN_DATA = 'UPDATE_ZEPPLIN_DATA';

export type PageTypes = keyof CanvasReducerState;


export interface OpenNewCanvasTab {
	type: typeof OPEN_A_NEW_CANVAS_TAB;
	tabInfo: TabTypes;
	page: PageTypes;
}

export interface CloseCanvasTab {
	type: typeof CLOSE_CANVAS_TAB;
	id: string | number;
	page: PageTypes;
	openPenultimateTab: boolean;
	filterTabsFn?: (arg0: TabTypes) => boolean;
}

export interface SwitchToExistingCanvasTab
	extends Omit<CloseCanvasTab, 'type' | 'openPenultimateTab'> {
	type: typeof SWITCH_TO_EXISTING_CANVAS_TAB;
	page: PageTypes;
}

export interface UpdateACanvasTabInfo extends Omit<OpenNewCanvasTab, 'type'> {
	type: typeof UPDATE_A_CANVAS_TAB_INFO;
	page: PageTypes;
}

export interface CloseAllCanvasTabs {
	type: typeof CLOSE_ALL_CANVAS_TABS;
	page?: PageTypes;
}

export interface UpdateAllOpenTabs {
	type: typeof UPDATE_ALL_OPEN_TABS;
	page: PageTypes;
	updatedTabs: OpenTabsType;
}

export interface UpdateCodeView {
	type: typeof SET_CODE_VIEW;
	page: {
		open: boolean;
		notebook: string;
	};
}

export interface ResetActiveTab {
	type: typeof RESET_ACTIVE_TAB;
	page: PageTypes;
}

export type ActionTypes =
	| OpenNewCanvasTab
	| SwitchToExistingCanvasTab
	| CloseCanvasTab
	| UpdateACanvasTabInfo
	| CloseAllCanvasTabs
	| UpdateAllOpenTabs
	| UpdateCodeView
	| ResetActiveTab
    | ResetReducerState
	| LoadInitialState;

// export type ActionTypes = {
//     type: typeof OPEN_CANVAS_TAB | typeof SET_ACTIVE_CANVAS_TAB | typeof CLOSE_CANVAS_TAB;
//     payload?: any;
// }

export enum WorkflowEditorTypes {
	'WORKFLOW_SPARK'='WORKFLOW_SPARK',
	'WORKFLOW_PYTHON'='WORKFLOW_PYTHON',
	'WORKFLOW_PIPELINES' = 'WORKFLOW_PIPELINES',
	'WORKFLOW_STREAMING' = 'WORKFLOW_STREAMING' 
}
export enum WorkflowVisualizationTypes {
	'WORKFLOW_VISUALIZATION_SPARK'='WORKFLOW_VISUALIZATION_SPARK',
	'WORKFLOW_VISUALIZATION_PYTHON'='WORKFLOW_VISUALIZATION_PYTHON',
	'WORKFLOW_VISUALIZATION_STREAMING'='WORKFLOW_VISUALIZATION_STREAMING',
} 

export type CanvasTabTypes = WorkflowEditorTypes | WorkflowVisualizationTypes;

export type WorkflowCanvasTabInfo = WorkflowInfo & {
	previewStatusInfo?: {
		isRunning: boolean;
		components: ComponentStatus;
		workflowSessionId: string;
		statementId: string;
        isStreaming: boolean;
		previewPayload: Pick<JobInfo, 'maxTimeout' | 'noOfRecordsToIngest' | 'clusterId'> | null;
	};
	// pageNo: number;
	consoleInfo?: {
		jobInfo?: JobInfo;
		logs: string[];
		type: 'capture_schema' | JobInfo['type'] | null;
		height?: number;
	};
	activeComponentInfo: ActiveComponentInfo | null;
	showCodeEditor: boolean;
	showHelpBulbAnimation: boolean;
	helpDocRef: { docTitle: string; docData: string; subHeadingSlug?: string; subHeadingTitle?: string } | null;
	scheduleInfoForDag: {
		dag_id: string;
		cronExpression: string;
		scheduleType: string;
		mins: number;
		days: number | string;
		weekOfTheMonth: number;
		hrs: number;
		retries: number;
		retryDelay: number;
		execution_timeout: number | null;
		execution_timeout_frequency: ExecutionTimeoutFrequency;
		overrideNotificationEmail: boolean;
		notificationEmails: string;
		notificationType: string;
		scheduleAdvancedMode: boolean;
		startDate: string;
		weekDays: string;
		months: number;
		scheduleTimingInfo: ScheduleTiming,
		runOnceExecutionDate: Moment,
		formattedRunOnceExecutionDate: string;
		cluster?:string;
		max_active_runs:string,
		catchup:boolean,
		exponential_backoff:boolean
	} | null;
	activeClusterInfoForZeppelin: WorkflowReducerState['activeCluster'];
	activeClusterIdForPreview?: string | null;
	notes: string;
};

export type AnalyticsGraphInfo = { layout: Layout; formData: any; data: any };
export type WorkflowAnalyticsTabInfo = WorkflowInfo & {
	// pageNo: number;
	activeComponentIdForAnalytics: null | string;
	sessionId: null | string;
	previewStatementId: null | string;
	componentIdsOfPlots: string[];
	showPlotInProgressSpinner: boolean;
	graphsInfo: AllGraphsInfo;
	dfList: DfInfo[];
	isStreaming: boolean;
	activeClusterIdForPreview?: string;
	sessionState: SessionInfoTypes | null; 
};

export type WorkflowCanvasTab = {
	type: WorkflowEditorTypes;
	info: WorkflowCanvasTabInfo;
};
export type WorkflowAnalyticsTab = {
	type: WorkflowVisualizationTypes;
	info: WorkflowAnalyticsTabInfo;
};

export type TabTypes = WorkflowCanvasTab | WorkflowAnalyticsTab;
export type OpenTabsType = Map<string | number, TabTypes>;
export type TabInfoTypes = WorkflowCanvasTabInfo | WorkflowAnalyticsTabInfo;

export type BasicTabInfo = {
	id: number;
	name: string;
	saved: boolean;
	[x: string]: any;
};

export type CanvasReducerState = {
	workflowEditor: {
		openTabs: Map<string | number, WorkflowCanvasTab>;
		activeTab: { 
			type: WorkflowEditorTypes; 
			id: number; 
			name: string; 
		};
	};
	analytics: {
		openTabs: Map<string | number, WorkflowAnalyticsTab>;
		activeTab: {
			type: WorkflowVisualizationTypes;
			id: number;
			name: string;
		};
	};
};
