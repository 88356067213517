import { GraphInfo, PlotSelectionFormInfo, TiffColorPlotInfo } from ".";
import ReactEcharts from "echarts-for-react";
import React, {
	useRef,
	useEffect,
	useMemo,
	useState,
	useCallback,
} from "react";
import { useDispatch } from "react-redux";
import { worldJson } from "./test-graph-data";
import { getGraphConfig } from "./graph-creators";
import {
	setPlotStatmentInfoForErrorModal,
	toggleAnalyticsModal,
} from "../../../store/analytics";
import { WorkflowCanvas } from "../../../components/workflow-canvas";
import {
	SettingsIcon,
	MoveIcon,
	RestoreIcon,
	GoToFullScreenIcon,
	CloseGraphIcon,
	GoBackArrow,
} from "../assets/icons";
import { ShowWhenTrue } from "../../../helpers";
import { InPageSpinner } from "../../../components/spinners/in-page-spinner";
import { cloneDeep, omit } from "lodash";
import classNames from "classnames";
import { RasterPlot } from "./raster-plot";
import { RasterConfig } from "@api/analytics-handler";
import { RasterPlotFailureMessages } from "../enums";
import { TooltipTop } from "@components/tooltips";

type GraphCardProps = GraphInfo & {
	graphIdentifier: string;
	removeGraph: (arg0: string) => any;
	setGraphRef: (
		graphIdentifier: string,
		graphRef: React.RefObject<ReactEcharts>
	) => any;
	showPlotSelectionModalFromGraphCard: (
		arg0: PlotSelectionFormInfo,
		arg1: RasterConfig | null | 'new',
		graphIdentifier?: string
	) => any;
	showTiffColorPickerFromGraphCard: (
		graphIdentifier: string,
		rasterConfigId: string,
		tiffColorPlotInfo: TiffColorPlotInfo
	) => any;
	resizeGraph: (arg0: string, fullscreen?: boolean) => any;
	isFullScreenActive: boolean;
	updateGraph: (
		graphIdentifier: string,
		graphInfo: Partial<GraphInfo>
	) => void;
	plotInProgress: boolean;
	isDataExplorer?: boolean;
};

export const GraphCard: React.FC<GraphCardProps> = ({
	graphIdentifier,
	componentId,
	plotData,
	state,
	updateGraph,
	removeGraph,
	setGraphRef,
	showPlotSelectionModalFromGraphCard,
	plotSelectionFormInfo,
	resizeGraph,
	isFullScreenActive,
	tiffColorPlotInfo,
	showTiffColorPickerFromGraphCard,
	plotStatementId,
	errorMessage,
	plotInProgress,
	isDataExplorer
}) => {
	const echartsRef = useRef<ReactEcharts>(null);
	// console.log(cloneDeep(omit(plotData, 'plotData')))

	// Add workflowsessioninfo into graph card data for error log modal
	const dispatch = useDispatch();
	const [dims, setDims] = useState({ height: "100%", width: "100%" });

	const [isFullScreen, setFullScreen] = useState(false);

	const fullsc = useRef<any>();
	const divHolder = useRef<any>();
	const postionHolder = useRef<any>();
	const backdrop = useRef<any>();
	const parentNode = useRef<any>();

	useEffect(() => {
		setGraphRef(graphIdentifier, echartsRef);
	}, []);

	useEffect(() => {
		const div = document.createElement("div");
		div.className="dataexplorer__model";

		const divBackdrop = document.createElement("div");
		divBackdrop.className="dataexplorer__backdrop";

		if(isDataExplorer) {
			fullsc.current = div;
			backdrop.current = divBackdrop;
			window.document.body.appendChild(div);
			window.document.body.appendChild(divBackdrop);
			return () => {
				window.document.body.removeChild(div);
				window.document.body.appendChild(divBackdrop);
			};
		}
	}, []);

	const handleToggleSpinner = (action: "show" | "hide") => {
		updateGraph(graphIdentifier, {
			state: action === "show" ? "LOADING" : "SHOW_GRAPH",
		});
	};

	const __graphData = useMemo(() => {
		if (plotData) {
			if (plotData?.plotType !== "raster") {
				// @ts-ignore
				return getGraphConfig(plotData);
			}
			return plotData;
		}

		return {};
	}, [plotData]);

	useEffect(() => {
		if (plotData?.plotType === "heatmap") {
			let width = "100%";
			let height = "100%";
			if (isFullScreenActive) {
				let [x, y] = [0, 0];
				if (plotData?.plotData) {
					// @ts-ignore
					const _a = plotData.plotData[plotData.plotData.length - 1];
					[x, y] = [_a[0], _a[1]];
				}
				// MANUALLY SET WIDTH AND HEIGHT ONLY IF NUMBER OF ROWS EXCEED 13 AMD
				// COLUMNS EXCEED 15
				// console.log(x, y)
				if (x > 15) {
					width = Math.ceil(x * 65) as any;
				}
				if (y > 13) {
					height = Math.ceil(y * 45) as any;
				}
			}

			setDims({ width, height });
		}
	}, [isFullScreenActive, plotData]);

	// console.log(JSON.stringify(__graphData))
	// actualGraphData AND __graphData are separated
	// getGraphConfig returns a new config (switching b/w fullscreen to normal)) resets the entire graph state
	// i.e user slider filter options are removed
	const actualGraphData = useMemo(() => {
		const graphDataClone = cloneDeep(__graphData);
		if (graphDataClone) {
			if (graphDataClone.plotType !== "raster") {
				if (isFullScreenActive) {
					// let leftDistance = 'auto';

					if (graphDataClone.grid) {
						graphDataClone.grid.right = "15px";
						// if(plotData?.plotType === 'heatmap') {
						//     // leftDistance = '2%';
						//     let [x, y] = [0,0]

						//     let k;
						//     if(plotData?.plotData) {
						//         // @ts-ignore
						//         const _a = plotData.plotData.pop();
						//         [x, y] = [_a[0], _a[1]]
						//     }
						//     // plotData?.plotData.forEach(element => {
						//     //     // [_xCoord, _yCoord]
						//     //     x = element[0]
						//     //     y = element[1]
						//     // });
						//     graphDataClone.grid.width = Math.ceil(x*55)
						//     graphDataClone.grid.height = Math.ceil(y*30)
						// }
						// graphDataClone.grid.left = leftDistance;
					}
				} else {
					graphDataClone.grid = omit(graphDataClone.grid, "left");
					if (plotData?.plotType === "heatmap") {
						graphDataClone.grid.containLabel = false;
						graphDataClone.series[0].label.fontSize = 6;
						if (graphDataClone.xAxis) {
							graphDataClone.xAxis.show = false;
						}
						if (graphDataClone.yAxis) {
							graphDataClone.yAxis.show = false;
						}
					}
				}
			}
		}
		return graphDataClone;
	}, [isFullScreenActive, __graphData, plotData]);
	useEffect(() => {
		// full screen - set left to 3%
		if (
			echartsRef.current &&
			plotData &&
			(plotData.plotType === "map" || plotData.plotType === "geospatial")
		) {
			(echartsRef.current as any).echartsLib.registerMap(
				"world",
				worldJson
			);
		}
	}, [plotData, echartsRef.current]);

	const graphRoam1 = (param: any, echarts: any) => {
		(echartsRef.current as any)
			?.getEchartsInstance()
			.setOption(echarts._api.getOption(), true);
	};

	const handleShowTiffColorPickerModal = (rasterConfigId: string) => {
		tiffColorPlotInfo[rasterConfigId] &&
			showTiffColorPickerFromGraphCard(
				graphIdentifier,
				rasterConfigId,
				tiffColorPlotInfo
			);
	};

	const handleShowRasterConfigSelectionModal = (
		rasterConfigId: string | null
	) => {
		const rasterConfig = rasterConfigId ? plotSelectionFormInfo.rasterInfo?.raster_configs.find(
			(raster) => raster.id === rasterConfigId
	  ): null
		showPlotSelectionModalFromGraphCard(plotSelectionFormInfo, rasterConfig || 'new', graphIdentifier);
	};

	const handleDeleteRaster = (rasterConfigId: string) => {
		const __plotData = cloneDeep(plotData);
		const __plotSelectionFormInfo = cloneDeep(plotSelectionFormInfo);
		if (__plotData?.raster_info) {
			if (__plotData.raster_info.raster_configs.length === 2) {
				__plotData.raster_info.raster_configs = __plotData?.raster_info?.raster_configs.filter(
					(config) => config.id !== rasterConfigId
				);
				__plotData.tiffFilePath = omit(
					__plotData.tiffFilePath,
					rasterConfigId
				);
				__plotSelectionFormInfo.rasterInfo = cloneDeep(__plotData.raster_info)
				updateGraph(graphIdentifier, {
					tiffColorPlotInfo: omit(tiffColorPlotInfo, rasterConfigId),
					plotData: __plotData,
					plotSelectionFormInfo: __plotSelectionFormInfo
				});
			} else {
				removeGraph(graphIdentifier);
			}
		}
		handleResizeGraph();
	};

	const handleToggleRasterVisibility = (rasterConfigId: string) => {
		const updatedTiffColorPlotInfo: TiffColorPlotInfo = {
			...tiffColorPlotInfo,
			[rasterConfigId]: {
				...tiffColorPlotInfo[rasterConfigId],
				hidden: !tiffColorPlotInfo[rasterConfigId].hidden,
			},
		};
		updateGraph(graphIdentifier, {
			tiffColorPlotInfo: updatedTiffColorPlotInfo,
		});
	};

	const renderGraph = useCallback(
		(showGraph: boolean) => {
			return (
				showGraph && (
					<ReactEcharts
						ref={echartsRef}
						option={actualGraphData}
						onEvents={{
							georoam: graphRoam1,
							// 'datazoom': datazoom1
						}}
						style={dims}
						notMerge
					/>
				)
			);
		},
		[actualGraphData, isFullScreenActive, dims, tiffColorPlotInfo]
	);

	const handleRemoveGraph = () => {
		if(isDataExplorer && isLoading) return;
		removeGraph(graphIdentifier);
	};

	const handleShowPlotSelectionModal = () => {
		let rasterConfig = null;
		if(plotSelectionFormInfo.plot_type === 'raster' && plotSelectionFormInfo.rasterInfo?.raster_configs) {
			// Automatically select first raster config on clicking the icon on the graph card
			rasterConfig = plotSelectionFormInfo.rasterInfo.raster_configs[0]
		}
		showPlotSelectionModalFromGraphCard(plotSelectionFormInfo, rasterConfig, graphIdentifier);
	};

	const handleResizeGraph = () => {
		if(isDataExplorer) {
			if(isFullScreen) {
				setFullScreen(false);
				(document.getElementById("middle_container") as any).style.display = '';
				(document.getElementById("right-container") as any).classList.remove("full");
				(document.getElementById("pid_" + graphIdentifier) as any).classList.remove("show");
				resizeGraph(graphIdentifier, false);
			} else {
				setFullScreen(true);
				(document.getElementById("middle_container") as any).style.display = 'none';
				(document.getElementById("right-container") as any).classList.add("full");
				(document.getElementById("pid_" + graphIdentifier) as any).classList.add("show");
				resizeGraph(graphIdentifier, true);
			}
		} else {
			resizeGraph(graphIdentifier);
		}
	};

	const showErrormodal = () => {
		dispatch(setPlotStatmentInfoForErrorModal(plotStatementId));
		dispatch(toggleAnalyticsModal("errorLogs", true));
	};

	const handleShowRasterError = (errorMessage: RasterPlotFailureMessages) => {
		updateGraph(graphIdentifier, {
			state: 'ERROR',
			errorMessage
		});
	}

	const showGraph = state === "SHOW_GRAPH";
	const isLoading = state === "LOADING";
	const isPlotSelectionDisabled = isLoading || plotInProgress

	return (
		<div
			ref={divHolder}
			className={classNames("innerPlotBox", {
				enableScroll: isFullScreenActive && state !== "LOADING",
			})}
		>
			<div className="workflowCanvas__header">
				{isFullScreenActive && !isDataExplorer && (
					<button
						className="btn-sm btn-grey btn-goBackWithArrow"
						onClick={handleResizeGraph}
					>
						<GoBackArrow />
						Back
					</button>
				)}
				
				{!isDataExplorer && <span className="react-grid-dragHandle workflowName">
					{WorkflowCanvas.getNodeTitle(componentId)}
					</span>
				}
				{
					isFullScreen? null : (
						<>
							<button
								onClick={handleShowPlotSelectionModal}
								id={`${graphIdentifier}_options`}
								disabled={isPlotSelectionDisabled}
							>
								<TooltipTop overlay={isPlotSelectionDisabled ? "Plot in progress":""}>
									<span className={classNames("graphCardIcon skeletonIcon-Graph", { disabled: isPlotSelectionDisabled })}>
										<SettingsIcon />
									</span>
								</TooltipTop>
							</button>
							<div className="separator" />
							{
								isDataExplorer? null : (
									<button
										className=" react-grid-dragHandle graphCardIcon"
										id={`${graphIdentifier}_move`}
									>
										<MoveIcon />
									</button>
								)
							}
						</>
					)  
				}
				
				<button
					onClick={handleResizeGraph}
					className="graphCardIcon"
					id={`${graphIdentifier}_${
						isFullScreenActive ? "minimize" : "maximize"
					}`}
				>
					{isFullScreenActive || isFullScreen ? (
						<RestoreIcon />
					) : (
						<GoToFullScreenIcon />
					)}
				</button>
				{
					isFullScreen? null : (
						<button
							onClick={handleRemoveGraph}
							className="graphCardIcon"
							id={`${graphIdentifier}_close`}
						>
							<CloseGraphIcon />
						</button>
					)
				}
			</div>
			<ShowWhenTrue show={isLoading}>
				<div className={classNames("spinnerDiv", {
					"center": isDataExplorer
				})}>
					<InPageSpinner />
				</div>
			</ShowWhenTrue>
			<ShowWhenTrue show={state === "ERROR"}>
				<div className="graph_error_wrapper">
					<div className="graph_error">
						<div>
							<img
								src="/icons/Bitmap.png"
								style={{ marginLeft: 15 }}
							/>
						</div>
						{plotData?.plotType === 'raster' && errorMessage !== null ?
							<>
							<span>Oops, An error occured.</span>
							{!isDataExplorer &&<div>
								<span
									className="error-link"
									onClick={showErrormodal}
								>
									<u>View Error Logs</u>
								</span>
							</div>}
						</>
							:
							<>
								<span>Oops, An error occured.</span>
								{!isDataExplorer &&<div>
									<span
										className="error-link"
										onClick={showErrormodal}
									>
										<u>View Error Logs</u>
									</span>
								</div>}
							</>
						}	
					</div>
				</div>
			</ShowWhenTrue>
			{plotSelectionFormInfo?.plot_type === "raster" &&
			tiffColorPlotInfo ? (
				<RasterPlot
					rasterPlotsInfo={plotData?.raster_info || null}
					tiffFilePath={plotData?.tiffFilePath || null}
					isFullScreenActive={isFullScreenActive}
					tiffColorPlotInfo={tiffColorPlotInfo}
					handleToggleSpinner={handleToggleSpinner}
					isSpinnerActive={!showGraph}
					graphId={graphIdentifier}
					plotSelectionFormInfo={plotSelectionFormInfo}
					showColorPicker={handleShowTiffColorPickerModal}
					showPlotSelectionModal={
						handleShowRasterConfigSelectionModal
					}
					deleteRaster={handleDeleteRaster}
					handleToggleVisibility={handleToggleRasterVisibility}
					handleShowRasterError={handleShowRasterError}
					isErrorState={state === 'ERROR'}
					switchToFullScreen={handleResizeGraph}
					isDataExplorer={isDataExplorer}
				/>
			) : (
				renderGraph(showGraph)
			)}
		</div>
	);
};
