import { LoginPostData, LoginResponse, SignupPostData, UpdatePasswordData, AllUsersInfo, ForgotPasswordData, ValidateTokenResponse, ResetPasswordData, ValidateUsernameData, ValidateUsernameResponse, UpdateProfile, UserInfo, CreateUserData, CrateGitRepoResponseTypes, GitRepoTypes, ActiveSessionsInfo } from './types';
import { HttpClient } from '../services';
import { API } from '../../constants/settings';
import { errorHandlerCallback } from '../services/httpinvoker';/* eslint-disable-line */
import { ApiPromise, ApiResponse } from '../data-source-handler';

class UserHandlerClass {
    Login(link: string, data: LoginPostData, callback: (arg0: LoginResponse) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Login', link +  '/auth/signin', data, callback, errorHandlerCallback);
    }

    // GetConcurrentUser(cb: ApiResponse<any>) {
    //     HttpClient.Get('Get concurrent users', API.userManagementUrl + '/client/datastudio-public/users/active-sessions', cb);
    // }
    
    Signup(link: string, data: SignupPostData, callback: (arg0: any) => void) {
        HttpClient.Post('Sign up', link + '/auth/signup', data, callback);
    }

    ValidateUsername(link: string, data: ValidateUsernameData, callback: ApiResponse<ValidateUsernameResponse>) {
        HttpClient.Post('Sign up', link + '/auth/usernames', data, callback);
    }

    // GetUserInfo(callback: ApiResponse<UserInfo>, errorCallback: (arg0: AxiosResponse) => void){
    //     HttpClient.Get('Get user info', API.url + '/user/me', callback, errorCallback);
    // }

    UpdatePassword(link: string, data: UpdatePasswordData, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Update Password', link +  '/user/updatePassword', data, callback, errorHandlerCallback);
    }

    // UpdateProfile(data:UpdatePasswordData);
    UpdateProfile(link: string, data: UpdateProfile, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Update Profile', `${link}/user/updateProfile`, data, callback, errorHandlerCallback);
    }

    GetAllUsers(link: string, callback: (arg0: AllUsersInfo) => any){
        HttpClient.Get('Get all users', link + '/users', callback);
    }

    GetUserInfo(link: string, userId: string, callback: ApiResponse<UserInfo>, errorHandlerCallback: ApiResponse<any>){
        HttpClient.Get('Get all users', link + '/users/' + userId, callback, errorHandlerCallback);
    }

    GetAllRoles(link: string, callback: ApiResponse<any>) {
        HttpClient.Get('Get all roles', link + '/roles', callback);
    }

    ForgotPassword(link: string, data: ForgotPasswordData, callback: any, errorHandlerCallback: errorHandlerCallback) {
        HttpClient.Post('Send forgot password email', link + '/auth/forgot-password', data, callback, errorHandlerCallback);
    }

    ValidateToken(link: string, token: string): ApiPromise<ValidateTokenResponse>{
        return HttpClient.PostPromise(link + '/auth/validate-token', { token });
    }

    ResetPassword(link: string, data: ResetPasswordData, callback: any, errorHandlerCallback: errorHandlerCallback) {
        HttpClient.Post('Reset password', link + '/auth/reset-password', data, callback, errorHandlerCallback);
    }

    InitialCall(link: string, cb: ApiResponse<any>) {
        // to create default directory in the backend
        HttpClient.Get('Initial Call', link + '/v2/load-data', cb, cb);
    }

    // CreateUser(data: CreateUserData, cb: ApiResponse<any>, errorCb: errorHandlerCallback) {
    //     HttpClient.Post('Create user', API.userManagementUrl + '/users', data, cb, errorCb);
    // }

    UpdateUser(link: string, userId: string, data: CreateUserData, cb: ApiResponse<UserInfo>, errorCb: errorHandlerCallback) {
        HttpClient.Put('Create user', link + '/users/' + userId, data, cb, errorCb);
    }   

    GetGitRepoDetails(link: string, callback: ApiResponse<any>) {
        HttpClient.Get('Get git repo details', link + '/api/repo/', callback);
    }

    GetPlatformList(link: string, callback: ApiResponse<any>) {
        HttpClient.Get('Get git repo details', link + '/api/repo/platforms', callback);
    }

    DeleteGitRepoDetails(link: string, userId: number, callback: ApiResponse<CrateGitRepoResponseTypes>) {
        HttpClient.Delete('Delete git repo details', link + `/api/repo/${userId}`, callback);
    }

    CreateGitRepo(link: string, data: GitRepoTypes, cb: ApiResponse<CrateGitRepoResponseTypes>, errorCb: errorHandlerCallback) {
        HttpClient.Put('Create git integration',link + '/api/repo/',data, cb, errorCb);
    }

    GetActiveSessions(url: string, link: any,callback: ApiResponse<ActiveSessionsInfo>) {
        HttpClient.Get('Get active sessions', `${url}/client/${link}/users/active-sessions`, callback);
    }

    GetEnvVariables(callback: ApiResponse<any>) {
        HttpClient.Get('Get env variables', `${API.azureblobUrl}/env-variables`, callback);
    }
}

const UserHandler = new UserHandlerClass();

export { UserHandler };