import { AxiosResponse } from "axios";
import { HttpClient } from "../services";
import { ApiResponse, Plot, PlotConfig } from "./types";
import { API } from "../../constants/settings";

class VisualizationHandlerClass {
	SubmitPlot(
		link: string,
		data: Plot,
		callback: ApiResponse<PlotConfig>,
		errorHandlerCallback: ApiResponse<AxiosResponse>
	) {
		HttpClient.Post(
			"Submit  Plot",
			link + "/v2/execute/plot-submit",
			data,
			callback,
			errorHandlerCallback
		);
	}
}

const VisualizationHandler = new VisualizationHandlerClass();

export { VisualizationHandler };
