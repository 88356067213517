import { AppThunk } from '../types';
import {  WorkflowCanvasTab } from '.';
import { CLOSE_CANVAS_TAB, SWITCH_TO_EXISTING_CANVAS_TAB, OPEN_A_NEW_CANVAS_TAB, 
    OpenNewCanvasTab, CloseCanvasTab, SwitchToExistingCanvasTab, UPDATE_A_CANVAS_TAB_INFO, 
    UpdateACanvasTabInfo, CLOSE_ALL_CANVAS_TABS, CloseAllCanvasTabs, WorkflowAnalyticsTab, 
    TabTypes, OpenTabsType, UpdateAllOpenTabs, UPDATE_ALL_OPEN_TABS, WorkflowCanvasTabInfo,
    SET_CODE_VIEW,
    PageTypes,
    ResetActiveTab,
    RESET_ACTIVE_TAB,
    WorkflowEditorTypes,
    CanvasTabTypes,
    WorkflowVisualizationTypes
} from './types';
import { DetailedWorkflowInfo } from '../../api/workflow-handler';
import { Env } from '../../constants/settings';
import { checkIfCurrentWorkflowHasHelpDocs } from '../help';
import { ExecutionEnvModes } from '../../constants/enums';
import { WorkflowConfig } from '@services/WorkflowConfig';

export const openANewCanvasTab = (page: OpenNewCanvasTab['page'], tabInfo: TabTypes): OpenNewCanvasTab => ({ type: OPEN_A_NEW_CANVAS_TAB, page, tabInfo });

export const switchToExistingCanvasTab = (page: OpenNewCanvasTab['page'], id: string | number): SwitchToExistingCanvasTab => 
    ({ type: SWITCH_TO_EXISTING_CANVAS_TAB, page, id });
    
export const closeCanvasTab = (page: OpenNewCanvasTab['page'], id: string | number, openPenultimateTab: boolean, filterTabsFn?: CloseCanvasTab['filterTabsFn']): CloseCanvasTab => 
    ({ type: CLOSE_CANVAS_TAB, page, id, openPenultimateTab, filterTabsFn });

export const updateACanvasTabInfo = (page: OpenNewCanvasTab['page'], tabInfo: TabTypes): UpdateACanvasTabInfo => 
    ({ type: UPDATE_A_CANVAS_TAB_INFO, page, tabInfo });

export const closeAllTabsOfPage =(page: OpenNewCanvasTab['page']): CloseAllCanvasTabs => 
    ({ type: CLOSE_ALL_CANVAS_TABS, page });

export const closeAllCanvasTabs = (): CloseAllCanvasTabs => 
    ({ type: CLOSE_ALL_CANVAS_TABS });

export const updateOpenTabsOfACanvas = (page: OpenNewCanvasTab['page'], updatedTabs: OpenTabsType): UpdateAllOpenTabs => 
    ({ type: UPDATE_ALL_OPEN_TABS, page, updatedTabs });

export const switchCodeAndDiagram = (open: boolean, notebook: string)=> {
    return ({ type: SET_CODE_VIEW , page: {open, notebook} });
};

export function getTabType(page: 'workflowEditor', tabTypeIdentifier: ExecutionEnvModes): WorkflowEditorTypes;
export function getTabType(page: 'analytics', tabTypeIdentifier: ExecutionEnvModes): WorkflowVisualizationTypes;
export function getTabType(page: PageTypes, tabTypeIdentifier: ExecutionEnvModes): CanvasTabTypes;
export function getTabType(page: PageTypes, tabTypeIdentifier: ExecutionEnvModes): CanvasTabTypes {
    if(page === 'workflowEditor' || tabTypeIdentifier === ExecutionEnvModes['Pipelines']) {
        const workflowEditorTabTypes = {
            [ExecutionEnvModes['Spark']]: WorkflowEditorTypes['WORKFLOW_SPARK'], 
            [ExecutionEnvModes['Python']]: WorkflowEditorTypes['WORKFLOW_PYTHON'], 
            [ExecutionEnvModes['Pipelines']]: WorkflowEditorTypes['WORKFLOW_PIPELINES'],
            [ExecutionEnvModes['Streaming']]: WorkflowEditorTypes['WORKFLOW_STREAMING']
        };
        return workflowEditorTabTypes[tabTypeIdentifier];
    } else {
        const workflowVisualizationTabTypes = {
            [ExecutionEnvModes['Spark']]: WorkflowVisualizationTypes['WORKFLOW_VISUALIZATION_SPARK'], 
            [ExecutionEnvModes['Python']]: WorkflowVisualizationTypes['WORKFLOW_VISUALIZATION_PYTHON'], 
            [ExecutionEnvModes['Streaming']]: WorkflowVisualizationTypes['WORKFLOW_VISUALIZATION_STREAMING'], 
        };
        return workflowVisualizationTabTypes[tabTypeIdentifier];
    }
}
// ALL WORKFLOW-EDITOR TABS

export const openANewWorkflowEditorTab = (tabInfo: WorkflowCanvasTabInfo) => 
    openANewCanvasTab('workflowEditor', { 
        info: tabInfo, 
        type: getTabType('workflowEditor', tabInfo.env) 
    }); 

export const updateAWorkflowEditorTabInfo = (tabInfo: WorkflowCanvasTab): UpdateACanvasTabInfo => updateACanvasTabInfo('workflowEditor' ,tabInfo);



export const openANewWorkflowEditorTabUsingWorkflowInfo = (workflow: DetailedWorkflowInfo['data'], extraInfo: Partial<WorkflowCanvasTabInfo> = {}): AppThunk => (dispatch, getState) => {
    

    const { helpDemoWorkflowsInfo } = getState().HelpReducer;
    const { envVariables: Env } = getState().AccountReducer;
    const { details, projectName, id, version, createdBy, isStarred, notes, readOnly, executable, hasVariables, componentCounter, zeppelinNotebookId, position, directoryId, env, zepplinData, created, updated, config } = workflow;
    let helpDocRef: WorkflowCanvasTabInfo['helpDocRef'] = null;
    let showHelpBulbAnimation: WorkflowCanvasTabInfo['showHelpBulbAnimation'] = false;

    if(Env?.REACT_APP_USER_NAME_DEMO_WORKFLOWS_HELP) {
        const { allUsersList } = getState().AccountReducer;
        const workflowUserInfo = allUsersList.find(user => user.email === workflow.createdBy);
        if(Env?.REACT_APP_USER_NAME_DEMO_WORKFLOWS_HELP === workflowUserInfo?.email) {
            helpDocRef = checkIfCurrentWorkflowHasHelpDocs(projectName, helpDemoWorkflowsInfo);
            showHelpBulbAnimation = !!helpDocRef;
        }
    }

    let workflowConfig: WorkflowConfig = [];

    try {
        // config is stored as string in db
        workflowConfig = JSON.parse(config)
    } catch {
        /* eslint-disable no-empty */
    }   

    const tabInfo: WorkflowCanvasTabInfo = { 
        name: projectName, 
        details, 
        id, 
        version, 
        saved: true, 
        createdBy, 
        isStarred, 
        notes,
        directoryId,
        workflowPositionInList: position, 
        isExecutable: executable, 
        isReadOnly: readOnly, 
        hasVariables, 
        activeComponentInfo: null,
        env,
        componentCounter: componentCounter || 0,
        zeppelinNotebookId,
        showCodeEditor: false,
        showHelpBulbAnimation,
        helpDocRef,
        scheduleInfoForDag: null,
        activeClusterInfoForZeppelin: null,
        zepplinData : zepplinData || [],
        isPreviewSessionCreationInProgress: false,
        created,
        updated,
        config: workflowConfig,
        ...extraInfo 
    };

    return dispatch(openANewCanvasTab('workflowEditor', { info: tabInfo, type: getTabType('workflowEditor', env) })); 
};

export const closeWorkflowEditorAnalyticsTab = (page: PageTypes, tabId: number): AppThunk => (dispatch, AppState) => {
    let openPenultimateTab = true;
    let filterTabsFn: CloseCanvasTab['filterTabsFn'];
    const { CommonReducer, CanvasReducer } = AppState();

    const activePageTabs = CanvasReducer[page].openTabs as OpenTabsType;
    const activeTabType = getTabType(page, CommonReducer.activeExecutionEnv);
    const activePageTabsList = Array.from(activePageTabs.values()).filter(tab => tab.type === activeTabType);
    // 1 since currentTab is not closed yet
    // In WorkflowEditor/Analytics - Spark and Python env are shown separately on the basis of toggle
    // In spark mode, if user closes a spark tab, tab that has to be opened should be of type spark
    if(activePageTabsList.length === 1) openPenultimateTab = false;
    else filterTabsFn = tab => tab.type === activeTabType;
    dispatch(closeCanvasTab(page, tabId, openPenultimateTab, filterTabsFn));
};


// ALL WORKFLOW-EDITOR TABS

export const openANewWorkflowAnalyticsTab = (workflowInfo: WorkflowAnalyticsTab['info']): AppThunk => (dispatch) => {
    dispatch(openANewCanvasTab('analytics', { info: workflowInfo, type: getTabType('analytics', workflowInfo.env) } as unknown as TabTypes)); 
}; 
    

export const updateAWorkflowAnalyticsTabInfo = (tabInfo: WorkflowAnalyticsTab): UpdateACanvasTabInfo => updateACanvasTabInfo('analytics' ,tabInfo);


export const resetActiveTab = (page: PageTypes): ResetActiveTab => ({ type: RESET_ACTIVE_TAB, page });