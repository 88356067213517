import { HttpClient } from '../services';

import { API } from '../../constants/settings';
import { BriefClusterInfo, ClusterLibraryInfo, InstallClusterInfo, ScheduleClusterApiData } from '.';
import { ApiResponse } from '../data-source-handler';
import { AxiosResponse } from 'axios';
import { AccessNodeType, Cluster, ClusterDetail, ClusterModeType, DataBricksVersionListType, NodeType, UsersType } from './types';

class DatabricksHandlerClass {
    
    CreateNewCluster(link: string, newCluster: any, callback: (cluster: Cluster) => any, errorHandlerCallback: any) {
        HttpClient.Post('Create a Cluster', link + '/cluster/create', newCluster, callback, errorHandlerCallback);
    }

    GetAllClusterList(link: string, callback: (arg0: [Cluster]) => any) {
        HttpClient.Get('Get all cluster list', link + '/cluster/list', callback);
    }
    
    GetClusterMode(link: string, callback: (nodeTypeList: ClusterModeType[] ) => any) {
        HttpClient.Get('Get cluster mode', link + '/cluster/listclustermodes', callback);
    }

    GetAccessNode(link: string, callback: (nodeTypeList: AccessNodeType[] ) => any) {
        HttpClient.Get('Get access mode', link + '/cluster/get-access-modes', callback);
    }

    GetUsersList(link: string, callback: (nodeTypeList: UsersType[] ) => any) {
        HttpClient.Get('Get users list', link + '/scim/current-user', callback);
    }
    
    // GetSparkVersionList( callback: (arg0: []) => any, errorHandlerCallback?: (arg0: any) => void) {
    //     HttpClient.Get('Get all cluster list', API.azureUrl + '/clusters/databricks/listsparkversions', callback,errorHandlerCallback);
    // }
    GetNodeTypeList (link: string, callback: (nodeTypeList: NodeType[] ) => any, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Get('Get all cluster list', link + '/cluster/listnodetypes', callback,errorHandlerCallback);
    }
    
    DeleteCluster(link: string, _clusterId: any, callback: (arg0: []) => any, errorCb?: ApiResponse<any>) {
        HttpClient.Delete('Get all cluster list', link + `/cluster/delete?clusterId=${_clusterId}`, callback, errorCb);
    }
    
    TerminateCluster(link: string, _clusterId: any, callback: (arg0: []) => any) {
        HttpClient.Get('Terminating a Cluster', link + `/cluster/terminate?clusterId=${_clusterId}`, callback);
    }

    RestartCluster(link: string, _clusterId: any, callback: (arg0: []) => any) {
        HttpClient.Get('Restarting cluster', link + `/cluster/restart?clusterId=${_clusterId}`, callback);
    }

    startCluster(link: string, _clusterId: any, callback: (arg0: []) => any) {
        HttpClient.Get('Starting Cluster', link + `/cluster/start?clusterId=${_clusterId}`, callback);
    }

    UpdateClusterDefaultStatus(link: string, _clusterId: string, defaultCluster: boolean, callback: (arg0: []) => any) {
        const payload = { clusterId: _clusterId, defaultCluster: defaultCluster};
        HttpClient.Post('Change Cluster Default Status', link + '/cluster/default', payload, callback);
    }

    // cloneCluster(_clusterId: any, callback: (arg0: []) => any) {
    //     HttpClient.Get('Get all cluster list', API.azureUrl + `/clusters/databricks/start?clusterId=${_clusterId}`, callback);
    // }

    GetCluster(link: string, _clusterId: string, callback: (clusterDetail: ClusterDetail) => any) {
        HttpClient.Get('Get cluster details', link + `/cluster/get?clusterId=${_clusterId}`, callback);
    }

    GetDataBricksRunTimeVersion(link: string, callback: (nodeTypeList: DataBricksVersionListType[] ) => any) {
        HttpClient.Get('Get databricks runtime version', link + '/cluster/listsparkversions', callback);
    }
    // GetAllRunningClusterList(type: string, status: string, callback: (arg0: []) => any) {
    //     HttpClient.Get('Get all cluster list', API.url + '/clusters/listbytype/' + type + '/' + status, callback);
    // }
    // Getrecentcluster(callback: (arg0: []) => any) {
    //     HttpClient.Get('Get all cluster list running', API.url + '/clusters/recent/databricks/running', callback);
    // }
    UpdateCluster(link: string, data: Omit<ClusterDetail, 'modelType' >, callback: (cluster: Cluster) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Put('Update Cluster Details', link + '/cluster/update', data, callback, errorHandlerCallback);
    }

    // ScheduleCluster(data: ScheduleClusterApiData, callback: ApiResponse<any>) {
    //     HttpClient.Post('Schedule cluster', API.azureUrl + '/clusters/databricks/schedule', data, callback);
    // }

    // UnScheduleCluster(data: BriefClusterInfo, callback: ApiResponse<any>) {
    //     HttpClient.Post('Schedule cluster', API.azureUrl + '/clusters/databricks/unschedule', data, callback);
    // }


    GetClusterLibrariesList(link: string, clusterId: string, callback: ApiResponse<ClusterLibraryInfo[]>) {
        HttpClient.Get('Get Cluster Libraries', link + '/library/status?clusterId=' + clusterId, callback);
    }

    InstallLibrariesOnCluster(link: string, data: InstallClusterInfo, callback: ApiResponse<any>, errorHandlerCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Install Libraries on Cluster',link + '/library/install', data, callback, errorHandlerCallback);
    }

    UninstallLibrariesOnCluster(link: string, data: InstallClusterInfo, callback: ApiResponse<any>, errorHandlerCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Uinstall Libraries on Cluster', link + '/library/uninstall', data, callback,errorHandlerCallback);
    }

    ClusterDetails(link: string, data: Omit<ClusterDetail, 'modelType' >, callback: ApiResponse<any>, errorHandlerCallback: ApiResponse<AxiosResponse>) { 
        HttpClient.Post('Get Cluster details', link + '/cluster/create/request', data, callback,errorHandlerCallback);
    }
    
    GetAllDatabricksClusterList(link: string, callback: ApiResponse<Cluster[]>) {
        HttpClient.Get('Get all cluster list', link + '/cluster/databricks-clusters', callback);
    }
}

const NewDatabricksHandlerClass = new DatabricksHandlerClass();

export { NewDatabricksHandlerClass };