import moment from 'moment-mini';
import _ from 'lodash';
import queryString from 'query-string';
import momentTimezone from 'moment-timezone';
import { JobsExecutionModes } from '../constants/enums';
import { API } from '../constants/settings';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

export function withoutOpacity(color: any) {
    return `rgba(${color}, 1)`;
}

export function withOpacity(color: any) {
    return `rgba(${color}, 0.5)`;
}


export function getColor(name: any) {
    if (name === 'hourly') {
        return '185,68,89';
    } else if (name === 'daily') {
        return '0,200,150';
    } else if (name === 'monthly') {
        return '208,156,48';
    } else if (name === 'weekly') {
        return '46,91,255';
    }
}

let count = 0;

export function getColorMapping(dbName: any) {/* eslint-disable-line */

    const colors = [
        '18, 45, 68',
        '36, 107, 142',
        '65, 148, 133',
        '145, 178, 82',
        '225, 178, 24',
        '240, 144, 34',
        '181, 50, 42',
        '0, 51, 25',
        '86, 24, 175',
        '50, 0, 102',
        '100, 32, 32',
        '153, 10, 0',
        '102, 0, 51',
        '153, 0, 0',
        '50, 0, 50',
        '106, 115, 152'
    ];

    // this will just return a string not rgba we have to formulate it
    return colors[count++ % 10];
}


export enum IngestionTime {
    ThisHour = 1,
    Last24Hour,
    Today,
    Last7Days,
    Month,
    All
}

export function getTime(type: IngestionTime) {
    let time;
    switch (type) {
        case IngestionTime.All:
            time = moment('1995-12-25');
            break;
        case IngestionTime.Today:
            time = moment().startOf('day');
            break;
        case IngestionTime.Last7Days:
            time = moment().subtract(7, 'days');
            break;
        case IngestionTime.Month:
            time = moment().startOf('month');
            break;
        case IngestionTime.ThisHour:
            time = moment().startOf('hour');
            break;
        case IngestionTime.Last24Hour:
            time = moment().subtract({ hour: 24 });
            break;
    }

    return time.toDate().getTime();
}

export function plainToFlattenObject(object: Record<any, any>): Record<string, any> {
    const result = {};

    function flatten(obj: Record<any, any>, prefix = '') {
        _.forEach(obj, (value, key) => {
            if (_.isObject(value)) {
                flatten(value, `${prefix}${key}.`);
            } else {
            // @ts-ignore
                result[`${prefix}${key}`] = value;
            }
        });
    }

    flatten(object);

    return result;
}

export const stringifyQueryParams = (queryParams: Record<string, any>) => '?' + queryString.stringify(queryParams);

export const getCSTTime = (timeString: string) =>
    momentTimezone.tz(timeString, 'America/Chicago');


export const getCSTTimeString = (timeString: string, format = 'LLL') =>
    getCSTTime(timeString).format(format);

export const getTimeStringUtcLocal = (timeString: string, utc: boolean) =>
    utc ? moment(timeString).utc().format('LLL'):  moment(timeString).format('LLL');


export function getContextMenuPostion(event: React.MouseEvent<HTMLElement>, contextMenu: HTMLDivElement) {

    const mousePosition = {x: 0, y: 0};
    const menuPostion = {x: 0, y: 0};
    const menuDimension = {x: 0, y: 0};

    menuDimension.x = contextMenu.offsetWidth;
    menuDimension.y = contextMenu.offsetHeight;
    mousePosition.x = event.pageX;
    mousePosition.y = event.pageY;


    if (mousePosition.x + menuDimension.x > window.innerWidth + window.pageXOffset ) {
        menuPostion.x = mousePosition.x - menuDimension.x;
    } else {
        menuPostion.x = mousePosition.x;
    }

    if (mousePosition.y + menuDimension.y > window.innerHeight + window.pageYOffset) {
        menuPostion.y = mousePosition.y - menuDimension.y;
    } else {
        menuPostion.y = mousePosition.y;
    }

    return menuPostion;
}

export const getJobExecutionDomain = (type: JobsExecutionModes) => {
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    return type === JobsExecutionModes.livy ? `${Env.REACT_APP_PLATFORM_URL}/platform/api` : `${Env.REACT_APP_PLATFORM_URL}/databrowser/api`;
};
export const isMac = () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);


export const downloadFileUsingLink = (url: string, fileName: string) => {
    return new Promise<void>((resolve, reject) => {
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', fileName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        resolve();
    });
};