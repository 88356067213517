import React, {useEffect, useState} from 'react';
import { InPageSpinner } from '@components/spinners/in-page-spinner';
import styles from './../styles.module.scss';
import classNames from 'classnames';
import { StateData, TableData } from '../types';
import {TiWarningOutline} from 'react-icons/ti'
import { DataTable, PlusIcon, MinusIcon, PauseTable, MetaDataIcon } from '../icons';
import { DataSourceHandler } from '@api/data-source-handler';
import { TooltipTop } from '@components/tooltips';
import { TbRefresh } from 'react-icons/tb';
import { HttpClient } from '@api/services';
// import { Env } from '@constants/settings';
import { TiTick } from 'react-icons/ti';
import { errorAlert, infoAlert } from '@components/toastify/notify-toast';
import { Modal } from '@components/modals';
import MonacoEditor from "react-monaco-editor";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/monaco.contribution';
import 'monaco-editor/esm/vs/editor/edcore.main';
import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
import 'monaco-editor/esm/vs/editor/standalone/browser/standaloneServices';
import 'monaco-editor/esm/vs/language/json/monaco.contribution';
import { useDataExplorerContext } from '../useDataExplorerContext';
import Form, { SelectField } from '@components/form';
import { _selectoptionType } from '@components/form/select-field';
import { BsDatabaseFillCheck } from 'react-icons/bs';
import { FaBagShopping } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';


export const LeftContainer: React.FC<{
    isLoading: boolean,
    mainData: StateData,
    setTableSchemaData: any
}> = ({
	isLoading,
    mainData,
    setTableSchemaData,
}) => {
    const [showRulePopup, setShowRulePopup] = useState(false);
    
    const { database, engine, sendDataExplorerReducerCmd} = useDataExplorerContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [tdata, setTableData] = useState<TableData[]>([]);
    const [isIndexed, setIndexed] = useState<{"unique_col_val":boolean,"db_config":boolean, unique_values: number} | false>(false);
    const [_mainData, setData] = useState<StateData>();
    const location = useLocation();
    const isDrillingDataExplorer = location.pathname.includes('data-explorer-drilling-app');
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    const getData = async (id: number) => {
        try{
            setLoading(true);
            setError('');
            // const resp = await DataSourceHandler.GetDatabaseTableWithSchema(id)
            const resp = await DataSourceHandler.GetDeepSqlDatabaseTableWithSchema(Env?.REACT_DEEP_SQL_URL, id)
            const results = resp.data.map((item: any) => {
                return{
                    ...item,
                    tableName: item.name,
                    columnsMetaData: [],
                    foreignKeys:[],
                    primaryKeys:[],
                }
            })
            setTableData(results);
            setTableSchemaData(results)
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError('Internal error');
        }
    }

    const getIndex = async (id: number) => {
        setIndexed(false)
        try{
            const temp = await DataSourceHandler.GetSchemaIndex(Env?.REACT_DEEP_SQL_URL, id);
            setIndexed(temp.data)
        } catch(e) {
            setIndexed(false)
        }
    }

    useEffect(() => {
        setData(mainData)
    }, [mainData])

    useEffect(() => {
        if(isDrillingDataExplorer && !isEmpty(_mainData?.options)){
            sendDataExplorerReducerCmd({
                type: "SELECTED_DATABASE",
                payload: {
                    id: mainData?.options?.[1]?.value,
                    name: mainData?.options?.[1]?.label,
                    databaseName:mainData?.options?.[1]?._extra.name,
                    dbType:mainData?.options?.[1]?.db_type,
                    tableId:mainData?.options?.[1]?._extra.id
                }
            });
            getData(mainData?.options?.[1]?._extra.id)
        }
    
    },[_mainData?.options])

    const onRefresh = async (e: any) => {
        if(loading) return;
        e.preventDefault();
        e.stopPropagation();
        try{
            if(engine === "RULE" || engine === "GenerativeAI" || engine === "DeepQuery6" || engine === "GENAI") {
                setShowRulePopup(true);
            } else if(engine === "RESDSQL") {
                setLoading(true);
                infoAlert(`Database "${database.name}" sync has started`);
                await DataSourceHandler.GetDeepSqlDatabaseTableWithSchema(Env?.REACT_DEEP_SQL_URL, database.tableId);
                await HttpClient.PostPromise(`${Env?.REACT_TEXT_SQL_URL}/schema/custom/from_jdbc/${database.id}`, {});
                infoAlert(`Database "${database.name}" sync has completed`);
                setLoading(false);
            } else {
                setLoading(true);
                await DataSourceHandler.GetDeepSqlDatabaseTableWithSchema(Env?.REACT_DEEP_SQL_URL, database.tableId);
                setLoading(false);
            }
        } catch(e) {
            setLoading(false);
        }
        return false;
    };

    const onDataBaseRefresh = async (e: any)=>{
        if(loading) return;
        e.preventDefault();
        e.stopPropagation();
        try{
            setLoading(true);
            const result = await DataSourceHandler.GetDeepSqlDatabaseTableWithSchema(Env?.REACT_DEEP_SQL_URL, database.tableId);
            infoAlert(result.data);
            setLoading(false);
        } catch(e) {
            setLoading(false);
        }
        return false;
    };

    const onConfigChange = async (config:any) => {
        try{
            setLoading(true);
            infoAlert(`Database "${database.name}" sync has started`);
            await DataSourceHandler.GetDeepSqlDatabaseTableWithSchema(Env?.REACT_DEEP_SQL_URL, database.tableId);
            await HttpClient.PostPromise(`${Env?.REACT_DEEP_SQL_URL}/schema/custom/${database.id}/from_data_explorer/`, {});
            await fetch(`${Env?.REACT_DEEP_SQL_URL}/schema/custom/${database.id}/config`, {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: config
            });
            await HttpClient.PostPromise(`${Env?.REACT_DEEP_SQL_URL}/schema/custom/${database.id}/unique-value-columns/?from_data_explorer=true`, {});
            infoAlert(`Database "${database.name}" sync has completed`);
            setLoading(false);
        } catch(e) {
            setLoading(false);
        }
    };

	return (
		<div className={styles["left_container"]} style={{width: '300px', maxWidth: '300px', overflowX: "auto"}}>
            <div>
                <div className={styles["page_title"]}>Data Explorer <div className={styles["beta"]}>BETA</div></div>
            </div>
            <div className={classNames(styles["m-1"], styles["overflow-x"])}>
                {
                    isLoading ? (
                        <div className={classNames("align-middle", styles["m-5"])}>
                            <InPageSpinner />
                        </div>
                    ) : (
                        <div>
                            <label style={{fontSize: '1rem'}}>Select a Knowledge Graph </label>
                            <Form
                                initialValues={{  db: isDrillingDataExplorer?_mainData?.options?.[1]?.value:''}}
                                enableReinitialize
                                onSubmit={() => { 
                                    return; 
                                }}
                                className="flex justify-between align-middle gap-1"
                            >
                                <SelectField
                                    className={classNames(styles["mb-0"], styles["w-full"])}
                                    name="db"
                                    disabled={isDrillingDataExplorer}
                                    options={_mainData?.options || []}
                                    onOptionClick={(option) => {
                                        sendDataExplorerReducerCmd({
                                            type: "SELECTED_DATABASE",
                                            payload: {
                                                id: option.value,
                                                name: option.label,
                                                databaseName: option._extra.name,
                                                dbType: option.db_type,
                                                tableId:option._extra.id
                                            }
                                        });
                                        getData(option._extra.id)
                                        // getIndex(option.value)
                                    }}
                                />
                            </Form>
                            <ul className={styles['left-list']}>
                                <li >
                                    {
                                        !loading && database.id !== -1 ? (
                                            <div className='flex justify-between mt-1' style={{
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginTop: "1rem",
                                                marginBottom: "0.2rem"
                                            }}>
                                                <div className={classNames(styles["database_name"])}>Graph</div>
                                                <div>
                                                    <TooltipTop title={"Refresh Database"} ><BsDatabaseFillCheck size='20' style={{marginRight: '0.5rem'}} onClick={onDataBaseRefresh}/></TooltipTop>
                                                    {
                                                        isIndexed ? (
                                                            <>
                                                                {
                                                                    isIndexed.db_config ? <TooltipTop title={"Database is indexed"} ><TiTick size='20' style={{color:"#539165"}}/></TooltipTop> : null
                                                                }
                                                                {
                                                                    isIndexed.unique_col_val ? <TooltipTop title={"Unique values is available \nUnique values: " + isIndexed.unique_values} ><FaBagShopping size='20'/></TooltipTop> : null
                                                                }
                                                            </>
                                                        ) : null
                                                    }
                                                    {/* <TooltipTop title={"Configure Database"} ><TbRefresh size='20' style={{marginRight: '0.5rem'}} onClick={onRefresh}/></TooltipTop> */}
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        error !== ''? <div className='flex justify-center align-center'>
                                            <TiWarningOutline size='24' color="red" />
                                            &nbsp;
                                            <span>Failed to get data</span>
                                        
                                        </div> : null
                                    }
                                    {
                                        !loading && tdata && tdata.length === 0 &&  database.id !== -1 && error === '' ? <span>No Data</span> : null
                                    }
                                    {
                                        !loading && tdata && tdata.length ? (
                                            <ul className='open'>
                                                {
                                                    tdata.map((_tdata: TableData, index: number) => {
                                                        return <TableList key={_tdata.tableName + index} tableData={_tdata} dbData={database} /> 
                                                    })
                                                }
                                            </ul>
                                        ): null
                                    }
                                    {
                                        loading ? (
                                            <div className={classNames("flex align-middle justify", styles["m-5"])} style={{ 
                                                justifyContent: "center",
                                            }}>
                                                <InPageSpinner />
                                            </div>
                                        ) : null
                                    }
                                </li>
                            </ul>
                        </div>
                    )
                }
            </div>
            <Modal
                isOpen={showRulePopup}
                toggleClose={()=>{
                    setShowRulePopup(false)
                }}
                title="Configure rule"
                subtitle=""
                showCloseMark
            >
                <div className='' style={{width: 650, height: 600}}>
                    <RulePopup 
                        dbId={database.id} 
                        configChange={onConfigChange} 
                        showPopup={setShowRulePopup} 
                    />
                </div>
            </Modal> 
        </div>
	);
};

const TableList: React.FC<{tableData: TableData,  dbData: any}> = ({tableData, dbData}) => {
    const [expand, setExpand] = useState(false);
    const { setDBTable, db_table } = useDataExplorerContext();
    return (
        <li key={tableData.tableName + dbData.id} >
            <div className={classNames({
                [styles['selected']]: db_table === tableData.tableName
            })} onClick={() => {
                
                setDBTable(tableData.tableName)
            }}>
                <div className={styles['icon-holder']}><DataTable /></div>
                <TooltipTop title={tableData.tableName} ><span className={classNames(styles['grow-1'], styles['ellipsis'])}>{tableData.tableName}</span></TooltipTop>
                {
                    expand? <span onClick={(e)=>{
                        e.stopPropagation();
                        setExpand(!expand);
                    }}><MinusIcon /></span> : <span onClick={(e)=>{
                        e.stopPropagation();
                        setExpand(!expand);
                    }}> <PlusIcon /></span>
                }
            </div>
            {
                expand && tableData.columnsMetaData.length ? (
                    <ul>
                        {
                            tableData.columnsMetaData.map((_data, index) => {
                                return (
                                    <li key={_data.name + index}>
                                        <div className={styles['icon-holder']}><PauseTable /></div>
                                        <TooltipTop title={_data.name} ><span className={classNames(styles['grow-1'], styles['ellipsis'])}>{_data.name}</span></TooltipTop>
                                        <span className={styles['bold']}>{_data.dataType}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                ): null
            }
        </li>
    );
};

const RulePopup: React.FC<{dbId: number, showPopup: any, configChange: any}> = ({dbId, showPopup, configChange}) => {
    const [showLoading, setLoading] = useState(true);
    const [config, setConfig] = useState("");
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const getSchemaData = async () => {
        if(!dbId) return;
        try{
            setLoading(true);
            const resp = await HttpClient.GetPromise(`${Env?.REACT_DEEP_SQL_URL}/schema/custom/${dbId}/config`);
            setConfig(JSON.stringify(resp.data, null, 2));
            setLoading(false)
        } catch(e) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSchemaData();
    }, [dbId])

    const onUpdate = () => {
        try{
            JSON.parse(config);
        } catch(e) {
            return errorAlert("Invalid JSON");
        }
        showPopup(false);
        configChange(config);
    };

    if(showLoading) return <div className={styles['ruleConfig']}> <InPageSpinner /> </div>;
    return (
        <>
            <MonacoEditor
                height='100%'
                width='100%'
                theme='vs-dark'
                value={config}
                language='json'
                onChange={(value) => {
                    //setSQLData(value);
                    setConfig(value)
                }}
                options={{
                    selectOnLineNumbers: true,
                    'contextmenu': false,
                    'formatOnPaste': true,
                    'accessibilitySupport': 'auto',
                    'autoIndent': 'full',
                    'highlightActiveIndentGuide': true,
                    'quickSuggestions': true,
                    formatOnType: true,
                    minimap: {
                        enabled: false,
                    },
                    fontSize: 14,
                    lineNumbersMinChars: 2,
                    lineDecorationsWidth: 3,
                    glyphMargin: false,
                    automaticLayout: true,
                    lineNumbers: 'on',
                    folding: false,
                }}
            />
            <div style={{margin: '1rem 0', float:'right'}}>
                <button  className="btn-sm btn-yellow-transparent btn__new__workflow btn__new__action" onClick={onUpdate}>
                    <span>Submit</span>
                </button>
            </div>
        </>
    );
}