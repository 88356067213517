import React, {useRef, useState, useEffect} from 'react';
import styles from './../styles.module.scss';
import classNames from 'classnames';
import {Button} from 'antd';
import { PlotSelectionFormType, PlotSelectionModal } from '@pages/workflow-analytics-page/modals/plot-selection';
import usePlotApi, { SubmitPlotDataRef } from '@pages/workflow-analytics-page/modals/plot-selection/usePlotApi';
import { AnalyticsHandler, RasterConfig } from '@api/analytics-handler';
import PlotLayout from './plotLayout';
import { useInterval } from 'rooks';
import { errorAlert } from '@components/toastify/notify-toast';
import { getSubmitPlotPayloadFromPlotSelectionForm } from '@pages/workflow-analytics-page/utils';
import { GraphInfo, PlotData, PlotSelectionFormInfo, UserColumnSelectionRef } from '@pages/workflow-analytics-page/Canvas';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { useAnalyticsContext } from '@pages/workflow-analytics-page/useAnalyticsContext';
import { useDataExplorerContext } from '../useDataExplorerContext';
import { uuid } from 'uuidv4';
import { toggleAnalyticsModal } from '@store/analytics';
import { getStatementData, getTabInfoData, submitStatementDetails } from '../constants';
import { useGenAIContext } from '../gen-ai/useGenAIContext';

export const RightContainer: React.FC<{
    sessionId: string,
    previewStatementId: string
}> = ({
    sessionId,
    previewStatementId
}) => {
  const [showPopup, setPopup] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [showPlotSelectionSpinner, setPlotSpinnerStatus] = useState(false);
  const plotJobId = useRef("");
  const childFunc = useRef<any>(null);
  const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
  const enabledWorkspace = workSpaceData.filter((item)=>item.isEnabled)[0];
  const { workflow_details, setPlot } = useDataExplorerContext();
  const { sendAnalyticsReducerCmd, rasterSelectionInfo } = useAnalyticsContext();
  const formValuesRef = useRef<any>();
  const { database, schema, cluster, gotData, sqlQuery, graphIdentifier } = useDataExplorerContext();
  const { genAI_DataTable } = useGenAIContext();
  const cancelRequest = useRef(false);
  const userInfo = useSelector((store: RootState) => store.AccountReducer.activeUserInfo);
  const dispatch = useDispatch();
  const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
  const link = Env?.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
  useEffect(() => {
    return () => {
      setPlot('');
      sendAnalyticsReducerCmd({ type: "RESET_PLOT_SELECTION_STATE" });
    }
  }, [])

  const columnSelectionRef = useRef<UserColumnSelectionRef>({
		keys: [],
		values: [],
		seriesGroupings: [],
		multiBandCols: {
			red: [],
			green: [],
			blue: [],
		},
	});

  const onConfigurePlot = async () => {
    sendAnalyticsReducerCmd({ type: "RESET_PLOT_SELECTION_STATE" });
    const temp: any = submitStatementDetails(sessionId, database.databaseName, sqlQuery, cluster, database.dbType === "Databricks");
    AnalyticsHandler.SubmitStatement(link, temp, (data) => {
      const tabInfoData = getTabInfoData(sessionId, previewStatementId, workflow_details, userInfo.email);
      tabInfoData.previewStatementId = data.jobId;
      const statementData = getStatementData(sessionId, database.databaseName, sqlQuery, cluster, database.dbType === "Databricks");
      prepareDataForPlot(tabInfoData, statementData);
      setPopup(true);
    }, (e: any)=>{
      errorAlert(e);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const voidcall = () => {
  }

  const {
    prepareDataForPlot
	} = usePlotApi({
		onSubmitPlotSuccessCb: voidcall,
		onSubmitPlotFailureCb: voidcall,
		onPlotDataSuccessCb: voidcall,
		onPlotDataFailureCb: voidcall,
		handleSetInitialDataForPlotForm: voidcall,
		showPlotSelectionModal: voidcall,
		hidePlotSelectionModal: voidcall,
	});

  const checkPlotProgress = async () => {
    const result = await AnalyticsHandler.GetPlotDataStatusPromise(link, sessionId, plotJobId.current);
    if(cancelRequest.current) {
      childFunc.current?.waitGraphLoading(graphIdentifier, false);
      return stop();
    }
    if(result.data === 1) {
      childFunc.current?.waitGraphLoading(graphIdentifier, false);
      stop();
      getPlotData();
    }
  };

  const { start, stop } = useInterval(checkPlotProgress, 800);

  const onPlotDataFailureCb = (message: string) => {
		errorAlert(message);
		if (graphIdentifier)
			updateAGraph(graphIdentifier, { state: "ERROR" });
	};

  const getPlotData = () => {
    AnalyticsHandler.GetPlotData(link, sessionId, plotJobId.current, (response: any) => {
      setPlotSpinnerStatus(false);
      setPopup(false);
      if(cancelRequest.current) return;
      const {
				keys,
				values,
				seriesGroupings,
				multiBandCols,
			} = columnSelectionRef.current;

			const rasterConfigInfo = response.raster_info;
      const formValues = formValuesRef.current;

      const plotData: PlotData = {
        plotType: response.plotType,
        plotData: response.plotData,
        x: response.x,
        y: response.y,
        tiffFilePath: response?.tiffFilePath,
        latitude: response.latitude,
        longitude: response.longitude,
        raster_info: response.raster_info,
      };
      
      const plotSelectionFormInfo: PlotSelectionFormInfo = {
        ...formValues,
        xAxis: keys[0],
        yAxis: values,
        groupByColumns: seriesGroupings,
        graphIdentifier,
        multiBandCols,
        rasterInfo: rasterConfigInfo,
        "connectedComponentInfo": {
          "output": database.dbType === "Databricks" ? "sql_query_2_0" :  "read_rdbms_0_0",
          "component_id": "10e1557f-1baa-435d-96fe-4d704457a0ea",
          "target_component_id": "eeb14611-f563-46af-ad92-ba52569aab9c",
          "component_name": database.dbType === "Databricks" ? "Sql Query" : "Read RDBMS",
        }
      };
      setLoading(false);
      childFunc.current?.updateGraph(plotData, plotSelectionFormInfo, response.statementId, graphIdentifier + '', "SHOW_GRAPH");
    }, (error) => {
      setLoading(false);
      onPlotDataFailureCb("Unable to render plot. Please verify plot settings");
      setPlotSpinnerStatus(false);
    });
  }

  const addInitialPlot = (submitPlotData: SubmitPlotDataRef) => {
    const _graphIdentifier = uuid();
    const formValues = formValuesRef.current;
    const {
      keys,
      values,
      seriesGroupings,
      multiBandCols,
    } = columnSelectionRef.current;

    const temp = getSubmitPlotPayloadFromPlotSelectionForm(submitPlotData.formValues, {
      keys: submitPlotData.columnSelectionInfo.keys,
      values: submitPlotData.columnSelectionInfo.values,
      seriesGroupings: submitPlotData.columnSelectionInfo.seriesGroupings,
      multiBandCols: submitPlotData.columnSelectionInfo.multiBandCols,
      rasterSelectionInfo: submitPlotData.rasterSelectionInfo,
      currentRasterConfigs: submitPlotData.currentRasterConfigs,
      sessionId
    });

    const graphInfo: GraphInfo = {
      componentId: "10e1557f-1baa-435d-96fe-4d704457a0ea",
      state: 'LOADING',
      errorMessage: null,
      plotStatementId: "",
      plotSelectionFormInfo: {
        ...formValues,
        xAxis: keys[0],
        yAxis: values,
        groupByColumns: seriesGroupings,
        graphIdentifier,
        multiBandCols,
        rasterInfo: temp.raster_info,
        plot_type: temp.plot_type as any,
        "connectedComponentInfo": {
          "output": database.dbType === "Databricks" ? "sql_query_2_0" : "read_rdbms_0_0",
          "component_id": "10e1557f-1baa-435d-96fe-4d704457a0ea",
          "target_component_id": "eeb14611-f563-46af-ad92-ba52569aab9c",
          "component_name": database.dbType === "Databricks" ? "Sql Query" : "Read RDBMS",
        }
      },
      plotData: {
        raster_info: temp.raster_info,
        latitude: null,
        longitude: null,
        plotData: {},
        plotType: temp.plot_type as any,
        x: '',
        y: [],
        tiffFilePath: {}
      },
      tiffColorPlotInfo: {},
    };
  

    childFunc.current?.addDummyGraph(_graphIdentifier, graphInfo);
    return _graphIdentifier;
  }


  const handleSubmitPlot = async (submitPlotData: SubmitPlotDataRef, formValues: PlotSelectionFormType) => {
    setLoading(true);
    cancelRequest.current = false;
    setPlotSpinnerStatus(true);
    setPopup(false);
    if(graphIdentifier === '') {
      const graphIdentifier = addInitialPlot(submitPlotData);
      setPlot(graphIdentifier);
      childFunc.current?.waitGraphLoading(graphIdentifier, true);
    } else {
      childFunc.current?.updateGraphLoading(graphIdentifier, true);
      dispatch(toggleAnalyticsModal("plotSelection", false));
      childFunc.current?.waitGraphLoading(graphIdentifier, true);
    }
    columnSelectionRef.current = submitPlotData.columnSelectionInfo;
    const sessionInfo = await AnalyticsHandler.GetSessionInfo(link, sessionId);
    if(sessionInfo.data.state !== "Running") {
      // const sess = await AnalyticsHandler.StartSessionPromise()
    }
    formValuesRef.current = formValues;
    const plotData = await submitPlot(submitPlotData);
    plotJobId.current = plotData.data.jobId;
    if(cancelRequest.current) {
      setLoading(false);
      setPlotSpinnerStatus(false);
      return;
    }
    start();
	};

  const submitPlot = async (submitPlotData: SubmitPlotDataRef) => {
    const {
			formValues,
			columnSelectionInfo: {
				keys,
				values,
				seriesGroupings,
				multiBandCols,
			},
			rasterSelectionInfo,
			currentRasterConfigs,
		} = submitPlotData;

    const temp = getSubmitPlotPayloadFromPlotSelectionForm(formValues, {
        keys,
				values,
				seriesGroupings,
				multiBandCols,
        rasterSelectionInfo,
        currentRasterConfigs,
        sessionId
    });

    temp.df = database.dbType === "Databricks" ? "sql_query_2_0" : "read_rdbms_0_0";
    temp.output_key.workflow_session_id = sessionId;
    temp.output_key.component_id = "10e1557f-1baa-435d-96fe-4d704457a0ea";
    temp.output_key.target_component_id = "eeb14611-f563-46af-ad92-ba52569aab9c";
    const data = {
      "sessionId": sessionId,
      "payload": temp,
      "clusterId": cluster,
      "workspaceType":enabledWorkspace?.workspaceType,
      "workspaceId":enabledWorkspace?.workspaceId
    };
    return await AnalyticsHandler.SubmitPlotPromise(link, data);
  }

  const showPlotConfigPopup = (plotSelectionFormInfo: PlotSelectionFormInfo, rasterConfig: RasterConfig | "new" | null
  )=>{
    setPopup(true);
  }

  const handleAddNewRaster = (graphIdentifier: string) => {
		sendAnalyticsReducerCmd({
			type: "SET_RASTER_SELECTION_INFO",
			payload: {
				graphCardInfo: (window as any).graphsData[graphIdentifier],
				rasterConfig: "new",
			},
		});
	};

  const handleSetRasterSelectionInfo = (
		graphIdentifier: string,
		rasterConfig: RasterConfig
	) => {
		sendAnalyticsReducerCmd({
			type: "SET_RASTER_SELECTION_INFO",
			payload: {
				graphCardInfo: (window as any).graphsData[graphIdentifier],
				rasterConfig,
			},
		});
	};

  const addAGraph = (graphIdentifier: string, graphInfo: GraphInfo) => {
		childFunc.current?.addDummyGraph(graphIdentifier, graphInfo);
	};

	const updateAGraph = (
		graphIdentifier: string,
		graphInfo: Partial<GraphInfo>
	) => {
    childFunc.current?.updateGraph1(graphIdentifier, graphInfo);
	};

  const onCancel = () => {
    cancelRequest.current = true;
    if(showPlotSelectionSpinner) {
      childFunc.current?.deleteRaster(graphIdentifier, rasterSelectionInfo?.statementId);
    }
    setPlot('');
    sendAnalyticsReducerCmd({ type: "RESET_PLOT_SELECTION_STATE" });
    setPopup(false);
  }

	return (
    <>
      <div id="right-container" className={styles["right_container"]}>
        <div className={styles["mt-1"]}>
            <div className={styles["page_title"]}>Visualizations</div>
            <div className={styles["subtitle"]}>Sources</div>
        </div>
        <div className={classNames('text-center', styles['m-1'], styles['text'])}>Configure a plot to view custom visualizations</div>
        <div className={classNames('align-middle', {
          [styles["bottom"]]: !isEmpty
        })}>
          <Button 
              disabled={!genAI_DataTable || isLoading} 
              className={classNames(styles['button'], styles['m-1'])}
              onClick={onConfigurePlot}
              >
              Configure Plot
          </Button>
        </div>
        <PlotLayout setEmpty={setEmpty} childFunc={childFunc} showPlotConfigPopup={showPlotConfigPopup} />
      </div>
      <PlotSelectionModal
        isDataExplorer={true}
        handleAddGraph={addAGraph}
        handleUpdateGraph={updateAGraph}
        setRasterSelectionInfo={handleSetRasterSelectionInfo}
        handleAddNewRaster={handleAddNewRaster}
        submitPlot={handleSubmitPlot}
        showPlotSelectionSpinner={showPlotSelectionSpinner}
        togglePlotSelectionSpinner={setPlotSpinnerStatus}
        onCancel={onCancel}
        subtitle="&nbsp;"
        sessionId={sessionId}
        showPopup={showPopup}
        captureSchemaData={schema}
      />
    </>
	);
};