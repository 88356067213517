import React from 'react';
import { Route } from 'react-router';
import { InvalidUrlPage } from './components/error-page';
import { UnderConstruction } from './components/error-page/under-construction';
import { Env } from './constants/settings';
import { PrivateRoute } from './helpers/private-route';
import {Cluster} from './pages/cluster_redisign/';
import WorkflowView from './pages/workflow-page';
import Profile from './pages/profile';
import WorkflowAnalytics from './pages/workflow-analytics-page';
import HelpPage from './pages/help-page';
import DataSourceBrowser from './pages/data-browser';
// import Administrator from './pages/adminstrator';
import { AirFLow } from './pages/airflow';
import AdminPage from './pages/admin/user_management';
// import { TagSynchronizer } from './pages/osi-pi';
import { MlLoop } from './pages/MlLoop';
import { Switch } from 'react-router-dom';
import { TagSynchronizer } from './pages/osi-pi';
import { LoadTiff } from './pages/loadTiff';
import { FileBrowser } from '@pages/file-browser';
import NifiManagement from '@pages/admin/nifi_management';
import AssetHeirarchy from '@pages/admin/asset_heirarchy';
import GitSettings from './pages/admin/git_integration';
import Hierarchy from '@pages/admin/nifi_management/Hierarchy';
import { useAppSelector } from '@store/hooks';
import ActiveSessionsModal from '@components/ActiveSessionsModal';
import DataExplorer from '@pages/data-explorer';
import DataExplorerNew from '@pages/data-explorer-new';

// import { AddUser, EditUser } from './pages/user-management';

export const PageRoutes = () => {
    const isAdmin = useAppSelector((state) => state.AccountReducer.activeUserInfo.id ? state.AccountReducer.activeUserInfo.isAdmin : true);
    const { envVariables: Env } = useAppSelector((store)=> store.AccountReducer);

    return(
        <>
            <Switch>
                {/* <Route exact path="/" component={LoginSignup} />
                    <Route exact path="/reset/password/:token" component={LoginSignup} /> */}
                <PrivateRoute exact path="/" component={WorkflowView} />
                <PrivateRoute exact path="/workflow/" component={WorkflowView} />
                <PrivateRoute exact path="/workflow/visualizations/" component={WorkflowAnalytics} />
                <PrivateRoute exact path="/datasource/browser/" component={DataSourceBrowser} />
                <PrivateRoute exact path="/jobdashboard/" component={UnderConstruction} />
                {/* <PrivateRoute exact path="/jobhistory/" component={FlowDetails} />
                <PrivateRoute exact path="/jobhistory/detail/:id" component={FlowDetails} /> */}
                <PrivateRoute exact path="/profile/" component={Profile} />
                {/* <PrivateRoute exact path="/user-management/" component={UserOverview} /> */}
                {/* <PrivateRoute exact path="/add-user/" component={AddUser} />
                <PrivateRoute exact path="/edit-user/:id" component={EditUser} /> */}
                {/* <PrivateRoute exact path="/filemanagement" component={FileManagement} /> */} {/* TODO/Ajay: Remove old File Management(page: file-management) code once fully moved to the new implementation */}
                <PrivateRoute exact path="/filemanagement" component={FileBrowser} />
                <PrivateRoute exact path="/tagbrowser" component={TagSynchronizer} />
                <PrivateRoute exact path="/cluster" component={Cluster} />
                <PrivateRoute exact path="/dataexplorer" component={DataExplorer} />
                <PrivateRoute exact path="/dataexplorer-v2" component={DataExplorerNew} />
                <PrivateRoute  path="/data-explorer-drilling-app" component={DataExplorer} />
                {
                    Env?.REACT_APP_ENABLE_MLFLOW ? (<PrivateRoute exact path="/mlloop" component={MlLoop} />) : null
                }
                <PrivateRoute exact path="/airflow" component={AirFLow} />
                <PrivateRoute exact path="/help" component={HelpPage} />
                <PrivateRoute exact path="/tiff" component={LoadTiff} />
                {
                    isAdmin ? (
                        <>
                            <PrivateRoute exact path="/admin/user-management" component={AdminPage} />
                            <PrivateRoute exact path="/admin/git-integration-settings" component={GitSettings} />
                            {Env?.REACT_SHOW_PI_REQUESTS_TABLE && <PrivateRoute exact path="/admin/nifi-management" component={NifiManagement} />}
                            <PrivateRoute exact path="/admin/asset-hierarchy" component={Hierarchy} />
                            <PrivateRoute exact path="/admin/asset-hierarchy/edge-server" component={Hierarchy} />
                        </> 
                    ) : null
                }
                <Route path="*" component={InvalidUrlPage} />
            </Switch>
            <ActiveSessionsModal />
        </>
    );
};