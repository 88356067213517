import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Divider } from "antd";
import { HttpClient } from "@api/services";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { API } from "@constants/settings";
import { useDataExplorerContext } from "../useDataExplorerContext";
import styles from "./../styles.module.scss";
import { tables } from "./graphSameData";
import GraphCharts from "./GraphCharts";
import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";

const categories = [
	{
		name: "Entities in the Relationship Path",
		color: "#808080",
	},
	{
		name: "Selected Entities",
		color: "#ea7ccc",
	},
	{
		name: "Entities used for Filtering",
		color: "#ff8a45",
	},
	{
		name: "Properties used in the Query",
		color: "#05c091",
	},
	{
		name: "Values used in the Query",
		color: "#fddd60",
	},
];

export type Link = {
	source: string;
	target: string;
	join: string;
	details: any;
	value: any;
};

export type GraphDataType = {
	categories: { name: string }[];
	nodes: any;
	links: Link[];
};

export type ChartsProps = {
	onClose: () => any;
    sqlQuery:string;
};

const QueryChartsModal: React.FC<ChartsProps> = ({ onClose,sqlQuery}) => {
	const [_graphData, _setGraphData] = useState<GraphDataType[]>([]);
	const [_graphDataAllEvents, _setGraphDataAllEvents] = useState<
		GraphDataType[]
	>([]);
	const [edgeData, setEdgeData] = useState<Link | undefined>(undefined);
	const [isLoading, setLoading] = useState(false);
	const { database } = useDataExplorerContext();
	const [unionOrIntersect, setUnionOrIntersect] = useState<string[]>([]);
	const [unionOrIntersectAllEvent, setUnionOrIntersectAllEvent] = useState<
		string[]
	>([]);
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

	const parseGraphData = (data: any) => {
		let newGraphData: GraphDataType[] = [];
		let setOperation: string[] = [];
		if (data) {
			for (let i = 0; i < data?.length; i++) {
				const isNested = !Array.isArray(data[i][0]);
				if (isNested) {
					continue;
				}
				
				let _links = [...data[i][0]] as Link[];

				let _nodes = data[i][1].map((item: any, i: number) => {
					const x =
						tables.length > i
							? tables[i].x
							: tables[i % tables.length].x + i * 60;
					const y =
						tables.length > i
							? tables[i].y
							: tables[i % tables.length].y + i * 60;
					const isDetailsContainSelect =
						Array.isArray(item.details[3]?.select) &&
						item.details[3]?.select.length > 0;
					const isDetailsContainOrderBy =
						Array.isArray(item.details[2]?.orderby) &&
						item.details[2]?.orderby.length > 0;
					const isDetailsContainGroupBy =
						Array.isArray(item.details[1]?.groupby) &&
						item.details[1]?.groupby.length > 0;
					const isDetailsContainWhere =
						Array.isArray(item.details[0]?.where) &&
						item.details[0]?.where.length > 0;
					const isFilterExistsInDetails =
						isDetailsContainOrderBy ||
						isDetailsContainGroupBy ||
						isDetailsContainWhere;

					let color = 0;
					if (isDetailsContainSelect) {
						color = 1;
					}
					if (isFilterExistsInDetails) {
						color = 2;
					}

					return {
						id: item.source,
						name: item.source,
						details: item.details,
						used_columns: item.used_columns,
						symbolSize: 60,
						x: x,
						isColumn: false,
						y: y,
						value: "",
						category: color,
					};
				});
				_nodes.forEach((item: any, i: number) => {
					if (item.used_columns) {
						item.used_columns.forEach(
							(col: string, ind: number) => {
								const x =
									tables[i]?.columns.length > ind
										? tables[i].columns[ind].x
										: item.x + i * 20;
								const y =
									tables[i]?.columns.length > ind
										? tables[i].columns[ind].y
										: item.y + i * 20;
								const obj = {
									id: item.id + "." + col,
									name: col,
									details: item.details,
									used_columns: null,
									symbolSize: 40,
									x: x,
									y: y,
									isColumn: true,
									value: "",
									category: 3,
								};
								_links = [
									..._links,
									{
										source: item.id,
										target: item.id + "." + col,
										join: item.id + "." + col,
									} as Link,
								];
								_nodes = [..._nodes, obj];
							}
						);
					}
				});

				_nodes.forEach((item: any, i: number) => {
					if (item.isColumn && item.details) {
						item.details[0].where?.forEach((_item: any) => {
							const columnKeys = Object.keys(_item);
							columnKeys.forEach((key) => {
								const val = _item[key];
								if (key === item.name) {
									const obj = {
										id: item.id + "=" + val,
										name:
											val.length > 13
												? val.substring(0, 10) + "..."
												: val,
										details: null,
										used_columns: null,
										symbolSize: 20,
										isValues: true,
										x: item.x + i * 110,
										y: item.y + i * 10,
										value: "",
										category: 4,
									};
									_links = [
										..._links,
										{
											source: item.id,
											target: item.id + "=" + val,
											join: item.id + "=" + val,
											value: item.id + ":" + val,
										} as Link,
									];
									_nodes = [..._nodes, obj];
								}
							});
						});
					}
				});

				newGraphData = [
					...newGraphData,
					{ nodes: _nodes, links: _links, categories: categories },
				];
				if (i < data.length - 1) {
					setOperation = [...setOperation, data[i][2]?.set_operation];
				}
			}
		}
		return [newGraphData, setOperation];
	};

	const setAllEventsGraphData = (allEventsData: any) => {
		const _data: any[] = parseGraphData(allEventsData);
		_setGraphDataAllEvents(_data[0]);
		setUnionOrIntersectAllEvent(_data[1]);
		setLoading(false);
	};

	const fetchPLotData = async () => {
		try {
			setLoading(true);
			const payload = {
				sql_input: sqlQuery,
			};
			const data = await HttpClient.PostPromise(
				`${Env.REACT_APP_PLATFORM_URL}/schema/${database.id}/parse_sql`,
				payload
			);
			let _tempAllEventsData: any = [];

			if (data) {
				for (let i = 0; i < data.data?.length; i++) {
					const isNested = !Array.isArray(data.data[i][0]);
					if (isNested) {
						const nestedKey = Object.keys(data.data[i][0])?.[0];
						const allEventsData =  data.data[i][0]?.[nestedKey];
						_tempAllEventsData = [
							..._tempAllEventsData,
							...allEventsData,
						];
					}
				}
			}

			setAllEventsGraphData(_tempAllEventsData);
			const _data: any[] = parseGraphData(data.data);
			_setGraphData(_data[0]);
			setUnionOrIntersect(_data[1]);
			setLoading(false);
		} catch {
			onClose();
		}
	};

	useEffect(() => {
		fetchPLotData();
	}, []);

	const handleEdgeData = useCallback((e: any, i: number) => {
		setEdgeData(undefined);
		let linkInfo = undefined;
		if (e.data?.details && !e.data.isColumn) {
			const linkInfoIndex: any = _graphData[i]?.links.findIndex(
				(item: any) => item.source === e.data?.id
			);
			linkInfo = {
				..._graphData[i]?.links[linkInfoIndex],
				details: e.data?.details,
				source: e.data.id,
			} as Link;
		}
		setEdgeData(linkInfo);
	}, []);

	return (
		<div className={styles["plotMainContainer"]}>
			{isLoading ? (
				<div
					className={classNames(
						"flex align-middle justify",
						styles["m-5"],
						styles["loader"]
					)}
				>
					<InPageSpinner />
				</div>
			) : null}

			{!isLoading && !isEmpty(_graphData) ? (
				<div
					style={{
						display: "flex",
						gap: "1rem",
						width: window.innerWidth - 100,
					}}
				>
					<div className={styles["queryGraphDiv"]}>
						<div className={styles["queryGraphWrapper"]}>
							{_graphData.map((graph, i) => (
								<GraphCharts
									Key={i.toString()}
									graphData={graph}
									unionOrIntersect={unionOrIntersect[i]}
									handleEdgeData={handleEdgeData}
									showLegends={false}
								/>
							))}
						</div>

						{!isEmpty(_graphDataAllEvents) && (
							<div>
								<div className={styles["center"]}>
									nested &nbsp;
									<div
										className={styles["verticalDivider"]}
									/>
								</div>
								<div
									className={
										styles["queryGraphWrapperAllEvents"]
									}
								>
									{_graphDataAllEvents.map((graph, i) => (
										<GraphCharts
											Key={i.toString()}
											graphData={graph}
											unionOrIntersect={
												unionOrIntersectAllEvent[i]
											}
											handleEdgeData={handleEdgeData}
											showLegends={false}
										/>
									))}
								</div>
							</div>
						)}
					</div>
					<div
						style={{
							width: "20%",
							paddingRight: 10,
						}}
					>
						<div>
							{categories.map(
								(item: { name: string; color: string }) => (
									<Flex
										alignItems={"center"}
										mb={2}
										justifyContent={"space-between"}
										key={item.name}
									>
										<Text>{item.name}</Text>
										<div
											style={{
												width: "30px",
												height: "20px",
												borderRadius: 4,
												background: item.color,
											}}
										/>
									</Flex>
								)
							)}
							<Divider />
							{!isEmpty(edgeData) && (
								<div>
									<div className={styles["group"]}>
										<div
											className={styles["group_key_text"]}
										>
											Entity 1{" "}
										</div>
										<div>{edgeData?.source}</div>
									</div>
									<div className={styles["group"]}>
										<div
											className={styles["group_key_text"]}
										>
											Entity 2{" "}
										</div>
										<div>{edgeData?.target || "-"}</div>
									</div>
									<div className={styles["group"]}>
										<div
											className={styles["group_key_text"]}
										>
											Link{" "}
										</div>
										<div>{edgeData?.join || "-"}</div>
									</div>
									<Divider />
									<div>
										{edgeData?.details?.map((item: any) => {
											const keyNameRef: any = {
												select: "Selected Properties",
												where: "Filter",
												groupby: "Data Groups",
												orderby: "Data Order",
											};
											const keys = Object.keys(item);
											const values = Object.values(item);
											let text: any = "-";
											if (
												values[0] !== null &&
												typeof values[0] === "object"
											) {
												text = JSON.stringify(
													values[0]
												);
											} else {
												text =
													Array.isArray(values[0]) &&
													values[0].length > 0
														? values[0].join()
														: "-";
											}
											return (
												<div
													className={styles["group"]}
													key={text}
												>
													<div
														className={
															styles[
																"group_key_text"
															]
														}
													>
														{keyNameRef[keys[0]]}
													</div>
													<div
														style={{
															wordBreak:
																"break-all",
														}}
													>
														{text}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default React.memo(QueryChartsModal);
