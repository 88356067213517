import { KeycloakUserInfo } from '@services/keycloak';
import { PersistedReduxState } from '@store/utils';
import get from 'lodash/get';
import { UserHandler } from '../../api/user-handler';
import { ExecutionEnvModes } from '../../constants/enums';
import { Env } from '../../constants/settings';
import { getAllUsersList, setActiveSessionsInfo } from '../account';
import { PageTypes, resetActiveTab, switchToExistingCanvasTab } from '../canvas';
import { getAllClusters } from '../cluster';
import { getAllDocs } from '../help';
import { AppThunk, LOAD_INITIAL_STATE } from '../types';
import { getWorkflowDirectories, setWorkflowTabSelection } from '../workflow';
import { SHOW_SPINNER, HIDE_SPINNER, CommonActionTypes, SpinnerTypes, TOGGLE_LANDING_SCREEN , GO_TO_ANALYTICS_PAGE, TOGGLE_NETWORK_ERROR, SET_EXECUTION_ENV, SET_LAST_ACTIVE_WORKFLOW_INFO, SetLastActiveWorkflowInfoType, SetComponentsSearchState, SET_COMPONENTS_SEARCH_STATE, } from './types';



export const showSpinner = (spinnerType: SpinnerTypes): CommonActionTypes => ({ type: SHOW_SPINNER,  payload: spinnerType });

export const hideSpinner = (spinnerType: SpinnerTypes): CommonActionTypes => ({type: HIDE_SPINNER, payload: spinnerType });

export const toggleLandingPage = (action: boolean): CommonActionTypes => ({ type: TOGGLE_LANDING_SCREEN, payload: action });

export const goToAnalyticsPage = () => ({ type: GO_TO_ANALYTICS_PAGE });

const toggleNetworkErrorPage = (action: boolean) => ({ type: TOGGLE_NETWORK_ERROR, payload: action});

export const showNetworkErrorPage = () => toggleNetworkErrorPage(true);
export const hideNetworkErrorPage = () => toggleNetworkErrorPage(false);

export const setActiveExecutionEnv = (env: ExecutionEnvModes) => ({ type: SET_EXECUTION_ENV, payload: env });

export const setLastActiveWorkflowInfo = (page: PageTypes, env: ExecutionEnvModes, workflowId: number | null): { type: string; payload: SetLastActiveWorkflowInfoType } => 
    ({ type: SET_LAST_ACTIVE_WORKFLOW_INFO, payload: { page, env, workflowId } });

export const onLoadSuccess = (): AppThunk => (dispatch, getState) => {
    const Env = getState().AccountReducer.envVariables;
    const _cb = () => {
        dispatch(getAllDocs());
        dispatch(getAllUsersList());
        if(Env?.REACT_APP_DATABRICKS) {
            dispatch(getAllClusters());
        }
        dispatch(hideSpinner(SpinnerTypes.initialPageLoadSpinner));
    };

    UserHandler.GetActiveSessions(`${Env?.REACT_APP_PLATFORM_URL}/usermanagement/api`, 'datastudio-public',(response) => {
        dispatch(setActiveSessionsInfo(response));
    })

    UserHandler.InitialCall(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, _cb);
    
};

export const setComponentsSearchState = (env: ExecutionEnvModes, openNodesState: string[]): { type: string; payload: SetComponentsSearchState } => 
    ({ type: SET_COMPONENTS_SEARCH_STATE, payload: { env, openNodesState } }); 


export const updateExecutionEnv = (env: ExecutionEnvModes, resetDirectory = true): AppThunk => (dispatch, getState) => {
    const { CommonReducer: { lastActiveWorkflowInfo }, WorkflowReducer } = getState();
    const { selectedDirectory } = WorkflowReducer.workflowTabSelection;


    dispatch(setActiveExecutionEnv(env));
    dispatch(getWorkflowDirectories());
    // When executionenv is changed in workflowEditor page, activeTab also needs to be set in analytics page 
    // ActiveTab wouldnt be set w.r.t new env when user switches from workflowEditor to analytics page if active tab is not updated here 
    Object.entries(lastActiveWorkflowInfo).forEach(([pageType, pageInfo]) => {
        const activeWorkflowInfoInUpdatedEnv = pageInfo[env];
        if(activeWorkflowInfoInUpdatedEnv) {
            dispatch(switchToExistingCanvasTab(pageType as any, activeWorkflowInfoInUpdatedEnv));
        } else {
            dispatch(resetActiveTab(pageType as any));
        }
    });
    if(selectedDirectory && resetDirectory) {
        // reset selectedDirectory
        dispatch(setWorkflowTabSelection('selectedDirectory', null));
    }
};


const getUserIdFromSavedReduxState = (state: PersistedReduxState) => {
    const { workflowEditor, analytics } = state.CanvasReducer;
    if(get(state, 'AccountReducer.activeUserInfo.username')) {
        return state.AccountReducer.activeUserInfo.username
    } else if(workflowEditor.activeTab.id) {
        return workflowEditor.openTabs.get(workflowEditor.activeTab.id)?.info.createdBy
    } else if(analytics.activeTab.id) {
        return analytics.openTabs.get(analytics.activeTab.id)?.info.createdBy
    }
    return null
    
}


export const loadReduxStateOnMount = (state: PersistedReduxState | undefined, userInfo: KeycloakUserInfo): AppThunk => (dispatch) => {
    if(state) {
        // This check is to make sure saved local redux state belongs to the user
        if(getUserIdFromSavedReduxState(state) === userInfo.preferred_username) {
            dispatch({ type: LOAD_INITIAL_STATE, payload: state })
        }        
    }
}