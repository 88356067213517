import { WorkspaceData } from '@pages/cluster_redisign/types';
import { AxiosResponse } from 'axios';
import { Cluster, ClusterDetail, ClusterModeType, AccessNodeType, UsersType, ClusterState, DataBricksVersionListType, NodeType } from '../../api/databricks-handler';
import { NewDatabricksHandlerClass } from '../../api/databricks-handler/handler';
import { errorHandlerFor400 } from '../../api/services/errorhandler';
import { AppThunk } from '../types';
import { GET_CLUSTERS, UPDATE_CLUSTER_STATUS, UPDATE_CLUSTER_DEFAULT, CREATED_BY_ME, DELETE_CLUSTER, ADD_CLUSTER, GET_NODE_TYPE_LIST, GET_CLUSTER_MODE, GET_ACCESS_NODE, GET_USERS_LIST, GET_DATABRICKS_VERSION_LIST, SET_WORKSPACE_LIST, SET_ROUTE_OPTIMIZATION_LIST } from './constants';


export const getAllClusters = (): AppThunk => (dispatch,getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchClusterList = (clusterList: Cluster[]) => {
        dispatch({ type: GET_CLUSTERS, payload: { 'clusters': clusterList } });
    };
    NewDatabricksHandlerClass.GetAllClusterList(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, dispatchClusterList);
};

export const restartCluster = (clusterId: string): AppThunk => (dispatch, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchClusterStart = () => {
        dispatch({ type: UPDATE_CLUSTER_STATUS, payload: { clusterId: clusterId, newState: ClusterState.RESTARTING } });
    };
    NewDatabricksHandlerClass.RestartCluster(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, clusterId, dispatchClusterStart);
};

export const startCluster = (clusterId: string): AppThunk => (dispatch, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchClusterReStart = () => {
        dispatch({ type: UPDATE_CLUSTER_STATUS, payload: { clusterId: clusterId, newState: ClusterState.PENDING } });
    };
    NewDatabricksHandlerClass.startCluster(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, clusterId, dispatchClusterReStart);
};

export const terminateCluster = (clusterId: string): AppThunk => (dispatch, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchClusterTerminate = () => {
        dispatch({ type: UPDATE_CLUSTER_STATUS, payload: { clusterId: clusterId, newState: ClusterState.TERMINATING } });
    };
    NewDatabricksHandlerClass.TerminateCluster(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, clusterId, dispatchClusterTerminate);
};

export const changeClusterDefault = (clusterId: string, defaultCluster: boolean): AppThunk => (dispatch, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchUpdateClusterDefault = () => {
        dispatch({ type: UPDATE_CLUSTER_DEFAULT, payload: { clusterId: clusterId, defaultCluster: defaultCluster } });
    };
    NewDatabricksHandlerClass.UpdateClusterDefaultStatus(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, clusterId, defaultCluster, dispatchUpdateClusterDefault);
};

export const deleteCluster = (clusterId: string, successCb?: () => void, failureCb?: () => void): AppThunk => (dispatch, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchClusterDelete = () => {
        successCb && successCb();
        dispatch({ type: DELETE_CLUSTER, payload: { clusterId: clusterId } });
    };
    NewDatabricksHandlerClass.DeleteCluster(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, clusterId, dispatchClusterDelete, failureCb);
};

export const addCluster = (clusterDetail: Omit<ClusterDetail, 'modelType' >,
    onSuccessHandler: () => void,
    onFailureHandler: () => void): AppThunk => (dispatch, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchAddCluster = (cluster: Cluster) => {
        dispatch({ type: ADD_CLUSTER, payload: { cluster: cluster } });
        onSuccessHandler();
    };
    const errorCallbackHandler = (errorResponse: AxiosResponse) => {
        onFailureHandler();
        errorHandlerFor400(errorResponse);
    };
    NewDatabricksHandlerClass.CreateNewCluster(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, clusterDetail, dispatchAddCluster, errorCallbackHandler);
};

export const getNodeTypeList = (): AppThunk => (dispatch: any, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchNodeTypeList = (nodeTypeList: NodeType[]) => {
        dispatch({ type: GET_NODE_TYPE_LIST, payload: { 'nodeTypeList': nodeTypeList } });
    };
    NewDatabricksHandlerClass.GetNodeTypeList(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, dispatchNodeTypeList);
};

export const getClusterMode = (): AppThunk => (dispatch: any, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchNodeTypeList = (clusterModeList: ClusterModeType[]) => {
        dispatch({ type: GET_CLUSTER_MODE, payload: { 'custerModeList': clusterModeList } });
    };
    NewDatabricksHandlerClass.GetClusterMode(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, dispatchNodeTypeList);
};

export const getAccessNode = (): AppThunk => (dispatch: any, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchAccessNodeList = (accessNodeList: AccessNodeType[]) => {
        dispatch({ type: GET_ACCESS_NODE, payload: { 'accessNodeList': accessNodeList } });
    };
    NewDatabricksHandlerClass.GetAccessNode(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, dispatchAccessNodeList);
};

export const getUsersList = (): AppThunk => (dispatch: any, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchUsersList = (usersList: UsersType[]) => {
        dispatch({ type: GET_USERS_LIST, payload: { 'usersList': usersList } });
    };
    NewDatabricksHandlerClass.GetUsersList(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, dispatchUsersList);
};

export const getDataBricksVersionList = (): AppThunk => (dispatch: any, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const dispatchNodeTypeList = (databricksVersionList: DataBricksVersionListType[]) => {
        dispatch({ type: GET_DATABRICKS_VERSION_LIST, payload: { 'databricksVersionList': databricksVersionList } });
    };
    NewDatabricksHandlerClass.GetDataBricksRunTimeVersion(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`,dispatchNodeTypeList);
};

export const getCluster = (clusterId: string,
    onSuccessHandler: (clusterDetail: ClusterDetail) => void): AppThunk => (dispatch: any, getState) => {
    const { AccountReducer: {envVariables : Env} }= getState();
    const successCallbackHandler = (clusterDetail: ClusterDetail) => {
        onSuccessHandler(clusterDetail);
    };
    NewDatabricksHandlerClass.GetCluster(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, clusterId, successCallbackHandler);
};

export const updateCluster = (clusterDetail: Omit<ClusterDetail, 'modelType' >,
    onSuccessHandler: () => void,
    onFailureHandler: () => void): AppThunk => (dispatch, getState) => {
        const { AccountReducer: {envVariables : Env} }= getState();
    const successCallbackHandler = () => {
        dispatch(getAllClusters());
        onSuccessHandler();
    };
    const errorCallbackHandler = (err: AxiosResponse) => {
        onFailureHandler();
        errorHandlerFor400(err);
    };
    NewDatabricksHandlerClass.UpdateCluster(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, clusterDetail, successCallbackHandler, errorCallbackHandler);
};

export const toggleCreatedByMe = (): AppThunk => (dispatch) => {
    dispatch({ type: CREATED_BY_ME });
};

export const setWorkspaceList = (payload: WorkspaceData[]) =>
    ({ type: SET_WORKSPACE_LIST, payload });

export const setRouteOptimizationist = (payload: any) =>
    ({ type: SET_ROUTE_OPTIMIZATION_LIST, payload });