import React from 'react';
import 'antd/dist/antd.css';
import 'react-virtualized/styles.css';
import './App.scss';
import { Provider, useSelector } from 'react-redux';
import { store } from './store';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { ToastContainer, Slide } from 'react-toastify';
import { ErrorBoundaryComponent } from './components/error-page';

import { SideBars } from './components/header-sidebar';
import { PageRoutes } from './routes';
import { ThemeProvider } from 'styled-components';
import { theme } from '@constants/theme';
import { CoachMark } from '@components/coachmark';
import { KeycloakService } from './services';
import { RootState } from '@store/types';

export const App: React.FC = () => {
    const isDrillingDataExplorer = window.location.pathname.includes('data-explorer-drilling-app');
    const Env = useSelector((state: RootState) => state.AccountReducer.envVariables);
    const handleOnIdle =()=>{
        KeycloakService.doLogout();
    }

    useIdleTimer({
        timeout: Number(Env?.REACT_APP_DATA_STUDIO_IDLE_TIMEOUT||10800000),
        onIdle: handleOnIdle,
    });

    return (
        <ErrorBoundaryComponent>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Router>
                        {/* <Suspense fallback={<div>Loading...</div>}> */}
                        <SideBars />
                        <PageRoutes />
                        {Env?.ENABLE_DATASTUDIO_WALKTHROUGH && <CoachMark />}
                        {/* </Suspense> */}
                    </Router>
                    {!isDrillingDataExplorer && <ToastContainer
                        rtl={false}
                        draggable={false}
                        pauseOnHover={false}
                        transition={Slide}
                        autoClose={8000}
                        newestOnTop={true}
                    />}
                </Provider>
            </ThemeProvider>
        </ErrorBoundaryComponent>
    );
}; 

export default App;
