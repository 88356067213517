/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import TreeMenu, { TreeMenuItem, TreeNodeInArray } from 'react-simple-tree-menu';
import { ItemComponent } from '../../../components/treeview/itemcomponent';
import classNames from 'classnames';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import {
    toggleModal,
    closeActiveForm,
    closeActiveComponentNotes,
    setActiveUpdataParamter,
    setCustomComponentModalType,
    setActiveUpdataModalType,
    setcomponentParamenters,
    setCustomComponentUpdateId,
    setCustomComponentName
} from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RenameComponentModal } from '../modals/rename-component';
import { ComponentImportModal } from '../modals/import-component-profile-workflow';
import { DeleteComponentModal } from '../modals/delete-component';
import { RootState } from '../../../store/types';
import {
    encryptAndExport
} from '../utils';
import { ShowWhenTrue } from '../../../helpers';
import { TooltipTop } from '../../../components/tooltips';
import { useDebounce } from 'rooks';
import { isEmpty, cloneDeep } from 'lodash';
import { CustomComponentParametersModal } from '../modals/customcomponent-parameters';
import { WorkflowCanvasTabInfo } from '../../../store/canvas';
import { getContextMenuPostion } from '../../../helpers/utils';
import { setComponentsSearchState } from '../../../store/common';
import { ExecutionEnvModes } from '../../../constants/enums';
import { ComponentTreeView, ComponentTreeViewItemInfo } from '@services/RiTreeParser/types';
import { getComponentTreeData } from '@services/RiTreeParser';
import { CreateNewComponentResponse, newComponentHandler } from '@api/new-custom-component';
import { infoAlert, successAlert } from '@components/toastify/notify-toast';
import { ComponentEditorModal } from '../modals/customcomponent-editor';
// import { Env } from '@constants/settings';
import { CustomModelComponentParametersModal } from '../modals/customModelcomponent-parametersDemo';

interface TreeViewProps {
  hideTreeview: boolean;
  allowDrag: boolean;
  showCustomComponentModal: () => void;
}

interface CustomComponentInfo extends TreeMenuItem {
  payload: string;
  id: number;
  name: string;
}

const checkStringIncludesSearchString = (
    targetString: string,
    searchString: string
): boolean => {
    return targetString.toLowerCase().includes(searchString);
};

const ComponentsTreeView: React.FC<TreeViewProps> = ({
    hideTreeview,
    allowDrag,
    showCustomComponentModal,
}): JSX.Element => {
    const [searchInput, setSearchInput] = useState('');
    const dispatch = useDispatch();
    // const [componentTreeData, setComponentTreeData] = useState([]);
    // const [originalTreeData, setOriginalTreeData] = useState([]);
    const [showCreateComponentModal, setShowCreateComponentModal] = useState(false);
    const [searchComponentTreeData, setSearchComponentTreeData] = useState<ComponentTreeViewItemInfo[]>([]);
    const openNodesStateBeforeSearch = useRef<string[]>([]);
    const [isShowContextMenu, setIsShowContextMenu] = useState(false);
    const [
        currentContextMenuItem,
        setCurrentContextMenuItem,
    ] = useState<CustomComponentInfo | null>(null);
    const {
        activeComponentPropertiesInfo,
    } = useSelector((store: RootState) => store.WorkflowReducer);
    // const [showComponentErrorMsg, setShowComponentErrorMsg] = useState(true);
    const treeMenuRef = useRef<TreeMenu | null>(null);
    const contextMenuRef = useRef<HTMLDivElement>(null);
    const { data: componentTreeData, loading: isDataLoading } = useSelector(
        (store: RootState) => store.WorkflowReducer.componentTreeData
    );
    const showComponentErrorMsg = useSelector(
        (store: RootState) => !store.WorkflowReducer.customComponentsList.length && !store.WorkflowReducer.componentTreeData.loading
    );
    const { openTabs, activeTab } = useSelector(
        (store: RootState) => store.CanvasReducer.workflowEditor
    );
    const { activeUserInfo } = useSelector(
        (store: RootState) => store.AccountReducer
    );
    const activeWorkflowInfo = useRef<WorkflowCanvasTabInfo | undefined>();
    const { activeExecutionEnv, componentsSearchState } = useSelector((store: RootState) => store.CommonReducer);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    useEffect(() => {
        activeWorkflowInfo.current = openTabs.get(activeTab.id)?.info;
    }, [openTabs, activeTab]);

    const resetRiTree = () => {
        treeMenuRef.current?.resetOpenNodes(openNodesStateBeforeSearch.current);
        setSearchInput('');
        setSearchComponentTreeData(componentTreeData);
    };

    const handleTreeViewWalk = useDebounce(
        (searchString: string, treeData: any) => {
            if (searchString) {
                const treeDataWithResults: any[] = [];
                const openNodes: string[] = [];
                treeData.forEach((parentData: any) => {
                    const parent = cloneDeep(parentData);
                    let matchFound = false;
                    parent.nodes = parent.nodes.filter((child: any) => {
                        let isMatchFoundInChildLevel = false;
                        if (!child.is_component) {
                            // For components that are in the second level like Data Transformation, Structured Analytics
                            child.nodes = child.nodes.filter((grandChild: any) => {
                                if (
                                    checkStringIncludesSearchString(
                                        grandChild.label,
                                        searchString
                                    )
                                ) {
                                    isMatchFoundInChildLevel = true;
                                    matchFound = true;
                                    return true;
                                }
                                return false;
                            });
                        } else if (
                            checkStringIncludesSearchString(child.label, searchString)
                        ) {
                            // For components that are in the first level like Streaming Analytics, Geospatial Analytics
                            isMatchFoundInChildLevel = true;
                            matchFound = true;
                        }
                        if (isMatchFoundInChildLevel)
                            openNodes.push(parent.key + '/' + child.key);
                        return isMatchFoundInChildLevel;
                    });
                    if (matchFound) {
                        treeDataWithResults.push(parent);
                        openNodes.push(parent.key);
                    }
                });
                if (!isEmpty(openNodes)) treeMenuRef.current?.resetOpenNodes(openNodes);
                // @ts-ignore
                setSearchComponentTreeData(treeDataWithResults);
            } else {
                resetRiTree();
            }
        },
        500
    );

    const handleTreeViewSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = e.target.value;
        if(searchString !== '') {
            handleTreeViewWalk(searchString.toLowerCase(), componentTreeData);
            setSearchInput(searchString);
        } else {
            resetRiTree();
        }
    };

    const handleToggleNode = () => {
        setTimeout(() => {
            if(treeMenuRef.current && searchInput === '') {
                openNodesStateBeforeSearch.current = treeMenuRef.current.state.openNodes;
            } 
        }, 100);
    };

    useEffect(() => {
        if(searchInput)  {
            handleTreeViewWalk(searchInput, componentTreeData);
        } else {
            setSearchComponentTreeData(componentTreeData);
        }
    }, [componentTreeData])


    const refreshRiTree = (setOpenState = true) => {
        const openNodesState = cloneDeep(openNodesStateBeforeSearch.current);
        (dispatch(getComponentTreeData()) as unknown as Promise<ComponentTreeView>)
            .then(() => {
                if(setOpenState) {
                    setTimeout(() => {
                        treeMenuRef.current?.resetOpenNodes(openNodesState);
                    }, 10)
                }
            });
    };

    useEffect(() => {
        refreshRiTree(false);
        openNodesStateBeforeSearch.current = componentsSearchState ? componentsSearchState[activeExecutionEnv]: [];
        return () => {
            // save current openNodesState
            dispatch(setComponentsSearchState(activeExecutionEnv, openNodesStateBeforeSearch.current));
        };
    }, [activeExecutionEnv]);

    

    function contextMenuTrigger(e: any, itemInfo: CustomComponentInfo) {
        e.preventDefault();
        if (itemInfo.isCustomComponent) {
            setCurrentContextMenuItem(itemInfo);
            dispatch(setCustomComponentName({ name: itemInfo.name }));
            dispatch(setcomponentParamenters(itemInfo.formDetails));
            dispatch(setCustomComponentUpdateId(itemInfo.id));
            setIsShowContextMenu(true);
            if (contextMenuRef.current) {
                const { x, y } = getContextMenuPostion(e.nativeEvent, contextMenuRef.current);
                contextMenuRef.current.style.top = y + 'px';
                contextMenuRef.current.style.left = x + 'px';
            }
        }
    }
    function closeContextmenu() {
        setIsShowContextMenu(false);
    }

    useEffect(() => {
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
        document.addEventListener('click', closeContextmenu, true);
        return function cleanup() {
            document.removeEventListener('click', closeContextmenu);
        };
    }, []);

    const handleCloneSuccess = (response: CreateNewComponentResponse) => {
        successAlert(`Cloned as ${response.data.name}`)
        refreshRiTree();
    }

    function handleContextEvent(e: any, arg: any) {
        switch (arg) {
            case 'clone':
                if(currentContextMenuItem) {
                    infoAlert(`Cloning ${currentContextMenuItem.label}`)
                    newComponentHandler.cloneCustomComponent(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, currentContextMenuItem.id, activeUserInfo.id, activeExecutionEnv, handleCloneSuccess)
                }
                break;
            case 'delete':
                {
                    if (currentContextMenuItem) {
                        dispatch(toggleModal('deleteComponent', true));
                    }
                }
                break;
            case 'rename':
                {
                    dispatch(toggleModal('renameComponent', true));
                }
                break;
            case 'edit': {
                dispatch(setCustomComponentModalType('update'));
                dispatch(setActiveUpdataModalType(true));
                dispatch(toggleModal('customEditor', true));
                // dispatch(toggleModal('customComoponentParameters', true));
                dispatch(setActiveUpdataParamter(true));
            }
                break;
            case 'export': {
                const currentContextMenuItemExport: any = currentContextMenuItem;
                const exportData = {
                    defaultValue: currentContextMenuItemExport.payload,
                    formData: JSON.stringify(currentContextMenuItemExport.formDetails),
                };
                currentContextMenuItem &&
          encryptAndExport(
              JSON.stringify(exportData),
              currentContextMenuItem.name,
              'component'
          );
            }
        }
    }

    const handleComponentImportSucess = () => {
        dispatch(toggleModal('componentImport', false));
        refreshRiTree();
    };

    const handleCustomComponentParametersSuccess = () => {
        dispatch(toggleModal('customEditor', true));
    };

    const showCustomComponentImportModal = () => {
        dispatch(toggleModal('componentImport', true));
    };

    const checkIfActiveComponentFormIsSaved = () => {
        const isActiveComponentFormSaved = activeComponentPropertiesInfo.isSaved;
        if (!isActiveComponentFormSaved) {
            dispatch(closeActiveForm());
        }
        return isActiveComponentFormSaved;
    };

    const checkIfActiveComponentNotesIsSaved = () => {
        const isActiveComponentNotesSaved = activeComponentPropertiesInfo.isNotesSaved;
        if (!isActiveComponentNotesSaved) {
            dispatch(closeActiveComponentNotes());
        }
        return isActiveComponentNotesSaved;
    };

    const setTreeMenuRef = (instance: TreeMenu | null) => treeMenuRef.current = instance;

    const showCustomComponent = activeExecutionEnv !== ExecutionEnvModes.Pipelines;

    function handleComponentEditorSucess() {
        refreshRiTree();
        dispatch(toggleModal('customEditor', false));
    }

    const handleClick = () => {
        showCustomComponentModal();
        dispatch(setCustomComponentName({ name: '' }));
        setShowCreateComponentModal(false);
    };
    
    const handleClickModel = () => {   {/* delete this file its just for demo*/}
        showCustomComponentModal();
        dispatch(setCustomComponentName({ name: '' }));
        setShowCreateComponentModal(true);
    };
    return (
        <div className={classNames('treeview', { 'hide__treeview': hideTreeview, 'treeview-item__center': isDataLoading })} >
            {isDataLoading ? (<InPageSpinner />) : (
                
                <TreeMenu
                    data={(searchInput ? searchComponentTreeData : componentTreeData) as TreeNodeInArray[]}
                    hasSearch={false}
                    ref={setTreeMenuRef}
                >
                    {({ items }) => (
                        <>
                            <div className="search__input__container">
                                <input
                                    onChange={handleTreeViewSearch}
                                    className="search_input"
                                    placeholder={'Try "RDBMS"'}
                                    value={searchInput}
                                    id="SearchComponent"
                                    autoComplete="off"
                                />
                                {!!(searchInput !== '') && (
                                    <img
                                        src="/icons/treeview/search-cross.svg"
                                        alt=""
                                        className="search_reset"
                                        onClick={resetRiTree}
                                    />
                                )}
                            </div>
                            <ShowWhenTrue show={showCustomComponent}>
                                <div className="component_action_wrapper mb-1">
                                <TooltipTop title={'Create / Edit Model'}>  {/* delete this file its just for demo*/}
                                        <button
                                            id="btn_new_component"
                                            // style={{width:90}}
                                            className="btn-sm btn-yellow-transparent btn__new__workflow btn_new_component btn__new__action"
                                            onClick={handleClickModel}
                                        >
                                            {/* <img src="/icons/workflow/add.svg" alt="" /> */}
                                            <span> Model </span>
                                        </button>
                                    </TooltipTop>
                                    <TooltipTop title="Import User Component">
                                        <button
                                            className="btn__new__workflow btn__import"
                                            id="btn_import_component"
                                            onClick={showCustomComponentImportModal}
                                        >
                                            <img src="/icons/workflow/Resting.svg" alt="" />
                                        </button>
                                    </TooltipTop>
                                    <TooltipTop title={'Create User Component'}>
                                        <button
                                            id="btn_new_component"
                                            className="btn-sm btn-yellow-transparent btn__new__workflow btn_new_component btn__new__action"
                                            onClick={handleClick}
                                        >
                                            <img src="/icons/workflow/add.svg" alt="" />
                                            <span> New </span>
                                        </button>
                                    </TooltipTop>
                                </div>
                            </ShowWhenTrue>
                            <div className='left-tree-holder'>
                                <div className="tree-label">
                                    <span> BUILT-IN </span>
                                </div>
                                <ul className="treeview__list">
                                    <div
                                        className={classNames(
                                            'context_menu',
                                            'context_menu_component',
                                            { context_menu_show: isShowContextMenu }
                                        )}
                                        ref={contextMenuRef}
                                        id={'Component_context_menu'}
                                    >
                                        <ul>
                                            <li id={'Component_edit'} onClick={(e: any) => handleContextEvent(e, 'edit')}>Edit</li>
                                            <li id={'Component_clone'} onClick={(e: any) => handleContextEvent(e, 'clone')}>Clone</li>
                                            <li id={'Component_delete'} onClick={(e: any) => handleContextEvent(e, 'delete')}>Delete</li>
                                            <li id={'Component_export'} onClick={(e: any) => handleContextEvent(e, 'export')}>Export</li>
                                        </ul>
                                    </div>
                                    {items.map(({ key, ...props }) => {
                                        if(Env?.REACT_APP_DISABLE_ML_PIPELINE_COMPONENT){
                                            if(activeExecutionEnv === "streaming"){
                                                if(["StructuredAnalytics"].includes(key)){
                                                    return null;
                                                }
                                            }
                                            if(["StructuredAnalytics/pipelines","StructuredAnalytics/pipelines/ml_pipeline","StructuredAnalytics/pipelines/ml_pipeline_streaming","StructuredAnalytics/pipelines/ml_pipeline_python"].includes(key)){
                                                return null;
                                            }
                                        }
                                        if(Env?.REACT_APP_DISABLE_TRIGGER_DAG_RUN_OPERATOR){
                                            if(key === "dag/TriggerDagRunOperator"){
                                                return null;
                                            }
                                        }
                                        if(Env?.REACT_APP_DISABLE_EXTERNAL_TASK_SENSOR_OPERATOR){
                                            if(["sensors","sensors/ExternalTaskSensor"].includes(key)){
                                                return null;
                                            }
                                        }
                                        return(<div
                                            className={classNames(
                                                'treeitem__container',
                                                { 'treeview__item--draggable': props.is_component },
                                                { treeitem__heading: props.level === 0 },
                                                {
                                                    hide_builtib_treedata:
                                                    props.parent === 'Custom_Component' ||
                                                    props.id === 'Custom_Component',
                                                }
                                            )}
                                            draggable={allowDrag && props.is_component}
                                            onDragStart={(event) => {
                                                event.dataTransfer.setData(
                                                    'storm-diagram-node',
                                                    JSON.stringify(props)
                                                );
                                            }}
                                            key={key + 1}
                                            id={`Component_${props.label.split(/\s/).join('')}`}
                                        >
                                            <ItemComponent
                                                key={key}
                                                className={classNames('treeview__item')}
                                                toggleNodeCb={handleToggleNode}
                                                // onContextMenu={(e: any) => contextMenuTrigger(e, props)}
                                                {...props}
                                            />
                                        </div>
                                    )})}
                                    <ShowWhenTrue show={showCustomComponent}>

                                        <div className="tree_label_custom_wrapper">
                                            <span className="tree-label">USER</span>
                                            <div>
                                                <ShowWhenTrue show={showComponentErrorMsg}>
                                                    <div
                                                        className={classNames(
                                                            'treeitem__container',
                                                            'showEmptyComponentMsg'
                                                        )}
                                                    >
                                                        No User Component Created, Click New button to create
                                                        User Component.
                                                    </div>
                                                </ShowWhenTrue>
                                            </div>
                                        

                                            {items.map(({ key, ...props }) => (
                                                <div
                                                    className={classNames(
                                                        'treeitem__container',
                                                        'hide_builtib_treedata',
                                                        { 'treeview__item--draggable': props.is_component },
                                                        { treeitem__heading: props.level === 0 },
                                                        {
                                                            show_builtib_treedata:
                                props.parent === 'Custom_Component' ||
                                props.id === 'Custom_Component',
                                                        }
                                                    )}
                                                    draggable={allowDrag && props.is_component}
                                                    onDragStart={(event) => {
                                                        if (checkIfActiveComponentFormIsSaved() || checkIfActiveComponentNotesIsSaved()) {
                                                            event.dataTransfer.setData(
                                                                'storm-diagram-node',
                                                                JSON.stringify(props)
                                                            );
                                                        }
                                                    }}
                                                    onContextMenu={(e: React.MouseEvent<HTMLDivElement>) =>
                                                        contextMenuTrigger(e, props as any)
                                                    }
                                                    key={key + 1}
                                                >
                                                    <ItemComponent
                                                        key={key}
                                                        className="treeview__item  sampleclass"
                                                        toggleNodeCb={handleToggleNode}
                                                        {...props}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </ShowWhenTrue>
                                </ul>
                            </div>
                        </>
                    )}
                </TreeMenu>
            )}
            <RenameComponentModal
                currentComponentInfo={currentContextMenuItem}
                handleRenameComponentSuccess={refreshRiTree}
            />
            {/* <ComponentEditorModal
                handleComponentEditorSuccess={handleGetComponentTreeData}
                propsEditItem={currentContextMenuItem}
            /> */}
            <ComponentImportModal
                handleComponentImportSuccess={handleComponentImportSucess}
            />
            <DeleteComponentModal
                componentId={currentContextMenuItem?.id || 0}
                componentName={currentContextMenuItem?.name || ''}
                handleDeleteComponentSuccess={refreshRiTree}
            />
            <ShowWhenTrue show={!showCreateComponentModal}>   {/* delete this file its just for demo*/}
                <CustomComponentParametersModal
                    CustomComponentParametersSuccess={
                        handleCustomComponentParametersSuccess
                    }
                />
            </ShowWhenTrue>
            <ShowWhenTrue show={showCreateComponentModal}>   {/* delete this file its just for demo*/}
                <CustomModelComponentParametersModal
                    CustomComponentParametersSuccess={
                        // handleCustomComponentParametersSuccess
                        ()=>setShowCreateComponentModal(false)
                    }
                />
            </ShowWhenTrue>
            <ComponentEditorModal
                handleComponentEditorSuccess={handleComponentEditorSucess}
            />
        </div>
    );
};

export { ComponentsTreeView };
