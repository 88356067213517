/* eslint-disable react/no-render-return-value */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { KeycloakService } from './services';
import { Provider } from 'react-redux';
import { store } from './store';

// ReactDOM.render(<App />, document.getElementById('root'));

const isDrillingDataExplorer = window.location.pathname.includes('data-explorer-drilling-app');

if(isDrillingDataExplorer){
    ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
}

if(!isDrillingDataExplorer){
    KeycloakService.initKeycloak(() => {
        return ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'))
    });
}
