const servers = ["pi-edge-and-dom"];
const databases = ["AnalysisDB"];
const sinkOptions = [{ label: 'S3', value: 's3', type: 's3' }, { label: 'Snowflake', value: 'snowflake', type: 'snowflake' }]
const modeOptions = [
    { label: "Append", value: "Append" },
    { label: "Overwrite", value: "Overwrite" },
    { label: "Ignore", value: "Ignore" },
]

const tagFilterTypeOptions = [
    { label: "Number", value: "number" },
    { label: "String", value: "string" },
    { label: "Null values", value: "null" },
]

const conditionTypeOptions = [
    { label: "Equal to", value: "equal_to" },
    { label: "Not Equal to", value: "not_equal_to" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Greater than or Equal to", value: "greater_than_or_equal_to" },
    { label: "Less than or Equal to", value: "less_than_or_equal_to" },
]

const interploted_valuesOptions = [
    { label: "Sample Values Every", value: "sample_values" },
    { label: "Use Key Column", value: "key_column" },
]

const formTitle:Record<number,string> ={
    0: "Tag Selection",
    1: "Request Parameters",
    2: "Sink Options",
    3: "Filter Options",
    4: "Record Type Options",
    5: "Schedule Options"
}

type Attribute = {
    attr_categories?: string;
    attr_id: string;
    attr_name?: string;
    attr_template?: string;
    attr_trait?: string;
    attr_type?: string;
    creation_date: string;
    effective_date: string;
    modify_date: string;
    obsolete_date: string;
    pipoint_id?: string;
    pipoint_name?: string;
    pipoint_type?: string;
    pipoint_class?: string;
    uom?: string;
    template?: string;
    path: string;
};

export type RootType = {
    element_id: string;
    element_name: string;
    parent: string;
    children: RootType[];
    parent_id?: string;
    path: string;
    creation_date: string;
    effective_date: string;
    modify_date: string;
    attributes: Attribute[]
};

export { servers, databases, sinkOptions, modeOptions, tagFilterTypeOptions, conditionTypeOptions, interploted_valuesOptions,formTitle };