/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line no-undef
import _ from 'lodash';
// import {Env} from '../../../../constants/settings';
import { store } from '@store/index';
class Field {
    constructor(name, type, defaultValue, constraint, fkTargetTables, nullable) {
        this.name = name;
        this.type = type;
        this.default = defaultValue;
        this.constraint = constraint;
        this.fkTargetTables = fkTargetTables;
        this.nullable = nullable;
    }

    isIndexed(){
        return this.name.includes('_idx');
    }
}

class Table {
    constructor(name, id, fields) {
        this.name = name;
        this.fields = fields;
        this.id = id;
    }

    getIndexFields() {
        return {
            name: this.name,
            id: this.id,
            fields: this.fields.filter((field)=>{
                return field.isIndexed();
            })
        };
    }

    toString() {
        return this.name;
    }
}

export default class Db {
    constructor(tables) {
        this.tables = tables;
        this.isPolygonal = false;
        this.isPoint = false;
    }

    getTableNames = () => {
        return this.tables.map(table => table.toString());
    };

    getTablesNamesWithIndex = () => {
        return this.tables;
        // const temp = [];
        // this.tables.forEach(ele => {
        //     temp.push(ele.getIndexFields());
        // });
        // return temp;
    };

    isTableInDb = tableName => {
        return this.getTableNames().includes(tableName);
    };

    getTable = tableName => {
        return this.tables.find(table => table.toString() === tableName);
    };

    static build(data) {
        const Env=store.getState().AccountReducer.envVariables;
        const data1 = {
            'tables': [
                {
                    'name': 'Read Hive1',
                    'id': 'output0',
                    'fields': [
                        {
                            'name': 'id',
                            'type': 'integer',
                            'default': 'nextval(\'departments_id_seq\'::regclass)',
                            'constraint': 'PRIMARY KEY',
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'name',
                            'type': 'character varying(255)',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'description',
                            'type': 'text',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'createdAt',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'updatedAt',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'index_idx',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        }
                    ]
                },
                {
                    'name': 'Read Hive2',
                    'id': 'output1',
                    'fields': [
                        {
                            'name': 'id',
                            'type': 'integer',
                            'default': 'nextval(\'departments_id_seq\'::regclass)',
                            'constraint': 'PRIMARY KEY',
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'name',
                            'type': 'character varying(255)',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'description',
                            'type': 'text',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'createdAt',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'updatedAt',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'index_idx',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        }
                    ]
                },
                {
                    'name': 'employees',
                    'id': 'output2',
                    'fields': [
                        {
                            'name': 'id',
                            'type': 'integer',
                            'default': 'nextval(\'employees_id_seq\'::regclass)',
                            'constraint': 'PRIMARY KEY',
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'firstName',
                            'type': 'character varying(255)',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'lastName',
                            'type': 'character varying(255)',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'gender',
                            'type': 'USER-DEFINED',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'birthDate',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'startDate',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'endDate',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': true
                        },
                        {
                            'name': 'createdAt',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'updatedAt',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        },
                        {
                            'name': 'roleId',
                            'type': 'integer',
                            'default': null,
                            'constraint': 'FOREIGN KEY',
                            'fkTargetTables': [
                                'roles'
                            ],
                            'nullable': true
                        },
                        {
                            'name': 'departmentId',
                            'type': 'integer',
                            'default': null,
                            'constraint': 'FOREIGN KEY',
                            'fkTargetTables': [
                                'departments'
                            ],
                            'nullable': true
                        },
                        {
                            'name': 'index_idx',
                            'type': 'timestamp with time zone',
                            'default': null,
                            'constraint': null,
                            'fkTargetTables': [],
                            'nullable': false
                        }
                    ]
                }
            ]
        };
        if(Env?.TEST_SQLBUILDER){
            data = data1;
        }
        
        const _table = {};
        let isPolygonal = false;
        let isPoint = false;
        try {
            const tables = data.tables.map(table => {
                const fields = table.fields.map(
                    (field) =>{
                        if(field.name.includes('polygon_deepiq_idx')){
                            isPolygonal = true;
                            isPoint = false;
                        } else if(field.name.includes('point_deepiq_idx')){
                            isPoint = true;
                            isPolygonal = false;
                        }
                        return new Field(
                            field.name,
                            field.type,
                            field.default,
                            field.constraint,
                            field.fkTargetTables,
                            field.nullable
                        );
                    }
                );
                let tableName = table.name;
                if(_table[tableName]){
                    let tmp = (_table[tableName]);
                    tmp++;
                    _table[tableName] = tmp;
                    tableName = `${tableName}(${tmp-1})`;
                    
                } else {
                    _table[tableName] = 1;
                }
                return new Table(tableName, table.id, fields);
            });
            const dbObj = new Db(tables);
            dbObj.isPoint = isPoint;
            dbObj.isPolygonal = isPolygonal;
            return dbObj;
        } catch (err) {
            return { error: 'error' };
        }
    }
}
