import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import styles from "../styles.module.scss";
import Form, { InputField, NewSelectField } from "@components/form";
import { _selectoptionType } from "@components/form/select-field";
import { FileHandler } from "@api/file-handler";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";

const connectionFormSchema = yup.object({
	connectionName: yup.string().required("This is a required field"),
	storageConnectionType: yup.string().required("This is a required field"),
	accessTypeName: yup
		.string()
		.nullable()
		.when("storageConnectionType", {
			is: (val) => ["AzureBlobStorage", "AzureDataLake"].includes(val),
			then: yup.string().required("This is a required field"),
		}),
	accountName: yup
		.string()
		.nullable()
		.when("storageConnectionType", {
			is: (val) => ["AzureBlobStorage", "AzureDataLake"].includes(val),
			then: yup.string().required("This is a required field"),
		}),
	accountKeyorSasToken: yup
		.string()
		.nullable()
		.when("storageConnectionType", {
			is: (val) => ["AzureBlobStorage", "AzureDataLake"].includes(val),
			then: yup.string().required("This is a required field"),
		}),
	containerName: yup
		.string()
		.nullable()
		.when("storageConnectionType", {
			is: (val) => ["AzureBlobStorage", "AzureDataLake"].includes(val),
			then: yup.string().required("This is a required field"),
		}),
	accessKey: yup
		.string()
		.nullable()
		.when("storageConnectionType", {
			is: "S3",
			then: yup.string().required("This is a required field"),
		}),
	secretKey: yup
		.string()
		.nullable()
		.when("storageConnectionType", {
			is: "S3",
			then: yup.string().required("This is a required field"),
		}),
	region: yup
		.string()
		.nullable()
		.when("storageConnectionType", {
			is: "S3",
			then: yup.string().required("This is a required field"),
		}),
	bucketName: yup
		.string()
		.nullable()
		.when("storageConnectionType", {
			is: "S3",
			then: yup.string().required("This is a required field"),
		}),
});

export type ConnectionFormValues = yup.InferType<typeof connectionFormSchema>;

export type ConnectionFormProps = {
	type: "UPDATE" | "ADD";
	initialValues: any;
	submitButtons?: React.ReactNode;
	onSubmit?: (arg0: ConnectionFormValues) => any;
};

const storageConnectionType = {
	AzureBlobStorage: "Azure Blob Storage",
	S3: "S3",
	AzureDataLake: "Azure Data Lake"
};

const azureAccessTypeOptions = [
	{
		label: "Access Key",
		value: "Access Key",
	},
	{
		label: "SAS Token",
		value: "SAS Token",
	},
];

export const convertEnumsToOptions = (
	_enum: Record<string, any>
): _selectoptionType[] =>
	Object.entries(_enum).map(([label, value]) => ({ label, value }));

export const awsRegions = [
	"af-south-1",
	"ap-east-1",
	"ap-northeast-1",
	"ap-northeast-2",
	"ap-northeast-3",
	"ap-south-1",
	"ap-southeast-1",
	"ap-southeast-2",
	"ap-southeast-3",
	"ca-central-1",
	"cn-north-1",
	"cn-northwest-1",
	"eu-central-1",
	"eu-north-1",
	"eu-south-1",
	"eu-west-1",
	"eu-west-2",
	"eu-west-3",
	"me-south-1",
	"sa-east-1",
	"us-east-1",
	"us-east-2",
	"us-gov-east-1",
	"us-gov-west-1",
	"us-west-1",
	"us-west-2",
].map((reg) => ({ label: reg, value: reg }));

export const StorageConnectionForm: React.FC<ConnectionFormProps> = ({
	onSubmit,
	type,
	initialValues,
	submitButtons,
}) => {
	const [
		storageConnectionTypeOptions,
		setStorageConnectionTypeOptions,
	] = useState<_selectoptionType[]>([]);
	const [isAccessKey, setIsAccessKey] = useState(true);
	const [selectedStorageConnectionType, setStorageConnectionType] = useState(
		initialValues?.storageConnectionType || "AzureBlobStorage"
	);
	const handleFormSubmit = (values: ConnectionFormValues) => {
		onSubmit && onSubmit(values);
	};
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
	const initialValuesForForm: ConnectionFormValues = useMemo(() => {
		return {
			connectionName: initialValues?.connectionName || "",
			storageConnectionType:
				initialValues?.storageConnectionType || "AzureBlobStorage",
			accountName: initialValues?.accountName,
			accessTypeName: initialValues?.accessTypeName || "Access Key",
			accountKeyorSasToken: initialValues?.accountKeyorSasToken,
			containerName: initialValues?.containerName || "",

			accessKey: initialValues?.accessKey || "",
			secretKey: initialValues?.secretKey || "",
			region: initialValues?.region || "",
			bucketName: initialValues?.bucketName || "",
		};
	}, [initialValues, type]);

	const handleStorageConnectionType = (storageConnectionType: string) => {
		setStorageConnectionType(storageConnectionType);
	};

	useEffect(() => {
		FileHandler.getStorageConnectionTypeOptions(`${Env?.REACT_APP_PLATFORM_URL}/filebrowser/api`, (res) => {
			setStorageConnectionTypeOptions(
				res.sort().map((_res: any) => {
					const connectionTypelabel =
						storageConnectionType[
							_res as keyof typeof storageConnectionType
						];
					return {
						label: connectionTypelabel,
						value: _res,
					};
				})
			);
		});
		handleStorageConnectionType(
			initialValues?.storageConnectionType || "AzureBlobStorage"
		);
	}, []);

	return (
		<Form
			initialValues={initialValuesForForm}
			validationSchema={connectionFormSchema}
			onSubmit={handleFormSubmit}
			enableReinitialize
		>
			<div className={styles["connectionForm"]}>
				<InputField
					name="connectionName"
					label="Connection Name"
					required={true}
					autoComplete="off"
				/>
				<NewSelectField
					name="storageConnectionType"
					label="Storage Connection Type"
					options={storageConnectionTypeOptions}
					onOptionClick={(option) => {
						handleStorageConnectionType(option.value);
					}}
					required={true}
					className="mb-0"
				/>
				{["AzureBlobStorage", "AzureDataLake"].includes(selectedStorageConnectionType) && (
					<>
						<InputField
							name="accountName"
							label="Account Name"
							required={true}
							autoComplete="off"
						/>
						<NewSelectField
							name="accessTypeName"
							label="Access Type Name"
							options={azureAccessTypeOptions}
							onOptionClick={(option) => {
								setIsAccessKey(option.value === "Access Key");
							}}
							required={true}
							className="mb-0"
						/>
						<InputField
							name="accountKeyorSasToken"
							className="secure_input_field"
							label={isAccessKey ? "Account Key" : "Sas Token"}
							required={true}
							autoComplete="off"
							isSecureInput
						/>
						<InputField
							name="containerName"
							label={"Container Name"}
							required={true}
							autoComplete="off"
						/>
					</>
				)}
				{selectedStorageConnectionType === "S3" && (
					<>
						<NewSelectField
							name="region"
							label="Region"
							options={awsRegions}
							required={true}
							className="mb-0"
						/>
						<InputField
							name="accessKey"
							className="secure_input_field"
							label={"Access Key"}
							required={true}
							autoComplete="off"
							isSecureInput
						/>
						<InputField
							name="secretKey"
							className="secure_input_field"
							label={"Secret Key"}
							required={true}
							autoComplete="off"
							isSecureInput
						/>
						<InputField
							name="bucketName"
							label={"Bucket Name"}
							required={true}
							autoComplete="off"
						/>
					</>
				)}
			</div>
			{!!submitButtons && submitButtons}
		</Form>
	);
};
