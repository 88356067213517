import { Modal } from '../../../components/modals';
import { object, string, InferType } from 'yup';
import React, { useEffect, useState } from 'react';
import Form, { InputField } from '../../../components/form';
import { toggleModal } from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { newComponentHandler } from '../../../api/new-custom-component';
import { successAlert, errorAlert } from '../../../components/toastify/notify-toast';

const newComponentSchema = object().shape({
    name: string()
        .required('Component name is required.')
});

type newComponentSchema = InferType<typeof newComponentSchema>


interface RenameComponentModalProps {
    currentComponentInfo: any;
    handleRenameComponentSuccess: () => void;
}

export const RenameComponentModal: React.FC<RenameComponentModalProps> = ({ currentComponentInfo, handleRenameComponentSuccess }) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.renameComponent);
    // const PropsItem = currentComponentInfo;
    const [PropsId, setPropsId] = useState('');
    // const [PropsName, setPropsName] = useState('');
    const [title, setTitle] = useState('');
    // const PropsId = currentComponentInfo.id;
    // const PropsName = currentComponentInfo.label;
    // const Title = 'Rename'+ PropsName + 'Component'
    const dispatch = useDispatch();
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    function toggleClose() {
        dispatch(toggleModal('renameComponent', false));
    }

    useEffect(() => {
        if (currentComponentInfo) {
            setPropsId(currentComponentInfo.id);
            // setPropsName(currentComponentInfo.label);
            setTitle(`Rename ${currentComponentInfo.label} Component`);
        }
    }, [showModal]);

    function handleComponentSuccessResponse(response: any) {
        handleRenameComponentSuccess();
        successAlert(response.message);
        toggleClose();
    }
    function renameErrorCallback(error: any) {
        errorAlert(error.response.data.message);
        toggleClose();
    }
    function handleCreateNewComponent(values: any) {
        newComponentHandler.renameCustomComponent(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, PropsId, values, activeExecutionEnv, handleComponentSuccessResponse, renameErrorCallback);
    }



    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="new__workflow__modal"
            title={title}        >
            <Form
                validationSchema={newComponentSchema}
                initialValues={{ name: '' }}
                onSubmit={handleCreateNewComponent}
            >
                <InputField
                    name="name"
                    label="New Name of Component"
                    autoComplete="off"
                    autoFocus
                />
                <div className="modalBtns__box">
                    <button
                        className="fl-r btn-md btn-yellow"
                        type="submit"
                    >
                        Create
                    </button>
                    <button
                        className="fl-r btn-md btn-cancel"
                        type="button"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>

                </div>


            </Form>

        </Modal>
    );
};