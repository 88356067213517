/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss';
import styles1 from './../styles.module.scss';
import RowRender from './components/rowRender';
import { RobotIcon } from './components/Icons';
import Loading from './components/loading';
import { useGenAIContext } from './useGenAIContext';
import { AI_RESPONSE_DATA, NEXT_ACTION_TYPE } from './types';
import { _selectoptionType } from '@components/form/select-field';
import RenderButton from './components/renderButton';
import MultiSelect from './components/multiSelect';
import { Modal } from "@components/modals";
import { DataSourceHandler } from '@api/data-source-handler';
import { Button } from 'antd';
import Form, { InputField } from '@components/form';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

const GenAIContainer = () => {
  const { loading, aiResponseList, isNextActionEnabled, nextAction, error, setFeedBack, sessionId } = useGenAIContext()
  const dummy = useRef<HTMLDivElement>(null);
  const [showFeedbackModel, setShowFeedbackModel] = useState(false);
  const quer_uuid = useRef<string>()
  const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
  useEffect(() => {
    setTimeout(() => {
      if(!dummy.current) return;
      scrollTo(dummy.current, dummy.current.scrollHeight, 300);
    }, 100);
  }, [isNextActionEnabled, loading]);

  useEffect(() => {
    const fn =  () => {
      const dom = document.getElementById("placeholderm1")
      const dom1 = document.getElementById("gen_ai")
      
      if(dom && dom1) {
        dom1.style.width = dom.getBoundingClientRect().width + 'px';
      }
    }
    window.addEventListener("resize", fn);
    const timer = setInterval(fn, 500);
    fn();

    return ()=>{
      window.removeEventListener("resize", fn)
      clearInterval(timer);
    }
  })

  const scrollTo =(element: HTMLDivElement, to: number, duration: number) => {
    let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;
    const easeInOutQuad = function (t:number, b:number, c:number, d:number) {
      t /= d/2;
      if (t < 1) return c/2*t*t + b;
      t--;
      return -c/2 * (t*(t-2) - 1) + b;
    };
        
    const animateScroll = function(){        
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if(currentTime < duration) {
          setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  const showFeed = async (dataIndex: number, data: AI_RESPONSE_DATA | NEXT_ACTION_TYPE, show: boolean, value: boolean) => {
    if(show) {
      setShowFeedbackModel(true)
      quer_uuid.current = data.query_uuid
    } else {
      await DataSourceHandler.Feedback(Env?.REACT_DEEP_SQL_URL, data.query_uuid, value, '', sessionId)
      setFeedBack(dataIndex) 
    }
  }

  return (
    <div ref={dummy} className={styles['container']}>
      {
        aiResponseList.map((data: AI_RESPONSE_DATA, index: number) => {
          return (
            <RowRender 
              key={`ai_${index}`}
              text={data.content}
              icon={<RobotIcon />}
              direction={data.message_type}
              date={data.timeStamp}
              isFeedbackSubmitted={data.isFeedbackSubmitted}
              showFeedback={(show: boolean, value: boolean)=>{
                showFeed(index, data, show, value)
              }}
              noFeedback={data.noFeedback || false}
              query_uuid={data.query_uuid}
            />    
          )
        })
      }
      {
        isNextActionEnabled && !error.state && !loading? (
          <>
            {
              nextAction.next_input_type === "BUTTONS" ? (
                <RenderButton 
                  options={nextAction.next_input_options} 
                  message={nextAction.message} 
                  showFeedback={(show: boolean, value: boolean)=>{
                    showFeed(-1, nextAction, show, value)
                  }}
                  isFeedbackSubmitted={nextAction.isFeedbackSubmitted}
                  query_uuid={nextAction.query_uuid}
                />
              ) : null 
            }
            {
              nextAction.next_input_type === "MULTI_SELECT" ? (
                <MultiSelect 
                  options={nextAction.next_input_options} 
                  message={nextAction.message} 
                  isFeedbackSubmitted={nextAction.isFeedbackSubmitted}
                  showFeedback={(show: boolean, value: boolean)=>{
                    showFeed(-1, nextAction, show, value)
                  }}
                  query_uuid={nextAction.query_uuid}
                />
              ) : null 
            }
            {
              nextAction.next_input_type === "MULTI_SINGLE_SELECT" ? (
                <MultiSelect 
                options={nextAction.next_input_options} 
                message={nextAction.message} 
                isSingleSelect
                isFeedbackSubmitted={nextAction.isFeedbackSubmitted}
                showFeedback={(show: boolean, value: boolean)=>{
                  showFeed(-1, nextAction, show, value)
                }}
                query_uuid={nextAction.query_uuid}
              />
              ) : null 
            }
          </>
        ) : null
      }
      {
        loading && !error.state ? <Loading title="Thinking" /> : null
      }
      {
        error.state ? <div className={styles['error_container']}>Something went wrong, Please try again. </div> : null
      }
      <Modal
        isOpen={showFeedbackModel}
        toggleClose={()=>{
          setShowFeedbackModel(false)}
        }
        title="Provide additional feedback"
        subtitle="How can we improve?"
        className={
          classNames(styles1['moreInfoModal'], styles['moreInfoModal'])
        }
        showCloseMark
      >
        <Form
          initialValues={{}}
          onSubmit={(value, {resetForm}) => { 
            DataSourceHandler.Feedback(Env?.REACT_DEEP_SQL_URL, quer_uuid.current as string, false, value.msg, sessionId).then(() => {
              setShowFeedbackModel(false)
              setFeedBack(-1)
            }).catch(() => {
              resetForm();
            })
            return; 
          }} 
          className='flex h-100'>
          {(formikp) => ( 
            <div>
              <div>
                <InputField
                  label=""
                  name="msg"
                  className="spark-config-field"
                  type="textarea"
                  autoComplete="off"
                />
              </div>
              <div className='mt-1 flex flex-end'>
                <Button onClick={() => formikp._formikprops.submitForm()}>Submit</Button>
              </div>
            </div>
          )}
          </Form>
      </Modal> 
    </div>
  )
}

export default GenAIContainer