import { useEffect, useState, useRef, useMemo } from 'react';
import React from 'react';
import styles from './../styles.module.scss';
import classNames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Env } from '@constants/settings';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

type leftNav = {
    id: string,
    title: string, 
    url: string, 
    disabled?: boolean,
    iconRef?: any
};

const LeftNav: React.FC<{selected_id: string}> = ({selected_id}) => {
    const [navList, setNavList]= useState<leftNav[]>([]);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    
    useEffect(() => {
        const nav: leftNav[] =  [
            {
                id: 'user',
                title: 'User Management',
                iconRef: '/icons/user-manage.svg',
                url: '/admin/user-management'
            },
            {
                id: 'asset',
                iconRef: '/icons/asset-hierarchy.svg',
                title: 'Asset Hierarchy',
                url: '/admin/asset-hierarchy'
            }
        ]
        !Env?.REACT_APP_DISABLE_GIT_INTEGRATION && nav.push(
            {
                id: 'git',
                title: 'Git Management',
                iconRef: '/icons/git-integration.svg',
                url: '/admin/git-integration-settings'
            },
        )
        Env?.REACT_SHOW_PI_REQUESTS_TABLE && nav.push(
            {
                id: 'nifi',
                iconRef: '/icons/nifi.svg',
                title: 'Edge Management',
                url: '/admin/nifi-management'
            }
        )
        setNavList(nav);
    }, [Env]);

    return (
        <div
            className={classNames(styles["treeview__container"])}
        >   
            <h2 className={styles["header"]}>Administrator</h2>
            <ul className="">
                {navList.map((tab) => (
                     <Link to={tab.url}  key={tab.id}>
                        <li
                            key={tab.id}
                            className={classNames( {
                                [styles["selected"]]: tab.id === selected_id,
                            })}
                        >
                            <Link to={tab.url}>
                                <img src={tab.iconRef} />
                                <span>{tab.title}</span>
                            </Link>
                        </li>
                     </Link>
                ))}
            </ul>
        </div>
    );
};

export default LeftNav;
