import React, { useCallback, useEffect, useState } from "react";
import styles from "../../styles.module.scss";
import LeftNav from "../../left-nav";
import { useLocation } from "react-router-dom";
import { Flex } from "@components/ui/Flex";
import { BsArrowLeft } from "react-icons/bs";
import { HierarchyData, NifiEdgeData } from "../types";
import { HierarchyModal } from "./hierarchy-model";
import { DataSourceHandler } from "@api/data-source-handler";
import { RootState } from "@store/types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { isEmpty } from "lodash";
import HierachyList from "./HierachyList";
import { errorAlert, successAlert } from "@components/toastify/notify-toast";
import { DeleteConfirm } from "@pages/admin/asset_heirarchy/confirm";
import FileMetaDataTable from "./FileMetaDataTable";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { TooltipTop } from "@components/tooltips";
import { Select, Tooltip } from "antd";
import { _selectoptionType } from "@components/form/select-field";

const { Option } = Select;

const TableColumns = [
    {
        "label": "Name",
        "value": "name"
    },
    {
        "label": "Element Type",
        "value": "elementType"
    },
    {
        "label": "Element Category",
        "value": "elementCategory"
    },
    {
        "label": "Element Path",
        "value": "elementPath"
    },
    {
        "label": "Tag",
        "value": "tag"
    },
    {
        "label": "Uom",
        "value": "uom"
    },
    {
        "label": "Created Date",
        "value": "createdDate"
    },

    {
        "label": "Updated Date",
        "value": "updatedDate"
    },
];

const Hierarchy: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [hierarchyData, setHierarchyData] = useState<HierarchyData[] | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [hierarchyDataModal, setHierarchyDataModal] = useState<HierarchyData | null>(null);
    const [csvFileRecords, setCsvFileRecords] = useState<any>(null);
    const [csvFileRecordsColumns, setCsvFileRecordsColumns] = useState<_selectoptionType[]>(TableColumns);
    const [isLoading, setIsLoading] = useState(false);
    const [hierarchy, setHierarchy] = useState<HierarchyData>({} as HierarchyData);
    const [hierarchyFilteredResults, setHierarchyFilteredResults] = useState<
        HierarchyData[] | null
    >([])
    const [isHierarchyUpdatingCreating, setIsHierarchyUpdatingCreating] = useState(false);
    const [nifiEdgeData, setNifiEdgeData] = useState<NifiEdgeData[]>([]);
    const [selectedNode, setSelectedNode] = useState<string | undefined>(undefined);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const nodeName = queryParams.get("nodeName");
    const { envVariables: Env } = useSelector((store: RootState) => store.AccountReducer);


    const handleDeleteConfirm = (item: HierarchyData) => {
        setShowDeletePopup(true);
        setHierarchyDataModal(item);
    }

    const handleDelete = () => {
        if (hierarchyDataModal?.id) {
            DataSourceHandler.DeleteHierarchy(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, hierarchyDataModal?.id || 0, (res) => {
                getHierarchyData();
                getDirectHierarchyData(selectedNode || nodeName || '');
                setIsLoading(false);
                setHierarchyDataModal(null);
                setShowDeletePopup(false);
                successAlert(res)
            }, (e: any) => {
                setIsLoading(false);
                errorAlert(e.data)
                setHierarchyDataModal(null);
                setShowDeletePopup(false);
            });
        }
    }

    const handleEdit = (item: HierarchyData) => {
        setHierarchyDataModal(item);
        setShowModal(true);
    }

    const handleViewCsvFileMetadata = (item: HierarchyData) => {

        setIsLoading(true);
        DataSourceHandler.ViewCsvFileMetadata(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, item.hierarchyName, (res) => {
            if (isEmpty(res?.data)) {
                errorAlert("No data present with this hierarchy");
            }
            setHierarchy(item);
            setCsvFileRecords(res?.data);
            if(!isEmpty(res?.headers)){
                setCsvFileRecordsColumns(res?.headers);
            }
            setIsLoading(false);
        }, (e: any) => {
            setIsLoading(false);
        });
    }

    const getHierarchyData = useCallback(() => {
        setIsLoading(true);
        DataSourceHandler.getHierarchyData(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, (res) => {
            const edgeData = res.filter((item: HierarchyData) => item.edgeName === nodeName);
            setHierarchyData(edgeData);
            setIsLoading(false);
        }, (e) => {
            errorAlert(e.data);
            setIsLoading(false);
        });
    }, [hierarchyData, Env]);

    const getNifiEdgeData = useCallback(() => {
        DataSourceHandler.GetEdgeNodes(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, (res) => {
          setNifiEdgeData(res);
        });
      }, [Env?.REACT_APP_PLATFORM_URL]);

      const getDirectHierarchyData = (name: string) => {
        setSelectedNode(name)
        setIsLoading(true);
        DataSourceHandler.getHierarchyData(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, (res) => {
            const edgeData = res.filter((item: HierarchyData) => item.edgeName === name);
            setHierarchyData(edgeData);
            setIsLoading(false);
        }, (e) => {
            errorAlert(e.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (!isEmpty(Env?.REACT_APP_PLATFORM_URL)) {
            getHierarchyData();
        }
        if(!window.location.href.includes("?nodeName=")){
            getNifiEdgeData();
        }
    }, [Env])

    useEffect(() => {
        if (nifiEdgeData.length > 0) {
          setSelectedNode(nifiEdgeData[0].nodeName); 
          getDirectHierarchyData(nifiEdgeData[0].nodeName); 
        }
      }, [nifiEdgeData]);

      useEffect(() => { 
        if (isHierarchyUpdatingCreating && selectedNode) {
          getDirectHierarchyData(selectedNode);
          setIsHierarchyUpdatingCreating(false);
        }
      }, [isHierarchyUpdatingCreating]);

    
    const filteredData = searchTerm.length === 0
        ? hierarchyData
        : hierarchyFilteredResults

        const Wrapper = ({ children }: { children: React.ReactElement }) => {
            if (isLoading) {
                return (
                    <div className={styles["centerLoading"]}>
                        <InPageSpinner size="Large" color="white" />
                    </div>
                );
            }
        
            if (!isLoading && isEmpty(filteredData)) {
                return (
                    <div className={styles["centerLoading"]}>
                        <h3 className="white">No data available.</h3>
                    </div>
                );
            }
        
            return <div className="mt-1">{children}</div>;
        };
        

    const fileContent = csvFileRecords?.map(({ csvMetaData, licenseId, deleted, ...rest }: {
        csvMetaData: any;
        licenseId: any;
        deleted: any;
        id: any;
        [key: string]: any;
    }) => rest);


    const handleSaveCsvFileMetadata = () => {
        handleViewCsvFileMetadata(hierarchy)

    }

    const searchData = (text: string) => {
        setSearchTerm(text);
        setHierarchyFilteredResults(
            hierarchyData?.filter((data) =>
                data.hierarchyName.toLowerCase().includes(text.toLowerCase())
            ) || []
        );
    };

    const handleBackToSelectedNode = () => {
        if (selectedNode) {
            setHierarchy({} as HierarchyData) 
            getDirectHierarchyData(selectedNode); 
            setCsvFileRecords([]);
            setCsvFileRecordsColumns(TableColumns);
        }
    };

    return (
        <section className={styles['hierachy__container']}>
            <LeftNav selected_id="asset" />
            <div className={styles['nifi_content']}>
                <header>
                    <h1>
                        <img src="/icons/asset-hierarchy.svg" alt="" />
                        Asset Hierarchy
                    </h1>

                </header>
                <Flex flexDirection={"column"} gap="8px">
                    {!(window.location.href.includes("?nodeName=")) && isEmpty(hierarchy) && (
                        <Select
                            className="assetStateSelect"
                            placeholder="Select a Node"
                            style={{ width: 250, marginBottom: 10 }}
                            value={selectedNode}
                            onChange={getDirectHierarchyData}
                        >
                            {Array.isArray(nifiEdgeData) ? nifiEdgeData.map((node) => (
                                <Option className="assetStateSelectItem" key={node.nodeName} value={node.nodeName}>
                                    <TooltipTop title={`${node.nodeName} (${node.nodeKind})`}>
                                        <span className="directory__item-text">
                                            {node.nodeName} ({node.nodeKind})
                                        </span>
                                    </TooltipTop>
                                </Option>
                            )) : null}
                        </Select>
                    )}
                        {!(window.location.href.includes("?nodeName=")) && !isEmpty(hierarchy) && <Flex flexDirection={"row"} alignItems={"center"} style={{ cursor: "pointer", marginBottom: "0px", backgroundColor: "#212233", padding: "10px", borderRadius: "2px", width: "fit-content" }} gap="10px" onClick={handleBackToSelectedNode} ><BsArrowLeft /><h3 className="white"> Edge ({selectedNode})</h3></Flex>}
                        {!(window.location.href.includes("?nodeName=")) && !isEmpty(hierarchy) &&<Flex flexDirection={"row"} alignItems={"center"} style={{ cursor: "pointer", marginBottom: "0px", backgroundColor: "#212233", padding: "10px", borderRadius: "2px", width: "fit-content" }} gap="10px" onClick={handleBackToSelectedNode} > <TooltipTop title="Back To Hierarchy"><BsArrowLeft /></TooltipTop>  <h3 className="white"> Hierarchy ({hierarchy?.hierarchyName})</h3>   </Flex>}
                        {window.location.href.includes("?nodeName=") && <><Flex flexDirection={"row"} alignItems={"center"} style={{ cursor: "pointer", marginBottom: "0px", backgroundColor: "#212233", padding: "10px", borderRadius: "2px", width: "fit-content" }} gap="10px" onClick={() => history.back()} > <TooltipTop title="Back To Edge Management"><BsArrowLeft /></TooltipTop>  <h3 className="white"> Edge ({nodeName})</h3>   </Flex>

                        <ShowWhenTrue show={!isEmpty(hierarchy)}>
                            <Flex flexDirection={"row"} alignItems={"center"} style={{ cursor: "pointer", marginBottom: "0px", backgroundColor: "#212233", padding: "10px", borderRadius: "2px", width: "fit-content" }} gap="10px" onClick={() => setHierarchy({} as HierarchyData)} > <TooltipTop title="Back To Hierarchy"><BsArrowLeft /></TooltipTop>  <h3 className="white"> Hierarchy ({hierarchy?.hierarchyName})</h3>   </Flex>

                        </ShowWhenTrue></>}
                    
                    <ShowWhenTrue show={isEmpty(hierarchy)}>
                        <div className={"mb-0"}>
                            <div className={styles["search_container"]}>
                                <div
                                    className={classNames(
                                        "search__input__container",
                                        styles["search_container"]
                                    )}
                                >
                                    <input
                                        autoFocus
                                        className={classNames(
                                            "search_input",
                                            styles["search_input"]
                                        )}
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => searchData(e.target.value)}
                                    />
                                    {searchTerm.length === 0 ? null : (
                                        <img
                                            src="/icons/treeview/search-cross.svg"
                                            alt=""
                                            className="search_reset"
                                            style={{
                                                top: 26,
                                            }}
                                            onClick={() => {
                                                searchData("");
                                            }}
                                        ></img>
                                    )}
                                </div>

                                <Flex>
                                    <button
                                        className={classNames(
                                            "btn-lg btn-yellow-transparent",
                                            styles["center"],
                                            styles["btn-lg"]
                                        )}
                                        onClick={() => {
                                            setHierarchyDataModal(null);
                                            setShowModal(true);
                                        }}
                                    >
                                        <img src="/icons/workflow/add.svg" alt="" />
                                        <span> Hierarchy </span>
                                    </button>
                                </Flex>
                            </div>
                        </div>
                    </ShowWhenTrue>
                </Flex>
                <Wrapper>
                    <div>
                        <DeleteConfirm
                            onConfirm={() => handleDelete()}
                            showModal={showDeletePopup}
                            title={`Are you sure you want to delete the ${hierarchyDataModal?.hierarchyName || ""} Hierarchy?`
                            }
                            toggleClose={() => {
                                setHierarchyDataModal(null);
                                setShowDeletePopup(false);
                            }}
                        />
                        <ShowWhenTrue show={isEmpty(hierarchy)}>
                            <HierachyList data={filteredData} handleDelete={handleDeleteConfirm} handleEdit={handleEdit} handleViewCsvFileMetadata={handleViewCsvFileMetadata} />
                        </ShowWhenTrue>
                    </div>
                </Wrapper>
                <HierarchyModal
                    showModal={showModal}
                    toggleClose={() => {
                        setHierarchyDataModal(null);
                        setShowModal(false);
                    }}
                    getHierarchyData={getHierarchyData}
                    edgeName={nodeName || selectedNode ||''}
                    hierarchyDataModal={hierarchyDataModal}
                    setIsHierarchyUpdatingCreating={setIsHierarchyUpdatingCreating}
                />
                <ShowWhenTrue show={!isEmpty(hierarchy) && !isLoading}>
                    <FileMetaDataTable
                        content={fileContent}
                        tableColumns={csvFileRecordsColumns}
                        hierarchy={hierarchy}
                        onSave={() => handleSaveCsvFileMetadata()}
                    />
                </ShowWhenTrue>

            </div>
        </section>
    );
};

export default Hierarchy;
