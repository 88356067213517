import { HttpClient } from '../services';
// import { API } from '../../constants/settings';
import { ApiResponse } from '../data-source-handler';
import { WorkspaceSuccessResponse, EnableWorkSpaceReq } from './types';
import { WorkspaceData } from '@pages/cluster_redisign/types';

class WorkSpaceHandlerClass {
    
    CreateNewWorkSpace(link: string, data: WorkspaceData, callback: ApiResponse<WorkspaceSuccessResponse>, errorHandlerCallback: ApiResponse<any>) {
        HttpClient.Post('Create a WorkSpace', link + '/workspace/create', data, callback, errorHandlerCallback);
    }
    
    GetWorkSpaceList( link: string, callback: ApiResponse<WorkspaceData[]>) {
        HttpClient.Get('Get all WorkSpace list', link + '/workspace/list', callback);
    }
  
    UpdateWorkSpace(link: string, data: WorkspaceData, callback: ApiResponse<WorkspaceSuccessResponse>, errorHandlerCallback: ApiResponse<any>) {
        HttpClient.Put('Update WorkSpace Details', link + '/workspace/update', data, callback, errorHandlerCallback);
    }

    DeleteWorkSpace(link: string, _workspaceId: string, callback: ApiResponse<WorkspaceSuccessResponse>, errorHandlerCallback?: ApiResponse<any>) {
        HttpClient.Delete('Delete WorkSpace', link + `/workspace/delete/${_workspaceId}`, callback, errorHandlerCallback);
    }
     
    enableWorkSpace(link: string, data: EnableWorkSpaceReq, callback: ApiResponse<WorkspaceSuccessResponse>, errorHandlerCallback: ApiResponse<any>) {
        HttpClient.Post('Enable/disable a WorkSpace', link + '/workspace/default', data, callback, errorHandlerCallback);
    }
    
}

const WorkSpaceHandler = new WorkSpaceHandlerClass();

export { WorkSpaceHandler };