import React, { useEffect, useState } from "react";
import Form, { InputField, RadioField, SelectField } from "../../components/form";
import AutoSave from "../../components/form/Autosave";
import { _selectoptionType } from "../../components/form/select-field";
import { ToggleField } from "../../components/form/toggle-field";
import { FieldSchemaValidator, FieldSchemaCreator } from "../../components/formcreators/schema-creator";
import { ShowWhenTrue } from "../../helpers";
import { WorkflowCanvasTabInfo } from "../../store/canvas";
import { convertEnumsToOptions } from "../../utils";
import { notificationTypeOptions, ScheduleFields, RenderSuggestedScheduleTimes, ScheduleTiming } from "./modals/schedule-workflow";
import { updateExecutionEnv } from '../../store/common';
import { useDispatch } from "react-redux";
import { ExecutionEnvModes } from "@constants/enums";
import { Text } from "@components/ui/Text";
import { useAppSelector } from "@store/hooks";
import { ClusterState } from "@api/databricks-handler";
import { Flex } from "@components/ui/Flex";

type SchedulePipelinesForm = {
    dagFormInitialValues: NonNullable<WorkflowCanvasTabInfo['scheduleInfoForDag']>;
    handleScheduleRhsFormSubmit: (values: WorkflowCanvasTabInfo['scheduleInfoForDag']) => void;
    handleScheduleDagWorkflow: () => void;
	isJobScheduled: boolean;
	isDagEnv?: boolean;
	isHierarchy?: boolean;
}

export enum ExecutionTimeoutFrequency {
	Seconds = "secs",
	Minutes = 'mins',
	Hours = "hours",
}

enum ExecutionTimeoutFrequencyMapping {
	"secs" = "seconds",
	'mins' = "minutes",
	"hours" = 'hours'
}

const executionTimeoutOptions = convertEnumsToOptions(ExecutionTimeoutFrequency)

const SchedulePipelinesForm: React.FC<SchedulePipelinesForm> = ({ dagFormInitialValues, handleScheduleRhsFormSubmit, handleScheduleDagWorkflow,isJobScheduled,isDagEnv,isHierarchy }) => {
    const [suggestedTimes, setSuggestedtimes] = useState<string[]>([]);
	const dispatch = useDispatch();
	const { clusters } = useAppSelector((store) => store.ClusterReducer);
    const [clusterData, setClusterDropDown] = useState<_selectoptionType[]>([]);

	const handleGotoConfigureSettings = () => {
		dispatch(updateExecutionEnv(ExecutionEnvModes.Pipelines));
	}

	useEffect(() => {
        const options: _selectoptionType[] = [{
            label: "CHOOSE A CLUSTER",
            value: "choose_cluster"
        }];

        clusters.forEach(cluster => {
            if(cluster.npipWorkSpace && cluster.state === ClusterState['RUNNING']) {
                options.push({
                    label:cluster.clusterName,
                    value: cluster.databricksClusterId
                })
            }
        });
        setClusterDropDown(options)
    }, [clusters]);

    return (
		<Form
			initialValues={dagFormInitialValues}
			onSubmit={handleScheduleRhsFormSubmit}
			className="scheduleForm__propertiesRHS"
			enableReinitialize
		>
			{({ _formikprops: { values, setFieldValue } }) => {
				return (
					<>
						<div>
							<ShowWhenTrue show={!isDagEnv}>
								<Text px={3} py={2}>
									This is an interface for you to schedule the current workflow as is. Please use the configurations below before scheduling. For any advanced settings or configurations, please use the  <span onClick={handleGotoConfigureSettings} 
									style={{
										color: '#fcad01',
										cursor:'pointer',
										textDecoration:'underline'
									}}>Pipeline</span> Experience.
								</Text>
								<SelectField
									name="cluster"
									options={clusterData}
									placeholder="Choose a cluster"
									onOptionClick={(option) => {
										setFieldValue(
											"cluster",
											option.value
										)
									}}
								/>
								
							</ShowWhenTrue>
							<ShowWhenTrue show={!isHierarchy}>
								<InputField
									name="dag_id"
									label="Job Name"
									infoText="The name to schedule this pipeline with. This pipeline will appear on the job dashboard with the specified name."
									className="mb-1"
								/>
							</ShowWhenTrue>
							<ToggleField
								label="Advanced Options"
								active={values.scheduleAdvancedMode}
								onClick={() =>
									setFieldValue(
										"scheduleAdvancedMode",
										!values.scheduleAdvancedMode
									)
								}
							/>
							<ShowWhenTrue show={values.scheduleAdvancedMode}>
								<div className="split__fields--halfwide">
									<InputField
										name="retries"
										label="Number of failed Retries"
										autoComplete="off"
										infoText="Number of times to retry executing the pipeline, in case of failure."
										className="retries__dropdown"
										validate={FieldSchemaValidator(
											FieldSchemaCreator({
												validationType:'POSITIVE_NUMBER',
												required: values?.exponential_backoff,
											}) as any
										)}
									/>
									<InputField
										name="retryDelay"
										label="Time between retries"
										autoComplete="off"
										infoText="Time Between Retries: The time (in seconds) between each retry attempt."
										validate={FieldSchemaValidator(
											FieldSchemaCreator({
												validationType:'POSITIVE_NUMBER_WITHOUT_ZERO',
												required: values?.exponential_backoff,
											}) as any
										)}
									/>
								</div>
								<RadioField
									name="exponential_backoff"
						            label="Exponential Backoff"
									options={[{label:"True", value:true},{label:"False", value:false}]}
									infoText='Exponential backoff is a retry strategy that progressively increases the delay between task retries,
									 doubling the wait time after each failure to reduce the load on resources. Set True to enable'				
									className='catchup-radio-field'
									inline
								/>
								<div
									className="split__fields--halfwide executionFrequency"
								>
									<InputField
										name="execution_timeout"
										label="Execution Timeout"
										autoComplete="off"
										// @ts-ignore
										infoText="Max time allowed for the execution of this task instance, if it goes beyond it will raise and fail."
										validate={FieldSchemaValidator(
											FieldSchemaCreator({
												variable_type:'integer',
												required: false,
											}) as any
										)}
									/>
									<SelectField 
										name="execution_timeout_frequency"
										options={executionTimeoutOptions}
										label="Execution Frequency"
									/>
								</div>
								<ToggleField
									label="Email Notification"
									active={values.overrideNotificationEmail}
									onClick={() =>
										setFieldValue(
											"overrideNotificationEmail",
											!values.overrideNotificationEmail
										)
									}
								/>
								<ShowWhenTrue
									show={values.overrideNotificationEmail}
								>
									<div className="split__fields--halfwide">
										<InputField
											name="notificationEmails"
											label="Email Address"
											infoText="Email address/addresses to be notified. Can be provided as a comma/space/semi-colon separated list."
											validate={FieldSchemaValidator(
												FieldSchemaCreator({
													variable_type: "string",
													required: true,
												}) as any
											)}
										/>
										<SelectField
											infoText='Specify when to notify the email list. Currently supporting only "Failure", meaning, the provided email addresses will be notified when the schedule fails.'
											name="notificationType"
											label="Notification Type"
											options={notificationTypeOptions}
											disabled
										/>
									</div>
								</ShowWhenTrue>
							</ShowWhenTrue>
							<ScheduleFields
								setSuggestedScheduleTimes={setSuggestedtimes}
								isHierarchy={isHierarchy}
							/>
							<ShowWhenTrue show={values.scheduleAdvancedMode}>
								<InputField
									name="max_active_runs"
									label="Max Active Runs"
									validate={FieldSchemaValidator(
										FieldSchemaCreator({
											validationType:"POSITIVE_NUMBER",
										}) as any
									)}
								/>
								<RadioField
									name="catchup"
									options={[{label:"True", value:true},{label:"False", value:false}]}
									label='Catchup'				
									className='catchup-radio-field'
									inline
								/>
							</ShowWhenTrue>
							{values.scheduleTimingInfo === ScheduleTiming.Recurring &&
								<RenderSuggestedScheduleTimes
									title="Upcoming Schedule Preview"
									suggestedScheduleTimes={suggestedTimes}
								/>
							}
						</div>
						{/* <AutoSave debounceMs={1500} /> */}
						<ShowWhenTrue show={!isHierarchy}>
							<div className="scheduleForm__propertiesRHS__actionBtns">
								<button
									className="btn-yellow btn-lg"
									type="submit"
									disabled={isJobScheduled}
									// onClick={handleScheduleDagWorkflow}
								>
									Confirm and Schedule
								</button>
							</div>
						</ShowWhenTrue>
					</>
				);
			}}
		</Form>
	);
};

export { SchedulePipelinesForm };
