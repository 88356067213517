import React, { useCallback, useState } from 'react';
import { CaretDown } from '@components/coachmark/Icons';
import moment from 'moment';
import classNames from 'classnames';
import { useToggle } from 'rooks';
import { HttpClient } from '@api/services';
import { Modal } from "@components/modals";
import { isEmpty } from 'lodash';
import { InPageSpinner } from '@components/spinners/in-page-spinner';
import WriteCsvForm from './writeCsvForm';
import { useDataExplorerContext } from '../useDataExplorerContext';
import { AxiosResponse } from 'axios';
import { JobInfo } from '@api/job-handler';
import { errorAlert, successAlert } from '@components/toastify/notify-toast';
import { DownloadIcon } from '@pages/data-browser/icons';
import { AdjustToCellSizeTable } from '@components/table';
import styles from './../styles.module.scss';
import { ShowWhenTrue } from '@helpers/showwhentrue';
import CustomMonacoEditor from '@components/monacoEditor';
import { WarningCircleIcon } from '../icons';
import { saveTextToFile } from '@utils/common';
import QueryChartsModal from './queryChartsModal';
import { API, Env } from '@constants/settings';
import { RootState } from '@store/types';
import { useSelector } from 'react-redux';

const TableContainer:React.FC<{}> = () => {
    const [showWriteCSV,setShowWriteCSV] = useState(false);
    const [showQueryCharts,setShowQueryCharts] = useState(false);
    const [showViewLog,setShowViewLog] = useState(false);
    const [showLoader,setShowLoader] = useState(false);
    const [jobLog,setJobLog] = useState<any>(undefined);
    const { tableData, sqlQuery } = useDataExplorerContext();
    const [downloadCsvStatus,setDownloadCsvStatus] = useState("none");
    const editorControllerProps = CustomMonacoEditor.useCustomMonacoEditorController();
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    const handleSubmit = (resp:any)=>{
        setDownloadCsvStatus("loading");
        setShowWriteCSV(false);
        const timer = setInterval(async function () {
            const recentJobs: AxiosResponse<JobInfo[]> = await HttpClient.GetPromise(`${Env.REACT_APP_PLATFORM_URL}/databrowser/api/batches/job/recent?env=spark`);
            const job = recentJobs.data?.filter((item) => item.id === resp.data.id)[0];
            if (!isEmpty(job) && job?.state !== "running") {
                setShowLoader(false);
                if (job?.state === "success") {
                    setDownloadCsvStatus("none");
                    successAlert("CSV file is generated successfully!")
                } else {
                    setJobLog(job);
                    setDownloadCsvStatus("error");
                    errorAlert("Error in generating csv file!");   
                    editorControllerProps.setEditorValue(job.log?.toString()||"")
                }
                clearInterval(timer);
            }
        }, 3000);
    }
    
    const downloadJobLogs = () => {
        if(jobLog?.log) {
            const fileName = "download-csv-failed-log" + '_' + moment(new Date()).format('YYYY_MM_DD_HH_mm_ss_SSS');
            saveTextToFile(fileName+ '.log', jobLog.log.join('\n'));
        }
    };

    const handleQueryChartsOpen = useCallback(() =>{
        setShowQueryCharts(true);
    },[])

    const handleQueryChartsClose = useCallback(() =>{
        setShowQueryCharts(false);
    },[])

    return (
        <>
            <Modal
                isOpen={showWriteCSV}
                toggleClose={()=>{setShowWriteCSV(false);setJobLog(undefined)}}
                title="Download results as CSV"
                subtitle={
                    <div className={styles["writeCsv-modal-subtitle"]}>
                    Writes any spark dataframe as a delimited file (csv) into azure blob, DBFS, or S3.
                    </div>
                }
                className={classNames("mf_model",
                styles["addEditWriteCsvModal_"]
                )}
                showCloseMark
                shouldCloseOnOverlayClick={false}
            >
            <div className={styles["addEditWriteCsvModal_innerContainer"]}>
                    <WriteCsvForm
                        onSubmit={handleSubmit}
                        setShowLoader={setShowLoader}
                        submitButtons={
                            <div
                                className={styles["addEditWriteCsvModal_actionBtns"]}
                            >
                                <button
                                    className={classNames(
                                        "btn btn-lg",
                                        styles["write-csv-cancel-btn"]
                                    )}
                                    type="button"
                                    onClick={()=>{setShowWriteCSV(false)}}
                                    >
                                    Cancel
                                </button>
                                <button
                                    className={classNames("btn btn-lg btn-yellow",
                                        styles["write-csv-edit-btn"]
                                    )}
                                    type="submit"
                                    disabled={showLoader}
                                >
                                    {showLoader ? (
                                        <InPageSpinner
                                            size="XSmall"
                                            color="black"
                                            className={styles["auto"]}
                                        />
                                    ) : 'Download CSV'}
                                </button>
                            </div>
                        }
                    ></WriteCsvForm>
                </div>
            </Modal> 
            <Modal
                isOpen={showViewLog}
                toggleClose={()=>setShowViewLog(false)}
                title="View Log"
                subtitle={
                    <div className={styles["writeCsv-modal-subtitle"]}>
                    Download as CSV failed
                    </div>
                }
                className={classNames("mf_model",
                styles["addEditWriteCsvModal_"]
                )}
                showCloseMark
                shouldCloseOnOverlayClick={false}
            >
            <div className={styles["addEditWriteCsvModal_viewLogContainer"]}>
                <div className=''>
                    <CustomMonacoEditor
                        {...editorControllerProps}
                        editorHeight={400}
                        editorWidth="100%"
                        language="string"
                        toolbarOptions={[]}
                    >
                        <div  className={styles["flex-end"]}>
                            <button 
                                className={classNames(styles['downloadCsvButton'], styles['ml-1'],styles['mt-2'])}
                                onClick={()=>{downloadJobLogs()}}
                            >
                                 <DownloadIcon /> &nbsp; Download
                            </button>
                        </div>
                    </CustomMonacoEditor>
                </div>
            </div>
            </Modal> 
            <Modal
                isOpen={showQueryCharts}
                toggleClose={handleQueryChartsClose}
                title="Explanation"
                className={classNames("mf_model",
                styles["addEditWriteCsvModal_"]
                )}
                showCloseMark
                shouldCloseOnOverlayClick={false}
            >
                <div className={styles["queryChartsContainer"]}>
                    <QueryChartsModal onClose={handleQueryChartsClose} sqlQuery={sqlQuery}/>
                </div>
            </Modal>
            <div className={classNames(styles['panel'], styles['m-1'])}>
                <div className={styles["space-between"]}>
                    <div className={styles["page_title"]}>Answer</div>
                    <div className={styles["space-between"]}>
                            <button 
                                className={classNames(styles['downloadCsvButton'], styles['ml-1'])}
                                onClick={()=>{setShowWriteCSV(true)}}
                            >
                                <DownloadIcon   />&nbsp; Download
                            </button>
                        <ShowWhenTrue show={downloadCsvStatus === "loading"}>
                            <button 
                                className={classNames('row-col-center', styles['ml-1'])}
                                disabled
                            >
                               Downloading &nbsp; <InPageSpinner size="XSmall" color="white" /> 
                            </button>
                        </ShowWhenTrue>
                        <ShowWhenTrue show={downloadCsvStatus === "error"}>
                            <div className='row-col-center'>
                                <button 
                                    className={classNames("download-failed-btn", styles["ml-1"])}
                                    >
                                    <WarningCircleIcon  /> &nbsp; Download failed
                                </button>
                                <button 
                                    className={classNames("retry-btn", styles["ml-1"])}
                                    onClick={()=>setShowViewLog(true)}
                                    >
                                        View Log
                                    </button>
                                    <button 
                                    className={classNames("retry-btn", styles["ml-1"])}
                                    onClick={()=>{setShowWriteCSV(true)}}
                                    >
                                        Retry Download
                                    </button>
                            </div>
                        </ShowWhenTrue>
                        {Env?.ENABLE_DATA_EXPLORER_EXPLAIN &&<button 
                                className={classNames(styles['downloadCsvButton'], styles['ml-1'])}
                                onClick={handleQueryChartsOpen}
                            >
                                Explain
                            </button>}
                    </div>
                </div>
                <div className={classNames(styles['table-holder'])}>
                    <div style={{
                        minHeight: '6rem',
                        height: 'auto'
                    }}>
                        <AdjustToCellSizeTable
                            data={tableData}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableContainer;
