import { trimStart, omit, cloneDeep, snakeCase } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { AppThunk, RootState } from '../types';
import { WorkflowHandler, WorkflowSearchQuery, ProfileInfo, PreviewStatusResponse, ClusterInfo, DirectoriesInfoResponse, WorkflowSortType, DetailedWorkflowInfo, CloneWorkflowData, CreateWorkflowResponse, CreateWorkflow, UpdateWorkflow, UpdateWorkflowResponse } from '../../api/workflow-handler';
import {
    RETRIEVE_WORKFLOWS, RetrieveWorkflowsPayload, TOGGLE_COMPONENTS_TREEVIEW,
    WorkflowsSelectionStatus, CHANGE_WORKFLOWS_SELECTION, SelectionType, SET_ACTIVE_SELECTION_TYPE,
    ModalTypes, TOGGLE_MODAL, SELECT_SINGLE_WORKFLOW, SingleItemInfo, SHOW_PROPERTIES, HIDE_PROPERTIES, ComponentStatus,
    SET_ACTIVE_TAB_IN_WORKFLOW_PAGE, LIST_JAR_FILES, SET_ACTIVE_PROFILE, SET_CUSTOMCOMPONENTNAME, 
    customComponentName, SET_COMPONENT_ID_FOR_RUN_TILL_SHOW, SET_ADDITIONAL_FORM_DATA,
    SET_ACTIVE_COMPONENT_INFO, SET_ACTIVE_COMPONENT_PROPERTIES_STATE,
    ActiveComponentInfo, WorkflowReducerState, SET_COPIED_COMPONENT_INFO,
    componentToBeCopiedInfo, WorkflowsListParams, SET_WORKFLOW_PARAMS,
    SET_CAPTURE_SCHEMA_LOGS, componentParamenters, SET_COMPONENTS_PARAMETERS,
    SET_CALLBACK_FUNCTION, SET_ACTIVE_CLUSTER, REFRESH_COMPONENT_FORM_DATA, SET_MODAL_TYPE_IN_WORKFLOWS_PAGE, SET_VARIABLE_COMPONENT_INFO, TOGGLE_CONSOLE_HEIGHT,
    SET_CUSTOM_COMPONENTS_LIST, SET_COMPONENT_FN_NAMES_INFO,
    SET_CUSTOM_COMPONENT_FN_NAMES_COUNT, SET_CUSTOMCOMPOENENTUPDATEID,SET_ACTIVE_UPDATECOMPONENTPARAMETER,SET_CUSTOMCOMPONENt_MODALTYPE , SET_ACTIVE_UPDATE_MODALTYPE, TOGGLE_WORKFLOW_CANVAS_SPINNER, 
    SET_WORKFLOW_TAB_SELECTION, SET_JOBS_LIST, SET_COMPONENT_TREE_DATA, ComponentTreeDataType, FETCH_GITREPOSITORIES_REQUEST,FETCH_GITREPOSITORIES_FAILURE, FETCH_GITREPOSITORIES_SUCCESS, SET_SELECTED_EXPERIMENT
} from './types';
import { hideSpinner, SpinnerTypes, showSpinner, setActiveExecutionEnv } from '../common';
import { CanvasTabs, DagCanvasTabs, InnerWorkflowTabs } from '../../pages/workflow-page/enums';
import { _selectoptionType } from '../../components/form/select-field';
import { WorkflowCanvasTab, updateAWorkflowEditorTabInfo, switchToExistingCanvasTab, WorkflowCanvasTabInfo, openANewWorkflowEditorTabUsingWorkflowInfo, getTabType, PageTypes, updateAWorkflowAnalyticsTabInfo, WorkflowAnalyticsTab, updateOpenTabsOfACanvas, updateACanvasTabInfo } from '../canvas';
import { addLabelsAndIsFieldHiddenToNodesIfEmpty, checkIfVariableComponentsExist, cloneWorkflowInfo, convertWorkflowDataForExecution, getDependentWorkflowsInfo, getWorkflowInfo, serializeWorkflow, WorkflowDependenciesRef } from '../../pages/workflow-page/utils';
import { JobInfo, JobsHandler } from '../../api/job-handler';
import { errorAlert, infoAlert, successAlert } from '../../components/toastify/notify-toast';
import { BaseModel, BaseModelGenerics } from '@projectstorm/react-canvas-core';
import { DsNodeModel } from '../../components/workflow-canvas/node/ds_nodemodel';
import { ApiResponse } from '../../api/data-source-handler';
// import { setUserDirectories } from '../account';
import { AccountReducer, setUserDirectories } from '../account';
import { SerializedData, WorkflowCanvas, WorkflowMetaData, WorkflowSerializedObjData } from '../../components/workflow-canvas';
import { AnalyticsHandler, SessionInfo, StreamingStatementData, SubmitStatementData } from '../../api/analytics-handler';
import { getScheduleFormInitialValues, notificationTypeOptions } from '../../pages/workflow-page/modals/schedule-workflow';
import { uuid } from 'uuidv4';
import { getActiveWorkflowType } from '../../utils';
import { ClusterState } from '../../api/databricks-handler';
import { DsPortModel } from '../../components/workflow-canvas/port/ds_portmodel';
import { ExecutionEnvModes } from '../../constants/enums';
import { convertSerializedWorkflowDataToModel, serializeWorkflowModel } from '../../components/workflow-canvas/utils';
import { DiagramModel, DiagramModelGenerics } from '@projectstorm/react-diagrams';
import { ExecutionTimeoutFrequency } from '../../pages/workflow-page/SchedulePipelinesForm';
import { AxiosResponse } from 'axios';
import { WorkflowConfig } from '@services/WorkflowConfig';
import { newComponentHandler } from '@api/new-custom-component';
import { getComponentTreeData } from '@services/RiTreeParser';
import { useSelector } from 'react-redux';
import { store } from '..';

export const toggleTreeview = () => ({ type: TOGGLE_COMPONENTS_TREEVIEW });
export const hideProperties = () => ({ type: HIDE_PROPERTIES });

export const showProperties = (): AppThunk => (dispatch, getState) => {
    const { isPropertiesMinimized } = getState().WorkflowReducer;
    if (isPropertiesMinimized) dispatch({ type: SHOW_PROPERTIES });
};

export const setWorkflowsListInStore = (payload: RetrieveWorkflowsPayload) => ({ type: RETRIEVE_WORKFLOWS, payload });

export const fetchRepositoriesRequest = () => ({
    type: FETCH_GITREPOSITORIES_REQUEST,
});
export const fetchRepositoriesSuccess = (gitRepositories: any) => ({
    type: FETCH_GITREPOSITORIES_SUCCESS,
    payload: gitRepositories,
});
export const fetchRepositoriesFailure = (errorGitRepo: string) => ({
    type: FETCH_GITREPOSITORIES_FAILURE,
    payload: errorGitRepo,
});

export const setExperimentOption = (selectedExp: any) =>
    ({ type: SET_SELECTED_EXPERIMENT, payload: selectedExp });

// function handleRetrieveWorkflowsSuccess(dispatch: AppDispatch, response: WorkflowList) {
//     const payload: RetrieveWorkflowsPayload = { results: response.data, totalCount: response.totalCount };
//     dispatch(setWorkflowsListInStore(payload) as any);
//     dispatch(hideSpinner(spinnerTypes.inPageSpinner));
// }
export const fetchRepositories = (): AppThunk => {
    return (dispatch, getState) => {
        const { 
            AccountReducer: { 
                envVariables: Env 
            },
        } = getState();
        
        dispatch(fetchRepositoriesRequest());
        WorkflowHandler.GetRepositories(
            `${Env?.REACT_APP_PLATFORM_URL}/git`,
            (res) => dispatch(fetchRepositoriesSuccess(res)),
            (error) => {
                console.error(error);
                dispatch(fetchRepositoriesFailure('Failed to fetch repositories.'));
                errorAlert('Failed to fetch repositories.');
            }
        );
    };
};

export const conditionalFetchRepositories = (): AppThunk => {
    return (dispatch, getState) => {
        const { 
            AccountReducer: { 
                envVariables: Env 
            },
        } = getState();

        if (!Env) {
            console.warn('Env is null; skipping fetchRepositories dispatch.');
            return;
        }

        dispatch(fetchRepositories());
    };
};

export const setWorkflowTabSelection = (key: keyof WorkflowReducerState['workflowTabSelection'], payload: WorkflowReducerState['workflowTabSelection']['selectedDirectory'] | null | InnerWorkflowTabs | number | WorkflowReducerState['workflowTabSelection']['activeSortState']) => 
    ({ type: SET_WORKFLOW_TAB_SELECTION, payload: { [key]: payload} });


export function retrieveWorkflows({ page = 1, size = 15, user_id, workflowSearch = '', isStarred = false, notes = '', directory_id, sort = 'updated' }: Partial<WorkflowSearchQuery>): AppThunk {
    return function (this: any, dispatch, getState) {
        const { 
            AccountReducer: { 
                envVariables: Env 
            },
        } = getState();

        if (!user_id) {
            user_id = getState().AccountReducer.activeUserInfo.id;
        }
        if (user_id && size) {
            dispatch(showSpinner(SpinnerTypes.inPageSpinner));
            const { CommonReducer: { activeExecutionEnv }, WorkflowReducer: { workflowTabSelection: { activeInnerTab }} } = getState();

            if(activeInnerTab === InnerWorkflowTabs.STARRED) {
                isStarred = true;
                directory_id = undefined;
            }

            dispatch(setWorkflowTabSelection(
                activeInnerTab === InnerWorkflowTabs.STARRED ? 'starredPageNum' :
                activeInnerTab === InnerWorkflowTabs.SHARED ? 'sharredPageNum' :
                'workflowsPageNum',
                page
              ));
           
            WorkflowHandler.RetrieveWorkflows(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, { page, size, user_id, workflowSearch, isStarred, notes, directory_id, sort, env: activeExecutionEnv })
                .then(response => {
                    const results = response.data.data.map(_workflow => ({ ..._workflow, page }));
                    const payload: RetrieveWorkflowsPayload = { results, totalCount: response.data.totalCount };
                    dispatch(setWorkflowsListInStore(payload));
                    dispatch(hideSpinner(SpinnerTypes.inPageSpinner));
                });
        }
    };
}

export const getWorkflowDirectories = (sort: WorkflowSortType = 'updated'): AppThunk => (dispatch, getState) => { 
    const { 
        AccountReducer: { activeUserInfo, envVariables: Env },
        CommonReducer: { activeExecutionEnv: env }
    } = getState();
    
    const userId = activeUserInfo.id;

    if (!userId) return;

    if (!Env?.REACT_APP_PLATFORM_URL) {
        // Show spinner while waiting for Env to load
        dispatch(showSpinner(SpinnerTypes.inPageSpinner));

        // Retry mechanism to wait for Env
        const checkEnvInterval = setInterval(() => {
            const { envVariables: updatedEnv } = getState().AccountReducer;

            if (updatedEnv?.REACT_APP_PLATFORM_URL) {
                clearInterval(checkEnvInterval);

                // Make API call after Env is available
                WorkflowHandler.GetWorkflowDirectories(
                    `${updatedEnv.REACT_APP_PLATFORM_URL}/platform/api`,
                    userId,
                    { sort, env },
                    (res: DirectoriesInfoResponse) => {
                        dispatch(setUserDirectories(env, res.data));
                        dispatch(hideSpinner(SpinnerTypes.inPageSpinner));
                    }
                );
            }
        }, 500); // Retry every 500ms
        return;
    }

    // If Env is already available
    dispatch(showSpinner(SpinnerTypes.inPageSpinner));

    WorkflowHandler.GetWorkflowDirectories(
        `${Env.REACT_APP_PLATFORM_URL}/platform/api`,
        userId,
        { sort, env },
        (res: DirectoriesInfoResponse) => {
            dispatch(setUserDirectories(env, res.data));
            dispatch(hideSpinner(SpinnerTypes.inPageSpinner));
        }
    );
};



export const setCallbackFunction = (callbackFunc: ((arg0?: any) => any) | undefined) =>
    ({ type: SET_CALLBACK_FUNCTION, payload: callbackFunc });

export const setActiveSelectionType = (selectionType: SelectionType) =>
    ({ type: SET_ACTIVE_SELECTION_TYPE, payload: selectionType });

export const resetWorkflowsSelection = () =>
    ({ type: CHANGE_WORKFLOWS_SELECTION, payload: {} });


export const changeWorkflowsSelection = (workflows: WorkflowsSelectionStatus) =>
    ({ type: CHANGE_WORKFLOWS_SELECTION, payload: workflows });

export const toggleModal = (modalType: ModalTypes, action: boolean, successCallback?: (arg0?: any) => any): AppThunk => (dispatch) => {
    dispatch({ type: TOGGLE_MODAL, payload: { [modalType]: action } });
    successCallback && dispatch(setCallbackFunction(successCallback));
};


// Single selection is used when a workflow is active or a workflow is selected in hover state
// in workflows list in LHS Pane
export const setSingleItemInfoForModals = (workflow: SingleItemInfo) =>
    ({ type: SELECT_SINGLE_WORKFLOW, payload: workflow });


export function get_list_jar_files(): AppThunk {
    return function (this: any, dispatch, getState) {
        const { AccountReducer: {envVariables: Env}}= getState();
        WorkflowHandler.ListJarFiles(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, (response: string[]) => {
            if (!isEmpty(response)) {
                const _jarFilesList = response.map((jarFile: string): _selectoptionType => {
                    const actualJarFileName = trimStart(jarFile, '/opt/spark-jars/');
                    return ({ label: actualJarFileName, value: jarFile });
                });
                dispatch({ type: LIST_JAR_FILES, payload: _jarFilesList });
            } else {
                dispatch({ type: LIST_JAR_FILES, payload: [] });
            }
        });
    };
}



export const setActiveTab = (tab: CanvasTabs | DagCanvasTabs) =>
    ({ type: SET_ACTIVE_TAB_IN_WORKFLOW_PAGE, payload: tab });


export const setActiveProfile = (profileInfo: ProfileInfo | null) => ({ type: SET_ACTIVE_PROFILE, payload: profileInfo });
export const setActiveCluster = (clusterInfo: ClusterInfo | null) => ({ type: SET_ACTIVE_CLUSTER, payload: clusterInfo });

export const setCustomComponentName = (payload: customComponentName) =>
    ({ type: SET_CUSTOMCOMPONENTNAME, payload: payload });

export const setcomponentParamenters = (payload: componentParamenters) =>
    ({ type: SET_COMPONENTS_PARAMETERS, payload: payload });

export const refreshComponentFormData = (componentId: string) =>
    ({ type: REFRESH_COMPONENT_FORM_DATA, payload: { id: componentId } });

export const setComponentIdForRunTillShow = (id: string) => ({ type: SET_COMPONENT_ID_FOR_RUN_TILL_SHOW, payload: id });

const updateActiveComponentInfoInTab = (componentInfo: ActiveComponentInfo | null): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeWorkflow = cloneDeep(openTabs.get(activeTab.id));
    if (activeWorkflow ?.info) {
        activeWorkflow.info.activeComponentInfo = componentInfo;
        dispatch(updateAWorkflowEditorTabInfo(activeWorkflow));
    }
};

export const setActiveComponentPropsState = (payload: Partial<Record<keyof WorkflowReducerState['activeComponentPropertiesInfo'], boolean>>) =>
    ({ type: SET_ACTIVE_COMPONENT_PROPERTIES_STATE, payload });

const resetPropertiesFormState = (componentInfo: ActiveComponentInfo | null): AppThunk => (dispatch, getState) => {
    const { activeComponentPropertiesInfo, activeComponentInfo } = getState().WorkflowReducer;
    if (activeComponentPropertiesInfo.show && componentInfo ?.id !== activeComponentInfo ?.id) {
        dispatch(setActiveComponentPropsState({ show: false }));
        setTimeout(() => {
            dispatch(setActiveComponentPropsState({ show: true }));
        }, 1);
    }
};

export const setActiveComponentInfo = (componentInfo: ActiveComponentInfo | null): AppThunk => (dispatch) => {
    dispatch(updateActiveComponentInfoInTab(componentInfo));
    dispatch(resetPropertiesFormState(componentInfo));
    return dispatch({ type: SET_ACTIVE_COMPONENT_INFO, payload: componentInfo });
};

export const updateSourcePortConnectedStatusOfActiveComponent = (componentId: string,  portOne: DsPortModel | undefined, portTwo: DsPortModel | undefined): AppThunk => (dispatch, getState) => {
    const { activeComponentInfo } = getState().WorkflowReducer; 
    const isVariableSourcePortConnected = !!portOne?.isVariablePortAndConnected() || !!portTwo?.isVariablePortAndConnected();
    if(activeComponentInfo?.id === componentId && activeComponentInfo.isVariableSourcePortConnected !== isVariableSourcePortConnected) {
        dispatch(setActiveComponentInfo({
            ...activeComponentInfo, isVariableSourcePortConnected
        }));   
    }
};


export const closeActiveComponentNotes = (): AppThunk => (dispatch, getState) => {
    const { isNotesSaved, show } = getState().WorkflowReducer.activeComponentPropertiesInfo;
    if (show && !isNotesSaved) {
        dispatch(toggleModal('loseUnsavedComponentNotesChanges', true));
    }
};

// export const closeActiveWorkflowNotes = (): AppThunk => (dispatch, getState) => {
//     if () {
//         dispatch(toggleModal('loseUnsavedComponentNotesChanges', true));
//     }
// };

export const closeActiveForm = (): AppThunk => (dispatch, getState) => {
    const { isSaved, show } = getState().WorkflowReducer.activeComponentPropertiesInfo;
    if (show && !isSaved) {
        dispatch(toggleModal('loseUnsavedFormChanges', true));
    }
};

export const saveCurrentWorkflowTabState = (): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const currentWorkflowInfo = openTabs.get(activeTab.id);
    if (currentWorkflowInfo) {
        const _serializedData = serializeWorkflow({ env: currentWorkflowInfo.info.env, config: JSON.stringify(currentWorkflowInfo.info.config), workflowDependenciesRef: {}, workflowsInfo: {}, workflowName: currentWorkflowInfo.info.name });
        const updatedWorkflowInfo: WorkflowCanvasTab = { type: getTabType('workflowEditor', currentWorkflowInfo.info.env), info: { ...currentWorkflowInfo.info, details: _serializedData } };
        dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
    }
};

export const setAdditionalFormInfo = (payload: any) => ({ type: SET_ADDITIONAL_FORM_DATA, payload });


export const setCopiedComponentInfo = (payload: componentToBeCopiedInfo) => ({ type: SET_COPIED_COMPONENT_INFO, payload });

export const setWorkflowListParams = (payload: WorkflowsListParams) => ({ type: SET_WORKFLOW_PARAMS, payload });

export const setCaptureSchemaLogs = (payload: string[]) => ({ type: SET_CAPTURE_SCHEMA_LOGS, payload });

type UpdateWorkflowPreviewStatus = { componentsInfo: PreviewStatusResponse; canvasTabId: string | number; statementId?: string; previewJobInfo?: JobInfo }

export const updateWorkflowPreviewStatus = ({ componentsInfo, canvasTabId, statementId = '', previewJobInfo }: UpdateWorkflowPreviewStatus): AppThunk => (dispatch, getState) => {
    const { openTabs } = getState().CanvasReducer.workflowEditor;
    const currentWorkflowInfo = openTabs.get(canvasTabId);
    if (currentWorkflowInfo) {
        const componentStatus: ComponentStatus = {};
        let isComponentPreviewRunning = false;
        let sessionId = '';

        const areAllComponentsInStartingMode = isEmpty(componentsInfo.filter(component => component.state !== 'starting'));
        if (previewJobInfo ?.state === 'failed' && areAllComponentsInStartingMode) {
            // this is a special scenario where job's state is failed but all the components' state is stuck to starting
            // Hence, all components's state is set to failed
            componentsInfo.forEach(component => {
                const __componentInfo: ComponentStatus[''] = { status: 'failed', sessionId: component.sessionId, componentId: component.componentId };
                componentStatus[component.componentId] = __componentInfo;
                if (!sessionId) sessionId = component.sessionId;
            });
        } else {
            componentsInfo.forEach(component => {
                const __componentInfo: ComponentStatus[''] = { status: component.state, sessionId: component.sessionId, componentId: component.componentId };
                componentStatus[component.componentId] = __componentInfo;
                if (!isComponentPreviewRunning && component.state === 'running' || component.state === 'starting') isComponentPreviewRunning = true;
                if (!sessionId) sessionId = component.sessionId;
            });
            if (currentWorkflowInfo.info.previewStatusInfo) {
                if (!statementId) statementId = currentWorkflowInfo.info.previewStatusInfo.statementId;
            }
        }

        if(previewJobInfo ?.state === 'failed' || previewJobInfo ?.state === 'success' || previewJobInfo ?.state === 'dead' || previewJobInfo ?.state === 'killed') {
            isComponentPreviewRunning = false;
        }
        // For streaming preview - noOfRecordsToIngest, maxTimeout are sent as payload
        const previewPayload: NonNullable<WorkflowCanvasTab['info']['previewStatusInfo']>['previewPayload'] = previewJobInfo?.env === ExecutionEnvModes.Streaming ? 
            { noOfRecordsToIngest: previewJobInfo.noOfRecordsToIngest, maxTimeout: previewJobInfo.maxTimeout, clusterId: previewJobInfo.clusterId } : null

        const previewStatusInfo: WorkflowCanvasTab['info']['previewStatusInfo'] = {
            components: componentStatus,
            isRunning: isComponentPreviewRunning,
            workflowSessionId: sessionId,
            isStreaming: !!(previewJobInfo ?.isStreamingWorkflow),
            statementId,
            previewPayload
        };
        const updatedWorkflowInfo: WorkflowCanvasTab = { type:getTabType('workflowEditor', currentWorkflowInfo.info.env), info: { ...currentWorkflowInfo.info, previewStatusInfo } };
        dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
    }
};


export const resetWorkflowPreviewStatus = (workflowId: number): AppThunk => (dispatch, getState) => {
    const { openTabs } = getState().CanvasReducer.workflowEditor;
    const currentWorkflowInfo = openTabs.get(workflowId);
    if(currentWorkflowInfo) {
        const updatedWorkflowInfo: WorkflowCanvasTab = { type:getTabType('workflowEditor', currentWorkflowInfo.info.env), info: { ...currentWorkflowInfo.info, previewStatusInfo: undefined } };
        dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
    }
}

export const clearWorkflowPreviewData = (canvasTabId?: string | number): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    if (!canvasTabId) canvasTabId = activeTab.id;
    const currentWorkflowInfo = openTabs.get(canvasTabId);
    if (currentWorkflowInfo) {
        const updatedWorkflowInfo: WorkflowCanvasTab = { type:getTabType('workflowEditor', currentWorkflowInfo.info.env), info: omit(currentWorkflowInfo.info, 'previewStatusInfo') };
        dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
    }
};

export const toggleWorkflowsLoadingState = (workflowIds: number[], action: boolean): AppThunk => (dispatch, getState) => {
    const workflows = getState().WorkflowReducer.workflows;
    const selectedWorkflows: Record<number, true> = {};
    workflowIds.map(_id => selectedWorkflows[_id] = true);
    const __workflowsList = { ...workflows };
    __workflowsList.results = __workflowsList.results.map(workflow => {
        if (selectedWorkflows[workflow.id]) workflow.isLoading = action;
        return workflow;
    });
    dispatch(setWorkflowsListInStore(__workflowsList));
};

export const switchWorkflowEditorTab = (workflowId: number): AppThunk => (dispatch) => {
    dispatch(saveCurrentWorkflowTabState());
    dispatch(switchToExistingCanvasTab('workflowEditor', workflowId));
};

export const setCurrentWorkflowStateToUnsaved = (): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeWorkflow = openTabs.get(activeTab.id);
    // ALLOW SAVE ONLY ON WORKFLOWS THAT ARE NOT READONLY
    if (activeWorkflow ?.info && !activeWorkflow.info.isReadOnly && activeWorkflow.info.saved) {
        const updatedInfo = { ...activeWorkflow, info: { ...activeWorkflow.info, saved: false } };
        dispatch(updateAWorkflowEditorTabInfo(updatedInfo));
    }
};

export const setModalInfoTypeInWorkflowsPage = (type: WorkflowReducerState['modalType']) =>
    ({ type: SET_MODAL_TYPE_IN_WORKFLOWS_PAGE, payload: type });


export const setVariableComponentInfo = (payload: WorkflowReducerState['variableComponent']) =>
    ({ type: SET_VARIABLE_COMPONENT_INFO, payload });

export const setComponentFnNamesInfo = (payload: WorkflowReducerState['componentFnNamesInfo']) =>
    ({ type: SET_COMPONENT_FN_NAMES_INFO, payload });

export const setCustomComponentFnNamesCount = (payload: WorkflowReducerState['customComponentFnNamesCount']) =>
    ({ type: SET_CUSTOM_COMPONENT_FN_NAMES_COUNT, payload });

export const openJobLogsOfWorkflow = (type: JobInfo['type'] | 'capture_schema', { jobInfo, captureSchemalogs }: { jobInfo?: JobInfo; captureSchemalogs?: string[] }): AppThunk => (dispatch, getState) => {
    const { openTabs, activeTab } = getState().CanvasReducer.workflowEditor;
    const { activeExecutionEnv } = getState().CommonReducer;
    const { 
        AccountReducer: { 
            envVariables: Env 
        },
    } = getState();
    const consoleInfo: WorkflowCanvasTabInfo['consoleInfo'] = { jobInfo, logs: [], type, height: 0 };
    let activeClusterIdForPreview: WorkflowCanvasTabInfo['activeClusterIdForPreview'] = undefined;
    if (type === 'capture_schema' && captureSchemalogs && !isEmpty(captureSchemalogs)) {
        const activeTabInfo = openTabs.get(activeTab.id);
        if (activeTabInfo) {
            consoleInfo.logs = captureSchemalogs;
            const updatedWorkflowInfo: WorkflowCanvasTab = { type:getTabType('workflowEditor', activeTabInfo.info.env), info: { ...omit(activeTabInfo.info, 'previewStatusInfo'), consoleInfo } };
            dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
        }
    } else if (jobInfo ?.log || (jobInfo && (jobInfo.state === 'running' || jobInfo.state === 'starting'))) {
        // for jobs that have just started 
        const workflowInfo = openTabs.get(jobInfo.workflowId);
        consoleInfo.logs = jobInfo.log || [];
        // set clusterId of the preview job
        if(jobInfo.type === 'preview' && jobInfo.clusterId) {
            activeClusterIdForPreview = jobInfo.clusterId
        }
        
        if (workflowInfo) {
            const updatedWorkflowInfo: WorkflowCanvasTab = {
                type:getTabType('workflowEditor', workflowInfo.info.env),
                info: type === 'run' ? 
                    { ...omit(workflowInfo.info, 'previewStatusInfo'), consoleInfo } 
                    : 
                    { ...workflowInfo.info, consoleInfo, activeClusterIdForPreview }
            };
            dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
            if (activeTab.id !== jobInfo.workflowId) {
                if(activeExecutionEnv !== workflowInfo?.info.env) {
                    dispatch(setActiveExecutionEnv(workflowInfo.info.env))
                }    
                dispatch(switchToExistingCanvasTab('workflowEditor', jobInfo.workflowId));
            }
        } else {
            WorkflowHandler.GetWorkflowInfo(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, jobInfo.workflowId, (workflow) => {
                // pageNo doesnt work when opened from job status panel
                if(activeExecutionEnv !== workflow.data.env) {
                    dispatch(setActiveExecutionEnv(workflow.data.env))
                }
                dispatch(openANewWorkflowEditorTabUsingWorkflowInfo(workflow.data, { consoleInfo, activeClusterIdForPreview }));
            });
        }
    } else {
        infoAlert('No logs available');
    }
};

export const clearWorkflowJobLogs = (tabId: number): AppThunk => (dispatch, getState) => {
    const { openTabs } = getState().CanvasReducer.workflowEditor;
    const workflowInfo = cloneDeep(openTabs.get(tabId));
    if (workflowInfo) {
        workflowInfo.info.consoleInfo = { ...workflowInfo.info.consoleInfo, type: null, logs: [], jobInfo: undefined };
        dispatch(updateAWorkflowEditorTabInfo(workflowInfo));
    }

};

export const updateConsolePositionInfo = (height: number): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeWorkflow = cloneDeep(openTabs.get(activeTab.id));

    if (activeWorkflow ?.info) {
        if (activeWorkflow.info.consoleInfo) {
            activeWorkflow.info.consoleInfo.height = height;
        } else {
            activeWorkflow.info.consoleInfo = { height, logs: [], type: null };
        }
        dispatch(updateAWorkflowEditorTabInfo(activeWorkflow));
    }
};

export const toggleConsoleHeightToShowLogs = () => ({ type: TOGGLE_CONSOLE_HEIGHT });


export const deleteCanvasEntities = (selectedEntities: BaseModel<BaseModelGenerics>[], removeItemsFn: () => any): AppThunk => (dispatch) => {
    dispatch(toggleModal('deleteWorkflow', true, removeItemsFn));
    dispatch(setModalInfoTypeInWorkflowsPage('canvasItems'));
    if (selectedEntities.length === 1) {
        dispatch(setActiveSelectionType('single'));
        dispatch(setSingleItemInfoForModals({ id: 0, name: (selectedEntities[0] as DsNodeModel) ?.getOptions().title, version: 0 }));
    } else {
        dispatch(setActiveSelectionType('multiple'));
    }
};

export const setCustomComponentsList = (payload: any[]) =>
    ({ type: SET_CUSTOM_COMPONENTS_LIST, payload });
export const setCustomComponentUpdateId = (componentId: number) =>
    ({ type: SET_CUSTOMCOMPOENENTUPDATEID, payload: componentId });

export const setActiveUpdataParamter = (params: boolean) =>
    ({ type: SET_ACTIVE_UPDATECOMPONENTPARAMETER, payload: params });

export const setCustomComponentModalType = (type: WorkflowReducerState['customComponentModelType']) =>
    ({ type: SET_CUSTOMCOMPONENt_MODALTYPE, payload: type });

export const setActiveUpdataModalType = (params: boolean) =>
    ({ type: SET_ACTIVE_UPDATE_MODALTYPE, payload: params });

export const increaseComponentCounterInActiveWorkflow = (incrementBy = 1): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeTabInfo = cloneDeep(openTabs.get(activeTab.id));
    if(activeTabInfo) {
        activeTabInfo.info.componentCounter += incrementBy;
        const updatedWorkflowInfo: WorkflowCanvasTab = activeTabInfo;
        return dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
    }
};

export const setActiveWorkflowStateToReadOnly = (): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeTabInfo = cloneDeep(openTabs.get(activeTab.id));
    if(activeTabInfo) {
        activeTabInfo.info.isReadOnly = true;
        activeTabInfo.info.isExecutable = false;
        const updatedWorkflowInfo: WorkflowCanvasTab = activeTabInfo;
        return dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
    }
};


export const disableHelpAnimationOfActiveWorkflow = (): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeTabInfo = cloneDeep(openTabs.get(activeTab.id));
    if(activeTabInfo) {
        activeTabInfo.info.showHelpBulbAnimation = false;
        const updatedWorkflowInfo: WorkflowCanvasTab = activeTabInfo;
        return dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
    }
};



export const switchCodeEditorOfActiveTab = (clusterId: string, clusterName: string): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const { envVariables : Env } = getState().AccountReducer;
    const activeWorkflow = cloneDeep(openTabs.get(activeTab.id));
    const _clusterInfo = { name: clusterName, id: clusterId };

    if(activeWorkflow?.info) {
        activeWorkflow.info.activeClusterInfoForZeppelin = _clusterInfo;

        const zeppelinNotebookId = activeWorkflow.info.zeppelinNotebookId;
        dispatch(hideProperties());
        if(zeppelinNotebookId === null && !activeWorkflow.info.showCodeEditor) {
            WorkflowHandler.CreateZeppelinCode(Env?.ZEPPELIN_WS, Date.now().toString(), (obj)=>{
                WorkflowHandler.UpdateNotebookForWorkflow(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, activeTab.id.toString(), obj.body, ()=>{
                    activeWorkflow.info.showCodeEditor = true;
                    activeWorkflow.info.zeppelinNotebookId = obj.body;
                    dispatch(updateAWorkflowEditorTabInfo(activeWorkflow));
                });
            });
        } else {
            activeWorkflow.info.showCodeEditor = !activeWorkflow.info.showCodeEditor;
            dispatch(updateAWorkflowEditorTabInfo(activeWorkflow));
        }
    }
};



export const getComponentDfNameUsingCounter = (componentFnId: string, counter: number) => 
    snakeCase(componentFnId) + '_' + counter;


export const getComponentDfNameAndIncrementCounter = (componentFnId: string): AppThunk => (dispatch, getState): string => {
    const { openTabs, activeTab } = getState().CanvasReducer.workflowEditor;
    const activeWorkflowInfo = openTabs.get(activeTab.id)?.info
    const dfName = getComponentDfNameUsingCounter(componentFnId, (activeWorkflowInfo?.componentCounter || 0));
    dispatch(increaseComponentCounterInActiveWorkflow() as any);
    return dfName;
};


export const toggleWorkflowCanvasSpinner = (payload: boolean) => ({ type: TOGGLE_WORKFLOW_CANVAS_SPINNER, payload });

export const handleOpenWorkflowUsingWorkflowId = (workflowId: number, alertMessage = ''): AppThunk => (dispatch, AppState) => {
    const { 
        WorkflowReducer: { activeComponentPropertiesInfo },   
        CanvasReducer: { workflowEditor: { activeTab, openTabs }}
    } = AppState();
    const { 
        AccountReducer: { 
            envVariables: Env 
        },
    } = AppState();
    
    const openWorkflowFunc = () => {
        const isWorkflowAlreadyOpen = openTabs.get(workflowId);
        dispatch(toggleWorkflowsLoadingState([workflowId], true));

        if (isWorkflowAlreadyOpen) {
            // switchWorkflowEditor already calls saveCurrentWorkflowTabState 
            dispatch(switchWorkflowEditorTab(workflowId));
        }
        else {
            dispatch(saveCurrentWorkflowTabState());
            alertMessage && infoAlert(alertMessage);
            WorkflowHandler.GetWorkflowInfo(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, workflowId, (workflow: DetailedWorkflowInfo) => {
                dispatch(openANewWorkflowEditorTabUsingWorkflowInfo(workflow.data));
            });
        }
    };

    if (!activeComponentPropertiesInfo.isSaved) {
        dispatch(closeActiveForm());
        activeTab.id !== workflowId && dispatch(setCallbackFunction(openWorkflowFunc));
    } else if(!activeComponentPropertiesInfo.isNotesSaved) {
        dispatch(closeActiveComponentNotes());
    }
    else if (activeTab.id !== workflowId) {
        openWorkflowFunc();
    }    
};

export const handleRetrieveJobsList = (): AppThunk => (dispatch, getState) => {
    const checkAndRetrieveJobs = () => {
        const {
            AccountReducer: { envVariables: Env },
        } = getState();

        if (Env?.REACT_APP_DATABRICKS) {
            const link = Env.REACT_APP_DATABRICKS
                ? `${Env.REACT_APP_PLATFORM_URL}/databricks/api`
                : `${Env.REACT_APP_PLATFORM_URL}/platform/api`;

            setTimeout(() => {
                JobsHandler.GetRecentJobs(
                    link,
                    getState().CommonReducer.activeExecutionEnv,
                    (jobsInfo) => {
                        dispatch({ type: SET_JOBS_LIST, payload: jobsInfo });
                    }
                );
            }, 3000);
        } else {
            // Re-check after a delay if the condition isn't met
            setTimeout(checkAndRetrieveJobs, 1000); // Check every 1 second
        }
    };

    checkAndRetrieveJobs();
};


export const callSuccessCallbackFunc = (): AppThunk => (dispatch, AppState) => {
    const successCallback = AppState().WorkflowReducer.callbackFunc;
    if(successCallback) {
        successCallback();
        setTimeout(() => dispatch(setCallbackFunction(undefined)), 500);
    }
};

const getDirectoryId = (env: ExecutionEnvModes, isImportWorkflowInDifferentEnv: boolean): AppThunk => (_, getState) => {
    const { 
        WorkflowReducer: {
            workflowTabSelection: { 
                selectedDirectory 
            }
        },
        AccountReducer: {
            userDirectories
        } 

    } = getState();
    
    
    let isUserDefaultDirectory = true;
    // Use default directory id if the imported workflow is in a different env or no directory is selected
    let directoryId: number | undefined = userDirectories[env]?.defaultDirectoryId || undefined;

    if(isImportWorkflowInDifferentEnv) {
        isUserDefaultDirectory = true;
    }
    else if(selectedDirectory) {
        isUserDefaultDirectory = selectedDirectory?.name.toLowerCase().includes('default directory');
        directoryId = selectedDirectory.id;
    }

    return {
        directoryId,
        isUserDefaultDirectory,
        selectedDirectory
    }
}

const updateWorkflowIdsInComponents = (serializedWorkflowInfo: string, workflowDependenciesRef: WorkflowDependenciesRef, oldWorkflowNewWorkflowMapping: Record<number, number>, workflowMetadata: WorkflowMetaData): Promise<{ details: SerializedData, payload: string }> => {
    // Inject new workflow ids before the components are added 
    const workflowModel = convertSerializedWorkflowDataToModel(serializedWorkflowInfo);

    return new Promise((resolve) => {
    setTimeout(() => {    
        for(const workflowId in workflowDependenciesRef) {
            const componentsList = workflowDependenciesRef[workflowId]
            const newWorkflowId = oldWorkflowNewWorkflowMapping[workflowId]
            
            Object.entries(componentsList).forEach(([componentId, fieldIndices]) => {
                const nodeInfo = workflowModel.getNode(componentId) as DsNodeModel;
                
                // update new workflow id in every index of the component
                fieldIndices.forEach(index => {
                    nodeInfo.extras.formData[index].value = newWorkflowId
                })
            })
        }
        let _workflowConfig: WorkflowConfig = [];
        try {
            _workflowConfig = JSON.parse(workflowMetadata.config) || []
        } catch {/*eslint-disable */}

        resolve({
            details: serializeWorkflowModel(workflowModel, { ...workflowMetadata, workflowDependenciesRef: {}, workflowsInfo: {} }),
            payload: JSON.stringify(convertWorkflowDataForExecution(workflowModel, false, workflowMetadata.env, { userName: '', workflowName: workflowMetadata.workflowName }, _workflowConfig))
        })
        
    }, 1)
})

}

const importDependentWorkflows = (workflowId: string, workflowInfo: WorkflowMetaData['workflowsInfo'][1], oldWorkflowNewWorkflowMapping: Record<number, number>, userId ?: string): AppThunk => async (dispatch, getState) => {
    const { 
        CommonReducer: { 
            activeExecutionEnv 
        },
    } = getState();

    const { 
        AccountReducer: { 
            envVariables: Env 
        },
    } = getState();
    
    const { details: currentWorkflowDetails, payload: currentWorkflowPayload } = workflowInfo;
    const currentWorkflowMetadata = (currentWorkflowDetails as WorkflowSerializedObjData).workflowMetaData;
    const { workflowDependenciesRef, workflowsInfo } = currentWorkflowMetadata;
    let actualSerializedWorkflowInfo = JSON.stringify(currentWorkflowDetails) as SerializedData;
    let actualWorkflowPayload = currentWorkflowPayload;
    
    if(!isEmpty(workflowDependenciesRef) && !isEmpty(workflowsInfo)) {
        for(const workflowId in workflowDependenciesRef) {
            await dispatch(importDependentWorkflows(workflowId, workflowsInfo[parseInt(workflowId)], oldWorkflowNewWorkflowMapping, userId));
        }
        if(!isEmpty(oldWorkflowNewWorkflowMapping)) {
            const updatedWorkflowInfo = await updateWorkflowIdsInComponents(
                currentWorkflowDetails as any,
                workflowDependenciesRef,
                oldWorkflowNewWorkflowMapping,
                currentWorkflowMetadata
            )
            actualSerializedWorkflowInfo = updatedWorkflowInfo.details;
            actualWorkflowPayload = updatedWorkflowInfo.payload;
        }
    }

    const payload: CreateWorkflow = { 
        projectName: currentWorkflowMetadata.workflowName, 
        status: true, 
        payload: actualWorkflowPayload, 
        details: actualSerializedWorkflowInfo as string, 
        // if userId is passed, it means the workflows are created for a different user
        directoryId: userId ? undefined: (dispatch(getDirectoryId(currentWorkflowMetadata.env, currentWorkflowMetadata.env !== activeExecutionEnv)) as any).directoryId, 
        env: currentWorkflowMetadata.env,
        config: currentWorkflowMetadata.config
    };

    const workflowResponse = await WorkflowHandler.CloneWorkflowUsingName(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, payload, userId) as AxiosResponse<CreateWorkflowResponse>
    oldWorkflowNewWorkflowMapping[parseInt(workflowId)] = workflowResponse.data.data.id;
}

export const importWorkflow = ({ serializedWorkflowInfo, workflowMetaData, userId, repoId }:{ serializedWorkflowInfo: string;  workflowMetaData: WorkflowMetaData, userId ?: string, repoId ?: number }, importSuccessCb ?: ApiResponse<CreateWorkflowResponse>, importFailureCb ?: any): AppThunk => async  (dispatch, getState) => {
    const { 
        CommonReducer: { 
            activeExecutionEnv 
        },
        AccountReducer: {
            activeUserInfo,
        }, 
        WorkflowReducer: {
            workflowTabSelection: { activeInnerTab }
        }
    } = getState();
    const { workflowDependenciesRef, workflowsInfo, env, config, workflowName } = workflowMetaData;
    const oldWorkflowNewWorkflowMapping: Record<number, number> = {}
    let actualSerializedWorkflowInfo = serializedWorkflowInfo;

    const isImport = !userId
    // Create copies of all the dependent workflows
    if(!isEmpty(workflowDependenciesRef) && !isEmpty(workflowsInfo)) {
        isImport && infoAlert("Importing dependencies")
        try {
            for(const workflowId in workflowDependenciesRef) {
                await dispatch(importDependentWorkflows(workflowId, workflowsInfo[parseInt(workflowId)], oldWorkflowNewWorkflowMapping, userId))
            }
            if(!isEmpty(oldWorkflowNewWorkflowMapping)) {
                actualSerializedWorkflowInfo = (await updateWorkflowIdsInComponents(
                    serializedWorkflowInfo,
                    workflowDependenciesRef,
                    oldWorkflowNewWorkflowMapping,
                    workflowMetaData
                )).details as any
            }
        } catch(e){
            if(isImport) {
                errorAlert("Failed importing dependencies")
                importFailureCb?.(e);
            } else {
                throw Error(`Importing dependencies Failed:::${workflowMetaData.workflowName}`)
            }
            return;
        }
    }


    // Only base workflow is cloned - rest all dependencies are imported directly without cloning
    const clonedData = await cloneWorkflowInfo({ 
        serializedWorkflowInfo: actualSerializedWorkflowInfo, 
        env, 
        workflowUserInfo: { userName: userId ?? activeUserInfo.name, workflowName }, 
        workflowConfig: config
     })


    const { directoryId } = dispatch(getDirectoryId(workflowMetaData.env, workflowMetaData.env !== activeExecutionEnv)) as any;
    const payload: CreateWorkflow = { 
        projectName: workflowName, 
        status: true, 
        payload: JSON.stringify(clonedData.payload), 
        details: JSON.stringify(clonedData.details),
        // cloneWorkflow v2 needs to be called if userId is different 
        // so that (1)/(2) is added in workflow names if user already has workflow with that name
        directoryId: userId ? undefined: directoryId, 
        env,
        config
    };
        const repo_id=activeInnerTab !== 'Remote'?undefined:repoId
        const { 
            AccountReducer: { 
                envVariables: Env 
            },
        } = getState();
        
        try {
            const responsePromise = await WorkflowHandler.CloneWorkflowUsingName(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, payload, userId || activeUserInfo.id, repo_id);
            importSuccessCb?.((responsePromise).data)
            return responsePromise
        } catch {
            if(userId) {
                throw Error(`Cloning Workflow Failed:::${workflowMetaData.workflowName}`)
            } else {
                importFailureCb?.()
            }
        }
}

// Fetches workflow details
// Goes into every component in the workflow and looks for workflow field
export const getDependentWorkflowDetailsAndPayload = async (workflowId: number, workflowsInfo: WorkflowMetaData['workflowsInfo'] = {}, allWorkflowsInfo: WorkflowMetaData['workflowsInfo'] = {}): Promise<WorkflowSerializedObjData> => {
    try{
        if(allWorkflowsInfo[workflowId]) {
            // if workflow details is already fetched, do not fetch again
            workflowsInfo[workflowId] = allWorkflowsInfo[workflowId]
            return allWorkflowsInfo[workflowId].details as WorkflowSerializedObjData
        }
        const Env=store.getState().AccountReducer.envVariables;
        const response = await WorkflowHandler.GetWorkflowInfoPromise(`${Env.REACT_APP_PLATFORM_URL}/platform/api`, workflowId) as AxiosResponse<DetailedWorkflowInfo>
        const { details, env, config, projectName, payload } = response.data.data;
        // All workflow ids are first grouped to prevent multiple workflow detail calls for the same workflow
        const workflowDependenciesRef =  getDependentWorkflowsInfo(details)
        const childWorkflowsInfo: WorkflowMetaData['workflowsInfo'] = {};
        if(!isEmpty(workflowDependenciesRef)) {
            for (const workflowId in workflowDependenciesRef) {
                await getDependentWorkflowDetailsAndPayload(parseInt(workflowId), childWorkflowsInfo, allWorkflowsInfo)
            }
        }
        const workflowMetaData: WorkflowMetaData = {
            env,
            workflowDependenciesRef,
            workflowsInfo: childWorkflowsInfo,
            config: config || '[]',
            workflowName: projectName
        }
        const workflowInfo: WorkflowSerializedObjData =  {
            ...JSON.parse(details),
            workflowMetaData
        }
        workflowsInfo[workflowId] = { details: workflowInfo, payload}
        allWorkflowsInfo[workflowId] = { details: workflowInfo, payload }
        return workflowInfo
    }catch{
        return {} as WorkflowSerializedObjData;
    }
   
}

export function cloneWorkflow(workflowInfo: {id: number;name: string; config: string;}, serializedWorkflowInfo: string, cloneSuccessCb: ApiResponse<CreateWorkflowResponse>, cloneErrorCb?: any): AppThunk {
    return (dispatch, getState) => {
        const { 
            CommonReducer: { 
                activeExecutionEnv 
            },
            AccountReducer: {
                activeUserInfo,
                envVariables: Env
            } 

        } = getState();

        cloneWorkflowInfo({ serializedWorkflowInfo, env: activeExecutionEnv, workflowUserInfo: { userName: activeUserInfo.name, workflowName: workflowInfo.name }, workflowConfig: workflowInfo.config })
            .then(async clonedData => {
                const { directoryId, isUserDefaultDirectory, selectedDirectory } = dispatch(getDirectoryId(activeExecutionEnv, false)) as any;
                
                const data: CloneWorkflowData = { 
                    id: workflowInfo.id as number, 
                    details: JSON.stringify(clonedData.details), 
                    payload: JSON.stringify(clonedData.payload),
                    directoryId,
                    config: workflowInfo.config
                };
                    
                WorkflowHandler.CloneWorkflow(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, data, (response: CreateWorkflowResponse) => {
                    cloneSuccessCb();
                    successAlert(`Successfully cloned as ${response.data.projectName} to ${isUserDefaultDirectory ? 'your Default Directory': selectedDirectory?.name}`);
                }, (e: any)=>{
                    cloneErrorCb && cloneErrorCb(e);
                });

            })
            .catch(() => errorAlert('Failed to clone the ' + getActiveWorkflowType(activeExecutionEnv)));
    };

}


export const updatePreviewStatusOfWorkflowTab = (
    sessionId: string,
    canvasTabId: number,
    jobsList: JobInfo[] = []
): AppThunk => (dispatch, getState) => {
    const { AccountReducer: {envVariables: Env}} = getState();
    const link = Env?.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
    WorkflowHandler.GetPreviewStatusPromise(link, sessionId).then((response) => {
        const previewJobInfo = jobsList.find(
            (_job) => _job.sessionId === sessionId && _job.workflowId === canvasTabId
        );
        // if(!!previewJobInfo) {
        dispatch(
            updateWorkflowPreviewStatus({
                componentsInfo: response.data,
                canvasTabId,
                previewJobInfo,
                statementId: previewJobInfo?.id,
            })
        );
        // }
        
    });
};



export const setActiveTabPreviewClusterId = (activeTabId: number, clusterId: string, page: PageTypes = 'workflowEditor'): AppThunk => (dispatch, getState) => {
    const { openTabs } = getState().CanvasReducer[page];
    const activeWorkflow = cloneDeep(openTabs.get(activeTabId));

    if(activeWorkflow?.info) {
        activeWorkflow.info.activeClusterIdForPreview = clusterId;
        if(page === 'workflowEditor') {
            dispatch(updateAWorkflowEditorTabInfo(activeWorkflow as WorkflowCanvasTab));
        } else {
            dispatch(updateAWorkflowAnalyticsTabInfo(activeWorkflow as WorkflowAnalyticsTab));
        } 
    }
};

type RunPreviewArg =  { activeTabId: number; clusterId?: string; successCbForPreview?: (sessionId: string) => void, successCbForStatementSubmission ?: () => void; errorCbForPreview?: () => void; showExecutionAlerts ?: boolean } & StreamingStatementData

const updateWorkflowPreviewSessionCreationStatus = (page: PageTypes, workflowId: number, inProgress: boolean): AppThunk => (dispatch, getState) => {
    const { 
        CanvasReducer: { [page]: { openTabs }},
    } = getState();
    
    const workflowInfo = openTabs.get(workflowId);

    if(workflowInfo) {
        const activeWorkflowInfo = cloneDeep(workflowInfo);
        activeWorkflowInfo.info.isPreviewSessionCreationInProgress = inProgress;
        dispatch(updateACanvasTabInfo(page, activeWorkflowInfo));
    }
}


export const runOrPreviewWorkflowOnDatabricks = (page: PageTypes, type: 'run' | 'preview', { clusterId: _clusterId, showExecutionAlerts = true, successCbForPreview, successCbForStatementSubmission, activeTabId, noOfRecordsToIngest, maxTimeout, errorCbForPreview }: RunPreviewArg): AppThunk => (dispatch, getState) => {
    const { 
        CommonReducer: { activeExecutionEnv },
        CanvasReducer: { [page]: { openTabs }},
        ClusterReducer: { defaultClusterInfo, clusters },
        AccountReducer: { envVariables: Env}
    } = getState();
    const link = Env?.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
    const activeWorkflowInfo = openTabs.get(activeTabId)?.info;

    // const workflowPayload = convertWorkflowDataForExecution(WorkflowCanvas.model, false, activeExecutionEnv, {userName: activeUserInfo.name, workflowName: activeWorkflowInfo?.name || ''  });
    const workflowPayload = getWorkflowInfo(page, dispatch as any)
    const clusterId = _clusterId || defaultClusterInfo?.clusterId;
    const selectedClusterInfo = clusters.find(cluster => cluster.clusterId === clusterId);

    if(workflowPayload) {
        const hideWorkflowPreviewSessionSpinner = () => {
            activeWorkflowInfo && dispatch(updateWorkflowPreviewSessionCreationStatus(page, activeWorkflowInfo?.id, false))
        }

        const handleSessionStartSuccess = (response: SessionInfo) => {
            if(successCbForPreview) {
                successCbForPreview(response.sessionId);
            } else {
                if(clusterId) {
                    const data: SubmitStatementData = {
                        sessionId: response.sessionId,
                        payload: workflowPayload,
                        env: activeExecutionEnv,
                        clusterId,
                        noOfRecordsToIngest, 
                        maxTimeout,
                        workspaceId: selectedClusterInfo?.workspaceId,
                        workspaceType: selectedClusterInfo?.workspaceType
                    }; 
                    // successAlert('Session created');
                    AnalyticsHandler.SubmitStatement(link, data, () => {
                        setTimeout(() => {
                            // this delay is added to make sure the play button is disabled 
                            // until the preview result api response is hit 
                            hideWorkflowPreviewSessionSpinner();
                        }, 5000)
                        successCbForStatementSubmission?.();
                        dispatch(setActiveTabPreviewClusterId(activeTabId, clusterId));
                        console.log("**2")
                        dispatch(handleRetrieveJobsList());
                        dispatch(updatePreviewStatusOfWorkflowTab(response.sessionId, activeTabId));
                    }, () => {
                        hideWorkflowPreviewSessionSpinner();
                        errorCbForPreview?.()
                        if(showExecutionAlerts)
                            errorAlert('Error previewing the workflow');
                    });
                } 
            }
        };


        if(activeWorkflowInfo && clusterId) {
            if(!selectedClusterInfo) {
                errorAlert('Please set a default cluster');     
            }
            else if(selectedClusterInfo.state === ClusterState.RUNNING || selectedClusterInfo.state === ClusterState.RESIZING) {
                showExecutionAlerts && infoAlert(`Workflow ${type} has started`);
                const data: any = {
                    name: activeWorkflowInfo.name + '_' + Date.now(),
                    'sparkVersion': '2.x',
                    'env': activeExecutionEnv,
                    workflowId: activeWorkflowInfo.id,
                    clusterId,
                    type,
                    workspaceId: selectedClusterInfo.workspaceId,
                    workspaceType: selectedClusterInfo.workspaceType
                };

                dispatch(updateWorkflowPreviewSessionCreationStatus(page, activeWorkflowInfo.id, true))
                if(type === 'run') {
                    data.payload = workflowPayload;
                    WorkflowHandler.RunWorkflow(link, activeExecutionEnv , data, () => {
                        dispatch(resetWorkflowPreviewStatus(activeWorkflowInfo.id))
                        console.log("**1")
                        dispatch(handleRetrieveJobsList());
                    }, () => {showExecutionAlerts && errorAlert('Error running the workflow');});
                } else {
                    // const 
                    // infoAlert('Creating session');
                    AnalyticsHandler.StartSession(link, data, handleSessionStartSuccess, () => {
                        hideWorkflowPreviewSessionSpinner();
                        errorCbForPreview?.();
                        showExecutionAlerts && 
                            errorAlert('Error creating the session');
                    });
                }
            } else {
                errorAlert(`Cluster ${selectedClusterInfo?.clusterName} is not in running state.`);      
            }
        } else {
            errorAlert('Please set a default cluster');      
        }
    }
};

export const executeActiveWorfklowStreamingPreview = (): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeTabPreviewPayload = openTabs.get(activeTab.id)?.info.previewStatusInfo?.previewPayload;
    if(activeTabPreviewPayload) {
        dispatch(
            runOrPreviewWorkflowOnDatabricks(
                'workflowEditor',
                'preview',
                {
                    activeTabId: activeTab.id,
                    clusterId: activeTabPreviewPayload.clusterId,
                    maxTimeout: activeTabPreviewPayload.maxTimeout,
                    noOfRecordsToIngest: activeTabPreviewPayload.noOfRecordsToIngest,
                    errorCbForPreview: () => {
                        dispatch(toggleModal('streamingPreviewWorkflow', true))
                    }
                }
            )
        )
    }
}


export const updateTabDagRhsFormValues = ({ values, reset }: {values?: WorkflowCanvasTabInfo['scheduleInfoForDag']; reset: boolean }): AppThunk => (dispatch, getState) => {   
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeTabType = openTabs.get(activeTab.id)?.type;
    const activeTabInfo = openTabs.get(activeTab.id)?.info;
    if(activeTabInfo && activeTabType) {
        if(reset && !values) {
            const initialDefaultFormValues = getScheduleFormInitialValues();
            // sets default values
            values = {
                ...initialDefaultFormValues,
                notificationType: notificationTypeOptions[0].value,
                retries: 0,
                retryDelay: 300,
                execution_timeout: null,
                dag_id: activeTabInfo?.name.replace('(', '').replace(')', '') + '_' + uuid(),
                execution_timeout_frequency: ExecutionTimeoutFrequency['Seconds'],
                max_active_runs:"16",
                catchup: false,
                exponential_backoff:false
            };
        }
        if(values) {
            const tabInfo: WorkflowCanvasTab = {
                type: activeTabType,
                info: { ...activeTabInfo, scheduleInfoForDag: values } 
            };
            dispatch(updateAWorkflowEditorTabInfo(tabInfo));
        }
    }
};

export const handleShowCaptureSchemaLogs = (sessionId: string | null, successCb?: () => void): AppThunk => (dispatch, getState) => {
    const { envVariables: Env } = getState().AccountReducer;
    const link = Env?.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
    const handleCaptureSchemaLogsResponse = (response: string[]) => {
        dispatch(openJobLogsOfWorkflow('capture_schema', { captureSchemalogs: response }));
        dispatch(toggleConsoleHeightToShowLogs());
        successCb && successCb();
    };
    
    if(sessionId) {
        WorkflowHandler.GetCaptureSchemaLogs(link, sessionId, handleCaptureSchemaLogsResponse);

    }
};

export const saveActiveWorkflow = (successCb: ApiResponse<UpdateWorkflowResponse> , saveAsVersion: boolean, page: PageTypes = 'workflowEditor'): AppThunk =>  (dispatch, getState) => {
    const activeTab = getState().CanvasReducer[page].activeTab;
    
    if(activeTab.id) {
        dispatch(saveWorkflow(successCb, page, activeTab.id, saveAsVersion))
    }

    // if(activeWorkflowInfo) {
    //     addLabelsAndIsFieldHiddenToNodesIfEmpty(componentFnNamesInfo, WorkflowCanvas.model);
    //     const _serializedData = serializeCurrentWorkflow({ env: activeWorkflowInfo.env });
    //     if(saveAsVersion && !_serializedData) {
    //         errorAlert('Cannot save an empty workflow as a version');
    //     } else {
    //         const payload = JSON.stringify(convertWorkflowDataForExecution(WorkflowCanvas.model, false, activeExecutionEnv, { workflowName: activeWorkflowInfo.name, userName: activeUserInfo.name }));
    //         const data: UpdateWorkflow = { 
    //             payload, 
    //             details: _serializedData, 
    //             projectName: activeWorkflowInfo.name, 
    //             create_new_version: saveAsVersion, 
    //             isStarred: activeWorkflowInfo.isStarred,
    //             hasVariables: checkIfVariableComponentsExist(),
    //             componentCounter: activeWorkflowInfo.componentCounter,
    //             directoryId: activeWorkflowInfo.directoryId,
    //             env: activeWorkflowInfo.env,
    //             zepplinData: typeof activeWorkflowInfo.zepplinData === 'string'? (activeWorkflowInfo.zepplinData as any) : JSON.stringify(activeWorkflowInfo.zepplinData || [])
    //         };
    //         WorkflowHandler.UpdateWorkflow(activeWorkflowInfo.id, data, successCb);
    //         const updatedActiveWorkflowInfo: WorkflowCanvasTabInfo = { ...activeWorkflowInfo, details: _serializedData, saved: true };
    //         if (saveAsVersion) {
    //             updatedActiveWorkflowInfo.version = updatedActiveWorkflowInfo.version + 1;
    //         }
    //         const timeout = saveAsVersion ? 500 : 0;
    //         setTimeout(() => dispatch(updateAWorkflowEditorTabInfo({ type: getTabType('workflowEditor', updatedActiveWorkflowInfo.env), info: updatedActiveWorkflowInfo })), timeout);
    //     }
    // }
};

export const saveAllActiveWorkflow = (successCb: ApiResponse<UpdateWorkflowResponse> , saveAsVersion: boolean, page: PageTypes = 'workflowEditor'): AppThunk =>  (dispatch, getState) => {
    const openTabs = getState().CanvasReducer[page].openTabs;
    const openTabArray=Array.from(openTabs.values() as Iterable<WorkflowCanvasTab>).map(info => ({
        key: info.info.id,
        saved: info.info.saved
    }));
    if (openTabArray) {
        openTabArray.filter(item => !item.saved).forEach(item => {
            dispatch(saveWorkflow(successCb, page, item.key, saveAsVersion));
        });
    }
};


export const saveWorkflow = (successCb: ApiResponse<UpdateWorkflowResponse> , page: PageTypes = 'workflowEditor', workflowId: number, saveAsVersion = false): AppThunk =>  (dispatch, getState) => {
    const { CanvasReducer, CommonReducer: { activeExecutionEnv }, AccountReducer: { activeUserInfo, envVariables: Env }, WorkflowReducer: { componentFnNamesInfo } } = getState();
    const {  openTabs, activeTab } = CanvasReducer[page];    
    const workflowInfo = openTabs.get(workflowId)?.info as WorkflowCanvasTabInfo | undefined;
    
    if(workflowInfo) {
        let _CanvasModel: DiagramModel<DiagramModelGenerics> | undefined;
        if(workflowId === activeTab.id) {
            _CanvasModel = WorkflowCanvas.model
        } else {
            _CanvasModel = convertSerializedWorkflowDataToModel(workflowInfo.details as SerializedData)
        }
        
        // setTimeout is added as _CanvasModel is not getting updated right away 
        setTimeout(() => {
            addLabelsAndIsFieldHiddenToNodesIfEmpty(componentFnNamesInfo, _CanvasModel);
            const _serializedData = serializeWorkflow({ env: workflowInfo.env, config: JSON.stringify(workflowInfo.config), workflowDependenciesRef: {}, workflowsInfo: {}, workflowName: workflowInfo.name }, _CanvasModel)
            const payload = JSON.stringify(convertWorkflowDataForExecution(_CanvasModel, false, activeExecutionEnv, { workflowName: workflowInfo.name, userName: activeUserInfo.name }, workflowInfo.config));
            const data: UpdateWorkflow = { 
                payload, 
                details: _serializedData, 
                projectName: workflowInfo.name, 
                create_new_version: saveAsVersion, 
                isStarred: workflowInfo.isStarred,
                notes: workflowInfo.notes,
                hasVariables: checkIfVariableComponentsExist(),
                componentCounter: workflowInfo.componentCounter,
                directoryId: workflowInfo.directoryId,
                env: workflowInfo.env,
                zepplinData: typeof workflowInfo.zepplinData === 'string'? (workflowInfo.zepplinData as any) : JSON.stringify(workflowInfo.zepplinData || []),
                config: JSON.stringify(workflowInfo.config || [])
            };

            const onSuccess = (response: UpdateWorkflowResponse) => {
                const updatedWorkflowInfo: WorkflowCanvasTabInfo = { ...workflowInfo, details: _serializedData, saved: true, created: response.data.created, updated: response.data.updated };
                if (saveAsVersion) {
                    updatedWorkflowInfo.version = updatedWorkflowInfo.version + 1;
                }
                const timeout = saveAsVersion ? 500 : 0;
                setTimeout(() => { 
                    dispatch(updateAWorkflowEditorTabInfo({ type: getTabType('workflowEditor', updatedWorkflowInfo.env), info: updatedWorkflowInfo }));
                    successCb(response);
                }, timeout);
            }

            WorkflowHandler.UpdateWorkflow(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, workflowInfo.id, data, onSuccess);
    }, 1)
    
    }
};


export const updateDirectoryIdOfOpenWorkflows = (targetDirectoryId: number, workflowIds: number[]): AppThunk => (dispatch, getState) => {
    // Directory ID of workflows that are open needs to be updated to the new directory ID after they're moved
    const workflowEditorOpenTabs = getState().CanvasReducer.workflowEditor.openTabs;
    let updatedWorkflowTabInfo = false;
    workflowIds.forEach(workflowId => {
        if(workflowEditorOpenTabs.has(workflowId)) {
            const workflowInfo = cloneDeep(workflowEditorOpenTabs.get(workflowId)) as WorkflowCanvasTab;
            workflowInfo.info.directoryId = targetDirectoryId;
            workflowEditorOpenTabs.set(workflowId, workflowInfo);
            updatedWorkflowTabInfo = true;
        }
    });
    if(updatedWorkflowTabInfo) {
        updateOpenTabsOfACanvas('workflowEditor', workflowEditorOpenTabs);
    }

};

export const setComponentTreeData = (payload: ComponentTreeDataType) =>({
    type: SET_COMPONENT_TREE_DATA, payload 
})

export const addCustomComponentToList = (componentId:number): AppThunk => (dispatch, getState) => {
    const {
        AccountReducer: {
            activeUserInfo,
            envVariables : Env
        },
        CommonReducer: {
            activeExecutionEnv
        }
    } = getState();

    newComponentHandler.cloneCustomComponent(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, componentId, activeUserInfo.id, activeExecutionEnv, () => {
        successAlert("Added Successfully")
        dispatch(getComponentTreeData());
    })

}