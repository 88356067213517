import React, { memo, useState } from 'react';
import { WorkflowCanvasTabInfo } from '../../../../store/canvas';
import { convertEnumsToOptions, saveTextToFile } from '../../../../utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { JobsHandler } from '../../../../api/job-handler';
import classNames from 'classnames';
import { HelpTabRenderer } from '../../help-renderer/help-console-tab-renderer';
import { useDidUpdate } from 'rooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/types';
import { Env } from '../../../../constants/settings';
import { WorkflowNote } from '../../Notes/WorkflowNote';

import {ArrowUp,ArrowHeadUp, WebIcon, ArrowMinimise, Save, Popout} from '@assets/icons';


interface ConsoleComponentProps {
    canvasDimensions: any; 
    setCanvasDimensions: any; 
    canvasMinMaxHeight: { min: number; max: number };
    consoleInfo: WorkflowCanvasTabInfo['consoleInfo'];
    activeTabName: string;
}

enum ConsoleTabs {
    'Console' = 'Console',
    'Help' = 'Help',
    'Notes' = 'Notes',
}

const consoleTabsList = convertEnumsToOptions(ConsoleTabs);

export const ConsoleComponent: React.FC<ConsoleComponentProps> = memo(({
    canvasDimensions,
    setCanvasDimensions,
    canvasMinMaxHeight,
    consoleInfo,
    activeTabName
}) => {
    const [activeTab, setActiveTab] = useState(ConsoleTabs.Console);
    const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
    const  { toggleHelpTab } = useSelector((store: RootState) => store.HelpReducer);
    const  { toggleConsoleHeight,} = useSelector((store: RootState) => store.WorkflowReducer);
    const { activeTab: activeWorkflowTab } = useSelector((store: RootState) => store.CanvasReducer.workflowEditor);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const link = Env?.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`; 
    useDidUpdate(() => {
        // SETS HEIGHT TO MAX ONLY WHEN  
        // if(canvasDimensions.height > canvasMinMaxHeight.max / 2) {
        setCanvasDimensions({ width: '100%', height: canvasMinMaxHeight.min });
        setActiveTab(ConsoleTabs.Help);
        // }
    }, [toggleHelpTab]);

    useDidUpdate(() => {
        // SETS HEIGHT TO HALF OF MAX ONLY WHEN NEW LOGS ARE ADDED 
        if(canvasDimensions.height > canvasMinMaxHeight.max / 2) {
            setCanvasDimensions({ width: '100%', height: canvasMinMaxHeight.max/2 });
        }
        setActiveTab(ConsoleTabs.Console);
    }, [toggleConsoleHeight]);

    const downloadJobLogs = () => {
        if(consoleInfo?.logs) {
            let fileName = '';
            if(consoleInfo.jobInfo && consoleInfo.type !== 'capture_schema' && !Env?.REACT_APP_DATABRICKS) {
                fileName = consoleInfo.jobInfo.name;
                JobsHandler.GetJobLogs(link, consoleInfo.jobInfo.id, (res) => {
                    saveTextToFile(fileName+ '.log', res.join('\n'));
                });
            } else {
                fileName = activeTabName + '_' + moment(new Date()).format('YYYY_MM_DD_HH_mm_ss_SSS');
                saveTextToFile(fileName+ '.log', consoleInfo.logs.join('\n'));
            }
        }
    };

    const setCanvasHeight = (height: number) => {
        setCanvasDimensions({ width: '100%', height });
    };

    const changeConsoleHeightToHalf = () => setCanvasHeight(canvasMinMaxHeight.max / 2);

    // const isConsoleDisabled = !activeTabName;
    const isConsoleDisabled = false;

    return(
        <div className="output__console" >
            <div className="output__console__header">
                {/* <span>CONSOLE</span> */}
                <div className="output__console__tabs__box">
                    {consoleTabsList.map(tab => (
                        <button
                            id={`consoleTab_${tab}`}
                            key={tab.label}
                            className={classNames(
                                'btn-consoleTab switchable__tab', 
                                {
                                    'switchable__tab--active': activeTab === tab.value,
                                    'switchable__tab--hidden': tab.label === 'Notes' && !activeWorkflowTab.id
                                }
                            )}
                            onClick={() => setActiveTab(tab.value)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
                {/* <div className="horizontal__line" /> */}
                <div className='centerh'>
                    { activeTab === ConsoleTabs['Notes'] &&
                        <button
                            onClick={() => setIsNotesModalOpen(true)}
                        >
                            <div className='centerh'><Popout/></div>
                        </button>
                    }
                    {   activeTab === ConsoleTabs['Console'] &&
                        <button 
                            onClick={downloadJobLogs}
                            disabled={isEmpty(consoleInfo?.logs) || consoleInfo?.jobInfo?.state === 'running'}
                            id="btn__downloadLogs"
                        
                        >
                              <div  className='centerh'><Save/></div>                 
                        </button>
                    }
                    <button
                        onClick={() => setCanvasHeight(canvasMinMaxHeight.max)}
                        disabled={(canvasDimensions.height === canvasMinMaxHeight.max) || isConsoleDisabled}
                    >
                         <div className='centerh'><ArrowMinimise/></div>         
                    </button>
                    <button
                        onClick={changeConsoleHeightToHalf}
                        disabled={(canvasDimensions.height === canvasMinMaxHeight.max / 2) || isConsoleDisabled}
                    >
                        <div className='centerh'><ArrowUp/></div>  
                    </button>
                    <button
                        onClick={() => setCanvasHeight(canvasMinMaxHeight.min)}
                        disabled={(canvasDimensions.height === canvasMinMaxHeight.min) || isConsoleDisabled}
                    >
                         <div className='centerh'><ArrowHeadUp/></div>  
                    </button>
                </div>
            </div>
            <pre
                className={classNames('output__text', {
                    'hide': activeTab !== ConsoleTabs['Console']
                })}
            >
                {(consoleInfo?.logs || []).map((_text, index) =>
                    <code key={index * 1.2}>
                        {_text}
                    </code>
                )}
            </pre>
            <HelpTabRenderer hide={activeTab !== ConsoleTabs['Help']} />

            <WorkflowNote visible={activeTab === ConsoleTabs['Notes']} isModalOpen={isNotesModalOpen} closeModal={() => setIsNotesModalOpen(false)}/>
        </div>
    );
});

ConsoleComponent.displayName = 'ConsoleComponent';