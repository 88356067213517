import { AzureBlobFileInfo, BaseFileInfo, ConnectionSuccessResponse, EnableConnectionReq, FileListResponse } from './types';
import { HttpClient } from '../services';
import { API, Env } from '../../constants/settings';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import HttpInvoker from '../services/httpinvoker';
import { _Axios } from '../services/httpsettings';
import { ApiResponse } from '../data-source-handler';
import moment from 'moment';
import queryString from 'query-string';
import { downloadFileUsingLink } from '../../helpers/utils';
import { StorageConnectionData } from '@pages/file-browser/types';

const CancelToken = _Axios().CancelToken;
class FileHandlerClass {
    GetInitialDirectoriesList(callback: (arg0: FileListResponse) => any) {
        const data = new FormData();
        data.append('cmd', 'open');
        data.append('init', '1');
        data.append('tree', '1');
        HttpClient.Post('Get all directories', `${API.fileBrowserUrl}/elfinder/connector`, data, callback);
    }

    GetFilesOfDirectory(targetDirectoryHash: string): Promise<AxiosResponse<FileListResponse>> {
        const data = new FormData();
        data.append('cmd', 'open');
        data.append('target', targetDirectoryHash);
        // HttpClient.Post('Get all directories', `${API.url}/elfinder/connector`, data, callback);
        return HttpInvoker._post(`${API.fileBrowserUrl}/elfinder/connector`, data);
    }

    

    DeleteFilesElfinder(selectedFilesHash: string[], callback: ApiResponse<{removed: string[]}>) {
        const queryParams: any = { cmd: 'rm', '_': moment().format('x'), 'targets[]': selectedFilesHash};
        HttpClient.Get('Delete Files Elfinder', `${API.fileBrowserUrl}/elfinder/connector?${queryString.stringify(queryParams)}`, callback);
    }

    // getDbfsFiles(path: any): Promise<AxiosResponse<FileListResponse>> {
    //     return HttpInvoker._get(`${API.databricksFilerBrowser}/v2/getfilesdbfs?path=/users/${path}`);
    // }
    getDbfsFiles(path: any, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Get('Get Dbfsfiles by type', `${API.databricksFilerBrowser}/v2/getfilesdbfs?path=/users/${path}`, callback, errorHandlerCallback);
    }

    private _uploadFile(url: string, _formData: FormData, callback: ApiResponse<any>, errorHandlerCallback?: ApiResponse<AxiosResponse>, uploadProgressCb?: ApiResponse<ProgressEvent>) {
        const source = CancelToken.source();
        const config: AxiosRequestConfig = {
            timeout: 3000000,
            onUploadProgress: uploadProgressCb,
            cancelToken: source.token
        };
        _Axios()
            .post(url, _formData, config).then((res: any)=>{
                callback(res.data);
            }).catch(function (error) {
                // handle error
                const errMsg = error || {};
                if(errorHandlerCallback){
                    error.response && errorHandlerCallback(errMsg);
                    // errorHandlerCallback(errMsg);
                }
                
            });
        return source.cancel;
    }

    private _downloadFile(url: string, fileName: string) {
        const config: AxiosRequestConfig = {
            timeout: 3000000,
            responseType: 'blob'
        };
        _Axios()
            .get(url, config).then((res: any)=>{
                // @ts-ignore
                if (!window.navigator.msSaveOrOpenBlob){
                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    downloadFileUsingLink(url, fileName);
                    window.URL.revokeObjectURL(url);
                }else{
                    // BLOB FOR EXPLORER 11
                    // @ts-ignore 
                    window.navigator.msSaveOrOpenBlob(new Blob([res.data]), fileName);
                }
            });
    }

    uploadFile(path: any, _formData: any, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void, uploadProgressCb?: ApiResponse<ProgressEvent>) {
        this._uploadFile(`${API.databricksFilerBrowser}/v2/uploadfiledbfs?path=/users/${path}`, _formData, callback, errorHandlerCallback, uploadProgressCb);
        // HttpClient.Post('Upload File by type', );
    }
    DeleteFile(path: any, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Delete('Delete File', `${API.databricksFilerBrowser}/v2/deletefiledbfs?path=/users${path}`, callback, errorHandlerCallback);
    }

    getBlobFilesAndDirectoriesUsingPath(link: string, username: any, callback: ApiResponse<AzureBlobFileInfo[]>, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Get('GET Azure blob storage files', `${link}/file/list?path=FileSystem/${username}/`, callback, errorHandlerCallback);
    }

    blobUploadFile(link: string, path: any, _formData: any, callback: (arg0: any) => void, errorHandlerCallback: (arg0: any) => any, uploadProgressCb?: ApiResponse<ProgressEvent>) {
        return this._uploadFile(`${link}/file/upload?path=FileSystem/${path}/`, _formData, callback, errorHandlerCallback, uploadProgressCb);
    }

    downloadFileblob(link: string, path: any, fileName: string) {
        this._downloadFile( `${link}/file/download?path=FileSystem${path}`, fileName);
    }

    deleteFileBlob(link: string, path: any, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Delete('Delete File', `${link}/file/delete?path=FileSystem${path}`, callback, errorHandlerCallback);
    }

    uploadFilesElfinder(targetDirectoryHash: string, files: File[], callback: ApiResponse<{added: BaseFileInfo[]}>, errorCallback: ApiResponse<AxiosResponse>, uploadProgressCb?: ApiResponse<ProgressEvent>) {
        const data = new FormData();
        data.append('cmd', 'upload');
        data.append('target', targetDirectoryHash);
        files.forEach(file => {
            data.append('upload[]', file);
            data.append('mtime[]', moment().format('x'));
        });

        this._uploadFile(`${API.fileBrowserUrl}/elfinder/connector`, data, callback, errorCallback, uploadProgressCb);
        // HttpClient.Post('Upload Files Elfinder', `${API.fileBrowserUrl}/elfinder/connector`, data, callback, errorCallback);
    }

    readAzureFile(link: string, path: string, callback: ApiResponse<string>) {
        HttpClient.Get('Get doc by name', `${link}/file/read?path=FileSystem${path}`, callback);
    }
    readCSVFile(link: string, path: string, callback: ApiResponse<string>,errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Get('read csv file', `${link}/file/read-csv?path=FileSystem${path}`, callback, errorHandlerCallback);
    }
    updateCSVFile(link: string, path: string, data:any,callback: ApiResponse<string>,errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Put('Update a csv file', `${link}/file/update-csv?path=FileSystem${path}`,data, callback,errorHandlerCallback);
    }
    getConnections(link: string, callback: ApiResponse<StorageConnectionData[]>) {
        return HttpClient.Get('Get all connections' ,`${link}/storage/connection/list`, callback);
    }
    getStorageConnectionTypeOptions(link: string, callback: ApiResponse<string[]>) {
        HttpClient.Get('Get Storage connections type options', link + '/storage/connection-type-list', callback);
    }
    deleteConnection(link: string, id: string, callback: ApiResponse<ConnectionSuccessResponse>, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Delete('Delete Connection', `${link}/storage/connection/${id}`, callback, errorHandlerCallback);
    }
    enableConnection(link: string, data:EnableConnectionReq,callback: ApiResponse<string>,errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Enable a connection', link + '/storage/connection/default',data, callback,errorHandlerCallback);
    }
    createConnection(link: string, data:StorageConnectionData,callback: ApiResponse<ConnectionSuccessResponse>,errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Create a connection', link + '/storage/connection',data, callback,errorHandlerCallback);
    }
    updateConnection(link: string, data:StorageConnectionData,callback: ApiResponse<ConnectionSuccessResponse>,errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Put('Update a connection', link + '/storage/connection/update',data, callback,errorHandlerCallback);
    }
}

const FileHandler = new FileHandlerClass();

export { FileHandler };