import { FileHandler } from "@api/file-handler";
import { SelectField, _selectoptionType } from "@components/form/select-field";
import { Modal } from "@components/modals";
import CustomMonacoEditor from "@components/monacoEditor";
import { Env } from "@constants/settings";
import { buildSoapXmlUsingObject, parseSoapWsdl } from "@services/SoapParser";
import { BindingWsdlObject } from "@services/SoapParser/types";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { uuid } from "uuidv4";
import { KeyPairTableValueType } from "./fieldComponents/keyPairComponents";
import { BaseFieldType } from "./types";
import { RootState } from "@store/types";
import { useSelector } from "react-redux";

type SoapBodyEditorProps = {
	isOpen: boolean;
	toggleClose: () => void;
	soapBody: string;
	onSave: (body: string) => void;
};

const SoapBodyEditor: React.FC<SoapBodyEditorProps> = ({
	isOpen,
	toggleClose,
	soapBody,
	onSave,
}) => {
	const editorProps = CustomMonacoEditor.useCustomMonacoEditorController();

	useEffect(() => {
		if (isOpen) editorProps.setEditorValue(soapBody);
	}, [soapBody, isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			toggleClose={toggleClose}
			title=""
			shouldCloseOnOverlayClick={false}
			className="SoapBodyEditor__modal"
		>
			<CustomMonacoEditor
				{...editorProps}
				editorHeight="50vh"
				editorWidth="70vw"
				language="xml"
			/>
			<div className="modalBtns__box">
				<button
					className="btn-md btn-yellow"
					type="button"
					onClick={() => onSave(editorProps.editorValue)}
				>
					Save
				</button>
				<button
					className="btn-md btn-cancel"
					type="button"
					onClick={toggleClose}
				>
					Cancel
				</button>
			</div>
		</Modal>
	);
};

type SoapFieldProps = {
	fieldData: BaseFieldType;
};

export type SoapFieldValueInFormikContext = {
	bindingType: string;
	inputType: string;
	payload: string;
};

const SoapField: React.FC<SoapFieldProps> = ({ fieldData }) => {
	const { values, setFieldValue } = useFormikContext<any>();
	const [bindingTypes, setBindingTypes] = useState<BindingWsdlObject[]>([]);
	const [showSoapBodyEditor, toggleSoapBodyEditor] = useState(false);
	const [wsdlString, setWsdlString] = useState<string | null>(null);
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

	useEffect(() => {
		if (values.source === "azure_blob" && values.azure_file_path) {
			const filePath = values.azure_file_path.replace(
				Env?.REACT_APP_AZURE_BLOB_FILESYSTEM_PREFIX ,
				""
			);
			FileHandler.readAzureFile(`${Env?.REACT_APP_PLATFORM_URL}/filebrowser/api`, filePath, (res) => {
				setWsdlString(res);
			});
		}
	}, [values.source, values.azure_file_path]);

	const [, { value }] = useField<SoapFieldValueInFormikContext>(
		fieldData.key
	);

	const getFieldKey = (name: string) => fieldData.key + "." + name;

	const bindingsName = getFieldKey("bindingType");
	const inputTypeFieldName = getFieldKey("inputType");

	useEffect(() => {
		if (wsdlString) {
			parseSoapWsdl(wsdlString).then((parsedWsdl) => {
				const bindingTypes = Object.values(parsedWsdl)[0];
				setBindingTypes(bindingTypes);
			});
		}
	}, [wsdlString]);

	const bindingOptions = useMemo(() => {
		return bindingTypes.map((binding) => ({
			label: binding.binding,
			value: binding.binding,
			inputTypes: binding.inputTypes,
			endpointUrl: binding.endpointUrl,
			headers: binding.headers,
		}));
	}, [bindingTypes]);

	const inputTypeOptions = useMemo(() => {
		const bindingInfo = bindingTypes.find(
			(binding) => binding.binding === value.bindingType
		);
		if (bindingInfo) {
			return bindingInfo.inputTypes.map((inputType) => ({
				label: inputType.inputType,
				value: inputType.inputType,
				body: inputType.body,
			}));
		}
		return [];
	}, [value.bindingType, bindingTypes]);

	const bodyOfSelectedInputType = useMemo(() => {
		const inputTypeInfo = inputTypeOptions.find(
			(inputType) => inputType.label === value.inputType
		);
		if (inputTypeInfo) {
			return {
				body: inputTypeInfo.body,
				asString: buildSoapXmlUsingObject(inputTypeInfo.body),
			};
		}
		return null;
	}, [inputTypeOptions, value.inputType]);

	const handleSelectBindingType = (option: _selectoptionType) => {
		setFieldValue("url", option.endpointUrl);

		const headersValue: KeyPairTableValueType = Object.keys(
			option.headers
		).reduce(
			(acc: KeyPairTableValueType, headerKey: any) => {
				const id = uuid();
				acc.rows.push(id);
				acc.data[id] = {
					key: headerKey,
					value: option.headers[headerKey],
				};

				return acc;
			},
			{
				rows: [],
				data: {},
			}
		);
		setFieldValue("headers", headersValue);
	};

	const closeSoapBodyEditor = useCallback(() => {
		toggleSoapBodyEditor(false);
	}, []);

	const onSoapBodySave = useCallback((body: string) => {
		setFieldValue(getFieldKey("payload"), body.replaceAll(/\n/g, ""));
		closeSoapBodyEditor();
	}, []);

	return (
		<div className="soapField">
			<SelectField
				options={bindingOptions}
				label="Bindings"
				name={bindingsName}
				onOptionClick={handleSelectBindingType}
			/>
			<SelectField
				options={inputTypeOptions}
				label="Input Type"
				name={inputTypeFieldName}
				className="inputType__dropdown"
			/>
			{!!bodyOfSelectedInputType && (
				<button
					className="btn btn-md btn-yellow"
					onClick={() => toggleSoapBodyEditor(true)}
				>
					Edit SOAP body
				</button>
			)}
			<SoapBodyEditor
				isOpen={showSoapBodyEditor}
				toggleClose={closeSoapBodyEditor}
				soapBody={
					bodyOfSelectedInputType
						? bodyOfSelectedInputType.asString
						: ""
				}
				onSave={onSoapBodySave}
			/>
		</div>
	);
};

export default SoapField;
