import React, { useEffect, useMemo, useState } from 'react';
import Form, { InputField } from '../../../../components/form';
import * as yup from 'yup';
import { SelectField, _selectoptionType } from '../../../../components/form/select-field';
import styles from '../../styles.module.scss';
import { DataSourceHandler } from '@api/data-source-handler';
import { InputNumber, Select, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { infoAlert } from '@components/toastify/notify-toast';
const { Option } = Select;


const nifiFormSchema = yup.object({
    edgeName: yup.string().test(
        "no-only-spaces",
        "This field cannot contain only spaces.",
        (value) => value && value.trim().length > 0
      ).test(
        "no-leading-spaces",
        "This field cannot start with a space.",
        (value) => value && value[0] !== " "
      ).required('This is a required field'),
    accessKey: yup.string().test(
        "no-only-spaces",
        "This field cannot contain only spaces.",
        (value) => value && value.trim().length > 0
      ).test(
        "no-leading-spaces",
        "This field cannot start with a space.",
        (value) => value && value[0] !== " "
      ).required('This is a required field'),
    accessKeyConfirm: yup.string().test(
        "no-only-spaces",
        "This field cannot contain only spaces.",
        (value) => value && value.trim().length > 0
      ).test(
        "no-leading-spaces",
        "This field cannot start with a space.",
        (value) => value && value[0] !== " "
      ).required('This is a required field').oneOf([yup.ref('accessKey'), null], 'AccessKey must match'),
    nodeKind: yup.string().required('This is a required field'),
    sinkInformation: yup.string(),
    sinkKind: yup.string(),
    enableScaling: yup.boolean(),
    scaleNodes: yup.number()
});

export type NifiFormValues = yup.InferType<typeof nifiFormSchema>

export type UserFormProps = {
    type: 'UPDATE' | 'ADD';
    initialValues: any;
    submitButtons?: React.ReactNode;
    onSubmit?: (arg0: NifiFormValues) => any;
}



export const NifiForm: React.FC<UserFormProps> = ({ onSubmit, type, initialValues, submitButtons}) => {
    const [nodeKindOptions, setNodeKindOptions] = useState<_selectoptionType[]>([]);
    const [sinkOptions, setSinkOptions] = useState<_selectoptionType[]>([]);
    const handleFormSubmit = (values: NifiFormValues) => {
        const submitValues = {
            ...values,
            sinkInformation: selectedSinkName || '',
            sinkKind: selectedSinkKind || '',
            enableScaling: enableScaling ,
            scaleNodes: scaledNodesCount
        };
        onSubmit && onSubmit(submitValues);
    };
    const [selectedNodeKind, setSelectedNodeKind] = useState<string>(''); 
    const [selectedSinkKind, setSelectedSinkKind] = useState<string>(''); 
    const [selectedSinkName, setSelectedSinkName] = useState<string>(''); 
    const [enableScaling, setEnableScaling] = useState(false);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const initialValuesForForm: NifiFormValues = useMemo(() => {
        return { 
            edgeName: initialValues?.nodeName ||'',  
            accessKey: initialValues?.accessKey ||'', 
            accessKeyConfirm:'',
            nodeKind: initialValues?.nodeKind || selectedNodeKind,
            sinkInformation: initialValues?.sinkInformation || selectedSinkName,
            sinkKind: selectedSinkKind || '',
            enableScaling: initialValues?.enableScaling || false,
            scaleNodes: initialValues?.scaleNodes || 2,
        };
    }, [initialValues, type, selectedSinkKind]);

    const [scaledNodesCount, setScaledNodesCount] = useState<number>(initialValuesForForm.scaleNodes);

    useEffect(() => {
        DataSourceHandler.GetNodeKind(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, (res) => {
            setNodeKindOptions(res.map((_res: any) => {
                return {
                    label: _res,
                    value: _res
                }
            }))
        });
    },  []);

    useEffect(() => {
            DataSourceHandler.GetSinkNodes(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, (res) => {
                setSinkOptions(
                    res.map((_res: any) => {
                        return {
                            label: _res.sinkName,
                            value: _res.sinkKind,
                        };
                    })
                );
            });
    }, [selectedNodeKind]);

    return(
        <Form
            initialValues={initialValuesForForm}
            validationSchema={nifiFormSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
        >
            <div
                className={styles['userInfoForm']}
            >
                <InputField 
                    name="edgeName"
                    label="Node Name"
                    required={true}
                    autoComplete="off"
                    disabled={type === 'UPDATE'}
                />
                <SelectField
                    name="nodeKind"
                    label="Node Kind"
                    options={nodeKindOptions}
                    required={true}
                    className='mb-0'
                    onOptionClick={(nodeKindOptions) => {
                        setSelectedNodeKind(nodeKindOptions.value);
                    }}
                />
                {(selectedNodeKind === 'OPCUA' && type === 'ADD') || (type === 'UPDATE' && initialValues.nodeKind === 'OPCUA') 
                || (type ==='UPDATE' && selectedNodeKind=='OPCUA')? (
                    <SelectField
                        name="sinkInformation"
                        label="Choose Sink"
                        options={sinkOptions}
                        initial_value={initialValuesForForm.sinkInformation}
                        className="mb-0"
                        required={true}
                        onOptionClick={(sinkOption) => {
                            setSelectedSinkName(sinkOption.label); 
                            setSelectedSinkKind(sinkOption.value);
                        }}
                    />
                ) : null}

                <InputField 
                    name="accessKey"
                    label="Access Key"
                    type="password"
                    required={true}
                    autoComplete="off"
                />
                <InputField 
                    name="accessKeyConfirm"
                    label="Confirm Access Key"
                    required={true}
                    type="password"
                    autoComplete="off"
                />
                <div className={styles['form-item']}>
                    <label>Enable Scaling</label>
                    <Switch
                        checked={enableScaling?enableScaling:initialValuesForForm.enableScaling}
                        onChange={(checked) => setEnableScaling(checked)}
                        checkedChildren="Enabled" 
                        unCheckedChildren="Disabled"
                        disabled={type === 'UPDATE'}
                    />
                </div>

                {(enableScaling || initialValuesForForm.enableScaling) && (
                    <div className={styles['form-item']}>
                        <label>No. of Scaled Nodes</label>
                        <Select
                            defaultValue={2}
                            style={{ width: '100%' }}
                            value={scaledNodesCount !== undefined ? scaledNodesCount : initialValuesForForm.scaleNodes}
                            onSelect={(value: number) => {
                                if (value < (scaledNodesCount || initialValuesForForm.scaleNodes)) {
                                    infoAlert("Please delete the nodes instead of selecting shorter number.") 
                                } else {
                                    setScaledNodesCount(value);
                                }
                            }}
                        >
                            {Array(9).fill(0).map((_, index) => {
                                const optionValue = index + 2;
                                return (
                                    <Select.Option
                                        key={optionValue}
                                        value={optionValue}
                                        disabled={optionValue < (scaledNodesCount || initialValuesForForm.scaleNodes)} 
                                    >
                                        {optionValue}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                )}
                <div className={styles['divider']}></div>
            </div>
            {!!submitButtons && submitButtons}
        </Form>
    );
};

