import { HttpClient } from '../services';
import {  ConnectionInfo, CreateConnection, AllConnectionsInfo, GetTablesInfo, RecordsSearchQuery, 
    RecordsInfoResponse, CreateCatalogType, CatalogInfo, ApiPromise, ApiResponse, PiRequestQueryResponse, PiRequestQuery, NewConnectionMetadata, NiFiManagedNodesResponse, AwsConnectionType, AuditRequestHistoryType, queryRequest, queryResponse, DeepSqlConnectionInfo } from './types';
import { API } from '../../constants/settings';
import { makeSearchParams } from '../../helpers/makeSearchParams';
import queryString from 'query-string';
import { DeleteCustomComponentResponse } from '../new-custom-component';
import { WitsmlHandler } from './witsml-handler';
import { NiFiSinkData } from '@pages/admin/nifi_management/types';
import isEmpty from 'lodash/isEmpty'
import { AI_QUERY, AI_RESPONSE, AI_QUERY_LANGGRAPH } from '@pages/data-explorer/gen-ai/types';
import { AxiosPromise, AxiosResponse } from 'axios';
class DataSourceHandlerClass extends WitsmlHandler {
    CreateConnection(link: string, data: CreateConnection, callback: (arg0: ConnectionInfo[]) => any, errorHandlerCallback: any){
        HttpClient.Post('Create a connection', link + '/v2/connection', data, callback, errorHandlerCallback);
    }

    UpdateConnection(link: string, connectionId: string, data: CreateConnection, callback: (arg0: ConnectionInfo[]) => any, errorHandlerCallback: any){
        HttpClient.Put('Create a connection', link + `/v2/connection/${connectionId}`, data, callback, errorHandlerCallback);
    }

    DeleteConnection(link: string, connectionId: number, callback: (arg0: DeleteCustomComponentResponse) => any, errorHandlerCallback: any){
        HttpClient.Delete('Create a connection', link + `/v2/connection/${connectionId}`, callback, errorHandlerCallback);
    }

    GetAllConnectionsInfo(link: string, callback: (arg0: AllConnectionsInfo) => any) {
        HttpClient.Get('Get all connections', link + '/v2/connection-metadata', callback);
    }

    GetDatabaseTypeList(link: string, callback: (arg0: NewConnectionMetadata) => any) {
        HttpClient.Get('Get Database Type List', link + '/v2/connection-type-metadata', callback);
    }

    GetTables(link: string, data: GetTablesInfo, callback: (arg0: string[]) => any,errorHandlerCallback: any) {
        const searchQuery = makeSearchParams(data);
        HttpClient.Get('Get Database Type List', link + `/v2/tables${searchQuery}`, callback,errorHandlerCallback);
    }

    GetRecords(link: string, searchQuery: RecordsSearchQuery, callback: (arg0: RecordsInfoResponse) => any){
        const _searchQuery = queryString.stringify(searchQuery, { encode: true });
        HttpClient.Get('Get Database Type List', link + `/v2/records?${_searchQuery}`, callback);
    }

    // GetDatabasesOfAConnection(connectionId: number, callback: (arg0: string[]) => void){
    //     HttpClient.Get('Get all connections', API.databrowserUrl + `/v2/databases?connectionId=${connectionId}`, callback);
    // }

    // CreateCatalog(data: CreateCatalogType): ApiPromise<CatalogInfo> {
    //     return HttpClient.PostPromise( API.databrowserUrl + '/v2/catalogs', data);
    // }
    
    // GetCatalogs(callback: (arg0: any) => any) {
    //     HttpClient.Get('Get all connections', API.databrowserUrl + '/v2/catalogs', callback);
    // }

    GetPIServer(link:string, connectionId: number, callback: (arg0: any) => void, error: (arg0: any) => void ) {
        HttpClient.Post('Get PI Server List', link + `/v2/osi-servers?connectionId=${connectionId}`, {
            'attr_type' : 'PI_Server',
            'site_id': 2
        }, callback, error);
    }

    GetPIData(link:string, connectionId: number, data: any, callback: (arg0: any) => void, error: (arg0: any) => void){
        HttpClient.Post('Get PI Data', link + `/v2/osi-data?connectionId=${connectionId}`, data, callback, error);
    }

    // GetContainersListInAzureBlobConn(connectionId: number, callback: ApiResponse<string[]>) {
    //     HttpClient.Get('Get PI Data', API.databrowserUrl + `/v2/azureblob/listofcontainers?connectionId=${connectionId}`, callback);
    // }

    // GetAzureBlobsOfContainer(connectionId: number, containerName: string, callback: ApiResponse<string[]>) {
    //     HttpClient.Get('Get PI Data', API.databrowserUrl + `/v2/azureblob/listofblobs?connectionId=${connectionId}&containerName=${containerName}`, callback);
    // }

    GetEdgeNodes(link:string, callback: ApiResponse<any>) {
        HttpClient.Get('GetEdgeNodes', link + '/v2/node', callback)
    }

    GetSinkNodes(link:string, callback: ApiResponse<any>) {
        HttpClient.Get('GetSinkNodes', link + '/v2/sink/', callback)
    }

    // GetNodesOfNiFiManaged(callback: ApiResponse<NiFiManagedNodesResponse>) {
    //     HttpClient.Get('GetNodesOfNiFiManaged', API.databrowserUrl + '/v2/node-metadata', callback)
    // }

    GetRequestsOfPiNode(link:string, nodeId: string, searchQuery: PiRequestQuery, callback: PiRequestQueryResponse) {
        const _searchQuery = queryString.stringify(searchQuery, { encode: true, skipNull: true });
        HttpClient.Get('GetRequestsOfPiNode', link + `/v2/request/pagination/${nodeId}?${_searchQuery}`, callback)
    }

    CreatePiNodeRequest(link:string, nodeName: string, requestName: string, stringifiedJson: string, sinkName:string, sinkKind:string, callback: ApiResponse<any>) {
        HttpClient.Post('Create Node Request', link + '/v2/request', { nodeName, json: stringifiedJson, state: "new", requestName , sinkName, sinkKind}, callback)
    }

    SubmitPiRequest(link:string, requestId: number, callback: ApiResponse<any>,errorHandlerCallback?: any) {
        HttpClient.Put('Submit Node Pi Request', link + '/v2/request/' + requestId + '/submit', {}, callback,errorHandlerCallback)
    }

    StopPiRequest(link:string, requestId: number, callback: ApiResponse<any>) {
        HttpClient.Put('Submit Node Pi Request', link + '/v2/request/' + requestId + '/stop', {}, callback)
    }

    DeletePiRequest(link:string, requestId: number, callback: ApiResponse<any>) {
        HttpClient.Delete('Delete Node Pi Request', link + '/v2/request/' + requestId, callback)
    }

    DownloadPiRequestJson(link:string, requestId: number, callback: ApiResponse<any>) {
        HttpClient.Get('Submit Node Pi Request', link + '/v2/request/' + requestId + '/download', callback)
    }

    UpdatePiRequestJson(link:string, requestId: string, requestName: string, stringifiedJson: string, sinkName:string, sinkKind:string, callback: ApiResponse<any>) {
        HttpClient.Put('Update Node Pi Request Json', link + '/v2/request/' + requestId + '/json', { json: stringifiedJson, requestName, sinkName, sinkKind }, callback)
    }

    ClonePiRequest(link:string, requestId: number, callback: ApiResponse<any>) {
        HttpClient.Put('Submit Node Pi Request', link + '/v2/request/' + requestId + '/clone', {}, callback)
    }

    // DeleteEdgeByID(requestId: number, callback: ApiResponse<any>) {
    //     HttpClient.Delete('Delete Edge Request', API.databrowserUrl + '/v2/node/' + requestId, callback)
    // }

    CreateEdgeNode(link:string, data: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Post('Create a Edge Connection', link + '/v2/node', data, callback, errorHandlerCallback);
    }
    
    UpdateEdgeNode(link:string, nodeName: string, data: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Put('Update Edge Connection', link + '/v2/node/' + nodeName , data, callback, errorHandlerCallback);
    }

    DeleteEdgeNode(link:string, nodeName: string, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Delete('Create a Edge Connection', link + '/v2/node/' + nodeName, callback, errorHandlerCallback);
    }

    CreateSinkNode(link:string, data: NiFiSinkData, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Post('Create a Sink Connection', link + '/v2/sink/', data, callback, errorHandlerCallback);
    }

    UpdateSinkNode(link:string, sinkName: string, data: NiFiSinkData, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Put('Update Sink Connection', link + '/v2/sink/' + sinkName , data, callback, errorHandlerCallback);
    }

    DeleteSinkNode(link:string, sinkName: string, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Delete('Delete a Sink Connection', link + '/v2/sink/' + sinkName, callback, errorHandlerCallback);
    }

    DownloadEdgeCertificate(link:string, data:  any) {
        if(data.nodeName){
            return HttpClient.GetBlob(link + `/v2/${data.nodeName}/generate-config`);
        }else{
            return HttpClient.GetBlob(link + `/v2/${data.parentNode}/generate-config?nodeScaleName=${data.nodeScaleName}`);
        }   
    }
    GetNodeNames(link:string, type: string, callback: ApiResponse<any>) {
        HttpClient.Get('Submit Node Pi Request', link + '/v2/node-name?nodeKind=' + type, callback);
    }

    GetSinkType(link:string, callback: ApiResponse<any>) {
        HttpClient.Get('Submit Node Pi Request',link + '/v2/sink-type', callback);
    }

    GetConnectionBySinkKind(link:string, sinkKInd: string, callback: (arg0: string[]) => void){
        HttpClient.Get('Get all connections by sinkKind', link + `/v2/connections?database_type=${sinkKInd}`, callback);
    }

    GetNodeKind(link:string, callback: ApiResponse<any>) {
        HttpClient.Get('Submit Node Pi Request',link + '/v2/node-kind', callback);
    }

    CloneConnectionRequest(link:string, connectionId: number, callback: ApiResponse<string>) {
        HttpClient.Get('Submit Clone Connection Request', link + '/v2/connection/clone?connectionId='+ connectionId, callback);
    }

    GetAWSConnectionList(link:string, callback: ApiResponse<AwsConnectionType[]>) {
        HttpClient.Get('Get AWS Connection List Request', link + '/v1/datasource?fs_type=s3', callback);
    }
    
    GetAuditReqHistory(link:string, requestId:string,callback: ApiResponse<AuditRequestHistoryType[]>) {
        HttpClient.Get('Get Audit Request History', link + '/v2/audit/request/'+ requestId, callback);
    }

    GetProgressReqHistory(link:string, requestId:string,callback: ApiResponse<any[]>) {
        HttpClient.Get('Get Process Request History', link + `/v2/request/${requestId}/progress`, callback);
    }
    
    SaveQueryResponse(link:string, data: queryRequest, callback?:any, errorHandlerCallback?: any) {
        HttpClient.Post('Save Query Response', link + '/v2/query', data, callback, errorHandlerCallback);
    }

    GetAllQueryResponse(link:string, page:number,callback: ApiResponse<queryResponse>) {
        HttpClient.Get('Get All Query Response', link + '/v2/query?page='+ page+'&size=5', callback);
    }

    GetDatabaseTableWithSchema(link:string, connectionId: number) {
        return HttpClient.GetPromise(link + `/v2/database-meta-data/${connectionId}`);
    }

    // GetTablesPromise(link:string, data: GetTablesInfo) {
    //     const searchQuery = makeSearchParams(data);
    //     return HttpClient.GetPromise(link + `/v2/tables${searchQuery}`);
    // }

    // RefreshConnectionId(link:string, connectionId: number) {
    //     return HttpClient.GetPromise(link + `/v2/database-meta-data/${connectionId}/refresh`);
    // }

    // OpenAIGPT(connectionId: number, prompt: string) {
    //     return HttpClient.PostPromise(API.databrowserUrl + `/v2/openai/query/${connectionId}`, {
    //         prompt
    //     })
    // }
    // AzureGPT(connectionId: number, prompt: string, settings: any) {
    //     return HttpClient.PostPromise(API.databrowserUrl + `/v2/openai/query/${connectionId}?cognitiveServices=AzureOpenAI`, {
    //         prompt,
    //         ...settings
    //     })
    // }
    // GenerativeAI(connectionId: number, prompt: string, settings: any) {
    //     return HttpClient.PostPromise(API.databrowserUrl + `/v2/openai/query/${connectionId}?cognitiveServices=AzureOpenAI&checkQuery=true`, {
    //         prompt,
    //         ...settings
    //     })
    // }
    GenerativeAINew(link: string, connectionId: number,prompt: string, settings?: any) {
        return HttpClient.PostPromise(link + `/schema/${connectionId}/query_gpt_sql`, {
            query:prompt,
            ...settings
        })
    }

    GenerativeAICygnet(connectionId: number,prompt: string) {
        return HttpClient.PostPromise(`https://deepsqldeploy5.dev.deepiqlab.com/schema/${connectionId}/query_cygnet?ss_wford=true`, {
            query:prompt,
        })
    }
    OpenAI(link: string, prompt: string, settings: any) {
        return HttpClient.PostPromise(link + `/v2/openai/azure/query`, {
            prompt,
            ...settings
        })
    }
    DeepSQLChat(link: string, db_id: number, session_id: string, data: AI_QUERY,username:string): AxiosPromise<AI_RESPONSE> {
        if(!isEmpty(session_id)) {
            return HttpClient.PostPromise(link + `/v2/schema/${db_id}/gpt/?session_id=${session_id}&username=${username}`, data)
        }
        return HttpClient.PostPromise(link + `/v2/schema/${db_id}/gpt/?username=${username}`, data)
    }

    DeepSQLChatLanggraph(link: string, db_id: number, session_id: string, data: AI_QUERY_LANGGRAPH,username:string): AxiosPromise<AI_RESPONSE> {
        if(!isEmpty(session_id)) {
            return HttpClient.PostPromise(link + `/v2/langgraph/${db_id}/?session_id=${session_id}&username=${username}`, data)
        }
        return HttpClient.PostPromise(link + `/v2/langgraph/${db_id}/?username=${username}`, data)
    }

    CygnetSQLChat(session_id: string, data: AI_QUERY): AxiosPromise<AI_RESPONSE> {
        if(!isEmpty(session_id)) {
            return HttpClient.PostPromise(`https://deepsqldeploy5.dev.deepiqlab.com/v2/schema/cygnet_custom/gpt/?session_id=${session_id}`, data)
        }
        return HttpClient.PostPromise(`https://deepsqldeploy5.dev.deepiqlab.com/v2/schema/cygnet_custom/gpt/`, data)
    }

    GetLogs(link: string, query_uuid: string) {
        return HttpClient.GetPromise(link + `/v2/query_logs/?query_uuid=${query_uuid}`)
    }

    GetSchemaForiegn(link: string, db_id: number) {
        return HttpClient.GetPromise(link + `/v2/${db_id}/schema_foreign_key/`)
    }

    GetSchemaTable(link: string, db_id: number) {
        return HttpClient.GetPromise(link + `/v2/${db_id}/schema_table_aug/`)
    }

    GetSchemaColumn(link: string, db_id: number) {
        return HttpClient.GetPromise(link + `/v2/${db_id}/schema_column_aug/`)
    }

    PostSchemaForeign(link: string, db_id: number, data: any) {
        return HttpClient.PostPromise(link + `/v2/${db_id}/schema_foreign_key/`, data)
    }

    PostSchemaTable(link: string, db_id: number, data: any) {
        return HttpClient.PostPromise(link + `/v2/${db_id}/schema_table_aug/`, data)
    }

    PostSchemaColumn(link: string, db_id: number, data: any) {
        return HttpClient.PostPromise(link + `/v2/${db_id}/schema_column_aug/`, data)
    }

    DownloadLogs(link: string, session_id: string) {
        return HttpClient.GetPromise(link + `/v2/chat_history?session_id=${session_id}`)
    }

    GetSchemaIndex(link: string, db_id: number) {
        return HttpClient.GetPromise(link + `/schema/custom/${db_id}/indexing`)
    }

    GetVirtualViewList(link: string, db_id: number) {
        return HttpClient.GetPromise(link + `/v2/virtual_view_list/?database_id=${db_id}`)
    }

    GetAllDeepSqlConnectionsInfo(link: string, callback: (arg0: DeepSqlConnectionInfo[]) => any,errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Get('Get all deepsql connections', link + '/databases/', callback,errorCallback);
    }
 
    GetDeepSqlDatabaseTableWithSchema(link: string, connectionId: number) {
        return HttpClient.GetPromise(link + `/tables/?database_id=${connectionId}`);
    }

    PostVirtualView(link: string, data: any,callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        return HttpClient.Post("Post Virtual View",link + `/v2/virtual_view/`, data,callback,errorCallback);     
    }

    Feedback(link: string, query_uuid: string, impression: boolean, comments: string, sessionId: string ) {
        return HttpClient.PostPromise(link + `/v2/feedback/`, {
            query_uuid,
            positive: impression,
            comments,
            session_uuid: sessionId
        })
    }

    GetNifiGraphData(link: string, nodeName: string, time: string, type: string,  callback: ApiResponse<any>) {
        HttpClient.Get('GetNifiGraphData', link + `/v2/node/status/changes/${nodeName}?type=${type}&filter=${time}`, callback)
    }

    ShowHideFeedBack(link: string, username: string, callback: ApiResponse<any>) {
        HttpClient.Get('Show or Hide Feedback', link+`/get-user-config/?username=${username}`, callback) 
    }

    ShowPatches(link: string, patchName: string, command: string, callback: ApiResponse<any>) {
        HttpClient.Get('Show Patches', link+`/v2/node/patches?nodeName=${patchName}&command=${command}`, callback) 
    }

    UpdatePatches(link: string, data: any, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Update Patches', link+`/v2/request/patch/create-submit`, data, callback, errorCallback) 
    }

    DeleteScaleNodes(link: string, nodeName: string, nodeScaleName: string, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Delete('Delete Scale Node', link+`/v2/node/${nodeName}?nodeScaleName=${nodeScaleName}`, callback, errorCallback)
    }

    scaledNode(link: string, nodeName: string, callback: ApiResponse<any>) {
        HttpClient.Get('Show Patches', link+`/v2/scalednodes/${nodeName}`, callback) 
    }

    CreateModel(link: string, data: any, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Create Model', link+`/v2/models/create`, data, callback, errorCallback) 
    }

    CreateInstances(link: string, data: any, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Create Instance', link+`/v2/instances/create`, data, callback, errorCallback) 
    }

    getModelList(link: string,  callback: ApiResponse<any>) {
        HttpClient.Get('Get Models', link+`/v2/models/list`, callback) 
    }

    getInstancesList(link: string,  callback: ApiResponse<any>) {
        HttpClient.Get('Get Instances', link+`/v2/instances/list`, callback) 
    }

    fetchInstancesDetails(link: string, id: number, callback: ApiResponse<any>) {
        HttpClient.Get('Fetch Instances Details', link+`/v2/instances/get/${id}`, callback) 
    }

    UpdateInstancesDetails(link:string, id: number, data: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Put('Update Instances', link + `/v2/instances/update/${id}` , data, callback, errorHandlerCallback);
    }

    deleteInstances(link:string, id: number, callback: ApiResponse<any>) {
        HttpClient.Delete('Delete Model', link + `/v2/instances/delete?id=${id}` , callback)
    }
    
    fetchInstancesAuditHistoryDetails(link: string, id: number, callback: ApiResponse<any>) {
        HttpClient.Get('Fetch Instances Audit History Details', link+`/v2/instances/get/audit-history/${id}`, callback) 
    }

    deleteInstancesGroup(link:string, groupInstanceName: string, callback: ApiResponse<any>) {
        HttpClient.Delete('Delete Model', link + `/v2/instances/delete?groupInstanceName=${groupInstanceName}` , callback)
    }

    fetchModelDetails(link: string, id: number, callback: ApiResponse<any>) {
        HttpClient.Get('Fetch Model', link+`/v2/models/get/${id}`, callback) 
    }

    UpdateModelDetails(link:string, id: number, data: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Put('Update Model', link + `/v2/models/update/${id}` , data, callback, errorHandlerCallback);
    }

    deleteModel(link:string, id: number, callback: ApiResponse<any>) {
        HttpClient.Delete('Delete Model', link + `/v2/models/delete/${id}` , callback)
    }

    fetchTagsFromCsv(link: string, data: any,  callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Fetch Tags From Csv', link+`/v2/instances/extract-tags`,data, callback,errorCallback) 
    } 

    getHierarchyData(link: string,  callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Get('Get Hierarchy Data', link+`/v2/metadata`, callback, errorCallback) 
    }

    CreateHierarchy(link:string, formData: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Post('Create a Hierarchy', link + '/v2/upload', formData, callback, errorHandlerCallback);
    }

    UpdateHierarchy(link:string, formData: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Post('Update a Hierarchy', link + '/v2/metadata', formData, callback, errorHandlerCallback);
    }
    

    DeleteHierarchy(link:string, id: number, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Delete('Delete Hierarchy', link + `/v2/metadata/${id}` , callback, errorHandlerCallback)
    }

    ViewCsvFileMetadata(link:string, hierarchyName: string, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Get('View Csv File Metadata', link + `/v2/records?hierarchyName=${hierarchyName}` , callback, errorHandlerCallback)
    }

    SaveCsvFileMetadata(link:string, hierarchyName: string, data: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Put('Save Csv File Records', link + `/v2/update-records?hierarchyName=${hierarchyName}`, data, callback, errorHandlerCallback)
    }

    DeleteCsvFileMetadata(link:string, data: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Post('Delete Csv File Records', link + `/v2/delete-records`,data, callback, errorHandlerCallback)
    }

    GetAssetRevisions(link: string, assetId: string, callback: ApiResponse<any>, errorHandlerCallback?: any) {
        HttpClient.Get('Get Asset Revisions', link + `/v2/assets/audit/${assetId}`, callback, errorHandlerCallback);
    }

    RevertAssetRevision(link: string, assetId: string, revision: string, callback: ApiResponse<any>, errorHandlerCallback?: any) {
        HttpClient.Post('Revert Asset Revision', link + `/v2/assets/audit/${assetId}/revert/${revision}`, {}, callback, errorHandlerCallback);
    }

    RevertAssetTableToDate(link: string,id: number, date: string, callback: ApiResponse<any>, errorHandlerCallback?: any) {
        HttpClient.Post('Revert Asset Table To Date', link + `/v2/assets/audit/revert?date=${date}&id=${id}`, {}, callback, errorHandlerCallback);
    }

    RevertAssetTableToDatePreview(link: string,id: number, date: string, callback: ApiResponse<any>, errorHandlerCallback?: any) {
        HttpClient.Post('Revert Asset Table To Date', link + `/v2/assets/audit/revert/preview?date=${date}&id=${id}`, {}, callback, errorHandlerCallback);
    }

    RevertAssetRevisionPreview(link: string, assetId: string, revision: string, hierarchy_id: number, callback: ApiResponse<any>, errorHandlerCallback?: any) {
        HttpClient.Post('Revert Asset Revision', link + `/v2/assets/audit/${assetId}/revert/${revision}/preview/${hierarchy_id}`, {}, callback, errorHandlerCallback);
    }

    GetRoots(link: string,nodeName:string, callback: ApiResponse<any>, errorHandlerCallback?: any) {
        HttpClient.Get('Get Roots', link + `/v2/afstructure/roots?nodeName=${nodeName}`, callback, errorHandlerCallback);
    }
    
    GetRootElementById(link: string,id:string,nodeName:string, callback: ApiResponse<any>, errorHandlerCallback?: any) {
        HttpClient.Get('Get Roots', link + `/v2/afstructure/elements/${id}?nodeName=${nodeName}`, callback, errorHandlerCallback);
    }

    CreateHierarchyRequest(link:string,payload:any, callback: ApiResponse<any>,errorHandlerCallback?:any) {
        HttpClient.Post('Create Hierarchy Request', link + '/v2/request/create-submit',payload, callback,errorHandlerCallback)
    }

    GetAllWorkflows(link: string, callback: ApiResponse<any>, errorHandlerCallback?: any) {
        HttpClient.Get('Get Workflows', link + `/v1/workflow/all/by-user`, callback, errorHandlerCallback);
    }

    HandlePullPushHierarchy(link:string, id:number, action:string, callback: ApiResponse<any>,errorHandlerCallback?:any) {
        HttpClient.Get('Handle Pull Push Hierarchy', link + `/v2/publish/${id}/${action}`, callback,errorHandlerCallback)
    }
}

const DataSourceHandler = new DataSourceHandlerClass();

export { DataSourceHandler };