import { AppThunk, AppDispatch } from '../types';
import { AdminHandler, UserData } from '../../api/admin-handler';
import { ACTIVE_INACTIVE_USERS, UserManagementReducerState, TOGGLE_USERMANAGEMENT_MODAL, ToggleUserManagementModalType, AdminReducerState, SET_ACTIVE_ADMIN_TAB } from './types';

const handleActiveInactiveUsersSuccess = (dispatch: AppDispatch, response: UserData[]) => {
    dispatch({ type: ACTIVE_INACTIVE_USERS, payload: response });
};

export function active_inactive_users(this: any): AppThunk {
    return function (this: any, dispatch, getState) {
        const Env = getState().AccountReducer.envVariables;
        AdminHandler.GetActiveInactiveUsers(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, handleActiveInactiveUsersSuccess.bind(this, dispatch as unknown as AppDispatch));
    };
}

export const toggleUserManagementModal = (modalType: keyof UserManagementReducerState['showModal'], action: boolean): ToggleUserManagementModalType =>
    ({ type: TOGGLE_USERMANAGEMENT_MODAL, payload: { [modalType]: action } }) as any;


export const setActiveAdminTab = (adminTab: AdminReducerState['activeAdminTab']) => 
    ({ type: SET_ACTIVE_ADMIN_TAB, payload: adminTab });