import React from 'react';
import { Modal } from '../../../components/modals';
import styles from '../styles.module.scss';

interface ViewRequestDataModalProps {
    isOpen: boolean;
    data: string[];
    onClose: () => void;
}

export const ViewRequestDataModal: React.FC<ViewRequestDataModalProps> = ({ isOpen, data, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggleClose={onClose}
            title="Data Logs"
            subtitle=""
            className="errorLogsModal__container"
            showCloseMark
        >
            <div className="log_wrapper">
                <pre
                    className="output__text"
                > 
                    {data.map((item, index) => (
                        <div key={index}>
                            {Object.entries(item).map(([key, value]) => (
                                <div key={key}>
                                    <strong>{key}:</strong> {Array.isArray(value) ? value.join(", ") : value.toString()}
                                </div>
                            ))}
                        </div>
                    ))}
                </pre>
            </div>
            <div className={styles["modalAction__btns"]}>
                <button
                    className="btn-md btn-grey-transparent"
                    type="button"
                    onClick={onClose}
                >
                    Close
                </button>

            </div>
        </Modal>
    );
};