/* eslint-disable @typescript-eslint/no-use-before-define */
import { Modal } from '../../../components/modals';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { RootState } from '../../../store/types';
import { toggleAnalyticsModal } from '../../../store/analytics';
import { AnalyticsHandler } from '../../../api/analytics-handler/handler';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import { errorAlert } from '../../../components/toastify/notify-toast';
import { useGetActiveTabInfo, saveTextToFile } from '../../../utils';
import { WorkflowAnalyticsTabInfo } from '../../../store/canvas';
import moment from 'moment';


export const ErrorLogsModal: React.FC = () => {
    const { showModal: modalsInfo, plotStatementIdForErrorModal } = useSelector((store: RootState) => store.AnalyticsReducer );
    const activeTabinfo = useGetActiveTabInfo('analytics') as WorkflowAnalyticsTabInfo;
    const showModal = modalsInfo.errorLogs;
    const dispatch = useDispatch();
    const [errorLogs, setErrorLogs] = useState<string[]>([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const [link, setLink] = useState<string | null>(null);
    const handleSuccessErrorLogsResponse = ((response: string[]) => {
        setShowSpinner(false);
        setErrorLogs(response);
    });

    useEffect(() => {
		if (Env?.REACT_APP_PLATFORM_URL) {
		  const generatedLink = Env.REACT_APP_DATABRICKS
			? `${Env.REACT_APP_PLATFORM_URL}/databricks/api`
			: `${Env.REACT_APP_PLATFORM_URL}/platform/api`;
		  setLink(generatedLink);
		}
	  }, [Env]);

    const handleErrorLogsResponse = () => {
        setShowSpinner(false);
        errorAlert('Error in loading Logs');
    };

    const fetchErrorLogs = () => {
        if (!link || !Env) {
          console.warn("Link or Env is not available. Retrying fetchErrorLogs...");
          setTimeout(fetchErrorLogs, 5000); // Retry after 5 seconds
          return;
        }
    
        if (showModal && plotStatementIdForErrorModal && activeTabinfo?.sessionId) {
          AnalyticsHandler.GetErrorLogs(
            link,
            activeTabinfo.sessionId,
            plotStatementIdForErrorModal,
            handleSuccessErrorLogsResponse,
            handleErrorLogsResponse
          );
        }
      };

    useEffect(() => {
        fetchErrorLogs();
    }, [showModal, plotStatementIdForErrorModal, activeTabinfo, Env]);

    const handleClose = () => {
        dispatch(toggleAnalyticsModal('errorLogs', false));
    };

    const downloadJobLogs = () => {
        if(errorLogs) {
            let fileName = '';
                fileName = activeTabinfo.name + '_ErrorLogs_' + moment(new Date()).format('YYYY_MM_DD_HH_mm_ss_SSS');
                saveTextToFile(fileName+ '.log', errorLogs.join('\n'));
        };
        };

    return (
        <Modal
            isOpen={showModal}
            toggleClose={handleClose}
            title="Error Logs"
            subtitle=""
            className="errorLogsModal__containerLarge"
            showCloseMark
        >
            {showSpinner ?
                <div className="spinnerDiv">
                    <InPageSpinner />
                </div>
                :
                <>
                <div className='button-container'>
                  <button 
                            onClick={downloadJobLogs}
                            disabled={isEmpty(errorLogs)}
                            id="btn__downloadLogs"
                        >
                            <img
                                src="/icons/workflow/download_code.svg"
                                alt=""
                                width="16" 
                                height="16"
                            />
                        </button>
                        </div>
                    <div className="log_wrapper">
                        <pre
                            className="output__text"
                        >
                            {(errorLogs).map((_text, index) =>
                                <code key={index * 1.2}>
                                    {_text}
                                </code>
                            )}
                        </pre>
                    </div>
                </>
            }
            <div className="btns__box">
                <button
                    className="btn-md btn-grey-transparent"
                    type="button"
                    onClick={handleClose}
                >
                    Close
                </button>

            </div>
        </Modal>
    );
};