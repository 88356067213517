import React from "react";
import { Select } from "antd";
import styles from "../../styles.module.scss";
import { InPageSpinner } from "@components/spinners/in-page-spinner";

const { Option } = Select;

interface PiRequestHierarchyHeaderProps {
    servers: string[];
    databases: string[];
    serverName: string;
    dbName: string;
    isLoading: boolean;
    setServerName: (value: string) => void;
    setDbName: (value: string) => void;
    getRoots: () => void;
}

const PiRequestHierarchyHeader: React.FC<PiRequestHierarchyHeaderProps> = ({
    servers,
    databases,
    serverName,
    dbName,
    isLoading,
    setServerName,
    setDbName,
    getRoots,
}) => {
    return (
        <div className={styles.piRequestHierarchyForm}>
            <div className={styles.inputContainerHierarchy}>
                <label>Server</label>
                <Select
                    placeholder="Select a server"
                    value={serverName}
                    className="piRangeSelect"
                    onChange={setServerName}
                    style={{ width: "100%" }}
                >
                    {servers.map((option) => (
                        <Option key={option} value={option}>
                            {option}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className={styles.inputContainerHierarchy}>
                <label>Database</label>
                <Select
                    placeholder="Select a database"
                    value={dbName}
                    className="piRangeSelect"
                    onChange={setDbName}
                    style={{ width: "100%" }}
                >
                    {databases.map((option) => (
                        <Option key={option} value={option}>
                            {option}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="m-1">
                <button
                    className="btn btn-md btn-grey-transparent"
                    id="refreshPiRequests"
                >
                    <>
                        <img src="/icons/data-browser/refresh.svg" />
                        Refresh
                    </>
                </button>
            </div>
            <div className={styles.buttonContainerHierarchy}>
                <button className="btn btn-yellow btn-md" disabled={isLoading} onClick={getRoots}>
                    {isLoading ? <InPageSpinner size="XSmall" color="black" /> : "Get Hierarchy"}
                </button>
            </div>
        </div>
    );
};

export default PiRequestHierarchyHeader;
