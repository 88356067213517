import React, { useEffect, useState } from "react";
import styles from "../../../styles.module.scss";
import Form, { InputField, NewSelectField } from "@components/form";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { awsRegions } from "@pages/file-browser/ui/StorageConnectionForm";
import { modeOptions, sinkOptions } from "../constant";
import { _selectoptionType } from "@components/form/select-field";
import { DataSourceHandler } from "@api/data-source-handler";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";

interface HierarchyFormProps {
    activeStep: number;
    setActiveStep: (step: number) => void;
    initialValues: any;
    formSchema: any;
    handleOnSubmit: (values: any) => void;
}


const HierarchySinkForm: React.FC<HierarchyFormProps> = ({
    activeStep,
    setActiveStep,
    initialValues,
    formSchema,
    handleOnSubmit
}) => {
    const [connectionNameOptions, setConnectionNameOptions] = useState<_selectoptionType[]>(
        []
    );
    const { envVariables: Env } = useSelector((store: RootState) => store.AccountReducer);

    const getSnowflakeDbOptions = () => {
        DataSourceHandler.GetConnectionBySinkKind(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, "Snowflake", (res) => {
            setConnectionNameOptions(
                res.map((_res: any) => {
                    return {
                        label: _res.name,
                        value: _res.name,
                    };
                })
            );
        });

    }

    useEffect(() => {
        getSnowflakeDbOptions();
    }, [])

    return (
        <>
            <Form
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                validationSchema={formSchema}
                enableReinitialize
            >
                {({ _formikprops: { values, setFieldValue } }) => (
                    <>
                        <div className={styles.jsonHierarchyForm}>
                            <div style={{ padding: 16 }}>
                                <NewSelectField
                                    name="sinkName"
                                    label="Choose Sink"
                                    options={sinkOptions}
                                    required={true}
                                    className={styles["requestName__field"]}
                                />
                                <ShowWhenTrue show={values.sinkName === "s3"}>
                                    <NewSelectField
                                        name="region"
                                        options={awsRegions}
                                        label="Region"
                                        className={styles["requestName__field"]}
                                        required
                                    />
                                    <div className="split__fields--halfwide">
                                        <InputField
                                            name='accessKeyId'
                                            label="Access Key ID"
                                            className={styles["requestName__field"]}
                                            required
                                        />
                                        <InputField
                                            name='secretAccessKey'
                                            label="Secret Access Key"
                                            type="password"
                                            className={styles["requestName__field"]}
                                            required
                                        />
                                    </div>

                                </ShowWhenTrue>
                                <ShowWhenTrue show={values.sinkName === "snowflake"}>
                                    <NewSelectField
                                        name="database"
                                        options={connectionNameOptions}
                                        label="Select a database"
                                        className={styles["requestName__field"]}
                                        required
                                    />
                                    <div className="split__fields--halfwide">

                                        <NewSelectField
                                            name="mode"
                                            options={modeOptions}
                                            label="Mode"
                                            className={styles["requestName__field"]}
                                            required
                                        />
                                        <InputField
                                            name='schema'
                                            label="schema"
                                            className={styles["requestName__field"]}
                                            required
                                        />
                                    </div>
                                    <div className="split__fields--halfwide">
                                        <InputField
                                            name='warehouse'
                                            label="Warehouse"
                                            className={styles["requestName__field"]}
                                            required
                                        />
                                        <InputField
                                            name='role'
                                            label="User Role"
                                            className={styles["requestName__field"]}
                                            required
                                        />
                                    </div>
                                </ShowWhenTrue>
                            </div>


                        </div>
                        <div className={styles.hierarchyFormFooter}>
                            <button type="button" className="btn btn-grey btn-md" onClick={() => { setActiveStep(activeStep - 1); }}>
                                Back
                            </button>
                            <button type="submit" className="btn btn-yellow btn-md" >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </Form>
        </>
    );
};

export default HierarchySinkForm;
