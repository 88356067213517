import React, { memo, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Space, Select, AutoComplete, Table, Popconfirm, Radio } from 'antd-latest';
import { Checkbox, DatePicker } from 'antd';
import type { FormInstance } from 'antd-latest';
import { useHistory } from "react-router-dom";
import moment, { Moment } from 'moment';
import { isEmpty } from 'lodash';
import { BaseFieldType } from './formcreators/types';
import { ShowWhenTrue } from '@helpers/showwhentrue';
import { Text } from './ui/Text';
import { Flex } from './ui/Flex';
import { PlushIcon } from '@pages/cluster_redisign/icons';
import { _selectoptionType } from './form/NewSelectField';
import { InPageSpinner } from './spinners/in-page-spinner';
import { BsInfoCircle } from 'react-icons/bs';
import { TooltipTop } from './tooltips';
import { CloseTabIcon } from '@pages/workflow-page/assets/icons';
import { uuid } from 'uuidv4';
import { errorAlert, successAlert } from './toastify/notify-toast';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { HttpClient } from '@api/services';
// import { API } from '@constants/settings';
import { setRouteOptimizationist } from '@store/cluster';
import { MdRefresh } from 'react-icons/md';
import RefreshComponentInProperties from './formcreators/RefreshComponent';
import classNames from 'classnames';

const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface RouteOptimisationProps {
    formData: BaseFieldType[];
    handleRouteFormSubmit: (values: any) => void;
    getComponentDescription: any;
}

interface EditableRowProps {
    index: number;
}

interface Item {
    area: any;
    key: string;
    operator_name: string;
    start_location: string;
    overtime_allowed: boolean;
}

interface DataType {
    key: React.Key;
    area: string;
    operator_name: string;
    start_location: string;
    overtime_allowed: boolean;
    routeData?: any;
    operator_type?: string;
    lat?: string;
    lon?: string;
}

type FormInitialValues = {
    area: string[],
    operators: Record<string, DataType[]> | undefined | any,
    default_task_time: number,
    new_task_overhead: number,
    run_name: string,
    optimization_date: Moment|null,
    morning_shift_hrs: number,
    night_shift_hrs: number,
    prioritize_critical_high: boolean;
    optimization_type:string
}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
    areaOperatorOption: _selectoptionType[];
    startLocationOptions: _selectoptionType[];
    routeData: any;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    areaOperatorOption,
    startLocationOptions,
    routeData,
    ...restProps
}) => {
    const form = useContext(EditableContext)!;
    const save = async () => {
        try {
            if (!isEmpty(record?.key)) {
                const values = await form.validateFields();
                handleSave({ ...record, ...values });
            }
        } catch (errInfo) {
            console.error('Save failed:', errInfo);
        }
    };

    let childNode = children;
    let _options: any = [];
    let initialValue = {};
    if (!isEmpty(routeData) && !isEmpty(record)) {
        initialValue = record?.operator_name;
        const operatorStartLoc = routeData?.operator?.[record?.area];
        const areaData = Object.keys(routeData?.operator?.[record?.area]);

        if (Array.isArray(areaData)) {
            _options = areaData.map((item: string) => ({ label: item, value: item }));
        }
        if (title === 'Start Location') {
            const startLocData = (routeData?.start_location?.[record?.area]);
            if (Array.isArray(areaData)) {
                _options = startLocData.map((item: string) => ({ label: item?.[0], value: item?.[0] }));
            }
            const selectedOperator = form.getFieldValue("operator_name");
            initialValue = record?.start_location || operatorStartLoc?.[selectedOperator];
        }
        if (title === 'Overtime') {
            _options = [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
            ];
            initialValue = record?.overtime_allowed;
        }
    }

    if (editable) {
        childNode = (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                initialValue={initialValue}
            >
                {title === 'Overtime' || title === "Start Location" ? <Select
                    onChange={save}
                    onBlur={save}
                    showSearch
                    optionFilterProp="label"
                    options={_options}
                /> : <AutoComplete
                    options={_options}
                    placeholder={title}
                    autoClearSearchValue
                    filterOption={(inputValue, option) => {
                        const value = option?.value;
                        if (typeof value !== 'string') return false;
                        return value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                    onChange={save}
                    onBlur={save}
                />}
            </Form.Item>
        )
    }

    return <td {...restProps}>{childNode}</td>;
};


export const RouteOptimisation: React.FC<RouteOptimisationProps> = ({ formData, handleRouteFormSubmit, getComponentDescription }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [areaValue, setAreaValue] = React.useState<string[]>([]);
    const [initialValues, setInitialValues] = useState<FormInitialValues | undefined>(undefined);
    const [dataSource, setDataSource] = useState<Record<string, DataType[]> | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshLoading, setIsRefreshLoading] = useState(false);
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const routeData = useSelector((store: RootState) => store.ClusterReducer.routeOptimizationList);
    const dispatch = useDispatch();
    const enabledWorkspace = workSpaceData?.filter((item) => item.isEnabled)?.[0];
    const cluster = useSelector(
        (store: RootState) => store.ClusterReducer.defaultClusterInfo
    );
    const [clusterError, setClusterError] = useState<string>("");
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    const onFinish = (values: any) => {
        try {
            const allFieldsNonEmpty = Object.values(dataSource || {}).flatMap((areaObjects: any) =>
                areaObjects?.flatMap((obj: any) =>
                    Object.values({ ...obj, operator_type: "test", lat: "0", lon: "0" })
                )
            ).every(value => {
                if (typeof value === 'boolean') {
                    return true;
                }
                if (typeof value === 'string') {
                    return value.trim() !== '';
                }
                if (typeof value === 'number') {
                    return !isNaN(value);
                }
                return value !== null && value !== undefined;
            });
            if (!allFieldsNonEmpty) {
                setError("Please fill all the operators details required fields");
                return;
            } else {
                setError("")
            }
            const _datasource = Object.values(dataSource || []).flatMap((_item: any) =>
                _item?.map((item: any) => {
                    return {
                        operator_name: item.operator_name,
                        start_location: item?.start_location,
                        overtime_allowed: item.overtime_allowed,
                        key: item?.key || uuid(),
                        area: item?.area,
                        operator_type: routeData?.operator?.[item?.area]?.[item.operator_name]?.[1],
                        lat: routeData?.start_location?.[item?.area].find((loc: any) => loc?.[0] === item?.start_location)?.[1],
                        lon: routeData?.start_location?.[item?.area].find((loc: any) => loc?.[0] === item?.start_location)?.[2]
                    };
                })
            );
            const convertToPythonDict = (data: any) => {
                return data.map((item: any) => {
                    return `{"operator_name": "${item.operator_name}", "start_location": "${item.start_location}", "overtime_allowed": ${item.overtime_allowed}, "area": "${item.area}","key":"${item?.key}", "operator_type":"${item.operator_type}", "lat":"${item.lat}", "lon":"${item.lon}"}`;
                }).join(',');
            }

            const pythonDictString = `[${convertToPythonDict(_datasource)}]`;
            handleRouteFormSubmit({
                ...values,
                operators: pythonDictString,
                night_shift_hrs: Number(values?.night_shift_hrs),
                morning_shift_hrs: Number(values?.morning_shift_hrs),
                optimization_date: values.optimization_date ? moment(values.optimization_date).format("YYYY-MM-DD"):null,
                optimization_type:values?.optimization_type,
                area: `[${values?.area?.map((item: string) => `"${item}"`).join(', ')}]`
            });
        } catch (error) {
            console.error('Parsing error:', error);
        }
    };


    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const getOperators = (selectedArea: any) => {
        if (!routeData?.operator?.[selectedArea] || !routeData?.start_location?.[selectedArea]) {
            return [];
        }

        const operators: {
            area: any; key: string; operator_name: string; operator_type: any; start_location: any; lat: any; lon: any; overtime_allowed: boolean;
        }[] = [];
        const startLocations = routeData?.start_location[selectedArea];

        Object.keys(routeData?.operator?.[selectedArea])?.forEach(operatorName => {
            const [locationName, operatorType] = routeData.operator[selectedArea][operatorName];

            const startLocation = startLocations.find((loc: any) => loc[0] === locationName);
            if (startLocation) {
                const operator = {
                    area: selectedArea,
                    key: uuid(),
                    operator_name: operatorName,
                    operator_type: operatorType,
                    start_location: locationName,
                    lat: startLocation[1],
                    lon: startLocation[2],
                    overtime_allowed: false
                };

                operators.push(operator);
            }
        });

        return operators;
    }

    const handleAreaChange = (value: string[]) => {
        setAreaValue(value);
        setDataSource((prevDataSource) => {
            // if (!prevDataSource) return prevDataSource;
            const updatedDataSource = { ...prevDataSource };
            value.forEach((area) => {
                if (!updatedDataSource[area]) {
                    updatedDataSource[area] = getOperators(area);
                }
            });
            Object.keys(updatedDataSource).forEach((area) => {
                if (!value.includes(area)) {
                    delete updatedDataSource[area];
                }
            });
            return updatedDataSource;
        });
    };

    const handleAdd = (area: string) => {
        setDataSource((prevDataSource) => {
            const newData: DataType = {
                key: uuid(),
                area: area,
                operator_name: '',
                start_location: '',
                overtime_allowed: false,
                operator_type: '',
                lat: '',
                lon: ''
            };
            const updatedDataSource = prevDataSource ? { ...prevDataSource } : {};
            if (updatedDataSource[area]) {
                updatedDataSource[area] = [...updatedDataSource[area], newData];
            } else {
                updatedDataSource[area] = [newData];
            }

            return updatedDataSource;
        });

    };

    const handleSave = (row: DataType) => {
        setDataSource((prevDataSource) => {
            if (!prevDataSource) return prevDataSource;

            const area = row?.area;
            const updatedAreaData = prevDataSource[area].map((item) =>
                item.key === row.key ? { ...item, ...row } : item
            );

            return {
                ...prevDataSource,
                [area]: updatedAreaData,
            };
        });
    };

    const handleDelete = (key: React.Key, area: string) => {
        setDataSource((prevDataSource) => {
            if (!prevDataSource) return prevDataSource;

            const updatedAreaData = prevDataSource[area].filter((item) => item.key !== key);
            if (updatedAreaData.length === 0) {
                const { [area]: _, ...rest } = prevDataSource;
                return rest;
            }

            return {
                ...prevDataSource,
                [area]: updatedAreaData,
            };
        });
    };

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Operator Name',
            dataIndex: 'operator_name',
            width: '30%',
            editable: true,
        },
        {
            title: 'Start Location',
            dataIndex: 'start_location',
            editable: true,
        },
        {
            title: 'Overtime',
            dataIndex: 'overtime_allowed',
            editable: true,
        },
        {
            title: ' ',
            dataIndex: 'operation',
            render: (_, record) => {
                const areaData = dataSource?.[record?.area];
                return areaData && areaData.length >= 1 && !isEmpty(record?.key) ? (
                    <Flex gap='0px' style={{ cursor: 'pointer' }}>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key, record.area)}>
                            <CloseTabIcon />
                        </Popconfirm>
                    </Flex>
                ) : null;
            }
        },
    ];


    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                routeData: routeData,
                handleSave,
            }),
        };
    });

    const getDataFrame = async (jobId: string) => {
        try {
            let timer: NodeJS.Timeout | null = setInterval(async () => {
                try {
                    const result = await HttpClient.GetPromise(`${Env.REACT_APP_PLATFORM_URL}/databricks/api/batches/schema_capture/${jobId}/dataframe`);
                    if (result?.data[0]?.state === "success") {
                        dispatch(setRouteOptimizationist(result?.data[0]?.dataframes?.[0]));
                        successAlert("Fetched data successfully");
                        clearInterval(timer!);
                        timer = null;
                        setIsLoading(false);
                        setIsRefreshLoading(false);
                    } else if (result?.data[0]?.state === "failed") {
                        clearInterval(timer!);
                        timer = null;
                        setIsLoading(false);
                        setIsRefreshLoading(false)
                        errorAlert("Failed to fetch data. Please check cluster health and restart as needed.");
                    }
                } catch (error) {
                    clearInterval(timer!);
                    timer = null;
                    setIsLoading(false);
                    setIsRefreshLoading(false);
                    errorAlert("An error occurred while fetching data");
                    console.error(error);
                }
            }, 1000);

            return () => {
                if (timer) {
                    clearInterval(timer!);
                    timer = null;
                    setIsLoading(false);
                    setIsRefreshLoading(false)
                }
            };
        } catch (err) {
            console.error(err);
        }
    };

    const fetchSchemaCapture = async () => {
        try {
            if (!(!isEmpty(enabledWorkspace) && cluster && cluster?.state === "RUNNING" && cluster.defaultCluster)) {
                setClusterError("Cluster should be in a running state and should be the default cluster");
                setIsLoading(false);
                return;
            }
            setIsRefreshLoading(true)
            const payload = {
                sparkVersion: "2.x",
                clusterId: cluster?.clusterId,
                env: "spark",
                workspaceId: enabledWorkspace?.workspaceId,
                workspaceType: enabledWorkspace?.workspaceType,
            };
            const response = await HttpClient.PostPromise(`${Env.REACT_APP_PLATFORM_URL}/databricks/api/batches/schema_capture/route_optimisation`, payload);
            if (!isEmpty(response?.data?.[0]?.jobId)) {
                getDataFrame(response?.data?.[0]?.jobId);
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false)
            setIsRefreshLoading(false);
            errorAlert("Failed to fetch data. Please check cluster health and restart as needed.");
        }
    };

    useEffect(() => {

        const _initialValues: FormInitialValues = {
            area: [],
            operators: undefined,
            default_task_time: 15,
            new_task_overhead: 0,
            run_name: `Run_${new Date().toDateString()}`,
            optimization_date: null,
            morning_shift_hrs: 8,
            night_shift_hrs: 0,
            prioritize_critical_high: true,
            optimization_type:"dynamic"
        }
        formData.forEach(field => {
            /* eslint-disable no-prototype-builtins */
            if (_initialValues.hasOwnProperty(field.key)) {
                if (field.key === "area") {
                    _initialValues[field.key] = (field?.value && JSON.parse(field?.value || "" as any)) || _initialValues.area;
                } else if (field.key === "default_task_time") {
                    _initialValues[field.key] = field.value || _initialValues.default_task_time;
                } else if (field.key === "new_task_overhead") {
                    _initialValues[field.key] = field.value || _initialValues.new_task_overhead;
                } else if (field.key === "run_name") {
                    _initialValues[field.key] = field.value || _initialValues.run_name;
                } else if (field.key === "optimization_date") {
                    _initialValues[field.key] = field.value && field.value !=="yesterday"?moment(field.value):_initialValues.optimization_date;
                } else if (field.key === "morning_shift_hrs") {
                    _initialValues[field.key] = field.value !== undefined ? field.value : _initialValues.morning_shift_hrs;

                } else if (field.key === "night_shift_hrs") {
                    _initialValues[field.key] = field.value || _initialValues.night_shift_hrs;
                } else if (field.key === "prioritize_critical_high") {
                    _initialValues[field.key] = field.value || _initialValues.prioritize_critical_high;
                }else if (field.key === "optimization_type") {
                    _initialValues[field.key] = field.value || _initialValues.optimization_type;
                } else if (field.key === "operators") {
                    _initialValues[field.key] = (field?.value && JSON.parse(field?.value || "" as any)) || _initialValues.operators || undefined;
                }
            }
        });
        const transformedOperatorData = _initialValues?.operators?.reduce((acc: any, obj: any) => {
            const area = obj.area;
            if (!acc[area]) {
                acc[area] = [];
            }
            acc[area].push(obj);
            return acc;
        }, {}) || undefined;
        setInitialValues(_initialValues);
        setAreaValue([..._initialValues.area]);
        setDataSource((transformedOperatorData));

    }, [formData])

    useEffect(() => {
        if (isEmpty(routeData?.area) && isEmpty(routeData?.operator) && isEmpty(routeData?.start_location)) {
            if (!isEmpty(enabledWorkspace) && cluster && cluster?.state === "RUNNING" && cluster.defaultCluster) {
                setIsLoading(true);
                fetchSchemaCapture();
            } else {
                setIsLoading(false);
                errorAlert("Cluster should be in a running state and should be the default cluster");
                setClusterError("Cluster should be in a running state and should be the default cluster");
            }
        }
    }, [enabledWorkspace?.workspaceId, cluster?.clusterId]);

    if (isLoading) {
        return <Flex className='route-form' display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
            <InPageSpinner size="Large" color="white" />
            <Text>Fetching data ...</Text>
        </Flex>
    }
    const areaOptions = routeData?.area?.map((item: _selectoptionType) => {
        return { label: item, value: item }
    })

    const disabledFutureDates = (current: moment.Moment | null) => {
        return current ? current.isAfter(moment(), "day") : false;
      };

    return (
        <div className='route-form'>
            <div className='componentMetaData'>
                {getComponentDescription}
                <RefreshComponentInProperties />
                <ShowWhenTrue show={!isEmpty(clusterError)}>
                    <Flex gap='10px' className='m-1'>
                        <Text color={"#FF7766"} fontSize={"1rem"}>{clusterError}</Text>
                        <button type="button" className={classNames(
                            "btn btn-sm btn-grey btn-refresh-component",
                        )} onClick={() => history.push('/cluster')}>Go to cluster Page</button>
                    </Flex>
                </ShowWhenTrue>
                <Flex display={"flex"} alignItems={"center"} className='m-1' gap="6px">
                    <button
                        className={classNames(
                            "btn btn-sm btn-grey btn-refresh-component",
                        )}
                        type="button"
                        disabled={isRefreshLoading}
                        onClick={fetchSchemaCapture}
                    >
                        <Flex alignItems={"center"} gap="6px">  <MdRefresh />Refresh Data</Flex>
                    </button>
                    <TooltipTop title="To refresh the operators data from Databricks">
                        <BsInfoCircle style={{ color: '#fff' }} />
                    </TooltipTop>
                    {isRefreshLoading && <InPageSpinner size="XSmall" color="white" />}
                </Flex>
            </div>

            <ShowWhenTrue show={!isEmpty(initialValues) && isEmpty(clusterError)}>
                <Form
                    name="dynamic_form_nest_item"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={initialValues}
                    colon={false}
                >
                    <Form.Item
                        name={'optimization_type'}
                        label={<Flex justifyContent={"center"} alignItems={"center"} gap='10px'><Text fontSize="16px" color="white">Select Optimization Type</Text> <TooltipTop title="Select Optimization Type">
                            <BsInfoCircle style={{ color: '#fff' }} />
                        </TooltipTop></Flex>}
                        rules={[{ required: true, message: 'Please Select Optimization Type' }]}
                        initialValue={initialValues?.optimization_type}
                    >
                        <Radio.Group className='radio-group-route-optimisation'>
                            <Radio value={"dynamic"}>Dynamic</Radio>
                            <Radio value={"static"}>Static</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name={'area'}
                        label={<Flex justifyContent={"center"} alignItems={"center"} gap='10px'><Text fontSize="16px" color="white">Select Area(s)</Text> <TooltipTop title="Select Area(s)">
                            <BsInfoCircle style={{ color: '#fff' }} />
                        </TooltipTop></Flex>}
                        rules={[{ required: true, message: 'Please Select Area(s)' }]}
                        initialValue={initialValues?.area}
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Select Area(s)"
                            onChange={handleAreaChange}
                            options={areaOptions}
                            className='area-select'
                            optionRender={(option) => (
                                <Space>
                                    <span aria-label={option.data.label}>
                                        {option.data.label}
                                    </span>
                                </Space>
                            )}
                        />
                    </Form.Item>
                    {<ShowWhenTrue show={!isEmpty(areaValue)}>
                        {areaValue.map((item) => {
                            return (
                                <div key={item}>
                                    <Form.Item name="operators" initialValue={dataSource?.[`${item}`]}>
                                        <div>
                                            <Form form={form} component={false} initialValues={dataSource?.[`${item}`] || []}>

                                                <Table
                                                    title={() => <Button className='add-operator-button' onClick={() => handleAdd(item)} icon={<PlushIcon />}>
                                                        Add Operator Details for {item} location
                                                    </Button>}
                                                    size='small'
                                                    components={components}
                                                    rowClassName={() => 'editable-row'}
                                                    bordered={false}
                                                    className='operator-table'
                                                    dataSource={dataSource?.[`${item}`]}
                                                    columns={columns as ColumnTypes}
                                                    pagination={false}
                                                    locale={{ emptyText: "No Operators added yet" }}
                                                />
                                            </Form>

                                        </div>
                                    </Form.Item>
                                </div>
                            )
                        })}
                        <Flex justifyContent={"space-between"}>
                            <Form.Item
                                name={'optimization_date'}
                                rules={[{ required: false, message: 'Optimization Date is required' }]}
                                label={<Flex justifyContent={"center"} alignItems={"center"} gap='10px'><Text fontSize="16px" color="white">Optimization Date</Text> <TooltipTop title="If no date is selected, yesterday's date will be used by default">
                                    <BsInfoCircle style={{ color: '#fff' }} />
                                </TooltipTop></Flex>}
                            >
                                <DatePicker  allowClear={true}  disabledDate={disabledFutureDates}  placeholder='yesterday' style={{ width: 240 }} format={"YYYY-MM-DD"} />
                            </Form.Item>
                            <Form.Item
                                label={<Flex justifyContent={"center"} alignItems={"center"} gap='10px'><Text fontSize="16px" color="white">Run Name</Text> <TooltipTop title="Run Name">
                                    <BsInfoCircle style={{ color: '#fff' }} />
                                </TooltipTop></Flex>}
                                name={'run_name'}
                                rules={[{ required: true, message: 'Run Name is required' }, {
                                    validator: (_, value) => {
                                        if (value === null || value === undefined || value === '' || (typeof value === 'string' && value.trim() === '')) {
                                            return Promise.reject(new Error('Field cannot be empty'));
                                        }
                                        return Promise.resolve();
                                    },
                                }]}
                            >
                                <Input style={{ width: 240 }} />
                            </Form.Item>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Form.Item
                                label={<Flex justifyContent={"center"} alignItems={"center"} gap='10px'><Text fontSize="16px" color="white">Default Task Time</Text> <TooltipTop title="Task time to be used by optimizer for missing values.">
                                    <BsInfoCircle style={{ color: '#fff' }} />
                                </TooltipTop></Flex>}
                                name={'default_task_time'}
                            >
                                <Input type='number' min={0} style={{ width: 240 }} placeholder="Default Task Time" addonAfter={<Text color="#fff">min</Text>} />

                            </Form.Item>
                            <Form.Item
                                label={<Flex justifyContent={"center"} alignItems={"center"} gap='10px'><Text fontSize="16px" color="white">Overhead Time</Text> <TooltipTop title="Time to add to the optimizer when modeling the additional time spent by the operator on a route is not feasible (e.g., when the operator has to walk long distances between route stops).">
                                    <BsInfoCircle style={{ color: '#fff' }} />
                                </TooltipTop></Flex>}
                                name={'new_task_overhead'}
                            >
                                <Input style={{ width: 240 }} min={0} type='number' placeholder="Overhead Time" addonAfter={<Text color="#fff">min</Text>} />

                            </Form.Item>
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                            <Form.Item
                                label={<Flex justifyContent={"center"} alignItems={"center"} gap='10px'><Text fontSize="16px" color="white">Day Shift Hours</Text> <TooltipTop title="Number of hours operator works in the day time.">
                                    <BsInfoCircle style={{ color: '#fff' }} />
                                </TooltipTop></Flex>}
                                name={'morning_shift_hrs'}
                            >
                                <Input style={{ width: 240 }} type='number' min={0} placeholder="Morning Shift" addonAfter={<Text color="#fff">hours</Text>} />

                            </Form.Item>
                            <Form.Item
                                label={<Flex justifyContent={"center"} alignItems={"center"} gap='10px'><Text fontSize="16px" color="white">Night Shift Hours</Text> <TooltipTop title="Number of hours operator works in the night time.">
                                    <BsInfoCircle style={{ color: '#fff' }} />
                                </TooltipTop></Flex>}
                                name={'night_shift_hrs'}
                            >

                                <Input style={{ width: 240 }} type='number' min={0} placeholder="Night Shift" addonAfter={<Text color="#fff">hours</Text>} />

                            </Form.Item>
                        </Flex>

                        <Form.Item
                            label={''}
                            name={'prioritize_critical_high'}
                            initialValue={Boolean(initialValues?.prioritize_critical_high) || false}
                            valuePropName="checked"
                            getValueFromEvent={e => e.target.checked}
                        >
                            <Flex alignItems={"center"} gap='10px'>
                                <Checkbox defaultChecked={Boolean(initialValues?.prioritize_critical_high) || false}
                                    className="prioritize-checkbox">
                                    Prioritize Critical and High
                                </Checkbox>
                                <TooltipTop title="When enabled, Critical and High priority tasks are attended at the beginning of the shift.">
                                    <BsInfoCircle style={{ color: '#fff' }} />
                                </TooltipTop>
                            </Flex>
                        </Form.Item>
                    </ShowWhenTrue>}
                    <Form.Item className='fixed-bottom' >
                        <Flex display={"flex"} gap="12px" bg={"#2a2d43"}>
                            <Button type="primary" htmlType="submit" className='route-btn-submit'>
                                Save
                            </Button>
                            <Text color={"#FF7766"}>{error}</Text>
                        </Flex>
                    </Form.Item>
                </Form>
            </ShowWhenTrue>
        </div>
    );
};

export default memo(RouteOptimisation);

