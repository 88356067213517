import React, { useEffect, useState, useRef } from 'react'
import Form, { InputField, SelectField } from '@components/form';
import { _selectoptionType } from '@components/form/select-field';
import { StateData } from '../types';
import { DataSourceHandler } from '@api/data-source-handler';
import { set, sortBy } from 'lodash';
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import Table from './table';
import Panel from './Panel';
import { useDataExplorerContext } from '../useDataExplorerContext';
import { InPageSpinner } from '@components/spinners/in-page-spinner';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

const MetaData:React.FC<{
    mainData: StateData,
    dbId: number,
}> = ({
    mainData,
    dbId
}) => {
    const { db_table, database } = useDataExplorerContext();
    const [isLoading, setLoading] = useState(false);
	const [virtualViewList, setVirtualViewList] = useState([]);
    const [alias, setAlias] = useState({
		alias_metadata: "",
		desc_metadata: "",
	});
    const [columnRowTableData, setColumnRowTableData] = useState<{
        columnName: string,
        alias: string,
        table: string,
        foreignKey: string,
        desc: string
    }[]>([]);
    const [tableData, setTableData] = useState<any>([])
    const tableSchData = useRef<any>({});
    const tablesMetaData = useRef<any>([])
    const response = useRef<any>([])
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    useEffect(() => {
        monacoEditor.languages.register({id: 'deepiqmeta'})
        const temp = monacoEditor.languages.registerCompletionItemProvider('deepiqmeta', {
            triggerCharacters: [''],
            // @ts-ignore
            provideCompletionItems: function(model, position) {
                const wordAtPosition = model.getWordUntilPosition(position);
                const suggestions = tableSchData.current.filter((da: any) => {
                    return da.colName.toLowerCase().includes(wordAtPosition?.word.toLowerCase() || '')
                }).map((da: any) => {
                    return {
                        label: da.colName,
                        insertText: da.colName + ':' + da.tableName,
                        kind: monacoEditor.languages.CompletionItemKind.Operator,
                        detail: da.tableName
                    }
                })
                return {
                    suggestions
                }
            }
        });

        return () => {
            temp.dispose()
        }
    }, [])

    useEffect(() => {
        if (tableData?.relationships?.length) {
            const temp = tableData.relationships.filter((rel: any) => rel.name === db_table)[0];
            setAlias({
                "alias_metadata": temp?.alias || '',
                "desc_metadata": temp?.description || ''
            });
        } else {
            setAlias({
                "alias_metadata": '',
                "desc_metadata": ''
            });
        }
    }, [db_table, tableData]);

    const update_table = async () => {
        const resp =  await DataSourceHandler.GetSchemaTable(Env?.REACT_DEEP_SQL_URL, database.id)
        setTableData(resp.data)
    }

    const update_table_data = () => {
        const columnList = response.current[3]?.data.tablesMetaData.find((table: any) => table.tableName === db_table);
        const dataResult: {
            columnName: string,
            alias: string,
            desc: string,
            foreignKey: string,
            table: string
        }[] = []
        if(!columnList){
            const selectedVirtualColumn:any = virtualViewList.find((table: any) => table.name === db_table);
            selectedVirtualColumn?.columns.forEach((column: any) => {
                const temp = {
                    columnName: column,
                    alias: '',
                    desc: '',
                    foreignKey: '',
                    table: ''
                };
                dataResult.push(temp)
            });
            setColumnRowTableData(dataResult);
        }
        if(columnList?.columnsMetaData) {
            
            columnList.columnsMetaData.forEach((column: any) => {
                const temp = {
                    columnName: column.name,
                    alias: '',
                    desc: '',
                    foreignKey: '',
                    table: ''
                };

                if(response.current[1].data.status_code !== 404) {
                    const result = response.current[1].data.relationships.find((relationship:any) => {
                        return relationship.table_name === db_table && relationship.column_name === column.name
                    });
                    if(result){
                        temp.alias = result.alias;
                        temp.desc = result.description;
                    }
                }            
               
                if(response.current[2].data.status_code !== 404) {
                    const result1 = response.current[2].data.find((d:any) => d.db_id + '' === database.id + '');
                    if(result1){
                        const result2 = result1.data.find((d:any) => d.src_table === db_table && d.src_column === column.name)
                        if(result2) {
                            temp.foreignKey = result2.tgt_column;
                            temp.table = result2.tgt_table;
                        }
                    }
                }
                
                dataResult.push(temp)
            })
            setColumnRowTableData(dataResult)
        }
    };

    const get_schema_column_foreign = async () => {
        if(db_table === '') return;
        setLoading(true);
        const resp = await Promise.all([
            DataSourceHandler.GetSchemaTable(Env?.REACT_DEEP_SQL_URL, database.id),
            DataSourceHandler.GetSchemaColumn(Env?.REACT_DEEP_SQL_URL, database.id),
            DataSourceHandler.GetSchemaForiegn(Env?.REACT_DEEP_SQL_URL, database.id),
            DataSourceHandler.GetDatabaseTableWithSchema(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, database.id)
        ]);
        response.current = resp;

        if (resp[1].data && resp[0].data.status_code + "" !== "404") {
            setTableData(resp[0].data)
        }

        const temp: {
            colName: string,
            tableName: string
        }[] = [];
        resp[3].data.tablesMetaData?.forEach((tTable: any) => {
            return tTable.columnsMetaData.forEach((_col: any) => {
                temp.push({
                    colName: _col.name,
                    tableName: tTable.tableName
                })
            })
        })
        tablesMetaData.current = resp[3].data.tablesMetaData;
        tableSchData.current = sortBy(temp, ['colName']);
        update_table_data()
        setLoading(false);
    };

    useEffect(() => {
        if(columnRowTableData.length === 0) {
            get_schema_column_foreign();
        } else {
            update_table_data();
        }
    }, [db_table])

    const fetchVirtualList = async () => {
		try {
			const res = await DataSourceHandler.GetVirtualViewList(Env?.REACT_DEEP_SQL_URL, database.id);
			setVirtualViewList(res.data);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log("error", e);
		}
	};

    useEffect(()=>{
        update_table_data();
    },[virtualViewList])

    useEffect(() => {
        setTableData([]);
        setColumnRowTableData([]);
        setAlias({
            alias_metadata: "",
            desc_metadata: "",
        })
        tableSchData.current = {};
        tablesMetaData.current = [];
        response.current = []
        get_schema_column_foreign();
        fetchVirtualList();
    }, [database])

    const isVirtualTable =
    tablesMetaData.current?.findIndex((item: any) => item.tableName === db_table) === -1;
   

    return (
       <>
        {
            db_table === '' ? <h2 className='center white'>Please select a graph to proceed</h2> : null
        }
        {
            db_table !== '' && !isLoading ? (
                <div className='metadata p-1' style={{minHeight: 450, backgroundColor: '#3E4161'}}>
                    <Panel mainData={mainData} alias={alias} update_table={update_table} tableData={tablesMetaData.current } virtualViewList={virtualViewList} isVirtualTable={isVirtualTable} refetchVirtualList={fetchVirtualList}/>
                    <Form
                        initialValues={{}}
                        enableReinitialize={true}
                        onSubmit={(arg) => { 
                            return; 
                        }} >
                        {() => ( 
                            <Table mainData={mainData} columnRowTableData={columnRowTableData} setLoading={setLoading} update={get_schema_column_foreign} />
                        )}
                    </Form>
                </div>
            ) : null
        }
        {
            isLoading ? ( 
                <div className='center'>
                    <InPageSpinner size='XSmall' className='h-24' /> 
                </div>
            ) : null
        }
       </>
    )
}

export default MetaData