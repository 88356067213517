import React, { useEffect, useRef, useState } from "react";
import styles from "../styles.module.scss";
import RowRender from "../components/rowRender";
import { RobotIcon } from "../components/Icons";
import { useGenAIContext } from "../useGenAIContext";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";
import { Input } from "antd-latest";


const InputField: React.FC<{
    defaultValue: string[];
    placeholder: string[];
    message?: string;
    showFeedback: any;
    query_uuid: string;
    isFeedbackSubmitted: boolean;
}> = ({ defaultValue, message, showFeedback, query_uuid, isFeedbackSubmitted, placeholder }) => {
    const [inputValues, setInputValues] = useState<string[]>([]);
    const { runQuery, addContent } = useGenAIContext();
    const { activeUserInfo: { username } } = useSelector((store: RootState) => store.AccountReducer);
    useEffect(() => {
        setInputValues(defaultValue || []);
    }, [defaultValue]);
    const handleInputChange = (index: number, value: string) => {
        const newValues = [...inputValues];
        newValues[index] = value;
        setInputValues(newValues);
    };
    const onUpdate = () => {
        const objInputValues:any= [{"Well.Country":inputValues[0],"Well.WellName":inputValues[1]}]
        addContent(JSON.stringify(objInputValues), "human", query_uuid, true);
        runQuery('', undefined, objInputValues, "", "", username, "manual_get_filters");
    };
    return (
        <>
            <RowRender
                text={message || "Please select your list"}
                icon={<RobotIcon />}
                direction="ai"
                date={new Date()}
                isFeedbackSubmitted={isFeedbackSubmitted}
                noFeedback={false}
                showFeedback={showFeedback}
                query_uuid={query_uuid}
            />

            {inputValues.map((value, index) => (
                <div
                    key={index}
                    style={{
                        marginLeft: '50px',
                        marginBottom: '20px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <span>{placeholder[index]}</span>
                    <Input
                        style={{ width: '300px' }}
                        value={value}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        placeholder={placeholder[index]}
                    />
                </div>
            ))}

            <div>
                <button
                    className={classNames(
                        "btn-grey btn-sm",
                        styles["btn"],
                        styles["btn_update"]
                    )}
                    onClick={() => onUpdate()}
                >
                    Update
                </button>
            </div>
        </>
    );
};
export default InputField;