import React from "react";
import * as yup from "yup";
import { FaFolder } from "react-icons/fa";
import { Table } from "antd";
import moment from 'moment';
import styles from "../../styles.module.scss";
import { Flex } from "@components/ui/Flex";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { Text } from "@components/ui/Text";
import { RootType,formTitle } from "./constant";
import { isEmpty } from "lodash";
import HierarchyReqForm from "./Forms/ReqForm";
import HierarchySinkForm from "./Forms/SinkForm";
import HierarchyRowFilterForm from "./Forms/RowFilterForm";
import HierarchyInterpolationForm from "./Forms/InterpolationForm";
import HierarchyScheduleForm from "./Forms/ScheduleForm";

interface HierarchyViewProps {
  selectedElements: RootType;
  tableDataSources: any;
  columns: any[];
  isLoadingElements: boolean;
  showForm: boolean;
  activeStep: number;
  selectedRowKeys: any[];
  setActiveStep: (step: number) => void;
  setShowForm: (show: boolean) => void;
  setSelectedRowKeys: (keys: any[]) => void;
  handleTableChange: (pagination: any, filters: any, sorter: any, extra: { action: string; currentDataSource: any; }) => any;
  handleGoToReqPage: () => void;
}

const formSchemaReqForm = yup.object().shape({
  requestName: yup
    .string()
    .required("This is a required field")
    .matches(/^[A-Za-z0-9_-]+$/, "Special Characters are not allowed except _ and -"),
  elementRootPath: yup
    .string()
    .required("This is a required field"),
  elementPath: yup
    .string()
    .required("This is a required field"),
  tags: yup
    .string()
    .required("This is a required field"),
});

const formSchemaSinkForm = yup.object().shape({
  sinkName: yup
    .string()
    .required("This is a required field"),
  region: yup.string().when('sinkName', {
    is: 's3',
    then: yup.string().required('This is a required field'),
  }),
  accessKeyId: yup.string().when('sinkName', {
    is: 's3',
    then: yup.string().required('This is a required field'),
  }),
  secretAccessKey: yup.string().when('sinkName', {
    is: 's3',
    then: yup.string().required('This is a required field'),
  }),
  database: yup.string().when('sinkName', {
    is: 'snowflake',
    then: yup.string().required('This is a required field'),
  }),
  mode: yup.string().when('sinkName', {
    is: 'snowflake',
    then: yup.string().required('This is a required field'),
  }),
  schema: yup.string().when('sinkName', {
    is: 'snowflake',
    then: yup.string().required('This is a required field'),
  }),
  warehouse: yup.string().when('sinkName', {
    is: 'snowflake',
    then: yup.string().required('This is a required field'),
  }),
  role: yup.string().when('sinkName', {
    is: 'snowflake',
    then: yup.string().required('This is a required field'),
  }),

});

const formSchemaRowFilterForm = yup.object().shape({});
const formSchemaInterPolationForm = yup.object().shape({});

const extractPaths = (input:string) =>{
  const parts = input.split("\\").filter(Boolean);
  if (parts.length === 0) {
      return { rootPath: "", elementPath: "" };
  }
  const rootPath = parts[0];
  const elementPath = parts[parts.length - 1];

  return { rootPath, elementPath };
}

const HierarchyView: React.FC<HierarchyViewProps> = ({
  selectedElements,
  tableDataSources,
  columns,
  isLoadingElements,
  showForm,
  activeStep,
  selectedRowKeys,
  setActiveStep,
  setShowForm,
  handleTableChange,
  handleGoToReqPage
}) => {
  const { rootPath, elementPath } = extractPaths(selectedElements?.path||"");
  const intialValuesReqForm = { requestName: "", start_time_str: moment(), end_time_str: moment(), tags: selectedRowKeys.join(","), elementRootPath: rootPath,elementPath: elementPath };
  const intialValuesSinkForm = { sinkName: 's3', region: '', accessKeyId: '', secretAccessKey: '', database: '', mode: '', schema: '', warehouse: '', role: '' };
  const intialValuesRowFilterForm = { tag_filter_type: '' };
  const intialValuesInterPolationForm = { interploted_values: "sample_values", frequencyType: 'seconds', frequency: '1' };

  const handleSubmitReqForm = (values: any) => {
    // console.log("ReqForm values", values);
    setActiveStep(activeStep + 1);
  }

  const handleSubmitSinkForm = (values: any) => {
    // console.log("SinkForm values", values);
    setActiveStep(activeStep + 1);
  }

  const handleSubmitRowFilterForm = (values: any) => {
    // console.log("RowFilterForm values", values);
    setActiveStep(activeStep + 1);
  }

  const handleSubmitInterPolationForm = (values: any) => {
    // console.log("InterPolation values", values);
    setActiveStep(activeStep + 1);
  }

  const handleSubmitScheduleForm = () => {
    setActiveStep(0);
    setShowForm(false);
    handleGoToReqPage();
  }

  return (
    <Flex className={styles.boxHierarchyView} flexDirection="column" gap="0px">
      <div className={styles.labelHierarchyView}>
        <FaFolder />
        <Text fontSize="14px" fontWeight={600}>{formTitle[activeStep]}</Text>
      </div>
      <div>
        <ShowWhenTrue show={!showForm}>
          <Table
            dataSource={tableDataSources}
            scroll={{ y: 606 }}
            onChange={handleTableChange as any}
            loading={isLoadingElements}
            columns={columns}
            size="small"
            className={styles.dataTableHierarchy}
            pagination={false}
            locale={{
              emptyText: <Text color="white" fontSize={14}>No data</Text>,
            }}
          />
        </ShowWhenTrue>
        <ShowWhenTrue show={showForm && activeStep > 0}>
          <ShowWhenTrue show={activeStep === 1}>
            <HierarchyReqForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              initialValues={intialValuesReqForm}
              formSchema={formSchemaReqForm}
              setShowForm={setShowForm}
              handleOnSubmit={handleSubmitReqForm} />
          </ShowWhenTrue>
          <ShowWhenTrue show={activeStep === 2}>
            <HierarchySinkForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              initialValues={intialValuesSinkForm}
              formSchema={formSchemaSinkForm}
              handleOnSubmit={handleSubmitSinkForm} />
          </ShowWhenTrue>
          <ShowWhenTrue show={activeStep === 3}>
            <HierarchyRowFilterForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              selectedRowKeys={selectedRowKeys}
              initialValues={intialValuesRowFilterForm}
              formSchema={formSchemaRowFilterForm}
              handleOnSubmit={handleSubmitRowFilterForm} />
          </ShowWhenTrue>
          <ShowWhenTrue show={activeStep === 4}>
            <HierarchyInterpolationForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              selectedRowKeys={selectedRowKeys}
              initialValues={intialValuesInterPolationForm}
              formSchema={formSchemaInterPolationForm}
              handleOnSubmit={handleSubmitInterPolationForm} />
          </ShowWhenTrue>
          <ShowWhenTrue show={activeStep === 5}>
            <HierarchyScheduleForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleOnSubmit={handleSubmitScheduleForm} />
          </ShowWhenTrue>
        </ShowWhenTrue>

        <ShowWhenTrue show={!isEmpty(selectedRowKeys) && activeStep < 1}>
          <div className={styles.hierarchyTableFooter}>
            <button
              className="btn btn-yellow btn-md"
              onClick={() => {
                setShowForm(true);
                setActiveStep(activeStep + 1);
              }}
            >
              Next
            </button>
          </div>
        </ShowWhenTrue>
      </div>
    </Flex>
  );
};

export default HierarchyView;
