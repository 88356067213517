import React, { useState, useMemo, useEffect } from 'react';
import { Modal } from '../../../components/modals';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import Form, { SelectField, InputField } from '../../../components/form';
import { schemaTypes, connectionFieldSchemas, connectionBaseFormSchema } from './form-schema';
import { ShowWhenTrue } from '../../../helpers';
import { useFormikContext } from 'formik';
import { _selectoptionType } from '../../../components/form/select-field';
import { DataSourceHandler, NewConnectionMetadata, NewConnectionTypeInfo } from '../../../api/data-source-handler';
import isEmpty from 'lodash/isEmpty';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { errorAlert,successAlert } from '../../../components/toastify/notify-toast';
import { setActionType, toggleDataBrowserModal } from '../../../store/datasources';
import { useDidUpdate } from 'rooks';
import { DynamicKeyValueFieldGenerator } from '../../../components/form/dynamic-field-generator';
import { FieldSchemaCreator, FieldSchemaValidator } from '../../../components/formcreators/schema-creator';
import { convertEnumsToOptions, getRequiredFieldSchema } from '../../../utils';
import { ToggleField } from '../../../components/form/toggle-field';
import { ScheduleFields, ScheduleTiming, ScheduleTypes } from '../../workflow-page/modals/schedule-workflow';
import { ConnectionTypes } from '../enums';
import CustomMonacoEditor from '@components/monacoEditor';
import { Checkbox } from '@components/form/elements';
import { Flex } from '@components/ui/Flex';
import { Text } from '@components/ui/Text';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd-latest';
// import { Env } from '@constants/settings';

const requestTypes = [
    { label: 'POST', value: 'POST' },
    { label: 'GET', value: 'GET' },
    { label: 'PUT', value: 'PUT' },
    { label: 'DELETE', value: 'DELETE' }
];

export enum AuthTypes {
    None = 'None',
    'Basic Auth' = 'basicAuth' 
}

export enum ConnectionScheduleTypes {
    'minutes' = 'minutes',
    'hourly' = 'hourly',
    'daily' = 'daily',
    'weekly' = 'weekly',
    'monthly' = 'monthly'
}

export enum AzureAccessType {
    'Access Key' = 'access_key',
    'SAS Token' = 'sas_token',
    'Connection String' = 'connectionString'
}

const azureAccessTypeOptions = convertEnumsToOptions(AzureAccessType);


const scheduleTypesToConnectionScheduleTypes: Record<ScheduleTypes, ConnectionScheduleTypes> =  {
    'Minute': ConnectionScheduleTypes.minutes,
    'Hour': ConnectionScheduleTypes.hourly,
    'Day': ConnectionScheduleTypes.daily,
    'Week': ConnectionScheduleTypes.weekly,
    'Month': ConnectionScheduleTypes.monthly
};

const connectionScheduleTypesToScheduleTypes: Record<ConnectionScheduleTypes, ScheduleTypes> =  {
    [ConnectionScheduleTypes.minutes]: ScheduleTypes.Minute,
    [ConnectionScheduleTypes.hourly]: ScheduleTypes.Hour,
    [ConnectionScheduleTypes.daily]: ScheduleTypes.Day,
    [ConnectionScheduleTypes.weekly]: ScheduleTypes.Week,
    [ConnectionScheduleTypes.monthly]: ScheduleTypes.Month
};

export const authTypeOptions = convertEnumsToOptions(AuthTypes);
export const authTypeOptionsSnow = [{label:'Private Key', value:'privateKey'}, {label:'Basic Auth', value:'basicAuth'}]
const awsRegions = ["af-south-1","ap-east-1","ap-northeast-1","ap-northeast-2","ap-northeast-3","ap-south-1","ap-southeast-1","ap-southeast-2","ap-southeast-3","ca-central-1","cn-north-1","cn-northwest-1","eu-central-1","eu-north-1","eu-south-1","eu-west-1","eu-west-2","eu-west-3","me-south-1","sa-east-1","us-east-1","us-east-2","us-gov-east-1","us-gov-west-1","us-west-1","us-west-2"].map(reg => ({ label: reg, value: reg }))

const initialValues: schemaTypes = {
    name: '', host: '', port: '', username: '', password: '', 
    url: '', databaseName: '', driver: '', connectionStringOption: '', oracleServiceName: '' ,
    scheduleType: ScheduleTypes.Hour, weekOfTheMonth: 1, 
    days: 1, hrs: 1, mins: 0, months: 1, weekDays: '0', 
    headers: {}, queryparam: {}, serverUrl: '', requestType: requestTypes[1].value,
    authType: AuthTypes['Basic Auth'], formData: {}, scheduleAdvancedMode: false, responseTokenKey: '', 
    accessTypeName: AzureAccessType['Access Key'], accountName: '', accountKeyOrSasToken:'',
    scheduleTimingInfo: ScheduleTiming.Recurring,
    cronExpression: "0 * * * *", // Runs every 0th minute of the 1st hour - default value on the basis of days hours mins fields
    platformType: "Generic",
    sourceType: "RDBMS",
    connectionType: 'MySql',
    sharedAccessKey: '',
    sharedAccessKeyName: '',
    edgeName: '',
    nodeName: '',
    accessKey: '',
    accessKeyId: '',
    secretAccessKey: '',
    region:'',
    schema: '',
    warehouse: '',
    clientId: '',
    clientSecret: '',
    tenantId:'',
    projectId:'',
    privateKeyJsonFile:'',
    serviceAccountEmailId:'',
    testConnection:true,
    isDefaultStorage:false,
    isRasterPlotStorage:false,
    entityPath:'',
    containerName:'',
    connectionString:'',
    privateKey: '',
    isPlainText: true,
    secretManager: '',
    secretManagerRegion: '',
    cloudProvider: 'aws',
};

interface NewConnectionFormProps {
    connectionTypesInfo: NewConnectionMetadata;
    handleSetAdditionalValues: (obj: Record<string, any>) => void;
    editorControllerProps?: any;
    actionType: 'NEW' | 'EDIT';
}

const azureConnectionTypes = ['AzureBlobStorage', 'Gen2', 'EventsHub', 'CosmosDB'];

const NewConnectionForm: React.FC<NewConnectionFormProps> = ({ connectionTypesInfo, handleSetAdditionalValues,editorControllerProps,actionType }) => {
    const { values, setFieldValue } = useFormikContext<schemaTypes>();
    const {
        isCosmosDb,
        isEventsHub,
        isSynapse,
        isPIServer,
        isWitsml,
        isOpcua,
        isAPI,
        isAzureType,
        isAwsType
    } = useMemo(() => ({
        isCosmosDb: values.connectionType === 'CosmosDB',
        isEventsHub: values.connectionType === 'EventsHub',
        isSynapse: values.connectionType === 'Synapse',
        isPIServer: values.connectionType === 'PI',
        isWitsml: values.connectionType === 'WITSML',
        isOpcua: values.connectionType === 'OPCUA',
        isAPI: values.connectionType === 'REST' || values.connectionType === 'Snowflake_API',
        isAzureType: azureConnectionTypes.some(connection => connection === values.connectionType),
        isAwsType: values.platformType === 'AWS'

    }), [values.connectionType])

    const [edgeNodeName, setEdgeNodeName]  = useState<_selectoptionType[]>([]);
    const [isPlainText, setIsPlainText]  = useState<boolean>(true);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    const isCosmosDbOrEventsHub = isEventsHub || isCosmosDb;
    // const handleFileUpload = (event: React.FormEvent<HTMLInputElement>) => {
    //     const a = event;
    // };

    // const formatTime = (time: string): string => {
    //     return moment(time).format('LLL');
    // };
    

    const { platformTypeOptions, sourceTypeOptions, connectionTypeOptions } = useMemo(() => {
        const platformTypeOptions: _selectoptionType[] = Object.keys(connectionTypesInfo)
            .map(platformType => ({ label: platformType, value: platformType }));
        let sourceTypeOptions: _selectoptionType[] = [];
        let connectionTypeOptions: _selectoptionType[] = [];
        
        const selectedPlatformInfo = connectionTypesInfo[values.platformType];
        if(selectedPlatformInfo) {
            sourceTypeOptions = Object.keys(selectedPlatformInfo).map(platform => ({ label: platform, value: platform }));

            const selectedSourceTypeInfo = selectedPlatformInfo[values.sourceType]

            if(selectedSourceTypeInfo) {
                connectionTypeOptions = selectedSourceTypeInfo.map(connection => ({
                    ...connection,
                    label: connection.label,
                    value: connection.databaseType,
                }))
            }
        }


        return { platformTypeOptions, sourceTypeOptions, connectionTypeOptions }

    }, [values.platformType, values.sourceType, connectionTypesInfo])

    useEffect(() => {
        const selectedConnectionType = connectionTypeOptions.find(_option => _option.value === values.connectionType) as _selectoptionType & NewConnectionTypeInfo;
        if (selectedConnectionType ?.url) {
            let _adjustedUrl = selectedConnectionType.url;
            if (values.host) _adjustedUrl = _adjustedUrl.replace('<hostname>', values.host);
            if (values.port) _adjustedUrl = _adjustedUrl.replace('<port>', values.port.toString());
            if (values.databaseName) _adjustedUrl = _adjustedUrl.replace('<database_name>', values.databaseName);
            if (values.connectionType === 'Oracle' && values.oracleServiceName) _adjustedUrl = _adjustedUrl.replace('<oracle_service_name>', values.oracleServiceName);
            if (values.connectionStringOption && values.sourceType !== 'Databricks') _adjustedUrl += '?' + values.connectionStringOption;
            if (values.connectionStringOption && values.sourceType === 'Databricks') _adjustedUrl += ';' + values.connectionStringOption;
            setFieldValue('url', _adjustedUrl);
            setFieldValue('driver', selectedConnectionType.driver);
        }
    }, [values.host, values.port, values.databaseName, values.connectionType, values.connectionStringOption, values.oracleServiceName, connectionTypeOptions]);


    useEffect(() => {
        if(values.sourceType === 'Edge' || values.connectionType === 'Edge_Managed' || rdbms_nifiCheck || genericStorageFtpCheck) {
            let sourceType; 
            if(rdbms_nifiCheck){
                sourceType='Relational'
            }else if(genericStorageFtpCheck){
                sourceType='FTP'
            }else{
                sourceType=values.sourceType;
            }
            DataSourceHandler.GetNodeNames(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, sourceType, (res) => {
                const nodes =  res.map((node: any) => ({
                    value: node,
                    label: node
                }))
                setEdgeNodeName(nodes);
            })
        } else {
            setEdgeNodeName([]);
        }
    }, [values.sourceType, values.connectionType]);

    const handleAccessTypeClick = () => {
        // reset accountKeyOrSasToken on option change
        setFieldValue('accountKeyOrSasToken', '');
    };

    const onPlatformTypeChange = (option: _selectoptionType) => {
        const selectedPlatformInfo = connectionTypesInfo[option.value as keyof typeof connectionTypesInfo]
        // Automatically set first source type as selected source type
        const firstSourceType = Object.keys(selectedPlatformInfo)[0];
        const firstSourceInfo = selectedPlatformInfo[firstSourceType as keyof typeof selectedPlatformInfo] as NewConnectionTypeInfo[];
        const initialConnectionInfo = {
            platformType: option.value,
            'sourceType': Object.keys(selectedPlatformInfo)[0],
            connectionType: firstSourceInfo[0].databaseType
        }
        setFieldValue('sourceType', initialConnectionInfo.sourceType)
        // Automatically set first connection type as selected connection type
        setFieldValue('connectionType', initialConnectionInfo.connectionType);
        handleSetAdditionalValues(initialConnectionInfo);
    }

    // const onSourceTypeChange = (option: _selectoptionType) => {
    //     console.log("****222", option)
    //     // @ts-ignore
    //     const sourceInfo = connectionTypesInfo[values.platformType as keyof typeof connectionTypesInfo][option.value];
    //     if(!isEmpty(sourceInfo)) {
    //         const firstConnectionType = sourceInfo[0] as NewConnectionTypeInfo;
    //         const clickedConnectionType = sourceInfo.find((connectionType: NewConnectionTypeInfo) => connectionType.databaseType === values.connectionType);
    //          console.log("*****33",firstConnectionType,  clickedConnectionType)
    //         // Automatically set first connection type as selected connection type
    //         setFieldValue('connectionType', option.value);
    //         // sourceType is also included as initial values will be reset 
    //         handleSetAdditionalValues({ sourceType: option.value, connectionType: firstConnectionType.databaseType })
    //     }
    // }   

    const onSourceTypeChange = (option: _selectoptionType) => {
        const sourceInfo = connectionTypesInfo[values.platformType as keyof typeof connectionTypesInfo][option.value];
        if (!isEmpty(sourceInfo)) {
            const selectedConnectionType = connectionTypeOptions.find(connType => connType.value === values.connectionType);
            if (selectedConnectionType && sourceInfo.some(connType => connType.databaseType === selectedConnectionType.value)) {
                // If the previously selected connection type is still available in the options of the new source type,
                // retain the previously selected connection type
                handleSetAdditionalValues({ sourceType: option.value, connectionType: values.connectionType });
            } else {
                // If the previously selected connection type is not available in the options of the new source type,
                // set the first connection type of the new source type as selected
                const firstConnectionType = sourceInfo[0] as NewConnectionTypeInfo;
                handleSetAdditionalValues({ sourceType: option.value, connectionType: firstConnectionType.databaseType });
            }
        }
    }

    const rdbms_nifiCheck=values.connectionType ==='Edge_Managed_Oracle' && values.sourceType ==='RDBMS';
    const genericStorageFtpCheck = values.connectionType === "Edge_Managed_FTP" && values.sourceType === 'ObjectStorage' && values.platformType=='Generic';

    return (
        <>
            <div className={classNames({ '_form--halfwide': !isOpcua })}>
                <SelectField
                    name="platformType"
                    label="Data Platform"
                    options={platformTypeOptions}
                    onOptionClick={onPlatformTypeChange}
                    validate={FieldSchemaValidator(connectionFieldSchemas.platformType)}
                />
                <SelectField
                    name="sourceType"
                    label="Data Source"
                    options={sourceTypeOptions}
                    onOptionClick={onSourceTypeChange}
                    validate={FieldSchemaValidator(connectionFieldSchemas.sourceType)}
                />
            </div>
            <ShowWhenTrue show={values.sourceType !== "Databricks"}>
                <SelectField
                    name="connectionType"
                    label="Connection Type"
                    className="connectionType"
                    options={connectionTypeOptions}
                />
            </ShowWhenTrue>
            <div className="horizontalSeparator" />
            <div
                className="innerConnectionForm"
            >
                <InputField
                    label="Name your connection"
                    name="name"
                    validate={FieldSchemaValidator(connectionFieldSchemas.name)}
                    autoComplete="off"
                    disabled={actionType ==="EDIT"}
                    required
                />
                <ShowWhenTrue show={(values.sourceType === "RDBMS" && values.connectionType === "CloudSQL")||(values.sourceType === "ObjectStorage" && values.connectionType === "GFS")}>
                    <InputField
                        label="Project ID"
                        name="projectId"
                        validate={FieldSchemaValidator(connectionFieldSchemas.projectId)}
                        autoComplete="off"
                        required
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={(values.connectionType === "GFS" && values.sourceType === "ObjectStorage")}>
                    <InputField
                        label="Service Account Email Id"
                        name="serviceAccountEmailId"
                        validate={FieldSchemaValidator(connectionFieldSchemas.serviceAccountEmailId)}
                        autoComplete="off"
                        required
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={(values.sourceType === "RDBMS" && values.connectionType === "CloudSQL") || (values.sourceType === "ObjectStorage" && values.connectionType === "GFS")}>
                    <CustomMonacoEditor
                        {...editorControllerProps}
                        editorHeight={250}
                        editorWidth="100%"
                        language="json"
                        toolbarOptions={["Copy", "Clear"]}
                        label="Private Key JSON File"
                        required
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={(values.sourceType === "DataExplorer" && values.connectionType === "Kusto" ||(values.sourceType === "Sharepoint" && values.connectionType === "TokenBased" || values.connectionType === "ClientCreds"))}>
                    <InputField
                        label="Client Id"
                        name="clientId"
                        validate={FieldSchemaValidator(connectionFieldSchemas.clientId)}
                        autoComplete="off"
                        required
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={(values.sourceType === "DataExplorer" && values.connectionType === "Kusto" ||(values.sourceType === "Sharepoint" && values.connectionType === "TokenBased" || values.connectionType === "ClientCreds"))}>
                    <InputField
                        label="Client Secret"
                        name="clientSecret"
                        validate={FieldSchemaValidator(connectionFieldSchemas.clientSecret)}
                        autoComplete="off"
                        required
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={(values.sourceType === "DataExplorer" && values.connectionType === "Kusto") ||(values.sourceType === "Sharepoint" && values.connectionType === "TokenBased")}>
                    <InputField
                        label="Tenant Id"
                        name="tenantId"
                        validate={FieldSchemaValidator(connectionFieldSchemas.tenantId)}
                        autoComplete="off"
                        required
                    />
                </ShowWhenTrue>
                {isAwsType && values.connectionType !== 'Redshift' ? 
                <>
                    <SelectField 
                        name="region"
                        options={awsRegions}
                        label="Region"
                        validate={FieldSchemaValidator(connectionFieldSchemas.region)}
                        required
                    />
                    <InputField 
                        name='accessKeyId'
                        label="Access Key ID"
                        validate={FieldSchemaValidator(connectionFieldSchemas.accessKeyId)}
                        required
                    />
                    <InputField 
                        name='secretAccessKey'
                        label="Secret Access Key"
                        validate={FieldSchemaValidator(connectionFieldSchemas.secretAccessKey)}
                        type="password"
                        required
                    />
                </>
                :
                <>
                    <ShowWhenTrue show={values.sourceType !== 'Edge' && values.connectionType !== 'Edge_Managed'}>
                        <ShowWhenTrue show={!(isPIServer || isWitsml || isAPI || isAzureType)}>
                            <div>
                                <ShowWhenTrue show={!isOpcua}>
                                    {values.connectionType === 'Oracle' ?
                                        <InputField
                                            label="Service name"
                                            name="oracleServiceName"
                                            autoComplete="off"
                                            validate={FieldSchemaValidator(FieldSchemaCreator({ variable_type: 'string', required: true }) as any)}
                                            required
                                        />
                                        :
                                        <ShowWhenTrue show={!genericStorageFtpCheck && !rdbms_nifiCheck && !(values.connectionType === "PI_Direct" && values.sourceType === "OSIPI" || values.connectionType === "Kusto" && values.sourceType === "DataExplorer" || values.connectionType === "CloudSQL"|| values.connectionType === "GFS" && values.sourceType === "ObjectStorage" || values.sourceType === "Sharepoint" ||  values.connectionType === "FTP")}>
                                            <InputField
                                                label="Database name"
                                                name="databaseName"
                                                autoComplete="off"
                                                validate={FieldSchemaValidator(connectionFieldSchemas.databaseName)}
                                                required
                                            />
                                        </ShowWhenTrue>  
                                    }
                                </ShowWhenTrue>
                            </div>
                            <ShowWhenTrue show={values.connectionType === 'Oracle'}>
                                <InputField
                                    label="Database name"
                                    name="databaseName"
                                    autoComplete="off"
                                />
                            </ShowWhenTrue>
                        </ShowWhenTrue>
                        <ShowWhenTrue show={!genericStorageFtpCheck && !rdbms_nifiCheck && !isWitsml && !isOpcua && !isAPI && !isAzureType && !(values.connectionType === "Kusto" && values.sourceType === "DataExplorer") && !(values.connectionType === "CloudSQL" && values.sourceType === "RDBMS") && !(values.connectionType === "GFS" && values.sourceType === "ObjectStorage") && !(values.sourceType === "Sharepoint")}>
                            <div className="_form--halfwide">
                                <InputField
                                    label="Host"
                                    name="host"
                                    placeholder="Enter Host"
                                    required
                                    validate={FieldSchemaValidator(connectionFieldSchemas.host)}
                                />
                                {values.connectionType === ConnectionTypes.Snowflake ? 
                                    <InputField
                                        label="Schema"
                                        name="schema"
                                        placeholder="Enter Schema"
                                        validate={FieldSchemaValidator(connectionFieldSchemas.schema)}
                                        required
                                    />
                                    :
                                    <InputField
                                        label="Port"
                                        name="port"
                                        type="number"
                                        placeholder="Enter port"
                                        validate={values.connectionType !== "FTP" && FieldSchemaValidator(connectionFieldSchemas.port)}
                                        required={values.connectionType !== "FTP"}
                                    />
                                }
                            </div>
                        </ShowWhenTrue>
                        <ShowWhenTrue show={values.connectionType === ConnectionTypes.Snowflake}>
                            <InputField
                                label='Account'
                                name="accountName"
                                placeholder='Enter Account name'
                                validate={FieldSchemaValidator(connectionFieldSchemas.accountName)}
                                required
                            />
                            <div className="_form--halfwide">
                                <InputField
                                    label='Region'
                                    name="region"
                                    validate={FieldSchemaValidator(connectionFieldSchemas.region)}
                                    required
                                />
                                <InputField
                                    label='Warehouse'
                                    name="warehouse"
                                    validate={FieldSchemaValidator(connectionFieldSchemas.warehouse)}
                                    required
                                />
                            </div>
                            <SelectField
                                    name="authType"
                                    label="Authentication Type"
                                    options={authTypeOptionsSnow}
                                />

                            <SelectField
                                    name="cloudProvider"
                                    label="Cloud Provider"
                                    options={[{label:'AWS', value:'aws'}]}
                                />
                        </ShowWhenTrue>
                        <ShowWhenTrue show={values.connectionType !=="RDBMS" && (isWitsml || isOpcua || isCosmosDbOrEventsHub) && (values.sourceType !== 'Edge') }>
                            <InputField
                                label={isCosmosDbOrEventsHub ? "Endpoint": "Url"}
                                name="url"
                                placeholder={`Enter ${isCosmosDbOrEventsHub ? "Endpoint": "Url"}`}
                                validate={FieldSchemaValidator(getRequiredFieldSchema(isCosmosDbOrEventsHub ? "Endpoint": "Url"))}
                                required
                            />
                        </ShowWhenTrue>
                        <ShowWhenTrue show={isAPI}>
                            <InputField
                                label="Server URL"
                                name="serverUrl"
                                placeholder="Enter Host Address"
                            />
                            <InputField
                                label="Token URL"
                                name="url"
                                placeholder="Enter Url"
                                validate={FieldSchemaValidator(connectionFieldSchemas.tokenUrl)}
                                required
                            />

                            <div className="_form--halfwide">
                                <SelectField
                                    name="requestType"
                                    label="Request Type"
                                    options={requestTypes}
                                    validate={FieldSchemaValidator(connectionFieldSchemas.url)}
                                />
                                <SelectField
                                    name="authType"
                                    label="Authentication Type"
                                    options={authTypeOptions}
                                />
                            </div>
                        </ShowWhenTrue>
                        <ShowWhenTrue show={isAzureType}> 
                            {isCosmosDbOrEventsHub ? 
                                <div className={classNames({ "_form--halfwide": isEventsHub })}>
                                    {isEventsHub &&
                                        <InputField
                                            label="Shared Access Key Name"
                                            name="sharedAccessKeyName"
                                            placeholder="Enter Shared Access Key name"
                                            validate={FieldSchemaValidator(connectionFieldSchemas.tokenUrl)}
                                            required
                                        />
                                    }
                                    <InputField
                                        label={isEventsHub ? 'Shared Access Key': 'Key'}
                                        name="sharedAccessKey"
                                        placeholder={isEventsHub ? 'Enter Shared access key': 'Enter Key'}
                                        required
                                        validate={FieldSchemaValidator(connectionFieldSchemas.accountKeyOrSasToken)}
                                        type="password"
                                    />
                                </div>
                                :
                                <> 
                                    <SelectField
                                        name="accessTypeName"
                                        label="Type of Access"
                                        options={azureAccessTypeOptions}
                                        onOptionClick={handleAccessTypeClick}
                                        validate={FieldSchemaValidator(connectionFieldSchemas.accessTypeName)}
                                        required
                                    />
                                    <div className={classNames(values.accessTypeName != AzureAccessType['Connection String']?"_form--halfwide":"")}>
                                        <InputField
                                            label='Storage Account'
                                            name="accountName"
                                            placeholder='Enter storage account name'
                                            validate={FieldSchemaValidator(connectionFieldSchemas.accountName)}
                                            required ={values.accessTypeName != AzureAccessType['Connection String']}
                                        />
                                        <ShowWhenTrue show={values.accessTypeName != AzureAccessType['Connection String']}>
                                            <InputField
                                                label={values.accessTypeName === AzureAccessType['Access Key'] ? 'Access Key': 'SAS Token'}
                                                name="accountKeyOrSasToken"
                                                placeholder={values.accessTypeName === AzureAccessType['Access Key'] ? 'Enter access key': 'Enter SAS token'}
                                                required
                                                validate={FieldSchemaValidator(connectionFieldSchemas.accountKeyOrSasToken)}
                                                type="password"
                                            />
                                        </ShowWhenTrue>
                                    </div>
                                    <ShowWhenTrue show={values.accessTypeName === AzureAccessType['Connection String']}>
                                        <div className={classNames("_form--halfwide")}>
                                            <InputField
                                                label='Container Name'
                                                name="containerName"
                                                placeholder='Enter container name'
                                                validate={FieldSchemaValidator(connectionFieldSchemas.containerName)}
                                                required
                                            />
                                            <InputField
                                                label='Connection String'
                                                name="connectionString"
                                                placeholder='Enter connection string'
                                                validate={FieldSchemaValidator(connectionFieldSchemas.connectionString)}
                                                required
                                            />
                                        </div>
                                    </ShowWhenTrue>
                                </>
                            }
                        </ShowWhenTrue>
                        <ShowWhenTrue show={(values.sourceType === "Queues" && values.connectionType === "EventsHub" && values.platformType ==="Azure")}>
                            <InputField
                                label="EventsHub Name"
                                name="entityPath"
                                validate={FieldSchemaValidator(connectionFieldSchemas.entityPath)}
                                autoComplete="off"
                                required
                            />
                        </ShowWhenTrue>

                        <InputField
                                            label='Username'
                                            name="username"
                                            placeholder='Enter username'
                                            validate={FieldSchemaValidator(connectionFieldSchemas.username)}
                                            required
                                        />

                        {((values.authType === 'basicAuth' && values.connectionType === 'Snowflake') ||
                                (values.authType === 'privateKey' && values.connectionType === 'Snowflake')) && (
                                    <>
                                        <Radio.Group
                                            name="isPlainText"
                                            className="mb-1"
                                            value={isPlainText}
                                            onChange={(e: RadioChangeEvent) => {
                                                setIsPlainText(e.target.value);
                                                setFieldValue("isPlainText", e.target.value);
                                            }}
                                        >
                                            <Radio value={true} checked={isPlainText}>
                                                Plain Text
                                            </Radio>
                                            <Radio value={false} checked={isPlainText}>
                                                Secret Key
                                            </Radio>
                                        </Radio.Group>
                                    </>
                                )}

                        <ShowWhenTrue show={!genericStorageFtpCheck && !rdbms_nifiCheck && !isOpcua &&  !isAzureType && (isAPI ? values.authType === AuthTypes['Basic Auth']: true) &&  !(values.sourceType === "DataExplorer" && values.connectionType === "Kusto") && !(values.connectionType === "CloudSQL" && values.sourceType === "RDBMS") && !(values.connectionType === "GFS" && values.sourceType === "ObjectStorage") && !(values.sourceType === "Sharepoint" && values.connectionType === "TokenBased" || values.connectionType === "ClientCreds")
                            || (values.connectionType === 'Snowflake' && values.authType =='basicAuth')
                        }>
                        <div className={classNames({'credentials__box': isAPI})}>
                                {isAPI && 
                                    <label 
                                        className="inputfield__label credentials__label"
                                    >
                                        Credentials
                                    </label>
                                }
                            
                                            {!(values.connectionType === 'Snowflake' &&
                                                values.authType === 'privateKey') &&
                                                isPlainText !== false && ( 
                                                    <InputField
                                                        label="Password"
                                                        name="password"
                                                        placeholder="Enter password"
                                                        validate={
                                                            values.sourceType === "Databricks" &&
                                                            FieldSchemaValidator(connectionFieldSchemas.password)
                                                        }
                                                        required={values.sourceType === "Databricks"}
                                                        type="password"
                                                    />
                                                )}
                            </div>
                        </ShowWhenTrue>
                        {(values.authType =='privateKey' && isPlainText) && <InputField
                                    inputClassName='privateKeyy'
                                    name="privateKey"
                                    label="Private Key"
                                    type="password"
                                    required
                                />} 

                            {!isPlainText &&
                                (values.authType === 'privateKey' || values.authType === 'basicAuth') && (
                                    <>
                                        <div className="_form--halfwide">
                                            <InputField
                                                label="Secret Manager"
                                                name="secretManager"
                                                placeholder="Enter Secret Manager"
                                                required
                                            />
                                            <InputField
                                                label="Secret Manager Region"
                                                name="secretManagerRegion"
                                                placeholder="Enter Secret Region"
                                                required
                                            />
                                        </div>
                                        <InputField
                                            inputClassName="privateKeyy"
                                            name="secretAccessKey"
                                            label="Secret Key ID"
                                            type="password"
                                            required
                                        />
                                    </>
                                )}

                        <ShowWhenTrue show={!genericStorageFtpCheck && !rdbms_nifiCheck && !isPIServer && !isWitsml && !isOpcua && !isAzureType && !isSynapse}>
                            <ShowWhenTrue show={!isAPI && !(values.connectionType === "PI_Direct" && values.sourceType === "OSIPI") && !(values.sourceType === "DataExplorer" && values.connectionType === "Kusto") && !(values.connectionType === "CloudSQL" && values.sourceType === "RDBMS") && !(values.connectionType === "GFS" && values.sourceType === "ObjectStorage") && !(values.sourceType === "ObjectStorage" && values.connectionType === "FTP")}>
                                <InputField
                                    label={values.sourceType === "Sharepoint"?"Site Url":"Url"}
                                    name="url"
                                    validate={FieldSchemaValidator(connectionFieldSchemas.url)}
                                    required
                                />
                            </ShowWhenTrue>
                            <ShowWhenTrue show={!genericStorageFtpCheck && !(values.connectionType === "PI_Direct" && values.sourceType === "OSIPI" || values.sourceType === "DataExplorer" && values.connectionType === "Kusto" || values.connectionType === "CloudSQL" && values.sourceType === "RDBMS" || values.connectionType === "GFS" && values.sourceType === "ObjectStorage"|| values.sourceType === "Sharepoint") && !(values.sourceType === "ObjectStorage" && values.connectionType === "FTP")}>
                                <InputField
                                    label="Additional Parameters"
                                    name="connectionStringOption"
                                />
                            </ShowWhenTrue>
                        </ShowWhenTrue>
                        <ShowWhenTrue show={isAPI}>
                            <InputField
                                label="Token attribute"
                                name="responseTokenKey"
                                placeholder="Key to access the token"
                                infoText="This should match the access token attribute in api response"
                                validate={FieldSchemaValidator(connectionFieldSchemas.responseTokenKey)}
                                required
                            />
                            <ToggleField
                                label="Schedule Options"
                                active={values.scheduleAdvancedMode}
                                onClick={() => setFieldValue('scheduleAdvancedMode', !values.scheduleAdvancedMode)}
                            />
                            <ShowWhenTrue show={values.scheduleAdvancedMode}>
                                <ScheduleFields     
                                    showTimezone={true}
                                    showAllMinutes  
                                    showScheduleFormAtAllTimes
                                    disableMultiWeekDaySelection
                                    hideScheduleType
                                />
                            </ShowWhenTrue>
                            <DynamicKeyValueFieldGenerator
                                keyToSaveKeyValueInfo="formData"
                                addButtonText="+ Form Data"
                            />
                            <DynamicKeyValueFieldGenerator
                                keyToSaveKeyValueInfo="headers"
                                addButtonText="+ Headers"
                            />
                            <DynamicKeyValueFieldGenerator
                                keyToSaveKeyValueInfo="queryparam"
                                addButtonText="+ Query Parameters"
                            />
                            
                        </ShowWhenTrue>
                    </ShowWhenTrue>
                        <ShowWhenTrue show={(values.connectionType !== 'Postgres' && values.connectionType !== 'MySql' && values.connectionType !== 'Oracle' && values.connectionType !== 'Snowflake' && values.connectionType !== 'SQLServer' && (values.sourceType === 'Edge' || values.connectionType === 'Edge_Managed' || values.sourceType === 'RDBMS')) || genericStorageFtpCheck}>
                        <SelectField
                            name="edgeName"
                            label="Edge Name"
                            required={rdbms_nifiCheck}
                            options={edgeNodeName}
                            validate={FieldSchemaValidator(connectionFieldSchemas.edgeName)}
                        />
                        <InputField
                            label="Access Key"
                            name="accessKey"
                            placeholder="Enter Access Key"
                            validate={FieldSchemaValidator(connectionFieldSchemas.edgeName)}
                            required
                            autoComplete='off'
                        />
                    </ShowWhenTrue>
                </>
                }
                {!(values.connectionType === ConnectionTypes.Snowflake) && <Flex alignItems={"center"} gap='10px' mt={2}>
                    <Checkbox
                        checked={values.testConnection}
                        onClick={() =>{
                            setFieldValue("testConnection",!values.testConnection);
                        }}
                        color="gold"								
                    />	
                    <Text color={"white"}>Test Connection</Text>
                </Flex>	}	
                <ShowWhenTrue show={values.platformType!=="Generic" && Env?.ENABLE_STORAGE_OPTION}>
                    <Text color={"white"} mt={3} mb={2} fontWeight={500}>Storage</Text>
                    <Flex alignItems={"center"}>
                        <ToggleField
                            label="Default Storage"
                            active={values.isDefaultStorage}
                            disabled
                            onClick={() =>{
                                setFieldValue("isDefaultStorage",!values.isDefaultStorage);
                            }}
                        />
                    </Flex>	
                    <Flex alignItems={"center"} >
                        <ToggleField
                            label="Raster Plot Storage"
                            active={values.isRasterPlotStorage}
                            disabled
                            onClick={() =>{
                                setFieldValue("isRasterPlotStorage",!values.isRasterPlotStorage);
                            }}
                        />
                    </Flex>		
                </ShowWhenTrue>			
            </div>
        </>
    );
};

export interface NewEditConnectionProps {
    onCreateConnSuccess: () => any;
    actionType: 'NEW' | 'EDIT';
    connectionDataForEdit: any;
}

export const NewEditConnectionModal: React.FC<NewEditConnectionProps> = ({ onCreateConnSuccess, connectionDataForEdit, actionType }) => {
    const { newEditConnection: showModal } = useSelector((store: RootState) => store.NewDataSourceReducer.showModal);
    const [connectionTypesInfo, setConnectionTypesInfo] = useState<NewConnectionMetadata>({});
    const [additionalInitialValues, setAdditionalInitialvalues] = useState<Record<any, any>>({});
    const [showSpinner, toggleSpinner] = useState(false);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const __initialValues = useMemo(() => {
        let obj =  { ...initialValues, ...additionalInitialValues }
        //TODO: this is round work, need to find a acutall fix
        for (const [k , v] of Object.entries(obj)) {
           if(v === null) {
                // @ts-ignore
                obj[k] = "";
           }
        }
        initialValues.edgeName = initialValues.nodeName;
        obj = {...obj,username:obj.sourceType === "Databricks"? "token": obj.username}
        return obj;
    }, [initialValues, additionalInitialValues]);

    const dispatch = useDispatch();

    const handleSetOptionsForConnectionsAndDatabases = (response: NewConnectionMetadata) => {
        if (!isEmpty(response)) {
            // let _connectionTypes: any = [];
            // if(Env.dataSourceList === 'ALL'){
            //     _connectionTypes = Object.keys(response).map(_val => ({ 
            //         label: _val === "PI_NiFi_Managed" ? "PI (NiFi Managed)": startCase(_val), value: _val 
            //     }));
            // } else {
            //     const obj: any = {};
            //     Env.dataSourceList.split(',').forEach((key: string | number) => {
            //         obj[key] = response[key];
            //     });
            //     _connectionTypes = Object.keys(obj).map(_val => ({ label: startCase(_val), value: _val }));
            // }
            setConnectionTypesInfo(response);
        }
    };

    const editorControllerProps = CustomMonacoEditor.useCustomMonacoEditorController();

    useEffect(() => {
        if (showModal) DataSourceHandler.GetDatabaseTypeList(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, handleSetOptionsForConnectionsAndDatabases);
        else {
            setAdditionalInitialvalues({});
        }
    }, [showModal]);

    useDidUpdate(() => {
        if (actionType === 'EDIT' && showModal) {
            // Refer to handleConnectionInfoSubmit payload for connectionType and sourceType keys
            setAdditionalInitialvalues({ ...connectionDataForEdit, connectionType: connectionDataForEdit.databaseType, sourceType: connectionDataForEdit.sourceType });
            if(connectionDataForEdit.scheduleType) {
                connectionDataForEdit.scheduleType = connectionScheduleTypesToScheduleTypes[connectionDataForEdit.scheduleType as ConnectionScheduleTypes];
                connectionDataForEdit.days = connectionDataForEdit.day;
                if(connectionDataForEdit.scheduleType === ScheduleTypes.Week && connectionDataForEdit.day) {
                    // scheduleFields days start from 0, backend days start from 1
                    // hence 1 is added while generating the payload
                    connectionDataForEdit.weekDays = (connectionDataForEdit.day - 1).toString();

                }
            }

            if (connectionDataForEdit && 
                (connectionDataForEdit.connectionType === 'GFS' || connectionDataForEdit.connectionType === 'CloudSQL')) {
				try {
					const privateKeyJsonFile = JSON.parse(connectionDataForEdit.privateKeyJsonFile);
					editorControllerProps.setEditorValue(
						JSON.stringify(privateKeyJsonFile,null,4)
					);
				} catch {
					errorAlert("Error parsing the Private key json file");
				}
			} else {
				editorControllerProps.setEditorValue("");
			}
        }
    }, [connectionDataForEdit, showModal]);

    const toggleClose = () => {
        dispatch(toggleDataBrowserModal('newEditConnection', false));
        editorControllerProps.setEditorValue("");
        dispatch(setActionType("EDIT"))
    };

    const handleNewConnectionSuccess = () => {
        if(actionType === 'NEW') successAlert('New Connection Created');
        if(actionType === 'EDIT') successAlert('Connection Updated');
        onCreateConnSuccess();
        toggleSpinner(false);
        toggleClose();
    };

    const handleNewConnectionFailure = (response: AxiosResponse) => {
        errorAlert(response.data.message ? response.data.message: response.data);
        toggleSpinner(false);
    };

    const handleConnectionInfoSubmit = (values: schemaTypes) => {
        const rdbmsnifiCheck=values.connectionType ==='Edge_Managed_Oracle' && values.sourceType ==='RDBMS';
        const genericStorageFtpCheck = values.connectionType === "Edge_Managed_FTP" && values.sourceType === 'ObjectStorage' && values.platformType=='Generic';
        
        if(!showSpinner) {

            let payload: Partial<schemaTypes> & { databaseType: string} = {
                'name': values.name,
                'platformType': values.platformType,
                'connectionType': values.sourceType,
                'databaseType': values.connectionType,
                driver: values.driver,
                connectionStringOption: values.connectionStringOption,
                testConnection:values.testConnection,
                isDefaultStorage:values.isDefaultStorage,
                isRasterPlotStorage:values.isRasterPlotStorage,
                entityPath: values.entityPath,
                containerName: values.containerName,
                connectionString: values.connectionString,
            };
            toggleSpinner(true);
            let scheduleObj: any = {};

            if(azureConnectionTypes.some(conn => conn === values.connectionType)) {
                if(values.connectionType === 'AzureBlobStorage' || values.connectionType === 'Gen2')
                    payload = {
                        ...payload,
                        accessTypeName: values.accessTypeName,
                        accountKeyOrSasToken: values.accountKeyOrSasToken,
                        accountName: values.accountName
                    };
                
                else {
                    // 'CosmosDB' || 'EventsHub'
                    payload = {
                        ...payload,
                        url: values.url,
                        sharedAccessKey: values.sharedAccessKey
                    }

                    if(values.connectionType === 'EventsHub') {
                        payload.sharedAccessKeyName = values.sharedAccessKeyName;
                    }
                }
                
            } else if(values.platformType === 'AWS' && values.connectionType !== 'Redshift') {
                payload.region = values.region;
                payload.accessKeyId = values.accessKeyId;
                payload.secretAccessKey = values.secretAccessKey;
            } else if(values.connectionType === 'Edge_Managed' || values.sourceType === 'Edge' || rdbmsnifiCheck || genericStorageFtpCheck) {
                payload.edgeName = values.edgeName;
                payload.nodeName = values.edgeName;
                payload.accessKey = values.accessKey;
            } else {
                payload = { 
                    ...payload,
                    'url': values.url,
                    'password': values.password,
                    'username': values.username,
                    'headers': values.headers,
                    // 'token':values.token,
                    'queryparam':values.queryparam,
                    'formData':values.formData,
                    'requestType':values.requestType,
                    'serverUrl': values.serverUrl,
                    'scheduleAdvancedMode' :values.scheduleAdvancedMode,
                    'authType': values.authType,
                    responseTokenKey: values.responseTokenKey,
                    host: values.host,
                    port: values.port,
                    databaseName: values.databaseName,
                    clientId: values.clientId,
                    clientSecret : values.clientSecret,
                    tenantId : values.tenantId,
                };
            }     
            if(values.connectionType === 'GFS' || values.connectionType === 'CloudSQL') {
                try{
                    const parsedJson = JSON.parse(editorControllerProps.editorValue);
                    const gfsObj = {
                        projectId: values.projectId,
                        serviceAccountEmailId:values.serviceAccountEmailId,
                        privateKeyJsonFile:JSON.stringify(parsedJson),
                    }
                    payload = {...payload,...gfsObj}
                }catch(e){
                    errorAlert("Invalid private key json");
                    toggleSpinner(false);
                    return;
                }
            }
            // if (values.connectionType === 'WITSML') {
            //     values.databaseType = 'WITSML';
            // }
            // if(!!values.scheduleType && scheduleTypesToConnectionScheduleTypes[values.scheduleType as ScheduleTypes]) {
            //     payloadReq.scheduleType = scheduleTypesToConnectionScheduleTypes[values.scheduleType as ScheduleTypes];
            // } else {
            //     payloadReq.scheduleType = scheduleTypesToConnectionScheduleTypes.Hour;
            // }
            if (values.connectionType === 'API' || values.connectionType === 'REST' || values.connectionType === 'Snowflake_API') {
                // values.databaseType = 'API';

                if(values.authType !== AuthTypes['Basic Auth']) {
                    // @ts-ignore
                    payload.username = null;
                    // @ts-ignore
                    payload.password = null;
                }

                if(values.scheduleAdvancedMode) {
                    scheduleObj.scheduleType = scheduleTypesToConnectionScheduleTypes[values.scheduleType as ScheduleTypes] || scheduleTypesToConnectionScheduleTypes.Hour;
                    scheduleObj.mins = values.mins;
                    scheduleObj.cronExpression = values.cronExpression;
                    
                    if(values.scheduleType === ScheduleTypes.Hour) {
                        scheduleObj.hrs = values.hrs; 
                    } else if(values.scheduleType === ScheduleTypes.Day) {
                        scheduleObj = {
                            ...scheduleObj,
                            'hrs':values.hrs,
                            'day': values.days
                        };
                    } else if (values.scheduleType === ScheduleTypes.Week) {
                        scheduleObj = {
                            ...scheduleObj,
                            'hrs':values.hrs,
                            // weekDays in the from start from 0 - Sunday : 0
                            // backend's sunday starts from 1 
                            'day': parseInt(values.weekDays as string) + 1
                        };
                    } else if (values.scheduleType === ScheduleTypes.Month) {
                        scheduleObj = {
                            ...scheduleObj,
                            'hrs':values.hrs,
                            'day': values.days,
                            'weekOfTheMonth': 1
                        };
                    }
                }            

                payload = {...payload,...scheduleObj};
            } else if(payload.databaseType === 'Snowflake') {
                payload.schema = values.schema,
                payload.warehouse = values.warehouse;
                payload.region = values.region;
                payload.accountName = values.accountName;
                payload.privateKey = values.privateKey;
                payload.authType = values.authType;
                payload.cloudProvider = values.cloudProvider;
                payload.isPlainText = values.isPlainText;
                payload.secretAccessKey = values.secretAccessKey;
                payload.secretManager = values.secretManager;
                payload.secretManagerRegion = values.secretManagerRegion;
            }
            if (actionType === 'NEW') {
                DataSourceHandler.CreateConnection(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, payload as any, handleNewConnectionSuccess, handleNewConnectionFailure);
            } else {
                DataSourceHandler.UpdateConnection(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, connectionDataForEdit.id, payload as any, handleNewConnectionSuccess, handleNewConnectionFailure);
            }
        }
    };

    const handleSetAdditionalValues = (newValues: Record<string, any>) => {
        setAdditionalInitialvalues(values => ({ ...values, ...newValues }));
    }

    return (
        <Modal
            title={actionType === 'NEW' ? 'Create New Connection' : 'Edit Connection'}
            isOpen={showModal}
            toggleClose={toggleClose}
            className={classNames('newConnection__modal', { 'inEditMode': actionType === 'EDIT' })}
            image={actionType === 'EDIT' ? '/icons/link.svg' : ''}
            subtitle={actionType === 'EDIT' ? connectionDataForEdit.name : ''}
            shouldCloseOnOverlayClick={false}
        >
            <Form
                initialValues={__initialValues}
                validationSchema={connectionBaseFormSchema}
                onSubmit={handleConnectionInfoSubmit}
                className={classNames({ 'showSpinner': showSpinner })}
                enableReinitialize
            >
                <ShowWhenTrue show={showSpinner}>
                    <InPageSpinner />
                </ShowWhenTrue>
                <div className={classNames('newConnection__form' , { 'hide': showSpinner })}>
                    <NewConnectionForm
                        connectionTypesInfo={connectionTypesInfo}
                        handleSetAdditionalValues={handleSetAdditionalValues}
                        editorControllerProps = {editorControllerProps}
                        actionType={actionType}
                    />
                </div>
                <div className="modalBtns__box">
                    <button
                        className="btn-md btn-yellow"
                        type="submit"
                        id={'btnConnectionSubmit'}
                        disabled={showSpinner}
                    >
                        {actionType === 'EDIT' ? 'Update' : 'Create'}
                    </button>
                    <button
                        className="btn-md btn-cancel"
                        type="button"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>
                </div>
            </Form>
        </Modal>
    );
};