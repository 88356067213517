import React from "react";
import classNames from "classnames";
import styles from "./../styles.module.scss";
import { _selectoptionType } from "@components/form/select-field";
import Form, { SelectField } from '@components/form';
import { isEmpty } from "lodash";
import { DataSourceHandler } from "@api/data-source-handler";
import { TooltipTop } from "@components/tooltips";
import { useDataExplorerContext } from "../useDataExplorerContext";
import {
	errorAlert,
	infoAlert,
	successAlert,
} from "@components/toastify/notify-toast";
import { useGenAIContext } from "../gen-ai/useGenAIContext";
import QueryContainer from "./queryContainer";
import { encryptAndExport } from "@pages/workflow-page/utils";
import { DownloadIcon } from "@pages/data-browser/icons";
import { BsFillSunFill } from "react-icons/bs";
import { Switch } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";

const MiddleContainer = () => {
	const { sessionId, setResultMode } = useGenAIContext();
	const { database, setTheme, theme } = useDataExplorerContext();
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
	const onDownload = async () => {
		try {
			if (isEmpty(sessionId)) {
				return infoAlert("No session present to download the logs");
			}
			const data = await DataSourceHandler.DownloadLogs(Env?.REACT_DEEP_SQL_URL, sessionId);
			encryptAndExport(JSON.stringify(data), "ds-logs", "", "dslog");
			successAlert("Logs downloaded successfully");
		} catch (e) {
			errorAlert("Failed to download the log");
		}
	};


	return (
		<div id="middle_container" className={styles["middle_container"]} style={{ background: theme === "dark" ? '#030613' : '#F4F4F5' }}>
			<div className={styles["space-between"]}>
				<div
					className={classNames(styles["flex-start"], styles["heading"])}
				>
					<div className={styles["page_title"]} style={{ color: theme === "dark" ? '#fff' : '#000' }}>Sources:</div>
					<div className={styles["subtitle"]} style={{ color: theme === "dark" ? '#fff' : '#000' }}>{database.name}</div>
				</div>

				<div className={styles["flex-end"]}>
					<Form
						initialValues={{ session: 'schema' }}
						enableReinitialize
						onSubmit={() => {
							return;
						}}
						className={"flex"}
					>
						<SelectField
							name="session"
							options={[
								{
									label: 'Schema',
									value: 'schema'
								},
								{
									label: 'Langgraph',
									value: 'langgraph'
								}
							]}
							onOptionClick={(option) => {
								setResultMode(option.value)
							}}
							theme={theme}
						/>

						<TooltipTop title="Download Logs">
							<button
								className={classNames(
									styles["u-button"],
									styles["flex justify-center"],
									styles["ml-1"]
								)}
								style={{ color: theme === "dark" ? '#fff' : '#000', height: '30px',marginTop: '5px' }}
								onClick={onDownload}
							>
								<DownloadIcon />
							</button>
						</TooltipTop>
						<TooltipTop title={theme === "dark" ? 'Light' : 'Dark'}>
							<Switch
								checkedChildren={<BsFillSunFill color={theme === "dark" ? '#000' : '#fff'} />}
								unCheckedChildren={<BsFillSunFill color={theme === "dark" ? '#000' : '#fff'} />}
								checked={theme === "dark"}
								onChange={(checked) => setTheme(checked ? "dark" : "light")}
								className={'ml-1'}
								style={{ background: theme === "dark" ? '#fff' : '#000',marginTop: '5px' }}
							/>
						</TooltipTop>
					</Form>
				</div>
			</div>
			<QueryContainer
			/>
			<div>
			</div>
		</div>
	);
};

export default MiddleContainer;
