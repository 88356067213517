/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { isDevelopment } from '../../constants/settings';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

export const AirFLow: React.FC = () => {
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    useEffect(() => {
        const fn = ((e: any) => {
            console.log("message received from iframe",e.data);
        }).bind(this);
        window.addEventListener('message', fn);
        return () => {
            window.removeEventListener('message', fn);
        }
    }, []);
    return (
        Env?.REACT_APP_DISABLE_AIRFLOW ?
            <div
                className="disableMlFlow__box"
            >
                <h2>Airflow is not deployed on this environment<br />Contact DeepIQ </h2>

            </div>
            :
            (<iframe 
                className='iframe'
                style={{
                    width: 'calc(100% - 72px)',
                    height: 'calc(100vh - 54px)',
                    marginLeft: '72px',
                    marginTop: '54px',
                    border: 'none',
                    backgroundColor: 'rgb(4, 6, 20)'
                }}
                src={isDevelopment? 'https://airflow.dev.deepiqlab.com/home' : Env?.REACT_APP_AIRFLOW_URL}>
            </iframe>)
    );
};