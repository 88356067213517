import React, { useEffect, useRef, useState } from "react";
import MonacoEditor from "react-monaco-editor";
import Form, { InputField } from "@components/form";
import { ContextEditor } from "../icons";
import { useDataExplorerContext } from "../useDataExplorerContext";
import { StateData } from "../types";
import * as yup from "yup";
import { DataSourceHandler } from "@api/data-source-handler";
import { errorAlert, successAlert } from "@components/toastify/notify-toast";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { PlushIcon } from "@pages/cluster_redisign/icons";
import { SelectField, _selectoptionType } from "@components/form/select-field";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";

const Panel: React.FC<{
	mainData: StateData;
	alias: {
		alias_metadata: string;
		desc_metadata: string;
	};
	update_table: () => Promise<void>;
	tableData: any;
	isVirtualTable: boolean;
	virtualViewList: any[];
	refetchVirtualList: () => void;
}> = ({
	mainData,
	alias,
	update_table,
	tableData,
	isVirtualTable,
	virtualViewList,
	refetchVirtualList,
}) => {
	const { database, db_table, setDBTable } = useDataExplorerContext();
	const [isLoading, setLoading] = useState(false);
	const [tableSchemaData, setTableSchemaData] = useState<_selectoptionType[]>(
		[]
	);
	const [showCreateView, setShowCreateView] = useState(false);
	const [queryData, setQueryData] = useState("");
	const [virtualViewName, setVirtualViewName] = useState("");
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
	const save = async (arg: any) => {
		setLoading(true);
		try {
			await DataSourceHandler.PostSchemaTable(Env?.REACT_DEEP_SQL_URL, database.id, {
				db_id: database.id,
				relationships: [
					{
						name: db_table,
						alias: arg.alias_metadata,
						description: arg.desc_metadata,
					},
				],
			});
			setLoading(false);
			await update_table();
			successAlert("Successfully updated");
		} catch (e) {
			setLoading(false);
			errorAlert("Failed to update");
		}
	};

	const saveVirtualTableQuery = () => {
		const isTableAlreadyExists =tableData?.findIndex((item: any) => item.tableName === virtualViewName) > -1;
		if(isTableAlreadyExists){
			errorAlert("Same name table already exists");
			return;
		}
		DataSourceHandler.PostVirtualView(
			Env?.REACT_DEEP_SQL_URL,
			{
				// id: selectedView?.id ?? null,
				name: virtualViewName,
				database_id: `${database.id}`,
				select_statement: queryData,
			},
			() => {
				successAlert("Successfully saved");
				refetchVirtualList();
				setDBTable(virtualViewName);
			},
			(error) => {
				errorAlert(error.data?.detail);
			}
		);
	};

	useEffect(() => {
		let temp = tableData.map((item: any) => {
			return {
				label: item.tableName,
				value: item.tableName,
			};
		});
		temp = [
			...temp,
			...virtualViewList.map((item: any) => {
				return {
					label: item.name + " (View)",
					value: item.name,
					key: item.select_statement,
					id: item.id,
				};
			}),
		];
		setTableSchemaData(temp);
	}, [tableData, virtualViewList]);

	return (
		<div style={{ height: 370 }}>
			<Form
				initialValues={{ ...alias, selected_table: db_table }}
				enableReinitialize={true}
				validationSchema={yup.object({
					alias_metadata: yup
						.string()
						.required("This is a required field"),
				})}
				onSubmit={(arg) => {
					save(arg);
					return;
				}}
				className="flex h-100"
			>
				{() => (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								width: 500,
								borderRadius: "0.5rem",
								border: "1px solid rgba(255, 255, 255, 0.32)",
								height: 360,
								padding: "1rem",
								marginRight: "2rem"
							}}
						>
							{isLoading ? (
								<div
									className="center"
									style={{ height: "100px" }}
								>
									{" "}
									<InPageSpinner
										size="XSmall"
										className="h-24"
									/>{" "}
								</div>
							) : (
								<>
									<div className="flex row-center">
										<div style={{ marginTop: "0.5rem" }}>
											<ContextEditor />
										</div>
										<h3
											style={{
												color: "white",
												marginLeft: "0.5rem",
												display: "flex",
												marginTop: "0.2rem",
												justifyContent: "center",
											}}
										>
											{database.databaseName} -{" "}
											<SelectField
												name="selected_table"
												options={tableSchemaData}
												className="ml-1"
												onOptionClick={(option) => {
													// eslint-disable-next-line no-console
													console.log("option",option);
													setDBTable(option.value);
													setQueryData(
														option.key ?? ""
													);
													setVirtualViewName(
														option.value
													);
													setShowCreateView(false);
												}}
												renderItemAtOptionsBottom={() => (
													<button
														id="create_view"
														onClick={() => {
															setShowCreateView(
																true
															);
															setVirtualViewName(
																""
															);
															setQueryData("");
														}}
														className="btn-yellow btn-sm"
														type="button"
													>
														<PlushIcon /> Create
														View
													</button>
												)}
											/>
										</h3>
									</div>
									<h4
										style={{
											color: "#C7C7CD",
											fontSize: "1.3rem",
										}}
									>
										Metadata editor
									</h4>

									<div className="w-100">
										<label
											style={{
												fontSize: "1rem",
												color: "#C7C7CD",
											}}
										>
											Alias
										</label>
										<InputField
											name="alias_metadata"
											placeholder="Add an alias"
										/>
									</div>
									<div className="w-100 mt-1">
										<label
											style={{
												fontSize: "1rem",
												color: "#C7C7CD",
											}}
										>
											Description
										</label>
										<InputField
											name="desc_metadata"
											type="textarea"
											placeholder="Add a description"
											rows={4}
										/>
									</div>
									<div className="flex flex-end w-full mt-1">
										<button
											type="submit"
											className="btn-sm btn-yellow"
										>
											{" "}
											Save{" "}
										</button>
									</div>
								</>
							)}
						</div>
						<ShowWhenTrue show={isVirtualTable || showCreateView}>
							<div
								style={{
									width: 500,
									borderRadius: "0.5rem",
									border:
										"1px solid rgba(255, 255, 255, 0.32)",
									height: 360,
									padding: "1rem",
								}}
							>
								<div className="w-100 mb-1">
									<label
										style={{
											fontSize: "1rem",
											color: "#C7C7CD",
										}}
									>
										Virtual View Name
									</label>
									<InputField
										name="virtual_view_name"
										placeholder="Virtual View Name"
										value={virtualViewName}
										onChange={(value) => {
											setVirtualViewName(value);
										}}
										autoComplete="off"
										autoFocus
									/>
								</div>
								<MonacoEditor
									height="220px"
									width="100%"
									theme="vs-dark"
									value={queryData}
									language="sql"
									onChange={(value) => {
										setQueryData(value);
									}}
									options={{
										selectOnLineNumbers: true,
										contextmenu: false,
										formatOnPaste: true,
										accessibilitySupport: "auto",
										autoIndent: "full",
										highlightActiveIndentGuide: true,
										quickSuggestions: true,
										formatOnType: true,
										minimap: {
											enabled: false,
										},
										fontSize: 14,
										letterSpacing: 0.25,
										lineNumbersMinChars: 0,
										lineDecorationsWidth: 0,
										lineHeight: 21,
										scrollbar: {
											verticalScrollbarSize: 0,
											horizontalScrollbarSize: 10,
											verticalSliderSize: 4,
											horizontalSliderSize: 4,
										},
										glyphMargin: false,
										automaticLayout: true,
										lineNumbers: "off",
										folding: false,
									}}
								/>
								<div className="flex flex-end w-full mt-1">
									<button
										type="button"
										className="btn-sm btn-yellow"
										onClick={saveVirtualTableQuery}
									>
										Save
									</button>
									<button
										type="button"
										className="btn-sm font-normal"
										onClick={() => {
											setQueryData("");
											setVirtualViewName("");
										}}
									>
										Discard
									</button>
								</div>
							</div>
						</ShowWhenTrue>
					</div>
				)}
			</Form>
		</div>
	);
};

export default Panel;
