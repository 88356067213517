import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Popconfirm, Select, Checkbox, DatePicker, Spin } from 'antd';
import 'antd/dist/antd.css';
import styles from "../../styles.module.scss"
import { errorAlert, successAlert } from '@components/toastify/notify-toast';
import classNames from 'classnames';
import { DataSourceHandler } from '@api/data-source-handler';
import { RootState } from '@store/types';
import { useSelector } from 'react-redux';
import { isEmpty, set } from 'lodash';
import { TooltipTop } from '@components/tooltips';
import { ShowWhenTrue } from '@helpers/showwhentrue';
import { BsArrowLeft } from 'react-icons/bs';
import { Flex } from '@components/ui/Flex';
import moment from 'moment';
import { errorHandlerFor400 } from '@api/services/errorhandler';
import { TbVersionsFilled } from 'react-icons/tb';
import { Text } from '@components/ui/Text';
import { MdOutlineUndo, MdRefresh } from 'react-icons/md';
import { HierarchyData } from '../types';
import FilePreviewModal from './FilePreviewModal';
import { width } from 'styled-system';
import { _selectoptionType } from '@components/form/select-field';
import { DeleteConfirm } from '@pages/admin/asset_heirarchy/confirm';
import Spinner from '@components/spinners/spinner';

interface FileContentModalProps {
    content: any[];
    tableColumns:_selectoptionType[];
    hierarchy: HierarchyData;
    onSave: () => void;
}

const FileMetaDataTable: React.FC<FileContentModalProps> = ({ content, onSave, hierarchy ,tableColumns}) => {
    const [editingKeys, setEditingKeys] = useState<string[]>([]);
    const [updatedContent, setUpdatedContent] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showConfirmRevertToDate, setShowConfirmRevertToDate] = useState(false);
    const [showConfirmRevision, setShowConfirmRevision] = useState(false);
    const [searchText, setSearchText] = useState<string>('');
    const [searchedColumn, setSearchedColumn] = useState<string>('');
    const [revertDate, setRevertDate] = useState<any>(moment());
    const { envVariables: Env } = useSelector((store: RootState) => store.AccountReducer);
    const [globalSearchText, setGlobalSearchText] = useState<string>('');
    const [hasChanges, setHasChanges] = useState(false);
    const [assetRevisions, setAssetRevisions] = useState<any[]>([]);
    const [previewData, setPreviewData] = useState<any[]>([]);
    const [isRevision, setIsRevision] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [recordForRevision, setRecordForRevision] = useState<any>(null);
    const [modelList, setModelList] = useState<any[]>([]);
    const [activeSortColumn, setActiveSortColumn] = useState<string | null>(null);
    const [activeSearchColumn, setActiveSearchColumn] = useState<string | null>(null);
    const [originalContent, setOriginalContent] = useState<any[]>([]);
    const [loadingState, setLoadingState] = useState<{ pull: boolean; push: boolean }>({
        pull: false,
        push: false
    });
    const [filterDropdownVisibleMap, setFilterDropdownVisibleMap] = useState<{ [key: string]: boolean }>({});
    const [selectedRecord, setSelectedRecord] = useState(null);
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);

    const enabledWorkspace = workSpaceData?.filter((item) => item.isEnabled)?.[0];
    const cluster = useSelector(
        (store: RootState) => store.ClusterReducer.defaultClusterInfo
    );

    const assetRevisionsData = assetRevisions.map((item) => {
        const { deleted, revision: rev, ...assetData } = item.asset;
        return {
            revisionNumber: item.revisionNumber,
            ...assetData,
            revisionDate: moment(item.revisionDate).format('MM/DD/YYYY HH:mm:ss'),
        }
    })

    const callModelList = () => {
        DataSourceHandler.getModelList(
            `${Env?.REACT_APP_PLATFORM_URL}/asset/api`,
            (response: any) => {
                if (response) {
                    setModelList(isEmpty(response) ? [] : response);
                } else {
                    console.error("Unexpected response format or error", response);
                    errorAlert("Failed to fetch patch data. Please try again.");
                }
            }
        );
    }

    useEffect(() => {
        callModelList();
    },[])

    const handlePushPull = (action: "pull" | "push") => {
        if (!isEmpty(enabledWorkspace) && cluster && cluster?.state === "RUNNING" && cluster.defaultCluster) {
            setLoadingState(prev => ({ ...prev, [action]: true }));
            DataSourceHandler.HandlePullPushHierarchy(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, hierarchy?.id, action, (response: any) => {
                if (response) {
                    setLoadingState(prev => ({ ...prev, [action]: false }));
                    successAlert(response)
                }
            }, (e: any) => {
                setLoadingState(prev => ({ ...prev, [action]: false }));
                errorAlert(e?.response?.data || "Failed to fetch patch data. Please try again.");
                console.error("Unexpected response format or error", e);
            });
        } else {
            errorAlert("Cluster is not running or workspace is not enabled. Please check the cluster status and workspace status.")
        }

    }

    useEffect(() => {
        // callModelList();
        const processedContent = content?.map((item, index) => {
            const processedItem = Object.entries(item).reduce((acc: any, [key, value]) => {
                const cleanedKey = key.trim() === '' ? ' ' : key.trim();
                acc[cleanedKey] = value;
                return acc;
            }, {});
            return { ...processedItem, key: index.toString() };
        });
        setUpdatedContent(processedContent);
        setOriginalContent(processedContent);
        // window.addEventListener('contextmenu', handleRightClick);
        // return () => {
        //     window.removeEventListener('contextmenu', handleRightClick);
        // };
    }, [content]);

    const isEditing = (record: any) => editingKeys.includes(record.key);

    const handleRightClick = (event: MouseEvent) => {
        const modalElement = document.querySelector('.ant-modal');
        if (!modalElement?.contains(event.target as Node)) {
            event.preventDefault();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string, dataIndex: string) => {
        const newData = [...updatedContent];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, [dataIndex]: e.target.value });
            setUpdatedContent(newData);
            setHasChanges(true);

            const originalIndex = originalContent.findIndex((item) => key === item.key);
            if (originalIndex > -1) {
                const updatedOriginal = [...originalContent];
                updatedOriginal.splice(originalIndex, 1, { ...updatedOriginal[originalIndex], [dataIndex]: e.target.value });
                setOriginalContent(updatedOriginal);
            }
        }
    };

    const getSorter = (a: any, b: any, dataIndex: string) => {
        setActiveSortColumn(dataIndex);
        const valueA = a[dataIndex];
        const valueB = b[dataIndex];

        // Checking if both values can be converted to numbers
        const numA = Number(valueA);
        const numB = Number(valueB);

        // If both values are numbers, performing numeric sorting
        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB; // Ascending order
        }

        // Otherwise, performing string sorting
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
    };

    const hasEmptyColumnTitles = (column: any[]) => {
        return column.slice(1).some(col => col.title.trim() === '');
    };
    // Global Search Handler
    const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {

        const hasEmptyTitle = hasEmptyColumnTitles(columns);

        if (hasEmptyTitle) {
            setGlobalSearchText("")
        } else {
            const value = e.target.value;
            setGlobalSearchText(value);

            if (value) {
                const filteredData = originalContent.filter((item) =>
                    Object.values(item).some((val: any) =>
                        val.toString().toLowerCase().includes(value.toLowerCase())
                    )
                );
                setUpdatedContent(filteredData.map((item, index) => ({ ...item, key: index.toString() })));
            } else {
                setUpdatedContent(originalContent.map((item, index) => ({ ...item, key: index.toString() })));
            }
        }
    };

    // Search functionality per column
    const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setActiveSearchColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
        setActiveSearchColumn(null)
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterDropdownVisible: filterDropdownVisibleMap[dataIndex] || false,
        onFilterDropdownVisibleChange: (visible: boolean) => {
            setFilterDropdownVisibleMap((prevState) => ({
                ...prevState,
                [dataIndex]: visible,
            }));
        },
        render: (text: any) => text,
    });

    const handleSaveAllRows = () => {
        if (hasChanges) {
            const updatedContentWithTag = updatedContent.map(item => ({
                ...item,
                tag: item.tag ?? ""
            }));

            const formattedData = updatedContentWithTag.filter((item) => selectedRowKeys.includes(item.key));
            const formattedDataPayload = formattedData.map(({ key, ...rest }) => rest);
            const allData = updatedContentWithTag.filter(item => !selectedRowKeys.includes(item.key));
            const hasEmptyElements = formattedDataPayload.some(item => !item.name || !item.elementPath);
            if (hasEmptyElements) {
                errorAlert("Empty entry not allowed");
                return;
            }  
            const uniqueNames = new Set();
            const uniquePaths = new Set();
            for (const item of formattedDataPayload) {
                if (uniqueNames.has(item.name) || uniquePaths.has(item.elementPath)) {
                    errorAlert("Only unique entry allowed");
                    return;
                }
                uniqueNames.add(item.name);
                uniquePaths.add(item.elementPath);
            }
            const nameExists = formattedDataPayload.some(newItem =>
                allData.some(existingItem => existingItem.name === newItem.name)
            );
            const pathExists = formattedDataPayload.some(newItem =>
                allData.some(existingItem => existingItem.elementPath === newItem.elementPath)
            );
            // if (nameExists || pathExists) {
            //     nameExists && errorAlert("Name already exists");
            //     pathExists && errorAlert("Element Path  already exists");
            //     return;
            // } else {
                const formatedDataWithid = formattedDataPayload.map((item, index) => {
                    // Remove id key if it's an empty string
                    if (item.id === '') {
                        const { id, ...rest } = item;
                        return rest;
                    }
                    return { ...item };
                });
                DataSourceHandler.SaveCsvFileMetadata(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, hierarchy?.hierarchyName, formatedDataWithid, (res) => {
                    setEditingKeys([]);
                    setSelectedRowKeys([]);
                    successAlert(res)
                    setHasChanges(false);
                    onSave();
                }, (e: any) => {
                    errorHandlerFor400(e)
                })
            // }
        }else{
            errorAlert("No changes to save")
        }
    };

    const handleAddRow = () => {
        const newKey = `new-${Date.now()}`; // Using timestamp to ensure unique key

        // Default columns if content is empty
        const defaultColumns = {
            name: '',
            elementType: '',
            elementCategory: '',
            elementPath: '',
            createdDate: '',
            updatedDate: '',
        };

        // Create empty row based on either existing content structure or default columns
        const emptyRow = content && content[0]
            ? Object.keys(content[0]).reduce((acc: any, key) => {
                acc[key] = '';
                return acc;
            }, { key: newKey })
            : { ...defaultColumns, key: newKey };

        // Add new row at the beginning of the array
        const newData = [emptyRow, ...updatedContent];
        const contentWithKeys = newData.filter(item =>
            item?.key.startsWith('new-')
        ).map((item) => item.key);
        const newOriginalData = [emptyRow, ...originalContent];
        setUpdatedContent(newData);
        setOriginalContent(newOriginalData);
        setEditingKeys(contentWithKeys);
        setSelectedRowKeys(contentWithKeys);
        setCurrentPage(1); // Set to first page
        setHasChanges(true);
    };

    const handleViewRecordVersions = (record: any) => {
        DataSourceHandler.GetAssetRevisions(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, record.id, (res) => {
            if (isEmpty(res)) {
                errorAlert("No revisions found for this record")
            }
            setAssetRevisions(res)
        }, (e: any) => {
            errorAlert(e)
        })
    }

    const handleDeleteSelectedRows = () => {
        setShowConfirmDelete(false);
        const deletedData = updatedContent.filter((item) => selectedRowKeys.includes(item.key));
        const deletedDataPayload = deletedData.map((item) => item.id);
        const deletedDataPayloadWithIds = deletedDataPayload.filter(Boolean);
        const deletedDataPayloadWithEmptyIds = deletedDataPayload.filter(item => item === "");
        if(!isEmpty(deletedDataPayloadWithEmptyIds)){
            setUpdatedContent(updatedContent.filter((item) => !selectedRowKeys.includes(item.key)));
            successAlert("Records deleted successfully")
        }
        if(!isEmpty(deletedDataPayloadWithIds)){
            DataSourceHandler.DeleteCsvFileMetadata(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, deletedDataPayloadWithIds, (res) => {
                onSave();
                setEditingKeys(editingKeys.filter((key) => !selectedRowKeys.includes(key)));
                setSelectedRowKeys([]);
                successAlert(res)
                setHasChanges(false);

            }, (e: any) => {
                errorAlert(e)
            })
        }
    };

    const handleRowSelection = (record: any) => {
        const selectedIndex = selectedRowKeys.indexOf(record.key);
        const newSelectedKeys = [...selectedRowKeys];

        if (selectedIndex > -1) {
            newSelectedKeys.splice(selectedIndex, 1);
        } else {
            newSelectedKeys.push(record.key);
        }

        setSelectedRowKeys(newSelectedKeys);
        setEditingKeys(newSelectedKeys);
    };

    const _updatedContent = updatedContent?.map(({ id, ...rest }) => rest)

    const formatColumnName = (key: string) => {
        const fromCamelCase = key.replace(/([A-Z])/g, ' $1');
        const withSpaces = fromCamelCase.replace(/[_-]/g, ' ');
        return withSpaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')
            .trim();
    };

    const columns = [
        {
            title: '',
            dataIndex: 'selection',
            key: 'selection',
            width: 50,
            render: (_: any, record: any) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.key)}
                    onChange={() => handleRowSelection(record)}
                    disabled = {record.key?.startsWith('new-')}
                />
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            sorter: (a: any, b: any) => getSorter(a, b, 'name'),
            ...getColumnSearchProps('name'),
            onHeaderCell: () => ({
                className: (activeSortColumn === 'name' || activeSearchColumn === 'name') ? 'highlighted-header' : '',
            }),
            render: (text: any, record: any) => {
                const editable = typeof record.key === "string" && record.key.startsWith("new-") ? isEditing(record) : false;
                return editable ? (
                    <Input
                        value={text}
                        onChange={(e) => handleInputChange(e, record.key, 'name')}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                handleInputChange({ target: { value: '' } } as any, record.key, 'name');
                            }
                        }}
                    />
                ) : (
                    <div>{text}</div>
                );
            }
        },
        {
            title: 'Element Type',
            dataIndex: 'elementType',
            key: 'elementType',
            width: 150,
            sorter: (a: any, b: any) => getSorter(a, b, 'elementType'),
            ...getColumnSearchProps('elementType'),
            onHeaderCell: () => ({
                className: (activeSortColumn === 'elementType' || activeSearchColumn === 'elementType') ? 'highlighted-header' : '',
            }),
            render: (text: any, record: any) => {
                const editable = isEditing(record);
                return editable ? (
                    <Select
                        value={text || undefined}
                        onChange={(value) => handleInputChange({ target: { value } } as any, record.key, 'elementType')}
                        style={{ width: '100%' }}
                    >
                        <Select.Option value="Model">Model</Select.Option>
                        <Select.Option value="Attribute">Attribute</Select.Option>
                    </Select>
                ) : (
                    <div>{text}</div>
                );
            }
        },
        {
            title: 'Element Category',
            dataIndex: 'elementCategory',
            key: 'elementCategory',
            width: 150,
            sorter: (a: any, b: any) => getSorter(a, b, 'elementCategory'),
            ...getColumnSearchProps('elementCategory'),
            onHeaderCell: () => ({
                className: (activeSortColumn === 'elementCategory' || activeSearchColumn === 'elementCategory') ? 'highlighted-header' : '',
            }),
            render: (text: any, record: any) => {
                const editable = isEditing(record);
                const isModel = record.elementType === 'Model';
                if (editable && isModel) {
                    return (
                            <Select
                                mode='combobox'
                                value={text || undefined}
                                onChange={(value) => handleInputChange({ target: { value } } as any, record.key, 'elementCategory')}
                                style={{ width: '100%', marginTop: '5px' }}
                            >
                                {modelList.map((model: any) => (
                                    <Select.Option key={model.id} value={model.name}>
                                        {model.name}
                                    </Select.Option>
                                ))}
                            </Select>
                    );
                }
        
                return editable ? (
                    <Input
                        value={text}
                        onChange={(e) => handleInputChange(e, record.key, 'elementCategory')}
                    />
                ) : (
                    <div>{text}</div>
                );
            }
        },
        {
            title: 'Element Path',
            dataIndex: 'elementPath',
            key: 'elementPath',
            width: 200,
            sorter: (a: any, b: any) => getSorter(a, b, 'elementPath'),
            ...getColumnSearchProps('elementPath'),
            onHeaderCell: () => ({
                className: (activeSortColumn === 'elementPath' || activeSearchColumn === 'elementPath') ? 'highlighted-header' : '',
            }),
            render: (text: any, record: any) => {
                const editable = typeof record.key === "string" && record.key.startsWith("new-") ? isEditing(record) : false;
                return editable ? (
                    <Input
                        value={text}
                        onChange={(e) => handleInputChange(e, record.key, 'elementPath')}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                handleInputChange({ target: { value: '' } } as any, record.key, 'elementPath');
                            }
                        }}
                    />
                ) : (
                    <div>{text}</div>
                );
            }
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            width: 100,
            sorter: (a: any, b: any) => getSorter(a, b, 'tag'),
            ...getColumnSearchProps('tag'),
            render: (text: any, record: any) => {
                const editable = isEditing(record);
                const isModel = record.elementType === 'Attribute';

                return editable && isModel ? (
                    <Input
                        value={text}
                        onChange={(e) => handleInputChange(e, record.key, 'tag')}
                    />
                ) : (
                    <div>{text}</div>
                );
            }
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            key: 'uom',
            width: 150,
            sorter: (a: any, b: any) => getSorter(a, b, 'uom'),
            ...getColumnSearchProps('uom'),
            onHeaderCell: () => ({
                className: (activeSortColumn === 'uom' || activeSearchColumn === 'uom') ? 'highlighted-header' : '',
            }),
            render: (text: any, record: any) => {
                const editable = isEditing(record);
                return editable ? (
                    <Input
                        value={text}
                        onChange={(e) => handleInputChange(e, record.key, 'uom')}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                handleInputChange({ target: { value: '' } } as any, record.key, 'uom');
                            }
                        }}
                    />
                ) : (
                    <div>{text}</div>
                );
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            width: 150,
            sorter: (a: any, b: any) => getSorter(a, b, 'createdDate'),
            ...getColumnSearchProps('createdDate'),
            render: (text: any) => text ? moment(text).format('MM/DD/YYYY HH:mm:ss') : ''
        },
        {
            title: 'Updated Date',
            dataIndex: 'updatedDate',
            key: 'updatedDate',
            width: 150,
            sorter: (a: any, b: any) => getSorter(a, b, 'updatedDate'),
            ...getColumnSearchProps('updatedDate'),
            render: (text: any) => text ? moment(text).format('MM/DD/YYYY HH:mm:ss') : ''
        },
        {
            title: 'Action',
            key: 'operation',
            width: 50,
            render: (record: any) => (
                <TooltipTop title="View this record versions">
                    <button className={styles.versionButton} onClick={() => handleViewRecordVersions(record)}>
                        <div className={styles.center}>
                            <TbVersionsFilled size={"24px"} />
                        </div>
                    </button>
                </TooltipTop>
            ),
        }
    ];

    const generalizedColumns = [
        {
            title: '',
            dataIndex: 'selection',
            key: 'selection',
            // width: 50,
            render: (_: any, record: any) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.key)}
                    onChange={() => handleRowSelection(record)}
                    disabled={record.key?.startsWith('new-')}
                />
            )
        },
        ...tableColumns.map((column) => {
            const existingColumn = columns.find((col) => col.dataIndex === column.value);
            if (existingColumn) {
                return existingColumn;
            }

            return {
                title: column.label,
                dataIndex: column.value,
                key: column.value,
                width: 150,
                sorter: (a: any, b: any) => getSorter(a, b, column.value),
                ...getColumnSearchProps(column.value),
                onHeaderCell: () => ({
                    className: (activeSortColumn === column.value || activeSearchColumn === column.value) ? 'highlighted-header' : '',
                }),
                render: (text: any, record: any) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <Input
                            value={text}
                            onChange={(e) => handleInputChange(e, record.key, column.value)}
                            onBlur={(e) => {
                                if (e.target.value === '') {
                                    handleInputChange({ target: { value: '' } } as any, record.key, column.value);
                                }
                            }}
                        />
                    ) : (
                        <div>{text}</div>
                    );
                }
            };
        }),
        {
            title: 'Action',
            key: 'operation',
            // width: 50,
            render: (record: any) => (
                <TooltipTop title="View this record versions">
                    <button className={styles.versionButton} onClick={() => handleViewRecordVersions(record)}>
                        <div className={styles.center}>
                            <TbVersionsFilled size={"24px"} />
                        </div>
                    </button>
                </TooltipTop>
            ),
        }
    ];

    const handleRevertChangeModal = (record: any) => {
        setSelectedRecord(record); 
        setShowConfirmRevision(true);
    };

    const handleRevertChangesPreview = (record: any) => {
        DataSourceHandler.RevertAssetRevisionPreview(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, record.id, record.revisionNumber, hierarchy?.id, (res) => {
            setShowConfirmRevision(false);
            setPreviewData(res)
            setRecordForRevision(record)
            setIsRevision(true);
            setShowPreview(true)
        }, (e: any) => {
            errorAlert(e)
            setShowConfirmRevision(false);
        })

    }
    const handleRevertChanges = (record: any) => {
        DataSourceHandler.RevertAssetRevision(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, record.id, record.revisionNumber, (res) => {
            successAlert(res)
            setAssetRevisions([])
            setIsRevision(false)
            onSave();
        }, (e: any) => {
            errorAlert(e)
            setIsRevision(false)
        })
    }

    const assetRevisionsColumns = [
        ...Object.keys(assetRevisionsData?.[0] || {})
            .filter(key => !['revisionNumber', 'id', 'revisionDate'].includes(key))
            .map((key) => ({
                title: formatColumnName(key),
                dataIndex: key,
                render: (text: any) => {
                    if (['createdDate', 'updatedDate'].includes(key) && text) {
                        return moment(text).format('MM/DD/YYYY HH:mm:ss');
                    }
                    return text;
                },
                key: key,
                width: Math.max(150, key.length * 10),
            })),
        {
            title: 'Action',
            key: 'operation',
            width: 50,
            render: (record: any) => (
                <>
                   <TooltipTop title="Revert to this revision">
                    <button className={styles.versionButton} onClick={() => handleRevertChangeModal(record)}>
                        <div className={styles.center} >
                            <MdOutlineUndo size={"24px"} />
                        </div>
                    </button>
                    </TooltipTop>
                  <DeleteConfirm
                        onConfirm={()=>handleRevertChangesPreview(selectedRecord)}
                        showModal={showConfirmRevision}
                        title={"Are you sure you want to revert to this revision?"}
                        toggleClose={() => {
                        setShowConfirmRevision(false);
                        }}
                    />
                </>
            ),
        }
    ];

    const handleDateChange = () => {
        DataSourceHandler.RevertAssetTableToDate(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, hierarchy?.id, moment(revertDate).utc().format('MM/DD/YYYY HH:mm:ss'), (res) => {
            successAlert(res)
            onSave();
            setIsRevision(false)
        }, (e: any) => {
            errorHandlerFor400(e)
            setIsRevision(false)
        })
    }
    const handleDateChangePreview = () => {
        DataSourceHandler.RevertAssetTableToDatePreview(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`, hierarchy?.id, moment(revertDate).utc().format('MM/DD/YYYY HH:mm:ss'), (res) => {
            setShowConfirmRevertToDate(false);
            setPreviewData(res)
            setIsRevision(false);
            setShowPreview(true);
        }, (e: any) => {
            errorHandlerFor400(e);
            setShowConfirmRevertToDate(false);
        })

    }

    const handleClosePreview = () => {
        setIsRevision(false);
        setShowPreview(false);
        setPreviewData([]);
    }

    const handleSavePreview = () => {
        if (isRevision) {
            handleRevertChanges(recordForRevision)
        } else {
            handleDateChange()
        }
        setIsRevision(false);
        setShowPreview(false);
        setPreviewData([]);
    }


    return (
        <div className={styles.fileMetaDataContainer}>
            <ShowWhenTrue show={isEmpty(assetRevisions)}>
                <div className={styles.actionPanel}>
                    <div className={styles.leftActions}>

                        <button
                            className={classNames(
                                "btn-lg btn-yellow-transparent",
                                styles["center"],
                                styles["btn-lg"]
                            )}
                            onClick={handleAddRow}
                        >
                            <img src="/icons/workflow/add.svg" alt="" />
                            <span> Record </span>
                        </button>
                        {selectedRowKeys.length > 0 && (
                            <>

                                <button
                                    className={classNames(
                                        "btn-lg btn-yellow-transparent",
                                        styles["center"],
                                        styles["btn-lg"]
                                    )}
                                    onClick={handleSaveAllRows}
                                >
                                    <span> Save Selected </span>
                                </button>
                                <DeleteConfirm
                                    onConfirm={handleDeleteSelectedRows}
                                    showModal={showConfirmDelete}
                                    title={"Are you sure you want to delete these rows?"}
                                    toggleClose={() => {
                                        setShowConfirmDelete(false);
                                    }}
                                />
                                <button
                                    className={classNames(
                                        "btn-lg btn-red",
                                        styles["center"],
                                        styles["btn-lg"]
                                    )}
                                    onClick={()=>setShowConfirmDelete(true)}
                                >
                                    <span> Delete Selected </span>
                                </button>
                            </>
                        )}
                        <button
                            className={classNames(
                                styles["pullPushButton"],
                                styles["center"],
                                "btn btnWt btn-grey btn-refresh-component"
                            )}
                            onClick={()=>handlePushPull('pull')}
                            disabled={loadingState.pull}
                        >
                            {loadingState.pull ? <Spin size="default" /> : <span>Pull</span>}
                        </button>
                        <button
                            className={classNames(
                                styles["pullPushButton"],
                                styles["center"],
                                "btn btnWt btn-grey btn-refresh-component"
                            )}
                            onClick={()=>handlePushPull('push')}
                            disabled={loadingState.push}
                        >
                            {loadingState.push ? <Spin size="default" /> : <span>Push</span>}
                        </button>
                        <button
                            className={classNames(
                                "btn btnWt btn-grey btn-refresh-component",
                            )}
                            type="button"
                            onClick={onSave}
                        >
                            <Flex alignItems={"center"} gap="6px">  <MdRefresh />Refresh Data</Flex>
                        </button>
                    </div>
                    <div className={styles.rightActions}>
                        <Flex flexDirection={"row"} alignItems={"center"} gap="10px">
                            <Text fontSize={"14px"} fontWeight={"400"} width={"60px"}>Revert to </Text>
                            <DatePicker showTime format={"MM/DD/YYYY HH:mm:ss"} value={revertDate} onChange={(date) => setRevertDate(date)} />
                                <button className={classNames(
                                    "btn-md btn-red",
                                    styles["center"],
                                )}
                                onClick={()=>setShowConfirmRevertToDate(true)}
                                >Apply</button>
                            <DeleteConfirm
                                onConfirm={()=>handleDateChangePreview()}
                                showModal={showConfirmRevertToDate}
                                title={"Are you sure you want to revert to this date?"}
                                toggleClose={() => {
                                    setShowConfirmRevertToDate(false);
                                }}
                            />
                            <Input
                                placeholder="Search..."
                                style={{ width: 300 }}
                                value={globalSearchText}
                                onChange={handleGlobalSearch}
                                className={styles.searchInput}
                            />
                        </Flex>
                    </div>
                </div>
                <div className={styles.tableWrapper}>
                    <Table
                        columns={generalizedColumns}
                        dataSource={updatedContent}
                        size='small'
                        pagination={{
                            current: currentPage,
                            // pageSize: pageSize,
                            total: updatedContent?.length,
                            onChange: (page) => setCurrentPage(page),
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '50', '100', '250'],
                            className: styles.tablePagination
                        }}
                        className={styles.dataTable}
                        rowClassName={(record) =>
                            `${styles.tableRow} ${selectedRowKeys.includes(record.key) ? styles.selectedRow : ''}`
                        }
                        locale={{
                            emptyText: (
                                <div className={styles.emptyState}>
                                    {updatedContent?.length === 0
                                        ? 'No data available'
                                        : 'No matching results found'}
                                </div>
                            )
                        }}
                    />
                </div>
            </ShowWhenTrue>
            <FilePreviewModal isOpen={showPreview} onClose={() => handleClosePreview()} data={previewData} isRevisions={isRevision} onSave={() => handleSavePreview()} />
            <ShowWhenTrue show={!isEmpty(assetRevisions)}>
                <div className={styles.assetRevisionsContainer}>

                    <Flex flexDirection={"row"} alignItems={"center"} style={{ cursor: "pointer", marginBottom: "20px" }} gap="10px" onClick={() => setAssetRevisions([])}><BsArrowLeft />  <h3 className="white"> Revisions </h3>   </Flex>
                    <Table
                        columns={assetRevisionsColumns}
                        size='small'
                        dataSource={assetRevisionsData}
                        className={styles.dataTableRevert}
                    />
                </div>
            </ShowWhenTrue>
        </div>
    );
};

export default FileMetaDataTable;