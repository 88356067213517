/* eslint-disable no-console */
import { Modal } from '../../../components/modals';
// import { object, string, InferType } from 'yup';
import React, { useRef, useState, useEffect } from 'react';
import {
    toggleModal,
    setActiveUpdataParamter,
    setcomponentParamenters,
    setCustomComponentUpdateId,
    setCustomComponentName,
} from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import {
    NewComponentResponse,
    newComponentHandler,
    CreateNewComponentResponse,
} from '../../../api/new-custom-component';
import MonacoEditor from 'react-monaco-editor';
import 'monaco-editor/esm/vs/basic-languages/monaco.contribution';
import 'monaco-editor/esm/vs/editor/edcore.main';
import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
import 'monaco-editor/esm/vs/editor/standalone/browser/standaloneServices';
import 'monaco-editor/esm/vs/language/json/monaco.contribution';
import {
    successAlert,
    errorAlert,
} from '../../../components/toastify/notify-toast';
import { Row, Col } from 'antd';
import { useDebounce } from 'rooks';
import { getInitialCustombuilderValues } from '../enums';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import { ExecutionEnvModes } from '../../../constants/enums';
import { getUniqueId } from '@utils/common';

interface ComponentEditorModalProps {
  propsEditItem?: any;
  handleComponentEditorSuccess: () => void;
}
interface ItemInterface {
  code: string;
}

const options: any = {
    selectOnLineNumbers: true,
    contextmenu: true,
    formatOnPaste: true,
    accessibilitySupport: 'auto',
    autoIndent: 'full',
    folding: true,
    highlightActiveIndentGuide: true,
    quickSuggestions: true,
    formatOnType: true,
    automaticLayout: true,
    tabSize: 4,
};

export const ComponentEditorModal: React.FC<ComponentEditorModalProps> = ({
    handleComponentEditorSuccess,
}) => {
    const showModal = useSelector(
        (store: RootState) => store.WorkflowReducer.showModal.customEditor
    );
    const componentParamenters = useSelector(
        (store: RootState) => store.WorkflowReducer.componentParamenters
    );
    const dispatch = useDispatch();
    const [language] = useState('python');
    const monacoRef = useRef<any>({});
    const editorRef = useRef<any>();
    const [defaultCode, isDefaultCode] = useState<string>('');
    const [rangeLimit, isRangeLimit] = useState<number>(0);
    const updateComponentId = useSelector(
        (store: RootState) => store.WorkflowReducer.customComponentUpdateId
    );
    const customComponentName = useSelector(
        (store: RootState) => store.WorkflowReducer.customComponentName.name
    );
    const { activeExecutionEnv } = useSelector(
        (store: RootState) => store.CommonReducer
    );
    const [showSpinner, toggleSpinner] = useState(false);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    function toggleClose(resetState: boolean | any = false) {
        dispatch(toggleModal('customEditor', false));
        // dispatch(setActiveUpdataModalType(false));
        dispatch(setActiveUpdataParamter(true));
        if(resetState === true) {
            setTimeout(() => {
                toggleSpinner(false);
                dispatch(setcomponentParamenters(getInitialCustombuilderValues()));
                dispatch(setCustomComponentUpdateId(0));
                dispatch(setCustomComponentName({ name: '' }));
            }, 300);
        }
    
    }

    function setArgString(params: any[]) {
        let listString = '';
        let listArgparam = '';
        for (let i = 0; i < params.length; i++) {
            // const argString = `# arg${i} = { "title": "${params[i].title}", "qtip": "${params[i].title}", "default_value":"${params[i].defaultValue}", "type":"${params[i].typeOf}", "variable_type":"string", "value": "", "required" : true }\r\n`;
            const argString = `# ${params[i].name} = { "title": "${params[i].title}", "qtip": "${params[i].tooltip}", "default_value":"${params[i].defaultValue}", "type":"${params[i].typeOf}", "required" : ${params[i].required} }\r\n`;
            listString = listString.concat(argString);
            const argParamString = `,${params[i].name}`;
            listArgparam = listArgparam.concat(argParamString);
        }
        return [listString, listArgparam];
    }

    const codeForEditor = () => {
        const arg_string = setArgString(componentParamenters.argList);
        let dfList: any = componentParamenters.inputList;
        if (dfList) {
            dfList = dfList.join(',');
        }
        let outputList: any = componentParamenters.outputList;
        if (outputList) {
            outputList = outputList.join(',');
        }
        const importString = componentParamenters.importCode;
        const importStringArr = (importString || '').split('\n');
        const importCodeString = importStringArr.join('\r\n') || '';
        const _rangeLimit = importCodeString === '' ? 3 : importStringArr.length + 3;
        isRangeLimit(_rangeLimit);
        const fn_str = componentParamenters.functionString;
        let fn_def = '';
        let fn_exceptions = '';
        if (activeExecutionEnv === ExecutionEnvModes.Spark || activeExecutionEnv === ExecutionEnvModes.Streaming) {
            fn_def = `def ${componentParamenters.functionName}(${dfList}${
                dfList.length > 0 ? ',' : ''
            }spark${arg_string[1]},function_type = ${JSON.stringify(
                componentParamenters.functionType
            )}):\r\n`;
            fn_exceptions = '@function_type_validation\r\n@log_exceptions\r\n';
        } else if (activeExecutionEnv === 'python') {
            const _arg_string = dfList.length === 0 ?  arg_string[1].replace(',','') : arg_string[1];
            fn_def = `def ${componentParamenters.functionName}(${dfList}${_arg_string}):\r\n`;
            fn_exceptions = '\r\n@log_exceptions\r\n';
        }
        const code_string =
      (importCodeString === '' ? '' : `${importCodeString}\r\n`) +
      `${fn_exceptions}${fn_def}${fn_str}`;
        isDefaultCode(code_string);
    };

    useEffect(() => {
        codeForEditor();
    }, [componentParamenters]);

    function handleEditorDidMount(editor: any, monaco: any) {
        editorRef.current = editor;
        monacoRef.current = monaco;
        monaco.languages.registerDocumentFormattingEditProvider('python', {
            async provideDocumentFormattingEdits(model: any) {
                const prettier = await import('prettier/standalone');
                const babylon = await import('prettier/parser-babylon');
                const text = prettier.format(model.getValue(), {
                    parser: 'babylon',
                    plugins: [babylon],
                    singleQuote: true,
                });
                return [
                    {
                        range: model.getFullModelRange(),
                        text,
                    },
                ];
            },
        });

        editor.addAction({
            id: 'undo_model',
            label: 'Undo',
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_Z],
            contextMenuGroupId: '9_cutcopypaste',
            contextMenuOrder: 0,
            precondition: 'undo',
            run: () => {
                editor.trigger('', 'undo');
            },
        });

        editor.addAction({
            id: 'redo_model',
            label: 'Redo',
            keybindings: [
                monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KEY_Z,
            ],
            contextMenuGroupId: '9_cutcopypaste',
            contextMenuOrder: 0,
            precondition: 'redo',
            run: () => {
                editor.trigger('', 'redo');
            },
        });
        // const arg_string = setArgString(componentParamenters.argList);
        let dfList: any = componentParamenters.inputList;
        if (dfList) {
            dfList = dfList.join(',');
        }
        let outputList: any = componentParamenters.outputList;
        if (outputList) {
            outputList = outputList.join(',');
        }
        // const importString = componentParamenters.importCode;
        // const importStringArr = importString.split('\n').map(function(item) {
        //     return item.trim();
        // });
        // const importCodeString = importStringArr.join('\r\n') || '';
        editor.onDidChangeModelContent((event: any) => {
            if (!event.isUndoing) {
                const doUndo = () =>
                    Promise.resolve().then(() => {
                        editorRef.current.getModel().undo();
                        // jsDestContainer.trigger('someIdString', 'undo')
                    });
                if (
                    event.changes[0].range.startLineNumber <= rangeLimit) {
                    doUndo();
                }
            }
        });

    }

    function handleCreateComponentSuccessResponse(
        response: CreateNewComponentResponse
    ) {
        if (response.success) {
            handleComponentEditorSuccess();
            // dispatch(getComponentTreeData());
            successAlert(response.message);
            toggleClose(true);
            // dispatch(setcomponentParamenters(getInitialCustombuilderValues()));
            // dispatch(setCustomComponentName({ name: '' }));
        }
    }

    function handleErrorCallback(error: any) {
        if (!error.data.success) {
            errorAlert(error.data.message);
        } else {
            errorAlert(error.response.data.message);
        }
    }
    function handleEditComponentSuccessResponse(response: NewComponentResponse) {
        toggleClose(true);
        handleComponentEditorSuccess();
        successAlert(response.message);
    }
    function handleEditErrorCallback(error: any) {
        // toggleClose();
        toggleSpinner(false);
        errorAlert(error.response.data.message);
    }

    function handleUpdateCustomComponent(payload: any) {
        newComponentHandler.updateCustomCompoent(
            `${Env?.REACT_APP_PLATFORM_URL}/platform/api`,
            updateComponentId,
            payload,
            activeExecutionEnv,
            handleEditComponentSuccessResponse,
            handleEditErrorCallback
        );
    }

    const setParamtersOnChange = useDebounce((_payload: any, btnType: string) => {
        let compFormData: any = {};
        const EditorValue = editorRef.current.getValue();
        const functionStrArray = EditorValue.split('\r\n');
        const importString = componentParamenters.importCode;
        const importStringArr = (importString || '').split('\n');
        const importCodeString = importStringArr.join('\r\n') || '';
        let _fnStr: any = functionStrArray.slice(
            activeExecutionEnv === 'python' && importCodeString.length === 0
                ? 3
                : rangeLimit,
            functionStrArray.length
        );
        _fnStr = _fnStr.join('\r\n');
        compFormData = {
            ...componentParamenters,
            ...{
                functionString: _fnStr,
                name: componentParamenters.name || customComponentName,
            },
        };
        dispatch(setcomponentParamenters(compFormData));
        if (btnType === 'edit') {
            dispatch(setActiveUpdataParamter(true));
            dispatch(toggleModal('customComoponentParameters', true));
            toggleClose();
        } else if (btnType === 'save') {
 
            if (updateComponentId !== 0) {
                const payload = {
                    payload: editorRef.current.getValue(),
                    formData: compFormData,
                };
                toggleSpinner(true);
                if (customComponentName !== compFormData.name) {
                    newComponentHandler.renameCustomComponent(
                        `${Env?.REACT_APP_PLATFORM_URL}/platform/api`,
                        updateComponentId,
                        { name: compFormData.name },
                        activeExecutionEnv,
                        () => handleUpdateCustomComponent(payload),
                        handleEditErrorCallback
                    );
                }
                else handleUpdateCustomComponent(payload);
            } else {
                const payload = {
                    name: compFormData.name,
                    payload: _payload,
                    formData: {
                        ...compFormData,
                        key: getUniqueId()
                    },
                    env: activeExecutionEnv,
                };
                toggleSpinner(true);
                newComponentHandler.CreateComponent(
                    `${Env?.REACT_APP_PLATFORM_URL}/platform/api`, 
                    payload,
                    handleCreateComponentSuccessResponse,
                    handleErrorCallback
                );
            }
        }
    }, 300);

    function listenEditorChanges() {
        setParamtersOnChange(editorRef.current.getValue(), 'save');
    }

    function updateOptions() {
        setParamtersOnChange(editorRef.current.getValue(), 'edit');
    }

    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="Editor-modal"
            title="Editor"
            image="/icons/editor/icon4.png"
        >
            
            <MonacoEditor
                height="75vh"
                width="90vw"
                language={language}
                theme={'vs-dark'}
                // onChange = {onChanageListerner}
                value={defaultCode}
                options={options}
                editorDidMount={handleEditorDidMount}
            />
            <InPageSpinner
                size="Large"
                hide={!showSpinner}
            />
            <Row gutter={[24, 8]}>
                <Col span={8}>
                    <div className=" editormodelBtns_box edit-parameter-btn">
                        <button
                            id="editormodel_saveBtn"
                            className="fl-r btn-md btn-yellow"
                            onClick={updateOptions}
                            disabled={showSpinner}
                        >
              Edit Parameters
                        </button>
                    </div>
                </Col>
                <Col span={16}>
                    <div className="modalBtns__box editormodelBtns_box">
                        <button
                            id="editormodel_saveBtn"
                            className="fl-r btn-md btn-yellow"
                            onClick={listenEditorChanges}
                            disabled={showSpinner}
                        >
              Save
                        </button>
                        <button
                            className="btn-md btn-cancel"
                            type="button"
                            onClick={toggleClose}
                        >
              Cancel
                        </button>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};
