/* eslint-disable @typescript-eslint/no-use-before-define */
import { WorkflowConfig } from '@services/WorkflowConfig';
import { RootState } from '@store/types';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {  useInterval } from 'rooks';
import { CaptureSchemaPayload, CaptureSchemaResponse, WorkflowHandler } from '../api/workflow-handler';
import { errorAlert } from '../components/toastify/notify-toast';
import { WorkflowCanvas } from '../components/workflow-canvas';
import { DsLinkModel } from '../components/workflow-canvas/link/ds_linkmodel';
import { DsNodeModel } from '../components/workflow-canvas/node/ds_nodemodel';
import { ExecutionEnvModes } from '../constants/enums';
// import { Env } from '../constants/settings';
import { checkIfAllLinksAreConnected, convertWorkflowDataForExecution, WorkflowUserinfoForNodeData } from '../pages/workflow-page/utils';

interface Props {
	componentId: string;
	toggleSchemaCaptureInProgress: React.Dispatch<React.SetStateAction<boolean>>;
	handleSchemaCaptureSuccess: (arg0: CaptureSchemaResponse[]) => any;
	handleSchemaCaptureFailure: () => any;
    activeExecutionEnv: ExecutionEnvModes;
    workflowUserInfo: WorkflowUserinfoForNodeData;
    workflowConfig: WorkflowConfig | undefined;
    clusterId: string | undefined;
}

export const useSchemaCapture = ({ componentId, toggleSchemaCaptureInProgress, handleSchemaCaptureSuccess, handleSchemaCaptureFailure, activeExecutionEnv, workflowUserInfo, clusterId, workflowConfig }: Props) => {
    const sessionIdRef = useRef<string | null>(null);
    const [connectedNodesInfo, setConnectedNodesInfo] = useState<Record<string, string>>({});
    const { clusters } = useSelector((store: RootState) => store.ClusterReducer);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const link = Env.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
    const handleSchemaCaptureResponse = (response: CaptureSchemaResponse[]) => {
        let areAllComponentsExecuted = true;
        let failure = false;
        response.some(nodeDataframeInfo => {
            if(nodeDataframeInfo.state === 'failed' ) {
                failure = true;
                stop();
                toggleSchemaCaptureInProgress(false);
                handleSchemaCaptureFailure();
            }
            else if (nodeDataframeInfo.state === 'running' || nodeDataframeInfo.state === 'starting') {
                areAllComponentsExecuted = false;
                return true;
            }
        });
        if (areAllComponentsExecuted && !failure) {
            stop();
            toggleSchemaCaptureInProgress(false);
            handleSchemaCaptureSuccess(response);
        }
    };

    const makeSchemaCaptureCall = () => {
        sessionIdRef.current &&
			WorkflowHandler.GetCaptureSchemaData(link, sessionIdRef.current, handleSchemaCaptureResponse);
    };

    const { start, stop } = useInterval(makeSchemaCaptureCall, 3000);

    const handleInitiateCaptureSchemaPolling = (
        response: CaptureSchemaResponse[]
    ) => {
        sessionIdRef.current = response[0].jobId;
        toggleSchemaCaptureInProgress(true);
        start();
    };

    const handleSetConnectedNodesInfo = () => {
        const __connectedNodesInfo: Record<string, string> = {};
        const currentNodeInfo = WorkflowCanvas.getNode(componentId) as DsNodeModel;
        if(currentNodeInfo) {
            const inPorts = currentNodeInfo.getInPorts();
            inPorts.forEach(port => {
                const connectedLinks = port.getLinksInArray();
                if (!isEmpty(connectedLinks)) {
                    const firstLinkInfo = connectedLinks[0] as DsLinkModel;
                    const connectedNodeId = firstLinkInfo.getConnectedNode(componentId)?.getID();
                    if (connectedNodeId && WorkflowCanvas.getNode(connectedNodeId)?.getOptions().nodeType !== 'variable')
                        __connectedNodesInfo[connectedNodeId] = WorkflowCanvas.getNodeTitle(
                            connectedNodeId
                        );
                }
            });
            setConnectedNodesInfo(__connectedNodesInfo);
        }
    };
	
    const initiateSchemaCapture = (removeLimit = false) => {
        // re-run schema capture when user clicks on populate schema button if fields are already shown
        const link = Env.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
        if (checkIfAllLinksAreConnected()) {
            if(Env?.REACT_APP_DATABRICKS && !clusterId) {
                errorAlert('No default cluster found, make a cluster default');
                return false;
            }
            const selectedClusterInfo = clusters.find(cluster => cluster.clusterId === clusterId);

            const payload = { ...convertWorkflowDataForExecution(WorkflowCanvas.model, false, activeExecutionEnv, workflowUserInfo, workflowConfig) };
            payload.nodes = payload.nodes.map(node => {
                if (node.id === componentId) {
                    node.showTillHere = true;
                }
                return node;
            });
            const data: CaptureSchemaPayload = {
                payload,
                clusterId,
                removeLimit,
                env: activeExecutionEnv,
                workspaceId: selectedClusterInfo?.workspaceId,
                workspaceType: selectedClusterInfo?.workspaceType
            };
            handleSetConnectedNodesInfo();
            WorkflowHandler.CaptureSchema(
                link,
                data,
                handleInitiateCaptureSchemaPolling
            );
            return true;
        } return false;
    };

    const handleCancelSuccess = () => {
        toggleSchemaCaptureInProgress(false);
    };

    const cancelSchemaCapture = () => {
        stop();
        sessionIdRef.current &&
			WorkflowHandler.CancelCaptureSchema(link, sessionIdRef.current, handleCancelSuccess);
    }; 

    useEffect(() => {
        handleSetConnectedNodesInfo();
        return () => stop();
    }, []);
	
    return { 
        sessionId: sessionIdRef.current,
        initiateSchemaCapture, 
        cancelSchemaCapture, 
        connectedNodesInfo
    };
};
