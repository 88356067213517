/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */

import {FileBrowser, FileView} from 'chonky';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Modal } from '../modals';
import { FileHandler, FileListResponse,  BaseFileInfo } from '../../api/file-handler';
import { FileData  } from 'chonky/lib/typedef';
import 'chonky/style/main.css';
import isEmpty from 'lodash/isEmpty';
import { API } from '../../constants/settings';
import { ServerFileUploadModal } from '../../pages/file-management/modals/serverFileUpload';
import moment from 'moment';
import queryString from 'query-string';
import { downloadFileUsingLink } from '../../helpers/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

// phash in elfinder for initial directories is _A
const ROOT_FOLDER_ID = 'A_';

interface FileBrowserProps {
    initialPath?: string;
    show: boolean;
    toggleClose: () => any;
    onFileSelection: (arg0: string) => any;
    showAsModal: boolean;
    readOnly?: boolean;
}


export const FileBrowserComponent: React.FC<FileBrowserProps> = ({ show, toggleClose, onFileSelection, initialPath = '', showAsModal, readOnly = false }) => {
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const [fileMap, setFileMap] = useState<Record<string, FileData>>({ [ROOT_FOLDER_ID]: {id: ROOT_FOLDER_ID, name: Env?.REACT_APP_ROOT_FOLDER_PATH_FOR_FILE_BROWSER, isDir: true, childrenIds: [] } });
    const [currentFolderId, setCurrentFolderId] = useState(ROOT_FOLDER_ID);
    const [filesToBeShown, setFilesToBeShown] = useState<(FileData | null)[]>([null, null]);
    const [folderChain, setFolderChain] = useState<FileData[]>([]);
    const [selectedFileInfo, setSelectedFileInfo] = useState({ path: '', name: '' });
    const fileMapRef = useRef(fileMap);
    const fileBrowserRef = useRef<FileBrowser>(null);
    const [showUploadModal, toggleUploadModal] = useState(false);
    const [showLoader, toggleLoader] = useState(true);

    useEffect(() => {
        fileMapRef.current = fileMap;
    }, [fileMap]);


    const parseApiResponse = (folderId: string, filesList: BaseFileInfo[]): Promise<typeof fileMap> => {
        return new Promise((resolve) => {
            const childrenIds: string[] = [];
            const updatedFileMapInfo: Record<string, FileData> = { ...fileMapRef.current };
    
            // console.log(folderId)
            filesList.forEach(file => {
                if(file && file.hidden === 0) {
                    if(!(updatedFileMapInfo[file.hash])) {
                        // update info of files which are not in the fileMap
                        const fileInfo: FileData = { id: file.hash, name: file.name, isDir: false, size: file.size, parentId: file.phash, modDate: new Date(file.ts) };
                        if(file.mime === 'directory') fileInfo.isDir = true;
                        updatedFileMapInfo[fileInfo.id] = fileInfo;
                    }
                    childrenIds.push(file.hash);
                }
            });
            if(updatedFileMapInfo[folderId])
                updatedFileMapInfo[folderId].childrenIds = childrenIds;
            toggleLoader(false);

            setFileMap(updatedFileMapInfo);
            resolve(updatedFileMapInfo);
        });
    };

    const loadInitialPath = async (_filemap: typeof fileMap) => {
        if(initialPath){
            initialPath = initialPath.replace('/'+Env?.REACT_APP_ROOT_FOLDER_PATH_FOR_FILE_BROWSER+'/', '');
            let currentFilePath = initialPath.split('/');
            // if(initialFilePath.length !== 1) {
            //     Object.values(_filemap).some(__fileInfo => {
            //         if(__fileInfo.name.toLowerCase() === initialFilePath[0].toLowerCase()) {
            //             handleFileOpen(__fileInfo);
            //             return true;
            //         }
            //     });
            // }
            while(!isEmpty(currentFilePath)) {
                const currentItemName = currentFilePath[0].toLowerCase();
                const currentItemInfo = Object.values(_filemap).find(__fileInfo => __fileInfo.name.toLowerCase() === currentItemName);
                if(currentItemInfo) {
                    if(currentItemInfo.isDir) {
                        const response = await FileHandler.GetFilesOfDirectory(currentItemInfo.id);
                        const updatedMap = await parseApiResponse(currentItemInfo.id, response.data.files);
                        if(updatedMap) {
                            _filemap = updatedMap;
                            setCurrentFolderId(currentItemInfo.id);
                            currentFilePath = currentFilePath.slice(1,);
                        } else {
                            break;
                        }
                    } else {
                        handleSelectFile(currentItemInfo);
                        fileBrowserRef.current?.setSelection({ [currentItemInfo.id]: true });
                        break;
                    }

                } else {
                    console.log('Could not parse the initial path');
                    break; 
                } 
                

            }
        }   
        
    };
    
    const handleLoadInitialFolders = (response: FileListResponse) => {
        parseApiResponse(ROOT_FOLDER_ID, response.files)
            .then(updatedFileMap => {
                if(initialPath) loadInitialPath(updatedFileMap);
            });
        // parseApiResponse(ROOT_FOLDER_ID, response.files);
    };

    // const handleFolderOpenSuccess = (folderId: string, response: FileListResponse) => {
    //     parseApiResponse(folderId, response.files);
    // };

    const handleSelectFile = (file: FileData) => {
        let currentFileLocation = '';
        folderChain.forEach(folder => {
            currentFileLocation += '/' + folder.name;
        });
        currentFileLocation += `/${file.name}`;
        // if(!file.isDir) {    // console.log(currentFileLocation);
        //     setSelectedFileInfo({ path: currentFileLocation, name: file.name });
        // }
        setSelectedFileInfo({ path: currentFileLocation, name: file.name });
        if(readOnly) {
            onFileSelection(currentFileLocation);
        }
    };

    const handleFileOpen = (file: FileData) => {
        if(file.isDir){
            setSelectedFileInfo({ path: '', name: '' });
            setCurrentFolderId(file.id);
            if(file.id !== ROOT_FOLDER_ID) {
                setFilesToBeShown([null, null]);
                toggleLoader(true);
                FileHandler.GetFilesOfDirectory(file.id)
                    .then(response => {
                        parseApiResponse(file.id, response.data.files);
                    });
            }
        } else {
            handleSelectFile(file);
        }
    };

    useEffect(() => {
        FileHandler.GetInitialDirectoriesList(handleLoadInitialFolders); 
    }, [initialPath]);

    

    const parseFileStructureInfo = () => {
        const folder = fileMap[currentFolderId];

        const folderChain = [];
        let files: FileData[] = [];

        if (folder) {
            let currentFolder: FileData | null = folder;
            while (currentFolder) {
                folderChain.unshift(currentFolder);
                const parentId = currentFolder.parentId as string;
                currentFolder = parentId ? fileMap[parentId] : null;
            }
            if (folder.childrenIds) {
                files = folder.childrenIds.map(id => fileMap[id]);
            }
        }
        setFilesToBeShown(files);
        setFolderChain(folderChain);
    };

    useEffect(() => parseFileStructureInfo(), [fileMap, currentFolderId]);

    const handleSelectCurrentFile = () => {
        onFileSelection(selectedFileInfo.path);
    };

    const refreshCurrentFolder = () => {
        handleFileOpen(fileMap[currentFolderId]);
    };

    const handleDeleteFiles = (files: FileData[]) => {
        FileHandler.DeleteFilesElfinder(files.map(f => f.id), refreshCurrentFolder);
    };

    const downloadFile = (file: FileData) => {
        if(!file.isDir) {
            const queryParams: any = { cmd: 'file', 'target': file.id, 'download': 1, '_t': moment().format('x')};
            const url = `${API.fileBrowserUrl}/elfinder/connector?${queryString.stringify(queryParams)}`;
            downloadFileUsingLink(url, file.name);
        }
    };

    const fileBrowserComponent = useMemo(() => {
        const disableControls = showAsModal || currentFolderId === ROOT_FOLDER_ID || readOnly;
        return(
            <FileBrowser 
                ref={fileBrowserRef}
                files={showLoader ? [null, null, null, null]: filesToBeShown} 
                folderChain={folderChain}
                onFileOpen={handleFileOpen}
                onFileSingleClick={handleSelectFile}
                view={FileView.Details}
                onUploadClick={disableControls ? undefined:() => {
                    toggleUploadModal(true);
                }}
                onDeleteFiles={disableControls ? undefined: handleDeleteFiles }
                onFileDoubleClick={disableControls ? undefined: downloadFile}
                fillParentContainer
            />
        );
    }, [fileBrowserRef, filesToBeShown, folderChain, currentFolderId, showAsModal]);

    // useEffect(() => {
    //     console.log(fileBrowserRef.current?.getSelection())
    // }, [show, fileBrowserRef])

    return(
        showAsModal ?
            <Modal
                title=""
                toggleClose={toggleClose}
                className="fileBrowserModal"
                isOpen={show}
            >
                {fileBrowserComponent}
                {/* <FileBrowser 
                    ref={fileBrowserRef}
                    files={filesToBeShown || [null, null]} 
                    folderChain={folderChain}
                    onFileOpen={handleFileOpen}
                    onFileSingleClick={handleSelectFile}
                    view={FileView.Details}
                    fillParentContainer
                /> */}
                <div className="fileSelectionOptions">
                    <button 
                        className="btn-md"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>
                    <button 
                        className="btn-md btn-yellow"
                        onClick={handleSelectCurrentFile}
                        disabled={!selectedFileInfo.name}
                    >
                        Select
                    </button>
                </div>
            </Modal>
            :
            <>
                {fileBrowserComponent}
                <ServerFileUploadModal 
                    showModal={showUploadModal}
                    toggleClose={() => toggleUploadModal(false)}
                    handleUploadSuccess={refreshCurrentFolder}
                    path={currentFolderId}
                />
            </>
    );
};