import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { cloneDeep, concat, get, isEmpty, isPlainObject, set } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react'; 
import { _selectoptionType } from '../../../form/select-field';
import { Modal } from '../../../modals';
import { errorAlert } from '../../../toastify/notify-toast';
import { 
    HyperParamsInfo, 
    MlPipelineStageKeys, 
    ModelLibraryOptions, 
    modeOptionsForDropdown, 
    OperationOptions, 
    PipelineCategoryOptions, 
    pipelineCategoryOptionsForDropdown, 
    PythonModelLibraryOptions, 
    TensorflowExtraModelParams, 
} from '../enums';
import {  SchemaCaptureProps } from '../../field-creator';
import {  MlflowHandler } from '../../../../api/mlflow-handler';
import { useDidMount } from 'rooks';
import { uuid } from 'uuidv4';
import { addAdditionalLayersToTensorflow, CurrentModelLibrarySelection, getCurrentModelTypeSelection, getDefaultValuesForSklearnParams, getDefaultValuesForTensorflowExtraParams, getKeyInPipelineStage, getMlPipelineStagesInitialVal, getModelLibrarySelectionKey, 
    getPythonModelOptions,  getTensorflowModelParamsKey,   handleSetModelLibrarySelection, parseResponseOfPythonModel, setInitialValuesForStage, useGetStagesInfo } from '../utils';
import { InPageSpinner } from '../../../spinners/in-page-spinner';
import scrollIntoView from 'scroll-into-view-if-needed';
import { BuildPipelineLHS, BuildPipelineLHSProps } from './build-pipeline-LHS';
import { BuildPipelineRHS, MlPipelineStageInfo, MlPipelineStages, ModelParamStageInfo, ModelParamStages } from './build-pipeline-RHS';
import { PipelineStageProps } from './pipeline-stage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/types';
import { ExecutionEnvModes } from '../../../../constants/enums';
import { ShowWhenTrue } from '../../../../helpers';
import { LoseUnsavedFormChangesComponent } from '../../../../pages/workflow-page/modals/lose-unsaved-form-changes';
import { Portal } from '../../../use-portal';
import { WorkflowCanvas } from '../../../workflow-canvas';
import { setComponentPropertiesState } from '../../../../pages/workflow-page/utils';
import { setActiveComponentPropsState } from '../../../../store/workflow';
import { getUniqueId } from '@utils/common';

export type BuildPipelineModalProps = Pick<SchemaCaptureProps, 'captureSchemaOptions'> & {
    showModal: boolean;
    toggleClose: () => any;  
    handleSetInitialValues: (arg0: any) => void;
}

export type PythonSingleModelTypeInfoOptions = _selectoptionType & { pythonModule?: string };

export type PythonSingleModelTypeInfo = {
    options: PythonSingleModelTypeInfoOptions[]; 
    params: Record<string, HyperParamsInfo>; 
    
}

export type PythonSingleModelExtraParams = {
    defaultValue: PythonSingleModelTypeInfoOptions | PythonSingleModelTypeInfoOptions[];
    selectionType: 'single' | 'multiple';
    label: string;
}

export type PythonSingleModelParamInfo = PythonSingleModelTypeInfo & PythonSingleModelExtraParams;
// export type PythonModelTypesInfo = Record<string, PythonSingleModelTypeInfo>

export type PythonPipelineCategoryInfo<T extends PipelineCategoryOptions | TensorflowExtraModelParams> = Record<T, PythonSingleModelTypeInfo>


export type SklearnModelsInfo = PythonPipelineCategoryInfo<PipelineCategoryOptions.Classification | PipelineCategoryOptions.Clustering | PipelineCategoryOptions.Regression>
export type TensorflowModelsInfo = PythonPipelineCategoryInfo<PipelineCategoryOptions.Layers>
// export type TensorflowExtraModelParamsInfo = PythonPipelineCategoryInfo<TensorflowExtraModelParams>
export type TensorflowExtraModelParamsInfo = Record<TensorflowExtraModelParams, PythonSingleModelParamInfo>


export type PythonModelsInfo = {
    [PythonModelLibraryOptions.Sklearn]: SklearnModelsInfo;
    [PythonModelLibraryOptions.Tensorflow]: TensorflowModelsInfo;
    tensorflowExtraParams: TensorflowExtraModelParamsInfo;
    [MlPipelineStageKeys.PREPROCESSING_Sklearn]: Record<string, PythonSingleModelTypeInfo>;
}


// export type PythonModelsInfo = SklearnModelsInfo & TensorflowModelsInfo & { tensorflowExtraParams: TensorflowExtraModelParamsInfo };

export type CreateTrainModelStageParams = {
    pipelineCategory: PipelineCategoryOptions;
    createNewStage?: boolean;
    newStagePosition?: number;
    trainModelStageInfo?: MlPipelineStageInfo;
    mlPipelineStageKey: MlPipelineStageKeys.Spark | MlPipelineStageKeys.sklearn | MlPipelineStageKeys.tensorflow;
    modelLibrary?: ModelLibraryOptions;
}

export const BuildPipelineModal: React.FC<BuildPipelineModalProps> = ({ showModal, toggleClose, handleSetInitialValues }) => {
    const [stagesInfo, setStagesInfo] = useState<MlPipelineStages>(getMlPipelineStagesInitialVal());
    const { submitForm, values, setFieldValue, initialValues, errors, setFieldTouched } = useFormikContext<any>();
    const [, { initialValue: initialStagesInfoValue },{ setValue: setStagesInfoValueInFormikContext }] = useField<MlPipelineStages>('stagesInfo');
    const [, { initialValue: initialModelStagesInfoValue },{ setValue: setModelStagesInfoValueInFormikContext }] = useField<ModelParamStages>('modelStagesInfo');
    const [pythonModelsInfo, setPythonModelsInfo] = useState<PythonModelsInfo>({ 
        sklearn: { classification: { options: [], params: {}}, clustering: {  options: [], params: {} }, regression: { options: [], params: {}}}, 
        tensorflow: { layers: {  options: [], params: {} }},
        tensorflowExtraParams: { 
            losses: { options: [], params: {}, defaultValue: [], selectionType: 'single', label: ''}, 
            optimizers:{ options: [], params: {}, defaultValue: [], selectionType: 'single', label: ''}, 
            metrics: { options: [], params: {}, defaultValue: [], selectionType: 'multiple', label: ''}
        },
        'PREPROCESSING_sklearn': {}
    });
    // SPINNER IS ADDED AS useEffects are getting executed without sklearninfo
    const [showSpinner, toggleSpinner] = useState(true);
    const [resetModelTypeFieldInfo,] = useState<PipelineStageProps['resetModelTypeFieldInfo']>(null);
    const [resetPipelineCategoryInfo, setResetPipelineCategoryInfo] = useState<BuildPipelineLHSProps['resetPipelineCategoryFieldInfo']>(null);
    const [resetOperationFieldInfo, setResetOperationFieldInfo] = useState<PipelineStageProps['resetOperationFieldInfo']>(null);
    const [modelParamStagesInfo, setModelParamStagesInfo] = useState<ModelParamStages>([]);
    const pipelineOuterDivRef = useRef<HTMLDivElement>(null);
    const activeExecutionEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv);
    const isFormSaved = useSelector((store: RootState) => store.WorkflowReducer.activeComponentPropertiesInfo.isSaved);
    const [showDiscardChangesPrompt, toggleDiscardChangesPrompt] = useState(false);
    const activeComponentInfo = useSelector((store: RootState) => store.WorkflowReducer.activeComponentInfo);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const dispatch = useDispatch();    

    

    useDidMount(() => {
        if(activeExecutionEnv === ExecutionEnvModes['Spark']) {
            handleSetInitialValues({});
            toggleSpinner(false);
        } else {
            MlflowHandler.GetPythonModels(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, (res) => {
                const _pythonModelsInfo: PythonModelsInfo = cloneDeep(pythonModelsInfo);
                _pythonModelsInfo['sklearn'] = parseResponseOfPythonModel(res['sklearn'].stages, PythonModelLibraryOptions['Sklearn']);
                // @ts-ignore
                _pythonModelsInfo['PREPROCESSING_sklearn'] = parseResponseOfPythonModel(res['sklearn'].preprocessing, PythonModelLibraryOptions['Sklearn']);
                _pythonModelsInfo['tensorflow'] = parseResponseOfPythonModel(res['tensorflow'].stages, PythonModelLibraryOptions['Tensorflow']);
                addAdditionalLayersToTensorflow(_pythonModelsInfo['tensorflow'])
                _pythonModelsInfo['tensorflowExtraParams'] = parseResponseOfPythonModel(res['tensorflow'].model_params, PythonModelLibraryOptions['Tensorflow'], true);
                const { tensorflowExtraParamInitialValues, modelParamStagesInfo } = getDefaultValuesForTensorflowExtraParams(initialModelStagesInfoValue, _pythonModelsInfo['tensorflowExtraParams']);
                const sklearnInitialValues = getDefaultValuesForSklearnParams(initialValues, initialStagesInfoValue?.sklearn[0].id, _pythonModelsInfo['sklearn']);
                if(!isEmpty(modelParamStagesInfo)) {
                    setModelParamStagesInfo(modelParamStagesInfo);
                }
                setPythonModelsInfo(_pythonModelsInfo);
                toggleSpinner(false);
                handleSetInitialValues({ ...tensorflowExtraParamInitialValues, ...sklearnInitialValues });
            });
        }
        
    });

    useEffect(() => {
        if(initialStagesInfoValue && !isEmpty(initialStagesInfoValue)) {
            setStagesInfo(initialStagesInfoValue);
        }
    }, [initialStagesInfoValue]);

    useEffect(() => {
        if(!isEmpty(stagesInfo)) {
            setStagesInfoValueInFormikContext(stagesInfo);
        }
    }, [stagesInfo]);

    useEffect(() => {
        if(initialModelStagesInfoValue && !isEmpty(initialModelStagesInfoValue)) {
            setModelParamStagesInfo(initialModelStagesInfoValue);
        }
    }, [initialModelStagesInfoValue]);

    useEffect(() => {
        if(!isEmpty(modelParamStagesInfo)) {
            setModelStagesInfoValueInFormikContext(modelParamStagesInfo);
        }
    }, [modelParamStagesInfo]);

    const { modelLibraryStages } = useGetStagesInfo(stagesInfo);

    const addNewMultiOptimizerStageOnNewPipelineStage = (stageInfo: MlPipelineStageInfo, newStagePosition: number) => {
        const multiOptimizerStageIndex = modelParamStagesInfo.findIndex((modelParamStage, idx) => {
            const stageId = modelParamStage.id;
            const selectedOptimizer = get(values, 
                getTensorflowModelParamsKey(stageId, TensorflowExtraModelParams.optimizers, 'type')
            )
            return selectedOptimizer === 'MultiOptimizer';
        })

        if(multiOptimizerStageIndex !== -1) {
            const newMultiOptimizerStageInfo: ModelParamStageInfo = {
                id: getUniqueId(),
                type: TensorflowExtraModelParams.optimizers,
                selectionType: 'single',
                isMultiOptimizerStage: true,
                pipelineStageInfoForMultiOptimizer: stageInfo
            }

            // multi optimizer stages are added after actual stage where multi optimizer is selected    
            const multiOptimizerPosition = multiOptimizerStageIndex + newStagePosition + 1
            const modelParamStagesInfoCopy = cloneDeep(modelParamStagesInfo)
            modelParamStagesInfoCopy.splice(multiOptimizerPosition, 0, newMultiOptimizerStageInfo);
            setModelParamStagesInfo(modelParamStagesInfoCopy)
        }
    }

    const handleCreateTrainModelStage = ({ 
        pipelineCategory,
        createNewStage,
        newStagePosition,
        trainModelStageInfo,
        mlPipelineStageKey,
        modelLibrary
    }: CreateTrainModelStageParams) => {

        const __stagesInfo = cloneDeep(stagesInfo);
        let selectedStagesInfo = __stagesInfo[mlPipelineStageKey];
        let trainModelStageId = '';
        if(createNewStage && newStagePosition !== undefined) {
            const id = uuid();
            const operation = OperationOptions['Train Model'];
            trainModelStageId = id;
            const stageInfo: MlPipelineStageInfo = { id, operation, touched: true }
            selectedStagesInfo.splice(newStagePosition, 0, stageInfo);
            addNewMultiOptimizerStageOnNewPipelineStage(stageInfo, newStagePosition)
        } else if(trainModelStageInfo){
            trainModelStageId = trainModelStageInfo.id;
            selectedStagesInfo = selectedStagesInfo.map(stage => {
                if(stage.id === trainModelStageInfo.id) stage.touched = true;
                return stage;
            });
        }
        
        setStagesInfo(__stagesInfo);
        setInitialValuesForStage({  
            stageId: trainModelStageId, 
            operationType: OperationOptions['Train Model'], 
            currentValues: values, 
            currentInitialValues: initialValues, 
            setFieldValue,
            optionsForModelType: pipelineCategory === PipelineCategoryOptions['Layers'] ? 
                getPythonModelOptions(pythonModelsInfo, PythonModelLibraryOptions['Tensorflow'], PipelineCategoryOptions['Layers'])
                : 
                getPythonModelOptions(pythonModelsInfo, PythonModelLibraryOptions['Sklearn'], pipelineCategory),
            pipelineCategory,
            modelLibrary,
            sklearnPreprocessorOptions: []
        });
        // }   
    };

    const trainModelStageInfo: MlPipelineStageInfo | undefined = useMemo(() => modelLibraryStages[0] || undefined, [modelLibraryStages]); 

   
    const handleModelLibraryChange = (modelLibrary: ModelLibraryOptions) => {
        const trainModelStageInfo = stagesInfo[MlPipelineStageKeys[modelLibrary]][0];
        if(!!trainModelStageInfo && values.model_library !== modelLibrary) {
            const librarySelectionKey = getModelLibrarySelectionKey(modelLibrary);
            const libraryExistingSelection: CurrentModelLibrarySelection = get(values, librarySelectionKey);
            if(libraryExistingSelection?.activePipelineCategory) {
                const currentActivePipelineCategory: PipelineCategoryOptions = libraryExistingSelection.activePipelineCategory; 
                // set(initialValues, 'pipeline_category', libraryExistingSelection.activePipelineCategory); 
                setFieldValue('pipeline_category', currentActivePipelineCategory); 
                const activePipelineCategoryOption = pipelineCategoryOptionsForDropdown.find(t => t.value === currentActivePipelineCategory);
                activePipelineCategoryOption && setResetPipelineCategoryInfo(activePipelineCategoryOption);

                const modelTypeKey = getKeyInPipelineStage('model_type', trainModelStageInfo.id);
                const libraryModelTypeSelection = get(libraryExistingSelection.modelTypeSelection, currentActivePipelineCategory);
                set(initialValues, modelTypeKey, libraryModelTypeSelection);
                setFieldValue(modelTypeKey, libraryModelTypeSelection);

            } else {
                // As modelLibrary: Spark is set initially,  libraryExistingSelection will be set while initializing
                // if(modelLibrary === PythonModelLibraryOptions['Sklearn'] || modelLibrary === PythonModelLibraryOptions['Tensorflow']) {
                if(modelLibrary === PythonModelLibraryOptions['Tensorflow']) {
                    const initialPipelineCategoryValue = PipelineCategoryOptions['Layers'];
                   
                    if(values.pipeline_category !== PipelineCategoryOptions['Layers']) {
                        // THIS IS TO RESET PIPELINE CATEGORY TO LAYERS
                        setResetPipelineCategoryInfo(pipelineCategoryOptionsForDropdown[4]);
                    }
                    setFieldValue('pipeline_category', initialPipelineCategoryValue);
                    handleCreateTrainModelStage({
                        trainModelStageInfo: stagesInfo.tensorflow[0],
                        mlPipelineStageKey: MlPipelineStageKeys['tensorflow'],
                        pipelineCategory: initialPipelineCategoryValue,
                        modelLibrary
                    });
                } 
            }
        }
    }; 

    const handlePipelineCategoryClick = (pipelineCategory: PipelineCategoryOptions) => {
        if(!!trainModelStageInfo && values.pipeline_category !==pipelineCategory) {
            // If modelType is RandomForestRegressor(PipelineCategoryOptions.Regression) and options are switched to Classification
            // modelType has to be changed to ClassificationTypes['Random Forest Classification'] i.e modeltype related to classification
            // read help of getModelTypeKey 
            const currentLibrarySelectionKey = getModelLibrarySelectionKey(values.model_library);
            const currentLibraryModelTypeSelection = getCurrentModelTypeSelection(get(values, currentLibrarySelectionKey),pipelineCategory);
            handleSetModelLibrarySelection('pipelineCategory' ,{ values, setFieldValue, modelLibrary: values.model_library, pipelineCategory });
            if(currentLibraryModelTypeSelection) {
                // as model type is a dropdown, its initial values is changed directly
                const modelTypeKey = getKeyInPipelineStage('model_type', trainModelStageInfo.id);
                set(initialValues, modelTypeKey, currentLibraryModelTypeSelection);
            } else {
                // RESET INITIAL VALUES WHEN MODEL TYPE IS SET FOR THE FIRST TIME
                setInitialValuesForStage({  
                    stageId: trainModelStageInfo.id, 
                    operationType: OperationOptions['Train Model'], 
                    currentValues: values, 
                    currentInitialValues: initialValues, 
                    setFieldValue,
                    optionsForModelType: getPythonModelOptions(pythonModelsInfo, values.model_library, pipelineCategory),
                    pipelineCategory
                });
            }
            if(pipelineCategory === PipelineCategoryOptions['Clustering'] && values.validate_model) {
                // set validate_model to false when clustering is set
                setFieldValue('validate_model', false);   
            }
        }
    };


    const getKeys = (obj: Record<string, any>, prefix = ''): string[] => (
        Object.keys(obj).reduce((res: string[], key) => {
            if(isPlainObject(obj[key]) && obj[key] &&! isEmpty(obj[key])){
                return [...res, ...getKeys(obj[key], prefix ? prefix + '.' + key: key)];
            } 
            return [ ...res, prefix ? prefix + '.' + key: key];
            // return []
        }, [])
    );

    const scrollErrorFieldIntoView = () => {
        const errorFields = pipelineOuterDivRef.current?.getElementsByClassName('inputfield--error');
        if(errorFields && errorFields.length > 0) {
            const elementWithError = errorFields[0];
            scrollIntoView(elementWithError, { block: 'center', behavior: 'smooth' });
        }
        
    };

 
    const handleSubmit = () => {
        submitForm()
            .then(() => {
                // if(trainModelStageInfo && values.pipeline_category !== PipelineCategoryOptions['Clustering']) {

                // }
                toggleDiscardChangesPrompt(false);
                if(!isEmpty(errors)) {
                    // Some fields' errors are not showing up 
                    getKeys(errors).forEach(key => {
                        setFieldTouched(key, true);
                    });
                    scrollErrorFieldIntoView();
                } else {
                    toggleClose();
                }
            })
            .catch(e => {
                /* eslint-disable no-console */
                console.log(e);
                errorAlert('Couldn\'t save the form');
                toggleDiscardChangesPrompt(false);
                toggleClose();
            });
    };

    const handleResetOperationOfStage = (stageId: string) => {
        setResetOperationFieldInfo(stageId);
        // REFER TO resetOperationFieldInfo in pipeline-stage
        setTimeout(() => setResetOperationFieldInfo(null), 1000);
    };

    const modalType= useMemo(() => modeOptionsForDropdown.find(mode => values.mode === mode.value)?.label || '', [values.mode]);
    
    const onModalCloseTrigger = () => {
        if(isFormSaved) {
            toggleClose();
        } else {
            toggleDiscardChangesPrompt(true);
        }
    };

    const activeComponentNodeInfo = useMemo(() => {
        if(activeComponentInfo) {
            const activeNode = WorkflowCanvas.getNode(activeComponentInfo.id);
            if(activeNode) return activeNode;
        }
        
        return null;
    }, [activeComponentInfo]);

    const onDiscardChanges = () => {
        if(activeComponentNodeInfo) {
            setComponentPropertiesState(activeComponentNodeInfo, errors, WorkflowCanvas);
            dispatch(setActiveComponentPropsState({ 'isSaved': true }));
            toggleClose();
            activeComponentNodeInfo?.setSelected(false);
            activeComponentNodeInfo?.setSelected(true);
        }
    };


    return(
        <Modal
            isOpen={showModal}
            title="Configure Pipeline"
            subtitle={modalType + ' - By default, preprocessing steps are available. Please select build model if you intend to build machine learning model on top of your data'}
            toggleClose={onModalCloseTrigger}
            className={classNames('profileModal__container configurePipelineModal__container')}
            showCloseMark
        >  
            <div 
                className="configurePipeline__outer"
                ref={pipelineOuterDivRef}    
            >
                {showSpinner ?
                    <InPageSpinner />
                    :
                    <>
                        <div className="configurePipeline__LHS">
                            <BuildPipelineLHS 
                                handleCreateTrainModelStage={handleCreateTrainModelStage}
                                handleModelLibraryChange={handleModelLibraryChange}
                                handlePipelineCategoryClick={handlePipelineCategoryClick}
                                handleStagesInfo={[stagesInfo, setStagesInfo]}
                                resetPipelineCategoryFieldInfo={resetPipelineCategoryInfo}
                                resetStageOperation={handleResetOperationOfStage}
                            />
                        </div>
                        <div className="configurePipeline__RHS">
                            <BuildPipelineRHS 
                                handleStagesInfo={[stagesInfo, setStagesInfo]}
                                handleModelParamStagesInfo={[modelParamStagesInfo, setModelParamStagesInfo]}
                                pythonModelsInfo={pythonModelsInfo}
                                resetModelTypeFieldInfo={resetModelTypeFieldInfo}
                                resetOperationFieldInfo={resetOperationFieldInfo}
                                handleCreateTrainModelStage={handleCreateTrainModelStage}
                            />
                        </div>
                    </>
                }
            </div>
            
            <div className="modalBtns__box">
                <button 
                    className="btn-md btn-yellow"
                    type="button"
                    onClick={handleSubmit}
                >
                    Save
                </button>
                <button 
                    className="btn-md btn-cancel"
                    type="button"
                    onClick={onModalCloseTrigger}
                >
                    Cancel
                </button>
            </div>
            <ShowWhenTrue show={showDiscardChangesPrompt}>
                <div className="loseUnSavedChangesbgOverlay"/>
                <Portal
                    className="loseUnsavedChangesPortalBox"
                >
                    <div
                        className="loseUnsavedFormChangesModal loseUnsavedFormChangesBox"
                    >
                        <LoseUnsavedFormChangesComponent
                            onCancel={() => toggleDiscardChangesPrompt(false)}
                            onDiscardChanges={onDiscardChanges}
                            onSaveAndContinue={handleSubmit}
                        />
                    </div>
                </Portal>
            </ShowWhenTrue>
        </Modal>
    );};