import React, { CSSProperties, useCallback, useState } from 'react'
import { Table } from 'antd';
import CsvDownloader from 'react-csv-downloader';
import { useGenAIContext } from '../useGenAIContext';
import classNames from 'classnames';
import styles from './../../styles.module.scss';
import QueryChartsModal from '@pages/data-explorer-new/components/queryChartsModal';
import { Modal } from "@components/modals";
import { GenAITableOutput, DataColumn } from '../types';
import { useDataExplorerContext } from '@pages/data-explorer-new/useDataExplorerContext';
import { DownloadIcon } from '@pages/data-browser/icons';

type Props = {
    tabelData: GenAITableOutput,
}

const RowRenderTable: React.FC<Props> = ({ tabelData }) => {
    const { theme } = useDataExplorerContext();
    const { sqlQuery } = useGenAIContext();
    const [showQueryCharts, setShowQueryCharts] = useState<boolean>(false);
    const convertToAntDTableData = (data: GenAITableOutput): Record<string, any>[] => {
        const keys = Object.keys(data);
        const numberOfRows = data[keys[0]].length;

        const tableData: Record<string, any>[] = Array.from({ length: numberOfRows }, (_, rowIndex) => {
            const row: Record<string, any> = {};
            keys.forEach(key => {
                row[key] = data[key][rowIndex];
            });
            return row;
        });

        return tableData;
    };

    const dataSource = tabelData ? convertToAntDTableData(tabelData) : [];
    const columns: DataColumn[] = Object.keys(tabelData || []).map(key => ({
        title: key,
        dataIndex: key
    }));



    const getColumns = (data: any[]) => {
        const keys = Array.from(new Set(data.flatMap(Object.keys)));
        return keys.map(key => ({
            id: key,
            displayName: key
        }));
    };
    const csvColumns = getColumns(dataSource);
    const csvData = dataSource?.map(item => {
        return csvColumns.reduce((acc, col: any) => {
            acc[col.id] = item[col.id] ?? '';
            return acc;
        }, {} as { [key: string]: any });
    });


    const textStyle: CSSProperties | undefined = theme ? {
        color: theme === "dark" ? '#fff' : '#000',
        borderColor: theme === "dark" ? '#fff' : '#000',
    } : undefined;

    const handleQueryChartsOpen = useCallback(() => {
        setShowQueryCharts(true);
    }, []);

    const handleQueryChartsClose = useCallback(() => {
        setShowQueryCharts(false);
    }, []);


    return (
        <div>
            {dataSource.length > 0 && (
                <div className={classNames(styles['table-holder'])}>
                    <div style={{ minHeight: '6rem' }}>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            rowClassName={() => 'custom-row'}
                            scroll={{ x: 'max-content' }}
                            // pagination={{
                            //     current: pageNo,
                            //     pageSize: pageSize,
                            //     total: totalRecords,
                            //     showSizeChanger: true,
                            //     pageSizeOptions: ['10', '50', '100', '250'],
                            // }}
                            // onChange={handleTableChange}
                            className="hidden-pagination"
                            style={{ overflow: 'hidden' }}
                        />
                    </div>
                    <div style={{ marginTop: -30 }}>
                        <div className={styles["space-between"]} style={{ justifyContent: 'flex-end', marginTop: '0rem' }}>
                            <CsvDownloader filename={`result_${new Date().toLocaleString('en-GB').replace(/[/:]/g, '-').replace(', ', '-')}`}
                                extension=".csv"
                                separator=";"
                                wrapColumnChar="'"
                                columns={csvColumns}
                                datas={csvData}>
                                <button
                                    className={classNames(styles['downloadCsvButton'], styles['ml-1'])}
                                    style={textStyle}
                                >
                                    <DownloadIcon />&nbsp; Download
                                </button>
                            </CsvDownloader>

                            <div>&nbsp;</div>
                            <button
                                className={classNames(styles['downloadCsvButton'], styles['ml-1'])}
                                onClick={handleQueryChartsOpen}
                                style={textStyle}
                            >
                                Explain
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                isOpen={showQueryCharts}
                toggleClose={handleQueryChartsClose}
                title="Explanation"
                className={classNames("mf_model", styles["addEditWriteCsvModal_"])}
                showCloseMark
                shouldCloseOnOverlayClick={false}
            >
                <div className={styles["queryChartsContainer"]}>
                    <QueryChartsModal onClose={handleQueryChartsClose} sqlQuery={sqlQuery} />
                </div>
            </Modal>
        </div>
    );
};

export default RowRenderTable;