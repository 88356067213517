import React from "react";
import styles from "../../../styles.module.scss";
import Form, { NewSelectField, RadioField } from "@components/form";
import { Flex } from "@components/ui/Flex";
import { interploted_valuesOptions } from "../constant";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { Text } from "@components/ui/Text";
import { Checkbox } from "@components/form/elements";

interface HierarchyFormProps {
    activeStep: number;
    setActiveStep: (step: number) => void;
    initialValues: any;
    formSchema: any;
    selectedRowKeys: string[];
    handleOnSubmit: (values: any) => void;
}

const HierarchyInterpolationForm: React.FC<HierarchyFormProps> = ({
    activeStep,
    setActiveStep,
    initialValues,
    formSchema,
    selectedRowKeys,
    handleOnSubmit
}) => {
    const filterTagOptions = selectedRowKeys?.map((tag) => ({ label: tag, value: tag }));
    const [showInterplotedValues, setShowInterplotedValues] = React.useState(false);    
    const [showRawValues, setShowRawValues] = React.useState(false);  
    return (
        <>
            <Form
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                validationSchema={formSchema}
                enableReinitialize
            >
                {({ _formikprops: { values, setFieldValue } }) => (
                    <>
                        <div className={styles.jsonHierarchyForm}>
                            <div style={{ padding: 16 }}>
                                <Flex alignItems={"center"} gap='10px' mb={10}>
                                    <Text color={"white"}>Interpolated Values</Text>
                                    <Checkbox
                                        checked={showInterplotedValues}
                                        onClick={() =>{
                                            setShowInterplotedValues(!showInterplotedValues);
                                        }}
                                        color="gold"							
                                    />	
                                </Flex>
                                <ShowWhenTrue show={showInterplotedValues}>
                                    <Text  className={styles["requestName__field"]}>{interploted_valuesOptions?.[0]?.label}</Text>
                                    <Flex gap="10px" alignItems={"center"}>

                                        <NewSelectField
                                            name="frequency"
                                            // label='Edit Row Filters'
                                            options={Array.from({ length: 60 }, (_, i) => i + 1).map((i) => ({ label: i.toString(), value: i.toString() }))}
                                            className={styles["requestName__field"]}
                                        />
                                        <NewSelectField
                                            name="frequencyType"

                                            options={[{ label: 'Seconds', value: "seconds" }, { label: "Minutes", value: "minutes" }, { label: "Hours", value: "hours" }, { label: "Days", value: "days" }]}
                                            className={styles["requestName__field"]}
                                        />
                                    </Flex>
                                </ShowWhenTrue>
                                <Flex alignItems={"center"} gap='10px' mb={10}>
                                    <Text color={"white"}>Raw Values</Text>
                                    <Checkbox
                                        checked={showRawValues}
                                        onClick={() =>{
                                            setShowRawValues(!showRawValues);
                                        }}
                                        color="gold"							
                                    />	
                                </Flex>
                                <ShowWhenTrue show={values.interploted_values != "sample_values"}>
                                    <NewSelectField
                                        name="tags"
                                        label="Tags"
                                        placeholder="Select a tag"
                                        options={filterTagOptions}
                                        className={styles["requestName__field"]}
                                    />
                                </ShowWhenTrue>
                            </div>
                        </div>
                        <div className={styles.hierarchyFormFooter}>
                            <button type="button" className="btn btn-grey btn-md" onClick={() => { setActiveStep(activeStep - 1); }}>
                                Back
                            </button>
                            <button type="submit" className="btn btn-yellow btn-md" >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </Form>
        </>
    );
};

export default HierarchyInterpolationForm;
