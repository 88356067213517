import logrocket from 'logrocket';

export const isDevelopment = process.env.NODE_ENV === 'development';

const hostname = window.location.hostname;
let licenseId = hostname?.split(".")[0];
licenseId = licenseId ==="localhost"?"3535353535":licenseId;

const replaceGateWayUrlPart=()=>{
    const domainWithoutLicense = hostname.substring(hostname.indexOf(".") + 1);
    const gatewayUrl = `https://gateway.${licenseId}.${domainWithoutLicense}`;

    return gatewayUrl;
}

const platformUrl = replaceGateWayUrlPart();

const getKeyClockUrl = ()=>{
    if(licenseId.toString().startsWith("3") || licenseId.toString().startsWith("4")){
        return `https://deepiqauth.${licenseId}.azuredeepiq.com/auth/`
    }
    else if(licenseId.toString().startsWith("7")){
        return `https://deepiqauth.prod.deepiq.live/auth/`
    }else{
        return `https://deepiqauth.${licenseId}.deepiq.live/auth/`
    }
}

const KEYCLOCK_URL = getKeyClockUrl();

// eslint-disable-next-line no-console

export const Env = {
    mlflow: isDevelopment,
    enableLogRocket: false, 
    logrocketId: 'hdkpwm/datastudio-20',
    helpImagesUrl: "https://dshelpfiles.blob.core.windows.net/helpfiles/Images",
    keycloakRealm: licenseId || 'datastudio',
    keycloakUrl: KEYCLOCK_URL || 'https://deepiqauth.3535353535.azuredeepiq.com/auth/',
    keycloakClientId: 'datastudio-public',
    gatewayURL: platformUrl,
};

export const gatewayUrl = isDevelopment ? 'https://gateway.3535353535.azuredeepiq.com': Env.gatewayURL;
export const tagsUrl = isDevelopment ? 'http://10.0.9.145:8089/api' : '/api1';
export const witsmlUrl = isDevelopment ? 'http://10.0.9.145:4547' : '/api2';
export const fileBrowserUrl = isDevelopment ? 'http://10.0.9.145:4888': '/api3';
export const dbfsUrl = isDevelopment? 'http://104.42.151.138:7704': '/api5';
export const azureUrl = isDevelopment? 'https://devgateway.deepiqlab.com': '/api6';

export const API = {
    url: gatewayUrl + '/platform/api',
    tagsUrl,
    witsmlUrl,
    fileBrowserUrl: fileBrowserUrl + '/api',
    databricksFilerBrowser: dbfsUrl + '/api',
    azureUrl: azureUrl + '/api',
    azureblobUrl: gatewayUrl + '/filebrowser/api',
};

export const helpImagesUrl = isDevelopment ? 'https://dshelpfiles.blob.core.windows.net/helpfiles/Images': Env.helpImagesUrl;

export const clientMode = {
    deepiq: "DATA_TRANSFORMATION_CONFIG_FILE_DIR=/data01/datastudio2/DATA_TRANSFORMATION_CONFIG_DIR_PATH spark-submit --master",
    maverick:
    'sudo PYSPARK_DRIVER_PYTHON=/opt/conda/bin/python PYSPARK_PYTHON=/opt/conda/bin/python spark2-submit --master',
};

if (Env.enableLogRocket) {
    logrocket.init(Env.logrocketId);
}
