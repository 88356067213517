/* eslint-disable no-console */
// import axios from 'axios';
import { loadReduxStateOnMount } from '@store/common';
import { loadReduxStateFromLS, persistReduxState } from '@store/utils';
import Keycloak from 'keycloak-js';
import { isEmpty } from 'lodash';
import { AdminHandler } from '../api/admin-handler';
import { errorAlert } from '../components/toastify/notify-toast';
import { Env } from '../constants/settings';
import { store } from '../store';
import { setUserInfoInStore } from '../store/account';

const keycloakConfig: Keycloak.KeycloakConfig = {
    'realm': Env.keycloakRealm,
    'url': Env.keycloakUrl,
    clientId: Env.keycloakClientId
};

const _kc = new Keycloak(keycloakConfig);


export interface UserInfo {
    id: string;
    username: string;
    name: string;
    email: string;
}

export interface PathInfos {
    id: string,
    name: string
}

export interface KeycloakUserInfo {
    name: string;
    email: string;
    sub: string;
    email_Vertified: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
}


const deepiqCookieFn = (type: string) =>{
    if(!(process.env.NODE_ENV === 'development')) {
        if(type === 'logout') {
            AdminHandler.RemoveCookie();
        } else {
            AdminHandler.SetCookie();
        }
    }
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const saveTokenAndRefreshToken = (kcRef: Keycloak.KeycloakInstance) => {
    return new Promise<void>((resolve, reject) => {
        if(kcRef.token && kcRef.refreshToken) {
            // localStorage.setItem('token', kcRef.token);
            // localStorage.setItem('refreshToken', kcRef.refreshToken);
            deepiqCookieFn('set_cookie');
            resolve();
        } else {
            reject();
        }
    });
};

const hasRole = (roles: string[]) => roles.some((role) => _kc.hasRealmRole(role));

const doLogin = _kc.login;

const doLogout = () => {
    deepiqCookieFn('logout');
    localStorage.clear();
    // _kc.logout({ redirectUri: window.location.origin });
    _kc.logout();
};

const logoutWithoutClearingLocalStorage = () => {
    _kc.logout();
}

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) => {
    _kc.updateToken(200)
        .then(function(refreshed) {
            if (refreshed) {
                successCallback(_kc);
                deepiqCookieFn('set_cookie');

            } 
        }).catch(doLogin);
};
   
const initKeycloak = (onAuthenticatedCallback: any) => {
    _kc.init({
        onLoad: 'login-required',
        redirectUri: window.location.pathname === '/' ? window.location.origin + '/workflow' : window.location.href
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    })
        .then((authenticated) => {
            // _kc.logout()
            if (authenticated) {
                saveTokenAndRefreshToken(_kc)
                    .then(() => {
                        onAuthenticatedCallback();
                        _kc.loadUserInfo()
                            .then((data: KeycloakUserInfo | {})=> {
                                const localReduxState = loadReduxStateFromLS();
                                if(!isEmpty(data)) {
                                    store.dispatch(loadReduxStateOnMount(localReduxState, data as KeycloakUserInfo) as any)
                                    store.dispatch(setUserInfoInStore(true, data as KeycloakUserInfo, hasRole(['ADMIN'])) as any);
                                    persistReduxState(store)
                                }
                            });
                        deepiqCookieFn('set_cookie');
                        setInterval(() => {
                            updateToken(saveTokenAndRefreshToken);
                        }, 60000);
                    })
                    .catch(() => {
                        errorAlert('Couldnot retrieve token');
                    });
            }
        })
        .catch(err => {
            console.log(err);
        });
};


export const KeycloakService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    logoutWithoutClearingLocalStorage
};

