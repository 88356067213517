import * as React from "react";
import { Dropdown, List, Menu, Tooltip } from "antd";
import "./styles.scss";
import { FilterType, WorkspaceData } from "./types";
import { ClouderaIcon, DataBricksIcon, IpIcon, WebIcon } from "@assets/icons";
import { ToggleField } from "@components/form/toggle-field";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { InformativeIcon } from "@pages/workflow-page/assets/icons";
import { TooltipTop } from "@components/tooltips";
import { isEmpty } from "lodash";
import { useAppSelector } from "@store/hooks";
import { ShowWhenTrue } from "@helpers/showwhentrue";

const filterItems: FilterType[] = [
	{
		value: "all",
		label: "All",
	},
	{
		value: "databricks",
		label: "Databricks",
	},
	{
		value: "cloudera",
		label: "Cloudera",
	},
	{
		value: "spark",
		label: "Spark",
	},
	{
		value: "livy",
		label: "Livy",
	},
];

const workspaceTypeImageRef: Record<string, JSX.Element> = {
	"databricks": <DataBricksIcon />,
	"cloudera": <ClouderaIcon />
}

const ClusterStateRef: Record<string,any>  ={
	"pending": {name:"Pending",bgColor:"#FCAD01",color:"#030613"},
	"active": {name:"Active",bgColor:"#125900",color:"#fff"},
	"failed": {name:"Failed",bgColor:"#B01900",color:"#fff"},
}

type WorkSpaceProps = {
	workSpace: WorkspaceData[];
	setWorkSpaceModal: (value: boolean) => void;
	setWorkSpaceDataModal: (data: WorkspaceData) => void;
	handleDeleteWorkSpace: (data: WorkspaceData) => void;
	handleEnableWorkSpace: (data: WorkspaceData) => void;
	handleResetWorkSpace: (data: WorkspaceData) => void;
	isLoading: boolean;
};


const tokenWarningRef:Record<string,{color:string,message:string}> = {
	"red":{color:"#B01900",message:"pat token expired, please update token and restart clusters"},
	"yellow":{color:"#FCAD01",message:"pat token expires soon, please update token and restart clusters note: make sure workflows are not running while restarting clusters"}
}
export const WorkspaceList: React.FC<WorkSpaceProps> = ({
	workSpace,
	setWorkSpaceModal,
	setWorkSpaceDataModal,
	handleDeleteWorkSpace,
	handleEnableWorkSpace,
	handleResetWorkSpace,
	isLoading,
}: WorkSpaceProps) => {
	const [selectedFilterValue, setSelectedFilterValue] = React.useState("all");
	const onEditConnection = React.useCallback((data: WorkspaceData) => {
		setWorkSpaceDataModal(data);
		setWorkSpaceModal(true);
	}, []);
	const isAdmin = useAppSelector((state) => state.AccountReducer.activeUserInfo.id ? state.AccountReducer.activeUserInfo.isAdmin : true);

	const onFilter = (selectedItem: FilterType) => {
		setSelectedFilterValue(selectedItem.value);
	};

	const moreActions = (item: WorkspaceData) => (
		<Menu>
			<Menu.Item
				key="enable"
				onClick={() => handleEnableWorkSpace(item)}
			>
				{item.isEnabled ? "Disable" : "Enable"}
			</Menu.Item>
			{isAdmin &&
			<Menu.Item key="edit" onClick={() => onEditConnection(item)}>
				Edit WorkSpace
			</Menu.Item>}
			{isAdmin &&
			<Menu.Item key="edit" onClick={() => handleResetWorkSpace(item)}>
				Reset
			</Menu.Item>}
			{isAdmin &&
			<Menu.Item key="delete" onClick={() => handleDeleteWorkSpace(item)}>
				Delete
			</Menu.Item>}
		</Menu>
	);
	const filterWorkspaceData = selectedFilterValue === "all" ? workSpace : workSpace.filter((item) => item.workspaceType === selectedFilterValue);
	
	const scrollLeft = () => {
		document.querySelector(".filter_buttons")?.scrollBy({ left: -100, behavior: "smooth" });
	};
	
	const scrollRight = () => {
		document.querySelector(".filter_buttons")?.scrollBy({ left: 100, behavior: "smooth" });
	};
	
	
	
	return (
		<div className="list_container">
			{!isLoading && workSpace.length !== 0 && (
				<div className="filter_div_container">
					<button className="arrow left" onClick={() => scrollLeft()}>&#9664;</button>
					<div className="filter_buttons">
					{filterItems.map((item) => (
						<button
							key={item.value}
							className={
								item.value !== selectedFilterValue
									? "filterButtonItem"
									: "filterButtonSelectedItem"
							}
							onClick={() => onFilter(item)}
						>
							{item.label}
						</button>
					))}
					</div>
					<button className="arrow right" onClick={() => scrollRight()}>&#9654;</button>
				</div>
			)}
			{isLoading ? (
				<div className="in_center">
					<InPageSpinner />
				</div>
			) : ["databricks","all"].includes(selectedFilterValue)? ( // hide cloudera in prod
				<List
					className="list"
					itemLayout="horizontal"
					dataSource={filterWorkspaceData}
					renderItem={(item) => {
						
						let workSpaceUrl = "";
						if (
							item.workspaceUrl &&
							item.workspaceUrl.length <= 15
						) {
							workSpaceUrl = item.workspaceUrl;
						} else {
							workSpaceUrl =
								item.workspaceUrl.substring(0, 6) +
								"..." +
								item.workspaceUrl.substring(
									item.workspaceUrl.length - 10
								);
						}

						return (
							<List.Item
								style={{
									backgroundColor: item.isEnabled
										? "#5B608F"
										: "#212233",
										position: "relative",
										marginTop:20,
										boxShadow:tokenWarningRef[item?.tokenWarning||""] &&`0.2px 0.2px 2px 4px ${tokenWarningRef[item?.tokenWarning||""]?.color}`,
										border:tokenWarningRef[item?.tokenWarning||""] && `1px solid ${tokenWarningRef[item?.tokenWarning||""]?.color}`
								}}
								key={item.id}
								actions={[
									<div key={item.id} className="list_item">
										<ToggleField
											active={item.isEnabled}
											onClick={() =>
												handleEnableWorkSpace(item)
											}
										/>
										<Dropdown
											overlayClassName="more-action-container clusterDropdown"
											overlay={() => moreActions(item)}
											trigger={["click"]}
										>
											<div className="more_icon">
												<img src="/icons/more.svg" />
											</div>
										</Dropdown>
									</div>,
								]}
							>
								<span 
									style={{
										padding: "4px 10px 4px 16px",
										position:"absolute",
										left:-10,
										top:-12,
										display:'flex',
										justifyContent:'center',
										textAlign:'center',
										borderRadius:20,
										backgroundColor:ClusterStateRef[item.state]?.bgColor,
										color:ClusterStateRef[item.state]?.color,
										zIndex:1,
										}}
									>
										{ClusterStateRef[item.state]?.name}
										{!isEmpty(item.errorMessage) ?<TooltipTop  placement="right" overlay={item.errorMessage}><div><InformativeIcon/></div></TooltipTop>:null}
								</span>
									<Tooltip
										placement="right"
										title={tokenWarningRef[item?.tokenWarning||""]?.message||null}
									>
										<List.Item.Meta
											avatar={
												<div className="list_item_avatar">
													{workspaceTypeImageRef[item.workspaceType]}
												</div>
											}
											title={<div>{item.workspaceName}</div>}
											description={
												<div className="desContainer">
													<WebIcon />
													<a
														href={item.workspaceUrl}
														target="_blank"
														rel="noreferrer"
													>
														{workSpaceUrl}
													</a>
													{item.workspaceType === "databricks" &&<>
														<IpIcon />
														<div className="npipWorkspace">
															{item.npipWorkSpace ? 'Private' : 'Public'}
														</div>
													</>}
												</div>
											}
										/>
									</Tooltip>
							</List.Item>
						);
					}}
				/>
			) : (
				<div className="infoMsgContainer">
					<img
						src="/icons/data-browser/error.svg"
						alt=""
						width={24}
						height={24}
					/>
					<div className="infoMsg">
						{`Please contact your administrator as ${selectedFilterValue} compute
						engine isn’t configured yet.`}
					</div>
				</div>
			)}
		</div>
	);
};
