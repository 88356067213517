import { HttpClient } from '../services';
import { API } from '../../constants/settings';
import { WitsmlVersionPayload, WitsmlWellsPayload, WitsmlWellBorePayload, WitsmlWellsInfo, ApiResponse, WitsmlWellBoresInfo, 
    WitmslDataObjectsInfo, WitsmlDataObjectPayload, WitsmlDataObjectResponse } from './types';

export class WitsmlHandler {
    GetVersions(data: WitsmlVersionPayload, callback: (arg0: string[]) => any) {
        // HttpClient.Post('Get Witsml Versions', API.witsmlUrl + '/witsml/versions/', data, callback);
    }

    GetWells(data: WitsmlWellsPayload, callback: ApiResponse<WitsmlWellsInfo> , errorHandlerCallback: any) {
        // HttpClient.Post('Get Witsml Wells', API.witsmlUrl + '/witsml/well/', data, callback,errorHandlerCallback);
    }

    GetWellBores(data: WitsmlWellBorePayload, callback: ApiResponse<WitsmlWellBoresInfo>) {
        // HttpClient.Post('Get Witsml Wells', API.witsmlUrl + '/witsml/wellbore/', data, callback);
    }

    GetDataObjects(data: WitsmlWellsPayload, callback: ApiResponse<WitmslDataObjectsInfo>) {
        // HttpClient.Post('Get Witsml Wells', API.witsmlUrl + '/witsml/capabilities/', data, callback);
    }

    GetDataObjectInfo(dataObject: string, data: WitsmlDataObjectPayload,callback: ApiResponse<WitsmlDataObjectResponse>) {
        // HttpClient.Post('Get Witsml Wells', API.witsmlUrl + '/witsml/' + dataObject, data, callback);
    }
}


