import React, { useEffect, useRef, useState } from 'react';
import styles from './../styles.module.scss';
import MiddleContainer from './middleContainer';
import { DataSourceHandler } from '@api/data-source-handler';
import { LeftContainer } from './leftContainer';
import { RightContainer } from './rightContainer';
import { StateData, TableData } from '../types';
import classNames from 'classnames';
import {  AnalyticsHandler } from '@api/analytics-handler';
import { CreateWorkflow, UpdateWorkflow, UpdateWorkflowResponse, WorkflowHandler } from '@api/workflow-handler';
import { ExecutionEnvModes } from '@constants/enums';
import _, { isEmpty, sortBy } from 'lodash';
import { errorAlert, infoAlert } from '@components/toastify/notify-toast';
import { useDataExplorerContext } from '../useDataExplorerContext';
import { useGenAIContext } from '../gen-ai/useGenAIContext';
import { _selectoptionType } from '@components/form/select-field';
import { Env } from '@constants/settings';
import { getWorkflowDetails, getWorkflowPayload, submitStatementDetails } from '../constants';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { InitHandler } from '@api/init';
import { DataExplorerVisualization } from '@pages/visualization-new/pages/data-explorer-visualization';
import MetaData from '../metadata';
import TabsComponent from '@components/tabs';

const DataExplorerLayout: React.FC<{}> = () => {
    const [isLoading, setLoading] = useState(true);
    const [mainData, setMainData] = useState<StateData>({
        data: {},
        rdbms_keys: [],
        database_list: [],
        options: []
    });
    const [tableSchemaData, setTableSchemaData] = useState<TableData[]>([]);
    const [dataError, setDataError] = useState("");
    const [fetchingData, setFetchingData] = useState(false);
    const session = useRef("");
    const previewStatementId = useRef("");
    const intervalId = useRef<any>(-1);
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const enabledWorkspace = workSpaceData?.filter((item)=>item.isEnabled)?.[0];

    const event = useRef<boolean>(false);
    const { database, cluster, sendDataExplorerReducerCmd, setWorkflowDetails, workflow_details,engine } = useDataExplorerContext();
    const { resultMode } = useGenAIContext();
    const isGenAiNoSpark = engine === "GENAI" && resultMode === "sql";
    const activeEnvDirectoriesInfo = useSelector((store: RootState) => store.AccountReducer.userDirectories[store.CommonReducer.activeExecutionEnv]);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const link = Env?.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
    const getWorkFlowDetails = async () => {
        const resp = await InitHandler.GetWorkflowForDataExplorer(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, "dataExplorerWorkflow");
        if(isEmpty(resp.data)) {
            createWorkflow()
        } else {
            setWorkflowDetails(resp.data.data)
        }
    }
    const createWorkflow = () => {
        let defaultDirectoryId = activeEnvDirectoriesInfo?.defaultDirectoryId || 0;
        const directoryOptions = activeEnvDirectoriesInfo['directories'].map(proj => {
            return({ label: proj.name, value: proj.id  });
        });
        if(!defaultDirectoryId && !isEmpty(directoryOptions)) {
            defaultDirectoryId = directoryOptions[0].value;
        }
        const data: CreateWorkflow = { 
            projectName: 'data-explorer-v1', 
            payload: '', 
            jobtype: 'DT', 
            status: true, 
            details: '', 
            env: ExecutionEnvModes.Spark,
            config: '[]'
        };
        
        data.directoryId = defaultDirectoryId;
        WorkflowHandler.CreateWorkflow(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`,data, (resp)=>{
            const id = resp.data.id;
            const data = `${id}|data-explorer-v1|${defaultDirectoryId}`
            setWorkflowDetails(data)
            InitHandler.SetStateForCounchMark(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, {
                data: data,
                name: "dataExplorerWorkflow"
            }, () => {
                //
            })
            WorkflowHandler.DeleteWorkflow(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, [id], ()=>{
                //
            });
        }, ()=>{
            //
        });
    }
    useEffect(() => {
        if(Env?.REACT_APP_PLATFORM_URL){
            getWorkFlowDetails();
        }
    }, [Env?.REACT_APP_PLATFORM_URL])

    const updateWorkflowForPreview = (query: string, connection: any) => {
        const connection_name =  connection.databaseName;
        const data: UpdateWorkflow = { 
            payload: getWorkflowPayload(connection_name, query, database.dbType === "Databricks"),
            details: getWorkflowDetails(connection_name, query, database.dbType === "Databricks"),
            projectName: workflow_details.name, 
            create_new_version: false, 
            isStarred: false,
            notes: "",
            hasVariables: false,
            componentCounter: 7,
            directoryId: workflow_details.directory,
            env: ExecutionEnvModes.Spark,
            zepplinData: "[]",
            config: "[]"
        };

        const onSuccess = (response: UpdateWorkflowResponse) => {
            getPreviewSession(query, connection_name);
        }
        WorkflowHandler.UpdateWorkflow(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, workflow_details.id, data, onSuccess);
    }

    const cancelJob = () => {
        clearInterval(intervalId.current);
        event.current = true;
        setFetchingData(false);
    };

    const clearError = () => {
        setDataError(""); 
    };

    // Will getsession
    const getPreviewSession = (query: string, connection_name: string) => {
        const previewData: any = { 
            "name": workflow_details.name + "_" + Date.now(),
            "sparkVersion": "2.x",
            env: ExecutionEnvModes.Spark,
            "workflowId": workflow_details.id,
            "clusterId": (window as any).cluster || cluster,
            "type":"preview",
            "workspaceId":enabledWorkspace?.workspaceId,
            "workspaceType":"databricks"
        };
        AnalyticsHandler.StartSession(link, previewData, (response) => {
            session.current = response.sessionId;
            submitStatement(query, connection_name);
        },  (e: any)=> {
            errorAlert(e)
        });
    };

    //Will jobid, for preview
    const submitStatement = (query: string, connection_name: string) => {
        event.current=false;
        const data: any = submitStatementDetails(session.current, connection_name, query, (window as any).cluster || cluster, database.dbType === "Databricks") 
        AnalyticsHandler.SubmitStatement(link, data, (data) => {
            previewStatementId.current = data.jobId;
            if(!event.current) {
                poolQuery();
            }
        }, ()=>{
            setDataError("Unable to parse your question. Please rephrase your question.");
        });
    }
    const poolQuery = () => {
        intervalId.current = setInterval(() => {
            WorkflowHandler.GetPreviewStatus(link, session.current, (data: any) => {
                if(event.current) return;
                if(data[0].state === "success") {
                    clearInterval(intervalId.current);

                    WorkflowHandler.GetComponentOutput(link, session.current, "10e1557f-1baa-435d-96fe-4d704457a0ea", (response) => {
                        const data = JSON.parse(response.output as string);
                        const tableData: any = [];
                        const schema = data.output1.schema.fields.map((field: any) => field.name)
                        tableData.push(schema);
                        data.output1.data.forEach((_data: any) => {
                            const temp = schema.map((field: any) => _data[field])
                            tableData.push(temp);
                        })
                        sendDataExplorerReducerCmd({
                            type: "TABLE_DATA",
                            payload: {
                                tableData: tableData
                            }
                        });
                        AnalyticsHandler.GetComponentColumnsList(link, session.current,  "10e1557f-1baa-435d-96fe-4d704457a0ea", "eeb14611-f563-46af-ad92-ba52569aab9c", (response)=>{
                            if(event.current) return;
                            sendDataExplorerReducerCmd({
                                type: "CAPTURE_SCHEMA",
                                payload: {
                                    data: response,
                                    type: "new"
                                }
                            })
                            
                            setTimeout(() => {
                                setFetchingData(false);
                                sendDataExplorerReducerCmd({
                                    type:"GOT_DATA",
                                    payload: {
                                        gotData: true
                                    }
                                })
                                setDataError("");
                            }, 100);
                        }, (e)=>{
                            errorAlert(e.data);
                        })
                    })
                }
                if(data[0].state === "failed") {
                    clearInterval(intervalId.current);
                    setDataError("Unable to parse your question. Please rephrase your question.");
                    setFetchingData(false);
                }
            }, () => {
                clearInterval(intervalId.current);
                errorAlert("Something went wrong, Please contact the administrator");
            })
        }, 1500);
    }

    const startDataFetch = (sqlData: string) => {
        const cluster1 = (window as any).cluster || cluster;
        if(cluster1 === "choose_cluster" || cluster1 === "") {
            const str = "Please select a cluster to run the query";
            setDataError(str)
            return infoAlert(str);
        } else if(sqlData) {
            setFetchingData(true);
            sendDataExplorerReducerCmd({
                type:"GOT_DATA",
                payload: {
                    gotData: false
                }
            })
            setDataError("");
            updateWorkflowForPreview(sqlData, database);
        }
        else if(Env?.ENABLE_DATA_EXPLORER_SQL_QUERY_VIEW){
            setDataError("Unable to parse your question. Please rephrase your question");
        }
    };

    useEffect(() => {
        setLoading(true);
        // DataSourceHandler.GetAllConnectionsInfo(async (data) => {
        //     if(!data.Generic) {
        //         return;
        //     }
        //     const obj = data.Generic.RDBMS || {};
        //     const database_list: any = (obj.MySql || []).map(m=>m.name);
        //     const rdbms_keys = Object.keys(obj);
        //     const dataBricksObj = (data.Generic.Databricks?.Databricks || [] )as any;
        //     const databricks_database_list = dataBricksObj.map((m:any)=>m.name);

        //     const rdbmsFlat = rdbms_keys.map((key) => {
        //         return obj[key].map((db) => {
        //             return {
        //                 label: db.name,
        //                 value:  db.id,
        //                 _extra: db,
        //                 db_type: key
        //             }
        //         })
        //     }).flat()

        //     const temp1 = dataBricksObj.map((db: any) => {
        //         return {
        //             label: db.name,
        //             value:  db.id,
        //             _extra: db,
        //             db_type: "Databricks"
        //         }
        //     })
        //     const options:_selectoptionType[] = sortBy([...rdbmsFlat, ...temp1], ["label"]);

        //     setMainData({
        //         data: obj,
        //         rdbms_keys: rdbms_keys,
        //         database_list: [...database_list, ...databricks_database_list],
        //         options: options
        //     })
        //     setLoading(false)
            
        // })
        if(Env?.REACT_DEEP_SQL_URL){
        DataSourceHandler.GetAllDeepSqlConnectionsInfo(Env?.REACT_DEEP_SQL_URL, async (data) => {
            const options:_selectoptionType[] = data.map((db: any) => {
                return {
                    label: db.db_id,
                    value:  db.db_id,
                    _extra: db,
                    db_type: ""
                }
            });

            setMainData({
                data: {},
                rdbms_keys: [],
                database_list: [],
                options: options
            })
            setLoading(false)
            
        },(e)=>{
            setLoading(false)
            errorAlert(e.data)
        })
    }
    }, [Env?.REACT_DEEP_SQL_URL]);

    const onChange = (key: string) => {
        // console.log(key);
    };

    return (
        <section id="dataexplorer" className={styles["dataexplorer__container"]}>
            <LeftContainer 
                isLoading={isLoading}
                mainData={mainData}
                setTableSchemaData={setTableSchemaData}
            />
            {
                database.id !== -1 ? (
                    <TabsComponent 
                        onTabChange={onChange}
                        items={[
                            {
                                key: 'chat_ai',
                                label: 'ChatAI',
                                children: (
                                    <div className='flex'>
                                        <MiddleContainer 
                                            startDataFetch={startDataFetch}
                                            sqlDataError={dataError}
                                            tableSchemaData={tableSchemaData}
                                            fetchingData={fetchingData}
                                            cancelJob={cancelJob}
                                            clearError={clearError}
                                        />
                                        {
                                            isGenAiNoSpark ?
                                                <DataExplorerVisualization /> : <RightContainer 
                                                    sessionId={session.current}
                                                    previewStatementId={previewStatementId.current}
                                                />
                                        }
                                    </div>
                                )
                            }, {
                                key: 'context_editor',
                                label: 'Context Editor',
                                children: (
                                    <MetaData 
                                        mainData={mainData} 
                                        dbId={database.id} 
                                    />
                                )
                            }
                        ]}
                    />
                ) : (
                    <div className={classNames(styles["middle_container"], 'align-middle', 'text-xl')}>
                        <span>Please select a knowledge graph to proceed...</span>
                    </div>
                )
            }
        </section>
    );
};

export default DataExplorerLayout;