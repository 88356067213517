import React, { useState } from 'react';
import { Modal } from '../../../../components/modals';
import classnames from 'classnames';
import styles from '../../styles.module.scss';
import { NifiEdgeData } from '../types';
import { NifiForm } from './nifi-edge-form';
import { InPageSpinner } from '@components/spinners/in-page-spinner';
import { DeleteNifiEdge } from './confirm';
import { DataSourceHandler } from '@api/data-source-handler';
import { errorAlert, successAlert } from '@components/toastify/notify-toast';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

type NifiEdgeModalProps = {
    showModal: boolean;
    toggleClose: () => void;
    getNifiData: () => void;
    nifidata: NifiEdgeData | null
};


const NifiEdgeModal: React.FC<NifiEdgeModalProps> = ({
    showModal,
    toggleClose,
    nifidata,
    getNifiData
}) => {
    const [showLoader, toggleLoader] = useState(false);
    const [showDelLoader, toggleDelLoader] = useState(false);
    const [showDeletePopup, toggleDeletePopup] = useState(false);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const onSubmit = (values: any) => {
        toggleLoader(true);
        if(nifidata) {
            DataSourceHandler.UpdateEdgeNode(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, nifidata.nodeName, {
                "nodeName": values.edgeName,
                "nodeIp": "",
                "accessKey": values.accessKey,
                "sinkKind": values.sinkKind,
                "sinkInformation": values.sinkInformation,
                "category": "Edge",
                nodeKind: values.nodeKind,
                enableScaling: values.enableScaling,
			    scaleNodes: values.enableScaling?values.scaleNodes : null
            },(res) => {
                getNifiData();
                toggleLoader(false);
                successAlert("Successfully updated edge node")
                toggleClose();
            }, (e: any)=>{
                errorAlert(e.data);
                toggleLoader(false);
            });
        } else {
            DataSourceHandler.CreateEdgeNode(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`,
                {
                "nodeName": values.edgeName,
                "nodeIp": "",
                "accessKey": values.accessKey,
                "sinkKind": values.sinkKind,
                "sinkInformation": values.sinkInformation,
                "category": "Edge",
                nodeKind: values.nodeKind,
                enableScaling: values.enableScaling,
			    scaleNodes: values.enableScaling?values.scaleNodes : null
            },(res) => {
                getNifiData();
                toggleLoader(false);
                toggleClose();
                successAlert("Successfully created edge node")
            }, (e: any)=>{
                errorAlert(e.data);
                toggleLoader(false);
            });
        }
    };

    const isNifiDataNull =  nifidata === null;
    const onDelete = () => {
        toggleDelLoader(true);
        toggleDeletePopup(false);
        if(!isNifiDataNull && nifidata.nodeName){
            DataSourceHandler.DeleteEdgeNode(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, nifidata.nodeName, ()=>{
                getNifiData();
                toggleDelLoader(false);
                toggleClose();
            }, (e: any)=> {
                toggleDelLoader(false);
                errorAlert(e.data)
            });
        }  
    };
    const isDisabled =showLoader || showDelLoader; 
    return (
        <>
            <Modal
                isOpen={showModal}
                toggleClose={toggleClose}
                title={nifidata ? 'Edit Edge Information' : 'Add Edge Information'}
                className={classnames(
                    'editConnection_container',
                    styles['addEditNifiModal']
                )}
            >
                <div className={styles['addEditNifiModal__innerContainer']}>
                    <NifiForm 
                        type={isNifiDataNull ?  'ADD' : 'UPDATE' }
                        onSubmit={onSubmit}
                        initialValues={nifidata}
                        submitButtons={
                            <div className={styles['addEditNifiModal__actionBtns']}>
                                <button
                                    className="btn btn-lg btn-yellow"
                                    type="submit"
                                    disabled={isDisabled}
                                >
                                    {showLoader ?
                                        <InPageSpinner size="XSmall" color="black" className={styles['auto']} />
                                        :
                                        isNifiDataNull?  'Save' : 'Update' 
                                    }
                                </button>
                                {
                                    !isNifiDataNull ? (
                                        <button
                                            className="btn btn-lg btn-red"
                                            type="button"
                                            disabled={isDisabled}
                                            onClick={()=>{
                                                toggleDeletePopup(true);
                                            }}
                                        >
                                            {showDelLoader ?
                                                <InPageSpinner size="XSmall" color="black" className={styles['auto']} />
                                                :
                                                'Delete'
                                            }
                                        </button>
                                    ) : null
                                }
                                <button
                                    className="btn btn-lg"
                                    type="button"
                                    onClick={toggleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    >
                    </NifiForm>
                </div>
            </Modal>
            <DeleteNifiEdge 
                onConfirm={onDelete}
                showModal={showDeletePopup}
                title={!isNifiDataNull ? `Are you sure you what to delete the ${nifidata.nodeName || ''} edge?` : ''}
                toggleClose={()=>{
                    toggleDeletePopup(false);
                }}
                
            />
        </>
    );
};

export { NifiEdgeModal };
