
import { SelectField, _selectoptionType } from "@components/form/select-field";
import { useFormikContext } from "formik";
import React from "react";
import { BaseFieldType } from "./types";
import { RootState } from "@store/types";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Text } from "@components/ui/Text";
import { WorkspaceData } from "@pages/cluster_redisign/types";
import { WorkSpaceHandler } from "@api/workSpace-handler";
import { getAllClusters, setWorkspaceList } from "@store/cluster";
import { errorAlert, successAlert } from "@components/toastify/notify-toast";
import { TooltipTop } from "@components/tooltips";
import { Flex } from "@components/ui/Flex";

type WorkspaceFieldProps = {
	fieldData: BaseFieldType;
};

const WorkspaceField: React.FC<WorkspaceFieldProps> = ({ fieldData }) => {
	const dispatch = useDispatch();
	const { values, setFieldValue } = useFormikContext<any>();
	const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
	const enabledWorkspace = workSpaceData?.filter((item) => item.isEnabled)?.[0];
	const { envVariables: Env } = useSelector((store: RootState) => store.AccountReducer);
	const selectedWorkspace = workSpaceData?.filter((item) => item.workspaceName === values.databricks_conn_id)?.[0];
	const getFieldKey = (name: string) => fieldData.key + "." + name;

	const databricks_conn_id = getFieldKey("databricks_conn_id");

	const handleSelectDataBricksConnId = (option: _selectoptionType) => {
		setFieldValue("databricks_conn_id", option.value);
	};

	const getAllWorkSPaces = (item: WorkspaceData) => {
		WorkSpaceHandler.GetWorkSpaceList(`${Env.REACT_APP_PLATFORM_URL}/databricks/api`, (res) => {
			dispatch(setWorkspaceList(res));
		});
	}

	const onEnableWorkSpace = (item: WorkspaceData) => {
		const obj = {
			id: item.workspaceId,
			defaultWorkspace: !item.isEnabled,
		};
		setTimeout(() => {
			if (item && item.workspaceId) {
				WorkSpaceHandler.enableWorkSpace(
					`${Env.REACT_APP_PLATFORM_URL}/databricks/api`,
					obj,
					(res) => {
						getAllWorkSPaces(item);
						dispatch(getAllClusters());
						successAlert("Workspace Enabled Successfully");
					},
					(e: any) => {
						errorAlert(e.data.message);
					}
				);
			}
		}, 400);
	};
	const showEnableButton = !isEmpty(selectedWorkspace) && !selectedWorkspace?.isEnabled;

	return (
		<div className="workspaceField">
			<SelectField
				options={workSpaceData?.map((item) => ({ label: item.workspaceName, value: item.workspaceName })) || []}
				label={<Flex alignItems={"center"}><Text>Databricks Connection ID</Text> <TooltipTop title={fieldData.templateOptions.qtip}>
					<img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="" />
				</TooltipTop></Flex>}
				name={databricks_conn_id}
				initial_value={isEmpty(selectedWorkspace) ? enabledWorkspace?.workspaceName : values?.databricks_conn_id}
				onOptionClick={handleSelectDataBricksConnId}
			/>

			<div>
				{showEnableButton && <Text textAlign={"center"}>{`${values.databricks_conn_id} workspace is not enabled!`}</Text>}
				{showEnableButton && (<div className='center h-auto'>
					<button
						className="btn btn-sm btn-yellow"
						onClick={() => {
							const item = workSpaceData?.find((item) => item.workspaceName === values.databricks_conn_id);
							onEnableWorkSpace(item as WorkspaceData);
						}}
					>
						Enable
					</button>
				</div>
				)}
			</div>
		</div>
	);
};

export default WorkspaceField;
