/* eslint-disable @typescript-eslint/no-use-before-define */

import { BaseFieldType, SchemaCaptureField } from './types';
import { InputField as FormikInputField } from '../form';
import React, {
    useState,
    useEffect,
    useMemo,
    useCallback
} from 'react';
import { RadioField as FormikRadioField } from '../form/radio-field';
import { EditorField as FormikEditorField } from '../form/editor';
import { SelectField as FormikSelectField } from '../form';
import { useFormikContext, useField, FieldValidator } from 'formik';
import { _selectoptionType } from '../form/select-field';
import { useDidMount, useDidUpdate, useDebounce } from 'rooks';
import { HttpClient } from '../../api/services';
import { FieldSchemaCreator, FieldSchemaValidator } from './schema-creator';
import { StringSchema } from 'yup';
import {
    omit,
    has,
    range,
    isEqual,
    replace,
    get,
} from 'lodash';
// import { API, Env, gatewayUrl } from '../../constants/settings';
import classNames from 'classnames';
import { FileBrowserComponent } from '../form/file-browser';
import { DbfsFileBrowserComponent } from '../form/dbfs-file-browser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import isEmpty from 'lodash/isEmpty';
import _ from 'lodash';
import { InputFieldProps } from '../form/input-field';
import { FastField } from 'formik';
import {
    setAdditionalFormInfo,
    handleOpenWorkflowUsingWorkflowId,
} from '../../store/workflow';
import {
    FileBrowserIcon
} from './icons';
import { ShowWhenTrue } from '../../helpers';
import { MlPipeline } from './ml-pipeline';
import KeyPairTable from './fieldComponents/keyPairComponents';
import ComponentList from './fieldComponents/componentList';
import Table from './fieldComponents/table';
import Repeat from './fieldComponents/repeat';
import RepeatV2 from './fieldComponents/repeatV2';
import RepeatV3 from './fieldComponents/RepeatV3';
import { TooltipTop } from '../tooltips';
import WorkflowField from './WorkflowField';
import { EmptyComponent } from './fieldComponents/EmptyComponent';
import { FileBrowserModal } from '@pages/file-browser/modals/FileBrowserModal';
import { CaptureSchemaComponent } from './fieldComponents/CaptureSchemaComponent';
import { File, Folder } from '@components/FileExplorer/types';
import { uuid } from 'uuidv4';
import { useFileStorage } from '@pages/file-browser/hooks/useFileStorage';
import { BaseSelectField } from './fieldComponents/BaseSelectField';
import SoapField from './SoapField';
import UniqueValueField from './fieldComponents/UniqueValue';
import UpdateComponentNameButton from './UpdateComponentName';
import { NewDatabricksHandlerClass } from '@api/databricks-handler';
import { successAlert } from '@components/toastify/notify-toast';
import { useGetActiveTabInfo } from '@utils/get-active-tab-info';
import { WorkflowCanvasTabInfo } from '@store/canvas';
import { setPathInfo } from '@store/account';
import WorkspaceField from './workSpaceField';

export interface FieldsCreatorProps {
    formData: BaseFieldType[];
    uniqueKey?: string;
}
export interface FieldCreatorProps {
    fieldData: BaseFieldType;
    validate?: FieldValidator;
    captureSchemaOptions?: SchemaCaptureProps['captureSchemaOptions'];
    formData?: any; 
}

export interface InputFieldType extends FieldCreatorProps {
    type?: 'textarea' | 'text';
    rows?: number;
    captureSchemaOptions: SchemaCaptureProps['captureSchemaOptions'];
}



interface BaseEditorField extends FieldCreatorProps {
    type?: 'ace' | 'editor';
    rows?: number;
}

export type CaptureSchemaOption=  { label: string; value: string; dType: string }

export interface SchemaCaptureProps {
    fieldData: BaseFieldType;
    showDropdownFields: boolean;
    toggleShowDropdownFields: React.Dispatch<React.SetStateAction<boolean>>;
    captureSchemaOptions: [
        Record<string, CaptureSchemaOption[]>,
        React.Dispatch<React.SetStateAction<Record<string, CaptureSchemaOption[]>>>
    ];
    schemaCaptureProgress: [
        boolean,
        React.Dispatch<React.SetStateAction<boolean>>
    ];
    schemaData?: SchemaCaptureField;
    multipleSelection ?: boolean;
}

const fieldTypes = {
    string: 'text',
    number: 'float',
    integer: 'float',
    float: 'float',
    boolean: 'text',
    array: 'text'
};

export const FormikFileBrowserComponent: React.FC<{ fieldName: string }> = ({
    fieldName
}) => {
    const [showBrowser, toggleBrowser] = useState(false);

    // const { setFieldValue, initialValues } = useFormikContext<any>();
    const fieldProps = useField(fieldName);
    const initialPath = fieldProps[1].initialValue;
    const { setValue } = fieldProps[2];

    const toggleClose = () => toggleBrowser(false);

    const handleFileSelection = (filePath: string) => {
        setValue(filePath);
        toggleClose();
    };

    return (
        <>
            <button
                className="btn-yellow btn-sm btn-fileBrowser"
                onClick={() => toggleBrowser(true)}
                type="button"
            >
                <FileBrowserIcon />
            </button>
            <FileBrowserComponent
                initialPath={initialPath}
                show={showBrowser}
                toggleClose={toggleClose}
                onFileSelection={handleFileSelection}
                showAsModal
            />
        </>
    );
};

export const FormikDbfsFileBrowserComponent: React.FC<{ fieldName: string }> = ({
    fieldName
}) => {
    const [showBrowser, toggleBrowser] = useState(false);

    // const { setFieldValue, initialValues } = useFormikContext<any>();
    const fieldProps = useField(fieldName);
    const initialPath = fieldProps[1].initialValue;
    const { setValue } = fieldProps[2];

    const toggleClose = () => toggleBrowser(false);

    const handleFileSelection = (filePath: string) => {
        setValue(filePath);
        toggleClose();
    };

    return (
        <>
            <button
                className="btn-yellow btn-sm btn-fileBrowser"
                onClick={() => toggleBrowser(true)}
                type="button"
            >
                <FileBrowserIcon />
            </button>
            <DbfsFileBrowserComponent
                initialPath={initialPath}
                show={showBrowser}
                toggleClose={toggleClose}
                onFileSelection={handleFileSelection}
            />
        </>
    );
};

export const FormikDeltaLakesBrowserComponent: React.FC<{ fieldName: string }> = ({
    fieldName
}) => {
    const connectionList = useSelector((store: RootState) => store.NewDataSourceReducer.fileBrowserConnectionList);
    const enabledConnection = connectionList.filter((item) => item.isEnabled)[0];
    const storageConnectionTypeFilePrefixMap:Record<string,string> ={
        "S3":`s3://${enabledConnection?.bucketName}/FileSystem`,
        "AzureBlobStorage":`wasbs://${enabledConnection?.containerName}@${enabledConnection?.accountName}.blob.core.windows.net/FileSystem`,
        "AzureDataLake":`abfss://${enabledConnection?.containerName}@${enabledConnection?.accountName}.dfs.core.windows.net/FileSystem`
    }

    const [showBrowser, toggleBrowser] = useState(false);

    // const { setFieldValue, initialValues } = useFormikContext<any>();
    const fieldProps = useField(fieldName);

    const userInfo = useSelector(
		(store: RootState) => store.AccountReducer.activeUserInfo
	);

    const {
		files,
		folderChain,
		setFolderChain,
		loading,
		refreshFileList,
		selectedFiles,
		setSelectedFiles,
        fileSystemPrefix,
	} = useFileStorage("Azure");

    useEffect(() => {
        const initialPath = fieldProps[1].value;
        if (initialPath) {
            const path = initialPath.toString().replace(
                fileSystemPrefix + "/",
                ""
            );
            const initialFolderChain: Folder[] = path
                .split("/")
                .map(
                    (folderName: string): Folder => ({ id: uuid(), name: folderName })
                );
            initialFolderChain.pop();

            // Shared workflows may have files from other users.
            // This check will prevent loading those path.
            if(initialFolderChain.length === 0 || initialFolderChain[0].name !== userInfo.email) {
                return;
            }

            setFolderChain(initialFolderChain);
        }
    }, []);

    useEffect(() => {
        if(showBrowser && fieldProps[1].value){
            setSelectedFiles(files.filter(file => file.name === fieldProps[1].value.split("/").pop()));
        }
    }, [showBrowser])

    const { setValue } = fieldProps[2];

    const toggleClose = () => {
        toggleBrowser(false);
        dispatch(setPathInfo([{id:'default', name:'default'}]))
    };

    const handleFileSelection = (files: File[]) => {
        setValue(storageConnectionTypeFilePrefixMap[enabledConnection?.storageConnectionType] + files[0].path);
        toggleClose();
    };
    const dispatch=useDispatch()

    return (
        <>
            <button
                className="btn-yellow btn-sm btn-fileBrowser"
                onClick={() => (toggleBrowser(true), dispatch(setPathInfo([{id:'default', name:'default'}])))}
                type="button"
            >
                <FileBrowserIcon />
            </button>
            {/* <AzureDeltaLakesBrowserComponent
                initialPath={initialPath}
                show={showBrowser}
                toggleClose={toggleClose}
                onFileSelection={handleFileSelection}
            /> */}
            <FileBrowserModal
                showModal={showBrowser}
                toggleClose={toggleClose}
                onSelectClick={handleFileSelection}
                loading={loading}
                files={files}
                folderChain={folderChain}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                actionBarOptions={{ hideUpload: true , hideDownload:true, hideDelete: true }}
                onRefresh={refreshFileList}
                setFolderChain={setFolderChain}
                clearSelectionOnOutsideClick={false}
            />
        </>
    );
};




export function strictFunc(values: any, expression: string): any {
    'use strict';
    try {
        const tempFn = new Function('values', 'return ' + expression);
        return tempFn(values);
    } catch (e) {
        return false;
    }
}

export function useGetClassNameIfHidden(
    fieldData: BaseFieldType
): 'hide__formfield' | 'show__formfield' {
    const { values } = useFormikContext();
    let fieldClassName: 'hide__formfield' | 'show__formfield' = 'show__formfield';

    if (fieldData.templateOptions?.type === 'hidden') {
        fieldClassName = 'hide__formfield';
    } else {
        const hiddenExpression = fieldData.hideExpression;
        if (hiddenExpression && strictFunc(values, hiddenExpression))
            fieldClassName = 'hide__formfield';
    }
    return fieldClassName;
}

export function convertStringToStringLiteral(_string: string): string {
    // converts 'abcd ${url}' to "`abcd ${url}`"
    const convertedString = '`' + _string + '`';
    return convertedString;
}


const splitStringUsingSeparator = (
    stringToBeSeparated: string,
    separator: string
): string[] => {
    if (stringToBeSeparated) {
        return stringToBeSeparated.split(separator);
    }
    return [];
};

export const InputField: React.FC<InputFieldType> = ({
    fieldData,
    captureSchemaOptions,
    validate,
    ...props
}) => {
    const { values } = useFormikContext<any>();
    const { templateOptions } = fieldData;
    const _type = templateOptions.type === 'password' ? 'password' : templateOptions.variable_type
        ? fieldTypes[templateOptions.variable_type]
        : 'text';
    const _className = useGetClassNameIfHidden(fieldData);
    const connectionList = useSelector((store: RootState) => store.NewDataSourceReducer.fileBrowserConnectionList);
    const enabledConnection = connectionList.filter((item) => item.isEnabled);
    const activeTabInfo = useGetActiveTabInfo('workflowEditor') as WorkflowCanvasTabInfo | undefined;
    const workflowConfigItems = useMemo(() => {
		return (
			activeTabInfo?.config?.map((configItem) => ({
				...configItem,
				label: configItem.key,
				value: configItem.key,
			})) || []
		);
	}, [activeTabInfo?.config]);
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const validateFn = useCallback(() => { 
        if (validate) return validate;
    
        const schema = FieldSchemaCreator(fieldData.templateOptions, workflowConfigItems);
    
        return (value: string) => {
            const schemaValidation = FieldSchemaValidator(schema as StringSchema)(value);
    
            const trimmedValue = value.trim();
    
            if (value.length > 0 && trimmedValue.length === 0) {
                return "Field cannot contain only spaces";
            }
    
            if (value !== trimmedValue) {
                return "Field cannot contain trailing or leading spaces";
            }
    
            return schemaValidation;
        };
    }, [validate, fieldData.templateOptions, workflowConfigItems]);
    
    
    const fsTypekey = fieldData.file_type_fieldname || 'fs_type';
    const fsTypeValue = get(values, fsTypekey);
    const showFileBrowser = fieldData.useFileBrowser && Env?.REACT_APP_LOCALFILEBROWSER
        ? has(values, fsTypekey)
            ? fsTypeValue === 'local'
            : false
        : false;
        const storageConnectionTypeMap:Record<string,string>={
            "s3":"S3",
            "azure_blob":"AzureBlobStorage",
            "gen2":"AzureDataLake"
        }
    // const showDbfsFileBroswer = fieldData.useFileBrowser && Env.databricks
    //     ? has(values, fsTypekey)
    //         ? fsTypeValue === 'dbfs'
    //         : false
    //     : false;
    const showAzureDeltaLakes = fieldData.useFileBrowser && Env?.REACT_APP_AZURE_DELTALAKES
        ? has(values, fsTypekey)
            ? fsTypeValue === (Env?.REACT_APP_S3_ENABLED ? 's3' : 'azure_blob')
            : false 
        : false;
    const pathFiledKeys = ["file_location","path","urlpath","filename","foldername"];
    const showFilePickerBtn = enabledConnection.length > 0 && (enabledConnection[0]?.storageConnectionType === storageConnectionTypeMap[fsTypeValue] && pathFiledKeys.includes(fieldData.key));
    const showCaptureSchema = !!fieldData.useCaptureSchema;
    const showUpdateComponentNameButton = !!fieldData.showUpdateComponentName;
    const [showDropdownFields, toggleShowDropdownFields] = useState(false);
    const [isSchemaCaptureInProgress, toggleSchemaCaptureInProgress] = useState(false);
    
    if(fieldData.async?.save) {
        values.extraData = values.extraData || {}; 
        values.extraData[fieldData.key] = values[fieldData.key]?.split(',');
    }

    return (
        <div
            className={classNames(
                '__inputField',
                _className,
                // { 'useFileSelector': (showFileBrowser || showDbfsFileBroswer) && !(fieldData.readOnly) },
                { 
                    'useFileSelector': (showFileBrowser || showAzureDeltaLakes) && !(fieldData.readOnly),
                    'useCaptureSchema': showCaptureSchema && !(fieldData.readOnly),
                    'schemaCaptureInProgress': isSchemaCaptureInProgress,
                    showUpdateComponentNameButton
                }
            )}
            data-fieldlabelforvariable={fieldData.labelForVariable || templateOptions.label}
            data-fieldkey={fieldData.key}
            
        >
            {!showDropdownFields && (
                <FormikInputField
                    name={fieldData.key}
                    type={_type as InputFieldProps['type']}
                    label={templateOptions.label}
                    required={!!templateOptions.required}
                    placeholder={templateOptions.placeholder}
                    className={_className}
                    validate={
                        _className !== 'hide__formfield' &&
                        validateFn()
                    }
                    readOnly={!!fieldData.readOnly}
                    autoComplete="off"
                    infoText={fieldData.templateOptions.qtip}
                    {...props}
                />
            )}
            <ShowWhenTrue show={!(fieldData.readOnly)}>
                {showCaptureSchema && (
                    <CaptureSchemaComponent
                        fieldData={fieldData}
                        showDropdownFields={showDropdownFields}
                        toggleShowDropdownFields={toggleShowDropdownFields}
                        captureSchemaOptions={captureSchemaOptions}
                        schemaCaptureProgress={[
                            isSchemaCaptureInProgress,
                            toggleSchemaCaptureInProgress
                        ]}
                        multipleSelection={!!fieldData.useCaptureSchema?.multipleSelect}
                    />
                )}
                {showFileBrowser && (
                    <FormikFileBrowserComponent fieldName={fieldData.key} />
                )}
                {/* {showDbfsFileBroswer && (
                    <FormikDbfsFileBrowserComponent fieldName={fieldData.key} />
                )} */}
                {showFilePickerBtn && (
                    <FormikDeltaLakesBrowserComponent fieldName={fieldData.key} />
                )}
                {showUpdateComponentNameButton && (
                    <UpdateComponentNameButton fieldValue={get(values, fieldData.key)}  />
                )}
            </ShowWhenTrue>

        </div>
    );
};

const BaseEditorField: React.FC<BaseEditorField> = ({
    fieldData,
    ...props
}) => {
    const { templateOptions } = fieldData;
    const _type = (templateOptions.variable_type
        ? fieldTypes[templateOptions.variable_type]
        : 'text') as 'text' | 'number';
    const _className = useGetClassNameIfHidden(fieldData);
    const schema = FieldSchemaCreator(fieldData.templateOptions);
    const editor_type = templateOptions.editor_type
        ? templateOptions.editor_type
        : 'sql';
    return (
        <FormikEditorField
            name={fieldData.key}
            type={_type}
            editor_type={editor_type}
            label={templateOptions.label}
            required={!!templateOptions.required}
            className={classNames('__inputField', _className)}
            validate={
                _className !== 'hide__formfield' &&
                FieldSchemaValidator(schema as StringSchema)
            }
            readOnly={!!fieldData.readOnly}
            advance_sql={templateOptions.advance_sql}
            {...props}
            usecase={fieldData.usecase}
        />
    );
};

// this value is sent as props due to which value cannot be changed
const removeValueKeyFromFieldData = (fieldData: BaseFieldType) =>
    omit(fieldData, 'value');

const TextareaField: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    return (fieldData.ace || fieldData.editor) ? (
        <BaseEditorField
            fieldData={removeValueKeyFromFieldData(fieldData)}
            type="editor"
            rows={fieldData.templateOptions.rows}

        />
    ) : (
    // @ts-ignore
        <InputField
            fieldData={removeValueKeyFromFieldData(fieldData)}
            type="textarea"
            rows={fieldData.templateOptions.rows}
        />
    );
};

const RadioField: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    const _className = useGetClassNameIfHidden(fieldData);
    return (
        <FormikRadioField
            label={fieldData.templateOptions.label}
            name={fieldData.key}
            options={fieldData.templateOptions.options}
            readOnly={!!fieldData.readOnly}
            className={_className}
            inline={fieldData.templateOptions?.inline}
            infoText={fieldData.templateOptions.qtip}
        />
    );
};


const SelectField: React.FC<FieldCreatorProps> = ({ fieldData }) =>
    fieldData.async ? (
        <AsyncSelectField fieldData={fieldData} />
    ) : (
        <BaseSelectField
            fieldData={fieldData}
            options={fieldData.templateOptions.options}
        />
    );

const AsyncSelectField: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    const [options, setOptions] = useState(fieldData.templateOptions.options);
    const [apiResponse, setApiResponse] = useState<any>();
    const { setFieldValue, values } = useFormikContext<any>();
    const [resetOption, toggleResetOption] = useState(false);
    const dispatch = useDispatch();
    const { clusters } = useSelector((store: RootState) => store.ClusterReducer);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const triggerField = useMemo(() =>
        fieldData.async?.triggerField && strictFunc(values, fieldData.async.triggerField)
    , [values]);

    // const previousActiveComponentId = usePrevious(activeComponentInfoId);

    function handleOptionsSuccessResponse(response: any) {
        if (fieldData.async) {
            if (fieldData.async?.data_path) {
                response = eval('response.' + fieldData.async?.data_path);
                //TODO: Specific for Loop component implementation. Need to find a way for generalizing it.
                if (fieldData.async?.parserExpression) {
                    try {
                        response = JSON.parse(response);
                        response = eval(fieldData.async?.parserExpression);
                    } catch (e) {
                        response = {};
                    }
                }
            }
            if (fieldData.async?.data_type === 'array') {
                const _options: _selectoptionType[] = response.map((value: any) => ({
                    label: value,
                    value: value
                }));
                setOptions(_options);
            } else if (fieldData.async?.data_type === 'object') {
                // has only one option
                setOptions([{ label: response[fieldData.async.labelKey], value: response[fieldData.async.valueKey] }]);
            } else {
                const _value = fieldData.async.valueKey;
                let _options: _selectoptionType[] = [];
                if (fieldData.async?.labelTemplate) {
                    _options = response.map(
                        (each: any) => ({
                            label: strictFunc(each, convertStringToStringLiteral(fieldData.async?.labelTemplate || '')),
                            value: each[_value]
                        }));
                } else {
                    const _label = fieldData.async.labelKey;
                    _options = _label && _value && response.map(
                        (each: any) => ({
                            label: each[_label] ?? '',
                            value: each[_value]
                        }));
                }
                setOptions(_options);
            }
            setApiResponse(response);
        }
    }

    function makeApiCall(
        url: string,
        ignore_prefix = false,
        api_type = 'GET',
        api_data = {}
    ) {
        url = ignore_prefix ? Env?.REACT_APP_PLATFORM_URL + url : `${Env?.REACT_APP_PLATFORM_URL}/platform/api` + url;
        if (api_type === 'GET') {
            return HttpClient.Get(
                'Async select get field',
                url,
                handleOptionsSuccessResponse
            );
        }
        if (api_type === 'POST') {
            return HttpClient.Post(
                'Async select post field',
                url,
                api_data,
                handleOptionsSuccessResponse
            );
        }
    }

    function makeApiCallForTriggerFields() {
        if (triggerField !== undefined && triggerField !== '' && fieldData !== undefined) {
            // eval substitues the ${values.key}
            const actualUrl = fieldData.async?.url && strictFunc(values, convertStringToStringLiteral(fieldData.async?.url));
            if (actualUrl && !actualUrl.includes('undefined')) {
                if (fieldData.async?.type === 'GET' || isEmpty(fieldData.async?.type)) {
                    makeApiCall(actualUrl, fieldData.async?.ignore_prefix_url);
                }
                if (fieldData.async?.type === 'POST') {
                    const data: any = {};
                    if (typeof fieldData.async?.data === 'object') {
                        Object.keys(fieldData.async?.data).forEach((key) => {
                            // refer to witsml component in ri tree for better understanding
                            const keyInfo = fieldData.async?.data[key];
                            if (typeof (keyInfo) === 'string') {
                                data[key] = strictFunc(values, convertStringToStringLiteral(keyInfo));
                            } else if (typeof (keyInfo) === 'object') {
                                switch (keyInfo.type) {
                                    case 'string':
                                        data[key] = strictFunc(values, convertStringToStringLiteral(keyInfo.value));
                                        break;
                                    case 'array':
                                        // for multi-select fields where values are stores as comma separated strings
                                        data[key] = strictFunc(values, convertStringToStringLiteral(keyInfo.value)).split(',');
                                        break;
                                    case 'array-object': {
                                        const obj: Record<string, any> = {};
                                        Object.keys(keyInfo.structure).forEach(_key => {
                                            obj[_key] = strictFunc(values, convertStringToStringLiteral(keyInfo.structure[_key]));
                                        });
                                        break;
                                    }
                                }
                            }
                        });
                    }
                    makeApiCall(
                        actualUrl,
                        fieldData.async?.ignore_prefix_url,
                        'POST',
                        data
                    );
                }
            }
        }
    }

    const isHidden = fieldData.hideExpression && strictFunc(values, fieldData.hideExpression);


    useDidUpdate(() => {
        // TRIGGERED WHEN TRIGGER FIELD CHANGES AND ALSO WHEN FIELD IS SWITCHED FROM HIDDEN TO VISIBLE
        if (!isHidden && triggerField !== undefined) {
            //reset extraData
            values.extraData = values.extraData || {};
            values.extraData[fieldData.key] = null;
            setFieldValue('extraData' as never, values.extraData);
            setFieldValue(fieldData.key, '');
            toggleResetOption(!resetOption);
            makeApiCallForTriggerFields();
        }
    }, [triggerField, isHidden]);


    useDidMount(() => {
        if(fieldData?.key === "existing_cluster_id"){
            const _clusters: _selectoptionType[] = [];
            clusters.forEach(cluster => {
                if(cluster.state === 'RUNNING'|| cluster.state === 'RESIZING' )
                _clusters.push({ label: cluster.clusterName ?? '', value: cluster.databricksClusterId })
            })
            setOptions(_clusters);
        }else{
            if (fieldData.async && fieldData.async.init) {
                makeApiCall(fieldData.async.url, fieldData.async.ignore_prefix_url);
            }
            // when trigger field value is not empty
            !fieldData?.async?.init && makeApiCallForTriggerFields();
        }
    });

    useEffect(() => { 
        if(values?.existing_workflow === "True"){
            if(fieldData?.key === "existing_cluster_id"){
                const _clusters: _selectoptionType[] = [];
                clusters.forEach(cluster => {
                    if(cluster.state === 'RUNNING'|| cluster.state === 'RESIZING' )
                    _clusters.push({ label: cluster.clusterName ?? '', value: cluster.databricksClusterId })
                })
                setOptions(_clusters);
            }else{
                if (fieldData.async && fieldData.async.init) {
                    makeApiCall(fieldData.async.url, fieldData.async.ignore_prefix_url);
                }
                // when trigger field value is not empty
                !fieldData?.async?.init && makeApiCallForTriggerFields();
            }
        }
     },[values]);
   

    function handleOptionClick(option: _selectoptionType) {
        if (fieldData?.async?.output === '--all--') {
            values.extraData = values.extraData || {};
            values.extraData[fieldData.key] = values.extraData[fieldData.key] || [];
            const _value = fieldData.async?.valueKey;
            const selectedOption = apiResponse && _value &&
                apiResponse.find((each: any) => each[_value] === option.value);

            const index = values.extraData[fieldData.key].findIndex((_obj: any) => {
                return _obj[_value] === option.value;
            });
            if (index !== -1) {
                values.extraData[fieldData.key].splice(index, 1);
            } else {
                values.extraData[fieldData.key].push(selectedOption);
            }
            setFieldValue('extraData' as never, values.extraData);
            return;
        }
        const fieldNamesToBeAddedInJson = fieldData?.async?.output?.split(',');
        if (fieldNamesToBeAddedInJson && fieldNamesToBeAddedInJson.length > 1) {
            const _value = fieldData.async?.valueKey;
            const selectedOption = apiResponse && _value &&
                apiResponse.find((each: any) => each[_value] === option.value);
            const fieldExtraInfo: any = {
                _value: option.value
            };
            fieldNamesToBeAddedInJson && fieldNamesToBeAddedInJson.map(fieldName => (
                fieldExtraInfo[fieldName] = selectedOption[fieldName]
            ));
            if (fieldData.templateOptions.multipleOptionsSelection) {
                values.extraData = values.extraData || {};
                values.extraData[fieldData.key] = values.extraData[fieldData.key] || [];
                const index = values.extraData[fieldData.key].findIndex((_obj: any) => {
                    return _obj._value === option.value;
                });
                if (index !== -1) {
                    values.extraData[fieldData.key].splice(index, 1);
                } else {
                    values.extraData[fieldData.key].push(fieldExtraInfo);
                }
                setFieldValue('extraData' as never, values.extraData);
            } else {
                const obj: any = {};
                obj[fieldData.key] = fieldExtraInfo;
                setFieldValue('extraData' as never, Object.assign(values.extraData || {}, obj));
            }
        }
        if (fieldData.templateOptions.trigger) {
            fieldData.templateOptions.trigger?.forEach((_obj) => {
                setFieldValue(_obj.field, strictFunc(values, convertStringToStringLiteral(_obj.value)));
            });
        }
    }

    const onFilterTextChange = useDebounce((filterText: string) => {
        if (fieldData.async?.searchable) {
            let actualUrl = strictFunc(
                values,
                convertStringToStringLiteral(fieldData.async.url)
            );
            if (filterText) {
                filterText = encodeURIComponent(filterText);
                actualUrl += filterText;
            }
            makeApiCall(actualUrl, fieldData.async.ignore_prefix_url);
        }
    }, 300);

    const fieldValue = get(values, fieldData.key);

    const handleGoToWorkflow = () => {
        if(fieldValue) {
            const selectedWorkflowName = options.find(opt => opt.value === fieldValue)?.label;
            dispatch(handleOpenWorkflowUsingWorkflowId(fieldValue, 'Opening ' + selectedWorkflowName || 'Workflow'));
        }
    };

    const showSelectWorkflowBtn = useMemo(() => fieldData.templateOptions.label.includes('Select') && fieldData.templateOptions.label.includes('Workflow'), [fieldData.templateOptions.label]);
    const [isFetchingAllClusters,setIsFetchingAllClusters] = useState(false);

    const handleFetchAllClusters = useCallback(() =>{
        setIsFetchingAllClusters(true);
        NewDatabricksHandlerClass.GetAllDatabricksClusterList(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, (data) => {
            successAlert('Successfully fetched all databricks clusters');
            setIsFetchingAllClusters(false);
            const _options:_selectoptionType[] = data.map(item => ({ value: item.clusterId, label: item.clusterName }));
            setOptions(_options)
        });
    },[]);

    return (
        <div
            className={classNames('asyncSelectField__box', {'showSelectWorkflow': showSelectWorkflowBtn})}
        >
            <BaseSelectField
                fieldData={fieldData}
                options={options}
                onOptionClick={handleOptionClick}
                reset_options={resetOption}
                onFilterTextChange={onFilterTextChange}
                handleFilterInParent={!!(fieldData.async?.searchable)}
                handleOptions={handleFetchAllClusters}
                isFetchingAllClusters={isFetchingAllClusters}
            />
            <ShowWhenTrue show={showSelectWorkflowBtn}>
                <TooltipTop
                    title="Opens Selected Workflow"
                >
                    <button
                        className="btn-grey btn-selectWorkflow"
                        onClick={handleGoToWorkflow}
                        disabled={!fieldValue}
                        type="button"
                    >
                        <img src="/icons/workflow/open-workflow-btn.svg" alt="" />
                    </button>
                </TooltipTop>
            </ShowWhenTrue>
        </div>
    );
};

const MultiSelectField: React.FC<FieldCreatorProps> = ({
    fieldData,
    ...props
}) => {
    const _className = useGetClassNameIfHidden(fieldData);
    const _props = omit(props, 'captureSchemaOptions', 'connectedNodes');
    const [fieldsCount, setFieldsCount] = useState(1);
    const { values, setFieldValue, initialValues } = useFormikContext<any>();
    const activeComponentInfo = useSelector(
        (store: RootState) => store.WorkflowReducer.activeComponentInfo
    );
    const dispatch = useDispatch();

    useEffect(() => {
        // DO NOT ADD NUMBER OF OUTPUT PORTS AS DEPENDENCY AS IT'S CAUSING A SAVE ISSUE

        const _values = splitStringUsingSeparator(initialValues[fieldData.key], ',');
        if (!isEmpty(_values)) {
            const _fieldsCount = _values.length;

            setFieldsCount(_fieldsCount);
            const temp: any = {};

            range(0, _fieldsCount).forEach((_item, index) => {
                temp[fieldData.key + index] = _values[index];
            });

            if (!isEqual(initialValues, { ...initialValues, ...temp })) {
                dispatch(setAdditionalFormInfo({ ...initialValues, ...temp }));
            }
        }
    }, [activeComponentInfo, initialValues]);

    const valuesCB = useDebounce(() => {
        const _values: any = [];
        range(0, fieldsCount).forEach((_item, index) => {
            _values.push(values[fieldData.key + index]);
        });
        setFieldValue(fieldData.key, _values.join(','));
    }, 250);

    useEffect(() => {
        valuesCB();
    }, [values, fieldsCount]);

    return (
        <div className="multiSelectField">
            <div className="portTickers__main">
                <div className="portTicker__box">
                    <div className="portTicker__Btnbox">
                        <button
                            onClick={() => setFieldsCount(count => count - 1)}
                            disabled={fieldsCount === 1}
                            type="button"
                            className="decrement__btn"
                        >
                            <span>&#8722;</span>
                        </button>
                        <span className={classNames('countText', { disabled: false })}>
                            {fieldsCount}
                        </span>
                        <button
                            onClick={() => setFieldsCount(count => count + 1)}
                            type="button"
                            className="increment__btn"
                        >
                            <span>&#43;</span>
                        </button>
                    </div>
                </div>
            </div>
            {range(0, fieldsCount).map(_count => (
                <FormikSelectField
                    label={fieldData.templateOptions.label}
                    name={fieldData.key + _count}
                    className={_className}
                    required={!!fieldData.templateOptions.required}
                    disabled={fieldData.disabled}
                    options={fieldData.templateOptions.options}
                    key={fieldData.key + _count}
                    infoText={fieldData.templateOptions.qtip}
                    {..._props}
                />
            ))}
        </div>
    );
};

const PrefixSuffixFeild: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    const {  values } = useFormikContext<any>();
    const [joinFields, setJoinFields] = useState([] as any);
    const _className = useGetClassNameIfHidden(fieldData);
    const repeatOptions = fieldData.repeatOptions;
    const listenToPort = repeatOptions?.listenToPort;
    useEffect(() => {
        if (listenToPort) {
            const arr: BaseFieldType[] = [];
            const port_type = repeatOptions?.listenToPort === 'input' ? 'num_ip_ports' : 'num_op_ports';
            Array.from(new Array(parseInt(values[port_type] || 0, 10))).map((_items: any, index: number) => {
                fieldData.fields?.forEach((field) => {
                    const temp = _.cloneDeep(field);
                    temp.key = temp.key + '_' + index;
                    if (temp.hideExpression) {
                        temp.hideExpression = replace(temp.hideExpression || '', /{index}/g, index + '');
                    }
                    if (temp.templateOptions && temp.templateOptions.labelTemplate) {
                        const _index = (repeatOptions?.indexStartFrom || 0) + index;
                        temp.templateOptions.label = replace(temp.templateOptions.labelTemplate || '', /{index}/g, _index + '');
                        delete temp.templateOptions.labelTemplate;
                    }
                    arr.push(temp);
                });
            });
            setJoinFields(arr);
        }
    }, [values['num_ip_ports'], values['num_op_ports']]);

    const debouncCB =  useDebounce(()=>{
        const arr: any = [];
        if (listenToPort) {
            const port_type = repeatOptions?.listenToPort === 'input' ? 'num_ip_ports' : 'num_op_ports';
            Array.from(new Array(parseInt(values[port_type] || 0, 10))).forEach((key1, index) => {
                const obj: any = {};
                fieldData.fields?.forEach((fields) => {
                    obj[fields.key] = values[fields.key + '_' + index];
                });
                arr.push(obj);
            });
            values[fieldData.key] = JSON.stringify(arr);
        }
    }, 1000);

    useEffect(()=>{
        debouncCB();
    }, [values]);

    return (
        <div
            className={classNames(_className)}
        >
            {
                joinFields?.map((item: any) => {
                    return (<FieldsCreator key={item.key} formData={[item]} />);
                })
            }
        </div>

    );
};

export const componentFields: Record<BaseFieldType['type'], React.FC<FieldCreatorProps> | React.ComponentType<any>> = {
    input: InputField,
    textarea: TextareaField,
    radio: RadioField,
    select: SelectField,
    'ng-select': SelectField,
    'select-multiple': SelectField,
    'date-picker': InputField,
    captureSchemaComponent: CaptureSchemaComponent,
    table: Table,
    repeat: Repeat,
    'repeat-v3': RepeatV3,
    'repeat-v2': RepeatV2,
    label: FastField,
    'multi-select': MultiSelectField,
    'component-list': ComponentList,
    'prefixSuffix': PrefixSuffixFeild,
    'ml-pipeline': MlPipeline,
    'keyvaluetable': KeyPairTable,
    workflow: WorkflowField,
    "workspaceSelect":WorkspaceField,
    "unique-id": EmptyComponent,
    soap: SoapField,
    "unique-value": UniqueValueField
};

export const FieldsCreator: React.FC<FieldsCreatorProps> = ({
    formData,
    uniqueKey = '',
}): JSX.Element => {

    const [optionsForSchemaCapture, setOptionsForSchemaCapture] = useState<
        Record<string, CaptureSchemaOption[]>
    >({});

    if(!Array.isArray(formData)) return <></>;

    return (
        <>
            {formData.map((fieldData: BaseFieldType, index) => {
                const fieldType = fieldData.type;
                const _Field = get(componentFields, fieldType) || InputField;
                const _key = uniqueKey + fieldData.key + fieldData.type + index;
                return (
                    <_Field
                        key={_key}
                        fieldData={removeValueKeyFromFieldData(fieldData)}
                        captureSchemaOptions={[
                            optionsForSchemaCapture,
                            setOptionsForSchemaCapture
                        ]}
                        formData={formData}
                    />
                );
            })}
        </>
    );
};
