import { SubmitStatementData, SessionInfo, StatementInfo, ColumnsListResponse, SubmitPlotInfoData,
    PlotResponse, CreateSessionData, SessionStates, } from './types';
import { HttpClient } from '../services';
// import { API, Env } from '../../constants/settings';
import { ApiPromise} from '../data-source-handler';
import { errorHandlerCallback } from '../services/httpinvoker';

class AnalyticsHandlerClass {
    // url: string;
    // constructor() {
    //     const Env=store.getState().AccountReducer.envVariables;
    //     this.url = Env?.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
    // }

    StartSession(link: string, data: CreateSessionData, callback: (arg0: SessionInfo) => any, errorHandlerCallBack: any) {
        HttpClient.Post('Start a analytics session', `${link}/sessions/create`, data, callback, errorHandlerCallBack);
    }

    StartSessionPromise(link: string, data: CreateSessionData): ApiPromise<SessionInfo> {
        return HttpClient.PostPromise(`${link}/sessions/create`, data);
    }

    // StartSessionWithoutParams(): ApiPromise<SessionInfo> {
    //     return HttpClient.GetPromise(`${API.url}/sessions/create`);
    // }

    SubmitStatement(link: string, data: SubmitStatementData, callback: (arg0: StatementInfo) => any, errorHandlerCallBack: any) {
        HttpClient.Post('Submit statement for analytics', `${link}/sessions/submit-statement`, data, callback, errorHandlerCallBack);
    }

    SubmitStatementPromise(link: string, data: SubmitStatementData): ApiPromise<StatementInfo> {
        return HttpClient.PostPromise(`${link}/sessions/submit-statement`, data);
    }

    // GetStatementInfo(workflowSessionId: string, statementId: string, callback: (arg0: StatementInfo) => any) {
    GetStatementInfo(link: string, workflowSessionId: string, statementId: string): ApiPromise<StatementInfo> {
        // HttpClient.Get('Get statement info', `${API.url}/sessions/${workflowSessionId}/statements/${statementId}`, callback);
        return HttpClient.GetPromise(`${link}/sessions/${workflowSessionId}/statements/${statementId}`);
    }

    GetComponentColumnsList(link: string, workflowSessionId: any, componentId: string, targetComponentId: string, callback: (arg0: ColumnsListResponse) => any, errorCb: errorHandlerCallback) {
        HttpClient.Get('Get statement info', `${link}/batches/schema_capture/dataframe/${workflowSessionId}/${componentId}/${targetComponentId}`, callback, errorCb);
    }

    SubmitPlot(link: string, data: SubmitPlotInfoData, callback: (arg0: StatementInfo) => any, errorHandlerCallBack: errorHandlerCallback) {
        HttpClient.Post('Submit statement for analytics', `${link}/sessions/submit-plot`, data, callback, errorHandlerCallBack);
    }

    SubmitPlotPromise(link: string, data: SubmitPlotInfoData) {
        return HttpClient.PostPromise(`${link}/sessions/submit-plot`, data);
    }

    GetPlotData(link: string, workflowSessionId: string, statementId: string, callback: (arg0: PlotResponse) => any, errorHandlerCallBack: (arg0: any) => any) {
        HttpClient.Get('Get plot info', `${link}/sessions/plot-data/${workflowSessionId}/${statementId}`, callback, errorHandlerCallBack);
    }

    KillSession(link: string, workflowSessionId: string) {
        HttpClient.Delete('kill a session', `${link}/sessions/${workflowSessionId}`, () => { return; });
    }

    GetSessionInfo(link: string, workflowSessionId: string): ApiPromise<SessionInfo> {
        // HttpClient.Get('get session status', `${API.url}/sessions/${workflowSessionId}/state`, callback);
        return HttpClient.GetPromise(`${link}/sessions/${workflowSessionId}`);
    }

    // GetSessionStatus(workflowSessionId: string): ApiPromise<SessionStates> {
    //     // HttpClient.Get('get session status', `${API.url}/sessions/${workflowSessionId}/state`, callback);
    //     return HttpClient.GetPromise(`${this.url}/sessions/${workflowSessionId}/state`);
    // }

    GetErrorLogs(link: string, workflowSessionId: string, statementId: string, callback: (arg0: any) => any, errorHandlerCallBack: (arg0: any) => any) {
        HttpClient.Get('get Error logs ', `${link}/sessions/${workflowSessionId}/statements/${statementId}/log`, callback, errorHandlerCallBack);
    }

    GetPlotDataStatus(link: string, workflowSessionId: string, statementId: string, callback: (arg0: any) => any, errorHandlerCallBack: (arg0: any) => any) {
        HttpClient.Get('get Error logs ', `${link}/sessions/${workflowSessionId}/statements/${statementId}/progress`, callback, errorHandlerCallBack);
    }

    GetPlotDataStatusPromise(link: string, workflowSessionId: string, statementId: string) {
        return HttpClient.GetPromise(`${link}/sessions/${workflowSessionId}/statements/${statementId}/progress`);
    }

    GetLocalMap(callback: (arg0: any) => any) {
        HttpClient.Get('get Error logs ', 'http://127.0.0.1:5000/bokeh', callback);
    }
}

const AnalyticsHandler = new AnalyticsHandlerClass();

export { AnalyticsHandler };