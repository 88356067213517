/* eslint no-console: "off" */

// import { API } from '../constants/settings';
import { Client, messageCallbackType, StompSubscription } from '@stomp/stompjs';
import { errorAlert } from '../components/toastify/notify-toast';

type ComponentSubscriptionProps = {
	type: 'PREVIEW' | 'PLOT';
	sessionId: string;
	componentId: string;
	callback: messageCallbackType;
};
type ComponentUnSubscriptionProps = Omit<
	ComponentSubscriptionProps,
	'callback'
	> & { componentSubscription: StompSubscription };

class SocketHandlerClass {
client!: Client;

private handleConnectToSocket(): boolean {
    try {
        if (!this.client) {
            // console.log(API.socketUrl);
            this.client = new Client({
                brokerURL: 'wss://ds-dev.deepiq.com/websocket/websocket',
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
            });
        }
        if (!this.client.active) {
            this.client.activate();
        }
        return true;
    } catch {
        errorAlert('Couldn\'t establish a socket connection');
        return false;
    }
}

Disconnect() {
    this.client.deactivate();
}

Connect(): Promise<boolean> {
    // Connection takes time to establish, calling subscribe immediately after handleConnectToSocket crashes the app
    return new Promise((resolve, reject) => {
        let retries = 0;
        if(this.handleConnectToSocket()) {
            const intervalId = setInterval(() => {
                if(this.client.connected) {
                    clearInterval(intervalId);
                    resolve(true);
                } else if(retries > 10) {
                    errorAlert('Couldn\'t establish a socket connection');
                    clearInterval(intervalId);
                    reject(false);
                }
                retries++;
            }, 1000);
        } else {
            reject(false);
        }
    });
}

UnSubscribeForComponentData({
    type,
    componentSubscription,
    sessionId,
    componentId,
}: ComponentUnSubscriptionProps) {
    const body = JSON.stringify({
        sessionId,
        componentId,
        type,
        action: 'LEAVE',
    });
    this.client.publish({ destination: '/ws/message', body });
    componentSubscription.unsubscribe();
}

SubscribeForComponentData({
    sessionId,
    componentId,
    type,
    callback,
}: ComponentSubscriptionProps): StompSubscription {

    // console.log(this.client.active)
    // console.log(this.client.connected)

    const body = JSON.stringify({
        sessionId,
        componentId,
        type,
        action: 'JOIN',
    });
    this.client.publish({ destination: '/ws/message', body });
    return this.client.subscribe(`/topic/${type.toLowerCase()}/${sessionId}/${componentId}`, callback);
}
}



const SocketHandler = new SocketHandlerClass();

export { SocketHandler };
