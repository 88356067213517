import React from "react";
import styles from "../../../styles.module.scss";
import { SchedulePipelinesForm } from "@pages/workflow-page/SchedulePipelinesForm";
import { _canvasdagFormInitialValues } from "@pages/workflow-page/utils";
import { WorkflowCanvasTabInfo } from "@store/canvas";

interface HierarchyFormProps {
    activeStep: number;
    setActiveStep: (step: number) => void;
    handleOnSubmit: (values: any) => void;
}

const HierarchyScheduleForm: React.FC<HierarchyFormProps> = ({
    activeStep,
    setActiveStep,
    handleOnSubmit
}) => {

    const handleScheduleRhsFormSubmit = (values: WorkflowCanvasTabInfo['scheduleInfoForDag']) => {
        const tmp = ""
    }

    const handleScheduleDagWorkflow = (values?: WorkflowCanvasTabInfo['scheduleInfoForDag']) => {
        const tmp = ""
    }

    return (
        <>
            <div className={styles.jsonHierarchyForm}>
                <SchedulePipelinesForm
                    dagFormInitialValues={_canvasdagFormInitialValues}
                    handleScheduleDagWorkflow={handleScheduleDagWorkflow}
                    handleScheduleRhsFormSubmit={
                        handleScheduleRhsFormSubmit
                    }
                    isJobScheduled={false}
                    isHierarchy={true}
                    isDagEnv={true}
                />
            </div>

            <div className={styles.hierarchyFormFooter}>
                <button type="button" className="btn btn-grey btn-md" onClick={() => { setActiveStep(activeStep - 1); }}>
                    Back
                </button>
                <button type="button" onClick={handleOnSubmit} className="btn btn-yellow btn-md" >
                    Publish
                </button>
            </div>
        </>
    );
};

export default HierarchyScheduleForm;
