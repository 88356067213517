import React, { useState, useEffect } from "react";
import styles from "../../../styles.module.scss";
import { List, Row, Col } from "antd-latest";
import { Checkbox } from "antd";
import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";

interface SelectedTagEditProps {
    selectedTags: string[];
    setFieldValue: (field: string, value: string) => void;
}

const SelectedTagEdit: React.FC<SelectedTagEditProps> = ({ selectedTags, setFieldValue }) => {
    const [search, setSearch] = useState("");
    const [checkedList, setCheckedList] = useState<string[]>([]);
    const [editableValues, setEditableValues] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setEditableValues(Object.fromEntries(selectedTags.map((item) => [item, item])));
        setCheckedList(selectedTags);
    }, [selectedTags]);

    const filteredItems = selectedTags?.filter((item) =>
        item.toLowerCase().includes(search.toLowerCase())
    );

    const handleCheck = (checked: boolean, item: string) => {
        setCheckedList((prev) =>
            checked ? [...prev, item] : prev.filter((i) => i !== item)
        );
    };

    const handleSelectAll = (checked: boolean) => {
        setCheckedList(checked ? [...selectedTags] : []);
    };

    const handleInputChange = (value: string, item: string) => {
        setEditableValues((prev) => ({ ...prev, [item]: value }));
    };

    const handleReset = () => {
        setCheckedList(selectedTags);
        setEditableValues(Object.fromEntries(selectedTags?.map((item) => [item, item])));
    };

    const handleApply = () => {
        const finalValues = checkedList.map((item) => editableValues[item]).join(", ");
        setFieldValue("tags", finalValues);
    };

    return (
        <Flex flexDirection={"column"} gap="0px">
            <input
                onChange={(e) => setSearch(e.target.value)}
                width={"200px"}
                className="search_input"
                placeholder="Search..."
                value={search}
            />
            <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Checkbox
                    className={styles["checkbox-tag"]}
                    checked={checkedList.length === selectedTags?.length}
                    indeterminate={checkedList.length > 0 && checkedList.length < selectedTags?.length}
                    onChange={(e) => handleSelectAll(e.target.checked)}

                >
                    Select All
                </Checkbox>
                <button type="button" style={{ marginTop: 8 }} onClick={handleReset} className="btn btn-grey btn-sm">
                    Reset
                </button>
            </Flex>
            <List
                className={styles["tag_checks_container"]}
                dataSource={filteredItems}
                renderItem={(item, index) => {
                    if (index % 2 === 0) {
                        return (
                            <Row gutter={8} >
                                <Col span={12}>
                                    <Flex flexDirection={"row"} gap="10px" alignItems={"center"}>
                                        <Checkbox
                                            className={styles["checkbox-tag"]}
                                            checked={checkedList.includes(filteredItems[index])}
                                            onChange={(e) => handleCheck(e.target.checked, filteredItems[index])}
                                        />
                                        {checkedList.includes(filteredItems[index]) ? (

                                            <input
                                                onChange={(e) => handleInputChange(e.target.value, filteredItems[index])}
                                                className={styles["tag_input"]}


                                                value={editableValues[filteredItems[index]]}
                                            />
                                        ) : (
                                            <Text color={"white"}>{filteredItems[index]}</Text>
                                        )}
                                    </Flex>
                                </Col>
                                {filteredItems[index + 1] && (
                                    <Col span={12}>
                                        <Flex flexDirection={"row"} gap="10px" alignItems={"center"}>
                                            <Checkbox

                                                className={styles["checkbox-tag"]}
                                                checked={checkedList.includes(filteredItems[index + 1])}
                                                onChange={(e) => handleCheck(e.target.checked, filteredItems[index + 1])}
                                            />
                                            {checkedList.includes(filteredItems[index + 1]) ? (

                                                <input
                                                    onChange={(e) => handleInputChange(e.target.value, filteredItems[index + 1])}
                                                    className={styles["tag_input"]}


                                                    value={editableValues[filteredItems[index + 1]]}
                                                />
                                            ) : (
                                                <Text color={"white"}>{filteredItems[index + 1]}</Text>
                                            )}
                                        </Flex>
                                    </Col>
                                )}
                            </Row>
                        );
                    }
                    return null;
                }}
            />
            <Flex gap='20px' justifyContent={"end"}>
                {/* <button type="button" onClick={handleReset} className="btn btn-grey btn-sm">
                    Reset
                </button> */}
                {/* <button type="button" onClick={handleApply} className="btn btn-yellow btn-sm" >
                    Save Changes
                </button> */}
            </Flex>
        </Flex>
    );
};

export default SelectedTagEdit;
