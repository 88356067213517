import React, { useMemo } from 'react';
import { Modal } from '../../../components/modals';
import { WorkflowHandler } from '../../../api/workflow-handler';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal, resetWorkflowsSelection, setActiveProfile, setActiveComponentPropsState, getWorkflowDirectories, callSuccessCallbackFunc } from '../../../store/workflow';
import { RootState } from '../../../store/types';
import { capitalize, toInteger, cloneDeep } from 'lodash';
import { closeWorkflowEditorAnalyticsTab, updateOpenTabsOfACanvas } from '../../../store/canvas';
import classNames from 'classnames';
import { InformativeIcon } from '../assets/icons';
import { ShowWhenTrue } from '../../../helpers';
import { getActiveWorkflowType } from '../../../utils';

interface DeleteWorkflowProps {
    handleDeleteWorkflowSuccess?: (arg0: number[]) => any;
    retrieveWorkflowsOnSuccess: (arg0: any) => any;
}

export const DeleteWorkflowModal: React.FC<DeleteWorkflowProps> = ({ retrieveWorkflowsOnSuccess }) => {
    const dispatch = useDispatch();
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.deleteWorkflow);
    const openTabs = useSelector((store: RootState) => store.CanvasReducer.workflowEditor.openTabs);
    const {  modalType, selectionType, singleItemInfoForModals, workflowsSelectedState } = useSelector((store: RootState) => store.WorkflowReducer);
    const activeEnvDirectoriesInfo = useSelector((store: RootState) => store.AccountReducer.userDirectories[store.CommonReducer.activeExecutionEnv]);
    const isWorkflowsActiveTab = modalType === 'workflow';
    const activeProfile = useSelector((store: RootState) => store.WorkflowReducer.activeProfile);
    const activeExecutionEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv);
    const { envVariables: Env } = useSelector((store: RootState) =>  store.AccountReducer);

    function toggleClose() {
        dispatch(toggleModal('deleteWorkflow', false));
    }

    const handleWorkflowDeleteSuccess = (deletedWorkflowIds: number[]) => {
        deletedWorkflowIds.forEach(id => {
            // clear deleted workflows from openTabs if open
            if (openTabs.has(id)) {
                dispatch(closeWorkflowEditorAnalyticsTab('workflowEditor' ,id));
            }
        });
        // retrieved after a while as backend takes time to update
        setTimeout(() => retrieveWorkflowsOnSuccess({}), 1000);
        dispatch(resetWorkflowsSelection());
        toggleClose();
        dispatch(setActiveComponentPropsState({ show: false }));
    };

    function handleDeleteWorkflows(ids: number[]) {
        WorkflowHandler.DeleteWorkflow(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, ids, handleWorkflowDeleteSuccess(ids));
    }

    const handleDeleteProfileSuccess = (ids: number[]) => {
        if(activeProfile){
            ids.forEach((id) => {
                if(id === activeProfile.id) dispatch(setActiveProfile(null));
            });
        }
       
        setTimeout(() => {
            dispatch(callSuccessCallbackFunc());
        }, 500);
        dispatch(resetWorkflowsSelection());
        toggleClose();
        
    };

    function handleDeleteProfiles(this: any, ids: number[]) {
        WorkflowHandler.DeleteProfiles(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, ids, handleDeleteProfileSuccess.bind(this, ids));
    }

    const isDirectoryType = modalType === 'directory';

    const handleDeleteDirectory = (moveProjectsToDefaultDirectory: boolean) => {
        WorkflowHandler.DeleteDirectory(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, singleItemInfoForModals.id, moveProjectsToDefaultDirectory, () => {
            dispatch(getWorkflowDirectories());
            if(moveProjectsToDefaultDirectory) {
                const currentUserDefaultDirectoryId = activeEnvDirectoriesInfo.defaultDirectoryId;
                if(currentUserDefaultDirectoryId) {
                    let tabsNeedToBeUpdated = false;
                    const updatedTabs = cloneDeep(openTabs);
                    updatedTabs.forEach(tabInfo => {
                        if(tabInfo.info.directoryId === singleItemInfoForModals.id) {
                            tabsNeedToBeUpdated = true;
                            tabInfo.info.directoryId = currentUserDefaultDirectoryId;
                        }
                    });
                    if(tabsNeedToBeUpdated)
                        dispatch(updateOpenTabsOfACanvas('workflowEditor', updatedTabs));
                }
            } else {
                // CLOSE TABS THAT BELONG TO THE DELETED DIRECTORY
                openTabs.forEach((tabInfo, tabId) => {
                    if(tabInfo.info.directoryId === singleItemInfoForModals.id)
                        dispatch(closeWorkflowEditorAnalyticsTab('workflowEditor', tabId as number));
                });
            }
            toggleClose();
        });

    };

    const handleDelete = () => {
        if(modalType === 'canvasItems') {
            dispatch(callSuccessCallbackFunc());
            toggleClose();
        } else {
            let _selectedIds: number[] = [];
            if(selectionType === 'multiple') {
                const _selectedWorkflows  = Object.entries(workflowsSelectedState).filter(([, workflow]) => workflow.status);
                _selectedIds = _selectedWorkflows.map(workflow => toInteger(workflow[0]));
            } else {
                _selectedIds = [singleItemInfoForModals.id];
            }
            return isWorkflowsActiveTab ? handleDeleteWorkflows(_selectedIds) : handleDeleteProfiles(_selectedIds);
        }
       
    };

    const showMultipleContent = selectionType === 'multiple';

    const { modalMessage } = useMemo(() => {
        let modalTitle = 'Delete '; 
        let modalMessage = '';
        const activeWorkflowType = getActiveWorkflowType(activeExecutionEnv);
        const __modalType = modalType === 'workflow' ? activeWorkflowType :modalType;
        const heading=activeWorkflowType == 'local'?'Workflow':activeWorkflowType;
        if(modalType === 'directory') {
            modalMessage += ' directory and the ' + heading  +'s inside?';
        } else { 
            if(modalType === 'canvasItems') {
                modalTitle += 'Component';
            } else  {
                modalTitle += capitalize(__modalType);
            }
            if(showMultipleContent) {
                modalTitle += 's';
                modalMessage = ` selected ${modalType === 'canvasItems' ? 'Components' : __modalType}?`;
            } else {
                modalMessage = ` ${singleItemInfoForModals.name} ${modalType === 'canvasItems' ? 'Component': __modalType}?`;
            }
        }
        return { modalTitle, modalMessage };
    }, [modalType, showMultipleContent, singleItemInfoForModals, activeExecutionEnv]);


    const showDirectoryMessageInfo = isDirectoryType && singleItemInfoForModals.workflowCount && singleItemInfoForModals.workflowCount > 0;

    return(
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            title=""
            className="deleteWorkflowModal deleteMultipleItemsModal"
        >
            <p className="deleteWorkflowModal__areYouSureMsg">
                Are you sure you want to delete the 
                {modalMessage}
            </p>
            {
                showDirectoryMessageInfo ?
                    <div
                        className="preserveWorkflows__msg"
                    >
                        <InformativeIcon />
                        <span>
                            &lsquo;Preserve Workflows&rsquo; deletes just the directory and moves all the workflows to the default directory. 
                        </span>
                    </div>
                    :
                    <p className="deleteWorkflowModal__warningMsg">
                        Warning: {isDirectoryType ? singleItemInfoForModals.name + ' will be permanently deleted': 'You can\'t undo this action!'}
                    </p>
            }            
            <div className={classNames('modalBtns__box', {'deleteDirectory': isDirectoryType})}>
               
                {isDirectoryType ?
                    <>
                        <button 
                            className={classNames('btn-md', {'btn-yellow': !showDirectoryMessageInfo})}
                            onClick={() => handleDeleteDirectory(false)}
                            type="submit"
                            aria-label="confirm-delete"
                        >
                            Yes, Delete
                        </button>
                        <ShowWhenTrue show={!!showDirectoryMessageInfo}>
                            <button 
                                className="btn-md btn-yellow"
                                onClick={() => handleDeleteDirectory(true)}
                                type="submit"
                                aria-label="confirm-delete"
                            >
                                Preserve Workflows
                            </button>
                        </ShowWhenTrue>
                        <button 
                            className="btn-md btn-grey"
                            type="button"
                            onClick={toggleClose}
                        >
                            Cancel
                        </button>
                    </>
                    :
                    <>
                        <button 
                            className="btn-md btn-yellow"
                            onClick={handleDelete}
                            type="submit"
                            aria-label="confirm-delete"
                        >
                            Yes
                        </button>
                        <button 
                            className="btn-md btn-cancel"
                            type="button"
                            onClick={toggleClose}
                        >
                            Cancel
                        </button>  
                    </>   
                }
                
            </div>
        </Modal>
    );
};