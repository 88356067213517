import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { UserDetails } from "@components/header-sidebar/user-details";
import { FileManagement } from "./ui/FileManagement";
import { ConnectionList } from "./ui/ConnectionList";
import { StorageConnectionModal } from "./ui/StorageConnectionModal";
import "./styles.scss";
import { FileHandler } from "@api/file-handler";
import { StorageConnectionData } from "./types";
import { DeleteStorageConnection } from "./modals/confirm";
import { errorAlert, successAlert } from "@components/toastify/notify-toast";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import JoyRide, { ACTIONS, EVENTS, STATUS, Step } from "react-joyride";
import { tourStyle } from "@components/coachmark";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/types";
import { InitHandler } from "@api/init";
import { FILE_BROWSER_COUCHMARK_STATUS } from "@store/coachMark/constants";
import { useMountedRef } from "../../hooks/mount";
import { useAppSelector } from "@store/hooks";
import { isEmpty } from "lodash";
import { setFileBrowserConnectionList } from "@store/datasources";
import { Env } from "@constants/settings";
import { ZoomInViewBlack } from "@assets/icons";

const TOUR_STEPS: Step[] = [
    {
      target: "#Btn_NewConnection",
      content: "Click here to open connection popup",
	  disableBeacon: true
    },
    {
      target: ".runWorkflowModal__container",
      content: "Please enter all details to create a connection.",
	  disableBeacon: true
    },
	{
		target: ".left_container .list_container .ant-list-items",
		content: "List of connection available",
		disableBeacon: true,
		floaterProps: {
			placement: "bottom"
		}
	},
	{
		target: "#file_upload",
		content: "Click here to upload file(s)",
		disableBeacon: true
	},
	{
		target: ".search_file",
		content: "Enter text to filter the file",
		disableBeacon: true
	},
	{
		target: '.react-contextmenu-wrapper > table',
		content: "Show the list of files present",
		disableBeacon: true
	}
];

const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
	run: false,
	continuous: true, // Show next button
	loading: false,
	stepIndex: 0, // Make the component controlled
	steps: TOUR_STEPS,
	hideBackButton: true,
	disableOverlayClose: true,
    spotlightPadding: 5,
	//hideCloseButton: false
};


export const FileBrowser = () => {
	const dispatch1 = useDispatch();
	const mountedRef = useMountedRef();
    const isAdmin = useAppSelector((state) => state.AccountReducer.activeUserInfo.id ? state.AccountReducer.activeUserInfo.isAdmin : true);
	const [storageConnectionData, setStorageConnectionData] = useState<
		StorageConnectionData[]
	>([]);
	const [
		showStorageConnectionModal,
		setShowStorageConnectionModal,
	] = useState(false);
	const [isNoConnActive, setIsNoConnActive] = useState(false);
	const [
		selectedDelActionItem,
		setSelectedDelActionItem,
	] = useState<StorageConnectionData | null>(null);
	const [isLoading, setLoadingState] = useState(false);
	const [showDeletePopup, toggleDeletePopup] = useState(false);
	const [
		storageConnectionDataModal,
		setStorageConnectionDataModal,
	] = useState<StorageConnectionData | null>(null);
	const [isMFRunning, setMFRunning] = useState(false);
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
	const getAllConnections = useCallback((item?: StorageConnectionData) => {
		setLoadingState(true);
		FileHandler.getConnections(`${Env?.REACT_APP_PLATFORM_URL}/filebrowser/api`, (res) => {
			dispatch1(setFileBrowserConnectionList(res));
			setStorageConnectionData(res);
			setLoadingState(false);
			
			const enabledConnection = res.filter((item) => item.isEnabled);
			setIsNoConnActive(enabledConnection.length === 0 && res.length > 0);
			if(isMFRunning && item && !item.isEnabled) {
				setTimeout(() => {
					dispatch({
						type: "OPEN",
						value: 3
					});
				}, 300);
			}
		});
	}, [isMFRunning, Env?.REACT_APP_PLATFORM_URL]);
	
	// Reducer will manage updating the local state
	const reducer = (state = INITIAL_STATE, action: any) => {
		if(!isMFRunning) return;
		switch (action.type) {
		// start the tour
		case "START":
			return { ...state, run: true };
		case "OPEN":
			return { ...state, run: true, stepIndex: action.value };
		// Reset to 0th step
		case "RESET":
			return { ...state, stepIndex: 0 };
		// Stop the tour
		case "STOP":
			return { ...state, run: false };
		// Update the steps for next / back button click
		case "NEXT_OR_PREV":
			return { ...state, ...action.payload };
		// Restart the tour - reset go to 1st step, restart create new tour
		case "RESTART":
			return {
			...state,
			stepIndex: 0,
			run: true,
			loading: false,
			key: new Date()
			};
		default:
			return state;
		}
	};
	const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
	const { 
		start_filebrowser_mf,
		cluster_status,
		file_browser_status
	 } = useSelector((store: RootState) => store.CoachMarkReducer);

	 

	useEffect(() => {
		if(Env?.REACT_APP_PLATFORM_URL){
			getAllConnections();
		}
	}, [Env?.REACT_APP_PLATFORM_URL]);

	useEffect(() => {
		if (mountedRef.current && start_filebrowser_mf !== 0) {
			setMFRunning(true);
			setTimeout(()=>{
				dispatch({ type: "RESTART" });
			}, 10);
		}
	}, [start_filebrowser_mf]);

	useEffect(() => {
		// Auto start the tour if the tour is not viewed before
		if(isAdmin && (file_browser_status !== "COMPLETE" && !isEmpty(file_browser_status))) {
			setMFRunning(true);
			setTimeout(()=>{
				dispatch({ type: "START" });
			}, 10);
		}
	}, []);

	const showFileComponent = !isNoConnActive && storageConnectionData.length !== 0;

	const closeNifiSinkModal = useCallback(() => {
		setStorageConnectionDataModal(null);
		setShowStorageConnectionModal(false);
		dispatch({type: 'OPEN', value: 2})
	}, []);

	const onDelete = () => {
		setLoadingState(true);
		toggleDeletePopup(false);
		if (selectedDelActionItem && selectedDelActionItem.id) {
			FileHandler.deleteConnection(
				`${Env.REACT_APP_PLATFORM_URL}/filebrowser/api`,
				selectedDelActionItem.id,
				(res) => {
					getAllConnections();
					toggleDeletePopup(false);
					successAlert(res.message);
				},
				(e: any) => {
					setLoadingState(false);
					errorAlert(e.data.message);
				}
			);
		}
	};

	const onEnableConnection = (item: StorageConnectionData) => {
		const obj = {
			id: item.id,
			defaultStorageAccount: !item.isEnabled,
		};
		if(isMFRunning) {
			dispatch({
				type: "STOP",
				payload: {}
			});
		}
	
		setTimeout(() => {
			setLoadingState(true);
			if (item && item.id) {
				FileHandler.enableConnection(
					`${Env.REACT_APP_PLATFORM_URL}/filebrowser/api`,
					obj,
					(res) => {
						getAllConnections(item);
						successAlert(res);
					},
					(e: any) => {
						setLoadingState(false);
						errorAlert(e.data.message);
					}
				);
			}
		}, 400);
	};

	const onHandleDeleteConnection = (data: StorageConnectionData) => {
		setSelectedDelActionItem(data);
		toggleDeletePopup(true);
	};

	const callback = (data: any) => {
		const { action, index, type, status } = data;
		if(status === STATUS.FINISHED) {
			InitHandler.SetStateForCounchMark(`${Env.REACT_APP_PLATFORM_URL}/platform/api`, {
				name: 'init_setup',
				data: "FILE_BROWSER_COMPLETE," + (cluster_status === "COMPLETE" ? "CLUSTER_COMPLETE" : "")
			}, ()=> {
				setMFRunning(false);
				dispatch1({
					type: FILE_BROWSER_COUCHMARK_STATUS,
					payload: {
						file_browser_status: "COMPLETE"
					}
				})
			})
		} else if (
			// If close button clicked then close the tour
			action === ACTIONS.CLOSE ||
			// If skipped or end tour, then close the tour
			(status === STATUS.SKIPPED && tourState.run)
		) {
			dispatch({ type: "STOP" });
			setMFRunning(false);
		// } else if (type === EVENTS.TARGET_NOT_FOUND) {
		} else if (type === EVENTS.STEP_AFTER) {
			if(index === 1) {
				dispatch({
					type: "STOP",
					payload: {}
				});
				return 
			}
			dispatch({
				type: "NEXT_OR_PREV",
				payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
			});
		}
	};
	
	return (
		<section className="fileBrowser__container">
			<ShowWhenTrue show={Env?.ENABLE_DATASTUDIO_WALKTHROUGH}>
				<JoyRide 
					{...tourState}
					callback={callback}
					styles={tourStyle}
					locale={{
						last: 'Done'
					}}
				/>
			</ShowWhenTrue>
			<div className={"left_container"}>
				<div>
					<div className="page_left_title">File Browser</div>
					<div className="subtitle">Connections</div>
				</div>
				<div className="newBtn__box">
					<button
						id="Btn_NewConnection"
						className="btn-sm btn-yellow-transparent btn__new__workflow btn__new__action"
						onClick={() => {
							setShowStorageConnectionModal(true)
							setTimeout(() => {
								dispatch({type: 'OPEN', value: 1})
							})
						}}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<ZoomInViewBlack/>
						<span>New Connection</span>
					</button>
				</div>
				<ConnectionList
					connections={storageConnectionData}
					setStorageConnectionModal={setShowStorageConnectionModal}
					setStorageConnectionDataModal={
						setStorageConnectionDataModal
					}
					handleDeleteConnection={onHandleDeleteConnection}
					handleEnableConnection={onEnableConnection}
					isLoading={isLoading}
				/>
			</div>
			<div className={"right_container"}>
				{isLoading ? (
					<div className="in_center">
						<InPageSpinner />
					</div>
				) : (
					<>
						<div className="page_right_title_header">
							<div className="page_right_title">File Browser</div>
							<UserDetails hideNameOnMinimize={false} />
						</div>

						<ShowWhenTrue show={showFileComponent}>
							<FileManagement />
						</ShowWhenTrue>
						<ShowWhenTrue show={isNoConnActive}>
							<div className="no-active-connection-text">
								No Active Connections Found
							</div>
						</ShowWhenTrue>
						<ShowWhenTrue show={storageConnectionData.length === 0}>
							<div className="create-new-connection-text">
								<button
									onClick={() =>
										setShowStorageConnectionModal(true)
									}
								>
									Create a New Connection
								</button>
							</div>
						</ShowWhenTrue>
					</>
				)}

				<StorageConnectionModal
					showModal={showStorageConnectionModal}
					toggleClose={closeNifiSinkModal}
					getStorageConnectionData={getAllConnections}
					storageConnectionData={storageConnectionDataModal}
				/>
				<DeleteStorageConnection
					onConfirm={onDelete}
					showModal={showDeletePopup}
					toggleClose={() => {
						toggleDeletePopup(false);
					}}
					selectedConnectionItem={selectedDelActionItem}
				/>
			</div>
		</section>
	);
};
