import React from 'react';
import * as yup from "yup";
import styles from './../styles.module.scss';
import { useState } from "react";
import Form, { InputField, NewSelectField, RadioField } from '@components/form';
import { ShowWhenTrue } from '@helpers/showwhentrue';
import { HttpClient } from '@api/services';
import { API } from '@constants/settings';
import { AxiosResponse } from 'axios';
import { FormikDeltaLakesBrowserComponent } from '@components/formcreators/field-creator';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { ExecutionEnvModes, noBlankSpaceInputFieldRegex } from '@constants/enums';
import { JobInfo } from '@api/job-handler';
import { useDataExplorerContext } from '../useDataExplorerContext';

const initialValues = {
    fs_type: 'dbfs',
    connection_type: 'custom',
    token_type: "access_key",
    single_file: false,
    file_location: '',
    connection_name: '',
    passwd: '',
    uname:''
}

const writeCSVFormSchema = yup.object({
    file_location: yup.string().matches(noBlankSpaceInputFieldRegex,
        'Blank spaces not allowed'
    ).required("This is a required field"),
    connection_name: yup.string().when(["fs_type", "connection_type"], {
        is: (fs_type, connection_type) => fs_type !== "dbfs" && connection_type !== "custom",
        then: yup.string().required("This is a required field"),
    }),
    passwd: yup.string().when(["fs_type", "token_type", "connection_type"], {
        is: (fs_type, token_type, connection_type) => (fs_type !== "dbfs" && connection_type === 'custom') || (fs_type === 'azure_blob' && token_type !== 'access_key'),
        then: yup.string().required("This is a required field"),
    }),
    uname: yup.string().when(["fs_type", "token_type", "connection_type"], {
        is: (fs_type, token_type, connection_type) => (fs_type !== "dbfs" && connection_type === 'custom') || (fs_type === 'azure_blob' && token_type !== 'access_key'),
        then: yup.string().required("This is a required field"),
    }),
});

const fsTypeOptions = [
    { label: 'DBFS', value: 'dbfs' },
    { label: 'AWS S3', value: 's3' },
    { label: 'Azure Blob Storage', value: 'azure_blob' },
    { label: 'Azure Data Lake Gen 2', value: 'gen2' }
];
const connectionTypeOption = [
    {
        "label": "Stored",
        "value": "stored"
    },
    {
        "label": "Custom",
        "value": "custom"
    }
];
const tokenTypeOption = [
    {
        "label": "SAS Token",
        "value": "sas"
    },
    {
        "label": "Account Key",
        "value": "access_key"
    }
];
const singleFileOption = [
    {
        "label": "True",
        "value": true
    },
    {
        "label": "False",
        "value": false
    }
];
const unameFstypeRef: any = {
    "s3": {
        label: "S3 Access Key ID",
        infoText: "Please enter S3 Access Key ID."
    },
    "azure_blob": {
        label: "Azure Storage Account",
        infoText: "Please enter Azure Storage Account."
    },
    "gen2": {
        label: "ADLS Gen 2 Storage Account",
        infoText: "Please enter ADLS Gen 2 Storage Account."
    },
};
const passwdFstypeRef: any = {
    "s3": {
        label: "S3 Secret Access Key",
        infoText: "Please enter S3 Secret Access Key."
    },
    "gen2": {
        label: "ADLS Gen2 Access Key",
        infoText: "Please enter ADLS Gen2 Access Key."
    },
}
const storageConnectionTypeMap: Record<string, string> = {
    "s3": "S3",
    "azure_blob": "AzureBlobStorage",
    "gen2": "AzureDataLake"
}

export type WorkSpaceFormProps = {
    submitButtons?: React.ReactNode;
    onSubmit: (resp:any) => any;
    setShowLoader: any
};

const WriteCsvForm: React.FC<WorkSpaceFormProps> = ({
    onSubmit,
    submitButtons,
    setShowLoader
}) => {
    const [connectionOptions, setConnectionOptions] = useState<any[]>([]);
    const connectionList = useSelector((store: RootState) => store.NewDataSourceReducer.fileBrowserConnectionList);
    const enabledConnection = connectionList.filter((item) => item.isEnabled);
    const { database, cluster, sqlQuery } = useDataExplorerContext();
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const enabledWorkspace = workSpaceData?.filter((item)=>item.isEnabled)?.[0];
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    const handleFormSubmit = async (values: any) => {
        setShowLoader(true);
        const { fs_type, connection_type, connection_name, single_file, file_location, uname, passwd, token_type } = values;
        const sql = {
            "id": "1e86eae4-fabb-492a-b6ac-0e50a5e1e9dc",
            "fn_id": "sql_query",
            "fnName": "transform.sql_query",
            "title": "SQL Query",
            "inputPorts": [
                {
                    "id": "2dc565e9-15cb-4145-a6fe-e9da3d8a5528",
                    "type": "IN-0",
                    "link": [],
                    "parentNode": "6a6d9eef-f90a-4c31-a5cc-085a13eb9155"
                }
            ],
            "outputPorts": [
                {
                    "id": "8a492449-ed47-46be-b160-c5f2ebd8233f",
                    "type": "OUT-0",
                    "link": [
                        "fdde456b-4943-4bd6-ace0-749ae676ed23"
                    ],
                    "parentNode": "1e86eae4-fabb-492a-b6ac-0e50a5e1e9dc"
                }
            ],
            "data": [
                {
                    "key": "Query",
                    "value": sqlQuery,
                    "arg_index": 2,
                    "remove_multiline": true,
                    "variable_type": "string"
                },
                {
                    "key": "sqlContext",
                    "value": "sqlContext",
                    "variable_type": "",
                    "arg_index": 1
                }
            ],
            "type": "component",
            "df_name": "sql_query_2",
            "previewAllowed": true,
            "field_workflow_config_mapping": {},
            "field_var_mapping": [],
            "extraData": {},
            "append": true
        }
        const rdbms = {
            "id": "1e86eae4-fabb-492a-b6ac-0e50a5e1e9dc",
            "fn_id": "ReadRDBMS",
            "fnName": "read.read_from_rdbms",
            "title": "Read RDBMS",
            "inputPorts": [],
            "outputPorts": [
                {
                    "id": "8a492449-ed47-46be-b160-c5f2ebd8233f",
                    "type": "OUT-0",
                    "link": [
                        "fdde456b-4943-4bd6-ace0-749ae676ed23"
                    ],
                    "parentNode": "1e86eae4-fabb-492a-b6ac-0e50a5e1e9dc"
                }
            ],
            "data": [
                {
                    "key": "connection_name",
                    "value": database.databaseName,
                    "arg_index": 2,
                    "variable_type": "string",
                    "useKey": true
                },
                {
                    "key": "p_type",
                    "value": "Query",
                    "arg_index": 3,
                    "variable_type": "string",
                    "useKey": true
                },
                {
                    "key": "p_query",
                    "value": sqlQuery,
                    "arg_index": 5,
                    "remove_multiline": true,
                    "variable_type": "string",
                    "useKey": true
                },
                {
                    "key": "p_partition",
                    "value": "",
                    "arg_index": 6,
                    "variable_type": "string",
                    "useKey": true
                },
                {
                    "key": "p_fetchsize",
                    "value": 50000,
                    "arg_index": 10,
                    "variable_type": "integer",
                    "useKey": true
                },
                {
                    "key": "sqlContext",
                    "value": "sqlContext",
                    "variable_type": "",
                    "arg_index": 1
                },
                {
                    "key": "p_table",
                    "value": "",
                    "arg_index": 4,
                    "variable_type": "string",
                    "useKey": true
                },
                {
                    "key": "p_numpartitions",
                    "value": 1,
                    "arg_index": 7,
                    "variable_type": "integer",
                    "useKey": true
                },
                {
                    "key": "p_lower",
                    "value": 1,
                    "arg_index": 8,
                    "variable_type": "string",
                    "useKey": true
                },
                {
                    "key": "p_upper",
                    "value": 1,
                    "arg_index": 9,
                    "variable_type": "string",
                    "useKey": true
                }
            ],
            "type": "component",
            "df_name": "read_rdbms_3",
            "previewAllowed": true,
            "field_workflow_config_mapping": {},
            "field_var_mapping": [],
            "extraData": {}
        };
        const payload = {
            "name": "download_csv",
            "sparkVersion": "2.x",
            "env": ExecutionEnvModes.Spark,
            "clusterId": cluster,
            "type": "run",
            "workspaceId": enabledWorkspace?.workspaceId,
            "workspaceType": "databricks",
            "payload": {
                "workflow_config": {},
                "nodes": [
                    database.dbType === "Databricks" ? sql : rdbms,
                    {
                        "id": "74f62d56-efd8-4b34-a139-2372533ae71f",
                        "fn_id": "WriteCSV",
                        "fnName": "write.write_csv",
                        "title": "Write CSV",
                        "inputPorts": [
                            {
                                "id": "e0e2191b-2024-46a2-b10b-22275f2cee83",
                                "type": "IN-0",
                                "link": [
                                    "fdde456b-4943-4bd6-ace0-749ae676ed23"
                                ],
                                "parentNode": "74f62d56-efd8-4b34-a139-2372533ae71f"
                            }
                        ],
                        "outputPorts": [],
                        "data": [
                            {
                                "key": "fs_type",
                                "value": fs_type,
                                "arg_index": 2,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "connection_type",
                                "value": connection_type,
                                "arg_index": 14,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "connection_name",
                                "value": connection_name,
                                "arg_index": 15,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "single_file",
                                "value": single_file,
                                "arg_index": 3,
                                "variable_type": "boolean",
                                "useKey": true
                            },
                            {
                                "key": "file_location",
                                "value": file_location,
                                "arg_index": 4,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "header",
                                "value": true,
                                "arg_index": 5,
                                "variable_type": "boolean",
                                "useKey": true
                            },
                            {
                                "key": "delimiter",
                                "value": ",",
                                "arg_index": 6,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "quote",
                                "value": "'",
                                "arg_index": 7,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "escape",
                                "value": "\\",
                                "arg_index": 8,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "null_value",
                                "value": "",
                                "arg_index": 9,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "date_format",
                                "value": "yyyy-MM-dd HH:mm:ss.S",
                                "arg_index": 10,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "codec",
                                "value": "None",
                                "arg_index": 11,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "quote_mode",
                                "value": "MINIMAL",
                                "arg_index": 12,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "mode",
                                "value": "append",
                                "arg_index": 13,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "sqlContext",
                                "value": "sqlContext",
                                "variable_type": "",
                                "arg_index": 1
                            },
                            {
                                "key": "uname",
                                "value": uname,
                                "arg_index": 17,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "passwd",
                                "value": passwd,
                                "arg_index": 18,
                                "variable_type": "string",
                                "useKey": true
                            },
                            {
                                "key": "token_type",
                                "value": token_type,
                                "arg_index": 16,
                                "variable_type": "string",
                                "useKey": true
                            }
                        ],
                        "type": "component",
                        "df_name": "write_csv_2",
                        "previewAllowed": true,
                        "field_workflow_config_mapping": {},
                        "field_var_mapping": [],
                        "extraData": {}
                    }
                ],
                "links": [
                    {
                        "id": "fdde456b-4943-4bd6-ace0-749ae676ed23",
                        "bridge": database.dbType === "Databricks" ? "Read SQL  <===>  Write CSV" : "Read RDBMS  <===>  Write CSV",
                        "source": {
                            "node": "1e86eae4-fabb-492a-b6ac-0e50a5e1e9dc",
                            "port": "OUT-0"
                        },
                        "target": {
                            "node": "74f62d56-efd8-4b34-a139-2372533ae71f",
                            "port": "IN-0"
                        }
                    }
                ]
            }
        }
        const resp: AxiosResponse<JobInfo> = await HttpClient.PostPromise(`${Env.REACT_APP_PLATFORM_URL}/databrowser/api/batches/job/?env=spark&version=2`, payload);
        if (resp) {
            onSubmit(resp);
        }
    };

    const handleConnections = async (fsType: string) => {
        try {
            if (fsType !== 'dbfs') {
                const resp: AxiosResponse<any[]> = await HttpClient.GetPromise(`${Env.REACT_APP_PLATFORM_URL}/databrowser/api/v1/datasource?fs_type=${fsType}`);
                const _options = resp.data.map((item) => {
                    return ({
                        label: item.name,
                        value: item.name
                    })
                })
                setConnectionOptions(_options)
            }
        } catch (err) {
              // eslint-disable-next-line no-console
            console.log(err)
        }
    }
    return (
        <Form
            initialValues={initialValues}
            validationSchema={writeCSVFormSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
        >
            {({ _formikprops: { values } }) => {
                const showFilePickerBtn = enabledConnection.length > 0 && (enabledConnection[0]?.storageConnectionType === storageConnectionTypeMap[values.fs_type]);
                return (
                    <>
                      <div className={styles["wcsvForm"]}>
                        <NewSelectField
                            name="fs_type"
                            label="Type"
                            options={fsTypeOptions}
                            onOptionClick={(item) => handleConnections(item.value)}
                            infoText="Select filesystem of the input file to be parsed."
                            className="mb-0"
                        />
                        <ShowWhenTrue show={values.fs_type !== 'dbfs'}>
                            <RadioField
                                name="connection_type"
                                options={connectionTypeOption}
                                label='Connection Type'
                                className='radio-field'
                            />
                        </ShowWhenTrue>
                        <ShowWhenTrue show={values.fs_type !== 'dbfs' && values.connection_type !== 'custom'}>
                            <NewSelectField
                                name="connection_name"
                                label="Select a Connection"
                                required={true}
                                options={connectionOptions}
                                className="mb-0"
                            />
                        </ShowWhenTrue>
                        <ShowWhenTrue show={(values.fs_type !== 'dbfs' && values.connection_type === 'custom')}>
                            <InputField
                                name="uname"
                                label={unameFstypeRef[values.fs_type]?.label}
                                autoComplete="off"
                                required={true}
                                infoText={unameFstypeRef[values.fs_type]?.infoText}
                            />
                            <ShowWhenTrue show={values.fs_type !== 'dbfs' && values.fs_type !== 'azure_blob'}>
                                <InputField
                                    name="passwd"
                                    label={passwdFstypeRef[values.fs_type]?.label}
                                    required={true}
                                    autoComplete="off"
                                    infoText={passwdFstypeRef[values.fs_type]?.infoText}
                                />
                            </ShowWhenTrue>
                        </ShowWhenTrue>
                        <ShowWhenTrue show={values.connection_type === 'custom' && values.fs_type === 'azure_blob'}>
                            <RadioField
                                name="token_type"
                                options={tokenTypeOption}
                                label='Token Type'
                                className='radio-field'
                            />
                        </ShowWhenTrue>
                        <ShowWhenTrue show={(values.fs_type === 'azure_blob' && values.token_type !== 'access_key' && values.connection_type === 'custom')}>
                            <InputField
                                name="passwd"
                                label="Azure blob storage access token"
                                autoComplete="off"
                                infoText='Azure blob storage access key. Optional if Azure credentials have been already specified in DataStudio configuration'
                            />
                        </ShowWhenTrue>
                        <ShowWhenTrue show={(values.fs_type === 'azure_blob' && values.token_type !== 'sas' && values.connection_type === 'custom')}>
                            <InputField
                                name="passwd"
                                label="Azure blob storage access key"
                                autoComplete="off"
                                infoText='Azure blob storage access key. Optional if Azure credentials have been already specified in DataStudio configuration'

                            />
                        </ShowWhenTrue>

                        <RadioField
                            name="single_file"
                            options={singleFileOption}
                            label='Single File'
                            className='radio-field'
                        />
                        <div className={styles["row-center"]}>
                            <InputField
                                name="file_location"
                                label="File Path"
                                required={true}
                                autoComplete="off"
                                infoText='Location to save delimited file/csv. Note: When file system type is DBFS, file needs to be saved in /user/root/ folder. (e.g.: /user/root/filename.csv)'
                            />
                            {showFilePickerBtn && <div className={styles["file-picker-btn"]}><FormikDeltaLakesBrowserComponent fieldName="file_location" /></div>}
                        </div>

                        {!!submitButtons && submitButtons}
                        </div>
                    </>
                )
            }}
        </Form>
    )
}

export default WriteCsvForm;