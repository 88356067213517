/* eslint-disable no-fallthrough */
import { ShowWhenTrue } from "../../../helpers";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Form, { SelectField } from "../../../components/form";
import { object, string, number, InferType, boolean } from "yup";
import { _selectoptionType } from "../../../components/form/select-field";
import { useFormikContext } from "formik";
import _, { cloneDeep, get } from "lodash";
import classNames from "classnames";
import {
	convertEnumsToOptions,
	convertToSelectFieldOptions,
} from "../../../utils";
import { getTimeStringUtcLocal } from "../../../helpers/utils";
import {
	getActualCronExpressionFromTime,
	getEstimatedDatesAndPrevExecutionDateFromCronExpression,
} from "../utils";
import moment from "moment";
import { ClockIcon } from "../../../assets/icons";
import { RadioField } from "../../../components/form/radio-field";
import CustomDateTimePicker from "../../../components/form/date-picker";
import { Checkbox } from '@components/form/elements';
import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";

// import { TimePicker } from 'antd';

// type ScheduleWorkflowModalProps = ModalProps
// const format = 'HH:mm';
const scheduleFormSchema = object().shape({
	jobName: string().required("Job name is a required field."),
	version: number().required("Version is a required field"),
	scheduleType: string(),
	weekOfTheMonth: number(),
	days: string(),
	hrs: number(),
	mins: number(),
	weekDays: string(),
	months: number(),
	cronExpression: string(),
	retries: number(),
	retryDelay: number(),
	scheduleAdvancedMode: boolean(),
	dependencies: string(),
	overrideNotificationEmail: boolean(),
	notificationEmails: string(),
	notificationType: string(),
	duration: string()
		.nullable()
		.transform((v, o) => (o === "" ? null : v)),
	workflowInfo: object()
		.shape({
			name: string(),
			id: number(),
		})
		.nullable(),
	scheduleTimingInfo: string(),
	runOnceExecutionDate: string(),
	formattedRunOnceExecutionDate: string()
});

export type ScheduleFormSchemaType = InferType<typeof scheduleFormSchema>;

export enum ScheduleTypes {
	"Minute" = "Minute",
	"Hour" = "Hour",
	"Day" = "Day",
	"Week" = "Week",
	"Month" = "Month",
}

export const configScheduleOptions = convertEnumsToOptions(ScheduleTypes);

const generateOptions = (
	upperLimit: number,
	lowerLimit = 1
): _selectoptionType[] => {
	const options: _selectoptionType[] = [];
	let i = lowerLimit;
	for (i; i <= upperLimit; i++) {
		options.push({ label: `${i}`, value: i });
	}
	return options;
};

export const weekDaysRef = {
	"0": "Sunday",
	"1": "Monday",
	"2": "Tuesday",
	"3": "Wednesday",
	"4": "Thursday",
	"5": "Friday",
	"6": "Saturday",
};

const weekDays = Object.entries(weekDaysRef).map(([dayValue, day]) => ({
	label: day,
	value: dayValue,
}));

const monthsOptions = generateOptions(12);
const hoursOptions = generateOptions(24, 0);
const minutesOptions = generateOptions(59, 0);
const minutesOptionsRef = [15, 20, 30];
const fiveStepMinutesOptions = minutesOptionsRef.map((num) => ({
	label: num.toString(),
	value: num,
}));
const datesOptions = generateOptions(31);

export const notificationTypeOptions = convertToSelectFieldOptions([
	"Failure",
	"Success",
	"Both",
]);

export const formatScheduleStartDate = (time: moment.Moment): string =>
	time.format("YYYY,M,D,H,m"); // formatted date cannot have 01, 05  - Hence M,D,H,m are used

export const getCronFieldsInitialValues = () => ({
	scheduleType: ScheduleTypes["Minute"],
	weekDays: "",
	months: 1,
	days: 1,
	hrs: 1,
	mins: 15,
	cronExpression: "*/5 * * * *",
	weekOfTheMonth: 1,
	startDate: formatScheduleStartDate(
		getEstimatedDatesAndPrevExecutionDateFromCronExpression("*/5 * * * *")
			.prevExecutionDate
	),
	scheduleString: "Runs every 15 minutes",
	scheduleTimingInfo: ScheduleTiming.Recurring,
});

export const getScheduleFormInitialValues = () => ({
	..._.cloneDeep({
		...getCronFieldsInitialValues(),
		jobName: "",
		version: 1,
		retries: 0,
		retryDelay: 0,
		scheduleAdvancedMode: false,
		dependencies: "",
		overrideNotificationEmail: false,
		notificationEmails: "",
		notificationType: notificationTypeOptions[2].value,
		workflowInfo: null,
		sparkJAR: "",
		duration: "00:00",
		runOnceExecutionDate: moment(),
		formattedRunOnceExecutionDate: formatScheduleStartDate(moment().utc())
	}),
});

interface ScheduleFieldsForm {
	setSuggestedScheduleTimes?: React.Dispatch<React.SetStateAction<string[]>>;
	prefixForFieldKeys?: string;
	showScheduleFormAtAllTimes?: boolean;
	showTimezone?: boolean;
	showAllMinutes?: boolean;
	disableMultiWeekDaySelection?: boolean;
	hideScheduleType ?: boolean;
	isHierarchy?: boolean;
}

export enum ScheduleTiming {
	None = "None",
	"Run Once" = "Run Once",
	Recurring = "Recurring"
}

const scheduleTimingOptions: _selectoptionType[] = [
	{ label: "Run Once", value: ScheduleTiming['Run Once'], infoText: "Run once option creates a DAG and by default executes it immediately. The execution time can be updated to be in the future using the options below." },
	{ label: "Recurring", value: ScheduleTiming['Recurring'], infoText: "Recurring option creates a DAG and executes it on the user defined schedule." },
]

export const ScheduleFields: React.FC<ScheduleFieldsForm> = ({
	setSuggestedScheduleTimes,
	prefixForFieldKeys = "",
	showScheduleFormAtAllTimes = false,
	showTimezone = false,
	showAllMinutes = false,
	disableMultiWeekDaySelection = false,
	hideScheduleType = false,
	isHierarchy = false
}) => {
	const [firstTimeRun, setFirstTimeRun] = useState(false);
	// these fields are separated to access the values of formik context
	const { values, setFieldValue, initialValues } = useFormikContext<
		ScheduleFormSchemaType
	>();

	const [showScheduleForm, toggleScheduleForm] = useState(
		showScheduleFormAtAllTimes
	);

	// prefixForFieldKeys = 'startTime.' => startTime.day
	const getFieldKey = (key: string) => prefixForFieldKeys + key;

	const getFieldValue = (key: string) => get(values, getFieldKey(key));

	const getAllScheduleFieldValues = () => ({
		mins: getFieldValue("mins"),
		weekDays: getFieldValue("weekDays"),
		days: getFieldValue("days"),
		hrs: getFieldValue("hrs"),
		months: getFieldValue("months"),
		scheduleType: getFieldValue("scheduleType"),
	});

	const scheduleValuesRef = useRef<any>(getAllScheduleFieldValues());

	useEffect(() => {
		const {
			cronExpression,
			scheduleString,
		} = getActualCronExpressionFromTime(getAllScheduleFieldValues());
		setFieldValue(getFieldKey("cronExpression"), cronExpression);
		setFieldValue(getFieldKey("scheduleString"), scheduleString);
		const {
			datesList: suggestedScheduleTimes,
			prevExecutionDate,
		} = getEstimatedDatesAndPrevExecutionDateFromCronExpression(
			cronExpression
		);
		setFieldValue(
			getFieldKey("startDate"),
			formatScheduleStartDate(prevExecutionDate)
		);
		setSuggestedScheduleTimes &&
			setSuggestedScheduleTimes(suggestedScheduleTimes);
	}, [
		getFieldValue("scheduleType"),
		getFieldValue("days"),
		getFieldValue("weekDays"),
		getFieldValue("hrs"),
		getFieldValue("mins"),
		getFieldValue("months"),
	]);

	const scheduleType = getFieldValue("scheduleType");
	const showMonthly = scheduleType === ScheduleTypes["Month"];
	const showHours = scheduleType !== ScheduleTypes["Minute"];
	const showWeekDays = scheduleType === ScheduleTypes["Week"];
	const showDates = scheduleType === ScheduleTypes["Day"];

	const hoursOptionsToShow = useMemo(() => {
		if (scheduleType === ScheduleTypes["Hour"])
			return hoursOptions.slice(1);
		return hoursOptions.slice(0, hoursOptions.length - 1);
	}, [hoursOptions, scheduleType]);

	const minuteOptionsToShow = useMemo(() => {
		if (scheduleType === ScheduleTypes["Minute"] && !showAllMinutes)
			return fiveStepMinutesOptions;
		return minutesOptions;
	}, [minutesOptions, scheduleType, showAllMinutes]);

	const handleShowScheduleFields = () => {
		if (!showScheduleForm && !showScheduleFormAtAllTimes) {
			scheduleValuesRef.current = cloneDeep(getAllScheduleFieldValues());
			toggleScheduleForm(true);
		}
	};

	const closeScheduleForm = () => {
		toggleScheduleForm(false);
	};

	const handleDiscardClick = () => {
		Object.entries(scheduleValuesRef.current).forEach(
			([fieldKey, value]) => {
				setFieldValue(getFieldKey(fieldKey), value);
			}
		);
		closeScheduleForm();
	};

	const handleScheduleTypeChange = (option: _selectoptionType) => {
		const selectedMins = getFieldValue("mins");
		switch (option.value) {
			case ScheduleTypes["Minute"]:
				if (!minutesOptionsRef.find((min) => min === selectedMins)) {
					// RESET THE FIELD VALUE TO 15 ON SELECTING MINUTE
					setFieldValue(getFieldKey("mins"), 15);
				}
				break;
			case ScheduleTypes["Day"]:
				setFieldValue(getFieldKey("days"), datesOptions[0].value);
				setFieldValue(getFieldKey("hrs"), hoursOptionsToShow[0].value);
				setFieldValue(
					getFieldKey("mins"),
					minuteOptionsToShow[0].value
				);
				break;
			case ScheduleTypes["Month"]:
				setFieldValue(getFieldKey("months"), monthsOptions[0].value);
				setFieldValue(getFieldKey("days"), datesOptions[0].value);
				setFieldValue(getFieldKey("hrs"), hoursOptionsToShow[0].value);
				setFieldValue(
					getFieldKey("mins"),
					minuteOptionsToShow[0].value
				);
		}
	};

	const isRecurringSchedule =
		getFieldValue("scheduleTimingInfo") === ScheduleTiming.Recurring;

	const isNoneSchedule = getFieldValue("scheduleTimingInfo") === ScheduleTiming.None;
	const {
		value: dtValue,
		setValue: setDtValue,
	} = CustomDateTimePicker.useCustomBasicController({ 
		initialValue: moment.utc(initialValues.runOnceExecutionDate, "YYYY,M,D,H,m")
	});

	const {
		value: dtValueFirstTimeRun,
		setValue: setDtValueFirstTimeRun,
	} = CustomDateTimePicker.useCustomBasicController({ 
		initialValue: moment.utc(initialValues.runOnceExecutionDate, "YYYY,M,D,H,m")
	});

	const handleSetDtValue = (newDate: moment.Moment | null) => {
		setDtValue(newDate);
		const runOnceExecutionDate = newDate ? newDate: moment()
		setFieldValue(getFieldKey("runOnceExecutionDate"), formatScheduleStartDate(runOnceExecutionDate));
		setFieldValue(
			getFieldKey("formattedRunOnceExecutionDate"),
			formatScheduleStartDate(runOnceExecutionDate)
		);
	};

	const handleSetDtValueFirstTimeRun = (newDate: moment.Moment | null) => {
		setDtValueFirstTimeRun(newDate);
		const runOnceExecutionDate = newDate ? newDate: moment()
		setFieldValue(getFieldKey("runOnceExecutionDate"), formatScheduleStartDate(runOnceExecutionDate));
		setFieldValue(
			getFieldKey("formattedRunOnceExecutionDate"),
			formatScheduleStartDate(runOnceExecutionDate)
		);
	};

	return (
		<div className="configurationSchedule__box">
			{!hideScheduleType &&
				<RadioField
					label="Schedule Type"
					name={getFieldKey("scheduleTimingInfo")}
					options={scheduleTimingOptions}
				/>
			}
			{!isNoneSchedule && <>
			<ShowWhenTrue show={isRecurringSchedule && isHierarchy}>
				<Flex alignItems={"center"} gap='20px' mb={1}>
					<Flex alignItems={"center"} gap='10px' mb={1}>
						<Checkbox
							checked={firstTimeRun}
							onClick={() =>{
								setFirstTimeRun(!firstTimeRun)
							}}
							color="gold"							
						/>	
						<Text color={"white"}>First Time Run</Text>
					</Flex>
					<ShowWhenTrue show={firstTimeRun}>
						<CustomDateTimePicker
							label=""
							value={dtValueFirstTimeRun}
							type="basic"
							setValue={handleSetDtValueFirstTimeRun}
							disablePastDates
							showTime
						/>
					</ShowWhenTrue>
				</Flex>
			</ShowWhenTrue>
			<p className="">Configuration Schedule</p>
			<div
				className={classNames("configurationSchedule__fields", {
					showForm: showScheduleForm,
				})}
			>
				{showScheduleForm && !showScheduleFormAtAllTimes && (
					<p>Preview</p>
				)}
				<div
					className={classNames("scheduleString__previewBox", {
						disableClick: showScheduleForm,
					})}
					onClick={handleShowScheduleFields}
				>
					<div className="scheduleString__previewInnerBox">
						<ClockIcon />
						<div>
							<p className="scheduleString__title">
								{isRecurringSchedule
									?getFieldValue("scheduleString")
									: dtValue?.format("LLLL")}
							</p>
							<p className="scheduleString__subtitle">
								Server time (UTC)
							</p>
						</div>
					</div>
					{!showScheduleForm && (
						<img
							src="/icons/profile/edit-profile.svg"
							alt="edit-profile"
						/>
					)}
				</div>
				{showScheduleForm && (
					<div
						className={classNames("scheduleFields", {
							isRecurringSchedule,
						})}
					>
						{isRecurringSchedule ? (
							<>
								<SelectField
									name={getFieldKey("scheduleType")}
									label="Frequency"
									options={configScheduleOptions}
									onOptionClick={handleScheduleTypeChange}
									className="frequency__dropdown"
								/>
								<div className="timeDurationFields__form">
									<div>
										<ShowWhenTrue show={showWeekDays}>
											<SelectField
												name={getFieldKey("weekDays")}
												label="Week Day"
												options={weekDays}
												className="weekDay__dropdown"
												multiple_select={
													!disableMultiWeekDaySelection
												}
											/>
										</ShowWhenTrue>
									</div>

									<div className="dayHrSecFields">
										<ShowWhenTrue show={showMonthly}>
											<SelectField
												name={getFieldKey("months")}
												label="Months"
												options={monthsOptions}
											/>
										</ShowWhenTrue>
										<ShowWhenTrue
											show={showDates || showMonthly}
										>
											<SelectField
												name={getFieldKey("days")}
												label={
													showMonthly
														? "Date"
														: "Days"
												}
												options={datesOptions}
											/>
										</ShowWhenTrue>
										<ShowWhenTrue show={showHours}>
											<SelectField
												name={getFieldKey("hrs")}
												label="Hour"
												options={hoursOptionsToShow}
											/>
										</ShowWhenTrue>
										<SelectField
											name={getFieldKey("mins")}
											label="Minute"
											options={minuteOptionsToShow}
											className="minute__dropdown"
										/>
									</div>
								</div>
							</>
						) : (
							<CustomDateTimePicker
								label="Execution Date"
								value={dtValue}
								type="basic"
								setValue={handleSetDtValue}
								disablePastDates
								showTime
							/>
						)}
						<ShowWhenTrue show={!showScheduleFormAtAllTimes}>
							<div className="scheduleFields__formBtns">
								<button
									className="btn-sm btn-grey"
									type="button"
									onClick={closeScheduleForm}
								>
									Save
								</button>
								<button
									className="btn-sm btn-transparent"
									type="button"
									onClick={handleDiscardClick}
								>
									Discard
								</button>
							</div>
						</ShowWhenTrue>
					</div>
				)}
			</div>
			</>}
		</div>
	);
};

enum Timezone {
	local = "local",
	utc = "utc",
}

function getTimeZone() {
	const offset = new Date().getTimezoneOffset(),
		o = Math.abs(offset);
	return (
		(offset < 0 ? "+" : "-") +
		("00" + Math.floor(o / 60)).slice(-2) +
		":" +
		("00" + (o % 60)).slice(-2)
	);
}

const timezoneOptions = [
	{ label: "Server (UTC)", value: Timezone.utc },
	{ label: `Local (UTC${getTimeZone()})`, value: Timezone.local },
];

export const RenderSuggestedScheduleTimes: React.FC<{
	suggestedScheduleTimes: string[];
	title?: string;
}> = ({ suggestedScheduleTimes, title = "Upcoming Schedules Preview" }) => {
	const [activeTimezone, setActiveTimezone] = useState(Timezone.utc);

	const suggestedScheduleTimesToShow = useMemo(
		() =>
			suggestedScheduleTimes.map((timeString) =>
				getTimeStringUtcLocal(
					timeString,
					activeTimezone === Timezone.utc
				)
			),
		[suggestedScheduleTimes, activeTimezone]
	);

	// const formatTime = (time: string): string => {
	//     // return moment(time).format('LLL');
	//     return getLocalTimeStringWithUtc(time);
	//     // return getCSTTimeString(time);
	// };

	const handleOptionClick = (option: _selectoptionType) => {
		setActiveTimezone(option.value);
	};

	return (
		<div className="scheduledTimes__container">
			<div>
				<span className="_title">{title}</span>
				<Form
					initialValues={{ activeTimezone }}
					onSubmit={() => {
						return;
					}}
				>
					<SelectField
						options={timezoneOptions}
						name="activeTimezone"
						onOptionClick={handleOptionClick}
						className="activeTime__field"
					/>
				</Form>
			</div>
			<ul className="scheduledTimes__list">
				<div className="scheduledTimes__LHS">
					{suggestedScheduleTimesToShow.slice(0, 5).map((time) => (
						<li key={time}>{time}</li>
					))}
				</div>
				<div className="scheduledTimes__RHS">
					{suggestedScheduleTimesToShow.slice(5, 11).map((time) => (
						<li key={time}>{time}</li>
					))}
				</div>
			</ul>
		</div>
	);
};
