import React from 'react';
import './styles.scss';
import { Modal } from '../modals';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/types';
import { toggleLandingPage } from '../../store/common';
import { UserDetails } from '../header-sidebar/user-details';
import { getAllCards } from './enums';
import { retrieveWorkflows, setActiveTab } from '../../store/workflow';
import { useDidUpdate } from 'rooks';
import { WorkflowHandler, DetailedWorkflowInfo } from '../../api/workflow-handler';
import { openANewWorkflowEditorTabUsingWorkflowInfo } from '../../store/canvas';
import { CanvasTabs } from '../../pages/workflow-page/enums';
import { WorkflowIcon } from './icons';

export const LandingPage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const showLandingPage = useSelector((store: RootState) => store.CommonReducer.showLandingPage);
    const userInfo = useSelector((store: RootState) => store.AccountReducer.activeUserInfo);
    const workflows = useSelector((store: RootState) => store.WorkflowReducer.workflows);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    useDidUpdate(() => {
        showLandingPage && userInfo.id && dispatch(retrieveWorkflows({ size: 10 }));
    }, [userInfo, showLandingPage]);

    const toggleClose = () => {
        dispatch(toggleLandingPage(false));
    };

    const handleLogout = () => {
        localStorage.clear();
        history.push('/');
    };

    const handleGetWorkflowSuccess = (index: number, workflow: DetailedWorkflowInfo) => {
        toggleClose();
        dispatch(setActiveTab(CanvasTabs.WORKFLOWS));
        // let pageNo = 1;
        // // resolution with less than 700 are only shown 8 workflows per page
        // if(window.innerHeight < 700 && index >= 7) pageNo = 2;
        // // since page1's workflows are retrieved
        dispatch(openANewWorkflowEditorTabUsingWorkflowInfo(workflow.data));
    };

    function handleOpenWorkflow(this: any, id: number, index: number) {
        WorkflowHandler.GetWorkflowInfo(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, id, handleGetWorkflowSuccess.bind(this, index));
    }


    return(
        <Modal
            isOpen={showLandingPage}
            toggleClose={toggleClose}   
            className="landingPage__container"
            title=''
        >
            <div className="landingPage__LHS">
                <div className="logo__box">
                    <img src="/images/logo-ds.png" alt="" width="167" height="41"  />
                </div>
                <div className="recents__box">
                    <span className="__title">Recent Workflows</span>
                    <div className="recentWorkflows__list">
                        {(workflows?.results || []).map((_workflow, index) => (
                            <button 
                                key={_workflow.id+'unique'}
                                onClick={() => handleOpenWorkflow(_workflow.id, index)}
                            >
                                <WorkflowIcon />
                                {_workflow.projectName}
                            </button>
                        ))}
                        
                    </div>
                </div>
                {/* <div className="adminSettings__box">
                    {userInfo.isAdmin &&
                        <Link
                            to="/admin/"
                            onClick={toggleClose}
                        >
                            Administrator Settings
                        </Link>
                    }
                    
                    <a href="mailto:support@deepiq.com">Contact Support</a>
                </div> */}
                <div className="userInfo__box">
                    <UserDetails disableDropdown />
                    <button onClick={handleLogout}>Logout</button>
                </div>

            </div>
            <div className="landingPage__RHS">
                <div className="landingPage__RHS__title">
                    Hi, <span className="userName">{userInfo.name.split(' ')[0]}</span> ! Welcome Back.&nbsp;
                    {/* <span className="lastLoginMsg">Your last login was 23 hours ago</span> */}
                    <img src="/icons/close_tab.svg" id="landing_close_button" alt="close-modal" role="close-modal" onClick={toggleClose} />
                </div>
                <ul className="landingPage__cardsBox">
                    {getAllCards(Env?.ENABLE_DATA_EXPLORER).map((card: any) => {
                        const Icon = card.icon; 
                        return(
                       
                            <li 
                                key={card.name}
                                className="pageCard"
                            >

                                <Icon active={false} />
                                <span className="pageCard__title">{card.name}</span>
                                <div className="pageCard__buttons">
                                    {card.type === 'button' ?
                                        (card.tabs as {title: string; action: ({ dispatch, history }: any) => any}[]).map(tab => (
                                            <button
                                                key={tab.title}
                                                onClick={() => tab.action({ dispatch, history })}
                                                className="btn-md btn-yellow-transparent"
                                            >
                                                {tab.title}
                                            </button>
                                        ))
                                        :
                                        (card.tabs as {title: string; url: string}[]).map(tab => (
                                            <Link
                                                key={tab.title}
                                                to={tab.url}
                                                onClick={() => toggleClose()}
                                                className="btn-md btn-yellow-transparent"
                                            >
                                                {tab.title}
                                            </Link>
                                        ))
                                    }
                                </div>

                            </li>
                        );
                    })}
                </ul>
            </div>
               

        </Modal>
    );
};