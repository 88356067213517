import { useField, useFormikContext } from 'formik';
import { get, isEmpty, isEqual, omit, set } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce, useDidMount } from 'rooks';
import { MlflowHandler, ModelInfo, ModelVersionInfo } from '../../../api/mlflow-handler';
import { ShowWhenTrue } from '../../../helpers';
import { InputField, SelectField } from '../../form';
import { _selectoptionType } from '../../form/select-field';
import { modeOptionsForDropdown, ModeOptions, PREPROCESSING, outputFormatOptionsForDropdown, OutputFormatOptions, inputTypeFieldName, InputTypeOptions, SparkModelLibraryOptions, getInputTypeOptionsForDropdown , DataSet, dataSetOptions, autoResizeOptions, labelModeOptionsForDropdown, filterFormData } from './enums';
import { SchemaCaptureProps } from '../field-creator';
import { BuildPipelineModal } from './build-pipeline-modal';
import { WorkflowCanvasTabInfo } from '../../../store/canvas';
import { useGetActiveTabInfo } from '../../../utils';
import { WorkflowCanvas } from '../../workflow-canvas';
import { updatePortCountOfComponent } from '../../../pages/workflow-page/utils';
import { BaseFieldType } from '../types';
import { InputField as FilePathField } from '../field-creator';
import { RepairIcon } from '../../../assets/icons';
import { toggleModal } from '../../../store/workflow';
import { useAppSelector } from '../../../store/hooks';
import { RadioField } from '@components/form/radio-field';
import Table from '@components/formcreators/fieldComponents/table';
import { MlPipelineContext } from './context';
import StoredPipelineFields from './StoredPipelineFields';
import UploadModelFields from './UploadModel';
import { RootState } from '@store/types';
    
type Props = Pick<SchemaCaptureProps, 'captureSchemaOptions'>
const CATEGORY_TYPE_FOR_TRANSFORM = 'categoryTypeForTransform';

const filePathFieldData: BaseFieldType = {
    'key': 'file_path',
    'type': 'input',
    'defaultValue': '',
    'useFileBrowser': true,
    'templateOptions': {
        'variable_type': 'string',
        'label': 'File Path',
        'options': [],
        required: true
    }
};
// INITIAL VALUES ARE SET IN the fn initialValuesCreator (\src\components\formcreators\schema-creator.ts)
export const MlPipeline: React.FC<any> = ({ captureSchemaOptions, ...props }) => {
    const { initialValues, values, setFieldValue, submitForm } = useFormikContext<any>();
    const [,{  value: fieldValue }, { setValue }] = useField('ml-pipeline');
    const [modelsList, setModelsList] = useState<_selectoptionType[]>([]);
    const [versionsList, setVersionsList] = useState<_selectoptionType[]>([]);
    const activeTabInfo = useGetActiveTabInfo('workflowEditor') as WorkflowCanvasTabInfo;
    const activeComponentId = useAppSelector((state) => state.WorkflowReducer.activeComponentInfo?.id);
    const userInfo = useAppSelector((state) => state.AccountReducer.activeUserInfo);
    const showBuildPipelineModal = useAppSelector((store)=> store.WorkflowReducer.showModal.mlBuildPipelineModal);
    const [formData, setFormData] = useState<any>(null);
    const activeExecutionEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    const handleModelResponse = (response: { registered_models: ModelInfo[] }) => {
        const modelsList = response.registered_models.map(mod => ({ label: mod.name, value: mod.name }));
        setModelsList(modelsList);
    };

    const getModels = () => {
        MlflowHandler.GetModels(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, handleModelResponse);
    };

    const getCategoryInfo = (pipeline_name: string, pipeline_version: string) => {
        // console.log(pipeline_name, pipeline_version);
        if(pipeline_name && pipeline_version) {
            MlflowHandler.GetModelCategoryInfo(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, pipeline_name, pipeline_version, (categoryType) => {
                if(categoryType.toLocaleLowerCase() !== 'not found') {
                    setFieldValue(CATEGORY_TYPE_FOR_TRANSFORM, categoryType);
                } else {
                    setFieldValue(CATEGORY_TYPE_FOR_TRANSFORM, null);
                }
            });
        } 
    };

    const getVersionsOfModel = useCallback((modelName: string, pipelineVersionFieldRef: React.RefObject<SelectField | null> | null = null, retrieveCategoryInfo: boolean) => {
        MlflowHandler.GetVersionsOfModel(`${Env?.REACT_APP_PLATFORM_URL}/databricks/api`, modelName, (response: { model_versions: ModelVersionInfo[]}) => {
            const versionsList = response.model_versions.map(ver => ({ 
                label: ver.version + (ver.current_stage !== 'None' ? ' ('+ ver.current_stage + ')': ''), 
                value: ver.version 
            }));
            setVersionsList(versionsList);
    
            let selectedVersion = values.pipeline_version;
            
            if(!selectedVersion) {
                const lastOption = versionsList[versionsList.length - 1]; 
                pipelineVersionFieldRef?.current?.setOption(lastOption);
                selectedVersion = lastOption.value;
            }
            if(retrieveCategoryInfo) {
                getCategoryInfo(response.model_versions[0].name, selectedVersion.value);
            }
           
            // if(versionsList.length === 1) {
            //     setFieldValue('pipeline_version', '1');
            //     getCategoryInfo(values.pipeline_name, '1');
            // } else {
            //     // RESET VERSION INFO ON MODEL CHANGE
            //     pipelineVersionRef.current?.resetSelectedOption();
            //     setFieldValue(CATEGORY_TYPE_FOR_TRANSFORM, null);
            // }
        });

    }, [values]);

    const modelInitialValue = get(initialValues, 'pipeline_name');

    useEffect(() => {
        if(values.mode === ModeOptions['Transform Data'] && modelInitialValue) {
            getVersionsOfModel(modelInitialValue, null, true);
        }
    }, [modelInitialValue, values.mode]);

    const setInitialValues = (initialVal: Record<string, any>) => {
        const extraData: Record<string, string> = {};
        if(get(values, 'extraData.username') !== userInfo.username) {
            setFieldValue('extraData.username', userInfo.username);
            set(extraData, 'username',  userInfo.username);
        } 
        if(get(values, 'extraData.workflowName') !== activeTabInfo.name){
            setFieldValue('extraData.workflowName', activeTabInfo.name);
            set(extraData, 'workflowName',  activeTabInfo.name);
        }
        if(!isEmpty(extraData) || !isEmpty(initialVal)) {
            if(!isEmpty(extraData)) {
                setFieldValue('ml-pipeline.extraData', { ...(get(values, 'ml-pipeline.extraData') || {}), ...extraData });
            }
            if(!isEmpty(initialVal)) {
                Object.entries(initialVal).forEach(([key, val]) => {
                    setFieldValue(key, val);
                    setFieldValue('ml-pipeline.'+key, val);
                });
            }
            submitForm();
        }
    };

    useDidMount(() => {
        getModels();
    });

    const handleSetValue = useDebounce((values: any) => {
        const __value = omit(values, 'ml-pipeline', 'toTriggerError');
        if(!isEqual(values['ml-pipeline'] as any, __value)) {
            setValue(__value);
        }
    }, 300);

    const activeComponentInfo = useMemo(() => {
        if(activeComponentId) {
            return WorkflowCanvas.getNode(activeComponentId);
        } return null;
    }, [activeComponentId]);

    const validatePortCount = useDebounce((values: Record<string, any>, activeComponent: typeof activeComponentInfo) => {
        if(activeComponent) {
            // const actualInPortCount = activeComponent.getInPorts().length;
            // const actualOutPortCount = activeComponent.getOutPorts().length;
            const portsCount = {
                in: activeComponent.getInPorts().length,
                out: activeComponent.getOutPorts().length
            };
            if(values.mode === ModeOptions['Fit Data'] && portsCount.out === 1) {
                portsCount.out = 0;
            } else if(values.mode !== ModeOptions['Fit Data'] && portsCount.out === 0) {
                portsCount.out = 1;
            }
            if(values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)'] && portsCount.in === 1) {
                portsCount.in = 0;
            } else if(values[inputTypeFieldName] === InputTypeOptions['Default (dataframes)'] && portsCount.in === 0) {
                portsCount.in = 1;
            }

            if(values.mode === ModeOptions['Upload Model']) {
                portsCount.in = 0;
                portsCount.out = 0;
            }

            if(portsCount.out !== activeComponent.getOutPorts().length || portsCount.in !== activeComponent.getInPorts().length) {
                updatePortCountOfComponent(
                    activeComponent.getID(), 
                    { 
                        inputPorts: portsCount.in,
                        outputPorts: portsCount.out,
                        addVariablePort: false,
                        removeVariablePort: false
                    }
                );
                setFieldValue('num_op_ports', portsCount.out);
            } 
        }
    }, 100);

    useEffect(() => {
        if(values !== undefined && activeComponentInfo !== undefined) {
            validatePortCount(values, activeComponentInfo);
        }
    }, [values.mode, values[inputTypeFieldName]]);

    useEffect(() => {
        if(values !== undefined && fieldValue !== undefined && activeComponentInfo !== undefined) {
            handleSetValue(values);
            
        }
    }, [values, activeComponentInfo]);

    useEffect(() => {
        setFormData(props.formData);
    }, []);

    const dispatch= useDispatch();

    const toggleBuildPipelineModal = (action: boolean) => {
        dispatch(toggleModal('mlBuildPipelineModal', action));
    };

    const handleCloseBuildPipelineModal = () => {
        toggleBuildPipelineModal(false);
    };

    const handleShowBuildPipelineModal = () => {
        toggleBuildPipelineModal(true);
    };

    const onModelListSelection = useCallback((retrieveCategoryInfo: boolean, pipelineVersionFieldRef: React.RefObject<SelectField | null>, option: _selectoptionType) => {
        getVersionsOfModel(option.value, pipelineVersionFieldRef, retrieveCategoryInfo)
    }, [getVersionsOfModel])

    const renderTransformFields = () => (
        <div
            className="transform__fields"
        > 
            <StoredPipelineFields 
                onPipelineVersionClick={(option) => getCategoryInfo(values.pipeline_name, option.value)}  
            />
            {/* <SelectField 
                name="pipeline_name"
                options={modelsList}
                label="Pipeline Name"
                onOptionClick={onModelListSelection.bind(null, true)}
            />
            <SelectField 
                name="pipeline_version"
                options={versionsList}
                label="Pipeline Version"
                ref={pipelineVersionRef}
                onOptionClick={(option) => getCategoryInfo(values.pipeline_name, option.value)}  
            /> */}
            {values.model_library !== SparkModelLibraryOptions['Spark'] ?
                <>
                    <SelectField 
                        name={inputTypeFieldName}
                        options={getInputTypeOptionsForDropdown (Env?.DISABLE_PETASTORM)}
                        label="Input Type"
                    />
                    <ShowWhenTrue show={values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)']}>
                        <FilePathField 
                            captureSchemaOptions={captureSchemaOptions}
                            fieldData={filePathFieldData}
                        />
                        <ShowWhenTrue show={values[inputTypeFieldName] !== InputTypeOptions['Parquet folder']}>
                            <InputField 
                                name="train_files_pattern"
                                label="File Pattern"
                                placeholder="regex pattern"
                            />
                        </ShowWhenTrue>
                    </ShowWhenTrue>
                    <ShowWhenTrue show={values[inputTypeFieldName] === InputTypeOptions['Parquet folder']}>
                        <RadioField 
                            name="dataset_type"
                            options={dataSetOptions}
                            label='Dataset type'
                            className='no-padding-left no-padding-bottom'
                        />
                        <ShowWhenTrue show={values['dataset_type'] === DataSet['Images']}>
                            <InputField 
                                name="image_column"
                                label="Image Column"
                            />
                            <InputField 
                                name="image_size"
                                label="Image Size"
                            />
                            <RadioField 
                                name="auto_resize"
                                options={autoResizeOptions}
                                label="Auto Resize"
                                className='no-padding-left no-padding-bottom'
                            />
                        </ShowWhenTrue>
                        
                        <ShowWhenTrue show={values['dataset_type'] === DataSet['Table']}>
                            <InputField 
                                name="features"
                                label="Features"
                            />
                        </ShowWhenTrue>
                        <div className='useCaptureSchema property_window'>
                            <Table fieldData={{
                                "key" : "train_data_filters",
                                "type": "table",
                                "defaultValue": "",
                                "templateOptions": {
                                    "hideCaptureSchema":  true,
                                    "variable_type": "string",
                                    "label": "Data Filters",
                                    "options": filterFormData,
                                    "qtip": ""
                                }
                            }} />
                        </div>
                        <InputField 
                            name="label_column"
                            label="Label column"
                        />
                        <SelectField 
                            name="label_mode"
                            options={labelModeOptionsForDropdown}
                            label="Label Mode"
                            infoText="Specifies the mode in which labels will be interpreted by model. Labels will be transformed into one hot encoded vectors In case of categorical labels."
                        />
                        <InputField 
                            name="prediction_col"
                            label="Prediction Column"
                        />
                        <InputField 
                            name="required_columns"
                            label="Required Columns"
                        />
                        
                    </ShowWhenTrue>
                </>
                :
                <ShowWhenTrue show={!!get(values, CATEGORY_TYPE_FOR_TRANSFORM)}>
                    {get(values, CATEGORY_TYPE_FOR_TRANSFORM) === PREPROCESSING ?
                        <>
                            <SelectField 
                                name="output_format"
                                options={outputFormatOptionsForDropdown}
                                className="highlightLabel"
                                label="Output Format"
                            />
                            <ShowWhenTrue show={values.output_format === OutputFormatOptions['Vectorized']}>
                                <InputField 
                                    name="transform_output_col"
                                    label="Output Column"
                                />
                            </ShowWhenTrue>
                        </>
                        :
                        <InputField 
                            name="transform_prediction_col"
                            label="Prediction Column"
                        />
                    }
                </ShowWhenTrue>
            }
        </div>
    );

    return(
        <MlPipelineContext.Provider value={{ modelsList, versionsList, onModelListSelection, captureSchemaOptions }}>
            <div className="mlpipeline__box">
                <div className="mode__field">
                    <SelectField 
                        name="mode"
                        options={activeExecutionEnv === "streaming" ? [modeOptionsForDropdown[2]]:modeOptionsForDropdown}
                        placeholder="<Select One>"
                        // onOptionClick={(val) => validateOutportPortCount({mode: val.value}, activeComponentInfo)}
                        label="Mode"
                    >
                        <ShowWhenTrue show={!values.mode}>
                            <div className="selectToConfigure__msg">
                                <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                                <span>Select to configure</span>
                            </div>
                        </ShowWhenTrue>
                        
                    </SelectField>
                    {/* <NewExperimentAddIcon /> */}
                </div>
                
                <ShowWhenTrue show={!!(values.mode)}>
                    {(values.mode === ModeOptions['Transform Data'] )? 
                        renderTransformFields() 
                        : values.mode === ModeOptions['Upload Model'] ?
                            <UploadModelFields formData={formData} /> :
                                <button
                                    className="btn btn-sm btn-yellow"
                                    id="btn_configure_pipeline"
                                    onClick={handleShowBuildPipelineModal}
                                    type="button"
                                >
                                    <RepairIcon /> Configure Pipeline
                                </button>
                    }
                </ShowWhenTrue>
                <BuildPipelineModal 
                    showModal={showBuildPipelineModal}
                    toggleClose={handleCloseBuildPipelineModal}
                    captureSchemaOptions={captureSchemaOptions}
                    handleSetInitialValues={setInitialValues}
                />
            </div>
        </MlPipelineContext.Provider>
    );
};