// import { API } from '../../constants/settings';
import { HttpClient } from '@api/services';

class UserService {
    GetAdminInitStatus(link: string, key: any, callback: (arg0: any) => void) {
        HttpClient.Get('Get InitState', `${link}/v1/user-data/${key}`, callback);
    }
    SetStateForCounchMark(link: string, data: any, callback: (arg0: any) => void) {
        HttpClient.Post('Get InitState', `${link}/v1/user-data`, data, callback);
    }
    GetWorkflowForDataExplorer(link: string, key: string) {
        return HttpClient.GetPromise( `${link}/v1/user-data/${key}`)
    }
}


const InitHandler = new UserService(); 

export { InitHandler };