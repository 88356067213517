import { InputField } from "@components/form";
import {
	SelectField,
	SelectFieldProps,
	_selectoptionType,
} from "@components/form/select-field";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { PlushIcon } from "@pages/cluster_redisign/icons";
import classNames from "classnames";
import { useField, useFormikContext } from "formik";
import { omit } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { useDidUpdate } from "rooks";
import { StringSchema } from "yup";
import {
	FieldCreatorProps,
	strictFunc,
	useGetClassNameIfHidden,
} from "../field-creator";
import { FieldSchemaCreator, FieldSchemaValidator } from "../schema-creator";
import { useDatabricksGetRunPreviewClusters, useGetRunPreviewClusters } from "@utils/useGetRunPreviewClusters";

interface BaseSelectField extends FieldCreatorProps {
	autoSelectFirstOption?: boolean;
	options: _selectoptionType[];
	reset_options?: boolean;
	onOptionClick?: SelectFieldProps["onOptionClick"];
	onFilterTextChange?: (arg0: string) => any;
	handleFilterInParent?: boolean;
	className?: string;
	handleOptions?:()=> void;
	isFetchingAllClusters?: boolean;
}

const validateMultipleSelect = (
	values: any,
	multipleOptionsSelection?: string | boolean
) => {
	if (multipleOptionsSelection) {
		if (typeof multipleOptionsSelection === "string") {
			return strictFunc(values, multipleOptionsSelection);
		}
		return multipleOptionsSelection;
	}
	return false;
};

const BaseSelectField: React.FC<BaseSelectField> = ({
	fieldData,
	handleOptions,
	isFetchingAllClusters,
	...props
}) => {
	const _className = useGetClassNameIfHidden(fieldData);
	const clusters = useDatabricksGetRunPreviewClusters();
	const { values, setFieldValue } = useFormikContext<any>();
	const selectFieldRef = useRef<SelectField>(null);
	const _props = omit(props, "captureSchemaOptions", "connectedNodes");

	const validateFn = useCallback(() => {
		const schema = FieldSchemaCreator(fieldData.templateOptions);
		return FieldSchemaValidator(schema as StringSchema)
	}, [fieldData.templateOptions]);

	const isSelectedInVariableMappingOrWorkflowConfigMapping = useMemo(() => {
		return (
			values.extraData.workflow_var_mapping?.all_fields_list.indexOf(
				fieldData.key
			) > -1 ||
			values.extraData.workflow_config_mapping?.selectedFieldKeys.indexOf(
				fieldData.key
			) > -1
		);
	}, [
		values.extraData.workflow_var_mapping?.all_fields_list,
		values.extraData.workflow_config_mapping?.selectedFieldKeys,
		fieldData.key,
	]);

	useDidUpdate(() => {
		if (!isSelectedInVariableMappingOrWorkflowConfigMapping) {
			// on removing the variable placeholder,
			// 1. if the select field has a value, its shown in the UI as select field is only hidden
			// when field is selected in variable mapping; Update select field's value in formik context
			const selectFieldValue = selectFieldRef.current?.getSelectedOptionValue();
			if (selectFieldValue) {
				setFieldValue(fieldData.key, selectFieldValue);
			} else if (
				fieldData.defaultValue !== undefined &&
				fieldData.defaultValue !== null
			) {
				// 2. If select field doesn't have a value, use default value
				const defaultValueOption = fieldData.templateOptions.options.find(
					(option) => option.value === fieldData.defaultValue
				);
				if (defaultValueOption) {
					setFieldValue(fieldData.key, defaultValueOption.value);
					selectFieldRef.current?.setOption(defaultValueOption);
				}
			}
		}
	}, [isSelectedInVariableMappingOrWorkflowConfigMapping]);

	const commonProps = {
		label: fieldData.templateOptions.label,
		name: fieldData.key,
		required: !!fieldData.templateOptions.required,
		disabled: fieldData.disabled || fieldData.readOnly,
		infoText: fieldData.templateOptions.qtip,
		validate: _className !== "hide__formfield" ? validateFn(): undefined,
	};
	const [{value:isExistingWorkflowSelected}] = useField("existing_workflow");

	return (
		<>
			{isSelectedInVariableMappingOrWorkflowConfigMapping && (
				<InputField
					className={classNames(_className, props.className)}
					{...commonProps}
				/>
			)}
			<ShowWhenTrue show={fieldData.key !== "existing_cluster_id"}>
				<SelectField
					no_wrap_multiple_select={fieldData.templateOptions.nowrap}
					multiple_select={validateMultipleSelect(
						values,
						fieldData.templateOptions.multipleOptionsSelection
					)}
					max_items={fieldData.templateOptions.max_items}
					{...commonProps}
					{..._props}
					data-fieldlabelforvariable={fieldData.labelForVariable || fieldData.templateOptions.label}
					data-fieldkey={fieldData.key}
					data-fieldtype="select"
					// field is hidden when it is selected in variable mapping
					className={classNames(_className, props.className, {
						hide: isSelectedInVariableMappingOrWorkflowConfigMapping,
					})}
					ref={selectFieldRef}
				/>
			</ShowWhenTrue>
			<ShowWhenTrue show={fieldData.key === "existing_cluster_id"}>
				<SelectField
					no_wrap_multiple_select={fieldData.templateOptions.nowrap}
					multiple_select={validateMultipleSelect(
						values,
						fieldData.templateOptions.multipleOptionsSelection
					)}
					max_items={fieldData.templateOptions.max_items}
					{...commonProps}
					{..._props}
					options={clusters}
					data-fieldlabelforvariable={fieldData.labelForVariable || fieldData.templateOptions.label}
					data-fieldkey={fieldData.key}
					data-fieldtype="select"
					// field is hidden when it is selected in variable mapping
					className={classNames(_className, props.className, {
						hide: isSelectedInVariableMappingOrWorkflowConfigMapping,
					})}
					ref={selectFieldRef}
					
					renderItemAtOptionsBottom={() => (<button id="fetch_all_clusters" disabled={isFetchingAllClusters || (isExistingWorkflowSelected === "True")} onClick={handleOptions} className='btn-yellow btn-sm'  type="button">
						<PlushIcon /> Fetch all clusters
					</button>)}
				/>
			</ShowWhenTrue>
		</>
	);
};

export { BaseSelectField };
