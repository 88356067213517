import {useCallback, useMemo, useState } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import styles from './../styles.module.scss';
import { NifiEdgeData } from './types';
import { DataSourceHandler } from '@api/data-source-handler';
import FileSaver from 'file-saver';
import { AxiosResponse } from 'axios';
import { errorAlert, successAlert } from '@components/toastify/notify-toast';
import { TooltipTop } from '@components/tooltips';
import {
	GoToFullScreenIcon
} from "@pages/workflow-analytics-page/assets/icons";
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { Modal } from 'antd';
import Spinner from '@components/spinners/spinner';

type NifiTilesProps={
    nifiData: NifiEdgeData[],
    setNifiEdgeModal: (value:boolean)=>void,
    setNifiEdgeDataModal: (data:NifiEdgeData)=>void
    onEdgeNodeClick: (clickedNodeData: NifiEdgeData, time: string) => void;
    getNifiEdgeData: () => void;
    selectedNodeType: string;
    showPatches: (data: NifiEdgeData, type: string) => void;
    poolData: NifiEdgeData[];
}

const NifiTiles: React.FC<NifiTilesProps> = ({
    nifiData = [],
    setNifiEdgeModal,
    setNifiEdgeDataModal,
    onEdgeNodeClick,
    getNifiEdgeData,
    selectedNodeType,
    showPatches,
    poolData
}) => {
    const [scaledNodesData, setScaledNodesData] = useState<any[]>([]);
    const history = useHistory();
    const [parentDataView, setParentDataView] = useState<boolean>(true); 
    const [isLoading, setIsLoading] = useState<boolean>(false); 
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    const onDownloadCertificate = useCallback((id: number, _data: any) => {
        DataSourceHandler.DownloadEdgeCertificate(`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`, _data)
            .then((response: AxiosResponse<any>) => {
                // Extract filename from url
                const link = response.config.url;
                if (link) {
                    const extractedWord = _data.nodeName ? _data.nodeName : _data.nodeScaleName
                    let filename = `${extractedWord ? extractedWord : 'config'}.tar.gz`; 
                    if (extractedWord) {
                        const filenameMatch = extractedWord.match(/filename="(.+)"/);
                        if (filenameMatch && filenameMatch.length === 2) {
                            filename = filenameMatch[1];
                        }
                    }
                    FileSaver.saveAs(new Blob([response.data]), filename);
                } else {
                    throw new Error("Failed to extract filename from the download link. Please try again later.");
                }
            })
            .catch(() => {
                errorAlert("Something went wrong, please try again after sometime");
            });
    }, []);

      
      

      const deleteScaleNode=useCallback((parentNode: string, nodeScaleName: string) => {
        Modal.confirm({
          title: 'Are you sure you want to delete this scaled node?',
          content: 'This action cannot be undone.',
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            DataSourceHandler.DeleteScaleNodes(
              `${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`,
              parentNode,
              nodeScaleName,
              (response) => {
                if (response) {
                  successAlert(response);
                  childNode(parentNode); 
                  getNifiEdgeData(); 
                }
              },
              (error) => {
                errorAlert('Failed to delete the node. Please try again.');
              }
            );
          },
          onCancel: () => {
            // console.log('Deletion canceled');
          },
        });
      },[nifiData])

      const childNode=(nodeName: string)=>{
        DataSourceHandler.scaledNode(
            `${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`,
            nodeName,
            (response: any) => {
              setIsLoading(false);
              if (response) {
                 setScaledNodesData(response);
              } else {
                console.error("Unexpected response format or error", response);
                errorAlert("Failed to fetch patch data. Please try again.");
              }
            }
          );
      }

      const scaledNodes=(nodeName: string)=>{
                  setIsLoading(true);
                  setParentDataView(false);
                  childNode(nodeName);
                };
    

    const onEditNode = useCallback((data: any) => {
        setNifiEdgeDataModal(data)
        setNifiEdgeModal(true);
    }, []);

    const filteredData = useMemo(() => {
      if (selectedNodeType === "edgeNodes") {
          return nifiData.filter(node => node.scaleNodes === null);
      } else if (selectedNodeType === "poolNodes") {
          return nifiData.filter(node => typeof node.scaleNodes === "number" && node.scaleNodes >= 0);
      }
      return nifiData;
  }, [nifiData, selectedNodeType]);

  const goToHierarchyPage = (data: NifiEdgeData) => {
    history.push(`/admin/asset-hierarchy/edge-server?nodeName=${data.nodeName}`);
  }

    return (
        <>
      <ul className={classnames(styles['nifi_titles'])}>
      {isLoading && (
    <div className={styles['spinner-container']}>
      <Spinner />
    </div>
  )}
    {(parentDataView ? filteredData : scaledNodesData).map((_data) => {
      const relatedNode = _data.scaledNodes?.[0]?.parentNode 
      ? poolData.find(node => node.nodeName === _data.scaledNodes?.[0]?.parentNode) 
      : null;    
      const totalScaledNodes = relatedNode?.scaleNodes || 0;
      const onlineScaledNodes = relatedNode?.scaledNodes
    ? relatedNode.scaledNodes.filter((node: any) => node.online).length
    : 0;
    const displayFraction = totalScaledNodes > 0 ? `${onlineScaledNodes}/${totalScaledNodes}` : null;

          return (<li key={_data.nodeName || _data.nodeScaleName}>
            <div className={styles['mt-0']}>
              <TooltipTop title={_data.nodeName || _data.nodeScaleName}>
                <h2 className={styles['title'] + ' ' + styles['truncate']}>
                  {_data.nodeName || _data.nodeScaleName}
                </h2>
              </TooltipTop>
              <div className={styles['badge-section']}>
                {parentDataView && <TooltipTop title="Create Hierarchy">
                  <img src='/icons/asset-hierarchy.svg' alt="Asset" style={{cursor: 'pointer'}} onClick={() => goToHierarchyPage(_data)} />
                </TooltipTop>}
                <div
                  className={classnames(styles['status-badge'], {
                    [styles['running']]: _data.online,
                    [styles['terminated']]: !_data.online,
                  })}
                >
                  <div className={styles['circle']}></div>
                  <span className={styles['label']}>
                    {_data.online ? 'Online' : 'Offline'}
                    {displayFraction && (
                      <span className={styles['fraction']}> ({displayFraction})</span>
                      )}
                  </span>
                </div>
                <div className={styles['go-to-full-screen-icon']} style={{cursor: 'pointer'}} onClick={() => onEdgeNodeClick(_data, '48days')}>
                  <GoToFullScreenIcon />
                </div>
              </div>
            </div>
            <div className={styles['divider']}></div>
            <div>
              <div className={styles['key']}>Node Kind</div>
              <div>{_data.nodeKind}</div>
            </div>
            {!parentDataView &&  <><div className={styles['divider']}></div>
            <div>
              <div className={styles['key']}>Current Version</div>
              <div>{_data.currentPatchVersion}</div>
            </div></>}
            <div className={styles['divider']}></div>

                {parentDataView && <button
                  className={styles['editButton']}
                  onClick={() => onEditNode(_data)}
                >
                  <div className={styles['center']}>
                    <img src='/icons/pencil.svg' alt="Edit" />
                    Edit Edge Node
                  </div>
                </button>}
                {(parentDataView && !_data.scaleNodes || _data && !parentDataView )&& (
                <button
                  className={styles['editButton']}
                  onClick={() => onDownloadCertificate(_data.id, _data)}
                >
                  <div className={styles['center']}>
                    <img src='/icons/certificate.svg' alt="Download" />
                    Download Configuration
                  </div>
                </button>
            ) }
            
              {parentDataView && _data.scaleNodes>0 &&<button
                className={styles['editButton']}
                onClick={() => {
                    setIsLoading(true);
                    scaledNodes(_data.nodeName)
                }}
              >
                <div className={styles['center']}>
                  <img src='/icons/scaled-nodes.svg' alt="" />
                  Scaled Nodes
                </div>
              </button>}
            {parentDataView && ( _data.nodeKind=="OSIPI" || _data.nodeKind=="OPCHDA") && (
              <>
                <button
                  className={styles['editButton']}
                  onClick={() => showPatches(_data, 'update')}
                >
                  <div className={styles['center']}>
                    <img src='/icons/patch.svg' alt="" />
                    Update Patches ({_data?.currentPatchVersion})
                  </div>
                </button>

                <button
                  className={styles['editButton']}
                  onClick={() => showPatches(_data, 'revert')}
                >
                  <div className={styles['center']}>
                    <img src='/icons/patch.svg' alt="" />
                    Revert Patches 
                  </div>
                </button>
              </>
            )}

           {!parentDataView && (
              <button
                className={styles['editButton']}
                onClick={() => deleteScaleNode(_data.parentNode, _data.nodeScaleName)}
              >
                <div className={styles['center']}>
                  <img src='/icons/patch.svg' alt="" />
                  Delete Node
                </div>
              </button>
            )}
          </li> );
    })}
  
      </ul>

      {!parentDataView && (
        <button
          className={styles['backButton']}
          onClick={() => {
            setParentDataView(true);
        }}
        >
          <div className={styles['center']}>
            <img src='/icons/back.svg' alt="" />
            Back to Nodes
          </div>
        </button>
      )}
    </>
    );
};

export default NifiTiles;
