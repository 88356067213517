import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorContent } from './error-page-content';

const ErrorFallback = () => (
    <ErrorContent 
        title={
            <p className="title">
                Whoops. it&apos;s not you, it&apos;s us.
            </p>
        }
        subtitle={
            <p 
                className="subtitle text-underline  c-pointer"
                onClick={() => {
                    if(!(process.env.NODE_ENV === 'development')) {
                        localStorage.removeItem('state');
                    }
                    window.location.reload();}
                }    
            >
                Click here to refresh.
            </p>
        }
    />
);


export const ErrorBoundaryComponent: React.FC = ({ children }) => {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            // onReset={() => setExplode(false)}
        >
            {children}
        </ErrorBoundary>
    );
};
