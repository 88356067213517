import {
    CreateWorkflow, CreateWorkflowResponse, WorkflowSearchQuery, WorkflowList, DetailedWorkflowInfo,
    UpdateWorkflow,  CronSearchQueryResponse, WorkflowHistory,
    DownloadWorkflowResponse, ScheduleWorkflowData, 
    CreateProfile, GetProfilesResponse, GetComponentOutputResponse, UpdateWorkflowResponse, RunWorkflowData, CaptureSchemaResponse, PreviewStatusResponse, CloneWorkflowData, SelectedProfileResponse, 
    ProfileSearchQuery, GetActiveProfile, SyncJobResponse, DirectoriesInfoResponse, CreateDirectoryResponse,  WorkflowDirectoriesQueryParams, CaptureSchemaPayload, ShareWorkflowPostData, DagPostWorkflowInfo, OpenDatabricksNotebookPayload, DownloadDirectoryData, DownloadDagWorkflowResponse,
    RepositoriesList,
    MoveDirectory,
    MoveGitDirectory,
    RenameGitDirectory
} from './types';
import { HttpClient } from '../services';
import { makeSearchParams } from '../../helpers/makeSearchParams';
import queryString from 'query-string';
// import { API, Env, zepplinUrl } from '../../constants/settings';
import { JobInfo } from '../job-handler';
import HttpInvoker from '../services/httpinvoker';
import { AxiosResponse } from 'axios';
import { ApiPromise, ApiResponse } from '../data-source-handler';
import { stringifyQueryParams } from '../../helpers/utils';
import { ExecutionEnvModes } from '../../constants/enums';
import { errorHandlerFor400 } from '../services/errorhandler';

class WorkflowHandlerClass {
    // getUrl() {
    //     return Env.databricks ? API.databricksUrl: API.url;
    // }

    CreateWorkflow(link: string, data: CreateWorkflow, callback: (arg0: CreateWorkflowResponse) => void, errorCallBack: ApiResponse<AxiosResponse> = errorHandlerFor400) {
        HttpClient.Post('Create workflow', `${link}/v1/workflow/`, data, callback, errorCallBack);
    }

    CloneWorkflowUsingName(link: string, data: CreateWorkflow, userId ?: string, repoId ?: number) {
        const queryParams = stringifyQueryParams({
            env: data.env,
            userId,
            repoId: repoId
        })
        return HttpClient.PostPromise(`${link}/v2/workflow/export/clone${queryParams}`, data);
    }

    RetrieveWorkflows(link: string, searchQueryObj: WorkflowSearchQuery): Promise<AxiosResponse<WorkflowList>> {
        if(!searchQueryObj.page) searchQueryObj.page = 1; // Make sure page number is included
        const searchQuery = stringifyQueryParams(searchQueryObj);
        // HttpClient.Get('Get workflows', `${API.url}/v2/workflow/pagination${searchQuery}`, callback);
        return HttpClient.GetPromise(`${link}/v2/workflow/pagination${searchQuery}`);
    }

    RetrieveSparkPythonWorkflows(link: string, callback: ApiResponse<WorkflowList>) {
        return HttpClient.Get('Retrieve spark and python workflows' ,`${link}/v1/airflow/workflow`, callback);
    }

    GetWorkflowInfo(link: string, workflowId: number, callback: (arg0: DetailedWorkflowInfo) => void) {
        HttpClient.Get('Get workflows', `${link}/v1/workflow/${workflowId}`, callback);
    }

    GetWorkflowInfoPromise(link: string, workflowId: number) {
        return HttpClient.GetPromise(`${link}/v1/workflow/${workflowId}`);
    }

    // GetWorkflowHistory(workflowId: number, callback: (arg0: WorkflowHistory[]) => void) {
    //     HttpClient.Get('Get workflows', `${API.url}/v1/workflow/${workflowId}/history`, callback);
    // }

    GetWorkflowInfoUsingVersion(link: string, workflowId: number | string, version: number, callback: (arg0: DetailedWorkflowInfo['data']) => void) {
        HttpClient.Get('Get workflows', `${link}/v1/workflow/${workflowId}/history/${version}`, callback);
    }

    UpdateWorkflow(link: string, workflowId: number, data: UpdateWorkflow, callback: (arg0: UpdateWorkflowResponse) => void) {
        HttpClient.Put('Update workflow', `${link}/v1/workflow/${workflowId}`, data, callback);
    }

    DownloadWorkflow(link: string, env: ExecutionEnvModes, data: any, callback: (arg0: DownloadWorkflowResponse) => void) {
        const queryParams = stringifyQueryParams({ env, spark: '2.x' });
        HttpClient.Post('DownloadWorkflow', link + '/v2/public/generate_code' + queryParams, data, callback);
    }

    // DownloadWorkflowInArray(env: ExecutionEnvModes, data: any, callback: (arg0: DownloadWorkflowResponse) => void) {
    //     const queryParams = stringifyQueryParams({ env, spark: '2.x', type: 'array' });
    //     HttpClient.Post('DownloadWorkflowInArray', API.url + '/v2/public/generate_code' + queryParams, data, callback);
    // }

    RunWorkflow(link: string, env: ExecutionEnvModes, data: RunWorkflowData, callback: (arg0: JobInfo) => void, errorHandlerCallback: any) {
        HttpClient.Post('Run Workflow', `${link}/batches/job/${stringifyQueryParams({env, version: '2' })}`, data, callback, errorHandlerCallback);
    }
    
    GetPreviewStatus(link: string, sessionId: string, callback: (arg0: PreviewStatusResponse) => void, errorCallback? : any) {
        HttpClient.Get('Get current job preview status', ` ${link}/batches/job/preview_result/${sessionId}`, callback, errorCallback);
    }

    GetPreviewStatusPromise(link: string, sessionId: string): Promise<AxiosResponse<PreviewStatusResponse>> {
        return HttpClient.GetPromise(` ${link}/batches/job/preview_result/${sessionId}`);
    }

    // ScheduleWorkflow(env: ExecutionEnvModes, data: ScheduleWorkflowData, callback: any, errorHandlerCallback?: (arg0: any) => void) {
    //     HttpClient.Post('DownloadWorkflow', API.url + '/v2/schedule_workflow' + stringifyQueryParams({env}), data, callback, errorHandlerCallback);
    // }

    // UpdateTransformationJob(id: number, env: ExecutionEnvModes, data: ScheduleWorkflowData, callback: any) {
    //     HttpClient.Put('Update Workflow', API.url + '/v2/analytic_flow/' + id + stringifyQueryParams({env}), data, callback);
    // }


    DeleteWorkflow(link: string, ids: number[], callback: any) {
        HttpClient.Post('Delete workflow', link + '/v2/workflow/multiple_delete', { ids }, callback);
    }

    CloneWorkflow(link: string, data: CloneWorkflowData, callback: (arg0: CreateWorkflowResponse) => any, errorHandlerCallBack?: any) {
        HttpClient.Post('Clone workflow', `${link}/v2/workflow/clone`, data, callback, errorHandlerCallBack);
    }

    ListJarFiles(link: string, callback: (arg0: string[]) => void) {
        HttpClient.Get('List all jar files', link + '/v2/list_jar_files', callback);
    }

    ListDatabricksJarFiles(link: string, callback: (arg0: string[]) => void) {
        HttpClient.Get('List all jar files', link + '/v2/getjarslist', callback);
    }

    // GetCronJobsEstimatedTime(stringifiedSearchQuery: string, timeZone: string, callback: (arg0: CronSearchQueryResponse) => void) {
    //     // let cronExpression = `0 ${mins} ${hrs} ? * ${day} *`;
    //     // if (weeknumber !== '*') cronExpression = `0 ${mins} ${hrs} ? * ${day}#${weeknumber} *`;
    //     const stringifiedCronExpression = queryString.stringify({ cronExpression: stringifiedSearchQuery, timeZone });
    //     HttpClient.Get('Get Cron jobs estimated time', `${API.url}/v1/cron?${stringifiedCronExpression}`, callback);
    // }

    // GetSyncJobList(callback: ApiResponse<SyncJobResponse>) {
    //     HttpClient.Get('Get Sync job list', `${API.url}/v2/sync_job_list`, callback);
    // }

    GetComponentOutput(link: string, sessionId: string, componentId: string, callback: (arg0: GetComponentOutputResponse) => any, errorCallback?: any) {
        HttpClient.Get('Get component\'s output using session and component ID', `${link}/batches/job/preview_result/${sessionId}/${componentId}`, callback, errorCallback);
    }

    // GetComponentOutputPromise(sessionId: string, componentId: string): ApiPromise<GetComponentOutputResponse> {
    //     return HttpInvoker._get(`${this.getUrl()}/batches/job/preview_result/${sessionId}/${componentId}`);
    // }

    CreateProfile(link: string, data: CreateProfile, callback: any) {
        HttpClient.Post('Create profile', link + '/v1/builds/', data, callback);
    }

    GetProfiles(link: string, searchQueryObj: ProfileSearchQuery, callback: (arg0: GetProfilesResponse) => any) {
        const searchQuery = makeSearchParams(searchQueryObj);
        HttpClient.Get('List user profiles', `${link}/v1/builds/pagination${searchQuery}`, callback);
    }

    UpdateProfile(link: string, profile_id: number, data: CreateProfile, callback: any) {
        HttpClient.Put('Update profile', `${link}/v1/builds/${profile_id}/`, data, callback);
    }

    CloneProfiles(link: string, ids: number[], callback: any) {
        HttpClient.Post('Duplicate profiles', `${link}/v1/builds/multiple_clone`, { ids }, callback);
    }

    DeleteProfiles(link: string, ids: number[], callback: any) {
        HttpClient.Post('Update profile', `${link}/v1/builds/multiple_delete/`, { ids }, callback);
    }

    SetUserProfile(link: string, profile_id: number, callback: (arg0: SelectedProfileResponse) => any) {
        HttpClient.Post('Set default profile', `${link}/v1/default-build/`, { 'id': profile_id }, callback );
    }

    GetSelectedProfile(link: string, callback: (arg0: GetActiveProfile) => any, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Get('Set default profile', `${link}/v1/default-build/`, callback,errorHandlerCallback);
    }

    CaptureSchema(link: string, payload: CaptureSchemaPayload, callback: (arg0: CaptureSchemaResponse[]) => any) {
        HttpClient.Post('Capture schema of a id', `${link}/batches/schema_capture?version=2`, { 'sparkVersion': '2.x', ...payload }, callback);
    }

    GetCaptureSchemaData(link: string, sessionId: string, callback: (arg0: CaptureSchemaResponse[]) => any) {
        HttpClient.Get('Get capture schema data', `${link}/batches/schema_capture/${sessionId}/dataframe`, callback);
    }

    GetCaptureSchemaLogs(link: string, sessionId: string, callback: (arg0: string[]) => any) {
        HttpClient.Get('Get capture schema data', `${link}/batches/schema_capture/${sessionId}/log`, callback);
    }

    CancelCaptureSchema(link: string, sessionId: string, callback: any) {
        HttpClient.Delete('Capture schema of a id', `${link}/batches/schema_capture/${sessionId}/terminate?version=2`, callback);
    }

    StarWorkflows(link: string, ids: number[]): Promise<AxiosResponse<any>> {
        return HttpClient.PostPromise(`${link}/v2/workflow/starred`, {ids});
    }

    SageMaker(link: string, workflow_name: string,data: any, callback: (arg0: DownloadWorkflowResponse) => void) {
        HttpClient.Post('SageMaker', `${link}/v2/public/generate_aws_sagemaker_notebook?spark=2.x&generate_type=py_spark&workflow_name=${workflow_name}`, data, callback);
    }

    OpenDatabricksNotebook(link: string, data: OpenDatabricksNotebookPayload, callback: (arg0: DownloadWorkflowResponse) => void) {
        HttpClient.Post('DataBricks', `${link}/notebook/open`, data, callback);
    }

    CreateZeppelinCode(link: string, name: string, callback: (arg0: any) => void) {
        HttpClient.Post('CreateZeppelin', link + '/api/notebook', {
            name,
            'paragraphs': []
        }, callback, undefined, true );
    }

    UpdateNotebookForWorkflow(link: string, workflowid: string, notebookId: string, callback: (arg0: any) => void) {
        HttpClient.Put('UpdateWorkflowZeppelin', `${link}/zeppelin/notebook/${notebookId}/${workflowid}`, {}, callback);
    }

    GetWorkflowDirectories(link: string, userId: string, queryParams: WorkflowDirectoriesQueryParams, callback: ApiResponse<DirectoriesInfoResponse>) {
        HttpClient.Get('Get Workflow Projects', `${link}/v2/project_directory_by_user/${userId}${stringifyQueryParams(queryParams)}` , callback);
    }

    CreateDirectory(link: string, workflowIds: number[], env: ExecutionEnvModes, callback: ApiResponse<CreateDirectoryResponse>){
        HttpClient.Post('Create Project with workflow', `${link}/v2/project_directory`, { projectIds: workflowIds, env }, callback);
    }

    RenameProject(link: string, directoryId: number, name: string, callback: ApiResponse<any>) {
        HttpClient.Put('Rename Directory', `${link}/v2/rename_directory/${directoryId}`, { name }, callback);
    }

    MoveWorkflowsToDirectory(link: string, directoryId: number, projectIds: number[], callback: ApiResponse<any>) {
        HttpClient.Post('Move Workflows To Directory', `${link}/v2/move_projects`, { directoryId, projectIds }, callback);
    }

    DeleteDirectory(link: string, directoryId: number, moveProjectsToDefaultDirectory: boolean, callback: ApiResponse<any>) {
        const queryParams = queryString.stringify({ moveProjectsToDefaultDirectory });
        HttpClient.Delete('Delete Project', `${link}/v2/project_directory/${directoryId}?${queryParams}`, callback);
    }

    ShareWorkflow(link: string, data: ShareWorkflowPostData, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Share workflow', `${link}/v2/workflow/share`, data, callback, errorCallback);
    }

    DownloadDagWorkflow(link: string, data: DagPostWorkflowInfo, callback: ApiResponse<DownloadDagWorkflowResponse>) {
        HttpClient.Post('Download Dag Workflow', `${link}/dags/download`, data, callback);
    }

    ScheduleDagWorkflow(link: string, data: DagPostWorkflowInfo, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Download Dag Workflow', `${link}/dags/create`, data, callback,errorCallback);
    }

    DownloadDirectory(link: string, data: DownloadDirectoryData) {
        return HttpClient.GetBlob(`${link}/directory/download?directoryId=${data.directoryId}&env=${data.env}`);
    }

    UploadDirectory(link: string, directoryName: string, env: ExecutionEnvModes,form: FormData, repoId?: any) {
        const baseUrl = `${link}/directory/upload?directoryName=${directoryName}&env=${env}`;
        const url = repoId ? `${baseUrl}&repoId=${repoId}` : baseUrl;
        return HttpClient.PostFile(url, form);
    }

    GetRepositories(link: string, callback: ApiResponse<RepositoriesList[]>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Get('Get Git Repos', `${link}/api/repo/`, callback,errorCallback);
    };

    GetListDirectories(link: string, userId: string,queryParams: any, callback: ApiResponse<DirectoriesInfoResponse>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Get('Get List Directories', `${link}/v2/project_directory_by_user/${userId}${stringifyQueryParams(queryParams)}` , callback, errorCallback);
    }

    CreateGitDirectory(link: string, data: any, callback: (arg0: any) => void, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Create directory', `${link}/v2/project_directory`, data, callback, errorCallback);
    }

    PushDirectory(link: string, data: any, callback: (arg0: any) => void, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Push directory', `${link}/api/repo/sync`, data, callback, errorCallback);
    }

    HandleConflict(link: string, data: any, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Put('Handle Git Conflict', `${link}/api/repo/resolve-conflict`, data, callback, errorCallback);
    }

    MoveGitDirectory(link: string, repoId: number|null, directoryId: number, callback: ApiResponse<any>, errorCallback: ApiResponse<any>) {
        if(repoId){
            HttpClient.Post('Move Git directory', `${link}/v2/directory/clone?repoId=${repoId}&directoryId=${directoryId}`, callback, errorCallback);
        }else{
            HttpClient.Post('Move Git directory', `${link}/v2/directory/clone?directoryId=${directoryId}`, callback, errorCallback);
        }
    }

    MoveGitWorkflows(link: string, repoId: number|null, data: MoveGitDirectory, callback: (arg0: any) => void, errorCallback: ApiResponse<AxiosResponse>) {
        if(repoId){
            HttpClient.Post('Move Git workflows', `${link}/v2/workflow/clone?repoId=${repoId}`, data, callback, errorCallback);
        }else{
            HttpClient.Post('Move Git workflows', `${link}/v2/workflow/clone`, data, callback, errorCallback);
        }
    }

    // GitSync(env: string, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
    //     HttpClient.Post('Git Sync', `${API.gitUrl}/api/git/projects/sync`, {
    //         env
    //     }, callback, errorCallback);
    // }

    RenameGitDirectory(link: string, data: RenameGitDirectory, callback: ApiResponse<any>) {
        HttpClient.Put('Rename Directory', `${link}/api/repo/rename`, data, callback);
    }
}

const WorkflowHandler = new WorkflowHandlerClass();

export { WorkflowHandler };
