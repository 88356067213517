import React, { useState, useEffect } from "react"
// import { Input, Select, Button, Table, Space, Typography, ConfigProvider } from "antd-latest"
import { PlushIcon } from "@pages/cluster_redisign/icons"
import { BaseFieldType } from "./formcreators/types"
import { Flex } from "./ui/Flex"
import { FormikDeltaLakesBrowserComponent } from "./formcreators/field-creator"
import Form, { InputField, NewSelectField, SelectField } from "@components/form";
import { styles } from "styled-system"
import { Button, Space, Table } from "antd-latest"
import { Input } from "antd"
import { CaptureSchemaComponent } from "./formcreators/fieldComponents/CaptureSchemaComponent"
import { CaptureSchemaButton } from "./formcreators/fieldComponents/CaptureSchemaButton"
import { CaptureSchemaIcon } from "./formcreators/icons"
import { Dropdown, DropdownOptions } from "./form/dropdown"



interface Props {
  formData: BaseFieldType[];
  handleRouteFormSubmit: (values: any) => void;
  getComponentDescription: any;
  handleInstanceModal?: () => void
}


const staticPumpAttributes = [
  { name: "Temperature", key: "temperature", value: "Temp001" },
  { name: "Pressure", key: "pressure", value: "Pres0001" },
  { name: "Current", key: "current", value: "Cur1" },
  { name: "Voltage", key: "voltage", value: "V1" },
  { name: "Vibration", key: "vibration", value: "Vib01" },
]

const dynamicPumpAttributes = [
  { name: "Temperature", key: "temperature", value: "Temp00{id}" },
  { name: "Pressure", key: "pressure", value: "Pres000{id}" },
  { name: "Current", key: "current", value: "Cur{id}" },
  { name: "Voltage", key: "voltage", value: "V{id}" },
  { name: "Vibration", key: "vibration", value: "Vib0{id}" },
]

const importPumpAttributes = [
  { name: "Temperature", key: "temperature", value: "%Temp%", importColumn: "Column3" },
  { name: "Pressure", key: "pressure", value: "%Pres%", importColumn: "Column3" },
  { name: "Current", key: "current", value: "%Cur%", importColumn: "Column3" },
  { name: "Voltage", key: "voltage", value: "%V%", importColumn: "Column3" },
  { name: "Vibration", key: "vibration", value: "%Vib%", importColumn: "Column3" },
]
const Instance: React.FC<Props> = ({ formData, handleRouteFormSubmit, getComponentDescription, handleInstanceModal }) => {
  const [parameters, setParameters] = useState([{ key: "", value: "" }])
  const [mode, setMode] = useState("Static")
  const [model, setModel] = useState("Pump")
  const isStaticPump = model === "Pump" && mode === "Static";

  const [attributes, setAttributes] = useState( staticPumpAttributes)
  const handleParameterChange = (index: number, field: string, value: string) => {
    const newParameters: any = [...parameters]
    newParameters[index][field] = value
    setParameters(newParameters)
  }

  const handleInstanceAttributeChange = (index: number, field: string, value: string) => {
    const newParameters: any = [...attributes]
    newParameters[index][field] = value
    setAttributes(newParameters)
  }

  const parameterColumns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      render: (text: string, record: any, index: number) => (
        <Input
          value={text}
          onChange={(e) => handleParameterChange(index, "key", e.target.value)}
          placeholder="Key"
        />
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text: string, record: any, index: number) => (
        <Input
          value={text}
          onChange={(e) => handleParameterChange(index, "value", e.target.value)}
          placeholder="Value"
        />
      ),
    },
  ]



  const attributeColumns = [
    {
      title: "Attribute Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Attribute Path/Pattern",
      dataIndex: "value",
      key: "value",
      render: (text: string, record: any, index: number) => (
        <Input
          value={text}
          onChange={(e) => handleInstanceAttributeChange(index, "value", e.target.value)}
          placeholder="Value"
        />
      ),
    },
    ...(mode === "Import"
      ? [{
        title: "Import",
        dataIndex: "importColumn",
        key: "importColumn",
        render: (text: string, record: any, index: number) => (
          <Flex flexDirection={"row"}>
            <Input
              value={text}
              onChange={(e) => handleInstanceAttributeChange(index, "importColumn", e.target.value)}
              placeholder="Import"
            />
            <Flex display={"flex"} alignItems={"center"}>
              <Button
                className="btn-yellow btn-capture"
                size="small"
                loading={false}
              >
                <CaptureSchemaIcon />
              </Button>
              <Dropdown
                title={
                  <span className="btn-yellow btn__dropdown__triangle">
                    &#x25BE;
                  </span>
                }
                position="right"
                dropdownOptions={options}
                className="runWorkflow__options"
              />
            </Flex>
          </Flex>
        ),
      }] : []),
    // ...(mode === "Import"
    //     ? [
    //         {
    //             title: "Import Column",
    //             dataIndex: "key",
    //             key: "importColumn",
    //             render: (text: string) => (
    //               render: (text: string, record: any, index: number) => (
    //                 <Input
    //                   value={text}
    //                   onChange={(e) => handleParameterChange(index, "importColumn", e.target.value)}
    //                   placeholder="Import"
    //                 />
    //               ),
    //             ),
    //         },
    //     ]
    //     : []),
  ]

  const options: DropdownOptions = {
    'CHOOSE A MODE': { action: () => { return; }, disabled: true },
    'Quick Capture (Default)': { action: () => {const temp="" } },
    'Full Capture': { action: () => {const temp="" } },
  };
  const addParameter = () => {
    setParameters([...parameters, { key: "", value: "" }])
  }

  return (
    <Form
      initialValues={{ model: 'Pump',mode: 'Static', assetName: '', assetParent: '', file_location: '' }}
      onSubmit={() => { handleInstanceModal?.() }}
      enableReinitialize
    >
      {({ _formikprops: { setFieldValue, values, errors } }) => (
        <>
          <div className="mb-1" style={{ overflowY: 'scroll', height: '70vh' }}>
            <div className="split__fields--halfwide">
              <NewSelectField
                name="model"
                label="Select Model"
                options={[{ label: "Pump", value: "Pump" }, { label: "Furnace", value: "Furnace" }]}
                infoText="Select Model"
                onOptionClick={(item) => {setModel(item.value)
                  if (item.value === "Static") {
                    setAttributes(staticPumpAttributes)
                  } else if (item.value === "Dynamic") {
                    setAttributes(dynamicPumpAttributes)
                  } else if (item.value === "Import") {
                    setAttributes(importPumpAttributes)
                  }
                }}
                className="mb-1"
              />

              <NewSelectField
                name="mode"
                label="Select Mode"
                options={[{ label: "Static", value: "Static" }, { label: "Dynamic", value: "Dynamic" }, { label: "Import", value: "Import" }]}
                infoText="Select Mode"
                onOptionClick={(item) => {
                  setMode(item.value)
                  if (item.value === "Static") {
                    setAttributes(staticPumpAttributes)
                  } else if (item.value === "Dynamic") {
                    setAttributes(dynamicPumpAttributes)
                  } else if (item.value === "Import") {
                    setAttributes(importPumpAttributes)
                  }
                }}
                className="mb-1"
              />
            </div>
            {values?.mode !== "Import" && <div className="split__fields--halfwide">
              <InputField
                label="Asset Name/Path" name="assetName"
                infoText="Enter Asset Name/Path"
                className="mb-1"
              />
              <InputField
                label="Asset Parent Name/Path" name="assetParent"
                infoText="Enter Parent Name/Path"
                className="mb-1"
              />
            </div>}


            {values?.mode === "Dynamic" && (

              <div style={{ border: '1px solid #fff', padding: 20, marginTop: 10, borderRadius: 10 }}>
                <Flex flexDirection={"row"} justifyContent={'space-between'} alignItems={"center"}>
                  <label>Parameters</label>
                  <Button
                    type="primary"
                    className='route-btn-submit'
                    onClick={addParameter}
                    style={{ marginTop: 16, marginBottom: 20 }}
                    icon={<PlushIcon />}
                  >
                    Add Parameter
                  </Button>
                </Flex>
                <Table
                  dataSource={parameters}
                  columns={parameterColumns}
                  rowClassName={() => 'editable-row'} className='operator-table'
                  pagination={false}
                  rowKey={(record, index) => index?.toString() || ""}
                />

              </div>
            )}
            {values?.mode === "Import" && (
              <Flex flexDirection={"row"}>
                {/* <div> */}

                <div >

                  <Flex flexDirection={"row"} justifyContent={'space-between'} alignItems={"center"}>
                    <InputField
                      name="file_location"
                      label="File Path"
                      style={{ width: 600 }}
                      required={true}
                      autoComplete="off"
                      infoText='Location to save delimited file/csv. Note: When file system type is DBFS, file needs to be saved in /user/root/ folder. (e.g.: /user/root/filename.csv)'
                    />
                    <div style={{ marginTop: '2rem', marginLeft: 20 }}><FormikDeltaLakesBrowserComponent fieldName="file_location" /></div>
                  </Flex>
                </div>
                {/* </div> */}
              </Flex>


            )}

            {values?.mode === "Import" && <div style={{ marginTop: 20 }} className="split__fields--halfwide">
              <div className="innerCaptureSchemabox">
                <Flex display={"flex"} gap={"30px"} flexDirection={"row"}>
                  <label>Asset Name/Path</label>
                  <Flex display={"flex"} alignItems={"center"}>
                    <Button
                      className="btn-yellow btn-capture"
                      size="small"
                      loading={false}
                    >
                      <CaptureSchemaIcon />
                    </Button>
                    <Dropdown
                      title={
                        <span className="btn-yellow btn__dropdown__triangle">
                          &#x25BE;
                        </span>
                      }
                      position="right"
                      dropdownOptions={options}
                      className="runWorkflow__options"
                    />
                  </Flex>
                </Flex>
              </div>
              <div className="innerCaptureSchemabox">
                <Flex display={"flex"} gap={"30px"} flexDirection={"row"}>
                  <label>Parent Name/Path</label>
                  <Flex display={"flex"} alignItems={"center"}>
                    <Button
                      className="btn-yellow btn-capture"
                      size="small"
                      loading={false}
                    >
                      <CaptureSchemaIcon />
                    </Button>
                    <Dropdown
                      title={
                        <span className="btn-yellow btn__dropdown__triangle">
                          &#x25BE;
                        </span>
                      }
                      position="right"
                      dropdownOptions={options}
                      className="runWorkflow__options"
                    />
                  </Flex>
                </Flex>
              </div>


            </div>}


            {values?.mode === "Static" && <div>
              <label>Asset Attributes</label>
              <div style={{ border: '1px solid #fff', padding: 20, marginTop: 10, borderRadius: 10 }}>
                <Table dataSource={attributes} rowClassName={() => 'editable-row'} className='operator-table' columns={attributeColumns} pagination={false} rowKey="key" />

              </div>
            </div>}
            {values?.mode === "Dynamic" && <div>
              <label>Asset Attributes</label>
              <div style={{ border: '1px solid #fff', padding: 20, marginTop: 10, borderRadius: 10 }}>
                <Table dataSource={attributes} rowClassName={() => 'editable-row'} className='operator-table' columns={attributeColumns} pagination={false} rowKey="key" />
              </div>

            </div>}
            {values?.mode === "Import" && <div style={{ marginTop: 20 }}>
              <label>Asset Attributes</label>
              <div style={{ border: '1px solid #fff', padding: 20, marginTop: 10, borderRadius: 10 }}>
                <Table dataSource={attributes} rowClassName={() => 'editable-row'} className='operator-table' columns={attributeColumns} pagination={false} rowKey="key" />
              </div>

            </div>}

          </div>
          <Space style={{ marginBottom: 20, marginTop: 20, display: 'flex', justifyContent: 'end' }}>
            <Button className='route-btn-submit' style={{ background: "#fff", color: '#000', width: 200 }} onClick={() => { handleInstanceModal?.() }}>Discard</Button>
            <Button className='route-btn-submit' type="primary" onClick={() => handleInstanceModal?.()} style={{ background: "#fcad01", color: '#000', width: 200 }} htmlType="submit">
              Save
            </Button>
          </Space>
        </>
      )}

    </Form>
  )
}

export default Instance;