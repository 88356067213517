import { HttpClient } from '../services';
import { ExecutionAnalyticsFlowAllResponse, IngestionLogResponse } from './types';
import { API } from '../../constants/settings';

class AzkabanDataHandlerClass {
    ExecutionAnalyticsFlowAll(link: string,id: number, time: number, callback: (arg0: ExecutionAnalyticsFlowAllResponse) => void, errorHandlerCallback? : (arg0: any) => void) {
        if(id) {
            HttpClient.Get('Execution Analytics Flow All', `${link}/v1/azkaban/execution_analytic_flow/` + id + '?time=' + time, callback, errorHandlerCallback);
        } else {
            HttpClient.Get('Execution Analytics Flow All', link +'/v1/azkaban/execution_analytic_flow/all/' + time, callback, errorHandlerCallback);
        }
        
    }

    IngestionLog(link:string, execId: number, callback: (arg0: IngestionLogResponse) => void, errorHandlerCallback? : (arg0: any) => void) {
        HttpClient.Get('Ingestion Log', link + '/v1/azkaban/ingestion/log/' + execId, callback, errorHandlerCallback);
    }
}

const AzkabanDataHandler = new AzkabanDataHandlerClass();

export { AzkabanDataHandler };