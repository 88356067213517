import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form, { InputField, RadioField } from '../../../../components/form';
import * as yup from 'yup';
import styles from "../../styles.module.scss";
import { Flex } from '@components/ui/Flex';
import { Text } from '@components/ui/Text';
import { ShowWhenTrue } from '@helpers/showwhentrue';
import { RootState } from '@store/types';
import { useSelector } from 'react-redux';
import { Select, Spin, Switch } from 'antd';
import { DataSourceHandler } from '@api/data-source-handler';

const hierarchyFormSchema = (type: 'UPDATE' | 'ADD') => yup.object({
    edgeName: yup.string().test(
        "no-only-spaces",
        "This field cannot contain only spaces.",
        (value) => value && value.trim().length > 0
    ).test(
        "no-leading-spaces",
        "This field cannot start with a space.",
        (value) => value && value[0] !== " "
    ).required('This is a required field'),
    hierarchyName: yup.string().test(
        "no-only-spaces",
        "This field cannot contain only spaces.",
        (value) => value && value.trim().length > 0
    ).test(
        "no-leading-spaces",
        "This field cannot start with a space.",
        (value) => value && value[0] !== " "
    ).required('This is a required field'),
    fileMode: yup.string(),
    enableEmailNotification: yup.boolean(),
    pullWorkflowId: yup.number().nullable(),
    pushWorkflowId: yup.number().nullable(),
    emailId: yup.array()
      .when("enableEmailNotification", {
        is: true,
        then: (schema: any) => schema.min(1, "At least one email must be selected when notifications are enabled"),
        otherwise: (schema: any) => schema.optional(),
      }),
    // file: yup.mixed()
    //     .test("fileType", "Only CSV files are allowed", (value: any) => {
    //         if (!value || type === 'UPDATE') return true;
    //         return value instanceof File && value.type === "text/csv";
    //     })
    //     .when('_', {
    //         is: () => type === 'ADD',
    //         then: (schema: { required: (arg0: string) => any; }) => schema.required('This is a required field'),
    //         otherwise: (schema: { optional: () => any; }) => schema.optional()
    //     }),
});

export type HierarchyFormValues = yup.InferType<typeof hierarchyFormSchema>

export type UserFormProps = {
    type: 'UPDATE' | 'ADD';
    initialValues: any;
    submitButtons?: React.ReactNode;
    onSubmit?: (arg0: HierarchyFormValues) => any;
    edgeName: string;
}

export const HierarchyForm: React.FC<UserFormProps> = ({ onSubmit, type, initialValues, submitButtons, edgeName }) => {
    const [enableNotification, setEnableNotification] = useState(initialValues?.enableEmailNotification || false);
    const usersFromStore = useSelector((state: RootState) => state.AccountReducer.allUsersList);
    const { envVariables: Env } = useSelector((store: RootState) => store.AccountReducer);
    const [workflows, setWorkflows] = useState<any[]>([]);

    const getAllWorkflows = useCallback(() => {
        DataSourceHandler.GetAllWorkflows(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, (res) => {
            setWorkflows(res.data);
        });
      }, [Env?.REACT_APP_PLATFORM_URL]);

      useEffect(() => {
        getAllWorkflows();
        }, [Env?.REACT_APP_PLATFORM_URL]);

    const initialValuesForForm = useMemo(() => {
        return {
            edgeName: edgeName,
            hierarchyName: initialValues?.hierarchyName || '',
            file: initialValues?.file || null,
            fileMode: initialValues?.fileMode || 'append',
            selectedUsers: initialValues?.selectedUsers || [],
            emailId: initialValues?.emailId ? initialValues.emailId.split(',') : [],
            enableEmailNotification: initialValues?.enableEmailNotification || false,
            pullWorkflowId: initialValues?.pullWorkflowId || null,
            pushWorkflowId: initialValues?.pushWorkflowId || null
        };
    }, [initialValues, type, edgeName]) as HierarchyFormValues;

    const handleFormSubmit = (values: HierarchyFormValues) => {
        onSubmit?.(values);
    };


    return (
        <Form
            initialValues={initialValuesForForm}
            validationSchema={hierarchyFormSchema(type)}
            onSubmit={handleFormSubmit as any}
            enableReinitialize
        >
            {({ _formikprops: { errors, setFieldValue, touched, values } }) => {
                return (
                    <>
                        <div
                            className={styles['userInfoForm']}
                        >
                            <InputField
                                name="edgeName"
                                label="Edge Name"
                                required={true}
                                disabled={true}
                                autoComplete="off"
                            />
                            <InputField
                                name="hierarchyName"
                                label="Hierarchy Name"
                                required={true}
                                autoComplete="off"
                            />
                            <span style={{ marginBottom: '-20px' }}>Pull Workflows</span>
                            <Select
                                className="hierarchyWorflowSelect"
                                style={{ width: '100%', marginTop: 0 }}
                                placeholder="Select Workflows"
                                value={
                                    initialValues?.pullWorkflowId
                                        ? workflows.find(w => w.id === values.pullWorkflowId)?.projectName || <Spin size="small" />
                                        : workflows.find(w => w.id === values.pullWorkflowId)?.projectName
                                }
                                onChange={(selected) => setFieldValue("pullWorkflowId", selected)}
                                showSearch
                                filterOption={(input, option) => {
                                    const projectName = option?.props?.children;
                                    return projectName && typeof projectName === "string"
                                        ? projectName.toLowerCase().includes(input.toLowerCase())
                                        : false;
                                }}
                            >
                                {workflows.map(user => (
                                    <Select.Option key={user.id} value={user.id}>
                                        {user.projectName}
                                    </Select.Option>
                                ))}
                            </Select>
                            <span style={{ marginBottom: '-20px' }}>Push Workflows</span>
                            <Select
                                className="hierarchyWorflowSelect"
                                style={{ width: '100%', marginTop: 0 }}
                                placeholder="Select Workflows"
                                value={
                                    initialValues?.pushWorkflowId
                                        ? workflows.find(w => w.id === values.pushWorkflowId)?.projectName || <Spin size="small" />
                                        : workflows.find(w => w.id === values.pushWorkflowId)?.projectName
                                }
                                showSearch
                                onChange={(selected) => setFieldValue("pushWorkflowId", selected)}
                                filterOption={(input, option) => {
                                    const projectName = option?.props?.children;
                                    return projectName && typeof projectName === "string"
                                        ? projectName.toLowerCase().includes(input.toLowerCase())
                                        : false;
                                }}
                            >
                                {workflows.map(user => (
                                    <Select.Option key={user.id} value={user.id}>
                                        {user.projectName}
                                    </Select.Option>
                                ))}
                            </Select>
                            <div>
                                <label className="inputField__label" style={{marginRight:4}}>Enable Notification</label>
                                <Switch
                                    checked={enableNotification}
                                    onChange={(checked) => {
                                        setEnableNotification(checked);
                                        setFieldValue("enableEmailNotification", checked);
                                    }}
                                    style={{
                                        backgroundColor: values.enableEmailNotification ? '#FCAD01' : '#d9d9d9',
                                        borderColor: values.enableEmailNotification ? '#FCAD01' : '#d9d9d9'
                                    }}
                                />
                            </div>
                            <ShowWhenTrue show={enableNotification}>
                                <span style={{marginBottom:'-20px'}}>Select Users</span>
                                <Select
                                    className="hierarchyWorflowSelect"
                                    mode="multiple"
                                    style={{ width: '100%', marginTop: 0 }}
                                    placeholder="Select users"
                                    value={values.emailId}
                                    onChange={(selected) => setFieldValue("emailId", selected)}
                                >
                                    {usersFromStore.map(user => (
                                        <Select.Option key={user.email} value={user.email}>
                                            {user.firstName} {user.lastName.charAt(0)} ({user.email})
                                        </Select.Option>
                                    ))}
                                    </Select>
                            </ShowWhenTrue>

                            <Flex gap='10px' flexDirection='column'>
                                <label className="inputField__label" htmlFor="fileUpload">Upload File </label>
                                <input name='file' className={styles['upload-input']} type="file" accept=".csv"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const selectedFile = event.target.files && event.target.files?.[0];
                                        if (selectedFile) {
                                            setFieldValue("file", selectedFile);
                                        }
                                    }}
                                />
                                {/* {errors.file && touched.file && <Text fontSize='10px' color='#ff7761'>{errors.file}</Text>} */}
                            </Flex>
                            <ShowWhenTrue show={(values.file) && type === 'UPDATE'}>
                                <RadioField
                                    name="fileMode"
                                    options={[{ label: 'Append', value: 'append' }, { label: 'Replace', value: 'replace' }]}
                                    infoText='Choose whether to append new data to existing hierarchy or replace it entirely.'

                                />
                            </ShowWhenTrue>
                            <div className={styles['divider']}></div>
                        </div>
                        {!!submitButtons && submitButtons}
                    </>
                )

            }}
        </Form>
    );
};

