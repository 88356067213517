import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import _ from 'lodash';
import { Dropdown, DropdownOptions } from '../form/dropdown';
import {logout } from '../../utils/common';
import { KeycloakService } from '../../services';
import { useAppSelector } from '@store/hooks';
import { SHOW_WORKFLOW_HELP, START_ADMIN_COUCHMARK, START_CLUSTER_COUCHMARK, START_FILE_BROWSER_COUCHMARK } from '@store/coachMark/constants';
import { Env } from '@constants/settings';

export const UserDetails: React.FC<{hideNameOnMinimize?: boolean; disableDropdown?: boolean}> = ({ hideNameOnMinimize, disableDropdown }) => {
    const dispatch1 = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const userInfo = useSelector((store: RootState) => store.AccountReducer.activeUserInfo);
    const { isPropertiesMinimized: isPropertiesInWorkflowMinimized } = useSelector((store: RootState) => store.WorkflowReducer);
    const { isColumnManagementMinimized } = useSelector((store: RootState) => store.NewDataSourceReducer);
    const isAdmin = useAppSelector((state) => state.AccountReducer.activeUserInfo.id ? state.AccountReducer.activeUserInfo.isAdmin : true);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    function getUserInitials(name: string): string {
        let userInitials = '';
        const splitName = name.split(' ');
        splitName.map(word => {
            if(word) userInitials += word[0];
        });
        return _.upperCase(userInitials);
    }

    function handleLogout() {
        KeycloakService.doLogout();
    }

    function adminProductWalkThrough() {
        if(location.pathname.includes('cluster'))
            dispatch1({type: START_CLUSTER_COUCHMARK})
        else if(location.pathname.includes('filemanagement'))
            dispatch1({type: START_FILE_BROWSER_COUCHMARK})
        else if(location.pathname.includes('workflow')) 
            dispatch1({type: START_ADMIN_COUCHMARK})
    }

    function handleAccountSettingsRedirect() {
        history.push('/profile');
    }

    function tourAction() {
        if(location.pathname.includes('workflow')) 
            dispatch1({type: SHOW_WORKFLOW_HELP})
    }

    function renderTitle() {
        return (
            <span id="logout_user" className="user__initials">
                {userInfo.name && getUserInitials(userInfo.name)}
            </span>
        );
    }

    const minimizeUserName = useMemo(() => {
        if(hideNameOnMinimize) {
            if(location.pathname.includes('workflow')) return isPropertiesInWorkflowMinimized;
            else return isColumnManagementMinimized;
        } return false;
    }, [isPropertiesInWorkflowMinimized, isColumnManagementMinimized]);

    const options:DropdownOptions =isAdmin ? {
        // 'Account Settings': { action: handleAccountSettingsRedirect },
        'Log out': {action: handleLogout},
        'Product walk through': {action: adminProductWalkThrough},
        'Tour': {action: tourAction}
    } : {
        // 'Account Settings': { action: handleAccountSettingsRedirect },
        'Log out': {action: handleLogout},
        'Tour': {action: tourAction}
    };
    
    const dropDownOptions = Env?.ENABLE_DATASTUDIO_WALKTHROUGH?options: { 'Log out': {action: handleLogout},};

    return(
        <div className={classNames('user__dropdown', {'user__dropdown--minimzed': minimizeUserName  })}>
            <span className={classNames('user__name', {'hide': minimizeUserName })}>
                {_.capitalize(userInfo.name)}
            </span>
            {disableDropdown ?
                renderTitle()
                :
                <Dropdown
                    title={renderTitle()}
                    dropdownOptions={dropDownOptions}
                    position="right"
                />
            }
        </div>
    );
};