import { getRequiredFieldSchema } from '@utils/common';
import { object, string, number, InferType, boolean } from 'yup';


export const connectionBaseFormSchema = object().shape({
    name: string(),
    host: string(),
    port: string(),
    username: string(),
    password: string(),
    connectionType: string(),
    url: string(),
    databaseName: string(),
    driver: string(),
    connectionStringOption: string(),
    platformType: string(),
    sourceType: string(),
    // formSchemaForNoSqlRelational
    oracleServiceName: string(),
    // for witsml
    headers: object(),
    // for api
    serverUrl: string(),
    requestType: string(),
    scheduleType: string(),
    weekOfTheMonth: number().nullable(),
    days: number().nullable(),
    hrs: number().nullable(),
    mins: number().nullable(),
    months: number().nullable(),
    weekDays: string().nullable(),
    formData: object(),
    queryparam: object(),
    authType: string(),
    scheduleAdvancedMode: boolean(),
    responseTokenKey: string(),
    scheduleTimingInfo: string(),
    cronExpression: string(),
    // azureBlobSchema
    accessTypeName: string(),
    accountName: string(),
    accountKeyOrSasToken: string(),
    // azure eventshub
    sharedAccessKeyName: string(),
    sharedAccessKey: string(),
    //edge
    accessKey: string(),
    edgeName: string(),
    nodeName: string(),
    // aws
    region: string(),
    accessKeyId: string(),
    secretAccessKey: string(),
    // for snowflake
    schema: string(),
    warehouse: string(),
    // for dataExplorer
    clientId: string(),
    clientSecret : string(),
    tenantId: string(),
    projectId:string(),
    privateKeyJsonFile:string(),
    serviceAccountEmailId: string().email('Please enter a valid email address'),
    testConnection:boolean().default(true),
    isDefaultStorage:boolean(),
    isRasterPlotStorage:boolean(),
    entityPath:string(),
    containerName:string(),
    connectionString:string(),
    privateKey:string(),
    isPlainText:boolean(),
    secretManager: string(),
    secretManagerRegion: string(),
    cloudProvider: string(),
});


export type schemaTypes = InferType<typeof connectionBaseFormSchema>



export const connectionFieldSchemas = {
    name: getRequiredFieldSchema("Name"),
    host: getRequiredFieldSchema("Host"),
    port: getRequiredFieldSchema("Port"),
    url: getRequiredFieldSchema("Url"),
    platformType: getRequiredFieldSchema("Platform"),
    sourceType: getRequiredFieldSchema("Source"),
    username: getRequiredFieldSchema("Username"),
    accountName: getRequiredFieldSchema("Account Name"),
    accountKeyOrSasToken: getRequiredFieldSchema("This"),
    accessTypeName: getRequiredFieldSchema("Access type"),
    oracleServiceName: getRequiredFieldSchema("This"),
    databaseName: getRequiredFieldSchema("Database Name"),
    requestType: getRequiredFieldSchema("Request Type"),
    responseTokenKey: getRequiredFieldSchema("Token Attribute"),
    tokenUrl: getRequiredFieldSchema("Token Url"),
    sharedAccessKeyName: getRequiredFieldSchema("Shared Access Key Name"),
    edgeName: getRequiredFieldSchema("This"),
    nodeName: getRequiredFieldSchema("This"),
    accessKey: getRequiredFieldSchema("This"),
    accessKeyId: getRequiredFieldSchema("Access Key ID"),
    secretAccessKey: getRequiredFieldSchema("Secret Access Key"),
    region: getRequiredFieldSchema("Region"),
    schema: getRequiredFieldSchema("Schema"),
    warehouse: getRequiredFieldSchema("Warehouse"),
    clientId: getRequiredFieldSchema("Client Id"),
    clientSecret: getRequiredFieldSchema("Client Secret"),
    tenantId: getRequiredFieldSchema("Tenant Id"),
    projectId : getRequiredFieldSchema("Project Id"),
    privateKeyJsonFile:getRequiredFieldSchema("Private Key Json File"),
    serviceAccountEmailId : getRequiredFieldSchema("Service Account Email Id"),
    password: getRequiredFieldSchema("Password"),
    entityPath: getRequiredFieldSchema("Event Hub Name"),
    containerName: getRequiredFieldSchema("Container Name"),
    connectionString: getRequiredFieldSchema("Connection String"),
}