import _ from 'lodash';
import { Env } from '../../constants/settings';
import { AdminReducerState, ActionTypes, UserManagementReducerState, TOGGLE_USERMANAGEMENT_MODAL, ToggleUserManagementModalType, SET_ACTIVE_ADMIN_TAB } from './types';

const initialState: AdminReducerState = {
    users: [],
    activeAdminTab:  'usermanagement'
};

const initialStateUserManagement: UserManagementReducerState = {
    showModal: {
        addUser: false,
        editUser: false,
        editCluster:false,
    },

};


export const AdminReducer = (state = initialState, action: ActionTypes): AdminReducerState => {
    const new_state = _.cloneDeep(state);

    switch(action.type){
        case 'ACTIVE_INACTIVE_USERS':
            _.setWith(new_state, ['users'], action.payload);
            return new_state;
        case SET_ACTIVE_ADMIN_TAB:
            return { ...state, activeAdminTab: action.payload };
        default:
            return state;
    }
};

export const UserManagementReducer = (state = initialStateUserManagement, action: ToggleUserManagementModalType): UserManagementReducerState => {
    switch(action.type) {
        case TOGGLE_USERMANAGEMENT_MODAL:
            return { ...state, showModal: { ...state.showModal, ...action.payload } };
        default:
            return state;        
    }

};