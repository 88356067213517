import { JobInfo } from './types';
import { HttpClient } from '../services';
// import { API, Env } from '../../constants/settings';
import { ApiResponse } from '../data-source-handler';
import { ExecutionEnvModes } from '../../constants/enums';
import { stringifyQueryParams } from '../../helpers/utils';

class JobsHandlerClass {
   
    GetRecentJobs(link: string, env: ExecutionEnvModes, callback: (arg0: JobInfo[]) => any) {
        HttpClient.Get('Gets latest 10 jobs', `${link}/batches/job/recent${stringifyQueryParams({ env })}`, callback);
    }

    TerminateAJob(link: string, jobId: string, callback: (arg0: JobInfo[]) => any, errorHandlerCallBack: any) {
        HttpClient.Delete('Delete a job', `${link}/batches/job/${jobId}?version=2`, callback, errorHandlerCallBack);
    }

    TerminateAPreviewJob(link: string, sessionId: string, statementId: string, callback: any, errorHandlerCallBack: any){
        HttpClient.Delete('Terminate a preview job', `${link}/sessions/${sessionId}/statements/${statementId}/cancel`, callback, errorHandlerCallBack);
    }

    GetJobLogs(link: string, jobId: string, callback: ApiResponse<string[]>) {
        HttpClient.Get('Gets latest 10 jobs', `${link}/batches/job/${jobId}/download/log`, callback);
    }

}

const JobsHandler = new JobsHandlerClass();

export { JobsHandler };