import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Space, Table, Tag, Typography } from 'antd-latest';
import type { ColumnsType } from 'antd-latest/es/table';
import { StateData } from '../types';
import Form, { InputField } from "@components/form";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import MonacoEditor from "react-monaco-editor";
import { useFormik, useFormikContext } from 'formik';
import './table.scss'
import { isEmpty } from 'lodash';
import { Modal } from '@components/modals';
import classNames from 'classnames';
import styles from "../../../pages/file-browser/styles.module.scss";
import { useDataExplorerContext } from '../useDataExplorerContext';
import { DataSourceHandler } from '@api/data-source-handler';
import { errorAlert, infoAlert } from '@components/toastify/notify-toast';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
interface Item {
  columnName: string,
  alias: string,
  table: string,
  foreignKey: string,
  desc: string
}

const TableD: React.FC<{
  mainData: StateData,
  columnRowTableData: Item[],
  update: ()=> Promise<void>,
  setLoading: (val: boolean) => void
}> = ({
  mainData,
  columnRowTableData,
  setLoading,
  update
}) => {
  const [isEdit, setEdit] = useState(false)
  const formikRef = useFormikContext()
  const [monoco, setMocoEditor] = useState<any>({});
  const originalVal = useRef<any>();
  const [showModal, setShowModal] = useState(false)
  const { database, db_table } = useDataExplorerContext();
  const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
  useEffect(() => {
    const temp: any = {}
    columnRowTableData.forEach((val: Item, index: number) => {
      temp[`alias_${index}`] = val.alias
      temp[`desc_${index}`] = val.desc
      temp[`foreignKey_${index}`] = val.foreignKey
      temp[`table_${index}`] = val.table

      formikRef.setFieldValue(`alias_${index}`, val.alias)
      formikRef.setFieldValue(`desc_${index}`, val.desc)
      formikRef.setFieldValue(`table_${index}`,  val.table)
      formikRef.setFieldValue(`foreignKey_${index}`, val.foreignKey)
      monoco[index] = val.foreignKey
    })
    originalVal.current = temp;
  }, [columnRowTableData])

  const mergedColumns = useMemo<ColumnsType<any>>(() => {
    const temp: any = [
      {
        title: 'Column',
        dataIndex: 'columnName',
        width: '20%',
        editable: true,
        render: (value: any, record: Item, index: number) => {
          return value
        },
      },
      {
        title: 'Alias',
        dataIndex: 'alias',
        width: '20%',
        editable: true,
        render: (value: any, record: Item, index: number) => {
          if(isEdit) {
            return <InputField name={'alias_' + index}  />
          } else {
            return value
          }
        },
      },
      {
        title: 'Description',
        dataIndex: 'desc',
        width: '20%',
        render: (value: any, record: Item, index: string) => {
          if(isEdit) {
            return <InputField name={'desc_' + index} />
          } else {
            return value
          }
        },
      },
      {
        title: 'Foreign key column',
        dataIndex: 'foreignKey',
        render: (value: any, record: Item, index: string) => {
          if(isEdit) {
            return (
              <MonacoEditor
                height="30px"
                width="100%"
                theme="vs-dark"
                language="deepiqmeta"
                value={monoco[index]}
                onChange={(value, event) => {
                  const [column, table] = value.split(":");
                  monoco[index] = column.split("\n")[0]
                  
                  if(isEmpty(table)) {
                    formikRef.setFieldValue(`table_${index}`, '')
                    formikRef.setFieldValue(`foreignKey_${index}`, '')
                    record.foreignKey = '';
                    record.table = '';
                  } else {
                    formikRef.setFieldValue(`table_${index}`, table)
                    formikRef.setFieldValue(`foreignKey_${index}`, column)
                    record.foreignKey = column;
                    record.table = table;
                  }
                  setMocoEditor({
                    ...monoco,
                  })
                  
                }}
                options={{
                  selectOnLineNumbers: true,
                  contextmenu: false,
                  formatOnPaste: true,
                  accessibilitySupport: "auto",
                  autoIndent: "full",
                  highlightActiveIndentGuide: true,
                  quickSuggestions: true,
                  formatOnType: true,
                  minimap: {
                    enabled: false,
                  },
                  fontSize: 14,
                  letterSpacing: 0.25,
                  lineNumbersMinChars: 0,
                  lineDecorationsWidth: 0,
                  lineHeight: 21,
                  scrollbar: {
                    verticalScrollbarSize: 0,
                    horizontalScrollbarSize: 10,
                    verticalSliderSize: 4,
                    horizontalSliderSize: 4,
                  },
                  glyphMargin: false,
                  automaticLayout: true,
                  lineNumbers: "off",
                  folding: false,
                }}
              />
            )
          } else {
            return value
          }
        },
      },
      {
        title: 'Foreign key table',
        dataIndex: 'table',
        width: '20%',
        render: (value: any) => {
          return <span style={{color: '#BDC2FF'}}>{value}</span>
        },
      },
    ];
    return temp;
  }, [isEdit])

  const discard = () => {
    if(formikRef.dirty) {
      setShowModal(true);
    } else {
      setEdit(!isEdit);
    }
  }

  const save = async () => {
   try{
    setLoading(true)
    const values:any = formikRef.values
    const relationships:{
      table_name: string,
      column_name: string,
      alias: string,
      description: string}[]= []

    const f_relationships:{
      src_table: string,
      src_column: string,
      tgt_table: string,
      tgt_column: string}[]= []


    columnRowTableData.forEach((column_row, index) => {
      if(!isEmpty(values[`alias_${index}`])) {
        const temp = {
          table_name: db_table,
          column_name: column_row.columnName,
          alias: values[`alias_${index}`],
          description: values[`desc_${index}`]
        }
        relationships.push(temp)
      }
      if(!isEmpty(values[`foreignKey_${index}`])){
        const temp = {
          src_table: db_table,
          src_column: column_row.columnName,
          tgt_column: values[`foreignKey_${index}`],
          tgt_table: values[`table_${index}`]
        }
        f_relationships.push(temp)
      }
    })

    await DataSourceHandler.PostSchemaColumn(Env?.REACT_DEEP_SQL_URL, database.id, {
      db_id: database.id,
      relationships: relationships,
    });

    await DataSourceHandler.PostSchemaForeign(Env?.REACT_DEEP_SQL_URL, database.id, {
      db_id: database.id,
      relationships: f_relationships,
    });

    update();
    setEdit(!isEdit);
   } catch(e){
    setLoading(false)
    errorAlert("Failed to update")
   }
  };

  return (
    <div className='table_metadata'>
      <div className='flex justify-between'>
        <h3 className='white'>Columns Metadata editor</h3>
        <div>
          {
            isEdit ? (
              <>
                <button type='button' className='btn-sm btn-yellow' onClick={save}>Save</button>
                <button type='button' className='btn-sm font-normal' onClick={discard}>Discard</button>
              </>
            ) : (
              <button type='button' onClick={()=>{
                setEdit(!isEdit);
              }} className='btn-bordered'>Edit Metadata</button>
            )
          }
        </div>
      </div>
      <Table columns={mergedColumns} dataSource={columnRowTableData} pagination={false} scroll={{ y: 440 }} />
      <Modal
        isOpen={showModal}
        toggleClose={()=>setShowModal(false)}
        title={
          <div className={styles["delConnectionTitleContainer"]}>
            <img src="/icons/data-browser/error.svg" />
            <div className={styles["delConnectionTitle"]}>Do you want to discard the changes?</div>
          </div>
        }
        className={classNames(
          "runWorkflowModal__container",
          styles["deleteConnectionModal"]
        )}
      >
        <div className="modalBtns__box">
          <button
            className="btn-md btn-yellow"
            onClick={()=>{
              setEdit(!isEdit);
              formikRef.resetForm({
                values: originalVal.current 
              });
              setShowModal(false)
            }}
            type="button"
          >
            Confirm Discard
          </button>
          <button
            className="btn-md btn-cancel"
            type="button"
            onClick={()=>setShowModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  )
}
export default TableD;
  