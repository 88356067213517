import React from "react";
import styles from "../../../styles.module.scss";
import { DatePicker } from "antd";
import Form, { InputField } from "@components/form";
import { Flex } from "@components/ui/Flex";
import SelectedTagEdit from "./SelectedTagEdit";

interface HierarchyFormProps {
    activeStep: number;
    setActiveStep: (step: number) => void;
    initialValues: any;
    formSchema: any;
    setShowForm: (show: boolean) => void;
    handleOnSubmit: (values: any) => void;
}

const HierarchyReqForm: React.FC<HierarchyFormProps> = ({
    activeStep,
    setActiveStep,
    initialValues,
    formSchema,
    setShowForm,
    handleOnSubmit
}) => {
    const selectedTags = initialValues.tags.split(",");
    return (
        <>
            <Form
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                validationSchema={formSchema}
                enableReinitialize
            >
                {({ _formikprops: { values, setFieldValue } }) => (
                    <>
                        <div className={styles.jsonHierarchyForm}>
                            <div style={{ padding: 16 }}>
                                <InputField
                                    name="requestName"
                                    label="Request Name"
                                    autoComplete="off"
                                    required={true}
                                    className={styles["requestName__field"]}
                                />
                                {/* <InputField
                                    name="tags"
                                    label="Tags"
                                    autoComplete="off"
                                    required={true}
                                    className={styles["requestName__field"]}
                                    type="textarea"
                                    rows={3}
                                /> */}
                                <label>Attributes</label>
                                <div className={styles["tag_container"]}>
                                    <SelectedTagEdit selectedTags={selectedTags} setFieldValue={setFieldValue}/>
                                </div>
                                <InputField
                                    name="elementRootPath"
                                    label="Element Root"
                                    autoComplete="off"
                                    required={true}
                                    className={styles["requestName__field"]}
                                />
                                 <InputField
                                    name="elementPath"
                                    label="Element"
                                    autoComplete="off"
                                    required={true}
                                    className={styles["requestName__field"]}
                                />
                                <div className="split__fields--halfwide">
                                    <Flex className={styles["requestName__field"]} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <label className="inputfield__label">Start Time </label>
                                        <DatePicker name="start_time_str" showTime format={"MM/DD/YYYY HH:mm:ss"} value={values.start_time_str} onChange={(date) => {
                                            setFieldValue("start_time_str", date);
                                        }} />
                                    </Flex>
                                    <Flex display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <label className="inputfield__label">End Time </label>
                                        <DatePicker className={styles["requestName__field"]} name="end_time_str" showTime format={"MM/DD/YYYY HH:mm:ss"} value={values.end_time_str} onChange={(date) => { setFieldValue("end_time_str", date) }} />
                                    </Flex>
                                </div>
                            </div>
                        </div>
                        <div className={styles.hierarchyFormFooter}>
                            <button type="button" className="btn btn-grey btn-md" onClick={() => { setActiveStep(activeStep - 1); setShowForm(false) }}>
                                Back
                            </button>
                            <button type="submit" className="btn btn-yellow btn-md" >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </Form>
        </>
    );
};

export default HierarchyReqForm;
