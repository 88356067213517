import React, {  useCallback, useEffect, useMemo, useState } from 'react'
import { AdjustToCellSizeTable } from '@components/table';
import { useGenAIContext } from '../useGenAIContext';
import classNames from 'classnames';
import styles from './../../styles.module.scss';
import { NextPrevPagination } from '@components/table/NextPrevPagination';
import QueryChartsModal from '@pages/data-explorer/components/queryChartsModal';
import { Modal } from "@components/modals";
import { DataSourceHandler } from '@api/data-source-handler';
import { isEmpty } from 'lodash';
import { useDataExplorerContext } from '@pages/data-explorer/useDataExplorerContext';
import { GenAITableOutput } from '../types';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

const TableContainerGenAI = () => {
    const {sqlQuery, sessionId, _sessionId, newData, isCygnet, genAiData } = useGenAIContext();
    const [pageNo, setPageNo] = useState(-1);
    const [pageSize, setPageSize] = useState(50);
    const [lastPageNo, setLastPageNo] = useState(-1);
    const [isNextDisabled, setNextDisabled] =  useState(false);
    const [isPrevDisabled, setPrevDisabled] =  useState(false);
    const [showQueryCharts,setShowQueryCharts] = useState(false);
    const [pageData, setPageData] = useState<{
        [pageNo: string]: string[][]
    }>({});
    const [isLastPage, setLastPage] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>(false);
	const { database } = useDataExplorerContext();
    const { activeUserInfo: { username: loggedUserName } } = useSelector((store: RootState) => store.AccountReducer);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const handleQueryChartsOpen = () =>{
        setShowQueryCharts(true);
    }

    const handleQueryChartsClose = () =>{
        setShowQueryCharts(false);
    }
    
    const fetchData = useCallback(async () => {
        if(isLoading || isEmpty(_sessionId)) return;
        try {
            setLoading(true)
            setError(false)
            const resp = isCygnet?  await DataSourceHandler.CygnetSQLChat(sessionId, {
				human_message: {
					content: "",
					next_action: "retrieve_data"
				},
				// chat_config: {
				// 	return_data: true,
				// 	page_no: pageNo,
				// 	page_size: pageSize
				// }
			}) : await DataSourceHandler.DeepSQLChat(Env?.REACT_DEEP_SQL_URL, database.id, sessionId, {
				human_message: {
					content: "",
					next_action: "retrieve_data"
				},
				// chat_config: {
				// 	return_data: true,
				// 	page_no: pageNo,
				// 	page_size: pageSize
				// },

			}, loggedUserName)
            setNoData(resp.data.ai_message.show_no_data_message);
            parseTableData(resp.data.ai_message.output?.output_value || {}, pageNo);
        } catch(e) {
            setError(true)
        }
        setLoading(false)
    },[newData]) 
   
    const isFloat = (n:any) => {
        return Number(n) === n && n % 1 !== 0;
    }

    const parseTableData = (data: GenAITableOutput, pageNo: number) => {
        const hasValues = Object.values(data).some(
            (field) => Array.isArray(field) && field.length > 0
        );
        genAiData(hasValues)

        const columnList = Object.keys(data);
		
		if(columnList.length === 0 || data[columnList[0]].length === 0) {
			setLastPage(true)
            setPageNo(pageNo - 1)
            setLastPageNo(pageNo -1)
			return;
		}

		const tableData: string[][] = []

		Object.keys(data[columnList[0]]).forEach((key: string, index) => {
			let temp:string[] = []
            if(index === 0) {
                columnList.forEach((column) => {
                    temp.push(column)
                })
                tableData.push(temp)
                temp = []
            }
			columnList.forEach((column) => {
                const temp1 = data[column][key]
				if(isFloat(temp1)) {
                    temp.push(parseFloat(temp1).toFixed(2))
                } else {
                    temp.push(temp1)
                }
			})
			tableData.push(temp)
		})
		setPageData({
            ...pageData,
            [pageNo]: tableData, 
        })
		return true;
	}

    const onPreviousPage = () => {
        if(pageNo === 1) return ;
        setPageNo(pageNo - 1)
    }

    const onNextPage = () => {
        if(lastPageNo !== -1 && pageNo >= lastPageNo) {
            return;
        }
        setPageNo(pageNo + 1)
    }

    useEffect(() => {
        if(isEmpty(_sessionId)) return;

        setLastPage(false)
        setPageData({})
        setNextDisabled(false)
        setPrevDisabled(true)
        setPageNo(1)
        setLastPageNo(-1)
        setLoading(false)
        setError(false)
    }, [_sessionId, pageSize, database.id, newData])
    
    const data = useMemo(() => {
        if(pageNo === -1 || isEmpty(_sessionId)) return [];

        if(pageData[pageNo]) {
            return pageData[pageNo]
        } else {
            fetchData()
            return pageData[pageNo-1] || []
        }
        
    }, [pageNo, pageData, newData])
   
    useEffect(() => {
        if(pageNo === -1 || isEmpty(_sessionId)) return
       
        if(pageNo === 1) {
            setPrevDisabled(true)
            setNextDisabled(false)
            if(Object.keys(pageData).length === 1) {
                setNextDisabled(true)
            }
            return;
        }
        if(pageNo === lastPageNo) {
            setNextDisabled(true)
            setPrevDisabled(false)
            return
        } 
        setPrevDisabled(false)
        setNextDisabled(false)
    }, [pageNo, lastPageNo])

    if(isLoading) return  ( <div className={styles['error-text']} style={{color: 'white'}}>Loading... Please wait...</div>)
    if(noData) return <div className={styles['error-text']} style={{color: '#ffffff'}}>No data was found for the generated sql query</div>

    if(!pageData[1] ||pageData[1].length === 0) return <></>

    return (
        <>
            <div className={styles["space-between"]}>
                <div className={styles["space-between"]} style={{
                    justifyContent: 'flex-end',
                    marginTop: '0.5rem'
                }}>
                    <div>&nbsp;</div>
                    <button 
                        className={classNames(styles['downloadCsvButton'], styles['ml-1'])}
                        onClick={handleQueryChartsOpen}
                    >
                        Explain
                    </button>
                </div>
            </div>
            <div className={classNames(styles['table-holder'])}>
                <div style={{minHeight: '6rem'}}>
                    <AdjustToCellSizeTable
                        data={data}
                    />
                </div>
                <div>
                    <NextPrevPagination  
                        handleItems={(page) => {
                            setPageSize(page)
                        }}
                        onNextPage={onNextPage}
                        onPreviousPage={onPreviousPage}
                        isLoading={isLoading}
                        isNextDisabled={isNextDisabled}
                        isPrevDisabled={isPrevDisabled}
                        pageItemCount={pageSize}
                        reachedLast={isNextDisabled && isLastPage}
                        pageNoList={[10, 50, 100, 250, 300, 500]}
                        pageNo={pageNo}
                    />
                </div>
            </div>
            <Modal
                isOpen={showQueryCharts}
                toggleClose={handleQueryChartsClose}
                title="Explanation"
                className={classNames("mf_model",
                    styles["addEditWriteCsvModal_"]
                )}
                showCloseMark
                shouldCloseOnOverlayClick={false}
            >
                <div className={styles["queryChartsContainer"]}>
                    <QueryChartsModal onClose={handleQueryChartsClose} sqlQuery={sqlQuery}/>
                </div>
            </Modal>
        </>
    )
}

export default TableContainerGenAI