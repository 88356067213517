import React from 'react';
import { Modal, Table, } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import styles from "../../styles.module.scss"
import { Flex } from '@components/ui/Flex';

interface FilePreviewModalProps {
    isOpen: boolean;
    onClose: () => void;
    data: any[];
    isRevisions: boolean;
    onSave: () => void;
}

const defaultColumns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 150,
},
{
    title: 'ElementType',
    dataIndex: 'elementType',
    key: 'elementType',
    width: 150,
},
{
    title: 'ElementCategory',
    dataIndex: 'elementCategory',
    key: 'category',
    width: 150,
},
{
    title: 'Element Path',
    dataIndex: 'elementPath',
    key: 'elementPath',
    width: 150,
},
{
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
    width: 150,
},
{
    title: 'UOM',
    dataIndex: 'uom',
    key: 'uom',
    width: 150,
},
{
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: 150,
    render: (text: any) => text ? moment(text).format('MM/DD/YYYY HH:mm:ss') : ''
},
{
    title: 'Updated Date',
    dataIndex: 'updatedDate',
    key: 'updatedDate',
    width: 150,
    render: (text: any) => text ? moment(text).format('MM/DD/YYYY HH:mm:ss') : ''
},

]

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({ isOpen, onClose, data = [], onSave, isRevisions }) => {
    const [currentPage, setCurrentPage] = React.useState(1);

    return (
        <>
            <Modal
                title={<div className="file-content-modal-header">Preview</div>}
                visible={isOpen}
                onCancel={onClose}
                footer={null}
                centered
                width="90%"
                style={{ height: '80vh', padding: 10, marginTop: 10 }}
                className="file-content-modal"
            >
                <div className="table-container">

                    <Table
                        columns={defaultColumns}
                        size='small'
                        dataSource={data}
                        rowClassName={(record, i) => currentPage === 1 && i === 0 ? styles.activeRow : styles.dataRow}
                        pagination={{
                            current: currentPage,
                            // pageSize: pageSize,
                            total: data?.length,
                            onChange: (page) => setCurrentPage(page),
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '50', '100', '250'],
                            className: styles.tablePagination
                        }}
                        className={styles.dataTableRevertPreview}
                    />

                    <div className={styles['addEditNifiModal__actionBtns']}>
                        <Flex flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"} gap="10px">
                            <button
                                className="btn btn-lg btn-grey"
                                type="button"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-lg btn-yellow"
                                type="button"
                                onClick={() => onSave()}

                            >
                                Confirm
                            </button>

                        </Flex>
                    </div>


                </div>
            </Modal>
        </>
    );
};

export default FilePreviewModal;