import React, { useState } from 'react';
import { Modal } from '../../../../components/modals';
import classnames from 'classnames';
import styles from "../../styles.module.scss";
import { HierarchyData } from '../types';
import { InPageSpinner } from '@components/spinners/in-page-spinner';
import { DataSourceHandler } from '@api/data-source-handler';
import { errorAlert, successAlert } from '@components/toastify/notify-toast';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { HierarchyForm } from './hierachy-form';
import { isEmpty } from 'lodash';

type NifiEdgeModalProps = {
    showModal: boolean;
    edgeName: string;
    toggleClose: () => void;
    getHierarchyData: () => void;
    hierarchyDataModal: HierarchyData | null;
    setIsHierarchyUpdatingCreating: (value: boolean) => void;
};


const HierarchyModal: React.FC<NifiEdgeModalProps> = ({
    showModal,
    edgeName,
    toggleClose,
    hierarchyDataModal,
    getHierarchyData,
    setIsHierarchyUpdatingCreating
}) => {
    const [showLoader, toggleLoader] = useState(false);
    const { envVariables: Env } = useSelector((store: RootState) => store.AccountReducer);
    const onSubmit = (values: any) => {
        const _values = { ...values, append: values.fileMode === 'append' ? true : false };
        const formData = new FormData();
        formData.append('file', _values.file);
        const metadata: {
            edgeName: any;
            hierarchyName: any;
            emailId: any;
            enableEmailNotification: any;
            pullWorkflowId: any;
            pushWorkflowId: any;
            append?: any;
            id?: any;
        } = {
            edgeName: _values.edgeName,
            hierarchyName: _values.hierarchyName,
            emailId: Array.isArray(_values.emailId) ? _values.emailId.join(",") : _values.emailId,
            enableEmailNotification: _values.enableEmailNotification,
            pullWorkflowId: _values.pullWorkflowId,
            pushWorkflowId: _values.pushWorkflowId,
        };
        if (hierarchyDataModal && hierarchyDataModal.id) {
            metadata.id = hierarchyDataModal.id;
            metadata.append = _values.fileMode=='append'?true:false;
        }
        formData.append('metadata', JSON.stringify(metadata));
        toggleLoader(true);
        setIsHierarchyUpdatingCreating(true);
        if (!isEmpty(hierarchyDataModal)) {
            DataSourceHandler.UpdateHierarchy(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`,
                formData, (res) => {
                    getHierarchyData();
                    toggleLoader(false);
                    successAlert(res)
                    toggleClose();
                    setIsHierarchyUpdatingCreating(true);
                }, (e: any) => {
                    errorAlert(e.data);
                    toggleLoader(false);
                    setIsHierarchyUpdatingCreating(false);
                });
        } else {
            DataSourceHandler.CreateHierarchy(`${Env?.REACT_APP_PLATFORM_URL}/asset/api`,
                formData, (res) => {
                    getHierarchyData();
                    toggleLoader(false);
                    toggleClose();
                    successAlert(res)
                    setIsHierarchyUpdatingCreating(true);
                }, (e: any) => {
                    errorAlert(e.data);
                    toggleLoader(false);
                    setIsHierarchyUpdatingCreating(false);
                });
        }
    };

    const isHierarchyDataNull = hierarchyDataModal === null;

    return (
        <>
            <Modal
                isOpen={showModal}
                toggleClose={toggleClose}
                title={hierarchyDataModal ? 'Edit Edge Hierarchy' : 'Add Edge Hierarchy'}
                className={classnames(
                    'editConnection_container',
                    styles['addEditNifiModal']
                )}
            >
                <div className={classnames('hierarchyModalForm', styles['addEditNifiModal__innerContainer'])}>
                    <HierarchyForm
                        type={isHierarchyDataNull ? 'ADD' : 'UPDATE'}
                        onSubmit={onSubmit}
                        initialValues={hierarchyDataModal}
                        edgeName={edgeName}
                        submitButtons={
                            <div className={styles['addEditNifiModal__actionBtns']}>
                                <button
                                    className="btn btn-lg btn-yellow"
                                    type="submit"
                                    disabled={showLoader}
                                >
                                    {showLoader ?
                                        <InPageSpinner size="XSmall" color="black" className={styles['auto']} />
                                        :
                                        isHierarchyDataNull ? 'Save' : 'Update'
                                    }
                                </button>
                                <button
                                    className="btn btn-lg"
                                    type="button"
                                    onClick={toggleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    >
                    </HierarchyForm>
                </div>
            </Modal>
        </>
    );
};

export { HierarchyModal };
