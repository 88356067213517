import { HttpClient } from '../services';
// import { API, Env } from '../../constants/settings';
import { ApiResponse } from '../data-source-handler';

class DocsHandlerClass {
    GetAllDocs(link: string, callback: ApiResponse<string[]>) {
        HttpClient.Get('Get all docs', `${link}/helpfiles/list`, callback);
    }

    GetDocByName(link: string, docName: string, callback: ApiResponse<string>) {
        HttpClient.Get('Get doc by name', `${link}/helpfiles/read?fileName=${docName}`, callback);
    }
}

const DocsHandler = new DocsHandlerClass();

export { DocsHandler };