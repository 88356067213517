import { AzureBlobFileInfo, FileHandler } from "@api/file-handler";
import { File } from "@components/FileExplorer/types";
// import { Env } from "@constants/settings";
import { uuid } from "uuidv4";
import { FileStorageController } from ".";
import { store } from "@store/index";

export const Azure: FileStorageController = {
	get prefix() {
		const state = store.getState();
		return state?.AccountReducer?.envVariables?.REACT_APP_AZURE_BLOB_FILESYSTEM_PREFIX || "";
	},

	getFiles: (path, successCallback, errorCallback) => {
		const state = store.getState();
		const link = `${state?.AccountReducer?.envVariables?.REACT_APP_PLATFORM_URL}/filebrowser/api`;
		FileHandler.getBlobFilesAndDirectoriesUsingPath(
			link,
			path,
			(fileList: AzureBlobFileInfo[]) => {
				const fileArray: File[] = fileList.map((file) => ({
					id: uuid(),
					name: file.name,
					isDir: file.isDirectory,
					size: file.size ? file.size : undefined,
					modDate: file.lastModified
						? new Date(file.lastModified)
						: undefined,
					type: file.isDirectory
						? undefined
						: file.name.split(".").pop(),
					path: "/" + path + "/" + file.name,
				}));
				successCallback(fileArray);
			},
			errorCallback
		);
	},

	deleteFile: (filePath, successCallback, errorCallback) => {
		const state = store.getState();
		const link = `${state?.AccountReducer?.envVariables?.REACT_APP_PLATFORM_URL}/filebrowser/api`;
		FileHandler.deleteFileBlob(link, filePath, successCallback, errorCallback);
	},

	downloadFile: (filePath, fileName) => {
		const state = store.getState();
		const link = `${state?.AccountReducer?.envVariables?.REACT_APP_PLATFORM_URL}/filebrowser/api`;
		FileHandler.downloadFileblob(link, filePath, fileName);
	},

	uploadFile: (
		path,
		requestData,
		successCallback,
		errorCallback,
		uploadProgress
	) => {
		const state = store.getState();
		const link = `${state?.AccountReducer?.envVariables?.REACT_APP_PLATFORM_URL}/filebrowser/api`;
		return FileHandler.blobUploadFile(
			link,
			path,
			requestData,
			successCallback,
			errorCallback,
			uploadProgress
		);
	},
};
