import React, { useState } from "react";
import { Modal } from "../../../../components/modals";
import classnames from "classnames";
import styles from "../../styles.module.scss";
import { NiFiSinkData } from "../types";
import { NifiForm } from "./nifi-sink-form";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { DeleteNifiEdge } from "./confirm";
import { DataSourceHandler } from "@api/data-source-handler";
import { errorAlert, successAlert } from "@components/toastify/notify-toast";
import { DeleteFilledIcon } from "@pages/workflow-analytics-page/assets/icons";
import { RootState } from "@store/types";
import { useSelector } from "react-redux";

type NifiEdgeModalProps = {
	showModal: boolean;
	toggleClose: () => void;
	getNifiData: () => void;
	nifidata: NiFiSinkData | null;
};

const NifiSinkModal: React.FC<NifiEdgeModalProps> = ({
	showModal,
	toggleClose,
	nifidata,
	getNifiData,
}) => {
	const [showLoader, toggleLoader] = useState(false);
	const [showDelLoader, toggleDelLoader] = useState(false);
	const [showDeletePopup, toggleDeletePopup] = useState(false);
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
	const onSubmit = (values: any) => {
		const sinkKind = values.sinkKind;
		let obj: any = {
			sinkName: values.sinkName,
			sinkKind: values.sinkKind,
			connectionName: values.connectionName
		};

		switch (sinkKind) {
			case "S3":
				obj = {
					...obj,
					bucket: values.bucket,
					objectKey: values.objectKey,
				};
				break;
			case "AzureBlob":
				obj = {
					...obj,
					blob: values.blob,
					containerName: values.containerName,
				};
				break;
			case "AzureEventHub":
				obj = {
					...obj,
					eventHubName: values.eventHubName,
					eventHubNamespace: values.eventHubNamespace,
				};
				break;
			case "Kinesis":
				obj = {
					...obj,
					amazonKinesisStreamName: values.amazonKinesisStreamName,
				};
				break;
			case "ADLSGen2":
				obj = {
					...obj,
					filesystemName: values.filesystemName,
					directoryName: values.directoryName,
					fileName: values.fileName
				};
				break;
			default:
				obj;
		}

		toggleLoader(true);
		if (nifidata) {
			DataSourceHandler.UpdateSinkNode(
				`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`,
				nifidata.sinkName,
				obj,
				(res) => {
					getNifiData();
					toggleLoader(false);
					successAlert("Successfully updated Sink");
					toggleClose();
				},
				(e: any) => {
					errorAlert(e.data);
					toggleLoader(false);
				}
			);
		} else {
			DataSourceHandler.CreateSinkNode(
				`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`,
				obj,
				(res) => {
					getNifiData();
					toggleLoader(false);
					toggleClose();
					successAlert("Successfully created Sink");
				},
				(e: any) => {
					errorAlert(e.data);
					toggleLoader(false);
				}
			);
		}
	};
	const isNifiDataNull =  nifidata === null;
	const onDelete = () => {
		toggleDelLoader(true);
		toggleDeletePopup(false);
		if(!isNifiDataNull && nifidata.sinkName){
			DataSourceHandler.DeleteSinkNode(
				`${Env?.REACT_APP_PLATFORM_URL}/databrowser/api`,
				nifidata.sinkName,
				() => {
					getNifiData();
					toggleDelLoader(false);
					toggleClose();
					successAlert("Sink deleted successfully.")
				},
				(e: any) => {
					toggleDelLoader(false);
					errorAlert(e.data);
				}
			);
		}
	};
	const isDisabled =showLoader || showDelLoader; 
	
	return (
		<>
			<Modal
				isOpen={showModal}
				toggleClose={toggleClose}
				title={nifidata ? "Edit Sink" : "Create New Sink"}
				subtitle={<div className={styles["nifi-sink-modal-subtitle"]}>{nifidata?.sinkName}</div>}
				className={classnames(
					"editConnection_container",
					styles["addEditNifiModal"]
				)}
			>
				<div className={styles["addEditNifiSinkModal__innerContainer"]}>
					<NifiForm
						type={isNifiDataNull ? "ADD" : "UPDATE"}
						onSubmit={onSubmit}
						initialValues={nifidata}
						submitButtons={
							<div
								className={
									styles["addEditNifiSinkModal__actionBtns"]
								}
							>
								<div>
									{!isNifiDataNull ? (
										<button
											className={classnames(
												"btn btn-lg btn-red",
												styles["nifi-delete-btn"]
											)}
											type="button"
											disabled={isDisabled}
											onClick={() => {
												toggleDeletePopup(true);
											}}
										>
											<div className={styles["sink-delete-btn"]}>
												<DeleteFilledIcon />
												{showDelLoader ? (
													<InPageSpinner
														size="XSmall"
														color="black"
														className={styles["auto"]}
													/>
												) : (
													"Delete"
												)}
											</div>
											
										</button>
									) : null}
								</div>
								<div>
									<button
										className={classnames(
											"btn btn-lg btn-yellow",
											styles["nifi-edit-btn"]
										)}
										type="submit"
										disabled={isDisabled}
									>
										{showLoader ? (
											<InPageSpinner
												size="XSmall"
												color="black"
												className={styles["auto"]}
											/>
										) : isNifiDataNull ? (
											"Create"
										) : (
											"Update"
										)}
									</button>
									<button
										className={classnames(
											"btn btn-lg",
											styles["nifi-cancel-btn"]
										)}
										type="button"
										onClick={toggleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						}
					></NifiForm>
				</div>
			</Modal>
			<DeleteNifiEdge
				onConfirm={onDelete}
				showModal={showDeletePopup}
				title={
					!isNifiDataNull
						? `Are you sure you want to delete the ${nifidata.sinkName ||
								""} Sink?`
						: ""
				}
				toggleClose={() => {
					toggleDeletePopup(false);
				}}
			/>
		</>
	);
};

export { NifiSinkModal };
