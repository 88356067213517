import React, { useMemo, Component, CSSProperties } from "react";
import ReactDOM from "react-dom";
import { capitalize, get, has, isObject } from "lodash";
import {
	Draggable,
	DropResult,
	DraggableProvided,
	DraggableStateSnapshot,
	DragDropContext,
	Droppable,
} from "react-beautiful-dnd";
import classNames from "classnames";
// import { Env } from "@constants/settings";
import { MoveIcon } from "@pages/workflow-analytics-page/assets/icons";
import { PlotData } from "@pages/workflow-analytics-page/Canvas";
import {
	BandType,
	lastColBoxIdNameRef,
	lastColBoxNameRef,
} from "@pages/workflow-analytics-page/enums";
import {
	ColumnErrors,
	DroppableIds,
	MultiBandColTypes,
	NormalColTypes,
	SourceColumns,
	TypesOfColumns,
} from "@pages/workflow-analytics-page/modals/plot-selection";
import RenderSelectedColumns, { ColumnInfo } from "./RenderSelectedColumns";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";

export type ColumnSelectionProps = {
	columnsList: string[];
	keys: string[];
	values: string[];
	seriesGroupings: string[];
	multiBandCols: Record<MultiBandColTypes, string[]>;
	handleColumnDrop: (
		columnName: string,
		target: TypesOfColumns,
		source: SourceColumns
	) => void;
	handleRemoveColumn: (type: TypesOfColumns, column: string) => void;
	plotType: PlotData["plotType"];
	renderSelectClearButtons: (
		type: NormalColTypes,
		selectedColumnsOfType: string[],
		disableSelectAll: boolean
	) => JSX.Element;
	bandType: BandType;
	columnErrors: ColumnErrors;
	isDataExplorer: boolean;
	theme?: string
};

const portal: HTMLElement = document.createElement("div");
portal.classList.add("my-super-cool-portal");

if (!document.body) {
	throw new Error("body not ready for portal creation!");
}

document.body.appendChild(portal);

type ItemProps = {
	provided: DraggableProvided;
	snapshot: DraggableStateSnapshot;
	column: string;
};

class PortalAwareItem extends Component<ItemProps> {
	render() {
		const provided: DraggableProvided = this.props.provided;
		const snapshot: DraggableStateSnapshot = this.props.snapshot;
		const column = this.props.column;

		const usePortal: boolean = snapshot.isDragging;

		const child = (
			<div
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				className="multiple-select-tags"
			>
				{column}
			</div>
		);

		if (!usePortal) {
			return child;
		}

		// if dragging - put the item in a portal
		return ReactDOM.createPortal(child, portal);
	}
}

const multiColKeys: MultiBandColTypes[] = ["red", "green", "blue"];

const ColumnSelection: React.FC<ColumnSelectionProps> = ({
	columnsList,
	keys,
	values,
	seriesGroupings,
	multiBandCols,
	handleRemoveColumn,
	handleColumnDrop,
	plotType,
	renderSelectClearButtons,
	bandType,
	columnErrors,
	isDataExplorer,
	theme
}) => {
	const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
	
	const {
		isCurrentGraphTypeMap,
		isCurrentGraphTypeGeoSpatial,
		isMapPlot,
		isRasterMultiBandSelection,
	} = useMemo(() => {
		const isCurrentGraphRaster = plotType === "raster";
		const isCurrentGraphTypeMap = plotType === "map";
		const isCurrentGraphTypeGeoSpatial = plotType === "geospatial";
		const isMapPlot =
			isCurrentGraphTypeMap ||
			isCurrentGraphTypeGeoSpatial ||
			isCurrentGraphRaster;

		const isRasterMultiBandSelection =
			isCurrentGraphRaster && bandType === BandType["Multi"];

		return {
			isCurrentGraphRaster,
			isCurrentGraphTypeMap,
			isCurrentGraphTypeGeoSpatial,
			isMapPlot,
			isRasterMultiBandSelection,
		};
	}, [plotType, bandType]);

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		if (
			destination?.droppableId &&
			destination.droppableId !== source.droppableId &&
			destination.droppableId !== DroppableIds.ColumnsList
		) {
			let sourceArray = columnsList;
			switch (source.droppableId) {
				case DroppableIds.keys:
					sourceArray = keys;
					break;
				case DroppableIds.values:
					sourceArray = values;
					break;
				case DroppableIds.seriesGroupings:
					sourceArray = seriesGroupings;
					break;
			}

			handleColumnDrop(
				sourceArray[source.index],
				destination.droppableId as TypesOfColumns,
				source.droppableId as TypesOfColumns
			);
		}
	};

	// this is needed to calculate the correct position of draggable column
	const viewportNode = useMemo(
		() =>
			document.getElementsByClassName(
				"plotSelectionModal__container"
			)[0] as HTMLDivElement,
		[columnsList]
	);

	const getDraggableStyle = (
		provided: DraggableProvided,
		snapshot: DraggableStateSnapshot
	) => {
		if (
			snapshot.isDragging &&
			viewportNode &&
			isObject(provided.draggableProps?.style) &&
			has(provided.draggableProps, "style.left")
		) {
			const left =
				(get(provided.draggableProps, "style.left") || 0) -
				(viewportNode.offsetLeft - viewportNode.offsetWidth / 2);

			const top =
				(get(provided.draggableProps, "style.top") || 0) -
				(viewportNode.offsetTop - viewportNode.offsetHeight / 2);

			return { ...provided.draggableProps.style, left, top };
		}
		return provided.draggableProps.style;
	};

	const columnSelectionListForRender = useMemo(() => {
		const _selectionList: ColumnInfo[] = [
			{
				label: isMapPlot ? "Latitude" : "X Axis",
				type: "keys",
				selectedColumns: keys,
				droppableId: DroppableIds.keys,
				id: isMapPlot ? "Latitude" : "XAxis",
				showEmptyError: !!columnErrors?.keys,
				disableAllColumnSelection:
					isMapPlot || plotType === "correlation",
				disabled:
					plotType === "geospatial" || plotType === "correlation" ||
					(plotType === "raster" && Env?.REACT_APP_DISABLE_RASTER_PLOT),
			},
			{
				label: isMapPlot ? "Longitude" : "Y Axis",
				type: "values",
				selectedColumns: values,
				droppableId: DroppableIds.values,
				id: isMapPlot ? "Longitude" : "YAxis",
				showEmptyError: !!columnErrors?.values,
				disableAllColumnSelection:
					isMapPlot || plotType === "correlation",
				disabled:
					plotType === "geospatial" || plotType === "correlation" ||
					(plotType === "raster" && Env?.REACT_APP_DISABLE_RASTER_PLOT),
			},
		];
		if (!isRasterMultiBandSelection) {
			_selectionList.push({
				label: lastColBoxNameRef[plotType] ?? "Groupby Columns",
				type: "seriesGroupings",
				selectedColumns: seriesGroupings,
				droppableId: DroppableIds.seriesGroupings,
				id: lastColBoxIdNameRef[plotType] ?? "GroupbyColumns",
				showEmptyError: !!columnErrors.seriesGroupings,
				disableAllColumnSelection: true,
				// disableAllColumnSelection:
				// 	plotType === "correlation" ||
				// 	plotType === "raster" ||
				// 	plotType === "geospatial",
				// disabled:
				// 	false || (plotType === "raster" && Env?.REACT_APP_DISABLE_RASTER_PLOT) ||  plotType === "correlation",
				disabled: true
			});
		}

		return _selectionList;
	}, [
		isMapPlot,
		keys,
		values,
		seriesGroupings,
		plotType,
		isRasterMultiBandSelection,
		columnErrors,
		bandType,
	]);

	const textStyle: CSSProperties | undefined = theme ? {
        color: theme === "dark" ? '#fff' : '#000'
    } : undefined;

	const backgroundStyle: CSSProperties | undefined = theme ? {
        background: theme === "dark" ? '#030613' : '#fff',
    } : undefined;

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div className="columnSelection__box">
				<div className="columnSelection__LHS">
					<label style={textStyle}>All Fields</label>
					<Droppable
						droppableId={DroppableIds.ColumnsList}
						isDropDisabled
					>
						{(provided) => (
							<ul
								className="columnsList"
								ref={provided.innerRef}
								{...provided.droppableProps}
								style={backgroundStyle}
							>
								{columnsList.map((column, index) => {
									// const disableDrag = selectedColumns[column];
									return (
										<Draggable
											key={column + "_" + index}
											draggableId={
												column +
												"_" +
												DroppableIds.ColumnsList
											}
											index={index}
										>
											{(
												draggableProvided: DraggableProvided,
												draggableSnapshot: DraggableStateSnapshot
											) => {
												return (
													<PortalAwareItem
														column={column}
														provided={
															draggableProvided
														}
														snapshot={
															draggableSnapshot
														}
													/>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>

					<span className="dragDropMsg">
						<MoveIcon />
						<span style={textStyle}>Drag & Drop tags as required</span>
					</span>
				</div>

				<div className="columnSelection__RHS">
					{columnSelectionListForRender.map((columnSelection) => (
						<div
							className={classNames("columnSelection__inner", {
								columnSelection__inner__disabled:
									columnSelection.disabled,
							})}
							key={columnSelection.id}
						>
							<div className="columnLabelBox" style={textStyle}>
								<label style={textStyle}>{columnSelection.label}</label>
								{!columnSelection.disabled &&
									renderSelectClearButtons(
										columnSelection.type as NormalColTypes,
										columnSelection.selectedColumns,
										columnSelection.disableAllColumnSelection
									)}
							</div>
							<Droppable
								droppableId={columnSelection.droppableId}
								direction="horizontal"
								isDropDisabled={columnSelection.disabled}
							>
								{(provided, snapshot) => (
									<RenderSelectedColumns
										handleRemoveColumn={handleRemoveColumn}
										getDraggableStyle={getDraggableStyle}
										droppableProvided={provided}
										droppableSnapshot={snapshot}
										{...columnSelection}
										isDataExplorer={isDataExplorer}
										theme={theme}
									/>
								)}
							</Droppable>
						</div>
					))}
					{isRasterMultiBandSelection && (
						<div className="multiBandCol__outerSelectionBox">
							{multiColKeys.map((type) => (
								<div
									className="columnSelection__inner multiBandCol__selectionBox"
									key={type}
								>
									<div className="columnLabelBox">
										<label style={textStyle}>{type} Scale Column</label>
									</div>
									<Droppable
										droppableId={type}
										direction="horizontal"
										key={type}
									>
										{(provided, snapshot) => (
											<RenderSelectedColumns
												selectedColumns={
													multiBandCols[
													type as MultiBandColTypes
													]
												}
												handleRemoveColumn={
													handleRemoveColumn
												}
												getDraggableStyle={
													getDraggableStyle
												}
												type={type}
												droppableProvided={provided}
												droppableSnapshot={snapshot}
												id={
													isCurrentGraphTypeMap
														? "YAxis"
														: isCurrentGraphTypeGeoSpatial
															? "Geometries"
															: "GroupbyColumns"
												}
												showEmptyError={
													!!columnErrors[type]
												}
												disabled={false}
												label={`${capitalize(
													type
												)} Scale Column`}
												isDataExplorer={false}
											/>
										)}
									</Droppable>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</DragDropContext>
	);
};

export default ColumnSelection;
