import { AppThunk, AppDispatch } from '../types';
import { 
    AnalyticHandler, 
    AnalyticFlowResponse,
    SearchData
} from '../../api/analytic-flow-handler';
import { 
    EXECUTION_ANALYTICS_FLOW_ALL, ExecutionAnalyticsFlowAllPayload
} from './types';
import { omit } from 'lodash';

const handleGetAnalyticsFlowSuccess = (dispatch: AppDispatch, response: AnalyticFlowResponse) => {
    const payload: ExecutionAnalyticsFlowAllPayload = { 
        data: response.data, 
        jobFrequency: response.jobFrequency, 
        hourlyBarchart: response.hourlyBarchart, 
        dailyBarchart: response.dailyBarchart,
        weeklyBarchart: response.weeklyBarchart,
        monthlyBarchart: response.monthlyBarchart,
        totalBarchart: response.totalBarchart,
        totalCount: response.totalCount, 
        is_loaded: true 
    };
    dispatch({ type: EXECUTION_ANALYTICS_FLOW_ALL, payload });
};

export function get_analytics_flow(this: any, data: SearchData): AppThunk {
    return function(this: any, dispatch, getState) {
        const { AccountReducer: { envVariables: Env}
    } = getState();
    const link = Env?.REACT_APP_DATABRICKS ? `${Env?.REACT_APP_PLATFORM_URL}/databricks/api`: `${Env?.REACT_APP_PLATFORM_URL}/platform/api`;
        const payload: ExecutionAnalyticsFlowAllPayload = { 
            data: [], 
            jobFrequency: [], 
            hourlyBarchart: [], 
            dailyBarchart: [],
            weeklyBarchart: [],
            monthlyBarchart: [],
            totalBarchart: [],
            totalCount: 0, 
            is_loaded: false 
        };
        dispatch({ type: EXECUTION_ANALYTICS_FLOW_ALL,  payload});
        const _data = omit(data, 'isKillScheduledJobs') as any;
        data.isKillScheduledJobs ?
            AnalyticHandler.GetKillScheduledJobs(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, _data, handleGetAnalyticsFlowSuccess.bind(this, dispatch as unknown as AppDispatch))
            :
            AnalyticHandler.PagenationSearchSort(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, _data, handleGetAnalyticsFlowSuccess.bind(this, dispatch as unknown as AppDispatch));
    };
}