import React from "react";
import styles from "../../../styles.module.scss";
import { Form as AntdForm } from "antd-latest";
import Form, { InputField, NewSelectField } from "@components/form";
import { Flex } from "@components/ui/Flex";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { isEmpty } from "lodash";
import { conditionTypeOptions, tagFilterTypeOptions } from "../constant";
import { PlushIcon } from "@pages/cluster_redisign/icons";
import { FaTrash } from "react-icons/fa";

interface HierarchyFormProps {
    activeStep: number;
    setActiveStep: (step: number) => void;
    initialValues: any;
    formSchema: any;
    selectedRowKeys: string[];
    handleOnSubmit: (values: any) => void;
}

const DynamicFilterForm: React.FC<{ tags: string[], type: string }> = ({ tags, type }) => {
    const filterTagOptions = tags.map((tag) => ({ label: tag, value: tag }));
    return (
        <AntdForm
            name="dynamic_filter_form_nest_item"
            // onFinish={onFinish}
            autoComplete="off"
        >
            <AntdForm.List name="filters">
                {(fields, { add, remove }) => (
                    <>
                        <button

                            onClick={() => add()}
                            className="btn btn-grey btn-md"
                            type="button"
                        >
                            <PlushIcon /> Add Filter Criteria
                        </button>
                        <div className={styles["filter_container"]}>

                            {fields.map(({ key, name, ...restField }) => (

                                <Flex key={key} display="flex" gap="0px" justifyContent={"space-between"} alignItems={"center"}>
                                    <NewSelectField
                                        name={name + 'key' + "tags"}
                                        placeholder="Select a tag"
                                        options={filterTagOptions}
                                        className={styles["filter_input__field"]}
                                    />
                                    <NewSelectField
                                        name={name + 'key' + "filter_condition_value"}
                                        placeholder="Select a condition"
                                        options={type === "number" ? conditionTypeOptions : type === "string" ? [conditionTypeOptions[0], conditionTypeOptions[1]] : []}
                                        className={styles["filter_input__field"]}
                                    />
                                    <InputField
                                        name={name + 'key' + "filter_value"}
                                        placeholder="Enter value"
                                        className={styles["filter_input__field"]}

                                    />
                                    <button
                                        onClick={() => remove(name)}
                                        style={{ marginBottom: 16 }}
                                        type="button"
                                    >
                                        <FaTrash color="white" />
                                    </button>

                                </Flex>
                            ))}
                        </div>
                    </>
                )}
            </AntdForm.List>
        </AntdForm>)
}

const HierarchyRowFilterForm: React.FC<HierarchyFormProps> = ({
    activeStep,
    setActiveStep,
    formSchema,
    initialValues,
    selectedRowKeys,
    handleOnSubmit
}) => {

    return (
        <>
            <Form
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                validationSchema={formSchema}
                enableReinitialize
            >
                {({ _formikprops: { values, setFieldValue } }) => (
                    <>
                        <div className={styles.jsonHierarchyForm}>
                            <div style={{ padding: 16 }}>
                                <NewSelectField
                                    name="tag_filter_type"
                                    label='Edit Row Filters'
                                    options={tagFilterTypeOptions}
                                    className={styles["requestName__field"]}
                                />
                                <ShowWhenTrue show={!isEmpty(values.tag_filter_type)}>
                                    <DynamicFilterForm tags={selectedRowKeys} type={values.tag_filter_type} />
                                </ShowWhenTrue>

                            </div>
                        </div>
                        <div className={styles.hierarchyFormFooter}>
                            <button type="button" className="btn btn-grey btn-md" onClick={() => { setActiveStep(activeStep - 1); }}>
                                Back
                            </button>
                            <button type="submit" className="btn btn-yellow btn-md" >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </Form>
        </>
    );
};

export default HierarchyRowFilterForm;
