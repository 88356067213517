import { HttpClient } from '../services';
import { UserData } from './types';
import { API } from '../../constants/settings';

class AdminHandlerClass {
    
    GetActiveInactiveUsers(link: string, callback: (arg0: UserData[]) => void, errorHandlerCallback? : (arg0: any) => void) {
        HttpClient.Get('active_inactive_users', `${link}/active_inactive_users`, callback, errorHandlerCallback);
    }

    PostUsers(link: string, data: any, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Post User', `${link}/users`, data, callback, errorHandlerCallback);
    }

    PutUsers(link: string, data: any, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Put('Put User', `${link}/users/` + data.id, data, callback, errorHandlerCallback);
    }

    
    SetCookie() {
        const locat = window.location.origin;
        //HttpClient.Get('Cookie User', 'https://dev.deepiqlab.com/set_cookie', ()=>{});
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        HttpClient.Get('Cookie User', `${locat}/set_cookie`, ()=>{});
    }

    RemoveCookie() {
        const locat = window.location.origin;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        HttpClient.Get('Logout User', `${locat}/logout`, ()=>{});
    }
}

const AdminHandler = new AdminHandlerClass();

export { AdminHandler };