/* eslint-disable no-fallthrough */
import { DeletedLinksInfo, DsNodeModel, DsNodeModelOptions } from '../../components/workflow-canvas/node/ds_nodemodel';
import { DsPortModel } from '../../components/workflow-canvas/port/ds_portmodel';
import {  DiagramModel, DiagramModelGenerics } from '@projectstorm/react-diagrams';
import isEmpty from 'lodash/isEmpty';
import { get, has, range, set, trim, cloneDeep, toLower, reverse } from 'lodash';
import { DsLinkModel } from '../../components/workflow-canvas/link/ds_linkmodel';
import { getFieldDefaultValue, getFieldValue } from '../../components/formcreators/schema-creator';
import { workflowRunData } from '.';
import { WorkflowCanvas, SerializedData, WorkflowMetaData, WorkflowSerializedObjData } from '../../components/workflow-canvas';
import { BaseFieldType } from '../../components/formcreators/types';
import { errorAlert } from '../../components/toastify/notify-toast';
import FileSaver from 'file-saver';
import lzString from 'lz-string';
import { AppDispatch, AppThunk } from '../../store/types';
import { getMlPipelineFunction, getMlTransformData, getPipelineParams, getPresetParams } from '../../components/formcreators/ml-pipeline/utils';
import { ModeOptions } from '../../components/formcreators/ml-pipeline/enums';
import { WorkflowReducerState } from '../../store/workflow';
import { componentFields } from '../../components/formcreators/field-creator';
import {  helpImagesUrl } from '../../constants/settings';
import { ExecutionEnvModes } from '../../constants/enums';
import { getTabType, PageTypes, TabTypes, WorkflowCanvasTabInfo } from '../../store/canvas';
import { CronSearchQuery, DagPostWorkflowInfo, DetailedWorkflowInfo, WorkflowHandler } from '../../api/workflow-handler';
import moment from 'moment';
import cronTime from '../../utils/cron-time-generator';
import parser from 'cron-parser';
import { formatScheduleStartDate, ScheduleTiming, ScheduleTypes, weekDaysRef } from './modals/schedule-workflow';
import { KeyPairTableValueType } from '../../components/formcreators/fieldComponents/keyPairComponents';
import { updateAWorkflowEditorTabInfo } from '../../store/canvas';
import { convertSerializedWorkflowDataToModel, serializeWorkflowModel } from '../../components/workflow-canvas/utils';
import { WorkflowFieldSelectionObj } from '../../components/formcreators/WorkflowField';
import { ExecutionTimeoutFrequency } from './SchedulePipelinesForm';
import { uuid } from 'uuidv4';
import { WorkflowConfigMappingInFormikContext } from '@components/formcreators/WorkflowConfigVariablePortFields/reducer';
import { getVariableKeyFromUniqueId } from '@components/formcreators/WorkflowConfigVariablePortFields/utils';
import { SoapFieldValueInFormikContext } from '@components/formcreators/SoapField';
import { WorkflowConfig, WorkflowConfigItem, convertNArgsDataToPayload } from '@services/WorkflowConfig';
import { getRepeatV3FieldsPayload } from '@components/formcreators/fieldComponents/RepeatV3/utils';
import { getCustomComponentsFnNameMapping } from '@services/CodeGenerator';

type PortInfo = {
    id: string;
    type: 'OUT' | 'IN' | string;
    link: string[];
    parentNode: string;
}

export type NodeData = {
    id: string;
    fn_id: string;
    fnName: string;
    title: string;
    outputPorts: PortInfo[];
    inputPorts: PortInfo[];
    append?: boolean;
    input_df_to_key?: boolean;
    showTillHere?: boolean;
    limitType? : string;
    data: {
        key: string;
        value: string | number | boolean | Record<string, any>;
        variable_type?: 'string' | 'integer' | 'number' | 'float' | 'boolean' | 'array' | 'object' | '';
        arg_index: number; 
        ignore_param?: boolean;
        useKey?: boolean;
        remove_multiline?: boolean;
        trigger?: TriggerData[];
        useFuzzyOptimizer ?: boolean;
    }[];
    py_code?: string;
    extraData?: any;
    passAsDict?: boolean;
    payloadForDict?: string;
    type: DsNodeModelOptions['nodeType'];
    field_var_mapping?: string[];
    df_name: string;
    previewAllowed: boolean;
    addEmptyStringAsFirstArgument ?: boolean;
    field_workflow_config_mapping: { [workflowConfigKey: string]: string[] }
    workflow_config ?: WorkflowConfigInPayload[]; // used to send workflow level config in components like dag operator which has workflow selection fields
    // data: {[x: string]: string | boolean | number };
}

export type WorkflowConfigInPayload = Record<string, WorkflowConfigItem>

type PortSourceInfo = {
    node: string;
    port: 'OUT' | 'IN' | string; 
}

type TriggerData = {
    label: string;
    value: string;
};

export type LinkData = {
    id: string;
    source: PortSourceInfo;
    target: PortSourceInfo | {};
}

function getPortsInfo(ports: DsPortModel[]): PortInfo[] {
    const portsInfo: PortInfo[] = (ports || []).map(port => 
        ({ id: port?.getID(), type: port.getOptions().name, link: Object.keys(port.getLinks()), parentNode: port?.getNode()?.getID() }));
    return portsInfo;
}

function getFieldTypeUsingClassName(className: string) {
    let _type = '_';
    if(className.includes('inputfield__container')) {
        _type = 'input';
    } else if(className.includes('select-field')) {
        _type = 'select';
    } else if(className.includes('radiofield__container')) {
        _type = 'radio';
    }
    return _type;
}

const FieldTypeMapping: Record<keyof typeof componentFields, string> = {
    'input': 'input',
    'textarea': 'input',
    'radio': 'radio',
    'select': 'select',
    'ng-select': 'select',
    'select-multiple': 'select',
    'date-picker': '_',
    captureSchemaComponent: 'input',
    table: '_',
    repeat: '_',
    label: '_',
    'multi-select': '_',
    'component-list': '_',
    'prefixSuffix': '_',
    'ml-pipeline': '_',
    'keyvaluetable': '_',
    'repeat-v2': 'input',
    'workflow': 'select',
    'unique-id': '_',
    'soap': '_',
    "repeat-v3": "_",
    'unique-value': "input",
    "workspaceSelect": "_",
};

export function getFieldTypeUsingPropsType(type: keyof typeof componentFields) {
    return FieldTypeMapping[type] || '_';
}

export function getHiddenFieldNameKey(fieldName: string, fieldType: string) {
    return fieldName + '___' + fieldType;
}

const getFieldVisibilityObj = (className: string) => {
    const fieldVisibilityObj: Record<string, true> = {};
    const fieldsHTMLCollection = document.getElementsByClassName(className);
    const fieldsHTMLArray = Array.from(fieldsHTMLCollection);

    fieldsHTMLArray.forEach(field => {
        const fieldKey = getHiddenFieldNameKey(
            field.getAttribute('data-fieldname') || '',
            getFieldTypeUsingClassName(field.className)
        );
        // fieldKey = "table_name___input"
        fieldVisibilityObj[fieldKey] = true;
    });

    return fieldVisibilityObj;
};

export function getHiddenFieldNames(): Record<string, true>  {    
    const hiddenFields = getFieldVisibilityObj('hide__formfield');
    const visibleFields = getFieldVisibilityObj('show__formfield');
    
    Object.keys(visibleFields).forEach(visibleFieldKey => {
        // There can be two fields with same key - one field can be shown and other field can be hidden on the basis of hide expression
        //  As field is shown, it has to be removed from hiddenFields 

        if(hiddenFields[visibleFieldKey]) {
            delete hiddenFields[visibleFieldKey];
        }
    });
    
    return hiddenFields; 
}


function strictFunc(values: any, expression: string): any {
    'use strict';
    try{
        const tempFn = new Function('values', 'return ' + expression);
        return tempFn(values); 
    } catch(e){
        return false;
    }
}

function convertStringToStringLiteral(_string: string): string {
    // converts 'abcd ${url}' to "`abcd ${url}`"
    const convertedString = '`' + _string + '`';
    return convertedString;
}

export function convertSpecificFieldsToNumbers(obj: Record<string, any>): Record<string, any> {
    // Check and convert specific fields
    if (typeof obj.poke_interval === "string" && !isNaN(Number(obj.poke_interval))) {
      obj.poke_interval = Number(obj.poke_interval);
    }
    if (typeof obj.timeout === "string" && !isNaN(Number(obj.timeout))) {
      obj.timeout = Number(obj.timeout);
    }
    return obj;
  }
  
export type WorkflowUserinfoForNodeData = { userName: string; workflowName: string };

type NArgsPayloadRef = {
    componentsConfigRef:  { [componentId: string]: (Record<string, { type: string; value: any }>)[] ; }
    exists: boolean;
    maxNoOfArgs: number;
}


export const convertComponentNArgsToPayload = (nArgsConfig: WorkflowConfig[]) => {
	return nArgsConfig.map(config => {
		return config.reduce((mapping, configItem) => {
			mapping[configItem.key] = {
				value: configItem.value,
				type: configItem.type,
			} 
			return mapping
		}, {} as NArgsPayloadRef['componentsConfigRef']['a'][0])
	})
}




export function prepareNodeDataForExecution(nodesData: {[id: string]: DsNodeModel}, forViewCode=false, activeExecutionEnv = 'spark', { userName, workflowName }: WorkflowUserinfoForNodeData): { nodeData: NodeData[], hasNArgsPayload: boolean } {
    let parsedNodesData: NodeData[] = [];
    let hasNArgsPayload = false;
    try {
        const { customComponentIdFnNameMapping, fnNameComponentIdMapping } = getCustomComponentsFnNameMapping(nodesData);
        const addedCustomComponentCodeMapping: { [id: string]: boolean } = {};
        parsedNodesData = Object.values(nodesData).map(node => {
            const inputPorts = node.getInPorts();
            const outputPorts = node.getOutPorts();
            const fn_id = node.extras.id ? node.extras.id : node.extras.key;
            const parsedNodeData: NodeData = {
                id: node.getID(), 
                fn_id, 
                fnName: node.extras.fnName, 
                title: node.getOptions().title,
                inputPorts: getPortsInfo(inputPorts), 
                outputPorts: getPortsInfo(outputPorts), 
                data: [],
                type: node.getOptions().nodeType,
                df_name: node.getOptions().dfName,
                previewAllowed: has(node.extras, 'previewAllowed') ? !!get(node.extras, 'previewAllowed'): true,
                field_workflow_config_mapping: {}
            };

            if(node.extras.extraData?.workflow_var_mapping?.all_fields_list) {
                // FOR ALL VARIABLES LIST
                parsedNodeData.field_var_mapping = node.extras.extraData.workflow_var_mapping.all_fields_list;
            }

            if((node.extras.extraData?.workflow_config_mapping as WorkflowConfigMappingInFormikContext)?.keyFieldMapping) {
                // FOR ALL WORKFLOW CONFIG KEYS LIST
                parsedNodeData.field_workflow_config_mapping = Object.entries(node.extras.extraData.workflow_config_mapping.keyFieldMapping)
                    .reduce((config, [configKey, selectedConfigKeys]) => {
                        if(!isEmpty(selectedConfigKeys)) {
                            config[configKey] = (selectedConfigKeys as string).split(",").map(configKey => getVariableKeyFromUniqueId(configKey))
                        }
                        return config
                    }, {} as NodeData['field_workflow_config_mapping']);
            }
            const savedFormData = node.extras.formData;
            const extraData = node.extras.extraData || {};
            const saveExtraDataKey: any = {};
            const addedArgIndices: {[x: number]: true} = {};
            const formData: NodeData['data'] = [];
            let addSqlContextArg = !((node.extras.customComponentPayload && activeExecutionEnv === 'python') || activeExecutionEnv === ExecutionEnvModes['Pipelines']); 
            // eslint-disable-next-line complexity
            savedFormData.forEach((field) => {
                 if(field.type === 'repeat') {
                    const outputFormat = field.repeat_options?.outputFormat;
                    if(outputFormat) {
                        Object.keys(outputFormat).forEach((key)=>{
                            // if variable is in the value, its key has to be added to field var mapping
                            let __value = '';
    
                            __value = extraData[field.key]? extraData[field.key][key]: ''; 
    
                            if(__value && __value.match(/{{(.*?)}}/) && parsedNodeData.field_var_mapping && !parsedNodeData.field_var_mapping?.find(fieldKey => fieldKey === key)) {
                                parsedNodeData.field_var_mapping.push(key);
                            }
                            const _repeatFieldInfo = outputFormat[key];
                            if(_repeatFieldInfo.arg_index) {
                                formData.push({
                                    arg_index: _repeatFieldInfo.arg_index,
                                    key,
                                    value: __value,
                                    variable_type: 'string',
                                    useKey: !!outputFormat[key].useKey
                                });
                            }
                        });
                        saveExtraDataKey[field.key] = true;
                    }
                    
                } else if (field.type === 'repeat-v3') {
                    const v3FieldsPayload = getRepeatV3FieldsPayload(field)
                    if(!isEmpty(v3FieldsPayload)) {
                        v3FieldsPayload.forEach((fieldPayload) => {
                            const { value: __value, key } = fieldPayload;
                            // if variable is in the value, its key has to be added to field var mapping
                            if(__value && __value.match(/{{(.*?)}}/) && parsedNodeData.field_var_mapping && !parsedNodeData.field_var_mapping?.find(fieldKey => fieldKey === key)) {
                                parsedNodeData.field_var_mapping.push(key);
                            }

                            // check if field config is present in the field value
                            const fieldConfigMatches = __value.matchAll(/<<(.*?)>>/g);
                            
                            if(fieldConfigMatches && parsedNodeData.field_workflow_config_mapping) {
                                // @ts-ignore
                                for (const match of fieldConfigMatches) {
                                    // Adds if key is not present in the field workflow config mapping, 
                                    const configItemKey = match[1]
                                    if(!parsedNodeData.field_workflow_config_mapping[configItemKey].includes(key) && has(parsedNodeData.field_workflow_config_mapping, configItemKey)) {
                                        parsedNodeData.field_workflow_config_mapping[configItemKey].push(key);
                                    }
                                }
                            }

                            formData.push(fieldPayload);
                        })
                    }
                } else if(field.type === 'ml-pipeline') { 
                    addSqlContextArg = false;
                    let dataForMlPipeline: any = {};
                    if(field.value) {
                        if(field.value.mode !== ModeOptions['Transform Data']) {
                            dataForMlPipeline = {
                                preset_params: getPresetParams(field.value),
                                pipeline_params: getPipelineParams(field.value)  
                            };
                        } else {
                            dataForMlPipeline = getMlTransformData(field.value);
                        }
                        set(dataForMlPipeline, 'preset_params.user_name', userName);
                        set(dataForMlPipeline, 'preset_params.workflow_name', workflowName);
                        parsedNodeData.passAsDict = true;
                        parsedNodeData.payloadForDict = JSON.stringify(dataForMlPipeline);
                        parsedNodeData.fnName = getMlPipelineFunction(field.value.mode);
                    }
                } else if(field.type === 'unique-id' && field.templateOptions.arg_index) { 
                    addedArgIndices[field.templateOptions.arg_index] = true;
                    formData.push({
                        arg_index: field.templateOptions.arg_index,
                        key: field.key,
                        value: uuid(),
                        variable_type: 'string',
                        useKey: !!field.templateOptions.useKey
                    });
                } else if(field.type === 'soap' && field.templateOptions.arg_index) {
                    addedArgIndices[field.templateOptions.arg_index] = true;
                    formData.push({
                        arg_index: field.templateOptions.arg_index,
                        key: field.key,
                        value: (field.value as SoapFieldValueInFormikContext).payload,
                        variable_type: 'string',
                        useKey: !!field.templateOptions.useKey
                    });

                } else if(field.templateOptions && field.templateOptions.arg_index && !addedArgIndices[field.templateOptions.arg_index]  && (field.templateOptions.type === 'hidden' ? true: !field.isFieldHidden )){
                    addedArgIndices[field.templateOptions.arg_index] = true;
                    let value: string | number | boolean = '';

                    if(field.type === 'keyvaluetable' && !!field.value && typeof(field.value) !== 'string') {
                        const _value: [string, string][] = Object.values((field.value as KeyPairTableValueType).data)
                            .reduce((prevVal, currentVal) => {
                                prevVal.push([currentVal.key, currentVal.value]);
                                return prevVal;
                            }, [] as [string, string][]);
                        value = JSON.stringify(_value);

                        if(value.match(/{{(.*?)}}/) && parsedNodeData.field_var_mapping && !parsedNodeData.field_var_mapping?.find(fieldKey => fieldKey === field.key)) {
                            parsedNodeData.field_var_mapping.push(field.key);
                        }
                        
                    } else {
                        const fieldDefaultValue = getFieldDefaultValue(field);
                        if(field.value !== '' && field.value !== undefined) value = field.value;
                        else {
                            value = fieldDefaultValue;
                        }

                        if(typeof(value) === 'string'){
                            if(toLower(value) === 'true') value = true as unknown as string;
                            else if (value === 'false') value = false as unknown as string;
                        }                    
                    }

                    if((field.templateOptions.variable_type === 'number' || field.templateOptions.variable_type === 'integer') && value === '') {
                        value = 0
                    }

                    const fieldData: NodeData['data'][0] = { 
                        key: field.key, 
                        value, 
                        arg_index: field.templateOptions.arg_index, 
                        remove_multiline: field.templateOptions.remove_multiline
                    };
                    if(field.templateOptions.variable_type) fieldData.variable_type = field.templateOptions.variable_type;
                    // fieldData.variable_type = field.templateOptions.variable_type ? field.templateOptions.variable_type : 'string'
                    // if(fieldData.key === "input_port") delete fieldData.variable_type
                    if(field.templateOptions.useKey) fieldData.useKey = true;
                    if(field.templateOptions.remove_multiline) fieldData.remove_multiline = true;
                    if(field.templateOptions.splitBy && field.templateOptions.variable_type === 'array' && fieldData.value) {
                        const temp: any = fieldData.value;
                        const splitBy = field.templateOptions.splitBy;
                        fieldData.value = temp.split(splitBy).map((res: string)=> {return `'${res}'`;}).join(splitBy);
                    }
                    if(field.async?.output_template){
                        if(extraData){
                            fieldData.value = strictFunc(extraData[field.key], convertStringToStringLiteral(field.async?.output_template));
                            if(fieldData.value === 'null'){
                                fieldData.value = '';
                            }
                        } else {
                            fieldData.value =  '';
                        } 
                    }

                    if(field.key === 'new_cluster') {
                        fieldData.value = extraData.clusterDetails;
                    } 


                    if(!isEmpty(parsedNodeData.field_var_mapping)) {
                        if((field.type === 'component-list' || field.type === 'keyvaluetable') && typeof(field.value) === 'string') {
                            // value of component-list = "[{"component_id":"044eec9a-4294-4ad9-a587-d85202daa433","data":[{"key":"mode","value":"{{Variable}}","arg_index":17}]}]"
                            // if variable is in the value, its key has to be added to field var mapping
                            // value of keyvaluetable = "[[\"pagesize\",\"1000\"],[\"updateddate\",\"ge({{max_date}})\"]]"
                            if(field.value.match(/{{(.*?)}}/) && parsedNodeData.field_var_mapping && !parsedNodeData.field_var_mapping?.find(fieldKey => fieldKey === field.key)) {
                                parsedNodeData.field_var_mapping.push(field.key);
                            }
                        }
                    }
                    if(field.type === 'workflow' && (field.templateOptions.showWorkflowConfig)) {
                        hasNArgsPayload = true;
                    }
                    

                    formData.push(fieldData);
                }  
                
                if(field.type === 'workflow' && !field.isFieldHidden && field.templateOptions.showPropertySelection) {                    
                    const workflowSelectionObj = get(extraData, field.templateOptions.showPropertySelection.key) as WorkflowFieldSelectionObj | undefined;
                    if(workflowSelectionObj) {
                        formData.push({
                            key: field.templateOptions.showPropertySelection.key, 
                            value: {
                                workflowInfo: workflowSelectionObj.workflowInfo,
                                componentsList: workflowSelectionObj.componentPropertiesArr.map(component => {
                                    const propertyValues = workflowSelectionObj.componentPropertiesValuesRef[component.componentId]

                                    return({
                                        component_id: component.componentId,
                                        properties: component.properties.split(",").map(fieldKey => ({
                                            key: fieldKey,
                                            value: propertyValues[fieldKey]
                                        }))
                                    })
                                })
                            }, 
                            useFuzzyOptimizer: true,
                            variable_type: 'object', 
                            'arg_index': field.templateOptions.showPropertySelection.propertyInfoArgIndex
                        });
                    }
                
                }

                if(field.async?.save){
                    saveExtraDataKey[field.key] = true;
                }
            });

            //skip if it is custom component, python experience, ml-pipeline
            if(addSqlContextArg &&  (node.extras.customComponentPayload? true: formData.length > 0)) {
                const isArgIndexOneAlreadyAdded = formData.find(field => field.arg_index === 1);
                if((!isArgIndexOneAlreadyAdded)) {
                    formData.push({key: 'sqlContext', value: 'sqlContext', 'variable_type': '', 'arg_index': 1});
                }
            }

            // to check if all indices are added
            savedFormData.forEach(field => {
                const arg_index = field.templateOptions?.arg_index;
                if(arg_index) {
                    const isIndexAdded = addedArgIndices[arg_index];
                    if(!isIndexAdded) {
                        const variable_type = field.templateOptions.variable_type ? field.templateOptions.variable_type : 'string';
                        const fieldData: NodeData['data'][0] = { key: field.key, value: '', arg_index, variable_type };
                        if((field.value === '' && field.value === undefined) || field.isFieldHidden){
                            // if field is hidden, defaultValue is added 
                            fieldData.value = getFieldDefaultValue(field);
                            // if(defaultValue)  = defaultValue;
                        } else {
                            fieldData.value = field.value;
                        }

                        if((field.templateOptions.variable_type === 'number' || field.templateOptions.variable_type === 'integer') && field.value === '') {
                            fieldData.value = 0;
                        }
                        if(field.templateOptions.useKey) {
                            fieldData.useKey = true;
                        }

                        if(field.templateOptions.remove_multiline) {
                            fieldData.remove_multiline = true;
                        }
                        if(field.templateOptions.splitBy && field.templateOptions.variable_type === 'array' && fieldData.value) {
                            const temp: any = fieldData.value;
                            const splitBy = field.templateOptions.splitBy;
                            fieldData.value = temp.split(splitBy).map((res: string)=> {return `'${res}'`;}).join(splitBy);
                        }

                      

                        formData.push(fieldData);
                        addedArgIndices[arg_index] = true;
                    }
                }
            });

            if(!isEmpty(extraData)){
                const obj: any = {};
                Object.keys(saveExtraDataKey).forEach((key)=>{
                    obj[key] = extraData[key];
                });
                parsedNodeData.extraData = obj;
            }


            // For variable when variable_type is static,
            // If input port is connected, first argument would be connected component's df
            // If input port is not connected, addEmptyStringAsFirstArgument = true is added to send an empty string for df
            if(parsedNodeData.type === 'variable' && !!inputPorts?.[0] && !inputPorts[0].isPortConnected()) {
                const variableTypefield = formData.find(field => field.key === 'variable_type')
                if(variableTypefield?.value === 'static') {
                    parsedNodeData.addEmptyStringAsFirstArgument = true;
                }
            }

            if(node.extras?.append) {
                parsedNodeData.append = true;
            }

            if(node.extras?.input_df_to_key) {
                parsedNodeData.input_df_to_key = true;
            }
            if(node.extras.customComponentPayload) {
                let flag = false;
                const te = node.extras.customComponentPayload.split('\n');
                if(te.length === 1) {
                    flag = true;
                    node.extras.customComponentPayload = undefined;
                }
                else if(te[1].includes('#DEEPIQ COMPONENT') && te.length === 4) {
                    flag = true;
                    node.extras.customComponentPayload = undefined;
                }
                // if(forViewCode){
                //     parsedNodeData.py_code = `#--custom start [${node.getID()}]\n${node.extras.customComponentPayload}\n#--custom end\n`;
                // } else {
                //     parsedNodeData.py_code = `${node.extras.customComponentPayload}\n`;
                // }
                if(!flag) {
                    const fnTypeValue = node.extras.fnType || '';
                    const fnName = node.extras.fnName;
                    const customComponentFnCode = node.extras.customComponentPayload;
                    const revisedFunctionName = customComponentIdFnNameMapping[parsedNodeData.id];
                    let addCustomComponentCode =  true;
                    if(revisedFunctionName !== fnName) {
                        // update the function name
                        parsedNodeData.fnName = revisedFunctionName;    
                    }

                    if(addedCustomComponentCodeMapping[revisedFunctionName]) {
                        // if the same function code is already added, don't add it again
                        addCustomComponentCode = false
                    }
                    addedCustomComponentCodeMapping[revisedFunctionName] = true;

                    if(addCustomComponentCode) {
                        const componentIdsListOfSameFnName = fnNameComponentIdMapping[revisedFunctionName].join(',');
                        // replace fn name with revised function name   
                        parsedNodeData.py_code = `\n#--custom start [${componentIdsListOfSameFnName}]\n${customComponentFnCode?.replace(`def ${fnName}`, `def ${revisedFunctionName}`)}\n#--custom end\n`;
                    }

                    if(!(node.extras.customComponentPayload && activeExecutionEnv === 'python') ) {
                        const highestArgIndex = formData.reduce((currentVal, fieldInfo) => {
                            if(currentVal < fieldInfo.arg_index) {
                                return fieldInfo.arg_index;
                            } return currentVal;
                        }, 1);
                        formData.push({
                            arg_index: highestArgIndex + 1,
                            key: 'function_type',
                            value: fnTypeValue,
                            variable_type: 'string',
                            useKey : true
                        });
                    }
                }
            }
            parsedNodeData.data = formData;
            return parsedNodeData;   
        });
    } catch(e) {
        /* eslint-disable */
        console.log(e)
        // errorAlert('Error generating the payload');
    }
    return { nodeData: parsedNodesData || [], hasNArgsPayload  };
}


function getNodeNameFromPort(port: DsPortModel) {
    let title = '';
    if(!isEmpty(port)) {
        // @ts-ignore
        title = port.getNode().getOptions().title;
    }

    return title;
}


export function prepareLinksDataForExecution(linksData: {[id: string]: DsLinkModel }): LinkData[] {
    const parsedLinksData: LinkData[] = Object.values(linksData).map(link => {
        const sourcePort = link.getSourcePort() as DsPortModel;
        // target port can be null if only one end of node is connected 
        const targetPort = link.getTargetPort() as DsPortModel;
        const sourcePortDetails = { node: sourcePort?.getNode()?.getID(), port: sourcePort?.getOptions().name };
        const targetPortDetails = !isEmpty(targetPort) ? {node: targetPort.getNode()?.getID(), port: targetPort.getOptions().name} : {};
        const bridge = `${getNodeNameFromPort(sourcePort)} ${!isEmpty(targetPort) ? ' <===>  ' + getNodeNameFromPort(targetPort) : ''}`;
        return ({
            id: link?.getID(),
            bridge,
            source: sourcePortDetails,
            target: targetPortDetails
        });
    });
    return parsedLinksData;
}

// export const checkIfAllComponentsPropsAreValid = (): boolean => {
//     let areAllComponentsPropsValid = true
//     Object.values(WorkflowCanvas.getAllNodes()).map((node) => {
//         node.getOptions().isPropertiesValid
//     })
//     return areAllComponentsPropsValid
// }

function cleanDfNames(data: any) {
    const regex = /^[^a-zA-Z]+/;
    
    return data.map((obj:any) => {
        obj.df_name = obj.df_name.replace(regex, '');
        return obj;
    });
}

export const convertWorkflowConfigToPayload = (workflowConfig: WorkflowConfig) => (workflowConfig || []).reduce((config, currentConfigItem) => {
    config[currentConfigItem.key] = currentConfigItem;
    return config
}, {} as WorkflowConfigInPayload)

export const convertWorkflowDataForExecution = (_workflowCanvasModel = WorkflowCanvas.model, forCodeView=false, expType = 'spark', workflowUserInfo: WorkflowUserinfoForNodeData, workflowConfig: WorkflowConfig | undefined): workflowRunData => {
    const workflowData: workflowRunData = { 
        workflow_config: workflowConfig ? convertWorkflowConfigToPayload(workflowConfig): {}, 
        nodes: [], 
        links: [] 
    };
    const nodes = _workflowCanvasModel.getActiveNodeLayer().getModels() as any
    const nodeGeneratedData = prepareNodeDataForExecution(nodes, forCodeView, expType, workflowUserInfo);
    const node_Data = nodeGeneratedData.nodeData;
    const cleaned_nodeData=cleanDfNames(node_Data) // function to remove any character at the starting other than alphabet in df_name
    workflowData.nodes = cleaned_nodeData
    workflowData.links = prepareLinksDataForExecution(_workflowCanvasModel.getActiveLinkLayer().getLinks() as any);

    if(nodeGeneratedData.hasNArgsPayload) {
        const data = convertNArgsDataToPayload(nodes, workflowConfig) as any;;
        workflowData.workflow_config = {
            isNArgs: data.length > 1,
            data
        } as any
    }

    return workflowData;
};

export const addLabelsAndIsFieldHiddenToNodesIfEmpty = (componentFnNames: WorkflowReducerState['componentFnNamesInfo'], _workflowCanvasModel = WorkflowCanvas.model) => {
    (_workflowCanvasModel.getNodes() as DsNodeModel[]).forEach((node) => {
        if(!node.getOptions().actualTitle  && node.getOptions().nodeType !== 'variable') {
            node.getOptions().actualTitle = componentFnNames[node.extras.fnName];
        }
        if(!isEmpty(node.extras.formData)) {
            const { formData } = node.extras;
            const values: Record<string, any> = {};
            formData.forEach((field: BaseFieldType) => {
                values[field.key] = getFieldValue(field);
            });
            formData.forEach((field: BaseFieldType) => {
                if(!has(field, 'isFieldHidden')) {
                    let isFieldHidden = false;
                    if(field.hideExpression) {
                        isFieldHidden = strictFunc(values, field.hideExpression);
                    }
                    field.isFieldHidden = isFieldHidden;
                }
            });
        }
    });
};

export const serializeWorkflow = (workflowMetaData: WorkflowMetaData, _model = WorkflowCanvas.model): string => {
    let _serializedData = '';
    const isCanvasEmpty = isEmpty(_model.getNodes());
    if (!isCanvasEmpty) {
        _serializedData = serializeWorkflowModel(_model, workflowMetaData)
    }
    return _serializedData;
};

export const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const addRunTillHereIdIfExists = (nodes: NodeData[]): AppThunk => (dispatch, getState): NodeData[] => {
    const { idOfComponentForRunTillHere: componentId} = getState().WorkflowReducer;
    if(componentId){
        const updatedNodes = nodes.map(node => {
            if (node.id === componentId) node.showTillHere = true;
            return node;
        });
        return updatedNodes;
    }
    return nodes;  
};


// const clearFormValuesAndExtraDataOfReadAndWrite = (node: DsNodeModel) => {
//     if(_.isArray(node.extras.formData)) {
//         node.extras.formData = node.extras.formData.map(formData => {
//             if(node.extras.fnName.includes('read') || node.extras.fnName.includes('write')) {
//                 delete formData['value'];
//             }
//             return formData;
//         });
//     }
    
//     if(_.has(node.extras, 'extraData')) {
//         node.extras.extraData = {};
//     }
// };

export type WorkflowDependenciesRef = { [workflowId: number]:  { [componentId: string]:  number[]  } };
export type ClonedWorkflowInfo =  {details: WorkflowSerializedObjData; payload: workflowRunData}
export type CloneWorkflowInfoArgType =  { 
    serializedWorkflowInfo: SerializedData,
    env: ExecutionEnvModes, 
    workflowUserInfo: WorkflowUserinfoForNodeData, 
    workflowConfig: string, 
}

export const getDependentWorkflowsInfo = (serializedWorkflowInfo: SerializedData) => {
    const workflowModelToBeCloned = convertSerializedWorkflowDataToModel(serializedWorkflowInfo);
    const workflowDependenciesRef: WorkflowDependenciesRef = {};

    (workflowModelToBeCloned.getNodes() as DsNodeModel[]).forEach((node) => {
        const componentId = node.getID();
        node.extras.formData.forEach(async (field: BaseFieldType, index: number) => {
            const workflowId = field.value;
            if(field.key.includes('workflow_id') || field.type === 'workflow' && workflowId) {
                let workflowDependencies = get(workflowDependenciesRef, workflowId);
                if(!workflowDependencies) {
                    workflowDependencies =  {}
                    set(workflowDependenciesRef, workflowId, workflowDependencies);
                }
                let componentIndices = get(workflowDependencies, componentId);
                if(!componentIndices) {
                    componentIndices = []
                    set(workflowDependenciesRef[workflowId], componentId, componentIndices);
                }
                componentIndices.push(index);
            }
        });
    })

    return workflowDependenciesRef
    
}

export const cloneWorkflowInfo = ({ 
    serializedWorkflowInfo, 
    env, 
    workflowUserInfo, 
    workflowConfig, 
}: CloneWorkflowInfoArgType): Promise<ClonedWorkflowInfo> => {
    // use serialized string for cloning the workflow - accessing the workflowcanvas is causing issues 
    const workflowModelToBeCloned = convertSerializedWorkflowDataToModel(serializedWorkflowInfo);

    return new Promise((resolve) => {
        // without settimeout, links are hanging loose
        setTimeout(() => {
            const workflowInfo: ClonedWorkflowInfo = { details: {} as WorkflowSerializedObjData, payload: { workflow_config: {}, nodes: [], links: []} };
            const model = new DiagramModel();
            model.setZoomLevel(workflowModelToBeCloned.getZoomLevel());
            model.setOffset(workflowModelToBeCloned.getOffsetX(), workflowModelToBeCloned.getOffsetY());

            const itemMap = {};
            const workflowDependenciesRef: WorkflowDependenciesRef = {}
            
            workflowModelToBeCloned.getModels().forEach(async(item) => {
                const newItem = item.clone(itemMap);
                if(newItem instanceof DsNodeModel) {
                    // if(clearReadWriteComponentsFields)
                    //     clearFormValuesAndExtraDataOfReadAndWrite(newItem);
                    model.addNode(newItem);
                } else if (newItem instanceof DsLinkModel) {
                    model.addLink(newItem);
                }
            });
            workflowInfo.details = { ...model.serialize(), workflowMetaData: { env, config: workflowConfig, workflowDependenciesRef, workflowsInfo: {}, workflowName: workflowUserInfo.workflowName } }
            let _workflowConfig: WorkflowConfig = [];
            try {
                _workflowConfig = JSON.parse(workflowConfig) || []
            } catch {/*eslint-disable */}
            workflowInfo.payload = convertWorkflowDataForExecution(model, false, env, workflowUserInfo, _workflowConfig);
            resolve(workflowInfo);
        }, 1);
    });
};


export function encryptAndExport(payload: any, _fileName: string, type: string, extension: string = 'dco'): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            const exportPayload = lzString.compressToUTF16(payload);
            const blob = new Blob([exportPayload], {
                type: 'text/plain;charset=utf-8'
            });
            const filename = `${
                _fileName
            }-${Date.now()}.${extension}`;
            FileSaver.saveAs(blob, filename);
            resolve()
        } catch (error) {
            // console.log(error)
            errorAlert('Could not export ' + type);
            reject();
        }
    })
}

export const checkIfAllLinksAreConnected = (): boolean =>  {
    if(!WorkflowCanvas.areAllLinksConnected()){
        errorAlert('Code generation not possible. Found hanging links');
        return false;
    } return true;
};

export const runNumberOfPortsExpression = (values: any, expression: string): number => {
    // values are required for executing the expression
    const output = eval('(function() {' + expression + '}())');
    return output;
};

export type PortsCount = { inputPorts: number; outputPorts: number; addVariablePort: boolean; removeVariablePort: boolean };

export const updatePortCountOfComponent = (componentId: string, portsCount: PortsCount) => {
    // Updates Ports if needed
    const activeNode = WorkflowCanvas.getNode(componentId) as DsNodeModel;
    const inPorts = cloneDeep(activeNode.getInPorts());
    const outPorts = cloneDeep(activeNode.getOutPorts());
    let activeNodeInPortsCountWithoutVar = inPorts.length;
    const activeNodeOutPortsLength = outPorts.length;


    if(activeNode.hasVariableInputPort) {
        // POSSIBLE SCENARIOS
        // 1. Toggle Variable Input Port to true - VIP should be added
        // 2. Increase port count when VIP is true 
        // 3. Decrease port count when VIP is true 
        // 4. Toggle Variable Input Port to false- only VIP should be removed
        // 5. Increase ports count and set VIP to true 
        // 6. Increase ports count and set VIP to false
        activeNodeInPortsCountWithoutVar -= 1;
    }

    let deletedLinksArray: DeletedLinksInfo[] = [];
    let arePortsAddedOrRemoved = false;

    if (has(portsCount, 'outputPorts')) {
        const numberOfPortsRequired = portsCount.outputPorts;
        if (activeNodeOutPortsLength < numberOfPortsRequired) {
            // this adds ports 
            let i = activeNodeOutPortsLength;
            for (i; i < numberOfPortsRequired; i++) {
                activeNode.addOutPort(i);
                if(!arePortsAddedOrRemoved) arePortsAddedOrRemoved = true;
            }
        } else if (activeNodeOutPortsLength > numberOfPortsRequired) {
            // this removes ports 
            const numberOfPortsToBeRemoved = activeNodeOutPortsLength - numberOfPortsRequired;
            // ports added after are removed
            reverse(outPorts).forEach((port: DsPortModel, index) => {
                if (index + 1 <= numberOfPortsToBeRemoved) {
                    const __deletedLinksOfthePort = activeNode.removePort(port);
                    deletedLinksArray = deletedLinksArray.concat(__deletedLinksOfthePort);
                    if(!arePortsAddedOrRemoved) arePortsAddedOrRemoved = true;
                }
            });
        }
    }
    if (has(portsCount, 'inputPorts')) {
        let numberOfPortsRequired = portsCount.inputPorts;
        if (activeNodeInPortsCountWithoutVar < numberOfPortsRequired) {
            let i = activeNodeInPortsCountWithoutVar;
            if(activeNode.hasVariableInputPort) {
                // this is to offset the index.
                // for example, there's one input port and variable input port
                // Initially, i = 1; numberOfPortsRequired = 2, so port would be added at index 1 
                // But Variable port is at index 1, hence 1 is added to offset the index
                // After adding, i = 2; numberOfPortsRequired = 3  new port will be added at index 2
                i += 1;
                numberOfPortsRequired += 1;
            }
            for (i; i < numberOfPortsRequired; i++) {
                activeNode.addInPort(i);
                if(!arePortsAddedOrRemoved) arePortsAddedOrRemoved = true;
            }
        } else if (activeNodeInPortsCountWithoutVar > numberOfPortsRequired) {
            const numberOfPortsToBeRemoved = activeNodeInPortsCountWithoutVar - numberOfPortsRequired;
            const inPortsWithoutVariablePort = inPorts.filter(port => !port.getOptions().isVariablePort);
            // ports added after are removed
            reverse(inPortsWithoutVariablePort).forEach((port: DsPortModel, index) => {
                if ((index + 1 <= numberOfPortsToBeRemoved)) {
                    const __deletedLinksOfthePort = activeNode.removePort(port);
                    deletedLinksArray = deletedLinksArray.concat(__deletedLinksOfthePort);
                    if(!arePortsAddedOrRemoved) arePortsAddedOrRemoved = true;
                }
            });
        }
    }

    if(portsCount.addVariablePort) {
        // VARIABLEPORT IS ADDED ON INPUT SIDE
        activeNode.addInPort(activeNode.getInPorts().length, true);
    } else if(portsCount.removeVariablePort) {
        const varPort = inPorts.find(port => port.getOptions().isVariablePort);
        if(varPort) {
            activeNode.removePort(varPort);
        }
    }

    const deletedLinksInfo: Record<string, Record<string, string>> = {};
    deletedLinksArray.forEach(linkInfo => {
        if(!has(deletedLinksInfo, linkInfo.nodeId)) {
            set(deletedLinksInfo, linkInfo.nodeId, {});
        } 
        set(deletedLinksInfo, linkInfo.nodeId + '.' + linkInfo.linkId, linkInfo.portId);
    });

    WorkflowCanvas.engine.repaintCanvas(true)
        .then(() => {
            if(!isEmpty(deletedLinksInfo)) {
                WorkflowCanvas.model.getNodes().forEach(node => {
                    const currentNodeLinkInfo = deletedLinksInfo[node.getID()];
                    if(currentNodeLinkInfo) {
                        Object.entries((currentNodeLinkInfo)).forEach(([linkId, portId]) => {
                            const selectedPort = node.getPortFromID(portId);
                            if(selectedPort)
                                delete selectedPort.links[linkId];
                        });                    
                    }
                });
            }

            
            if(arePortsAddedOrRemoved) {
            // this is done to refresh the port positions on increasing/removing the ports 
                setTimeout(() => {
                    Object.values(activeNode.getPorts()).forEach(port => {
                        port.setPosition(port.getPosition().x, port.getPosition().y - 10);
                    });    
                    WorkflowCanvas.repaintCanvas();
                }, 200);
            }
        });
};

export const checkIfVariableComponentsExist = (_WorkflowCanvas = WorkflowCanvas) => (
    Object.values(WorkflowCanvas.getAllNodes()).some(node => node.getOptions().nodeType === 'variable')
);

export const getAllVariableNames = (model: DiagramModel<DiagramModelGenerics>): Record<string, true> => {
    const allVariableNames: Record<string, true> = {}; 
    Object.values(model.getActiveNodeLayer().getNodes())
        .filter((node) => (node as DsNodeModel).getOptions().nodeType === 'variable')
        .map((node) => (node as DsNodeModel).getOptions().title)
        .forEach((varTitle) => {
            set(allVariableNames, varTitle.toLowerCase(), true);
        });
    return allVariableNames;
};

export const updateWorkflowVariablesInfo = (): AppThunk => (dispatch, getState) => {
    setTimeout(() => {
        const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
        const activeWorkflowInfo = openTabs.get(activeTab.id)?.info
        const workflowHasVariables = checkIfVariableComponentsExist();
        if(activeWorkflowInfo && activeWorkflowInfo.hasVariables !== workflowHasVariables) {
            dispatch(updateAWorkflowEditorTabInfo({ type: getTabType('workflowEditor', activeWorkflowInfo.env), info: { ...activeWorkflowInfo, hasVariables: workflowHasVariables } }));
            if(!workflowHasVariables) {
                // Remove variable port of all components if there're no variables in the workflow
                Object.values(WorkflowCanvas.getAllNodes()).forEach(node => {
                    if(node.hasVariableInputPort) {
                        const variableInputPort = node.getVariableInputPort();
                        if(variableInputPort) {
                            node.removePort(variableInputPort)
                        }
                    }
                });
                WorkflowCanvas.repaintCanvas();
            }
        }
    }, 1)
    
};

export const convertTextToSlugForDocHeadings = (text: string) =>
    text.toLowerCase().replace(/\W/g, '-');

export const parseDocDataForHeadings = (docString: string) => {
    const headingsList = docString.match(/#{2} (.*)/g);
    return (headingsList || [])?.map(h => {
        // FORMAT: # HEADING $$ TITLE - USED IN Subsection TOPICS
        // # General Overview of DataStudio $$ General Overview
        const actualTitleMatch = h.match(/\$\$ (.*)/g);
        let headingLabel = '';                
        // actualTitleMatch = $$ General Overview
        if(actualTitleMatch) {
            headingLabel = trim(actualTitleMatch[0].replaceAll('$$', ''));
            // headingLabel = General Overview
        }
        const slugRef = h.replaceAll(/ \$\$ (.*)/g, '').split('# ')[1];
        // slugRef = General Overview of DataStudio
        const slug = convertTextToSlugForDocHeadings(slugRef);
        // slug = general-overview-of-datastudio
        return ({ label: headingLabel, value: slug });
    });
};

export const getHelpDocImageUrl = (docTitle: string, imgUrl: string) => helpImagesUrl + '/' + docTitle + '/' + imgUrl;
// http://10.0.9.145:7701/help/Demo Workflows Help/PI%20Data%20Ingestion%20workflows.001.png

/** 
 * Parses help document using Regex to match images (![](PI%20Data%20Ingestion%20workflows.001.png))
 * if subHeading is given, it returns the number of images till the subHeading. 
 * This is useful when a help doc has 25 images and the images above heading are 10.
 * Document can be shown if 10 images (all images above the heading) have loaded  
*/
export const getImagesCountInHelpDoc = (docString: string, subHeading?: string) => {
    const _i: {totalImages: number; imagesTillSubHeading: number | null} = { totalImages: 0, imagesTillSubHeading: null };
    // Regex for matching images -  ![](PI%20Data%20Ingestion%20workflows.001.png)
    const imageMatchingRegex = /!\[(.*?)\]\((.*?)\)/g;
    _i.totalImages = docString.match(imageMatchingRegex)?.length || 0;
    if(subHeading) {
        const _strTillSubheading = docString.split(subHeading)[0];
        _i.imagesTillSubHeading = _strTillSubheading.match(imageMatchingRegex)?.length || 0;
    }
    return _i;
};


export const filterTabsFuncForWorkflowEditorVisualizations = (page: PageTypes, activeExecutionEnv: ExecutionEnvModes) => (tab: TabTypes) => 
    tab.type === getTabType(page, activeExecutionEnv);
export const getCronExpression = ({ mins = '*', hrs = '*', day = '*', weeknumber = '*' }: CronSearchQuery) => {
    let cronExpression = `0 ${mins} ${hrs} ? * ${day} *`;
    if (weeknumber !== '*') cronExpression = `0 ${mins} ${hrs} ? * ${day}#${weeknumber} *`;
    return cronExpression;
};


export const getCronExpressionFromTime = ({ mins, hrs, day, weekOfTheMonth, scheduleType = 'daily' }: Required<Omit<CronSearchQuery, 'weeknumber'>> & { scheduleType: string }): string => {
    // Retrieves estimated time on time change
    const searchQuery: CronSearchQuery = { mins };
    switch (scheduleType.toLowerCase()) {
        // Since monthly also needs weekday and hour, break statement is not used
        // Since Weekly also needs  hour, break statement is not used
        // @ts-ignore
        case 'monthly':
            searchQuery.weeknumber = weekOfTheMonth;
        // @ts-ignore
        case 'weekly':
            searchQuery.day = day;
        case 'daily':
            searchQuery.hrs = hrs;
    }
    return getCronExpression(searchQuery);
};


const splitExp = (expString: string) => expString.split(" ");
const joinExp = (expArr: string[]) => expArr.join(" ");

const getOrdinalSuffix = (number: number) => moment.localeData().ordinal(number) 
const getPluralSuffix = (number: number) => number > 1 ? 's':''
const getTimeString = (hrs: number, mins: number) => moment(hrs+":"+mins, "HH:mm").format('hh:mm A') 

export const getActualCronExpressionFromTime = ({ mins, hrs, days, weekDays, months, scheduleType }: Required<Omit<CronSearchQuery, 'day' | 'weeknumber' | 'weekOfTheMonth'>> & { months: string; days: string | number; scheduleType: ScheduleTypes; weekDays: string }) => {
    // Retrieves estimated time on time change
    mins = parseInt(mins as string);
    hrs = parseInt(hrs as string);
    days = parseInt(days as string)
    // mins = parseInt(mins as string);
    months = parseInt(months as string) as any;

    let scheduleString = '' 
    let cronExpression = ''

    switch (scheduleType) {
        case ScheduleTypes.Minute:
            scheduleString = `Runs every ${mins} minutes`
            cronExpression = cronTime.every(mins).minutes()
            break;
        case ScheduleTypes.Hour:
            scheduleString = `Runs every ${getOrdinalSuffix(mins)} minute of the ${getOrdinalSuffix(hrs)} hour`
            cronExpression = cronTime.every(hrs).hours().replace("0", mins.toString())
            break;
        case ScheduleTypes.Day: {
            // const timeString = moment(hrs+":"+mins, "HH:mm").format('h:m A') 
            scheduleString = `Runs at ${getTimeString(hrs,mins)}  every ${days} day${getPluralSuffix(days)}`

            const exp = cronTime.every(days).days()
            const expArr = splitExp(exp)
            // @ts-ignore
            expArr[0] = mins
            // @ts-ignore
            expArr[1] = hrs
            cronExpression = joinExp(expArr)
            break;
        }     
        case ScheduleTypes.Week: {
            scheduleString = `Runs at ${getTimeString(hrs,mins)} `

            let exp = cronTime.everyWeekDayAt(hrs, mins)
            if(!!weekDays) {
                const weekDaysArr = weekDays.split(",").map(day => parseInt(day)).sort();

                const selectedWeekdays = weekDaysArr.map(weekDay => (
                    weekDaysRef[weekDay.toString() as keyof typeof weekDaysRef]
                ))

                scheduleString += `every ${selectedWeekdays.join(', ')}`
                

                const args = splitExp(exp)
                args[4] = weekDays as string  
                exp = joinExp(args)
            } else {
                scheduleString += 'everyday'
            }
            cronExpression = exp
            break;
        }
        case ScheduleTypes.Month: {
            const exp = cronTime.everyMonthOn(days, hrs, mins)

            const expArr = splitExp(exp)

            expArr[3] = `*/${months}`
            
            scheduleString = `Runs at ${getTimeString(hrs,mins)} on ${getOrdinalSuffix(days)} every ${months} month${(months as any > 1) ? 's': ''}`
            cronExpression = joinExp(expArr)

        }
    }

    return { cronExpression, scheduleString }
};


export const getEstimatedDatesAndPrevExecutionDateFromCronExpression = (cronExpression: string) => {
    const res: { datesList: string[]; prevExecutionDate: moment.Moment } = { datesList: [], prevExecutionDate: moment()}
    try {
        const interval = parser.parseExpression(cronExpression, { currentDate: new Date(), iterator: true, tz: 'UTC' });
        res.prevExecutionDate = moment(interval.prev().value.toString()).utc()
        const datesList = range(1, 11).map(() => {
            try {
                const obj = interval.next();
                return obj.value.toString()
            } catch {
                return ''
            }
        })
        res.datesList = datesList;
        return res
    } catch {
        return res
    }
}



export const setComponentPropertiesState = (activeNode: DsNodeModel, errors: Object, workflowCanvas = WorkflowCanvas) => {
    const isPropsValid = activeNode.getOptions().isPropertiesValid;
    if(isEmpty(errors)){
        if(!isPropsValid){
            activeNode.setIsPropertiesValid(true);
            workflowCanvas.repaintCanvas();
        } 
    } else if(isPropsValid) {
        activeNode.setIsPropertiesValid(false);
        workflowCanvas.repaintCanvas();
    }
}

// type getWorkflowInfo = (page: PageTypes) => (() => workflowRunData | boolean)
export const getWorkflowInfo = (page: PageTypes, dispatch: AppDispatch) => {
    // return dispatch({ type: 'RESET_ACTIVE_TAB', page: 'analytics' })
    if (checkIfAllLinksAreConnected()) {
        return dispatch((dispatch, getState) => {
            const { CanvasReducer, AccountReducer, CommonReducer } = getState()
            const { activeTab, openTabs } = CanvasReducer[page];
            const activeTabInfo = openTabs.get(activeTab.id)
            const workflowData = convertWorkflowDataForExecution(WorkflowCanvas.model, false, CommonReducer.activeExecutionEnv,{ workflowName: activeTab.name, userName:AccountReducer.activeUserInfo.name }, activeTabInfo?.info?.config);
            workflowData.nodes =  dispatch(addRunTillHereIdIfExists(workflowData.nodes) as any);
            
            return workflowData;
        })
    } return false;
};

const executionTimeFrequencyMultiplier: Record<ExecutionTimeoutFrequency, number> = {
    'secs': 1,
    'mins': 60,
    'hours': 3600
}

export const getDagWorkflowInfo = (dispatch: AppDispatch, addWorkflowInfo = true) => {
    return dispatch((dispatch, getState) => {
        let data: DagPostWorkflowInfo | undefined;
        const Env = getState().AccountReducer.envVariables
        const { openTabs, activeTab } = getState().CanvasReducer.workflowEditor
        const activeTabInfo = openTabs.get(activeTab.id)?.info as WorkflowCanvasTabInfo;
        let execution_timeout: null | number = null
        if(activeTabInfo?.scheduleInfoForDag) {
            if(activeTabInfo.scheduleInfoForDag.scheduleAdvancedMode && activeTabInfo.scheduleInfoForDag.execution_timeout !== null) {
                execution_timeout = parseInt(activeTabInfo.scheduleInfoForDag.execution_timeout as unknown as string) * executionTimeFrequencyMultiplier[activeTabInfo.scheduleInfoForDag.execution_timeout_frequency]
            }

            data = {
                dag_id: activeTabInfo.scheduleInfoForDag.dag_id,
                description: '',
                schedule_interval: activeTabInfo.scheduleInfoForDag.scheduleTimingInfo === ScheduleTiming.None 
                    ? 'None' 
                    : (activeTabInfo.scheduleInfoForDag.scheduleTimingInfo === ScheduleTiming.Recurring 
                        ? activeTabInfo.scheduleInfoForDag.cronExpression
                        : "@once"),
                start_date: activeTabInfo.scheduleInfoForDag.scheduleTimingInfo === ScheduleTiming.Recurring ? activeTabInfo.scheduleInfoForDag.startDate: activeTabInfo.scheduleInfoForDag.scheduleTimingInfo === ScheduleTiming['Run Once'] ? activeTabInfo.scheduleInfoForDag.formattedRunOnceExecutionDate: formatScheduleStartDate(moment().utc()) ,
                isDatabricks: Env?.REACT_APP_DATABRICKS,
                scheduleType: activeTabInfo.scheduleInfoForDag.scheduleType,
                default_args: {
                    owner: 'Airflow',
                    depends_on_past: false,
                    email: activeTabInfo.scheduleInfoForDag.overrideNotificationEmail ? [activeTabInfo.scheduleInfoForDag.notificationEmails]: [],
                    email_on_failure: activeTabInfo.scheduleInfoForDag.overrideNotificationEmail,
                    email_on_retry: false,
                    retries: activeTabInfo.scheduleInfoForDag.scheduleAdvancedMode ? parseInt(activeTabInfo.scheduleInfoForDag.retries as unknown as string): 0,
                    retry_delay: activeTabInfo.scheduleInfoForDag.scheduleAdvancedMode ? parseInt(activeTabInfo.scheduleInfoForDag.retryDelay as unknown as string): 0,
                    execution_timeout
                },
                nodes: [],
                links: [],
                workflow_config: {}
            };
            if(addWorkflowInfo) {
                const workflowData = getWorkflowInfo('workflowEditor', dispatch);
                if(workflowData) {
                    data.nodes = workflowData.nodes;
                    data.links = workflowData.links;
                    data.workflow_config = workflowData.workflow_config
                }
            }
        }
        return data;
    })
};

export const getNumPortsBaseField = (type: 'input' | 'output', count: number) => ({
    key: type === 'output' ? 'num_op_ports': 'num_ip_ports',
    type: 'input',
    value: count,
    defaultValue: 0,
    templateOptions: { label: '', options: [] },
    hideExpression: 'true'
} as BaseFieldType)

export const _canvasdagFormInitialValues = {
    scheduleType: "Minute",
    weekDays: "",
    months: 1,
    days: 1,
    hrs: 1,
    mins: 15,
    cronExpression: "*/5 * * * *",
    weekOfTheMonth: 1,
    startDate: formatScheduleStartDate(
		getEstimatedDatesAndPrevExecutionDateFromCronExpression("*/5 * * * *")
			.prevExecutionDate
	),
    // scheduleString: "Runs every 15 minutes",
    scheduleTimingInfo: ScheduleTiming.Recurring,
    jobName: "",
    version: 1,
    retries: 0,
    retryDelay: 300,
    scheduleAdvancedMode: false,
    dependencies: "",
    overrideNotificationEmail: false,
    notificationEmails: "",
    notificationType: "Failure",
    workflowInfo: null,
    sparkJAR: "",
    duration: "00:00",
    runOnceExecutionDate: moment(),
    formattedRunOnceExecutionDate: formatScheduleStartDate(moment().utc()),
    execution_timeout: null,
    dag_id: uuid(),
    max_active_runs:"16",
	catchup:false,
    execution_timeout_frequency: ExecutionTimeoutFrequency.Seconds,
    cluster:"",
    exponential_backoff:false
};