import { HttpClient } from '../services';
import { CronExpressionResponse } from './types';
// import { API } from '../../constants/settings';
import queryString from 'query-string';

class CronExpressionHandlerClass {
    GetCron(link: string, expression: string, callback: (arg0: CronExpressionResponse) => void, errorHandlerCallback?: (arg0: any) => void) {
        const stringifiedCronExpression = queryString.stringify({ cronExpression: expression });
        HttpClient.Get('Get Cron', `${link}/v1/cron?${stringifiedCronExpression}`, callback, errorHandlerCallback);
    }
    
}

const CronExpressionHandler = new CronExpressionHandlerClass();

export { CronExpressionHandler };