import {
    WorkflowReducerState, ActionTypes, RETRIEVE_WORKFLOWS, TOGGLE_COMPONENTS_TREEVIEW,
    CHANGE_WORKFLOWS_SELECTION, SET_ACTIVE_SELECTION_TYPE, TOGGLE_MODAL, SELECT_SINGLE_WORKFLOW, SHOW_PROPERTIES,
    HIDE_PROPERTIES,
    SET_ACTIVE_TAB_IN_WORKFLOW_PAGE,
    LIST_JAR_FILES,
    SET_CUSTOMCOMPONENTNAME,
    SET_ACTIVE_PROFILE,
    SET_COMPONENT_ID_FOR_RUN_TILL_SHOW,
    SET_ACTIVE_COMPONENT_INFO,
    SET_ACTIVE_COMPONENT_PROPERTIES_STATE,
    SET_ADDITIONAL_FORM_DATA,
    SET_COPIED_COMPONENT_INFO,
    SET_WORKFLOW_PARAMS,
    SET_CAPTURE_SCHEMA_LOGS,
    SET_COMPONENTS_PARAMETERS,
    SET_CALLBACK_FUNCTION,
    SET_ACTIVE_CLUSTER,
    REFRESH_COMPONENT_FORM_DATA, SET_MODAL_TYPE_IN_WORKFLOWS_PAGE, SET_VARIABLE_COMPONENT_INFO, TOGGLE_CONSOLE_HEIGHT, SET_CUSTOM_COMPONENTS_LIST, SET_COMPONENT_FN_NAMES_INFO,
    SET_CUSTOM_COMPONENT_FN_NAMES_COUNT, SET_ACTIVE_UPDATECOMPONENTPARAMETER,SET_CUSTOMCOMPOENENTUPDATEID,SET_ACTIVE_UPDATE_MODALTYPE, TOGGLE_WORKFLOW_CANVAS_SPINNER, SET_WORKFLOW_TAB_SELECTION, SET_JOBS_LIST, SET_COMPONENT_TREE_DATA, SHOW_COMPONENTS_TREEVIEW,
    FETCH_GITREPOSITORIES_REQUEST,
    FETCH_GITREPOSITORIES_SUCCESS,
    FETCH_GITREPOSITORIES_FAILURE,
    SET_SELECTED_EXPERIMENT
} from './types';
import { CanvasTabs, getInitialCustombuilderValues, InnerWorkflowTabs } from '../../pages/workflow-page/enums';
import { LOAD_INITIAL_STATE, RESET_STATE } from '../types';
import { cloneDeep } from 'lodash';

export const workflowReducerInitialState: WorkflowReducerState = {
    additionalValuesForForm: {},
    workflows: {
        totalCount: 0,
        results: []
    },
    workflowTabSelection: {
        selectedDirectory: null,
        activeInnerTab: InnerWorkflowTabs.WORKFLOWS,
        workflowsPageNum:  1,
        starredPageNum: 1,
        sharredPageNum: 1,
        activeSortState: {
            directories: 'updated',
            workflows: 'updated'
        }
    },
    isComponentTreeviewMinimized: false,
    isPropertiesMinimized: false,
    updateComponentParameter: false,
    customComponentUpdateId:0,
    customComponentModelType: 'new',
    customComponentModelTypeUpdate:false,
    workflowsSelectedState: {},
    selectionType: 'single',
    editorCode: 'String',
    customComponentName: {
        name: '',
    },
    componentParamenters : getInitialCustombuilderValues(),
    showModal: {
        newWorkflow: false,
        runWorkflow: false,
        renameWorkflow: false,
        deleteWorkflow: false,
        scheduleWorkflow: false,
        loseUnsavedChanges: false,
        managePorts: false,
        newComponent: false,
        customEditor: false,
        customComoponentParameters: false,
        profile: false,
        loseUnsavedFormChanges: false,
        loseUnsavedComponentNotesChanges: false,
        renameComponent: false,
        componentImport: false,
        deleteComponent: false,
        fileUploadDbfs: false,
        createFolderDbfs: false,
        userPermission: false,
        fileUploadAzureBlobStorage: false,
        shareWorkflow: false,
        mlBuildPipelineModal: false,
        streamingPreviewWorkflow: false
    },
    singleItemInfoForModals: {
        id: 0,
        name: '',
        version: 0,
    },
    listjarfiles: [],
    activeTab: CanvasTabs.WORKFLOWS,
    activeProfile: null,
    activeCluster: null,
    idOfComponentForRunTillHere: '',
    activeComponentInfo: null,
    activeComponentPropertiesInfo: { isSaved: true, show: false, isValid: false, isNotesSaved: true },
    componentToBeCopied: null,
    workflowsParams: { page: 1, workflowSearch: '' },
    captureSchemaLogs: [],
    componentToBeRefreshed: null,
    modalType: 'workflow',
    variableComponent: null,
    toggleConsoleHeight: false,
    customComponentsList: [],
    componentFnNamesInfo: {},
    customComponentFnNamesCount: 1,
    showWorkflowCanvasSpinner: false,
    jobsList: [],
    componentTreeData: {
        data: [],
        loading: true
    },
    isGitLoading: false,
    gitRepositories: [],
    errorGitRepo: '',
    selectedExp: {
        label: '',
        value: {
            name: '',
            id: ''
        },
        artifactType: ''
    }
};


// eslint-disable-next-line complexity
export const WorkflowReducer = (state = workflowReducerInitialState, action: ActionTypes): WorkflowReducerState => {
    switch (action.type) {
        case RETRIEVE_WORKFLOWS:
            return { ...state, workflows: action.payload };
        case SET_WORKFLOW_TAB_SELECTION:
            return { ...state, workflowTabSelection: { ...state.workflowTabSelection, ...action.payload } };
        case SHOW_COMPONENTS_TREEVIEW:
            return { ...state, isComponentTreeviewMinimized: false };
        case TOGGLE_COMPONENTS_TREEVIEW:
            return { ...state, isComponentTreeviewMinimized: !state.isComponentTreeviewMinimized };
        case SHOW_PROPERTIES:
            return { ...state, isPropertiesMinimized: false };
        case HIDE_PROPERTIES:
            return { ...state, isPropertiesMinimized: true };
        case CHANGE_WORKFLOWS_SELECTION:
            return { ...state, workflowsSelectedState: action.payload };
        case SET_ACTIVE_SELECTION_TYPE:
            return { ...state, selectionType: action.payload };
        case SET_ACTIVE_UPDATECOMPONENTPARAMETER:
            return {...state,updateComponentParameter:action.payload};
        case SET_ACTIVE_UPDATE_MODALTYPE:
            return {...state,customComponentModelTypeUpdate:action.payload};
        case TOGGLE_MODAL:
            return { ...state, showModal: { ...state.showModal, ...action.payload } };
        case SELECT_SINGLE_WORKFLOW:
            return { ...state, singleItemInfoForModals: action.payload };
        case LIST_JAR_FILES:
            return { ...state, listjarfiles: action.payload };
        case SET_ACTIVE_TAB_IN_WORKFLOW_PAGE:
            return { ...state, activeTab: action.payload };
        case SET_ACTIVE_PROFILE:
            return { ...state, activeProfile: action.payload };
        case SET_CUSTOMCOMPONENTNAME:
            return { ...state, customComponentName: action.payload };
        case SET_COMPONENT_ID_FOR_RUN_TILL_SHOW:
            return { ...state, idOfComponentForRunTillHere: action.payload };
        case REFRESH_COMPONENT_FORM_DATA:
            return { ...state, componentToBeRefreshed: action.payload };
        case SET_ACTIVE_COMPONENT_INFO:
            return { ...state, activeComponentInfo: action.payload };
        case SET_ACTIVE_COMPONENT_PROPERTIES_STATE:
            return { ...state, activeComponentPropertiesInfo: { ...state.activeComponentPropertiesInfo, ...action.payload } };
        case SET_ADDITIONAL_FORM_DATA:
            return { ...state, additionalValuesForForm: action.payload };
        case SET_COPIED_COMPONENT_INFO:
            return { ...state, componentToBeCopied: action.payload };
        case SET_WORKFLOW_PARAMS:
            return { ...state, workflowsParams: action.payload };
        case SET_CAPTURE_SCHEMA_LOGS:
            return { ...state, captureSchemaLogs: action.payload };
        case SET_COMPONENTS_PARAMETERS:
            return { ...state, componentParamenters: action.payload };
        case SET_CALLBACK_FUNCTION:
            return { ...state, callbackFunc: action.payload };
        case SET_ACTIVE_CLUSTER:
            return { ...state, activeCluster: action.payload };
        case SET_MODAL_TYPE_IN_WORKFLOWS_PAGE:
            return { ...state, modalType: action.payload };
        case SET_VARIABLE_COMPONENT_INFO:
            return { ...state, variableComponent: action.payload };
        case TOGGLE_CONSOLE_HEIGHT:
            return { ...state, toggleConsoleHeight: !state.toggleConsoleHeight };
        case SET_CUSTOM_COMPONENTS_LIST:
            return { ...state, customComponentsList: action.payload };
        case SET_COMPONENT_FN_NAMES_INFO:
            return { ...state, componentFnNamesInfo: action.payload };
        case SET_CUSTOM_COMPONENT_FN_NAMES_COUNT:
            return { ...state, customComponentFnNamesCount: action.payload };
        case RESET_STATE:
            return cloneDeep(workflowReducerInitialState);
        case SET_CUSTOMCOMPOENENTUPDATEID: 
            return {...state,customComponentUpdateId : action.payload};
        case TOGGLE_WORKFLOW_CANVAS_SPINNER:
            return {...state, showWorkflowCanvasSpinner: action.payload };
        case SET_JOBS_LIST:
            return {...state, jobsList: action.payload };
        case LOAD_INITIAL_STATE:
            return { ...state, ...action.payload.WorkflowReducer };
        case SET_COMPONENT_TREE_DATA:
            return { ...state, componentTreeData: action.payload };
            case FETCH_GITREPOSITORIES_REQUEST:
                return { ...state, isGitLoading: true, errorGitRepo: '' };
            case FETCH_GITREPOSITORIES_SUCCESS:
                return { ...state, isGitLoading: false, gitRepositories: action.payload };
            case FETCH_GITREPOSITORIES_FAILURE:
                return { ...state, isGitLoading: false, errorGitRepo: action.payload };
            case SET_SELECTED_EXPERIMENT:
                return {...state, selectedExp: action.payload}
        default:
            return state;
    }
};