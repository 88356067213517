import React, { useEffect, useReducer, useRef, useState } from 'react';
import clsx from 'classnames';
import { Progress } from 'antd';
import { useToggle } from "rooks";
import { ArrowRight, CaretDown } from './Icons';
import { RootState } from '@store/types';
import { useDispatch, useSelector } from 'react-redux';
import { GreenTickIcon } from '@pages/adminstrator_old/icons';
import { InitHandler } from '@api/init';
import { initCoachMarkUserSettings } from '@store/coachMark';
import { setActiveTab } from '@store/workflow';
import { CanvasTabs } from '@pages/workflow-page/enums';
import JoyRide, { ACTIONS, EVENTS, Step, Styles } from "react-joyride";
import { useHistory, useLocation } from "react-router-dom";
import styles from './styles.module.scss';
import { useAppSelector } from '@store/hooks';
import { START_CLUSTER_COUCHMARK, START_FILE_BROWSER_COUCHMARK } from '@store/coachMark/constants';
import { useMountedRef } from '../../hooks/mount';
import { ShowWhenTrue } from '@helpers/showwhentrue';
// import { Env } from '@constants/settings';

const INIT_TOUR_STEPS: Step[] = [
	{
		target: "#tab_Compute",
		content: "Click here to navigate to create/manage cluster",
		disableBeacon: true,
        placement: 'bottom'
	},
	{
		target: "#tab_File_Browser",
		content: "Click here to navigate to create/manage file browser ",
        placement: 'left',
		disableBeacon: true
	}
];

const INITIAL_STATE = {
	key: new Date(), // This field makes the tour to re-render when we restart the tour
	run: false,
	continuous: true, // Show next button
	loading: false,
	stepIndex: 0, // Make the component controlled
	steps: INIT_TOUR_STEPS,
	hideBackButton: true,
    disableOverlayClose: true,
    spotlightPadding: 5,
	//hideCloseButton: false
};

export const tourStyle: Styles = {
    options: {
        zIndex: 10000,
        arrowColor:  'rgba(20, 24, 42, 1)',
    },
    tooltip: {
        backgroundColor: 'rgba(20, 24, 42, 1)',
        color: 'white',
    },
    spotlight: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
    },
    buttonNext: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: 'black'
    },
    buttonClose: {
        color: 'white',
        paddingTop: '1rem'
    },
    tooltipContainer: {

    },
    buttonBack: {
        color: '#fcad01'
    },
};

export const CoachMark: React.FC<{reset?: any}> = () => {
	const dispatch1 = useDispatch();
    const mountedRef = useMountedRef();
    const location = useLocation();
    const isAdmin = useAppSelector((state) => state.AccountReducer.activeUserInfo.id ? state.AccountReducer.activeUserInfo.isAdmin : true);
    const { 
        cluster_cm_complete_index, 
        cluster_cm_flow_total,
        init_status,
        file_browser_status,
        cluster_status,
        show_progress_ui
    } = useSelector((store: RootState) => store.CoachMarkReducer);

    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);

    const reducer = (state = INITIAL_STATE, action: any) => {
		if(!isAdmin) return;
		switch (action.type) {
			// start the tour
			case "START":
				return { ...state, run: true };
			case "OPEN":
				return { ...state, run: true, stepIndex: action.value };
			// Reset to 0th step
			case "RESET":
				return { ...state, stepIndex: 0 };
			// Stop the tour
			case "STOP":
				return { ...state, run: false };
			// Update the steps for next / back button click
			case "NEXT_OR_PREV":
				return { ...state, ...action.payload };
			// Restart the tour - reset go to 1st step, restart create new tour
			case "RESTART":
				return {
					...state,
					stepIndex: 0,
					run: true,
					loading: false,
					key: new Date(),
				};
			default:
				return state;
		}
	};

    const history = useHistory();
    const [percentage, setPercentage] = useState(20);
    const [state, toggle] = useToggle(true);
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [shown, setShown] = useState(false);
    const [dataLoad, setDataLoad] = useState(true);
    const [showProgress, setProgressUI] = useState(false)
    const [showClose, setShowCloseUI] = useState(false)

    const refDiv = useRef<HTMLDivElement>();

    useEffect(() => {
        const runEffect = () => {
            if (!Env) {
                // Retry after 5 seconds if Env is not available
                setTimeout(runEffect, 5000);
                return;
            }

            setDataLoad(false);
            InitHandler.GetAdminInitStatus(`${Env.REACT_APP_PLATFORM_URL}/platform/api`, "init_setup", (resp) => {
                setDataLoad(true);
                if (!resp) {
                    InitHandler.SetStateForCounchMark(`${Env.REACT_APP_PLATFORM_URL}/platform/api`, {
                        data: "INIT",
                        name: "init_setup"
                    }, () => {
                        dispatch1(initCoachMarkUserSettings("INIT"));
                    });
                } else {
                    dispatch1(initCoachMarkUserSettings(resp.data));
                }
            });
        };

        runEffect();
    }, []); 

    useEffect(() => {
        if(dataLoad && !mountedRef.current) return;
        setProgressUI(true);
        setShowCloseUI(true);
        if(cluster_status === "COMPLETE" && file_browser_status === "COMPLETE") {
            setShown(true);
        }
    }, [show_progress_ui])

    useEffect(() => {
        if(!shown && isAdmin) {
            if(!['/filemanagement', '/cluster'].includes(location.pathname)) {
                createBlockerUI();
            } else {
                removeBlockerUI();
            }
        } else {
            setProgressUI(false);
        }
        if(!isAdmin || shown || dataLoad) return;
        if(cluster_status !== "COMPLETE" && file_browser_status !== "COMPLETE" ) {
            dispatch({ type: "RESTART" });
            setShown(false);
            
            setProgressUI(true);
        } else {
            removeBlockerUI();
            setProgressUI(false);
        }
    }, [location.pathname, isAdmin, cluster_status, file_browser_status]);

    useEffect(() => {
        let count =0;
        if(cluster_status === "COMPLETE")
            count+=50;
        if(file_browser_status === "COMPLETE")
            count+=50;
        
        if(count === 100) {
            setShown(true);
            setProgressUI(false);
            removeBlockerUI();
        }
        setPercentage(count);
    }, [cluster_status, file_browser_status])

    useEffect(() => {
        if(init_status === 'INIT') {
            dispatch1(setActiveTab(CanvasTabs.COMPONENTS));
        } 
        if(init_status === 'INIT' && !['/filemanagement', '/cluster'].includes(location.pathname)) {
            dispatch({ type: "START" });
            setProgressUI(true);
            createBlockerUI();
        }
        return () => {
            removeBlockerUI();
        }
    }, [init_status]);

    useEffect(() => {
        setPercentage(cluster_cm_complete_index/cluster_cm_flow_total * 100);
    }, [cluster_cm_complete_index, cluster_cm_flow_total]);

    const createBlockerUI = ()=>{
        if(!refDiv.current) {
            const div = document.createElement("div");
            div.classList.add(styles['blockview']);
            document.body.appendChild(div);
            refDiv.current = div;
        }
    }

    const removeBlockerUI =() => {
        if(refDiv.current) {
            refDiv.current.parentElement?.removeChild(refDiv.current);
            refDiv.current = undefined;
        }
    };

    const closeProgressUI = () => {
        setProgressUI(false);
    };

    const callback = (data: any) => {
		const { action, index, type } = data;
		if (action === ACTIONS.CLOSE ) {
            dispatch({ type: "STOP" });
		//} else if (type === EVENTS.TARGET_NOT_FOUND) {
		} else if (type === EVENTS.STEP_AFTER) {
            dispatch({
				type: "NEXT_OR_PREV",
				payload: {
					stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
				},
			});
		}
	};

    return (
       <>
            <ShowWhenTrue show={Env?.ENABLE_DATASTUDIO_WALKTHROUGH}>
                <JoyRide
                    {...tourState}
                    // Callback will pass all the actions
                    callback={callback}
                    // showSkipButton={true}
                    styles={tourStyle}
                    locale={{
                        last: 'Done'
                    }}
                />
            </ShowWhenTrue>
            { showProgress && isAdmin ? (
                    <div className={clsx(styles['model'], state?  null: styles['mini'])}>
                        <div className={clsx(styles['label'])}>
                            {
                                shown ? ("Product walk through") :  `Setup ${percentage}% complete`
                            }
                            <div className={clsx(styles['alignspace'])}>
                                <div onClick={toggle} className={clsx(state? null : styles['rotate'])}>
                                    <CaretDown />
                                </div>
                                { showClose ? (<button onClick={closeProgressUI} style={{height: '20px', width: '20px', marginTop: "-9px", marginLeft: 10}} >
                                    <img className="icon__hover close__icon__modal" src="/icons/close_tab.svg" style={{height: '16px', width: '16px'}} alt="close-modal" />
                                    </button> ) : null
                                }
                            </div>
                        </div>
                        {
                            shown? null : (<Progress percent={percentage} strokeColor="#FCAD01" showInfo={false} />)
                        }
                        <div className={clsx({
                            hide: !state
                        })}>
                            <div>
                                <div className={styles['rows']}>
                                    <div className={clsx(styles['number_label'])}>
                                        {
                                            cluster_status === 'COMPLETE' && !shown ? (
                                                <div className={clsx(styles['round'], styles['completed'])}>
                                                    <GreenTickIcon /> 
                                                </div>
                                            ) :  <div className={clsx(styles['round'])}>
                                            1
                                            </div>
                                        }
                                        
                                    </div>
                                    <div className={clsx(styles['content'])}>
                                        <label className={clsx(styles['text1'])}>Setup Clusters</label>
                                        <button className={clsx(styles['getStartedBtn'])} onClick={()=>{
                                            history.push('/cluster')
                                            setTimeout(()=>{
                                                dispatch1({type: START_CLUSTER_COUCHMARK})
                                            }, 1000)
                                        }}>
                                            <span>Get Started </span>
                                            <ArrowRight />
                                        </button>
                                    </div>
                                </div>
                                <div className={styles['rows']}>
                                    <div className={clsx(styles['number_label'])}>
                                    {
                                            file_browser_status === 'COMPLETE' && !shown ? (
                                                <div className={clsx(styles['round'], styles['completed'])}>
                                                    <GreenTickIcon /> 
                                                </div>
                                            ) :  <div className={clsx(styles['round'])}>
                                            2
                                            </div>
                                        }
                                    </div>
                                    <div className={clsx(styles['content'])}>
                                        <label className={clsx(styles['text1'])}>Setup File Browser</label>
                                        <button className={clsx(styles['getStartedBtn'])} onClick={()=>{
                                            history.push('/filemanagement')
                                            setTimeout(()=>{
                                                dispatch1({type: START_FILE_BROWSER_COUCHMARK})
                                            }, 1000)
                                        }}>
                                            <span>Get Started </span>
                                            <ArrowRight />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ): null
            }
       </>
    )
};
export const TooltipComponent : React.FC<{text: string, onClick: any}> = ({text, onClick}) => {
    return (
        <>
            <div>{text}</div>
            <div>
                <button onClick={onClick}>Continue</button>
            </div>
        </>
    )
};

export const HighlightToolTip: React.FC = () => {
    return (
        <div className={clsx(styles['tooltip'])}>

        </div>
    )
};
