import { Modal } from '../../../components/modals';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import React, { useMemo } from 'react';
import { toggleModal, setActiveProfile } from '../../../store/workflow';
import { ProfileModalTypes } from '../enums';
import { ProfileInfo, WorkflowHandler, CreateProfile } from '../../../api/workflow-handler';
import { RunWorkflowForm, getRunWorkflowFormInitialValues, runWorkflowValidationSchema } from './run-preview-workflow';
import _ from 'lodash';


interface Props{
    profileInfoForEdit: ProfileInfo;
    modalType: ProfileModalTypes;
    handleSuccess: any;
}


export const NewEditProfileModal: React.FC<Props> = ({ profileInfoForEdit, modalType, handleSuccess }) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.profile);
    const dispatch = useDispatch();
    const isModalEdit = modalType === ProfileModalTypes.edit;
    const activeProfile = useSelector((store: RootState) => store.WorkflowReducer.activeProfile);
    const { envVariables: Env } = useSelector((store:RootState)=> store.AccountReducer);
    const toggleClose = () => {
        dispatch(toggleModal('profile', false));
    };

    const handleCreateProfileSuccess = (updatedProfileInfo: CreateProfile) => {
        if(profileInfoForEdit.id === activeProfile?.id) dispatch(setActiveProfile({ ...activeProfile, ...updatedProfileInfo }));
        handleSuccess();
        toggleClose();
    };

    

    function handleRunFormSubmit(this: any, values: runWorkflowValidationSchema & {name: string}){
        // remove name parameter from the values 
        const runWorkflowInfo = JSON.stringify(_.omit(values, 'name'));
        const data: CreateProfile = { name: values.name, command: runWorkflowInfo};
        if(isModalEdit){
            WorkflowHandler.UpdateProfile(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, profileInfoForEdit.id, data, handleCreateProfileSuccess.bind(this, data));
        } else {
            WorkflowHandler.CreateProfile(`${Env?.REACT_APP_PLATFORM_URL}/platform/api`, data, handleCreateProfileSuccess);
        }
    }

    
    const initialValues = useMemo(() => {
        if(isModalEdit){
            const commandValues = JSON.parse(profileInfoForEdit.command);
            return { ...commandValues, name: profileInfoForEdit.name};
        } else{
            return getRunWorkflowFormInitialValues();
        }
    }, [modalType, profileInfoForEdit, showModal]);

    return(
        <Modal
            isOpen={showModal}
            title={isModalEdit ? 'Edit Profile': 'Add a New Profile'}
            subtitle={isModalEdit ? profileInfoForEdit.name: ''}
            toggleClose={toggleClose}
            className="profileModal__container"
            showCloseMark
        >   
            <RunWorkflowForm
                initialValues={initialValues}
                onSubmit={handleRunFormSubmit as (arg0: runWorkflowValidationSchema) => any}
                toggleClose={toggleClose}
                showSpinner={false}
                submitText="Save"
                profileTab
            />
        </Modal>
    );
};