import React, { useState, useRef, useEffect, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';
import { FieldValidator, useField } from 'formik';
import './styles.scss';
import { ShowWhenTrue } from '../../helpers';
import { useDidUpdate, usePrevious } from 'rooks'; 
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { omit, isEqual, has } from 'lodash';
import { TooltipTop } from '../tooltips';
import { useOutsideClick } from 'rooks5';
import { errorAlert } from '@components/toastify/notify-toast';

export type _selectoptionType = { value: any ; label: string; key?: string; [x: string]: any ; disabled?: any };

export interface SelectFieldProps {
    options: _selectoptionType[];
    initial_value?: _selectoptionType | _selectoptionType['value'];
    name: string;
    label?: React.ReactNode;
    className?: string;
    inputClass?: string;
    required?: boolean;
    placeholder?: string;
    autoSelectFirstOption?: boolean;
    hideInputField?: boolean;
    position?: 'top' | 'normal' | 'left' | 'right';
    reset_options?: boolean;
    onOptionClick?: (option: _selectoptionType, selectedOptions?: Record<string, any>, selected?: boolean) => void;
    onSelectAll?: (selectedOptions: Record<string, any>) => void;
    multiple_select?: boolean;
    disabled?: boolean;
    useSelectedOptionsFromProps?: boolean;
    selectedOptionsFromProps?: Record<string, any>;
    infoText?: string;
    onFilterTextChange?: (arg0: string) => any;
    useOptionSearch?: boolean;
    useDynamicWidth?: boolean;
    no_wrap_multiple_select?: boolean;
    max_items?: number;
    handleFilterInParent?: boolean;
    renderItemAtOptionsBottom?: () => JSX.Element; 
    children?: React.ReactNode;
    renderOptionLabel?: (option: _selectoptionType) => JSX.Element;
    selectAll?: boolean;
    validate ?: FieldValidator;
    theme ? : string;
}

// const CloseIcon = ({ onClick }: any) => (
//     <svg
//         width="24px"
//         height="24px"
//         viewBox="0 0 24 24"
//         version="1.1"
//         xmlns="http://www.w3.org/2000/svg"
//         xmlnsXlink="http://www.w3.org/1999/xlink"
//         onClick={onClick}
//     >
//         <defs>
//             <path
//                 d="M12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 Z M16.1553041,7.84471837 C15.6957097,7.38509388 14.9528357,7.38509388 14.4932413,7.84471837 L14.4932413,7.84471837 L12.0001469,10.3379755 L9.50587717,7.84471837 C9.04745813,7.38509388 8.30340876,7.38509388 7.84381428,7.84471837 C7.38539524,8.30316735 7.38539524,9.04726531 7.84381428,9.5068898 L7.84381428,9.5068898 L10.3369086,12.0001469 L7.84381428,14.4934041 C7.38539524,14.9530286 7.38539524,15.695951 7.84381428,16.1555755 C8.07419924,16.3848 8.37393476,16.5 8.67484572,16.5 C8.97575669,16.5 9.27666765,16.3848 9.50587717,16.1555755 L9.50587717,16.1555755 L12.0001469,13.6623184 L14.4932413,16.1555755 C14.7224508,16.3848 15.0233617,16.5 15.3242727,16.5 C15.6251837,16.5 15.9260946,16.3848 16.1553041,16.1555755 C16.6148986,15.695951 16.6148986,14.9530286 16.1553041,14.4934041 L16.1553041,14.4934041 L13.6622098,12.0001469 L16.1553041,9.5068898 C16.6148986,9.04726531 16.6148986,8.30316735 16.1553041,7.84471837 Z"
//                 id="path-1-cross"
//             />
//         </defs>
//         <g
//             id="standard/action/cross-circle2"
//             stroke="none"
//             strokeWidth={1}
//             fill="none"
//             fillRule="evenodd"
//         >
//             <mask id="mask-2-cross" fill="white">
//                 <use xlinkHref="#path-1-cross" />
//             </mask>
//             <use id="Combined-Shape" fill="#A5ADBA" xlinkHref="#path-1-cross" />
//             <g id="↳-🎨Color/Neutral/Black" mask="url(#mask-2-cross)" fill="#000000">
//                 <rect id="Color-Container" x={0} y={0} width={24} height={24} />
//             </g>
//         </g>
//     </svg>
// );


export type SelectField = {
    resetSelectedOption: () => void;
    setOption: (option: _selectoptionType, selectedOptionsForMultiSelect?: Record<string, any>) => void;
    closeDropdown: () => void;
    setFilterText: (text: string) => void;
    revalidateOptions: () => void;
    getSelectedOptionValue: () => string;
}

const position_classname: any = {
    normal: '', 
    top: 'select-field-options--top', 
    left: 'select-field-options--left', 
    right: 'select-field-options--right'
};

export const SelectField = forwardRef<SelectField, SelectFieldProps>(({ 
    options, 
    label, 
    className = '', 
    inputClass = '',
    required, 
    infoText = '' , 
    autoSelectFirstOption, 
    position = 'normal', 
    no_wrap_multiple_select = false,
    reset_options, placeholder = '', 
    hideInputField = false,
    onOptionClick, 
    onSelectAll,
    multiple_select, 
    disabled, 
    useSelectedOptionsFromProps = false, 
    selectedOptionsFromProps = {}, 
    onFilterTextChange,
    useOptionSearch = true,
    useDynamicWidth = false,
    children,
    max_items = -1,
    handleFilterInParent= false, 
    renderItemAtOptionsBottom,
    renderOptionLabel,
    selectAll=false,
    validate,
    theme='',
    initial_value,
    // handleFilterInParent is set to true when filtering is handled at the parent component
    ...props 
}, ref) => {
    const [, setAllOptionsSelected] = useState(false);
    const [showOptions, toggleOptionsList] = useState(false);
    const [active_option, setActiveOption] = useState<_selectoptionType>({label: '', value: ''});
    const activeOptionRef = useRef<_selectoptionType>(active_option)
    const [fieldHandlers ,fieldMeta, { setValue }] = useField({ validate, name: props.name });
    const selectRef = useRef<HTMLDivElement>(null);
    const labelFieldRef = useRef<HTMLDivElement | HTMLLabelElement | any>(null);
    const [selectedOptions, setSelectedOptions] = useState<Record<string, any>>({});
    const selectedOptionsRef = useRef<any>([]);
    const previousOptions = usePrevious(options);
    const [filterText, setFilterText] = useState('');
    const inputFieldRef = useRef<HTMLInputElement>(null);
    const optionsListRef = useRef<HTMLDivElement>(null);
    const resizeObserverRef = useRef<any>();
    const intersectionObserverRef = useRef<any>();

    useEffect(() => {
        if(useDynamicWidth && !multiple_select && active_option.label &&  inputFieldRef.current) {
            inputFieldRef.current.size = active_option.label.length + 1;
        } 
    }, [active_option.label]);

        useEffect(() => {
            if (Array.isArray(selectedOptions)) {
                selectedOptionsRef.current = selectedOptions;
            } else if (selectedOptions && typeof selectedOptions === 'object') {
                selectedOptionsRef.current = Object.values(selectedOptions);
            } else {
                selectedOptionsRef.current = [];
            }
        }, [selectedOptions]);

    useEffect(() => {
        activeOptionRef.current = active_option;
    }, [active_option]);

    // to close the options list if user clicks outside
    useOutsideClick(selectRef, () => {
        toggleOptionsList(false);
    }, showOptions);
    

    const handleValueSelection = (option: _selectoptionType)  => {
        if(!useSelectedOptionsFromProps)
            setValue(option.value);
        setActiveOption(option);
        toggleOptionsList(false);
    };

    const updateSelectedValue = (__selectedOptions: typeof selectedOptions) => {
        let newValue = '';
        let activeOptionName = '';
        Object.entries(__selectedOptions).forEach(([__label, value]) => {
            newValue +=  (newValue !== '' ? ',' : '') + value;
            activeOptionName += (activeOptionName !== '' ? ', ' : '') + __label;
        });
        if(!useSelectedOptionsFromProps) {
            // state is handled in the parent component
            setValue(newValue);
        }
        // setFieldValue(props.name as never, newValue);
        setActiveOption({ label: activeOptionName, value: newValue });
        setSelectedOptions(__selectedOptions);
    };

    const handleMultipleValueSelection = (option: _selectoptionType, _selectedOptions: typeof selectedOptions) => {
        let __selectedOptions = { ..._selectedOptions };
        let selected = true;
        const label = option.label;
        if(selectedOptions[label]) {
            __selectedOptions = omit(__selectedOptions, label);
            selected = false;
        } else {
            __selectedOptions[label] = option.value;
        }
        updateSelectedValue(__selectedOptions);
        onOptionClick && onOptionClick(option, __selectedOptions, selected);
    };

    const revalidateOptions = () => {
        const __selectedOptions: typeof selectedOptions = {};
        options.forEach(option => {
            if(selectedOptionsRef.current[option.label]) {
                __selectedOptions[option.label] = option.value;
            }
        }); 
        if(!isEqual(__selectedOptions, selectedOptionsRef.current )) {
            setSelectedOptions(__selectedOptions);
        }
    };

    useImperativeHandle(ref, () => ({
        resetSelectedOption: () => {
            if(multiple_select){
                setValue('');
                setActiveOption({ label: '', value: '' });
                setSelectedOptions({});
            } else {
                handleValueSelection({label: '', value: ''});
            }
        },
        setOption: (option: _selectoptionType, _selectedOptions = selectedOptionsRef.current) => {
            if(multiple_select && _selectedOptions){
                handleMultipleValueSelection(option, _selectedOptions);
            } else {
                // for single selection
                handleValueSelection(option);
            }
            
        },
        closeDropdown: () => {
            toggleOptionsList(false);
        },
        setFilterText: (_t: string) => {
            setFilterText(_t);
        },
        revalidateOptions,
        getSelectedOptionValue: () => {
            if(multiple_select) {
                return selectedOptionsRef.current.join(",")
            } return activeOptionRef.current.value
        }
    }), [multiple_select]);

    useDidUpdate(() => {
        // reset options when select field changes from select to multi select or vice versa
        handleValueSelection({ label: '', value: ''});
        setSelectedOptions({});
    }, [multiple_select]);

    useDidUpdate(() => {
        if(multiple_select){
            setValue('');
            setActiveOption({ label: '', value: '' });
            setSelectedOptions({});
        } else {
            handleValueSelection({label: '', value: ''});
        }
    }, [reset_options]);

    const fieldInitialValue = fieldMeta.initialValue;
    const handleMultipleInitialValueSelection = () => {
        // for multiple selection, initial values should be a string of values separated by commas
        const initialValuesList = fieldMeta.value.split(',');
        const initialSelectedOptions: _selectoptionType[] = [];
        initialValuesList.forEach((initialValue: string) => {
            const __value = initialValue.trim();
            const __option = options.find(option => option.value === __value);
            if(__option) initialSelectedOptions.push(__option);
        });

        if(!isEmpty(initialSelectedOptions)){
            let value = '';
            let label = '';
            const __initialOptions: typeof selectedOptions = {};
            initialSelectedOptions.forEach(option => {
                value +=  (value !== '' ? ',' : '') + option.value;
                label += (label !== '' ? ', ' : '') + option.label;
                __initialOptions[option.label] = option.value;
            });
            setActiveOption({ label, value });
            setSelectedOptions(__initialOptions);
        } else {
            setSelectedOptions({});
            setActiveOption({label: '', value: ''});
        }
    };

    const selectAllCb = () => {
        setAllOptionsSelected(true);
        const __selectedOptions: typeof selectedOptions = { };
        options.forEach((option)=>{
            __selectedOptions[option.label] = option.value;
        });
        updateSelectedValue(__selectedOptions);
        onSelectAll && onSelectAll(__selectedOptions);
    };

    const previousFieldInitialValue = usePrevious(fieldInitialValue);

    useEffect(() => {
        if(fieldInitialValue !== '' 
            && fieldInitialValue !== undefined
            && !isEmpty(options) 
            && (!isEqual(fieldInitialValue, previousFieldInitialValue) || !isEqual(options, previousOptions))
            && (handleFilterInParent ?  !isEqual(fieldMeta.value, active_option.value) : true)
        ) {
            // automatically selects initial value option
            // do not use !!(fieldInitialValue) as 0 might be ignored 
            // When handleFilterInParent is true, options are changed directly n filtertextchange due to which this function is called 
            // which would automatically restore the field value to field initial value when option is changed 
            // fieldMeta.value, active_option.value are used for comparison, when the form is being initialized, 
            // active_option.value is empty, so this function runs when fieldMeta.value, active_option.value are not equal i.e on initialization
            if(multiple_select){
                handleMultipleInitialValueSelection();
            } else {
                const initialOption = (options).find(option => isEqual(option.value, fieldInitialValue));
                if(initialOption && initialOption.value !== active_option.value){
                    handleValueSelection(initialOption);
                }
            }
        } else if(autoSelectFirstOption && !isEmpty(options)) {  // Set first option as initial value
            handleValueSelection(options[0]);
        }
    }, [options, fieldInitialValue]);

    

    const getOptionSelectedStatus = useCallback((optionLabel: string): boolean => (
        useSelectedOptionsFromProps ?
            has(selectedOptionsFromProps, optionLabel)
            :
            !!(selectedOptions[optionLabel])
    ), [useSelectedOptionsFromProps, selectedOptionsFromProps, selectedOptions]);  

    const selectedOptionsToDisplay = useMemo(() => (
        useSelectedOptionsFromProps ? 
            options.filter(option => getOptionSelectedStatus(option.label))
            :
            Object.keys(selectedOptions).map((label => ({ label, value: selectedOptions[label] } as _selectoptionType)))
    ), [useSelectedOptionsFromProps, getOptionSelectedStatus, selectedOptions, options]);
    
    const selectedOptionInSingleSelection = useMemo(() => {
        if(useSelectedOptionsFromProps && !isEmpty(selectedOptionsFromProps)) {
            const optionKey = Object.keys(selectedOptionsFromProps)[0]
            return { label: optionKey, value: selectedOptionsFromProps[optionKey] }
        } 
        return active_option

    }, [active_option, useSelectedOptionsFromProps, selectedOptionsFromProps])

    useDidUpdate(() => {
        onFilterTextChange && onFilterTextChange(filterText);
    }, [filterText]);

    const handleFilterOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
    };

    useEffect(() => {
        if(!showOptions && filterText) setFilterText('');
    }, [showOptions]);

    const filteredOptions: _selectoptionType[] = useMemo(() => filterText && !handleFilterInParent ?
        options.filter(_option => _option.label.toLowerCase().includes(filterText.toLowerCase()))
        :
        options
    , [filterText, handleFilterInParent, options]);

    const searchMode = useOptionSearch && showOptions;

    useEffect(() => {
        const handleIntersectionObserver = new IntersectionObserver((entries) => {
            if(!isEmpty(entries) && optionsListRef.current && showOptions && selectRef.current) {
                // IF intersectionRatio < 1 => move options list to above the label
                if(entries[0].intersectionRatio < 1) {
                    optionsListRef.current.style.bottom = `${selectRef.current.clientHeight + 6}px`;
                    optionsListRef.current.style.top = 'auto';
                }
            } else if (!showOptions && optionsListRef.current) {
                optionsListRef.current.style.bottom = 'auto';
                optionsListRef.current.style.top = '30px';
            }
        });
        intersectionObserverRef.current = handleIntersectionObserver;
        optionsListRef.current && handleIntersectionObserver.observe(optionsListRef.current);
        return () => {
            if(optionsListRef.current) intersectionObserverRef.current?.unobserve(optionsListRef.current);
        };
    }, [showOptions]);


    const additionalStyleForOptionsDropdown = useMemo(() => 
        hideInputField ? { left: -150, top: -5, minWidth: 170 }: {}
    , [hideInputField]);

    useEffect(() => {
        if(multiple_select) {
            // to adjust the height of multi select dropdown
            // @ts-ignore
            const handleResizeObserver = new ResizeObserver((entries) => {
                if(optionsListRef.current && !isEmpty(entries)) {
                    // portalRef.current?.reCalculatePosition();
                    optionsListRef.current.style.top = `${entries[0].contentRect.height + 6}px`;
                }
            });
            resizeObserverRef.current = handleResizeObserver;
            labelFieldRef.current && handleResizeObserver.observe(labelFieldRef.current);
        }
        return () => {
            if(multiple_select && labelFieldRef.current) resizeObserverRef.current?.unobserve(labelFieldRef.current);
        };
    }, []);

    const minimumHeightOfOptions = useMemo(() => filteredOptions?.length ? 
        filteredOptions.length > 4 ? 
            120 : filteredOptions.length*24
        :0, [filteredOptions]  );


    const showErrorMessage = useMemo(() => (fieldMeta.error && fieldMeta.touched), [fieldMeta.error, fieldMeta.touched]);
    const backgroundcolor=theme?theme === "dark" ? '#000' : '#eaeaea':'';
    const textStyle=theme?theme === "dark" ? '#fff' : '#000':'';
    const multiSelectBackGroundColor =theme?theme === "dark" ? '#14182a' : '#fff':'';
    
    return (
        <div 
            className={classNames('select-field', {[className]: className})}
            data-fieldname={props.name}
            {...props}
        >
            <label 
                className="inputfield__label"
                style={{color: textStyle}} 
                // ref={hideInputField ? labelFieldRef: null}    
            >
                <span className={required ? 'red-star': ''} ref={!hideInputField? null : selectRef} onClick={() => toggleOptionsList(!showOptions)}  >{label}</span>
                <ShowWhenTrue show={!!infoText}>
                    <TooltipTop placement="topRight" overlay={infoText}>
                        <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                    </TooltipTop>
                </ShowWhenTrue>
            </label>
            
            <div 
                className={classNames('select-field-box', {'select-field-box--active': showOptions, 'select-field-error': showErrorMessage })} 
                ref={hideInputField? null : selectRef} 
                onBlur={fieldHandlers.onBlur}
                style={{ backgroundColor: backgroundcolor }}
            >
                {multiple_select ?
                    <ShowWhenTrue show={!hideInputField}>
                        <div 
                            className={classNames(
                                'select-field-value multiple-select-values', 
                                { 'no-wrap': no_wrap_multiple_select },
                                {'select-field-disabled': disabled }
                            )}
                            onClick={() => !(disabled) && toggleOptionsList(!showOptions)}  
                            ref={labelFieldRef}
                            style={{ backgroundColor: multiSelectBackGroundColor }}
                        >
                            <div className="multiple-select-tags-list">
                                {selectedOptionsToDisplay.map(_option => {
                                    return(
                                        <span
                                            key={_option.label+_option.value}
                                            className="multiple-select-tags"
                                        >
                                            {_option.label}
                                            {!disabled ?
                                                <span 
                                                    className="closeIcon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        !(disabled) && handleMultipleValueSelection(_option, selectedOptions);
                                                        // onOptionClick && onOptionClick(_option, selectedOptions);
                                                    }}
                                                >
                                                        X
                                                </span>
                                                :
                                                null
                                            }
                                        </span>
                                    );
                                })}
                            </div>
                            <ShowWhenTrue show={showOptions}>
                                <input
                                    className={'multi-select-field-search'} 
                                    onClick={(e) => e.stopPropagation()}
                                    value={filterText}
                                    autoComplete="off"
                                    placeholder="Type here to Search"
                                    onChange={handleFilterOptions}
                                    readOnly={!searchMode}
                                    ref={inputFieldRef}
                                    autoFocus
                                    style={{ backgroundColor: backgroundcolor }}
                                />
                            </ShowWhenTrue>
                        </div>
                    </ShowWhenTrue>
                    :
                    <input
                        className={classNames('select-field-value', {[className]: inputClass})}
                        value={
                            searchMode 
                              ? filterText 
                              : selectedOptionInSingleSelection?.label 
                                ? selectedOptionInSingleSelection.label 
                                : initial_value
                          }
                        onClick={() => !(disabled) && toggleOptionsList(!showOptions)}
                        autoComplete="off"
                        placeholder={classNames({[placeholder]: !searchMode}, {[selectedOptionInSingleSelection.label]: searchMode} )}
                        disabled={!!(disabled)}
                        onChange={handleFilterOptions}
                        readOnly={!searchMode}
                        ref={inputFieldRef}
                        name={props.name}

                        style={{ backgroundColor: backgroundcolor , color: textStyle }}
                    />
                }
                <ShowWhenTrue show={max_items !== -1 && Object.keys(selectedOptions).length >= max_items}>
                    <span className="" style={{
                        color: 'red',
                        fontSize: '12px'
                    }}>Max {max_items} items</span>
                </ShowWhenTrue>
                {/* <ShowWhenTrue show={showOptions && !(disabled)}> */}
                <div
                    className={classNames(`select-field-options-outer ${position_classname[position]}`, {'select-field-bottom-fixed-option': !!(renderItemAtOptionsBottom)}, {'hide': !showOptions || (disabled)})}
                    ref={optionsListRef}
                    style={{ minHeight: minimumHeightOfOptions, backgroundColor: backgroundcolor , color: textStyle }}
                >
                    <ul 
                        style={additionalStyleForOptionsDropdown} 
                        className={'select-field-options'}
                    >
                        {
                            (multiple_select && selectAll) ? (
                                <li 
                                    className="select-field-option select-field-option-multiple" 
                                    onClick={selectAllCb}
                                   style={{color: textStyle}} 
                                >
                                   --- Select All ---
                                </li>
                            ) : null
                        }
                        {multiple_select ? 
                            (filteredOptions || []).map((option, index) => {
                                const __selected = getOptionSelectedStatus(option.label);
                                return(
                                    <li 
                                        key={option.label+index} 
                                        className="select-field-option select-field-option-multiple" 
                                        onClick={() => {
                                            if(max_items !== -1 && Object.keys(selectedOptions).length >= max_items) 
                                                return;
                                            handleMultipleValueSelection(option, selectedOptions);
                                        }}
                                        style={{color: textStyle}} 
                                    >
                                        <label className="checkbox__container" >
                                            <input 
                                                type="checkbox"  
                                                checked={__selected} 
                                                onChange={() => {return;}}/>
                                            <span className="checkmark" onClick={(e)=> e.preventDefault()} />
                                        </label> 
                                        {renderOptionLabel ? 
                                            renderOptionLabel(option)
                                            :
                                            option.label
                                        }
                                    </li>
                                );
                            })
                            :
                            (filteredOptions || []).map((option, index) => {
                                return(
                                    <li 
                                        key={option.key ? option.key: option.label+index} 
                                        className={classNames('select-field-option', {
                                            'select-field-option--selected': option.value === active_option.value,
                                            'select-field-option--disabled': option.disabled
                                        })} 
                                        onClick={() => {
                                            if (option.disabled) {
                                                errorAlert("Data Studio does not support SHARED access mode.");
                                            } else {
                                                handleValueSelection(option);
                                                onOptionClick && onOptionClick(option);
                                            }
                                        }}
                                        id= {`selectedUser_WK_${option.label.replaceAll(' ','')}`}
                                        style={{color: textStyle}} 
                                    >
                                        {renderOptionLabel ? 
                                            renderOptionLabel(option)
                                            :
                                            option.label
                                        }
                                    </li>
                                );}
                            )
                        }
                    </ul>
                    {renderItemAtOptionsBottom ? 
                        <div
                            className={classNames('select-field-option', 'select-field-option-bottom-fixed')} 
                        >
                            {renderItemAtOptionsBottom()}
                        </div>    
                        : null
                    }
                </div>
                {showErrorMessage && <span className="inputfield__errormessage" role="error-message">{fieldMeta.error}</span>}
                {/* </ShowWhenTrue> */}
            </div>
            {children}
        </div>
    ); 
    
});

SelectField.displayName = 'SelectField';