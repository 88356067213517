import { 
    CanvasIcon, 
    DataSourcesIcon, 
    AdminIcon, 
    AnalyticsIcon, 
    BrowserIcon, 
    JobsHistoryIcon, 
    jobDashboardIcon, 
    FileBrowserIcon,
    MLFlowIcon,
    ClusterIcon,
    DataExplorerIcon,
    DataExplorerV2Icon
} from './icons';
import _ from 'lodash';
import { Env } from '../../constants/settings';

interface MenuTabs {
    name: string;
    tabs: {
        [key in string]: {
            name: string;
            url: string;
        }
    };
}

export const studioTabs: MenuTabs = {
    name: 'STUDIO',
    tabs: {
        canvas: { name: 'Canvas', url: '/workflow'},
        visualizations: { name: 'Visualizations', url: '/workflow/visualizations'},
    }
};

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const dataTabs = (env: boolean = true): MenuTabs => {
    return {
        name: 'DATA',
        tabs: env  
            ? { 
                browser: { name: 'Data Browser', url: '/datasource/browser/' },
                dataExplorer: { name: 'Data Explorer', url: '/dataexplorer' },
                dataExplorerV2: { name: 'Data Explorer V2', url: '/dataexplorer-v2' },
                fileBrowser: { name: 'File Browser', url: '/filemanagement' },
                cluster: { name: 'Compute', url: '/cluster' }
            } 
            : { 
                browser: { name: 'Data Browser', url: '/datasource/browser/' },
                fileBrowser: { name: 'File Browser', url: '/filemanagement' },
                cluster: { name: 'Compute', url: '/cluster' }
            }
    };
};


export const jobsTabs: MenuTabs = {
    name: 'JOBS',
    tabs: (()=>{
        const obj  = {};

        Object.assign(obj, {
            jobDashboard: { name: 'Job Dashboard', url: '/airflow'},
            // jobHistory: { name: 'Job History', url: '/jobhistory'}
        });

        if(Env.mlflow) {
            Object.assign(obj, {
                mlLoop: { name: 'Model Management', url: '/mlloop'}
            });
        }
        return obj;
    })()
};

export const adminTabs: MenuTabs = {
    name: 'ADMIN',
    tabs: {
        admin: { name: 'Admin', url: '/admin/user-management' }
    }
};
const dataTabsConfig = dataTabs(true);
const studioDataTabs = _.unionBy(Object.values(studioTabs.tabs), Object.values(dataTabsConfig.tabs), 'name');
const jobsAdminTabs = _.unionBy(Object.values(jobsTabs.tabs), Object.values(adminTabs.tabs), 'name');
export const allTabs = _.unionBy(studioDataTabs, jobsAdminTabs, 'name');

export const TabIcons = {
    canvas: CanvasIcon,
    visualizations: AnalyticsIcon,
    dataSources: DataSourcesIcon,
    browser: BrowserIcon,
    fileBrowser: FileBrowserIcon,
    cluster: ClusterIcon,
    jobDashboard: jobDashboardIcon,
    jobHistory: JobsHistoryIcon,
    admin: AdminIcon,
    mlLoop: MLFlowIcon,
    dataExplorer: DataExplorerIcon,
    dataExplorerV2: DataExplorerV2Icon
};

export type Tabs = typeof studioTabs | ReturnType<typeof dataTabs> |  typeof jobsTabs | typeof adminTabs;
